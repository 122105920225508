import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as uuid from 'uuid';

import AssetDetailsPage from '../Common/AssetDetailsPage';
import AssetDetailsLink from '../Common/AssetDetailsLink';
import ProgressBar from './ProgressBar';
import ClearingPrice from 'components/views/app/Assets/Trading/Common/ClearingPrice';
import { BidAskInfo } from 'components/views/app/Assets/Trading/Common/BidAskInfo/BidAskInfo';
import { Price } from 'components/shared/Price';
import { Info } from 'components/shared/Icons/Info';
import { DotsLoader } from 'components/shared/Loaders';

import { getInvestments } from 'actions';
import { ASSET_STATUS, SUPPORTED_APPLICATION_MODES } from 'constants/main';
import { getSecondaryMarketEnableDiff } from 'utils';
import { getTimeInTimezone } from 'utils/main';

import GREEN_UP_ARROW from 'images/green-up-arrow.png';

import './MainPage.scss';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import { SecondaryMarketStatus } from 'types/assets';
import { ModalViewContext } from 'components/shared/Modals/Modal';
import AlertModal from 'components/shared/Modals/AlertModal';
import CONFIG from 'config';

const POST_ONLY_ALERT_KEY = 'PostOnlyAlert';

class MainPage extends Component {
  constructor(props) {
    super(props);
    const { user, activeAsset } = this.props;
    const saAsset =
      user
        .getSpeciallyAccessibleAssetsIdAccessTypeAndLimitationsMap({
          assetIds: [activeAsset.id],
        })
        .get(activeAsset.id) || {};

    this.state = {
      tradingInfoOpen: false,
      isEbAsset: saAsset && saAsset.accessType === 'early_io',
      price: 0,
      priceShift: 0,
      priceShiftPercentage: 0,
      isTrading: null,
      isLiveTrading: null,
    };
  }

  toggleTradingInfoOpen = () => {
    this.setState({ tradingInfoOpen: !this.state.tradingInfoOpen });
  };

  componentDidMount() {
    this.props.getInvestments();
    this.updatePriceShift();
    this.showEducationalAlert();
  }

  componentDidUpdate(prevProps) {
    const { activeAsset } = this.props;
    if (prevProps.activeAsset !== activeAsset) {
      this.updatePriceShift();
    }
    if (activeAsset.id !== prevProps.activeAsset.id) {
      this.props.getInvestments();
    }
  }

  showEducationalAlert = () => {
    const { postOnlyEducationalEnabled } = CONFIG.featureFlags;
    if (!postOnlyEducationalEnabled) {
      return;
    }

    const { setModalViewRenderSequence } = this.context;
    const asset = this.props.activeAsset;
    const { secondaryMarket } = asset.financialInstrument?.markets ?? {};
    const timestampDiff = getSecondaryMarketEnableDiff(secondaryMarket);
    const isTrading =
      (asset.asset_status === ASSET_STATUS.POST_ONLY && timestampDiff <= 0) ||
      (asset.asset_status === ASSET_STATUS.TRADING_OPENED &&
        secondaryMarket.status !== SecondaryMarketStatus.DISABLED);

    const isLiveTrading =
      asset.asset_status === ASSET_STATUS.TRADING_OPENED &&
      secondaryMarket.status === SecondaryMarketStatus.OPEN;
    const {
      sessionsStart,
      enabled,
      sessionHours: { lastDateClose },
    } = secondaryMarket ?? {};

    if (!isTrading || isLiveTrading) {
      return;
    }

    if (!sessionsStart && !lastDateClose && !enabled) return;

    if (sessionsStart && moment(sessionsStart).isBefore(moment.now())) {
      return;
    }

    let postOnlyAlerts = JSON.parse(sessionStorage.getItem(POST_ONLY_ALERT_KEY));
    if (!postOnlyAlerts) {
      postOnlyAlerts = {};
    }

    if (postOnlyAlerts[asset.id]) {
      return;
    }

    postOnlyAlerts[asset.id] = true;
    sessionStorage.setItem(POST_ONLY_ALERT_KEY, JSON.stringify(postOnlyAlerts));
    setModalViewRenderSequence([
      {
        state: true,
        id: uuid.v4(),
        modalStyle: {
          overlayBgColor: 'rgba(0,0,0, .7)',
        },
        children: <AlertModal viewId={uuid.v4()} />,
        childrenProps: {
          title: 'This asset is "Accepting Orders"',
          message: `
            Your BIDs and ASKs can be submitted and placed into the queue, 
            but they will not be eligible to execute until the start of Live Trading for this asset, 
            which is currently scheduled for ${
              sessionsStart ? moment(sessionsStart).format('MM/DD/YYYY') : 'next week'
            }`,
          primaryButton: true,
          buttonText: 'Okay, got it!',
        },
      },
    ]);
  };

  updatePriceShift = () => {
    const { activeAsset } = this.props;
    const { trading } = activeAsset || {};
    const { secondaryMarket } = activeAsset.financialInstrument?.markets ?? {};
    const timestampDiff = getSecondaryMarketEnableDiff(secondaryMarket);

    const isTrading =
      (activeAsset.asset_status === ASSET_STATUS.POST_ONLY && timestampDiff <= 0) ||
      (activeAsset.asset_status === ASSET_STATUS.TRADING_OPENED &&
        secondaryMarket.status !== SecondaryMarketStatus.DISABLED);

    const isLiveTrading =
      activeAsset.asset_status === ASSET_STATUS.TRADING_OPENED &&
      secondaryMarket.status === SecondaryMarketStatus.OPEN;

    const isTradingClosed =
      (activeAsset.asset_status === ASSET_STATUS.POST_ONLY && timestampDiff > 0) ||
      activeAsset.asset_status === ASSET_STATUS.TRADING_CLOSED ||
      (activeAsset.asset_status === ASSET_STATUS.TRADING_OPENED &&
        secondaryMarket.status === SecondaryMarketStatus.DISABLED);

    const { pricing: { pricePerUnit } = {} } = trading || {};
    let price;
    let priceShift = 0;
    let priceShiftPercentage = 0;
    if (isTrading || isTradingClosed) {
      price = pricePerUnit?.session?.lastClose || pricePerUnit?.issue;
    }
    if (isLiveTrading) {
      price = pricePerUnit?.session?.latestFilled || pricePerUnit?.current;
    }
    if (!price) {
      price = activeAsset.asset_value / activeAsset.number_of_shares;
      this.setState({
        price,
        isLiveTrading,
        isTrading,
        priceShift,
        priceShiftPercentage,
      });
      return;
    }
    priceShift = price - (pricePerUnit?.issue ?? 0);
    priceShiftPercentage = (priceShift / pricePerUnit?.issue) * 100;
    this.setState({
      price,
      isLiveTrading,
      isTrading,
      priceShift,
      priceShiftPercentage,
    });
  };

  getProgressPercent(asset) {
    return Math.min(Math.floor(asset.progress_bar), 100);
  }

  getDaysLeft(asset) {
    const { user } = this.props;
    let daysLeft = 0;

    if (this.props.applicationMode === SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS) {
      if (user.access_groups.length > 0) {
        const earlyIOAccessEndDate = user.access_groups[0].assets[0].access_end;
        daysLeft = moment.duration(moment(earlyIOAccessEndDate) - moment()).asDays();
      }
    } else {
      if (!asset.offering_ends) return daysLeft;
      daysLeft = moment.duration(moment(asset.offering_ends) - moment()).asDays();
    }

    return Math.max(Math.round(daysLeft), 0);
  }

  renderClosingTimeInfo(asset) {
    const { isEbAsset } = this.state;
    const { activeAsset } = this.props;
    const { trading } = activeAsset || {};
    const { pricing: { pricePerUnit } = {} } = trading || {};
    const lastClosePrice =
      pricePerUnit?.session?.lastClose || activeAsset.asset_value / activeAsset.number_of_shares;

    let closingMessage = 'THIS INITIAL OFFERING';
    let daysLeft = this.getDaysLeft(asset);
    let percent = this.getProgressPercent(asset);

    if (asset.asset_status === ASSET_STATUS.IO_OPENED && daysLeft === 0) {
      closingMessage = 'INITIAL OFFERING CLOSE DATE TBD';
    } else if (asset.asset_status === ASSET_STATUS.COMING_SOON && !isEbAsset) {
      closingMessage += ' IS COMING SOON!';
    } else if (daysLeft > 0 && percent < 100) {
      const closingDate = moment(asset.offering_ends).format('MM/DD/YYYY');
      let closingTime = getTimeInTimezone(asset.offering_ends, true);
      closingTime = `${closingTime.time + closingTime.ampm} ${closingTime.timezone}`;
      closingMessage += ' CLOSES ' + closingDate + ', ' + closingTime;
    } else if (
      asset.asset_status === ASSET_STATUS.TRADING_OPENED ||
      asset.asset_status === ASSET_STATUS.POST_ONLY ||
      asset.asset_status === ASSET_STATUS.TRADING_CLOSED
    ) {
      closingMessage = null;

      const lastCloseDate =
        asset?.financialInstrument?.markets?.secondaryMarket?.sessionHours?.lastDateClose ||
        asset.offering_ends;

      closingMessage = (
        <span>
          <span className="AssetDetailsMain-last-close">
            Last Close, {lastCloseDate ? moment(lastCloseDate).format('MM/DD/YYYY') : '-'}
          </span>
          <span className="AssetDetailsMain-last-close has-bg">
            <span className="AssetDetailsMain-tradinginfo-currentvalue">
              <span className={'AssetDetailsMain-tradinginfo-currentvalue-price'}>
                <Price price={lastClosePrice} keepZeros />
              </span>
              (<Price price={lastClosePrice * asset.number_of_shares} />
              &nbsp;VALUE)
            </span>
          </span>
        </span>
      );
    } else if (asset.asset_status === ASSET_STATUS.IO_CLOSED) {
      if (asset.trading && asset.trading.last_trading_window) {
        const lastTradingWindow = asset.trading.last_trading_window;
        closingMessage = (
          <span className="AssetDetailsMain-last-trading">
            <span className="AssetDetailsMain-trading-closed-date">
              Last trading window: {moment(lastTradingWindow.closing_time).format('MM/DD/YYYY')}
            </span>
          </span>
        );
      } else {
        closingMessage += ' IS CLOSED. CHECK BACK SOON!';
      }
    } else {
      closingMessage += ' IS CLOSED. CHECK BACK SOON!';
    }
    return closingMessage;
  }

  renderCurrentPrice = () => {
    const { activeTradingWindow, activeAsset } = this.props;
    const price =
      activeAsset.trading && activeAsset.trading.last_trading_window
        ? activeAsset.trading.last_trading_window.closing_price ||
          activeAsset.current_price_per_share
        : activeAsset.current_price_per_share;

    return activeTradingWindow && activeTradingWindow.closing_price ? (
      <ClearingPrice />
    ) : (
      <Price price={price} keepZeros={true} />
    );
  };

  renderProgressInfo = () => {
    const car = this.props.activeAsset;
    const { trading } = car ?? {};
    const progress = this.getProgressPercent(car);
    const daysLeft = car.asset_status === 'Closed ICO' ? 0 : this.getDaysLeft(car); //check getDaysLeft function for earlybird-specific calculation
    const invested = Math.floor(car.confirmed_investment + car.pending_investment);
    const remaining = (trading?.estimated_price || car.asset_value) - invested;

    let progressBarMessage = `${progress}% FUNDED`;

    const isComplete = progress === 100;
    const hasMoreThanHalf = progress > 50 && !isComplete;
    const isFinishing = progress >= 75 && !isComplete;
    const barColor = isFinishing ? '#b8483d' : '#42ca84';

    if (hasMoreThanHalf) {
      progressBarMessage = `${100 - progress}% REMAINING`;
    }

    return (
      <div className="AssetDetailsMain-progress">
        <div className="AssetDetailsMain-progress-mobile">
          <ProgressBar
            barColor={barColor}
            height={6}
            scale="vmin"
            progress={progress}
            message={progressBarMessage}
          />
        </div>
        <div className="AssetDetailsMain-progress-desktop">
          <ProgressBar
            barColor={barColor}
            height={2.4}
            scale="vh"
            progress={progress}
            message={progressBarMessage}
          />
        </div>
        <div className="AssetDetailsMain-investmentinfo">
          {isComplete ? (
            <div>INITIAL OFFERING SOLD OUT</div>
          ) : (
            <>
              <span>
                <span className="AssetDetailsMain-investmentinfo-days">{daysLeft} DAYS</span>
                &nbsp;LEFT
              </span>
              <div className="AssetDetailsMain-investmentinfo-divider" />
              <span>
                <span
                  className={`AssetDetailsMain-investmentinfo-investments${
                    isFinishing ? ' remaining' : ''
                  }`}
                >
                  <Price price={hasMoreThanHalf ? remaining : invested} />
                </span>
                &nbsp;{hasMoreThanHalf ? 'LEFT' : 'INVESTED'}
              </span>
            </>
          )}
          <div className="AssetDetailsMain-investmentinfo-divider" />
          <span>
            <span className="AssetDetailsMain-investmentinfo-investors">{car.investor_count}</span>
            &nbsp;INVESTORS
          </span>
        </div>
      </div>
    );
  };

  renderLastTradeInfo = () => {
    const { isLoadingTradingWindow, activeAsset } = this.props;
    const { isLiveTrading, priceShiftPercentage, priceShift, price } = this.state;
    const { trading } = activeAsset || {};
    const priceShiftType = priceShift < 0 ? 'down' : 'up';
    const { pricing: { pricePerUnit } = {} } = trading || {};

    return (
      <div className="AssetDetailsMain-tradinginfo">
        <div className="AssetDetailsMain-tradinginfo-titleinfo">
          <span className="AssetDetailsMain-tradinginfo-title white">LAST TRADE</span>
          <span className="AssetDetailsMain-tradinginfo-infobutton">
            <Tooltip icon={Info}>
              <strong>The Last Trade Price</strong> is the most recent executed share price during a
              Live Trading Window.
            </Tooltip>
          </span>
        </div>
        <div className="AssetDetailsMain-tradinginfo-clearingprice">
          {isLoadingTradingWindow ? (
            <DotsLoader color="rgba(255, 255, 255, 0.7)" size={9} />
          ) : (
            <div className="AssetDetailsMain-tradinginfo-clearingprice-wrapper">
              {isLiveTrading && <BidAskInfo isBid price={pricePerUnit?.orderBook?.bidHigh} />}
              {isLiveTrading && <BidAskInfo price={pricePerUnit?.orderBook?.askLow} />}
              <span className="AssetDetailsMain-tradinginfo-clearingprice-price">
                <span
                  className="ClearingPrice"
                  style={{ visibility: isLoadingTradingWindow ? 'hidden' : 'visible' }}
                >
                  {price ? <Price price={price} keepZeros /> : '-'}
                </span>
              </span>
              {priceShiftPercentage > 0 && (
                <span className="AssetDetailsMain-tradinginfo-clearingprice-arrow">
                  <img src={GREEN_UP_ARROW} alt="up-arrow" />
                </span>
              )}
            </div>
          )}
        </div>
        {!isLoadingTradingWindow && (
          <div className="AssetDetailsMain-tradinginfo-currentvalue">
            <span className={'AssetDetailsMain-tradinginfo-currentvalue-change ' + priceShiftType}>
              <b>
                {priceShiftType === 'up' && '+'}
                <Price price={priceShift} keepZeros />
              </b>
              &nbsp;({`${priceShiftPercentage > 0 ? '+' : ''}${priceShiftPercentage.toFixed(2)}%`})
            </span>
            &nbsp;ALL TIME
          </div>
        )}
      </div>
    );
  };

  renderClosedTradingInfo = () => {
    const { activeAsset } = this.props;
    const { price, priceShiftPercentage } = this.state;
    const assetValue = price * activeAsset.number_of_shares;

    return (
      <div className="AssetDetailsMain-tradinginfo AssetDetailsMain-tradingclosed">
        <div className="AssetDetailsMain-tradinginfo-titleinfo">
          <span className="AssetDetailsMain-tradinginfo-title">CURRENT SHARE PRICE</span>
        </div>
        <div className="AssetDetailsMain-tradinginfo-divider" />
        <div className="AssetDetailsMain-tradinginfo-clearingprice">
          <span className="AssetDetailsMain-tradinginfo-clearingprice-price">
            {<Price price={price} keepZeros />}
          </span>
          <br />
          <span className="AssetDetailsMain-tradinginfo-current-asset-price">
            (current asset value: <Price price={assetValue} />)
          </span>
          <div className="AssetDetailsMain-tradinginfo-divider" />
          <span
            className="AssetDetailsMain-tradinginfo-priceshift"
            style={{ color: priceShiftPercentage >= 0 ? '#53cd7f' : '#c54e48' }}
          >
            {`(${priceShiftPercentage > 0 ? '+' : ''}${
              Number.isInteger(priceShiftPercentage)
                ? priceShiftPercentage
                : priceShiftPercentage.toFixed(2)
            }%) All time`}
          </span>
        </div>
      </div>
    );
  };

  renderComingSoonInfo = () => {
    const { activeAsset } = this.props;
    return (
      <div className="AssetDetailsMain-comingsoon">
        {activeAsset.offering_starts ? (
          <div>
            THIS INITIAL OFFERING BEGINS <br />
            {moment(activeAsset.offering_starts).format('MM/DD/YYYY, hA')}
          </div>
        ) : (
          'OFFERING FOR THIS ASSET IS NOT YET SCHEDULED. CHECK BACK SOON'
        )}
      </div>
    );
  };

  render() {
    const { isEbAsset, isTrading } = this.state;
    const car = this.props.activeAsset;
    const status = car.asset_status;
    const { secondaryMarket } = car.financialInstrument?.markets ?? {};
    const trading = car.trading || {};

    const timestampDiff = getSecondaryMarketEnableDiff(secondaryMarket);

    const isComingSoon = status === ASSET_STATUS.COMING_SOON;
    const isOpenICO = status === ASSET_STATUS.IO_OPENED;
    const isClosedICO = status === ASSET_STATUS.IO_CLOSED;
    const isInTradingStatus =
      status === ASSET_STATUS.POST_ONLY || status === ASSET_STATUS.TRADING_OPENED;
    const isTradingClosed =
      status === ASSET_STATUS.TRADING_CLOSED ||
      (isInTradingStatus && timestampDiff > 0) ||
      (isInTradingStatus && secondaryMarket?.status === SecondaryMarketStatus.DISABLED);

    const hasLastTrade = isTrading && !isTradingClosed;

    const notTradingEarlyAccess = isEbAsset && !isTradingClosed && !isTrading;

    return (
      <div
        id="AssetDetailsMain"
        className={`AssetDetailsMain ${isComingSoon ? 'coming-soon' : ''} ${
          this.props.applicationMode
        }`}
        style={{ background: car.tertiary_color }}
      >
        <div className="AssetDetailsMain-background">
          <AssetDetailsPage asset={car} className="AssetDetailsPage-assetMainPage" {...this.props}>
            <div className="AssetDetailsMain-ticker" style={{ color: car.tertiary_color }}>
              {car.ticker}
            </div>
            <div className="AssetDetailsMain-name">{car.display_name}</div>
            <div className="AssetDetailsMain-enddate">{this.renderClosingTimeInfo(car)}</div>
            <div
              className="AssetDetailsMain-image"
              style={{ backgroundImage: `url(${car.portal_image})` }}
            ></div>

            {!isTrading && !isTradingClosed && (
              <div className="AssetDetailsMain-goal">
                {isOpenICO || isClosedICO ? 'GOAL: ' : 'CURRENT VALUE: '}
                <Price price={trading.estimated_price || car.asset_value} />
              </div>
            )}

            {(isOpenICO ||
              isClosedICO ||
              notTradingEarlyAccess ||
              this.props.applicationMode === SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS) &&
              this.renderProgressInfo()}

            {hasLastTrade && this.renderLastTradeInfo()}

            {isTradingClosed && this.renderClosedTradingInfo()}

            {!isEbAsset && isComingSoon && this.renderComingSoonInfo()}

            <div className="AssetDetailsMain-links">
              <AssetDetailsLink name="details" />
              <AssetDetailsLink name="gallery" />
              <AssetDetailsLink name="legal" />
            </div>
          </AssetDetailsPage>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ Assets, Auth, Trading }) => ({
  activeAsset: Assets.activeAsset,
  activeTradingWindow: Trading.activeTradingWindow,
  user: Auth.user,
  isLoadingTradingWindow: Trading.isLoadingTradingWindow,
});

const mapDispatchToProps = { getInvestments };

MainPage.contextType = ModalViewContext;

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
