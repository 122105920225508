import { Asset } from 'types/assets';
import moment from 'moment';

type Group = 'io' | 'live' | 'postOnly' | 'comingSoon';

export const getAssetDates = (asset: Asset) => {
  const { sessionHours } = asset.financialInstrument.markets.secondaryMarket;
  const shouldUseDateOpen = moment(sessionHours.dateOpen).isAfter(moment.now());
  const dates = {
    start: shouldUseDateOpen ? sessionHours.dateOpen : sessionHours.nextDateOpen,
    end: shouldUseDateOpen ? sessionHours.dateClose : sessionHours.nextDateOpen,
  };
  return dates;
};

export const sortAssetsByGroup = (list: Asset[], group: Group) => {
  return list.sort((firstAsset, secondAsset) => {
    if (group === 'live') {
      return firstAsset.ticker.localeCompare(secondAsset.ticker) > 0 ? 1 : -1;
    }

    if (group === 'postOnly') {
      const firstAssetDates = getAssetDates(firstAsset);
      const secondAssetDates = getAssetDates(firstAsset);
      if (moment(firstAssetDates.start).isSame(moment(secondAssetDates.start))) {
        return firstAsset.ticker.localeCompare(secondAsset.ticker) > 0 ? 1 : -1;
      }
      return moment(firstAssetDates.start).isAfter(moment(secondAssetDates.start)) ? 1 : -1;
    }

    if (group === 'comingSoon') {
      const firstOffering = firstAsset.offering_starts;
      const secondOffering = secondAsset.offering_starts;
      if (!firstOffering && secondOffering) return 1;
      if (firstOffering && !secondOffering) return -1;
      // sort the group that have and dont have the offering date
      if ((!firstOffering && !secondOffering) || (firstOffering && secondOffering)) {
        return firstAsset.ticker.localeCompare(secondAsset.ticker) > 0 ? 1 : -1;
      }
    }

    if (group === 'io') {
      const firstOffering = firstAsset.offering_starts;
      const secondOffering = secondAsset.offering_starts;

      if (!firstOffering && secondOffering) return 1;
      if (firstOffering && !secondOffering) return -1;

      if (!firstOffering && !secondOffering) {
        return firstAsset.ticker.localeCompare(secondAsset.ticker) > 0 ? 1 : -1;
      }

      return moment(firstAsset.offering_starts).isAfter(moment(secondAsset.offering_starts))
        ? 1
        : -1;
    }

    return 0;
  });
};

export const addWeekdays = (date: string, days: number) => {
  const sunday = 0;
  const saturday = 6;

  const finalDate = moment(date);

  let daysRemaining = days;

  while (daysRemaining > 0) {
    finalDate.add(1, 'day');
    if (finalDate.day() !== sunday && finalDate.day() !== saturday) {
      daysRemaining--;
    }
  }
  return finalDate;
};
