import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/shared/Popup';
import { Button, TextButton } from 'components/shared/Buttons';
import { TRADINGWINDOW_ORDER_TYPE } from 'constants/main';

import partyPopperImage from 'images/party-popper.png';
import './ConfirmTradePopup.scss';

class ConfirmTradePopup extends Component {
  state = { showRefuseWarning: false };

  static propTypes = {
    show: PropTypes.bool,
    order: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
  };

  static defaultProps = {
    show: false,
    order: {},
    onConfirm: () => null,
    onCancel: () => null,
  };

  showRefuseWarning = () => this.setState({ showRefuseWarning: true });
  hideRefuseWarning = () => this.setState({ showRefuseWarning: false });

  renderRefuseWarning() {
    return (
      <Popup
        show
        disableAutoClose
        className="ConfirmTradePopup"
        contentClassName="ConfirmTradePopup-content"
      >
        <h1>Are you sure?</h1>
        <p>
          Cancelling a match cannot be reversed and you will not be able to participate until the
          next window
        </p>
        <div className="ConfirmTradePopup-refuse-controls">
          <TextButton
            className="ConfirmTradePopup-refuse-back-btn"
            onClick={this.hideRefuseWarning}
            text="Go Back"
          />
          <a
            className="ConfirmTradePopup-refuse-link"
            href="mailto:hello@rallyrd.com?subject=Refuse My Trade"
            onClick={this.props.onCancel}
          >
            Cancel Match
          </a>
        </div>
      </Popup>
    );
  }

  render() {
    const { show, order } = this.props;
    if (!show || !order) return null;
    if (this.state.showRefuseWarning) return this.renderRefuseWarning();

    const partial = order.confirmed_matched_status === 'PARTIALLY_MATCHED';
    const side = order.side === TRADINGWINDOW_ORDER_TYPE.BUY ? 'bid' : 'ask';
    const shares = order.matched_shares_count;
    const price = order.current_price_per_share;

    const subHeader = partial ? (
      <h2>
        A portion of your {side} was matched <br />({shares} share{shares > 1 && 's'} @
        <span className="ConfirmTradePopup-price">${price}</span>)
      </h2>
    ) : (
      <h2>
        Your {side} was matched ({shares} share{shares > 1 && 's'}) @
        <span className="ConfirmTradePopup-price">${price}</span>
      </h2>
    );

    const description =
      side === 'bid'
        ? 'Once confirmed, those shares will appear in your portfolio, and the purchase will be initiated from your cash balance.'
        : 'Once confirmed, those shares will appear in your “Exited Positions,” and your funds will become available in your Cash Balance within 24 hours.';

    return (
      <Popup
        show={show}
        disableAutoClose
        onRequestClose={this.props.onCancel}
        className={`ConfirmTradePopup ${partial ? 'partial' : ''}`}
        contentClassName="ConfirmTradePopup-content"
        useDiv
      >
        <img className="ConfirmTradePopup-popper" src={partyPopperImage} alt="party-popper" />
        <h1>Congratulations!</h1>
        {subHeader}
        <p>
          To complete this trade, you must confirm your {side === 'bid' ? 'purchase ' : 'sale '}
          below.
        </p>
        <Button
          className="ConfirmTradePopup-main-btn"
          onClick={this.props.onConfirm}
          text="CONFIRM MY TRADE!"
        />
        <p>{description}</p>
        <div className="ConfirmTradePopup-cancel">
          <TextButton
            className="ConfirmTradePopup-cancel-btn"
            onClick={this.showRefuseWarning}
            text="Refuse this Trade"
          />
        </div>
      </Popup>
    );
  }
}

export default ConfirmTradePopup;
