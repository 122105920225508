import React from 'react';
import { SimpleComponent } from 'types/components';
import {
  CalendarItemContainer,
  CalendarTicker,
  CalendarImage,
  CalendarButton,
  CalendarAssetName,
  CalendarInfoContent,
} from './styled';
import { Asset } from 'types/assets';
import { withRouter, RouteComponentProps } from 'react-router';
import { getAssetRoute } from 'utils/navigation';
import history from 'services/history';
import Arrow from './icons/Arrow';
import { useDispatch } from 'react-redux';
import { assetsCategories } from 'constants/main';
import { setActiveAssetCategory, setActiveAsset, toggleAssetsDetails } from 'actions';
import { SEGMENT_EVENTS, SEGMENT_ACTIONS, SEGMENT_CATEGORIES } from 'constants/analytics';
import { track } from 'services/analytics';

type CalendarItemProps = {
  asset: Asset;
} & RouteComponentProps;

const LiveCalendarItem: SimpleComponent<CalendarItemProps> = ({ asset }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    const route = getAssetRoute(asset);
    const targetAssetCategory = assetsCategories[asset.category];

    dispatch(setActiveAssetCategory(targetAssetCategory));
    dispatch(setActiveAsset(asset));
    dispatch(toggleAssetsDetails(true));
    track(SEGMENT_EVENTS.CALENDAR_IO_ASSET_TAP, {
      action: SEGMENT_ACTIONS.OPEN,
      category: SEGMENT_CATEGORIES.INVESTMENT_CALENDAR,
      assetState: 'OPEN OFFERING',
      assetTicker: asset.ticker,
      assetName: asset.display_name,
    });
    history.push(route);
  };

  return (
    <CalendarItemContainer onClick={handleClick}>
      <CalendarImage url={asset.portal_image} />
      <CalendarInfoContent>
        <CalendarTicker color={asset.tertiary_color}>{asset.ticker}</CalendarTicker>
        <CalendarAssetName>{asset.short_name}</CalendarAssetName>
      </CalendarInfoContent>
      <CalendarButton>
        <Arrow />
      </CalendarButton>
    </CalendarItemContainer>
  );
};

export default withRouter(LiveCalendarItem);
