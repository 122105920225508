import React, { Component } from 'react';
import './SelectDot.css';

export class SelectDot extends Component {
  constructor(props) {
    super(props);
    this.onClickHandler = this.onClickHandler.bind(this);
  }

  onClickHandler(e) {
    this.props.onClickHandler(this.props.index || 0);
  }

  render() {
    let selectedClass = this.props.selected ? 'selected' : '';
    return <div className={'SelectDot ' + selectedClass} onClick={this.onClickHandler} />;
  }
}
