import React, { useCallback, useEffect, useState } from 'react';
import downArrow from 'images/home/down-arrow.svg';
import upArrow from 'images/home/up-arrow.svg';

import {
  HeroAssetPricing,
  HomeHeroContainer,
  HomeHeading,
  HomeHeroCard,
  CTAButton,
  HeroCardStatusContainer,
  HeroCardTimeRemaining,
  SoldOutText,
  SoldOutContent,
} from './styled';
import { Asset, Carousel, CarouselType } from 'services/HomeAPI.types';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { getSecondaryMarketEnableDiff } from 'utils';
import { ASSET_STATUS } from 'constants/main';
import { SecondaryMarketStatus } from 'types/assets';
import * as AssetsAPI from 'services/AssetsAPI';
import TradingCloseCountdown from 'components/views/app/Assets/Trading/Common/TradingCloseCountdown';
import ProgressBar from 'components/views/app/Assets/AssetDetails/scenes/MainPage/ProgressBar';
import { theme } from 'theme';
import { goToAssetItem } from 'components/RootRouter';
import { ConditionalSimpleComponent } from 'types/components';
import { useTracking } from 'hooks/useTracking';

const HomeHero: ConditionalSimpleComponent<{ assets: Asset[]; data: Carousel }> = ({
  data,
  assets,
}) => {
  const {
    enabled,
    items: [item],
    type,
  } = data;

  const { homeTracking } = useTracking();

  const storeAssets = useSelector<RootState, any>((store) => store.Assets);
  const [assetPricing, setAssetPricing] = useState<{
    showPrices: boolean;
    priceShift: number;
    priceShiftPercentage: number;
  } | null>(null);

  const isTrade = CarouselType.ASSET_TRADING_LIVE === type;
  let asset = assets?.find((asset) => asset.id === item.assetId);
  const storeAsset = storeAssets?.assetList?.find(
    (_asset: { id: string }) => _asset.id === asset?.id,
  ) as any;

  const [postOnlyOrLiveTrading, setPostOnlyOrLiveTrading] = useState({
    isPostOnly: false,
    isLiveTrading: false,
  });

  const _setPostOnlyOrLiveTrading = useCallback(() => {
    const { financialInstrument } = storeAsset ?? {};
    const { secondaryMarket } = financialInstrument?.markets ?? {};

    const timestampDiff = financialInstrument?.markets?.secondaryMarket?.enabled
      ? new Date(secondaryMarket.enabled).getTime() - Date.now()
      : 0;

    const isPostOnly =
      (storeAsset.asset_status === ASSET_STATUS.POST_ONLY && timestampDiff <= 0) ||
      (storeAsset.asset_status === ASSET_STATUS.TRADING_OPENED &&
        financialInstrument.markets.secondaryMarket.status === SecondaryMarketStatus.CLOSED);

    const isLiveTrading =
      storeAsset.asset_status === ASSET_STATUS.TRADING_OPENED &&
      financialInstrument.markets.secondaryMarket.status === SecondaryMarketStatus.OPEN;

    return {
      isPostOnly,
      isLiveTrading,
    };
  }, [storeAsset]);

  useEffect(() => {
    setPostOnlyOrLiveTrading(_setPostOnlyOrLiveTrading());
  }, [storeAsset, _setPostOnlyOrLiveTrading]);

  const updatePriceShift = useCallback(async () => {
    const activeAsset = storeAssets?.assetList?.find(
      (_asset: { id: string }) => _asset.id === asset?.id,
    );

    const assetPricing = (await AssetsAPI.getSecurityPricing(
      activeAsset?.financialInstrument?.id,
    )) as any;

    const { secondaryMarket } = activeAsset.financialInstrument?.markets ?? {};

    const timestampDiff = getSecondaryMarketEnableDiff(secondaryMarket);
    // @ts-ignore
    // prettier-ignore
    const showPrices = (activeAsset?.asset_status === ASSET_STATUS.POST_ONLY && timestampDiff <= 0) ||
        activeAsset?.asset_status === ASSET_STATUS.TRADING_CLOSED ||
        (activeAsset?.asset_status === ASSET_STATUS.TRADING_OPENED &&
            secondaryMarket.status !== SecondaryMarketStatus.OPEN);

    const pricePerUnit = assetPricing.data.pricePerUnit;
    let price;
    let priceShift = 0;
    let priceShiftPercentage = 0;
    price =
      (pricePerUnit?.current || pricePerUnit?.session?.lastClose) * activeAsset?.number_of_shares;
    if (!price) {
      setAssetPricing({
        showPrices,
        priceShift,
        priceShiftPercentage,
      });
      return;
    }
    priceShift = price - activeAsset?.asset_value;
    priceShiftPercentage =
      (priceShift / (pricePerUnit?.issue * activeAsset?.number_of_shares)) * 100;
    setAssetPricing({
      showPrices,
      priceShift,
      priceShiftPercentage,
    });
    return;
  }, [asset, storeAssets]);

  useEffect(() => {
    (async () => updatePriceShift())();
  }, [asset, updatePriceShift]);

  const setHeading = () => {
    switch (type) {
      case CarouselType.ASSET_INITIAL_OFFERING:
        return 'Initial Offering Now Open';

      case CarouselType.ASSET_TRADING_LIVE:
        return postOnlyOrLiveTrading.isPostOnly
          ? 'Accepting Orders Now!'
          : 'Trading Now — Act Fast!';

      default:
        return '';
    }
  };

  const assetDateClose =
    asset?.financialInstrument?.markets?.secondaryMarket?.sessionHours?.dateClose;

  const HeroCardStatus = () => {
    return (
      <HeroCardStatusContainer isTrade={isTrade}>
        <p>
          {isTrade
            ? postOnlyOrLiveTrading.isLiveTrading
              ? 'Trading now'
              : 'Accepting Orders'
            : 'Initial offering'}
        </p>
      </HeroCardStatusContainer>
    );
  };

  const handleTracking = async () => {
    switch (type) {
      case CarouselType.ASSET_INITIAL_OFFERING:
        return await homeTracking.homeIOHeroAssetTapped(asset?.id, asset);

      case CarouselType.ASSET_TRADING_LIVE:
        return await homeTracking.homeTradingHeroAssetTapped(asset?.id, asset);

      default:
        return '';
    }
  };

  const renderProgressBar = () => {
    const progressBar= asset?.progressBar ?? 0;
    const percent = Math.min(Math.floor(progressBar), 100);
    let progressBarMessage = `${percent}% FUNDED`;
    if (percent > 50) progressBarMessage = `${100 - percent}% REMAINING`;
    if (percent === 100) {
      return (
        <SoldOutContent>
          <SoldOutText>SOLD OUT </SoldOutText>{' '}| 100% FUNDED
        </SoldOutContent>
      );
    }
    return (
      <ProgressBar
        height={10}
        scale={1}
        progress={asset?.progressBar}
        message={progressBarMessage}
        fontColor={theme.colors.grey}
        isHome
      />
    )
  };

  return enabled ? (
    <HomeHeroContainer
      to={goToAssetItem(asset, { pathname: '/app/home' })}
      onClick={handleTracking}
    >
      <HomeHeading>{setHeading()}</HomeHeading>
      <HomeHeroCard>
        <HeroCardTimeRemaining show={isTrade && postOnlyOrLiveTrading?.isLiveTrading}>
          <p>
            Time Remaining: <TradingCloseCountdown closingTime={assetDateClose} />
          </p>
        </HeroCardTimeRemaining>
        <div className={'left'}>
          <HeroCardStatus />
          <img
            src={storeAsset?.portal_image || asset?.media[0]?.thumbnail}
            alt={'asset thumbnail'}
          />
        </div>
        <div className={'right'}>
          <h1>{asset?.shortName}</h1>

          {isTrade ? (
            <>
              <h2>Last Traded Price</h2>
              <div>
                <h1>${Number(asset?.currentPricePerShare).toFixed(2)}</h1>
                {assetPricing?.priceShift !== 0 && (
                  <img
                    src={Math.sign(assetPricing?.priceShift || 0) > 0 ? upArrow : downArrow}
                    alt={'sign arrow'}
                  />
                )}
              </div>
              <HeroAssetPricing sign={Math.sign(assetPricing?.priceShiftPercentage || 0)}>
                {Math.sign(assetPricing?.priceShift || 0) > 0 ? '+' : ''}
                {Number(assetPricing?.priceShiftPercentage).toFixed(2)}% SINCE INITIAL OFFERING
              </HeroAssetPricing>{' '}
            </>
          ) : (
            <>
              <h2>Price Per Share</h2>
              <div>
                <h1>${Number(asset?.currentPricePerShare).toFixed(2)}</h1>
              </div>
              {renderProgressBar()}
            </>
          )}
        </div>
        <div className={'footer'}>
          <CTAButton to={goToAssetItem(asset, { pathname: '/app/home' })}>
            {isTrade ? 'TRADE' : 'INVEST'}
          </CTAButton>
        </div>
      </HomeHeroCard>
    </HomeHeroContainer>
  ) : null;
};

export default HomeHero;
