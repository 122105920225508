import React, { Component } from 'react';

import { Popup } from 'components/shared/Popup';
import { SecondaryButton } from 'components/shared/Buttons/SecondaryButton';
import analytics from 'services/analytics';

import TRADING_INFO from 'images/trading-info.png';

import './TradingInfoPopup.scss';
import { SEGMENT_CATEGORIES, SEGMENT_ACTIONS } from 'constants/analytics';

export default class TradingInfoPopup extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.show && prevProps.show !== this.props.show) {
      analytics.track('How It Works Screen', {
        category: SEGMENT_CATEGORIES.TRADING_WINDOW,
        action: SEGMENT_ACTIONS.OPEN,
      });
    }
  }

  render() {
    return (
      <Popup className="TradingInfoPopup" {...this.props}>
        <span className="TradingInfoPopup-body">
          <h2 className="TradingInfoPopup-title">
            To buy shares you'll need to make an offer to buy, known as a{' '}
            <span className="TradingInfoPopup-emphasis">Bid</span>
          </h2>
          <img src={TRADING_INFO} alt="trading-info" className="TradingInfoPopup-img" />
          <p>
            The <b>"Clearing Price"</b> is the price at which the maximum number of bids and asks
            are matched. The "current clearing price" represents the closing price if the Trading
            Window were to end at this exact moment. When you place a bid, you'll need to find a
            price you are comfortable paying for your shares and be sure to check back throughout
            the day.
          </p>
          <SecondaryButton text="OKAY, GOT IT!" onClick={this.props.onClick} />
          <span className="TradingInfoPopup-footer">
            For additional information on please check out{' '}
            <a href="https://rallyrd.com/faq/" target="_blank" rel="noopener noreferrer">
              FAQS
            </a>{' '}
            and{' '}
            <a
              href="https://www.sec.gov/cgi-bin/browse-edgar?CIK=0001688804&amp;action=getcompany"
              target="_blank"
              rel="noopener noreferrer"
            >
              SEC
            </a>{' '}
            documents.
          </span>
        </span>
      </Popup>
    );
  }
}
