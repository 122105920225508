import React from 'react';

import './Preloader.css';

export default props => (
  <div className="Preloader" style={props.isLoaded ? { display: 'none' } : {}}>
    <div className="Preloader-box">
      <div className="Preloader-box-top"></div>
      <div className="Preloader-box-bottom">
        <div className="Preloader-box-bottom-left"></div>
        <div className="Preloader-box-bottom-right"></div>
      </div>
    </div>
  </div>
);
