import React from 'react';
import PropTypes from 'prop-types';

import './DotsLoader.scss';

const DotsLoader = ({ size = 14, color = '#41c84b', style = {}, dotStyle }) => {
  const dotStyles = {
    width: size,
    height: size,
    margin: Math.round(size / 4),
    backgroundColor: color,
    ...dotStyle,
  };
  return (
    <div className="DotsLoader" style={style}>
      <span className="DotsLoader-dot" style={dotStyles} />
      <span className="DotsLoader-dot" style={dotStyles} />
      <span className="DotsLoader-dot" style={dotStyles} />
    </div>
  );
};

DotsLoader.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.object,
  dotStyle: PropTypes.object,
};

export default DotsLoader;
