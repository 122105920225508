import React from 'react';

import { Button } from 'components/shared/Buttons/Button';
import { Price } from 'components/shared/Price';

import './BuySharesButton.css';

const BuySharesButton = props => {
  const price = props.asset.asset_value / props.asset.number_of_shares;

  const buttonContents = props.earlyAccess ? (
    <span>
      <b>
        <Price price={price} />
      </b>
      <span className="button-divider"> | </span>
      <b>BUY SHARES (EARLY ACCESS)</b>
    </span>
  ) : (
    <span>
      <b>BUY SHARES</b>
      <span className="button-divider"> | </span>
      <b>
        <Price price={price} />
      </b>
    </span>
  );

  return (
    <div className="BuySharesButton">
      <Button text={buttonContents} onClick={props.buyShares} />
    </div>
  );
};

export default BuySharesButton;
