import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { isEmpty } from 'utils';
import { Button, TextButton } from 'components/shared/Buttons';
import { Price } from 'components/shared/Price';
import { DotsLoader } from 'components/shared/Loaders';
import { ACCREDITATION_STATUSES_TEXT } from 'constants/main';

import './InvestingPassport.scss';
import { employmentOptions } from '../EmploymentStatus/EmploymentStatus';

const InvestingPassport = ({
  data,
  onFormUpdate,
  onStepForward,
  restartPassport,
  shouldSkipUpdate,
}) => {
  const {
    first_name,
    last_name,
    phone_number,
    address,
    city,
    state,
    postal_code,
    accredited_status,
    sec_or_finra_organization_name,
    publicly_traded_company_tickers,
    ssn,
    pretaxAnnualIncome,
    liquidNetWorth,
    employmentStatus,
    riskToleranceLevel,
  } = data;
  const { user, updatingUserData } = useSelector(state => state.Auth);
  const { investmentFlow } = useSelector(state => state.Investments);

  const handleContinueClick = () => {
    onFormUpdate({ investingPassportApplied: true }, onStepForward);
  };

  const handleEditProfileClick = () => {
    onFormUpdate({ investingPassportApplied: false }, restartPassport);
  };

  useEffect(() => {
    if (shouldSkipUpdate) return;
    // Update the passport data with relevant profile data:
    if (!updatingUserData) {
      onFormUpdate({
        first_name: user.first_name,
        last_name: user.last_name,
        phone_number: user.phone_number,
        address: user.address,
        city: user.city,
        state: user.state,
        postal_code: user.postal_code,
        ...user.investment_info,
      });
    }
  }, [user, updatingUserData, onFormUpdate, shouldSkipUpdate]);

  let investmentObj = {
    accredited_status,
    sec_or_finra_organization_name,
    publicly_traded_company_tickers,
  };
  if (!isEmpty(investmentFlow.investment_info)) {
    investmentObj = { ...investmentFlow.investment_info };
  }
  return (
    <div className="InvestingPassport">
      <div className="InvestingPassport-wrapper">
        <h2 className="InvestingPassport-title">Confirm these details to continue</h2>
        <div className="InvestingPassport-items">
          <div className="InvestingPassport-item">
            <div className="InvestingPassport-item-title">Basic info</div>
            <div className="InvestingPassport-item-content">
              {first_name} {last_name}
            </div>
            <div className="InvestingPassport-item-content">{phone_number}</div>
            <div className="InvestingPassport-item-content">
              {address}, {city} {state}, {postal_code}
            </div>
          </div>
          <div className="InvestingPassport-item">
            <div className="InvestingPassport-item-title">Last 4 digits of SSN</div>
            <div className="InvestingPassport-item-content">{ssn.slice(-4)}</div>
          </div>
          <div className="InvestingPassport-item">
            <div className="InvestingPassport-item-content">
              {ACCREDITATION_STATUSES_TEXT[investmentObj.accredited_status]}
            </div>
          </div>
          <div className="InvestingPassport-item">
            <div className="InvestingPassport-item-content">
              {!investmentObj.sec_or_finra_organization_name ? 'Neither' : 'Either'} I{' '}
              {!investmentObj.sec_or_finra_organization_name ? 'nor' : 'or'} a member of my
              household is associated with a FINRA member, organization, or the SEC
            </div>
          </div>
          <div className="InvestingPassport-item">
            <div className="InvestingPassport-item-content">
              {!investmentObj.publicly_traded_company_tickers ? 'Neither' : 'Either'} I{' '}
              {!investmentObj.publicly_traded_company_tickers ? 'nor' : 'or'} a member of my
              household or immediate family is a 10% shareholder, officer, or member of the board of
              directors of a publicly traded company
            </div>
          </div>
          <div className="InvestingPassport-item">
            <div className="InvestingPassport-item-content">
              Employment Status:{' '}
              {employmentStatus
                ? employmentOptions.find(({ value }) => value === employmentStatus)?.text
                : ''}
            </div>
          </div>
          <div className="InvestingPassport-item">
            <div className="InvestingPassport-item-content">
              Annual Income: <Price price={pretaxAnnualIncome} />
            </div>
            <div className="InvestingPassport-item-content">
              Net Worth: <Price price={liquidNetWorth} />
            </div>
          </div>
          <div className="InvestingPassport-item">
            <div className="InvestingPassport-item-content">
              Risk Profile: {riskToleranceLevel?.[0] + riskToleranceLevel?.slice(1).toLowerCase()}
            </div>
          </div>
        </div>
      </div>
      <div className="InvestingPassport-footer">
        {updatingUserData ? (
          <DotsLoader />
        ) : (
          <>
            <Button onClick={handleContinueClick}>Continue</Button>
            <br />
            <TextButton onClick={handleEditProfileClick}>Edit this info</TextButton>
          </>
        )}
      </div>
    </div>
  );
};

InvestingPassport.propTypes = {
  data: PropTypes.object,
  onStepForward: PropTypes.func.isRequired,
  onFormUpdate: PropTypes.func.isRequired,
  shouldSkipUpdate: PropTypes.bool,
};

export default InvestingPassport;
