import React, { useEffect } from 'react';
import { HomeContainer, Spacer, HomeWelcomeHeading } from './styled';
import { ConditionalSimpleComponent } from 'types/components';
import { HomeBanner, HomeCarousel, HomeHero } from './index';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { getHomeAssets } from 'actions';
import { CarouselType, IHomeAPI } from 'services/HomeAPI.types';
import { isEmpty } from 'utils';
import { useTracking } from 'hooks/useTracking';

const Home: ConditionalSimpleComponent<any> = (props) => {
  const { homeTracking } = useTracking();
  useEffect(() => {
    homeTracking.homeViewed();
    return () => {
      homeTracking.homeClosed();
    };
  }, [homeTracking]);

  const dispatch = useDispatch();
  // @ts-ignore
  const homeAssets = useSelector<RootState, IHomeAPI>((store) => store.Assets?.homeAssets);
  const user = useSelector<RootState, any>((store) => store.Auth?.user);
  const { sections, features, data } = homeAssets ?? {};

  useEffect(() => {
    dispatch(getHomeAssets());
  }, [dispatch]);

  if (!Object.keys(homeAssets).length) return null;

  const sortHero = () => {
    return (sections?.highlights ?? []).sort((item) => {
      switch (item.type) {
        case CarouselType.ASSET_INITIAL_OFFERING:
          return 1;
        case CarouselType.ASSET_TRADING_LIVE:
          return -1;

        default:
          return 0;
      }
    });
  };

  const sortCarousel = () => {
    return (sections?.carousels ?? []).sort((item) => {
      switch (item.type) {
        case CarouselType.ASSET_OFFERING:
          return 1;
        case CarouselType.ASSET_TRADING:
          return -1;

        default:
          return 0;
      }
    });
  };

  return (
    <HomeContainer>
      <HomeWelcomeHeading>
        {user?.first_name ? `Welcome, ${user?.first_name}` : 'Welcome'}{' '}
      </HomeWelcomeHeading>
      {!isEmpty(sections?.banners) && <HomeBanner data={sections.banners} assets={data?.assets} />}
      <div className={'heroes'}>
        {sortHero().map((section, index) => {
          return (
            <>
              <HomeHero data={section} assets={data.assets} />
              {!index && <Spacer />}
            </>
          );
        })}
      </div>
      {sortCarousel().map((carouselData, index) => {
        return (
          <>
            <HomeCarousel
              data={carouselData}
              enableCalendar={features?.calendar?.enabled}
              assets={data.assets}
            />
            {!index && <Spacer />}
          </>
        );
      })}
    </HomeContainer>
  );
};

export default Home;
