import React, { Component } from 'react';

import { SelectDot } from './SelectDot';

import './SelectDots.css';

export class SelectDots extends Component {
  constructor(props) {
    super(props);
    this.onDotSelected = this.onDotSelected.bind(this);
    this.numberOfDots = this.props.number || 0;
  }

  onDotSelected(index) {
    this.props.onSelected(index);
  }

  render() {
    let dots = Array(this.numberOfDots)
      .fill()
      .map((_, index) => (
        <div className="SelectDots-dot" key={index}>
          <SelectDot
            index={index}
            selected={index === this.props.selected}
            onClickHandler={this.onDotSelected}
          />
        </div>
      ));

    return <div className="SelectDots">{dots}</div>;
  }
}
