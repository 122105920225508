import styled from '@emotion/styled';

export const CalendarListContainer = styled.div<{ scrollDisabled?: boolean }>(
  ({ scrollDisabled }) => ({
    overflow: scrollDisabled ? 'visible' : 'auto',
  }),
);

export const CalendarListTitle = styled.h5(({ theme }) => ({
  fontFamily: 'Gotham Medium',
  fontWeight: '500',
  fontSize: '4.3vmin',
  padding: '5.8vmin 0 2.4vmin',
  margin: 0,
  [theme.breakpoints.md]: {
    fontSize: '2vmin',
    padding: '2.4vmin 0 1.2vmin',
  },
}));
