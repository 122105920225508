import React, { Component } from 'react';
import { matchPath, withRouter } from 'react-router';
import withDeviceDetection from 'hoc/withDeviceDetection';
import { connect } from 'react-redux';
import classNames from 'classnames';

import AssetsAnimeList from './AssetsAnimeList';
import AssetsMenuItem from './AssetsMenuItem';
import './AssetsMenuList.scss';
import AssetsSearchResultList from './AssetsSearchResultList';

class AssetsMenuList extends Component {
  assetListContainerRef = React.createRef(null);

  componentDidMount() {
    if (this.props.registerResetScrollCallback) {
      this.props.registerResetScrollCallback(this.resetScroll);
    }
    this.scrollToActiveAsset();
  }

  componentDidUpdate(prevProps) {
    const { isAssetsSearchActive } = this.props;
    const { pathname } = prevProps.location;
    const scrollNeededRoutes = ['/app/home', '/app/investments-calendar'];
    if (scrollNeededRoutes.includes(pathname)) {
      return this.scrollToActiveAsset(prevProps.history.location.assetTicker);
    }

    if (prevProps.isAssetsSearchActive && !isAssetsSearchActive) {
      this.scrollToActiveAsset();
    }
  }

  scrollToActiveAsset = (assetTicker = false) => {
    let { activeAsset, userDevice } = this.props;

    const listRef = this.assetListContainerRef.current;
    if (!listRef || userDevice.isMobile) {
      return;
    }
    const assetElement = document.getElementById(assetTicker ? assetTicker : activeAsset.ticker);

    listRef.scrollTo({
      top: assetElement?.offsetTop || 0,
      behavior: 'smooth',
    });
  };

  resetScroll = () => {
    if (this.assetListContainerRef.current) {
      this.assetListContainerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  renderList = () => {
    const {
      assetList,
      handleSlideSwitch,
      activeAsset,
      displayAssetsMenu,
      userDevice,
      shouldShowAssetList,
    } = this.props;

    const { isMobile } = userDevice;

    if (isMobile) {
      return (
        <AssetsAnimeList
          assetListStatus={displayAssetsMenu}
          assetList={assetList}
          handleSlideSwitch={handleSlideSwitch}
          activeAsset={activeAsset}
        />
      );
    }
    if (shouldShowAssetList) {
      return <AssetsSearchResultList containerRef={this.assetListContainerRef} />;
    }

    return assetList.map((asset, index) => (
      <div id={asset.ticker} key={asset.id}>
        <AssetsMenuItem
          index={index}
          asset={asset}
          isActive={
            this.props.activeAsset.id === asset.id && this.props?.location?.pathname !== '/app/home'
          }
          onClick={handleSlideSwitch}
        />
      </div>
    ));
  };

  render() {
    const { assetsOverlayVisible, displayAssetsMenu, userDevice, detailsOpened } = this.props;

    const isAssetPath = matchPath(this.props.history.location.pathname, {
      path: '/app/assets/:category/:ticker',
      exact: true,
      strict: false,
    });

    return (
      <div
        ref={this.assetListContainerRef}
        className={classNames('AssetList', {
          'mobile-visible': userDevice.isMobile && displayAssetsMenu,
          'mobile-hidden':
            (userDevice.isMobile && !displayAssetsMenu) || detailsOpened || !isAssetPath,
        })}
      >
        <div className={`AssetList-items${assetsOverlayVisible ? ' loading' : ''}`}>
          {this.renderList()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  displayAssetsMenu: state.UI.displayAssetsMenu,
  assetsOverlayVisible: state.UI.assetsOverlayVisible,
  activeAsset: state.Assets.activeAsset,
  activeAssetCategory: state.Assets.activeAssetCategory,
  detailsOpened: state.Assets.detailsOpened,
  assetList: state.Assets.assetCategoryLists[state.Assets.activeAssetCategory.key],
  searchResultList: state.Assets.searchResultList,
  shouldShowAssetList: state.Assets.shouldShowAssetList,
  isAssetsSearchActive: state.Assets.isAssetsSearchActive,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withDeviceDetection(AssetsMenuList)));
