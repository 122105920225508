import React from 'react';
import PropTypes from 'prop-types';
import { TextButton } from 'components/shared/Buttons/TextButton';

import './FormCloseModal.scss';

/**
 * Controlled dialog component to show before closing forms
 */
const FormCloseModal = ({ visible, message, onAccept, onDecline }) => {
  if (!visible) return null;

  return (
    <div className="FormCloseModal">
      <div className="FormCloseModal-content">
        <div className="FormCloseModal-text">{message}</div>
        <TextButton onClick={onAccept}>FINISH IT NOW</TextButton>
        <br />
        <TextButton className="FormCloseModal-finishlaterbutton" onClick={onDecline}>
          No Thanks, I'll do it later
        </TextButton>
      </div>
    </div>
  );
};

FormCloseModal.propTypes = {
  visible: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onAccept: PropTypes.func,
  onDecline: PropTypes.func,
};

FormCloseModal.defaultProps = {
  visible: false,
  message:
    'You will need to fill this information out before investing on Rally, but you can always access it later through your profile in the top left of the home screen.',
  onAccept: () => null,
  onDecline: () => null,
};

export default FormCloseModal;
