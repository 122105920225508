import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import ContentHeader from 'components/shared/ContentHeader';
import InvestmentsList from './InvestmentsList';
import MonetaryData from './MonetaryData';
import DepositWithdrawal from './DepositWithdrawal';
import FundingSourceDeletePopup from './FundingSourceDeletePopup';
import DocumentUploadPopup from './DocumentUploadPopup';
import MicroDepositVerification from './MicroDepositVerification';
import Receipts from './Receipts';
import Statements from './Statements';
import GainLoss from './GainLoss';
import VerifyEmailPopup from 'components/shared/VerifyEmailPopup';
import StateAvailabilityError from 'components/shared/StateAvailabilityError';
import DwollaStatusPopup from 'components/shared/DwollaStatusPopup';
import AppBuildNumber from 'components/shared/AppBuildNumber';
import { FUNDING_SOURCE_STATUS, DWOLLA_CUSTOMER_STATUS } from 'constants/main';
import analytics from 'services/analytics';
import { isEmpty, setPageTitle } from 'utils';
import CONFIG, { IS_PRODUCTION } from 'config';
import * as AssetsAPI from 'services/AssetsAPI';

import {
  getUserData,
  getTradingDates,
  getBalance,
  getInvestments,
  getGainLoss,
  getReceipts,
  getBankFundingSource,
  checkMicroDeposits,
  setInvestmentError,
  getDwollaCustomerStatus,
  getOrders,
} from 'actions';
import { SEGMENT_CATEGORIES, SEGMENT_ACTIONS } from 'constants/analytics';
import { getUserPositions } from 'actions/investments';

class Investments extends Component {
  state = {
    showDepositWithdrawalPopup: false,
    showFundingSourceDeletePopup: false,
    showDocumentUploadPopup: false,
    showStateAvailabilityError: false,
    showMicroDepositsPopup: false,
    isMicroDepositsChecked: false,
    showVerifyEmailPopup: false,
    showDwollaStatusPopup: false,
    investmentsToShow: [],
    ordersToShow: [],
    loading: false,
  };

  abortController = new AbortController();

  async componentDidMount() {
    this.props.getDwollaCustomerStatus();
    this.props.getInvestmentData();
    setPageTitle('My Portfolio');

    // Analytics
    analytics.track('My Portfolio View', {
      category: SEGMENT_CATEGORIES.MY_PORTFOLIO,
      action: SEGMENT_ACTIONS.OPEN,
    });

    if (this.props.location.pathname.indexOf('/microdeposits') !== -1) {
      this.setState({ showMicroDepositsPopup: true });
    } else if (this.props.location.pathname.indexOf('/deposit-withdrawal') !== -1) {
      this.toggleDepositWithdrawalPopup();
    }

    const { investmentsData, assetList, userOrders } = this.props;

    if (assetList) {
      this.setState({ loading: true });
      if (investmentsData?.user_investments)
        await this.getInvestmentsList(investmentsData.user_investments, assetList);
      if (userOrders) await this.getOrdersList(userOrders, assetList);
      this.setState({ loading: false });
    }
  }

  componentWillReceiveProps(newProps) {
    if (
      newProps.investmentsData.errors !== null ||
      newProps.investmentsData.bankFundingSource.info.status === FUNDING_SOURCE_STATUS.VERIFIED
    ) {
      this.setState({
        isMicroDepositsChecked: true,
      });
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  componentDidUpdate(prevProps) {
    const receivedNewAssetList =
      JSON.stringify(prevProps.assetList) !== JSON.stringify(this.props.assetList);
    const receivedNewInvestments =
      JSON.stringify(prevProps.investmentsData.user_investments) !==
      JSON.stringify(this.props.investmentsData.user_investments);
    const receivedNewOrders =
      JSON.stringify(prevProps.userOrders) !== JSON.stringify(this.props.userOrders);

    if (receivedNewInvestments || receivedNewAssetList)
      this.getInvestmentsList(this.props.investmentsData.user_investments, this.props.assetList);
    if (receivedNewOrders || receivedNewAssetList)
      this.getOrdersList(this.props.userOrders, this.props.assetList);
  }

  getInvestmentsList = async (investments, assetList) => {
    if (investments.length <= 0) return [];

    const investmentsToShow = await Promise.all(
      investments.map(async investment => {
        const investmentAsset = await assetList.find(asset => asset.id === investment.asset);
        if (!investmentAsset) return null;

        const { data } = await AssetsAPI.getSecurityPricing(
          investmentAsset.financialInstrument?.id,
        );
        return {
          ...investment,
          ...investmentAsset,
          ...data,
        };
      }),
    )
      .then(result => {
        return result.filter(investment => !!investment);
      })
      .catch(e => {
        if (e.name === 'AbortError') return;
        throw e;
      });

    this.setState({ investmentsToShow });
  };

  getOrdersList = async (orders, assetList) => {
    if (orders.length <= 0) return [];

    const ordersToShow = await Promise.all(
      orders.map(async order => {
        const orderAsset = await assetList.find(
          asset => asset.financialInstrument.id === order.financialInstrumentId,
        );
        if (!orderAsset) return null;

        const { data } = await AssetsAPI.getSecurityPricing(orderAsset.financialInstrument?.id);
        return {
          ...orderAsset,
          ...order,
          ...data,
        };
      }),
    )
      .then(result => {
        return result.filter(order => !!order);
      })
      .catch(e => {
        if (e.name === 'AbortError') return;
        throw e;
      });

    this.setState({ ordersToShow });
  };

  toReceipts = () => this.props.history.push('/app/investments/transaction');
  toStatements = () => this.props.history.push('/app/investments/statements');

  handleTransferInitiated = () => {
    this.props.getReceipts();
    this.props.getBalance();
  };

  sendMicroDeposits = payload => {
    this.props.setInvestmentError(null);
    this.props.checkMicroDeposits(payload);
  };

  async isEmailVerifiedAsync() {
    const { emails } = await this.props.getUserData({ showGlobalLoader: false });
    return emails && !!emails.find(email => email.primary && email.verified);
  }

  toggleDepositWithdrawalPopup = async () => {
    const { user, investmentsData, dwollaCustomerStatus } = this.props;

    if (this.state.showDepositWithdrawalPopup) {
      return this.setState({ showDepositWithdrawalPopup: false });
    }
    if (!(await this.isEmailVerifiedAsync())) {
      return this.toggleVerifyEmailPopup();
    }
    if (user.state && user.state_status.is_state_blocked) {
      return this.setState({ showStateAvailabilityError: true });
    }
    if (dwollaCustomerStatus === DWOLLA_CUSTOMER_STATUS.SUSPENDED) {
      return this.setState({ showDwollaStatusPopup: true });
    }
    // If bank funding source is not created, navigate user to fund account route:
    if (isEmpty(investmentsData.bankFundingSource.info)) {
      if (dwollaCustomerStatus === DWOLLA_CUSTOMER_STATUS.VERIFIED) {
        return this.props.history.push('/app/fund-account');
      } else {
        return this.props.history.push('/app/onboarding');
      }
    }

    this.setState({ showDepositWithdrawalPopup: !this.state.showDepositWithdrawalPopup });
  };

  toggleMicroDepositsPopup = () => {
    this.setState({
      showMicroDepositsPopup: !this.state.showMicroDepositsPopup,
      isMicroDepositsChecked: this.state.showMicroDepositsPopup,
    });
  };

  toggleVerifyEmailPopup = () => {
    this.setState({ showVerifyEmailPopup: !this.state.showVerifyEmailPopup });
  };

  toggleDwollaStatusPopup = () => {
    this.setState({ showDwollaStatusPopup: !this.state.showDwollaStatusPopup });
  };

  toggleFundingSourceDeletePopup = () =>
    this.setState({
      showFundingSourceDeletePopup: !this.state.showFundingSourceDeletePopup,
      showDepositWithdrawalPopup: false,
    });

  toggleDocumentUploadPopup = () =>
    this.setState({
      showDocumentUploadPopup: !this.state.showDocumentUploadPopup,
    });

  closeInvestments = () => this.props.history.push('/app');

  render() {
    const { investmentsToShow, ordersToShow, loading } = this.state;
    if (!this.props.assetList.length) return null;

    if (this.state.showStateAvailabilityError) {
      return (
        <div style={{ height: '100%' }}>
          <ContentHeader>INVESTMENTS & POSITIONS</ContentHeader>
          <StateAvailabilityError onClose={this.closeInvestments} />
        </div>
      );
    }

    return (
      <div className="Investments">
        <ContentHeader>MY PORTFOLIO</ContentHeader>
        {!IS_PRODUCTION && <AppBuildNumber />}
        <Switch>
          <Route
            exact
            path="/(app/investments|app/investments/microdeposits|app/investments/deposit-withdrawal)/"
            render={() => (
              <div>
                <MonetaryData
                  dwollaCustomerStatus={this.props.dwollaCustomerStatus}
                  balance={this.props.investmentsData.balance}
                  toReceipts={this.toReceipts}
                  toStatements={this.toStatements}
                  toDocument={this.toggleDocumentUploadPopup}
                  toggleDepositWithdrawalPopup={this.toggleDepositWithdrawalPopup}
                  toggleMicroDepositsPopup={this.toggleMicroDepositsPopup}
                  isVerificationNeeded={
                    this.props.investmentsData.bankFundingSource.info.status ===
                    FUNDING_SOURCE_STATUS.UNVERIFIED
                  }
                />
                {CONFIG.featureFlags.gainLossEnabled && (
                  <GainLoss data={this.props.investmentsData.gainLoss} />
                )}
                <InvestmentsList
                  investments={investmentsToShow}
                  orders={ordersToShow}
                  loading={loading}
                />
              </div>
            )}
          />
          <Route
            exact
            path="/app/investments/transaction"
            render={props => (
              <Receipts
                {...props}
                transfers={this.props.investmentsData.transfers}
                orders={ordersToShow}
              />
            )}
          />
          <Route exact path="/app/investments/statements" component={Statements} />
        </Switch>
        <DepositWithdrawal
          show={this.state.showDepositWithdrawalPopup}
          balance={this.props.investmentsData.balance}
          onRequestClose={this.toggleDepositWithdrawalPopup}
          onSourceDelete={this.toggleFundingSourceDeletePopup}
          account={this.props.investmentsData.bankFundingSource.info}
          onTransferInitiated={this.handleTransferInitiated}
        />
        <MicroDepositVerification
          show={this.state.showMicroDepositsPopup}
          onRequestClose={this.toggleMicroDepositsPopup}
          isMicroDepositsChecked={this.state.isMicroDepositsChecked}
          sendMicroDeposits={this.sendMicroDeposits}
          errors={this.props.investmentsData.errors}
          hasErrors={!!this.props.investmentsData.errors}
        />
        <FundingSourceDeletePopup
          show={this.state.showFundingSourceDeletePopup}
          onRequestClose={this.toggleFundingSourceDeletePopup}
          onAfterDelete={this.props.getInvestmentData}
        />
        <DocumentUploadPopup
          show={this.state.showDocumentUploadPopup}
          onRequestClose={this.toggleDocumentUploadPopup}
        />
        <VerifyEmailPopup
          show={this.state.showVerifyEmailPopup}
          text={
            'In order to fund your account on Rally you must verify your email, ' +
            this.props.user.email
          }
          email={this.props.user.email}
          onRequestClose={this.toggleVerifyEmailPopup}
        />
        {this.state.showDwollaStatusPopup && (
          <DwollaStatusPopup
            status={this.props.dwollaCustomerStatus}
            onClick={this.toggleDwollaStatusPopup}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  investmentsData: state.Investments,
  dwollaCustomerStatus: state.Auth.dwollaCustomerStatus,
  assetList: state.Assets.assetList,
  user: state.Auth.user,
  userOrders: state.Trading.userOrders,
  nonOpenTradingWindowOrders: state.Trading.nonOpenTradingWindowOrders,
});

const mapDispatchToProps = dispatch => ({
  getInvestmentData: () => {
    dispatch(getBalance());
    dispatch(getInvestments());
    dispatch(getGainLoss());
    dispatch(getReceipts());
    dispatch(getBankFundingSource());
    dispatch(getTradingDates());
    dispatch(getOrders());
    dispatch(getUserPositions());
  },
  getReceipts: () => dispatch(getReceipts()),
  getBalance: () => dispatch(getBalance()),
  checkMicroDeposits: payload => dispatch(checkMicroDeposits(payload)),
  setInvestmentError: payload => dispatch(setInvestmentError(payload)),
  getUserData: payload => dispatch(getUserData(payload)),
  getDwollaCustomerStatus: () => dispatch(getDwollaCustomerStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Investments);
