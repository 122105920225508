import styled from '@emotion/styled';
import { StyledWithActiveProp } from 'types/components';

export const TabsContainer = styled.div(({ theme }) => ({
  paddingTop: '8.2vh',
  maxWidth: '100%',
  width: 350,
  margin: '0 auto',
  marginBottom: '3.8vh',
  position: 'relative',
  overflow: 'hidden',
  [theme.breakpoints.sm]: {
    paddingTop: '4.2vh',
  },
  [theme.breakpoints.md]: {
    minWidth: 320,
    width: '43%',
  },
}));

export const Tab = styled.button<StyledWithActiveProp>(({ active }) => ({
  background: 'none',
  border: 'none',
  width: '50%',
  cursor: 'pointer',
  color: active ? '#fff' : '#AFAFAF',
  padding: '0.06vh 3.4vh',
  letterSpacing: '0.6px',
  transition: 'all 0.15s',
  opacity: 1,
  '&:active': {
    opacity: 0.8,
  },
}));

export const TabTitle = styled.div({
  fontFamily: 'Gotham Bold',
  fontWeight: 700,
  fontSize: '1.7vh',
  textTransform: 'uppercase',
});

export const TabDescription = styled.div({
  fontFamily: 'Gotham Book',
  fontSize: '1.5vh',
  marginBottom: '0.67vh',
});

export const TabIndicator = styled.div<StyledWithActiveProp>(({ active, theme }) => ({
  height: 'calc(1vh + 1px)',
  width: '50%',
  top: '1px',
  background: theme.colors.newGreen,
  position: 'relative',
  transition: 'left .3s',
  left: active ? '50%' : '0',
}));

export const TabError = styled.div(({ theme }) => ({
  color: theme.colors.pastelRed,
  fontSize: '1.6vh',
  position: 'absolute',
  width: '50%',
  textAlign: 'center',
  top: '4vh',
  [theme.breakpoints.sm]: {
    fontSize: '1.4vh',
    top: '1vh',
  },
}));

export const TabLine = styled.div(({ theme }) => ({
  height: 1,
  background: theme.colors.newGreen,
  width: '100%',
}));
