import React, { Component } from 'react';
import './VideoPlay.css';

export class VideoPlay extends Component {
  render() {
    return (
      <span className="VideoPlayIcon">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="17" viewBox="0 0 25 17">
          <path
            d="M288.33,297.84c-2.32,0-4.64,0-7,0a5.09,5.09,0,0,1-5-4.15,4.89,4.89,0,0,1-.11-1.07c0-2.12,0-4.24,0-6.36a5.09,5.09,0,0,1,5.06-5.18c4.72-.14,9.44-.16,14.15,0a4.89,4.89,0,0,1,4.79,4.79,59.07,59.07,0,0,1,0,7.19,5,5,0,0,1-4.93,4.78h-7Zm-2.43-11.39v6.15l6-3.1Z"
            transform="translate(-276.2 -280.95)"
          />
        </svg>
      </span>
    );
  }
}
