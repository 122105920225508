import React, { Component } from 'react';
import './Checkmark.css';

export class Checkmark extends Component {
  render() {
    return (
      <span className="CheckmarkIcon">
        <svg xmlns="http://www.w3.org/2000/svg" width="54" height="44" viewBox="0 0 54 44">
          <path
            d="M278.9,295.46c9.13-9.38,18.63-18.34,27.79-27.63a79.86,79.86,0,0,1,7.91,7.67l-35.79,35.85-17.62-17.65,8-8Z"
            transform="translate(-261.19 -267.83)"
          />
        </svg>
      </span>
    );
  }
}
