import configureStore, { sagaMiddleware } from './ConfigureStore';
import mainSaga from 'saga/mainSaga';

const store = configureStore();

sagaMiddleware.run(mainSaga);

export type RootState = ReturnType<typeof store.getState>;

export default store;
