import styled from '@emotion/styled';

const SecondaryButtonV2 = styled.button<{ outlined?: boolean, fullWidth?: boolean }>(({ theme, outlined, fullWidth }) => ({
  fontFamily: 'Gotham Bold, sans-serif',
  borderRadius: '15vh',
  fontSize: '2vh',
  fontWeight: 700,
  textAlign: 'center',
  border: outlined ? `1px solid ${theme.colors.newGreen}` : 'none',
  color: '#fff',
  backgroundColor: outlined ? 'transparent' : theme.colors.newGreen,
  textTransform: 'uppercase',
  letterSpacing: 'normal',
  padding: '2vh 0',
  maxWidth: '100%',
  width: fullWidth ? '100%' : 'auto',
  cursor: 'pointer',
  '&:disabled': {
    backgroundColor: '#474848',
    color: '#5E5E5E',
  },
  [theme.breakpoints.sm]: {
    fontSize: '2vh',
    padding: fullWidth ? '2.2vh 3vh' : '2.2vh 15vh',
  },
}));

export default SecondaryButtonV2;
