import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spring, animated } from 'react-spring/renderprops.cjs';
import classnames from 'classnames';
import debounce from 'lodash.debounce';
import { Link } from 'react-router-dom';

import {
  BooksIcon,
  HistoryIcon,
  WineIcon,
  CarIcon,
  LuxuryIcon,
  HomeIcon,
} from 'components/shared/Icons/nav';
import { toggleAssetsMenu } from 'actions';
import { ASSET_CATEGORIES } from 'constants/main';

import './AssetsMobileFooter.scss';

const MOBILE_SCREEN_BREAKPOINT_WIDTH = 600;
const DEFAULT_NAVIGATOR_HEIGHT = 32;
const DEFAULT_ICON_WIDTH = 76;

class AssetsMobileFooter extends Component {
  constructor(props) {
    super(props);
    this.navRootRef = React.createRef();
  }

  state = { navButtonsSizes: [], navigatorSize: 0, iconSize: 0 };

  navButtons = [
    {
      // home
      label: 'Home',
      Icon: HomeIcon,
      activeColor: '#4ECB80',
      highlightStyle: {},
      key: 'home',
      path: `/app/home`,
    },
    {
      // memoriabilia
      label: ASSET_CATEGORIES.HISTORY.title,
      Icon: HistoryIcon,
      activeColor: '#4AAA80',
      highlightStyle: {},
      key: ASSET_CATEGORIES.HISTORY.key,
      path: `/app/assets/${ASSET_CATEGORIES.HISTORY.pathname}`,
    },
    {
      // comics + literature
      label: ASSET_CATEGORIES.COMICS_LITERATURE.title,
      Icon: BooksIcon,
      activeColor: '#877768',
      key: ASSET_CATEGORIES.COMICS_LITERATURE.key,
      path: `/app/assets/${ASSET_CATEGORIES.COMICS_LITERATURE.pathname}`,
    },
    {
      // cars
      label: ASSET_CATEGORIES.CARS.title,
      Icon: CarIcon,
      activeColor: '#75A2CB',
      key: ASSET_CATEGORIES.CARS.key,
      path: `/app/assets/${ASSET_CATEGORIES.CARS.pathname}`,
    },
    {
      // watches + luxury
      label: ASSET_CATEGORIES.LUXURY.title,
      Icon: LuxuryIcon,
      activeColor: '#9AA0A6',
      highlightStyle: {},
      key: ASSET_CATEGORIES.LUXURY.key,
      path: `/app/assets/${ASSET_CATEGORIES.LUXURY.pathname}`,
    },
    {
      // wine + whisky
      label: ASSET_CATEGORIES.WINE_SPIRITS.title,
      Icon: WineIcon,
      activeColor: '#6B4073',
      highlightStyle: {},
      key: ASSET_CATEGORIES.WINE_SPIRITS.key,
      path: `/app/assets/${ASSET_CATEGORIES.WINE_SPIRITS.pathname}`,
    },
  ];

  navButtonsRefs = [];

  get activeButtonIndex() {
    const index = this.navButtons.findIndex(
      ({ key }) => key === this.props.activeAssetCategory.key,
    );
    return this.props.location === '/app/home' ? 0 : index < 0 ? 0 : index;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.activeAssetCategory) {
      return true;
    }

    return false;
  }

  componentDidMount() {
    this.setState({
      from: this.getSpringValues(this.props),
      to: this.getSpringValues(this.props),
    });
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.activeAssetCategory !== this.props.activeAssetCategory &&
        this.props.activeAssetCategory) ||
      this.props.location !== prevProps.location
    ) {
      this.setState({
        from: this.getSpringValues(prevProps),
        to: this.getSpringValues(this.props),
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize = debounce(() => {
    if (window.innerWidth <= MOBILE_SCREEN_BREAKPOINT_WIDTH) {
      this.setState({
        from: this.getSpringValues(this.props),
        to: this.getSpringValues(this.props),
      });
    }
  }, 200);

  handleAssetListToggle = e => {
    const currentCategory = e.currentTarget.getAttribute('data-category');
    this.props.toggleAssetsMenu(currentCategory);
  };

  getSpringValues = props => {
    const activeButtonIndex =
      this.props.location === '/app/home'
        ? 0
        : this.navButtons.findIndex(({ key }) => key === props.activeAssetCategory.key);

    const navigatorSize = this.navRootRef?.current?.getBoundingClientRect();
    const iconSize = this.navRootRef?.current?.childNodes[1].getBoundingClientRect();
    const { activeColor } = this.navButtons[activeButtonIndex < 0 ? 1 : activeButtonIndex];

    let height = Math.round((navigatorSize?.height || DEFAULT_NAVIGATOR_HEIGHT) * 0.9);
    let width = iconSize?.width || DEFAULT_ICON_WIDTH;

    let position = activeButtonIndex * width;

    return {
      height: `${height}px`,
      width: `${width}px`,
      transform: `translate3d(${position}px, 0, 0)`,
      backgroundColor: activeColor,
    };
  };

  getBtnClassNames = path => {
    return classnames('AssetsMobileFooter-nav-btn', {
      notification: this.props.navMenuNotifications.includes(path),
    });
  };

  matchHomeOrAssetsRoute = location =>
    location.match(/(\/app\/assets|\/app\/home)(\/\D+\/?\w+)?$/g);

  render() {
    const { location, detailsOpened } = this.props;
    const activeCategory = this.navButtons[this.activeButtonIndex].category;
    const navListClasses = classnames('AssetsMobileFooter-nav-list', {
      [`AssetsMobileFooter-nav-list ${activeCategory}`]: activeCategory,
    });
    const mobileFooterClass = classnames('AssetsMobileFooter', {
      hidden: detailsOpened || !this.matchHomeOrAssetsRoute(location),
    });

    return (
      <div className={mobileFooterClass}>
        <ul className={navListClasses} ref={this.navRootRef}>
          <Spring
            native
            config={{ tension: 260, friction: 23 }}
            from={this.state.from}
            to={this.state.to}
          >
            {props => <animated.div className="AssetsMobileFooter-indicator" style={props} />}
          </Spring>

          {this.navButtons.map(({ Icon, key, path }, index) => {
            const navItemClasses = classnames('AssetsMobileFooter-nav-item', {
              'AssetsMobileFooter-nav-item active': this.activeButtonIndex === index,
              [`AssetsMobileFooter-nav-item ${key}`]: key,
            });
            return (
              <li
                key={key}
                className={navItemClasses}
                ref={ref => (this.navButtonsRefs[index] = ref)}
              >
                <Link className={this.getBtnClassNames(path)} to={path}>
                  <Icon className="AssetsMobileFooter-nav-icon" />
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  displayAssetsMenu: state.UI.displayAssetsMenu,
  detailsOpened: state.Assets.detailsOpened,
  navMenuNotifications: state.UI.navMenuNotifications,
  activeAssetCategory: state.Assets.activeAssetCategory,
});

const mapDispatchToProps = { toggleAssetsMenu };

export default connect(mapStateToProps, mapDispatchToProps)(AssetsMobileFooter);
