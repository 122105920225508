import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateUserData } from 'actions';
import { Input } from 'components/shared/Inputs/Input';
import { BackNextButtons } from 'components/shared/Buttons/BackNextButtons';
import CollectInfoMessage from 'components/shared/CollectInfoMessage';

import withDeviceDetection from 'hoc/withDeviceDetection';

import './NamePhoneInfo.css';

class NamePhoneInfo extends Component {
  static propTypes = {
    data: PropTypes.object,
    onFormUpdate: PropTypes.func.isRequired,
    onStepForward: PropTypes.func.isRequired,
    onStepBackward: PropTypes.func.isRequired,
    updateUserData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    formMode: 'investment',
  };

  state = { isStepValid: false };

  initialData = {};

  componentDidMount() {
    this.validateStep();
    this.initialData = this.props.data;
  }

  validateStep = () => {
    const {
      data: { first_name, last_name, phone_number },
    } = this.props;
    const phoneDigits = phone_number.replace(/\D/g, '');

    const isNameValid = typeof first_name === 'string' && first_name !== '';
    const isLastNameValid = typeof last_name === 'string' && last_name !== '';
    const isPhoneValid =
      typeof phone_number === 'string' && phoneDigits.length >= 10 && phoneDigits.length <= 11;

    this.setState({ isStepValid: isNameValid && isLastNameValid && isPhoneValid });
  };

  onFieldChange = (fieldKey, fieldValue) =>
    this.props.onFormUpdate(
      {
        [fieldKey]: fieldValue,
      },
      this.validateStep,
    );

  handleNextClick = () => {
    const {
      data: { first_name, last_name, phone_number },
    } = this.props;
    this.props.updateUserData({ first_name, last_name, phone_number });
    this.props.onStepForward();
  };

  handleBackClick = () => {
    if (this.props.resetOnBackPress) {
      const { first_name, last_name, phone_number } = this.initialData;
      this.props.onFormUpdate({ first_name, last_name, phone_number });
    }
    this.props.onStepBackward();
  };

  render() {
    const {
      formMode,
      data: { first_name, last_name, phone_number },
    } = this.props;
    const completeWhat = formMode === 'investment' ? 'your investment' : 'your profile';

    return (
      <div className="NamePhoneInfo">
        <CollectInfoMessage completeWhat={completeWhat} />
        <Input
          placeholder="First Name"
          value={first_name}
          onChange={value => this.onFieldChange('first_name', value)}
          autoFocus={this.props.userDevice.isMobile ? false : true}
        />
        <Input
          placeholder="Last Name"
          value={last_name}
          onChange={value => this.onFieldChange('last_name', value)}
        />
        <Input
          type="phone"
          value={phone_number}
          onChange={value => this.onFieldChange('phone_number', value)}
        />
        <BackNextButtons
          buttons={{ back: { text: 'back' }, next: { text: 'next' } }}
          nextDisabled={!this.state.isStepValid}
          onBackClick={this.handleBackClick}
          onNextClick={this.handleNextClick}
        />
      </div>
    );
  }
}

const mapDispatchToProps = { updateUserData };

export default connect(null, mapDispatchToProps)(withDeviceDetection(NamePhoneInfo));
