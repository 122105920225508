import React, { Component } from 'react';
import { connect } from 'react-redux';

import TradingCloseCountdown from 'components/views/app/Assets/Trading/Common/TradingCloseCountdown';

import './AssetBanner.css';
import { SecondaryMarketStatus } from 'types/assets';
import { ASSET_STATUS } from 'constants/main';

class AssetBanner extends Component {
  render() {
    const { activeAsset } = this.props;
    const { secondaryMarket } = activeAsset.financialInstrument?.markets || {};
    const isTrading = activeAsset.asset_status === ASSET_STATUS.TRADING_OPENED;
    const isLiveTrading = isTrading && secondaryMarket?.status === SecondaryMarketStatus.OPEN;
    const show = isLiveTrading && secondaryMarket?.status === SecondaryMarketStatus.OPEN;
    const displayProp = show ? 'shown' : 'hidden';

    return (
      <div className={'AssetBanner ' + displayProp}>
        <div className="AssetBanner-callout">Trading Window Now open!</div>

        {show && (
          <div className="AssetBanner-remaining">
            <TradingCloseCountdown /> Remaining...
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeAsset: state.Assets.activeAsset,
});

export default connect(mapStateToProps, null)(AssetBanner);
