import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import MultistepForm from 'components/shared/MultistepForm';
import FormCloseModal from 'components/shared/FormCloseModal';
import VerifyEmailPopup from 'components/shared/VerifyEmailPopup';
import DwollaStatusPopup from 'components/shared/DwollaStatusPopup';
import StateAvailabilityError from 'components/shared/StateAvailabilityError';
import { Fullpage } from 'components/shared/Fullpage';
import { CloseButton } from 'components/shared/Buttons/CloseButton';
import {
  SharePurchaseInfo,
  ConnectBank,
  BankAccountType,
  BankInfo,
  Confirmation,
  Transfer,
  TransferInitiated,
} from 'components/shared/MultistepForm/steps/funding';
import { getBankFundingSource } from 'actions';
import analytics from 'services/analytics';
import { SEGMENT_ACTIONS, SEGMENT_CATEGORIES } from 'constants/analytics';
import { ACCOUNT_STATUS, FUNDING_SOURCE_STATUS } from 'constants/main';

import './FundAccount.css';

class FundAccount extends Component {
  constructor(props) {
    super(props);

    const { user } = this.props;
    const initialFormData = {
      agreeAndContinue:
        user.customer?.accounts?.[0]?.status === ACCOUNT_STATUS.ENROLLMENT_PENDING ?? false,
      isSmallDepositNeeded: false, // verification via micro deposits
      fundingSourceCreated: false, // funding source was created with plaid
      transfer_amount: 0,
      emailVerificationNeeded: this.emailVerificationNeeded(),
      signupOnboardingFlow:
        this.props.location.state && this.props.location.state.signupOnboardingFlow,
    };

    this.state = { showWarningModal: false, emailVerificationNeeded: false, ...initialFormData };
  }

  steps = [];

  componentDidMount() {
    this.props.getBankFundingSource();
    this.trackAnalyticsViewEvent(0);
  }

  emailVerificationNeeded = () => {
    const { user } = this.props;
    if (typeof user.emails !== 'undefined') {
      const primaryEmails = user.emails.filter(email => email.primary === true);
      return primaryEmails.length > 0 && primaryEmails[0].verified === false;
    }
    return false;
  };

  handleFormClose = () => {
    this.props.history.push({
      pathname: '/app',
      state: {
        signupOnboardingFlow: this.state.signupOnboardingFlow,
      },
    });
  };

  showWarningModal = () => this.setState({ showWarningModal: true });

  hideWarningModal = () => this.setState({ showWarningModal: false });

  /**
   * Updates form data in component's state
   * This is async operation! Use callback if needed
   * @param  {Object}   updates  - updates object
   * @param  {Function} callback - callback function (optional)
   */
  handleFormUpdate = (updates, callback) => {
    this.setState(
      state => ({
        ...state,
        ...updates,
      }),
      () => callback && callback(),
    );
  };

  handleDwollaRetry = () => {
    this.props.history.replace('/app/onboarding', {
      step: '1',
      signupOnboardingFlow: this.state.signupOnboardingFlow,
    });
  };

  trackAnalyticsViewEvent(stepIndex) {
    const screenName = this.steps[stepIndex].name;
    analytics.track(`${screenName} View`, {
      category: SEGMENT_CATEGORIES.FUND_ACCOUNT,
      action: SEGMENT_ACTIONS.OPEN,
    });
  }

  onStepChange = ({ nextStepIndex }) => {
    this.trackAnalyticsViewEvent(nextStepIndex);
  };

  render() {
    const { bankFundingSource } = this.props;
    const hasBankFundingSource = JSON.stringify(bankFundingSource.info) !== '{}';
    const unverifiedBankFundingSource =
      bankFundingSource?.info?.status === FUNDING_SOURCE_STATUS.UNVERIFIED;

    if (this.props.user.state && this.props.user.state_status.is_state_blocked) {
      return (
        <Fullpage>
          <StateAvailabilityError onClose={this.handleFormClose} />
        </Fullpage>
      );
    }

    this.steps = [
      {
        path: '/',
        render: SharePurchaseInfo,
        name: 'Fund Account Info',
      },
      {
        path: '/connect-bank',
        render: ConnectBank,
        name: 'Fund Account IAV or Manually',
        needToSkip: hasBankFundingSource,
      },
      {
        path: '/bank-account-type',
        render: BankAccountType,
        name: 'Fund Account Bank Account Type',
        needToSkip: hasBankFundingSource || this.state.fundingSourceCreated,
      },
      {
        path: '/bank-info',
        render: BankInfo,
        name: 'Fund Account Bank Account Info Input',
        needToSkip: hasBankFundingSource || this.state.fundingSourceCreated,
      },
      {
        path: '/transfer',
        render: Transfer,
        name: 'Fund Account Transfer',
        needToSkip: !this.state.fundingSourceCreated,
      },
      {
        path: '/transfer-initiated',
        render: TransferInitiated,
        name: 'Fund Account Transfer Initiated',
        needToSkip: !this.state.fundingSourceCreated,
      },
      {
        path: '/confirmation',
        name: 'Fund Account Confirmation',
        render: Confirmation,
        needToSkip:
          (!this.state.isSmallDepositNeeded || !this.state.fundingSourceCreated) &&
          hasBankFundingSource &&
          !unverifiedBankFundingSource,
      },
      {
        path: '/success',
        render: () => (
          <Redirect
            to={{
              pathname: '/app',
              state: {
                signupOnboardingFlow: this.state.signupOnboardingFlow,
              },
            }}
          />
        ),
      },
    ];

    return (
      <div>
        <Fullpage>
          <div className="FundAccount">
            <CloseButton onClickHandler={this.showWarningModal} />
            <div className="FundAccount-wrapper">
              <MultistepForm
                formMode="profile"
                rootUrl="/app/fund-account"
                steps={this.steps}
                onFormClose={this.handleFormClose}
                onFormUpdate={this.handleFormUpdate}
                onStepChange={this.onStepChange}
                data={this.state}
              />
            </div>
          </div>

          <VerifyEmailPopup
            show={this.state.emailVerificationNeeded}
            text={
              'In order to fund your account on Rally you must verify your email, ' +
              this.props.user.email
            }
            onRequestClose={this.handleFormClose}
            email={this.props.user.email}
          />

          <DwollaStatusPopup
            status={this.props.dwollaCustomerStatus}
            onClick={this.handleFormClose}
            onRetry={this.handleDwollaRetry}
          />
        </Fullpage>

        <FormCloseModal
          visible={this.state.showWarningModal}
          onAccept={this.hideWarningModal}
          onDecline={this.handleFormClose}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dwollaCustomerStatus: state.Auth.dwollaCustomerStatus,
  user: state.Auth.user,
  bankFundingSource: state.Investments.bankFundingSource,
});

const mapDispatchToProps = { getBankFundingSource };

export default connect(mapStateToProps, mapDispatchToProps)(FundAccount);
