import React, { PureComponent } from 'react';
import { CloseButton } from 'components/shared/Buttons';
import Fullpage from 'components/shared/Fullpage';
import MultistepForm from 'components/shared/MultistepForm';
import { InvestingPassport, StartedInfo } from 'components/shared/MultistepForm/steps/investment';
import { PassportSteps } from 'components/shared/MultistepForm/steps/profile';
import { PASSPORT_SECTION, STATUSES_TO_SKIP_EMPLOYER } from 'constants/main';
import { profileUpdateRoot } from 'constants/routes';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from 'store';
import { createPassportData } from 'utils/passport';
import { compose } from 'redux';
import { connect } from 'react-redux';

const introText = `
  We will be launching a new trading experience on Rally. In order to prepare your account,
  we'll need to collect a bit more information and complete your profile. We will NEVER sell this information,
  and all data is encrypted and locked securely in your account.
`;

type UserUpdatesProps = RouteComponentProps & { user: any };

class ProfileUpdates extends PureComponent<UserUpdatesProps, { data: any }> {
  constructor(props: UserUpdatesProps) {
    super(props);
    const { investment_info } = props.user;

    const initialData = createPassportData({
      user: props.user,
      basicProfileCompleted: true,
      giftCode: 0,
      ...investment_info,
      balance: {},
      activeAsset: {},
      userShares: 1,
    });
    this.state = {
      data: { ...initialData, availableShares: 1 },
    };
  }

  componentDidUpdate({ user: prevUser }: UserUpdatesProps) {
    const { user } = this.props;
    if (!prevUser.customer.employmentDetails && user.customer.employmentDetails) {
      const { investment_info } = user;
      const {
        employmentStatus,
        employer,
        occupationOrJobTitle,
        liquidNetWorth,
        pretaxAnnualIncome,
        riskToleranceLevel,
      } = createPassportData({
        user,
        basicProfileCompleted: true,
        giftCode: 0,
        ...investment_info,
        balance: {},
        activeAsset: {},
        userShares: 1,
      });
      this.setState({
        data: {
          ...this.state.data,
          employmentStatus,
          employer,
          occupationOrJobTitle,
          liquidNetWorth,
          pretaxAnnualIncome,
          riskToleranceLevel,
        },
      });
    }
  }

  handleUpdateData = (updates: any, callback?: () => void) => {
    const { history } = this.props;

    this.setState(
      state => ({
        data: {
          ...state.data,
          ...updates,
        },
      }),
      () => {
        if (updates.investingPassportApplied) {
          history.push('');
          return;
        }
        if (updates.investingPassportApplied === false) {
          history.replace(`${profileUpdateRoot}/name-phone-info`);
          return;
        }
        callback && callback();
      },
    );
  };

  render() {
    const { history } = this.props;
    const { data } = this.state;
    const fullPassportSteps = PassportSteps({
      investingPassportApplied: false,
      shouldSkipEmployerInfo: STATUSES_TO_SKIP_EMPLOYER.includes(data.employmentStatus),
      isAssociated: data.isAssociated,
      isShareholder: data.isShareholder,
      fundingSourceExists: true,
      shouldSkipConfirmation: true,
      shouldSkipBankVerification: true,
    });
    const steps = [
      {
        path: '/',
        render: (props: any) => <StartedInfo {...props} text={introText} />,
        name: 'Profile update intro',
        analyticsEventName: 'Profile update intro',
      },
      ...fullPassportSteps,
      {
        path: '/profile-confirmation',
        render: (props: any) => (
          <InvestingPassport {...props} shouldSkipUpdate onStepForward={() => {}} />
        ),
        name: 'Profile Confirmation',
        analyticsEventName: 'Profile Confirmation',
      },
    ];

    return (
      <div className="profile-update">
        <Fullpage>
          <div className="Onboarding">
            <CloseButton onClickHandler={() => history.push('/app')} />
            <div className="Onboarding-wrapper">
              <MultistepForm
                rootUrl={profileUpdateRoot}
                steps={steps}
                section={PASSPORT_SECTION.IO}
                onFormClose={() => history.push('/app')}
                onFormUpdate={this.handleUpdateData}
                data={data}
                resetOnBackPress
              />
            </div>
          </div>
        </Fullpage>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  user: state.Auth.user,
});

export default compose(withRouter, connect(mapStateToProps, null))(ProfileUpdates);
