// Move everything to the base Asset Interface and let only key we add for active asset eg. pricing
export interface IActiveAsset extends Asset {
  merchandising: {
    enabled: boolean;
    merchants: [];
  };
  merchandising_enabled: boolean;
  modified: string;
  number_of_shares: number;
  offering_ends: string;
  offering_starts: string;
  order: number;
  pdf: string;
  pending_investment: number;
  pending_investment_shares: number;
  portal_image: string;
  progress_bar: number;
  quote: string;
  receiptcard_image: string;
  sharecard_image: string;
  snapchat_deeplink_url: string;
  subscription_agreement_template: Record<string, any>;
  tertiary_color: string;
  ticker: string;
  timeline_items: [];
  trading: {
    pricing: {
      financialInstrumentId: string;
      pricePerUnit: {
        current: number;
        issue: number;
        orderBook: {
          bidHigh: number;
          askLow: number;
        };
        session: {
          latestFilled: number;
          lastClose: number;
        };
      };
    };
  };
}

export enum SecondaryMarketStatus {
  CLOSED = 'CLOSED',
  DISABLED = 'DISABLED',
  OPEN = 'OPEN',
}

export enum AssetStatus {
  COMING_SOON = 'Coming Soon',
  IO_OPENED = 'Open ICO',
  IO_CLOSED = 'Closed ICO',
  TRADING_OPENED = 'Trading',
  POST_ONLY = 'Post Only',
  TRADING_CLOSED = 'Trading closed',
}

export interface FinancialInstrument {
  id: string;
  markets: {
    status: string;
    secondaryMarket: {
      disabled: string;
      enabled: string;
      status: SecondaryMarketStatus;
      sessionsFollowMarketHours: boolean;
      sessionsStart: string;
      sessionHours: {
        dateClose: string;
        dateOpen: string;
        lastDateClose: string;
        lastDateOpen: string;
        nextDateClose: string;
        nextDateOpen: string;
      };
    };
  };
}

export type AssetCategory = 'cars' | 'history' | 'wine-spirits' | 'art' | 'watches';

export interface Asset {
  asset_media: [];
  asset_status: AssetStatus;
  asset_value: number;
  background_color: string;
  category: AssetCategory;
  categories: any[]; // Todo verify what is that type
  confirmed_investment: number;
  confirmed_investment_shares: number;
  current_price_per_share: string;
  current_value: string;
  description: string;
  display_name: string;
  document: [];
  docusign_template_id: string;
  exited_date: string;
  financialInstrument: FinancialInstrument;
  id: string;
  ticker: string;
  progress_bar: number;
  offering_starts: string;
  offering_ends: string;
  tertiary_color: string;
  home_image: string;
  portal_image: string;
  investment_image?: string;
  history_value_points: [];
  invested_shares: number;
  investor_count: number;
  ipo_price_per_share: string;
  is_active: boolean;
  is_exited: boolean;
  max_share_investment: number;
  media_description: string;
  media_image: string;
  short_name: string;
}
