import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SecondaryButton } from 'components/shared/Buttons/SecondaryButton';
import * as specialAccessHelper from 'utils/specialAccess';

import './Confirmation.css';

class Confirmation extends Component {
  static propTypes = {
    data: PropTypes.object,
    onFormUpdate: PropTypes.func.isRequired,
    onStepForward: PropTypes.func.isRequired,
  };

  onConfirmClick = () => {
    const {
      data: { isSmallDepositNeeded },
    } = this.props;

    if (isSmallDepositNeeded) {
      if (this.props.saPopup?.asset?.type === 'free_share_program') {
        specialAccessHelper.increaseAmount({
          userId: this.props.user.id,
          assetId: this.props.saPopup.asset.id,
          assetType: this.props.saPopup.asset.type,
        });
      }

      return this.props.onFormClose();
    }

    this.props.onFormUpdate({ fundingSourceCreated: true }, this.props.onStepForward());
  };

  render() {
    const {
      data: { isSmallDepositNeeded },
    } = this.props;
    const title = isSmallDepositNeeded ? <h1>CONFIRMATION</h1> : <h2>Success!</h2>;
    const text = isSmallDepositNeeded ? (
      <p>
        Within 48 hours you will receive two small deposits. You will be sent an email to confirm
        these deposits, or can enter the amounts using the verification link located in the “My
        Portfolio” section in the navigation menu.
      </p>
    ) : (
      <p>Your account is now linked to a funding source.</p>
    );

    return (
      <div className="Confirmation">
        {title}
        <div className="Confirmation-text">{text}</div>
        <SecondaryButton text={<b>OKAY</b>} onClick={this.onConfirmClick} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  saPopup: state.UI.saPopup,
  user: state.Auth.user,
});

export default connect(mapStateToProps)(Confirmation);
