import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './TextButton.scss';

/**
 * TextButton component
 * @param  {String}    arrow:  'back'|'left'|'previous' or 'forward'|'right'|'next'
 * @param  {Object}    style:  styles object
 * @param  {String}    className:  additional css classes
 * @param  {Node}      children:  button content
 * @param  {String}    text:  text content (same as 'children' prop)
 * @param  {Node}      icon:  button icon
 * @param  {String}    img:   source for button image (<img /> src attribute)
 */
export const TextButton = props => {
  const { arrow, style, className, children, text, icon, img, buttonRef, ...restProps } = props;

  const buttonClasses = classnames('TextButton', {
    'TextButton-left-arrow': arrow === 'back' || arrow === 'left' || arrow === 'previous',
    'TextButton-right-arrow': arrow === 'forward' || arrow === 'right' || arrow === 'next',
    'TextButton-icon': icon || img,
    [className]: className,
  });

  let buttonIcon = null;

  if (img) {
    buttonIcon = (
      <span className="TextButton-img-wrapper">
        <img src={img} alt="icon" />
      </span>
    );
  } else if (icon) {
    buttonIcon = <span className="TextButton-icon-wrapper">{icon}</span>;
  }

  return (
    <button className={buttonClasses} style={style} ref={buttonRef} {...restProps}>
      {buttonIcon}
      {children || text}
    </button>
  );
};

TextButton.propTypes = {
  arrow: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
};

TextButton.defaultProps = {
  text: 'OKAY',
  icon: null,
  img: null,
};

export default TextButton;
