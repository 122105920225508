import React, { Component } from 'react';

import RallyLogo from 'components/shared/RallyLogo';
import { Input } from 'components/shared/Inputs/Input';
import { sendPasswordResetConfirmation } from 'services/AuthAPI';
import history from 'services/history';
import { setPageTitle } from 'utils';

import './NewPassword.css';
import 'components/views/public/AuthenticationPages.css';

class NewPassword extends Component {
  state = {
    password1: '',
    password2: '',
    isFormSubmitted: false,
    error: null,
    loading: false,
  };

  componentDidMount() {
    setPageTitle('Reset Password');
  }

  onPassword1Change = password1 => this.setState({ password1, error: null });
  onPassword2Change = password2 => this.setState({ password2, error: null });

  parseArgs(urlArgs) {
    let result = {};

    urlArgs.split('&').forEach(function(part) {
      var item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });

    return result;
  }

  onFormSubmit = async e => {
    e.preventDefault();
    document.activeElement.blur();
    if (!this.validateForm()) return;

    const { password1: new_password1, password2: new_password2 } = this.state;
    const { uid, token } = this.parseArgs(this.props.location.search.substr(1));
    this.setState({ loading: true });
    try {
      await sendPasswordResetConfirmation({ new_password1, new_password2, uid, token });
      this.setState({ isFormSubmitted: true, loading: false });
    } catch (err) {
      this.setState({ loading: false, error: err });
    }
  };

  validateForm = () => {
    const { password1, password2 } = this.state;
    let error = null;

    if (!password1 || !password2) error = 'Please fill in password fields';
    else if (password1 !== password2) error = 'Passwords should match';

    this.setState({ error });
    return !error;
  };

  goToLogin = () => history.push('/login');

  render() {
    const { password1, password2, isFormSubmitted, error, loading } = this.state;

    let content = (
      <form className="NewPassword-form" onSubmit={this.onFormSubmit} noValidate>
        <div className="NewPassword-form-title">Create a new password</div>
        <div className="NewPassword-inputs">
          <Input
            name="password1"
            type="password"
            placeholder="New Password"
            value={password1}
            onChange={this.onPassword1Change}
            valid={!error}
          />
          <Input
            name="password2"
            type="password"
            placeholder="Confirm New Password"
            value={password2}
            onChange={this.onPassword2Change}
            valid={!error}
          />
          {error && <p className="NewPassword-error">{error}</p>}
          <button className="AuthenticationPages-actionButton" disabled={loading} type="submit">
            Reset Password
          </button>
          <p className="NewPassword-restrictions">
            For security purposes, your new password must have an Uppercase letter, a number, and be
            at least 8 characters long.
          </p>
        </div>
      </form>
    );

    if (isFormSubmitted) {
      content = (
        <div className="NewPassword-success">
          <h2>Success!</h2>
          <p>Your password has been successfully changed!</p>
          <button className="AuthenticationPages-actionButton" onClick={this.goToLogin}>
            OKAY
          </button>
        </div>
      );
    }

    return (
      <section className="AuthenticationPages NewPassword">
        <div className="NewPassword-box">
          <div className="NewPassword-logo">
            <a href="https://app.rallyrd.com/">
              <RallyLogo color="black" size="normal" />
            </a>
          </div>
          <div className="NewPassword-body">{content}</div>
        </div>
      </section>
    );
  }
}

export default NewPassword;
