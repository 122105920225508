import { handleActions } from 'redux-actions';

import {
  SET_BALANCE,
  SET_GAIN_LOSS,
  SET_INVESTMENTS,
  SET_INVESTMENT_ERROR,
  SET_RECEIPTS,
  SET_BANK_FUNDING_SOURCE,
  DELETE_BANK_FUNDING_SOURCE,
  SET_GIFT_CODE_APPLIED,
  REMOVE_GIFT_CODE_APPLIED,
  SET_TRADING_WINDOW,
  SET_LOADING_TRADING_WINDOW,
  SET_BANK_FUNDING_SOURCE_ERROR,
  SET_BANK_FUNDING_SOURCE_LOADING,
  SET_AGREEMENT_TIMESTAMP,
  SET_INVESTMENT_FLOW,
  SET_POSITIONS,
} from 'actions/types';

const defaultState = {
  balance: {
    cash: 0.0,
    investments: 0.0,
    pending: 0.0,
    total: 0.0,
  },
  investmentFlow: {
    investment_info: {},
  },
  user_investments: [],
  total_shares: {},
  total_approved_shares: {},
  transfers: [],
  gainLoss: {},
  bankFundingSource: {
    loading: false,
    error: null,
    info: {},
  },
  positions: {},
  errors: null,
  giftCodeApplied: null,
  tradingWindows: {
    loading: true,
  },
};

const reducerMap = {};

reducerMap[SET_BALANCE] = (state, action) => ({
  ...state,
  balance: {
    cash: action.payload.cash || 0,
    investments: action.payload.investments || 0,
    pending: action.payload.pending || 0,
    total: action.payload.total || 0,
    pending_info: action.payload.pending_info || {},
  },
});

reducerMap[SET_INVESTMENTS] = (state, action) => {
  const total_shares = action.payload.reduce((result, current) => {
    if (!result[current.asset]) result[current.asset] = 0;
    const shares = current.status === 'Rejected' ? 0 : current.shares;
    result[current.asset] += shares;
    return result;
  }, {});

  const total_approved_shares = action.payload.reduce((result, current) => {
    if (!result[current.asset]) result[current.asset] = 0;
    const shares = current.status !== 'Approved' ? 0 : current.shares;
    result[current.asset] += shares;
    return result;
  }, {});

  return {
    ...state,
    user_investments: action.payload,
    total_shares: total_shares,
    total_approved_shares: total_approved_shares,
  };
};

reducerMap[SET_GAIN_LOSS] = (state, action) => {
  return {
    ...state,
    gainLoss: action.payload,
  };
};

reducerMap[SET_RECEIPTS] = (state, action) => ({
  ...state,
  transfers: action.payload,
});

reducerMap[SET_AGREEMENT_TIMESTAMP] = (state, action) => ({
  ...state,
  agreementTimestamp: action.payload,
});

reducerMap[SET_BANK_FUNDING_SOURCE] = (state, action) => ({
  ...state,
  bankFundingSource: {
    ...state.bankFundingSource,
    info: {
      ...action.payload,
    },
    loading: false,
    error: null,
  },
});

reducerMap[SET_BANK_FUNDING_SOURCE_ERROR] = (state, action) => ({
  ...state,
  bankFundingSource: {
    ...state.bankFundingSource,
    loading: false,
    error: action.payload,
  },
});

reducerMap[SET_BANK_FUNDING_SOURCE_LOADING] = state => ({
  ...state,
  bankFundingSource: {
    ...state.bankFundingSource,
    loading: true,
    error: null,
  },
});

reducerMap[DELETE_BANK_FUNDING_SOURCE] = (state, action) => ({
  ...state,
  bankFundingSource: defaultState.bankFundingSource,
});

reducerMap[SET_INVESTMENT_ERROR] = (state, action) => ({
  ...state,
  errors: action.payload,
});

reducerMap[SET_INVESTMENT_ERROR] = (state, action) => ({
  ...state,
  errors: action.payload,
});

reducerMap[SET_GIFT_CODE_APPLIED] = (state, action) => ({
  ...state,
  giftCodeApplied: action.payload,
});

reducerMap[REMOVE_GIFT_CODE_APPLIED] = (state, action) => ({
  ...state,
  giftCodeApplied: null,
});

reducerMap[SET_INVESTMENT_FLOW] = (state, action) => {
  if (action.payload && action.payload.investment_info) {
    const { investment_info } = action.payload;

    return {
      ...state,
      investmentFlow: {
        ...state.investmentFlow,
        investment_info: {
          ...state.investmentFlow.investment_info,
          ...investment_info,
        },
      },
    };
  }

  return {
    ...state,
  };
};

reducerMap[SET_TRADING_WINDOW] = (state, action) => ({
  ...state,
  tradingWindows: {
    ...state.tradingWindows,
    [action.tradingWindowId]: {
      ...action.payload,
    },
  },
});

reducerMap[SET_LOADING_TRADING_WINDOW] = (state, action) => ({
  ...state,
  tradingWindows: {
    ...state.tradingWindows,
    loading: action.payload,
  },
});

reducerMap[SET_POSITIONS] = (state, action) => {
  return {
    ...state,
    positions: action.payload,
  };
};

const investments = handleActions(reducerMap, defaultState);

export default investments;
