import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import AssetDetailsPage from '../Common/AssetDetailsPage';
import TextButton from 'components/shared/Buttons/TextButton';
import { Chat } from 'components/shared/Icons/Chat';

import './AssetQuotes.css';

class AssetQuotes extends Component {
  renderPlaceholder(car) {
    return (
      <h2
        id="AssetQuotes"
        className={`AssetQuotes-placeholder ${this.props.applicationMode}`}
        style={{ background: car.tertiary_color }}
      >
        Quotes <br />
        coming soon...
      </h2>
    );
  }

  render() {
    const { activeAsset: car } = this.props;
    if (!car.quote) return this.renderPlaceholder(car);

    return (
      <div
        id="AssetQuotes"
        className={`AssetQuotes ${this.props.applicationMode}`}
        style={{ backgroundImage: `url(${car.quote.background_image})` }}
      >
        <AssetDetailsPage asset={car} {...this.props}>
          <div className="AssetQuotes-quoteContainer">
            <div className="AssetQuotes-quote">
              <span>{car.quote.quote}</span>
            </div>
            <img className="AssetQuotes-author-image" src={car.quote.author_image} alt="author" />
            <div className="AssetQuotes-author">{car.quote.author}</div>
            <div className="AssetQuotes-author-description" style={{ color: car.tertiary_color }}>
              {car.quote.author_description}
            </div>
          </div>
          <NavLink to="/app/chat">
            <TextButton icon={<Chat />} arrow="forward" style={{ color: car.tertiary_color }}>
              Join The Conversation
            </TextButton>
          </NavLink>
        </AssetDetailsPage>
      </div>
    );
  }
}

export default AssetQuotes;
