import Bugsnag from '@bugsnag/js';
import { createActions } from 'redux-actions';
import { getCustomerById } from 'services/CustomerApi';
import { ThunkActionCaller } from 'types/redux';
import {
  SET_CUSTOMER_DATA,
  SET_INVESTOR_DETAILS,
  SET_PERSONAL_INFORMATION,
  SET_EMPLOYMENT_DETAILS,
} from './types';

export const {
  setInvestorDetails,
  setCustomerData,
  setPersonalInformation,
  setEmploymentDetails,
} = createActions<any>(
  SET_INVESTOR_DETAILS,
  SET_CUSTOMER_DATA,
  SET_PERSONAL_INFORMATION,
  SET_EMPLOYMENT_DETAILS,
);

export const getCustomerData: ThunkActionCaller<string> = id => async dispatch => {
  try {
    const { data } = await getCustomerById(id);
    dispatch(setCustomerData(data));
  } catch (err) {
    Bugsnag.notify(err);
  }
};
