import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BackNextButtons } from 'components/shared/Buttons/BackNextButtons';

import learnImage from 'images/tour/02_learn.gif';

import './Learn.css';

class Learn extends Component {
  static propTypes = {
    onStepForward: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className="Learn">
        <h2>Learn</h2>
        <p className="light">
          Within each asset portal, explore all the unique details of the asset including valuation
          charts, interactive photos & video, and a provenance timeline.
        </p>
        <img src={learnImage} alt="manage" className="Learn-img Tour-gif" />
        <BackNextButtons
          buttons={{ next: { text: 'next' }, style: 'centered' }}
          onNextClick={() => this.props.onStepForward()}
        />
      </div>
    );
  }
}

export default Learn;
