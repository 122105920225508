import React, { useState, useEffect, useCallback } from 'react';

const Percentage = ({ percentage, showSign, numOfDec }) => {
  const [value, setValue] = useState(0);

  const formatPercentage = useCallback(
    percentValue => {
      let cleanNumber = Math.abs(Number(percentValue)).toLocaleString('en', {
        useGrouping: false,
        minimumFractionDigits: numOfDec || 2,
      });

      if (showSign) {
        switch (Math.sign(percentValue)) {
          case 1:
            cleanNumber = `+${cleanNumber}`;
            break;
          case -1:
            cleanNumber = `-${cleanNumber}`;
            break;

          default:
            break;
        }
      }

      setValue(cleanNumber);
    },
    [showSign, numOfDec],
  );

  useEffect(() => {
    if (!isNaN(percentage)) {
      formatPercentage(percentage);
    }
  }, [percentage, formatPercentage]);

  return <span className="Percentage">{`${value}%`}</span>;
};

export default Percentage;
