import React, { Component } from 'react';
import { connect } from 'react-redux';

import './DetailCategory.css';

class DetailCategory extends Component {
  render() {
    const info = this.props.details_info || {};
    const header = info.name;
    const details = info.details.map((object, index) => {
      return (
        <div className="Detail" key={object.id}>
          <div className="Detail-name">{object.name}</div>
          <div className="Detail-value">{object.value}</div>
        </div>
      );
    });

    return (
      <div className="DetailCategory">
        <div className="DetailCategory-header">{header}</div>
        <div className="DetailCategory-details">{details}</div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DetailCategory);
