import React from 'react';
import { theme } from 'theme';

const ProgressBar = props => {
  const scale = props.scale || 'vmax';
  return (
    <div className={'ProgressBar-container'}>
      <div
        className="ProgressBar"
        style={{
          position: 'relative',
          width: props.isHome ? '100%' : props.width + scale,
          height: props.height + scale,
          borderRadius: props.isHome ? 100 : props.height / 2 + (props.border || 0) + scale,
          background: props.isHome ? 'white' : props.backgroundColor || '#252525',
          borderWidth: props.isHome ? 1 : 0,
          borderColor: props.isHome ? theme.colors.deepOrange : 0,
          borderStyle: props.isHome ? 'solid' : 'inherit',
        }}
      >
        <div
          className="ProgressBar-bar"
          style={{
            left: props.isHome ? -1 : 0,
            position: 'absolute',
            width: `${props.progress > 8 ? props.progress : props.progress > 0 ? '8' : '0'}%`,
            height: '100%',
            borderRadius: props.height / 2 + scale,
            background: props.isHome ? theme.colors.deepOrange : props.barColor || '#42ca84',
            paddingRight: props.isHome ? 2 : 0,
          }}
        />
        <div
          className="ProgressBar-border"
          style={{
            position: 'absolute',
            width: props.width + scale,
            height: '100%',
            borderRadius: props.height / 2 + (props.border || 0) + scale,
            border: (props.border || 0) + scale + ' solid ' + props.fontColor || '#333',
            boxSizing: 'border-box',
          }}
        />
        <div
          className="ProgressBar-text"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: props.height * 0.5 + scale,
            color: props.fontColor || '#ffffff',
          }}
        >
          {props.isHome ? '' : props.message ? props.message : ''}
        </div>
      </div>
      <p>{props.isHome ? props.message : ''}</p>
    </div>
  );
};

export default ProgressBar;
