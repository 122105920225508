import axios from 'axios';

import { logoutUser, setAuthTimer } from 'actions/auth';
import { isRunningOnRallyAndroidClient, getClientVersionNumber } from 'utils';
import { fetchTimeout } from 'utils/network';
import { NetworkRequestError } from 'services/errors';
import { storeAuthToken } from 'services/AuthAPI';
import { NETWORK_ERROR_TYPES } from 'constants/errors';
import { ERROR_CODES } from 'constants/main';

import store from 'store';

const isRallyAndroidClient = isRunningOnRallyAndroidClient();
const clientVersionNumber = getClientVersionNumber();

export const HEADERS_NO_AUTH = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Application': isRallyAndroidClient ? 'rallyrd-android' : 'rallyrd-web',
  'X-Application-Version': clientVersionNumber,
};

export const HEADERS_WITH_AUTH = token => ({
  Accept: 'application/json',
  Authorization: 'JWT ' + token,
  'Content-Type': 'application/json',
  'X-Application': isRallyAndroidClient ? 'rallyrd-android' : 'rallyrd-web',
  'X-Application-Version': clientVersionNumber,
  'Cache-Control': 'max-age=60, must-revalidate',
});

export const FORM_DATA_HEADERS_WITH_AUTH = token => ({
  Accept: 'application/json',
  Authorization: 'JWT ' + token,
  'X-Application': isRallyAndroidClient ? 'rallyrd-android' : 'rallyrd-web',
  'X-Application-Version': clientVersionNumber,
});

export function getRequest(endpoint, token) {
  return axios
    .get(endpoint, { headers: HEADERS_WITH_AUTH(token), withCredentials: true })
    .catch(error => {
      if (error.response) {
        const errResponse = error.response;
        const errorType =
          errResponse.status >= 400 && errResponse.status < 500
            ? errResponse.status === 401
              ? NETWORK_ERROR_TYPES.CLIENT_UNAUTHORIZED
              : NETWORK_ERROR_TYPES.CLIENT
            : errResponse.status >= 500
            ? NETWORK_ERROR_TYPES.SERVER
            : NETWORK_ERROR_TYPES.GENERIC;

        if (errResponse.status === 401) store.dispatch(logoutUser(ERROR_CODES.UNAUTHORIZED_401)); // unauthorized; therefore logs out user right away
        if (Array.isArray(errResponse.data.errors) && errResponse.data.errors.length) {
          throw new NetworkRequestError(
            errorType,
            errResponse.status,
            errResponse.data.errors[0].message,
          );
        } else {
          throw new NetworkRequestError(errorType, errResponse.status);
        }
      } else if (error.request) {
        throw new NetworkRequestError(
          navigator.onLine
            ? NETWORK_ERROR_TYPES.UNKNOWN_ONLINE
            : NETWORK_ERROR_TYPES.UNKNOWN_OFFLINE,
          JSON.stringify(error),
        );
      } else {
        throw new NetworkRequestError(NETWORK_ERROR_TYPES.REQ_SETUP_FAIL, null, error.message);
      }
    })
    .then(response => {
      const data = response.data;
      if ('token' in data) {
        if (store.getState().Auth.isAuth) storeAuthToken(data.token);
        delete data.token;
      } else {
        if (store.getState().Auth.isAuth) store.dispatch(setAuthTimer()); // call this if there's no new 'token' item found in the response
      }
      if ('errors' in data) {
        return [];
      }
      if ('items' in data && Object.keys(data).length === 1) {
        return data.items;
      }
      return data;
    });
}

export function getRequestNoAuth(endpoint, handleResponse) {
  const requestInit = {
    method: 'GET',
    headers: HEADERS_NO_AUTH,
    credentials: 'same-origin',
  };

  return fetchTimeout(endpoint, requestInit)
    .then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        return { items: [] };
      }
    })
    .then(body => {
      if ('items' in body) {
        return body.items;
      }
      return body;
    });
}

export function postRequest(endpoint, token, body, handleResponse) {
  return axios
    .post(endpoint, body, {
      headers: HEADERS_WITH_AUTH(token),
      withCredentials: true,
    })
    .catch(error => {
      if (error.response) {
        const errResponse = error.response;
        if (errResponse.status === 401) {
          store.dispatch(logoutUser()); // unauthorized; therefore logs out user right away
          return;
        }
        throw error;
      } else if (error.request) {
        throw new NetworkRequestError(
          navigator.onLine
            ? NETWORK_ERROR_TYPES.UNKNOWN_ONLINE
            : NETWORK_ERROR_TYPES.UNKNOWN_OFFLINE,
          null,
        );
      } else {
        throw new NetworkRequestError(NETWORK_ERROR_TYPES.REQ_SETUP_FAIL, null, error.message);
      }
    })
    .then(response => {
      if (!response) {
        return;
      }
      if ('errors' in response) {
        throw response;
      }
      if ('token' in response) {
        storeAuthToken(response.token);
        delete response.token;
      }
      return response;
    });
}

export function postRequestNoAuth(endpoint, body, handleResponse) {
  const requestInit = {
    method: 'POST',
    headers: HEADERS_NO_AUTH,
    body: body,
    credentials: 'include',
  };

  fetch(endpoint, requestInit)
    .then(response => response.json())
    .then(json => {
      handleResponse(json);
    });
}

export function postRequestWithoutHandler(endpoint, token, body) {
  const requestInit = {
    method: 'POST',
    headers: HEADERS_WITH_AUTH(token),
    body: body,
    credentials: 'include',
  };

  return fetch(endpoint, requestInit)
    .then(response => {
      if (response.status === 401) {
        localStorage.removeItem('token');
        throw new Error(401);
      }
      return response.json();
    })
    .then(json => {
      if ('errors' in json) {
        throw json.errors;
      }

      if ('token' in json) {
        storeAuthToken(json.token);
        delete json.token;
      }
      return json;
    });
}
/**
 * A fetch return the entire error not only a property from it
 * preventing errors while logging the response error
 */
export function postFetchRequestWithoutHandler(endpoint, token, body) {
  const requestInit = {
    method: 'POST',
    headers: HEADERS_WITH_AUTH(token),
    body: body,
    credentials: 'include',
  };

  return fetch(endpoint, requestInit)
    .then(response => {
      if (response.status === 401) {
        localStorage.removeItem('token');
        throw new Error(401);
      }
      return response.json();
    })
    .then(json => {
      if ('errors' in json) {
        throw json;
      }
      if ('token' in json) {
        storeAuthToken(json.token);
        delete json.token;
      }
      return json;
    });
}

export function postRequestWithoutHandlerWithFormData(endpoint, token, formData) {
  const requestInit = {
    method: 'POST',
    headers: FORM_DATA_HEADERS_WITH_AUTH(token),
    body: formData,
    credentials: 'include',
  };

  return fetch(endpoint, requestInit)
    .then(response => {
      if (response.status === 401) {
        localStorage.removeItem('token');
        throw new Error(401);
      }
      return response.json();
    })
    .then(json => {
      if ('errors' in json) {
        throw json.errors;
      }

      if ('token' in json) {
        storeAuthToken(json.token);
        delete json.token;
      }
      return json;
    });
}

export function deleteRequestWithoutHandler(endpoint, token) {
  const requestInit = {
    method: 'DELETE',
    headers: HEADERS_WITH_AUTH(token),
    credentials: 'include',
  };

  return fetch(endpoint, requestInit);
}

export function postRequestNoAuthWithoutHandler(endpoint, body) {
  const requestInit = {
    method: 'POST',
    headers: HEADERS_NO_AUTH,
    body: body,
    credentials: 'include',
  };

  return fetch(endpoint, requestInit);
}

export function patchRequest(endpoint, token, body) {
  return axios
    .patch(endpoint, body, {
      headers: HEADERS_WITH_AUTH(token),
      withCredentials: true,
    })
    .catch(error => {
      if (error.response) {
        const errResponse = error.response;
        if (errResponse.status === 401) store.dispatch(logoutUser()); // unauthorized; therefore logs out user right away

        throw errResponse.data;
      } else if (error.request) {
        throw new NetworkRequestError(
          navigator.onLine
            ? NETWORK_ERROR_TYPES.UNKNOWN_ONLINE
            : NETWORK_ERROR_TYPES.UNKNOWN_OFFLINE,
          null,
        );
      } else {
        throw new NetworkRequestError(NETWORK_ERROR_TYPES.REQ_SETUP_FAIL, null, error.message);
      }
    })
    .then(response => {
      const data = response.data;
      if ('errors' in data) {
        throw data;
      }
      if ('token' in data) {
        storeAuthToken(data.token);
        delete data.token;
      }
      return data;
    });
}

export function putRequest(endpoint, token, body, handleResponse) {
  return axios
    .put(endpoint, body, {
      headers: HEADERS_WITH_AUTH(token),
      withCredentials: true,
    })
    .catch(error => {
      if (error.response) {
        const errResponse = error.response;
        if (errResponse.status === 401) {
          store.dispatch(logoutUser()); // unauthorized; therefore logs out user right away
          return;
        }
        throw error;
      } else if (error.request) {
        throw new NetworkRequestError(
          navigator.onLine
            ? NETWORK_ERROR_TYPES.UNKNOWN_ONLINE
            : NETWORK_ERROR_TYPES.UNKNOWN_OFFLINE,
          null,
        );
      } else {
        throw new NetworkRequestError(NETWORK_ERROR_TYPES.REQ_SETUP_FAIL, null, error.message);
      }
    })
    .then(response => {
      if (!response) {
        return;
      }
      if ('errors' in response) {
        throw response;
      }
      if ('token' in response) {
        storeAuthToken(response.token);
        delete response.token;
      }
      return response;
    });
}
