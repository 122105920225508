import React, { Component } from 'react';
import MobileDetect from 'mobile-detect';

/**
 * HOC for detecting the user's device.
 * Passes "userDevice" object to composed components.
 */
const withDeviceDetection = ComposedComponent => {
  return class withDeviceDetection extends Component {
    constructor() {
      super();

      const mobileDetect = new MobileDetect(window.navigator.userAgent);
      this.state = {
        userDevice: {
          isMobile: mobileDetect.mobile(),
          isTablet: mobileDetect.tablet(),
          userAgent: mobileDetect.userAgent(),
          os: mobileDetect.os(),
        },
      };
    }

    render() {
      return <ComposedComponent {...this.props} userDevice={this.state.userDevice} />;
    }
  };
};

export default withDeviceDetection;
