import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import LazyImage from 'components/shared/Media/LazyImage';
import TickerPrice from 'components/shared/TickerPrice';
import './AssetsMenuItem.css';

import withDeviceDetection from 'hoc/withDeviceDetection';
import { SecondaryMarketStatus } from 'types/assets';
import { ASSET_STATUS } from 'constants/main';
import { getSecondaryMarketEnableDiff } from 'utils';

const AssetsMenuItem = React.memo(props => {
  const { asset, isActive } = props;
  const { id, portal_image, ticker, tertiary_color, display_name } = asset;
  const { user } = useSelector(state => state.Auth);
  const activeAsset = useSelector(state => state.Assets.activeAsset);
  const { getSpeciallyAccessibleAssetsIdAccessTypeAndLimitationsMap } = user;
  const ebAssets = getSpeciallyAccessibleAssetsIdAccessTypeAndLimitationsMap
    ? getSpeciallyAccessibleAssetsIdAccessTypeAndLimitationsMap()
    : new Map();
  const ebAsset = ebAssets.get(id);
  const [isEbAsset] = useState(ebAsset && ebAsset.accessType === 'early_io');

  const getValue = () => {
    const { secondaryMarket } = asset.financialInstrument?.markets ?? {};
    const timestampDiff = getSecondaryMarketEnableDiff(secondaryMarket);
    const isTrading =
      (asset.asset_status === ASSET_STATUS.POST_ONLY && timestampDiff <= 0) ||
      (asset.asset_status === ASSET_STATUS.TRADING_OPENED &&
        secondaryMarket.status !== SecondaryMarketStatus.DISABLED);

    const isLiveTrading =
      asset.asset_status === ASSET_STATUS.TRADING_OPENED &&
      secondaryMarket.status === SecondaryMarketStatus.OPEN;

    const isTradingClosed =
      (asset.asset_status === ASSET_STATUS.POST_ONLY && timestampDiff > 0) ||
      asset.asset_status === ASSET_STATUS.TRADING_CLOSED ||
      (asset.asset_status === ASSET_STATUS.TRADING_OPENED &&
        secondaryMarket.status === SecondaryMarketStatus.DISABLED);

    const isSameAsset = isActive && !!activeAsset.trading.pricing;

    const { pricing } = isSameAsset ? activeAsset.trading : asset;
    const numberOfShares = isSameAsset ? activeAsset.number_of_shares : asset.number_of_shares;

    if (!pricing) return asset.asset_value;

    if (isTrading || isTradingClosed) {
      return (
        (pricing.pricePerUnit?.session?.lastClose || pricing.pricePerUnit?.issue) * numberOfShares
      );
    }

    if (isLiveTrading) {
      return (
        (pricing.pricePerUnit?.session?.latestFilled || pricing.pricePerUnit?.current) *
        numberOfShares
      );
    }
    return asset.asset_value;
  };

  const getPriceShift = () => {
    const priceShift = getValue() - asset.asset_value;
    const priceShiftPercentage = ((priceShift / asset.asset_value) * 100).toFixed();
    const priceShiftSymbol = Number(priceShift) > 0 ? '+' : '';
    return `(${priceShiftSymbol}${priceShiftPercentage}%)`;
  };

  const handleClick = () => props.onClick(props.index);

  const getAssetStatus = () => {
    const { asset_status, is_exited, exited_date, progress_bar, financialInstrument } = props.asset;
    const { secondaryMarket } = financialInstrument?.markets ?? {};

    const baseClass = 'AssetItem-info-status';
    let statusClass = `${baseClass} available`;
    let statusText = '';

    const timestampDiff = getSecondaryMarketEnableDiff(secondaryMarket);
    const priceShiftText = getPriceShift();

    if (is_exited) {
      statusClass = `${baseClass} closed`;
      statusText = `EXITED / SOLD ${
        exited_date && 'ON ' + moment(exited_date).format('MM/DD/YYYY')
      }`;
    } else if (asset_status === ASSET_STATUS.TRADING_OPENED) {
      if (!secondaryMarket) {
        statusText = '';
      }
      if (secondaryMarket.status === SecondaryMarketStatus.DISABLED || timestampDiff > 0) {
        statusText = 'TRADING CLOSED';
        statusClass = `${baseClass} closed`;
      } else {
        statusClass = `${baseClass} trading`;
        if (secondaryMarket.status === SecondaryMarketStatus.OPEN) {
          statusText = 'NOW TRADING!';
        } else {
          statusText = 'ACCEPTING ORDERS (POST ONLY)';
        }
      }
    } else if (asset_status === ASSET_STATUS.POST_ONLY) {
      if (secondaryMarket.status === SecondaryMarketStatus.DISABLED || timestampDiff > 0) {
        statusText = 'TRADING CLOSED';
        statusClass = `${baseClass} closed`;
      } else if (timestampDiff <= 0) {
        statusClass = `${baseClass} trading`;
        statusText = 'ACCEPTING ORDERS (POST ONLY)';
      }
    } else if (asset_status === 'Coming Soon') {
      if (isEbAsset) {
        statusText = 'EARLY ACCESS';
      } else {
        statusClass = `${baseClass} comingsoon`;
        statusText = 'PREVIEW (COMING SOON)';
      }
    } else if (asset_status === 'Open ICO') {
      statusClass = `${baseClass} closed`;
      const percent = Math.min(Math.floor(progress_bar), 100);
      let progressBarMessage = (
        <span>
          <span className={`${baseClass} trading`}>{percent}% FUNDED</span> | OPEN
        </span>
      );
      if (percent > 50)
        progressBarMessage = (
          <>
            <span className={`${baseClass} ${percent > 75 ? 'finishing' : 'trading'}`}>
              {100 - percent}% REMAINING
            </span>{' '}
            | OPEN
          </>
        );
      if (percent === 100)
        progressBarMessage = (
          <>
            INITIAL OFFERING COMPLETE | <span className={`${baseClass} finishing`}>SOLD OUT</span>
          </>
        );
      statusText = (
        <>
          <span className={`${baseClass} trading`}>{percent}% FUNDED</span> | OPEN
        </>
      );
      statusText = progressBarMessage;
    } else if (asset_status === 'Closed ICO') {
      statusClass = `${baseClass} closed`;
      statusText = 'OFFERING CLOSED';
    } else if (asset_status === 'Reserve Now') {
      statusClass = `${baseClass} reserveshares`;
      statusText = 'RESERVE YOUR SHARES NOW!';
    } else if (asset_status === 'Trading closed') {
      // TODO remove this in the future
      statusClass = `${baseClass} closed`;
      statusText = 'TRADING CLOSED ' + priceShiftText;
    }

    return (
      <div className={statusClass}>
        <span>{statusText}</span>
      </div>
    );
  };

  return (
    <div
      className={`AssetItem${isActive ? ' selected' : ''}`}
      onClick={handleClick}
      style={props.userDevice.isMobile && { backgroundColor: tertiary_color }}
    >
      <LazyImage
        mode="background"
        className="AssetItem-image-container"
        imageClassName="AssetItem-image"
        src={portal_image}
        offset={10}
      />
      <div className="AssetItem-info">
        <TickerPrice ticker={ticker} price={getValue(isActive)} color={tertiary_color} />
        <div className="AssetItem-info-name">
          <span>{display_name}</span>
        </div>
        {getAssetStatus()}
      </div>
      <div
        className="AssetItem-activebar"
        style={{ visibility: isActive ? 'visible' : 'hidden' }}
      />
    </div>
  );
});

export default withDeviceDetection(AssetsMenuItem);
