import React, { useState, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import styled, { css, keyframes } from 'styled-components';
import * as uuid from 'uuid';

import { DotsLoader } from 'components/shared/Loaders';
import { Checkmark } from 'components/shared/Icons/Checkmark';
import { CheckoutButton } from './MerchandiseCheckoutStyles';
import { ModalViewContext, getViewContexts } from 'components/shared/Modals/Modal';
import { createMerchandiseProductOrder } from 'services/StoreAPI';
import AlertModal from 'components/shared/Modals/AlertModal';
import { MERCHANDISE_CHECKOUT_CONFIRM_COMPLETION_TYPE } from './MerchandiseCheckout';

const MerchandiseCheckoutConfirm = ({ viewId, ...props }) => {
  const { setModalViewRenderSequence, viewArray } = useContext(ModalViewContext);
  const { asset, totalProductsSelectedForPurchase, totalProductsPrice } = getViewContexts(
    viewArray,
    viewId,
  ).contextHandlers.childrenProps;

  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const [paymentAccountTypeName, setPaymentAccountTypeName] = useState('Your Account');
  const [isSuccessfullyCreatedOrder, setIsSuccessfullyCreatedOrder] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [orderRequestResult, setOrderRequestResult] = useState([]);

  useEffect(() => {
    if (!props.bankFundingSource.info.name) return;
    const fundingSource = props.bankFundingSource.info.name.toLowerCase();
    fundingSource.match(/checking/gm)
      ? setPaymentAccountTypeName('Checking Acct')
      : fundingSource.match(/savings/gm)
      ? setPaymentAccountTypeName('Savings Acct')
      : setPaymentAccountTypeName(props.bankFundingSource.info.name);
  }, [props.bankFundingSource.info]);

  const orderOptions = {
    paymentType: {
      ACCOUNT: 'account',
      BALANCE: 'balance',
    },
    contextSubjectType: {
      ASSET: 'asset',
    },
    contextDescriptors: {
      TICKER: 'ticker',
      ID: 'id',
    },
    contextActionType: {
      INVESTMENT: 'investment',
      TRADE: 'trade',
    },
  };

  const createOrderSubmissionObject = (
    cart,
    orderContext,
    paymentMethod,
    orderType = 'cross-sell',
  ) => {
    return {
      cart: cart || [],
      order: {
        context: orderContext || {
          action: {
            type: '',
          },
          subject: {
            descriptors: {},
            type: '',
          },
        },
        payment: {
          method: paymentMethod || {
            descriptors: {},
            type: '',
          },
        },
        type: orderType,
      },
    };
  };

  const createCheckoutOrder = async () => {
    if (isCreatingOrder || isSuccessfullyCreatedOrder) return;

    setOrderRequestResult([]); // init result state again
    setIsCreatingOrder(true);
    try {
      const totalProductsCartData = totalProductsSelectedForPurchase.map(item => {
        delete item.product.data;
        return item;
      });
      const finalOrderSubmissionObject = createOrderSubmissionObject(
        totalProductsCartData,
        {
          action: {
            type: orderOptions.contextActionType.INVESTMENT,
          },
          subject: {
            descriptors: {
              [orderOptions.contextDescriptors.ID]: asset.id,
            },
            type: orderOptions.contextSubjectType.ASSET,
          },
        },
        {
          descriptors: {
            id: props.bankFundingSource.info.id,
          },
          type: orderOptions.paymentType.ACCOUNT,
        },
      );

      const productOrderResult = await createMerchandiseProductOrder(finalOrderSubmissionObject);

      setOrderRequestResult(productOrderResult);
      setIsSuccessfullyCreatedOrder(true);
      setTimeout(() => {
        getViewContexts(viewArray, viewId).onClose(
          MERCHANDISE_CHECKOUT_CONFIRM_COMPLETION_TYPE.COMPLETION_SUCCESSFUL,
        );
      }, 3000);
    } catch (error) {
      setIsCreatingOrder(false);
      setIsSuccessfullyCreatedOrder(false);
      setOrderRequestResult(error);

      setModalViewRenderSequence([
        {
          state: true,
          id: uuid.v4(),
          children: <AlertModal />,
          childrenProps: {
            title: 'Unable to complete purchase',
            message: (
              <span>
                Your purchase from the Rally store could not be completed. Please contact{' '}
                <a href="mailto:support@rallyrd.com">support@rallyrd.com</a> for assistance.
              </span>
            ),
          },
        },
      ]);
    }
  };

  const closeViewWithoutConfirming = () =>
    getViewContexts(viewArray, viewId).onClose(
      MERCHANDISE_CHECKOUT_CONFIRM_COMPLETION_TYPE.COMPLETION_CANCEL,
    );

  return (
    <MakeInvestmentCheckoutConfirmView>
      <TitleNavBar>
        <Title>REVIEW PURCHASE</Title>
        <CloseButton
          disabled={isCreatingOrder || isSuccessfullyCreatedOrder}
          onClick={() => closeViewWithoutConfirming()}
        >
          Close
        </CloseButton>
      </TitleNavBar>

      <PurchaseDetails>
        <ItemRow>
          <ItemType>Amount</ItemType>
          <ItemDetails>
            <li>${totalProductsPrice}</li>
          </ItemDetails>
        </ItemRow>
        <ItemRow>
          <ItemType>Method</ItemType>
          <ItemDetails>
            <li>{paymentAccountTypeName}</li>
            <li>Ending *{props.bankFundingSource.info.accountNumberTail}</li>
          </ItemDetails>
        </ItemRow>
      </PurchaseDetails>

      <PurchaseActions>
        <CheckoutPurchaseButton
          orderSubmittingOrSubmitted={isCreatingOrder || isSuccessfullyCreatedOrder}
          onClick={() => createCheckoutOrder()}
        >
          <ButtonMainText>Complete Purchase</ButtonMainText>
          <ButtonObject show={isCreatingOrder && !isSuccessfullyCreatedOrder}>
            <DotsLoader size={10} color="rgba(0, 0, 0, 0.8)" />
          </ButtonObject>
          <ButtonObject show={isSuccessfullyCreatedOrder}>
            <Checkmark />
          </ButtonObject>
        </CheckoutPurchaseButton>
      </PurchaseActions>
    </MakeInvestmentCheckoutConfirmView>
  );
};

const fadeIn = keyframes`
    1% { opacity: 0; visibility: hidden; }
    2% { opacity: 0; visibility: visible; }
    100% { opacity: 1; visibility: visible; }
`;

const MakeInvestmentCheckoutConfirmView = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  height: 40%;
  padding: 2.8vh 1.5vw;
  width: 40%;
  overflow: auto;
  background: #fff;
  border-radius: 0;
  bottom: 0;
  letter-spacing: -0.03em;

  @media only screen and (max-width: 600px) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-top: 50vh;
    height: auto;
    position: absolute;
    width: calc(100% - 10vw);
    padding: 3.5vh 5vw;
  }
`;

const TitleNavBar = styled.div`
  position: relative;
  display: flex;
  align-items: space-evenly;
  justify-content: space-between;
  border-bottom: 1px solid rgb(231, 231, 231);
  padding: 0 3% 2.8vh 3%;
  width: 94%;
`;

const Title = styled.div`
  color: #000;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 2.5vmin;
  font-family: 'Gotham Medium';
  line-height: 1.2em;

  @media only screen and (max-width: 600px) {
    font-size: 5vmin;
  }
`;

const CloseButton = styled(CheckoutButton)`
  font-family: 'Gotham Medium', sans-serif;
  font-size: 1.7vmin;
  font-weight: 800;
  background: rgba(236, 236, 236, 1);
  color: #202020;
  padding: 7px 13px;
  justify-content: center;

  @media only screen and (max-width: 600px) {
    font-size: 3.5vmin;
    padding: 1.8vmin 3.4vmin;
  }
`;

const PurchaseDetails = styled.div`
  width: 100%;
`;

const ItemRow = styled.div`
  position: relative;
  display: flex;
  align-items: space-evenly;
  justify-content: flex-start;
  padding: 4% 3%;
  border-bottom: 1px solid rgb(231, 231, 231);

  & :last-child {
    border-bottom: none;
  }
`;

const ItemType = styled.span`
  display: flex;
  width: 40%;
  font-weight: 800;
  color: #000;
  font-size: 2.5vmin;
  font-family: 'Gotham Medium';
  line-height: 1.2em;

  @media only screen and (max-width: 600px) {
    font-size: 5vmin;
  }
`;

const ItemDetails = styled.ul`
  display: flex;
  flex-flow: column;
  width: auto;
  color: #000;
  font-size: 2.5vmin;
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
  font-family: 'Gotham Book';

  @media only screen and (max-width: 600px) {
    font-size: 5vmin;
  }

  li {
    padding-bottom: 5px;
  }
`;

const ButtonMainText = styled.span`
  font-weight: 800;
  text-transform: uppercase;
  color: #fff;
  position: absolute;
`;

const PurchaseActions = styled.div`
  position: relative;
  padding: 10% 0;

  @media only screen and (max-width: 600px) {
    width: 80%;
    padding: 15% 0;
  }
`;

const CheckoutPurchaseButton = styled(CheckoutButton)`
  font-family: 'Gotham Medium', sans-serif;
  font-size: 2.6vmin;
  font-weight: 400;
  background-color: ${({ orderSubmittingOrSubmitted }) =>
    orderSubmittingOrSubmitted ? 'rgb(243, 243, 243)' : '#53cd7f'};
  padding: 2.6vmin 3vmin;
  justify-content: space-evenly;
  width: 32vw;
  max-width: 385px;
  min-height: 3.6em;

  @media only screen and (max-width: 600px) {
    font-size: 4vmin;
    padding: 4.2vmin 6vmin;
    width: 100%;
    max-width: none;
  }
`;

const ButtonObject = styled.div`
  position: absolute;
  opacity: 0;
  visibility: hidden;

  ${({ show }) =>
    show &&
    css`
      animation: ${fadeIn} 1000ms forwards;
      max-height: none;
    `};

  .CheckmarkIcon svg {
    color: #000;
  }
`;

const mapStateToProps = state => ({
  activeAsset: state.Assets.activeAsset,
  balance: state.Investments.balance,
  bankFundingSource: state.Investments.bankFundingSource,
  user: state.Auth.user,
});

export default connect(mapStateToProps, null)(MerchandiseCheckoutConfirm);
