import React, { Component } from 'react';

import { Button } from './Button';
import { AdminButton } from './AdminButton';

import APP_STORE_DOWNLOAD from 'images/app-store-download.png';

import './DownloadAppButton.css';

const APP_DOWNLOAD_LINK =
  'https://itunes.apple.com/us/app/rally-rd-invest-in-classic-cars/id1136945620?mt=8';
const ANDROID_DOWNLOAD_LINK =
  'https://play.google.com/store/apps/details?id=com.exchange.rs.rallyrd&utm_source=app&utm_medium=link_contact&utm_campaign=in_app';

export class DownloadAppButton extends Component {
  render() {
    let buttoniOS = <Button text={<b>Download for iPhone &rarr;</b>} theme={this.props.theme} />;
    let buttonAndroid = (
      <Button text={<b>Download for Android &rarr;</b>} theme={this.props.theme} />
    );
    if (this.props.admin_button === true) {
      buttoniOS = <AdminButton text={<b>Download for iPhone</b>} />;
    }
    if (this.props.image_button === true) {
      buttoniOS = <img src={APP_STORE_DOWNLOAD} alt="Download on the iOS app store" />;
    }
    return (
      <div className="DownloadAppButton">
        <a href={APP_DOWNLOAD_LINK} target="_blank" rel="noopener noreferrer">
          {buttoniOS}
        </a>
        <br />
        <a href={ANDROID_DOWNLOAD_LINK} target="_blank" rel="noopener noreferrer">
          {buttonAndroid}
        </a>
      </div>
    );
  }
}

export default DownloadAppButton;
