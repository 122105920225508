import React, { Component } from 'react';
import moment from 'moment';

import './Input.css';

const INPUT_PLACEHOLDERS = {
  date: 'MM/DD/YYYY',
  date_of_birth: 'MM/DD/YYYY',
  ssn: '000-00-0000',
  phone: '(555) 555-5555',
  password: '• • • • • • • • • • • •',
};

export class Input extends Component {
  // Store the actual input value so that it is possible to apply mask's to this value
  // (important for SSN inputs - see 'getRenderValue()' method)
  constructor(props) {
    super(props);

    this.state = { inputValue: this.props.value || '' };
  }

  /**
   * Formats input value and passes it to container component via onChange prop.
   * SSN fields are processed in a special way in order to be able to apply input masks.
   * @param  {Event} - 'onchange' event object
   */
  handleChange = e => {
    const value = e.target.value;
    const type = this.getInputType();
    let inputValue = type === 'ssn' ? this.state.inputValue : value;

    if (type === 'ssn') {
      inputValue =
        value.length > inputValue.length
          ? this.getFormattedValue(inputValue + this.digitsOnly(value.slice(-1)), e)
          : this.getFormattedValue(inputValue.slice(0, -1), e);
    } else {
      inputValue = this.getFormattedValue(inputValue, e);
    }

    if (!this.props.onChange) {
      throw new Error('onChange prop is required for <Input/> component!');
    }

    this.props.onChange(inputValue);
    this.setState({ inputValue });

    if (this.props.valid === false) {
      this.checkValid();
    }
  };

  handleFocus = e => {
    e.target.placeholder = '';
  };

  handleBlur = e => {
    this.checkValid();
    e.target.placeholder = this.getPlaceholder();
    if (this.props.onBlur) this.props.onBlur();
  };

  checkValid() {
    if (typeof this.props.checkValid === 'function') {
      this.props.checkValid();
    }
  }

  getInputType() {
    return typeof this.props.type !== 'undefined' ? this.props.type : 'text';
  }

  getPlaceholder() {
    const type = this.getInputType();
    if (typeof this.props.placeholder !== 'undefined') {
      return this.props.placeholder;
    } else {
      let placeholder = INPUT_PLACEHOLDERS[type];
      return typeof placeholder !== 'undefined' ? placeholder : '';
    }
  }

  formatPhoneNumber(phoneNumber) {
    phoneNumber = this.digitsOnly(phoneNumber);
    if (phoneNumber.length >= 4) {
      phoneNumber = '(' + phoneNumber.substr(0, 3) + ') ' + phoneNumber.substr(3);
    }
    if (phoneNumber.length >= 10) {
      phoneNumber = phoneNumber.substr(0, 9) + '-' + phoneNumber.substr(9, 4);
    }
    return phoneNumber;
  }

  formatSSN(socialSecurityNumber) {
    let ssn = this.digitsOnly(socialSecurityNumber);
    if (ssn.length >= 4) {
      socialSecurityNumber = ssn.substr(0, 3) + '-' + ssn.substr(3);
    }
    if (ssn.length >= 6) {
      socialSecurityNumber =
        socialSecurityNumber.substr(0, 6) + '-' + socialSecurityNumber.substr(6, 4);
    }
    return socialSecurityNumber;
  }

  maskSSN(formattedSSN) {
    const maskedDigits = formattedSSN.substr(0, 7).replace(/[0-9]/g, '*');
    return maskedDigits + formattedSSN.substr(7);
  }

  formatZIP(zipcode) {
    zipcode = this.digitsOnly(zipcode);
    if (zipcode.length >= 5) zipcode = zipcode.substr(0, 5);
    return zipcode;
  }

  formatDateOfBirth(value, event) {
    // Allow to enter only digits (0-9) and slashes ('/'):
    value = value.replace(/[^0-9|/]/g, '');

    // Detect if the suer enters new charachter:
    const isCharachterAdded = value.length > this.state.inputValue.length;

    // Automatically add slashes when user types in new charachter:
    if (isCharachterAdded) {
      // detect if the new charachter is slash ('/')
      if (event && event.nativeEvent.data === '/') {
        // allow to type slashes only after first two and the second two digits:
        if (/^\d{2}\/$/.test(value) || /^\d{2}\/\d{2}\/$/.test(value)) {
          return value;
        } else {
          return this.state.inputValue; // return previous value
        }
      }
      // Detect if slash charachter should be added to the new value ↓

      // add slash after the first two digits '/'
      if (/^\d{2}$/.test(value)) {
        value = value + '/';
        // if three digits, add '/' before the last digit
      } else if (/^\d{3}$/.test(value)) {
        value = `${value.substr(0, 2)}/${value.substr(2)}`;
        // if four digits, add '/'
      } else if (/^\d{2}\/\d{2}$/.test(value)) {
        value = value + '/';
        // if five digits, add '/' before the last number
      } else if (/^\d{2}\/\d{3}$/.test(value)) {
        value = `${value.substr(0, 5)}/${value.substr(5, 4)}`;
      }
    }

    // Allow max 10 charachters (8 digits and two slashes):
    if (value.length > 10) return value.slice(0, -1);

    return value;
  }

  formatAddress(address) {
    let lastComma = address.indexOf(',');
    return lastComma === -1 ? address : address.substr(0, lastComma);
  }

  digitsOnly(string) {
    return string.replace(/[^0-9]/g, '');
  }

  getFormattedValue(value, event) {
    let type = this.getInputType();

    if (typeof value !== 'undefined' && value !== '') {
      switch (type) {
        case 'date':
          // case 'birthday':
          value = moment(value).format('YYYY-MM-DD');
          break;
        case 'date_of_birth':
          value = this.formatDateOfBirth(value, event);
          break;
        case 'ssn':
          value = this.formatSSN(value);
          break;
        case 'phone':
        case 'phone_number':
          value = this.formatPhoneNumber(value);
          break;
        case 'zip':
        case 'zipcode':
        case 'zip_code':
        case 'postal_code':
          value = this.formatZIP(value);
          break;
        case 'address':
        case 'street_address':
          value = this.formatAddress(value);
          break;
        default:
      }
    }

    // Only digits with 'numbersOnly' props:
    if (this.props.numbersOnly && type === 'text' && value) {
      value = this.digitsOnly(value);
    }

    // Fractional numbers with 'decimalsOnly' props:
    if (this.props.decimalsOnly && type === 'text' && value) {
      const incompleteNumberRegex = /^([+-]|\.0*|[+-]\.0*|[+-]?\d+\.)?$/;

      if (value === '.') return '';
      if (incompleteNumberRegex.test(value)) return value;

      const decimalNumbers = value.replace(/[^0-9.]+/g, '');
      return decimalNumbers ? String(parseFloat(decimalNumbers)) : '';
    }

    return value;
  }

  getRenderValue() {
    const { value, shouldMaskSsn } = this.props;
    if (!value) return '';

    const type = this.getInputType();
    const formattedValue = this.getFormattedValue(value);

    switch (type) {
      case 'ssn':
        return shouldMaskSsn ? this.maskSSN(formattedValue) : formattedValue;
      default:
        return formattedValue;
    }
  }

  render() {
    let validClass = this.props.valid === false ? 'invalid' : '';
    let inputType = this.getInputType();
    let placeholder = this.getPlaceholder();
    let value = this.getRenderValue();

    let description =
      typeof this.props.description !== 'undefined' ? (
        <span className="Input-description">{this.props.description}</span>
      ) : (
        ''
      );
    return (
      <div className={'Input ' + validClass}>
        <input
          type={inputType}
          name={this.props.name}
          autoFocus={this.props.autoFocus}
          inputMode={this.props.inputMode}
          value={value}
          placeholder={placeholder}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onKeyPress={this.props.onKeyPress}
          disabled={this.props.disabled}
        />
        {description}
      </div>
    );
  }
}

export default Input;
