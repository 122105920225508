import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateInvestmentFlow } from 'actions';
import { BackNextButtons } from 'components/shared/Buttons/BackNextButtons';
import { Input } from 'components/shared/Inputs/Input';

class OrganizationName extends Component {
  static propTypes = {
    data: PropTypes.object,
    onFormUpdate: PropTypes.func.isRequired,
    onStepForward: PropTypes.func.isRequired,
    onStepBackward: PropTypes.func.isRequired,
    updateInvestmentFlow: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const isStepValid = this.validate();
    this.state = { isStepValid };
    this.initialData = this.props.data;
  }

  validate = () => {
    const {
      data: { sec_or_finra_organization_name },
    } = this.props;
    const isStepValid =
      typeof sec_or_finra_organization_name === 'string' && sec_or_finra_organization_name !== '';

    return isStepValid;
  };

  validateStep = () => {
    const isStepValid = this.validate();
    this.setState({ isStepValid });
  };

  onOrganizationNameChange = value =>
    this.props.onFormUpdate(
      {
        sec_or_finra_organization_name: value,
      },
      this.validateStep,
    );

  handleNextClick = () => {
    const { sec_or_finra_organization_name } = this.props.data;
    this.props.updateInvestmentFlow({ investment_info: { sec_or_finra_organization_name } });
    this.props.onStepForward();
  };

  handleBackClick = () => {
    if (this.props.resetOnBackPress) {
      const { sec_or_finra_organization_name } = this.initialData;
      this.props.onFormUpdate({ sec_or_finra_organization_name });
    }
    this.props.onStepBackward();
  };

  render() {
    const {
      data: { sec_or_finra_organization_name },
    } = this.props;

    return (
      <div>
        <p>Please Provide the name of the institution or organization</p>
        <Input
          onChange={this.onOrganizationNameChange}
          value={sec_or_finra_organization_name}
          autoFocus={true}
          placeholder="Organization Name"
        />
        <BackNextButtons
          buttons={{ back: { text: 'back' }, next: { text: 'next' } }}
          nextDisabled={!this.state.isStepValid}
          onBackClick={this.handleBackClick}
          onNextClick={this.handleNextClick}
        />
      </div>
    );
  }
}

const mapDispatchToProps = { updateInvestmentFlow };

export default connect(null, mapDispatchToProps)(OrganizationName);
