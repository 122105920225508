import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Price } from 'components/shared/Price';
import { BackNextButtons } from 'components/shared/Buttons/BackNextButtons';
import NumberInput from 'components/shared/Inputs/NumberInput';
import GiftPopup from './GiftPopup';
import { setGiftCodeApplied, setAutoGiftCode, removeGiftCodeApplied, setSaPopup } from 'actions';
import giftImage from 'images/gift.png';
import analytics from 'services/analytics';
import { roundAccurately, isEmpty } from 'utils';

import { SPECIAL_ACCESS_LIMITATION_TYPES, SUPPORTED_APPLICATION_MODES } from 'constants/main';
import { SEGMENT_ACTIONS, SEGMENT_CATEGORIES } from 'constants/analytics';

import './SharesSelect.scss';

class SharesSelect extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    onFormUpdate: PropTypes.func.isRequired,
    onStepForward: PropTypes.func.isRequired,
    onStepBackward: PropTypes.func.isRequired,
  };

  state = {
    isStepValid: false,
    giftPopupOpened: false,
    maxShares: this.props.asset.max_share_investment - this.props.data.userShares,
    pricePerShare: this.props.asset.asset_value / this.props.asset.number_of_shares,
    loading: false,
  };

  componentDidMount() {
    this.setMaxSharesCount();

    const { saPopup, asset } = this.props;
    const autoGiftEnabled = saPopup?.autoGiftCode && saPopup?.asset?.id === asset.id;

    if (autoGiftEnabled) {
      this.setState({ loading: true });
      setTimeout(() => {
        this.setState({ loading: false });
        this.handleAutoGiftCode();
      }, 1000);
    }
  }

  handleAutoGiftCode = () => {
    this.setState({ giftPopupOpened: true });
  };

  setMaxSharesCount = () => {
    const { userShares } = this.props.data || {};
    const { asset, user, saPopup } = this.props;
    if (!asset) return;

    const totalAvailableShares = this.props.data.availableShares;

    let maximumShareRestriction = asset.max_share_investment;
    // when in special access mode, we need to determine if the asset has restrictions (limitations) imposed on the max
    //  share count.
    const saAsset =
      user
        .getSpeciallyAccessibleAssetsIdAccessTypeAndLimitationsMap({
          assetIds: [asset.id],
        })
        .get(asset.id) || {};

    const isOnFreeShareFlow =
      saPopup && !isEmpty(saPopup.asset) && saPopup.asset?.type === 'free_share_program';
    const hasFreeShareIdAndType =
      saPopup?.asset?.id === asset?.id && saPopup?.asset?.type === saAsset?.accessType;

    if (
      this.props.applicationMode === SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS ||
      saAsset?.accessType === 'early_io' ||
      (isOnFreeShareFlow && hasFreeShareIdAndType)
    ) {
      const { limitations } =
        user
          .getSpeciallyAccessibleAssetsIdAccessTypeAndLimitationsMap({ assetIds: [asset.id] })
          .get(asset.id) || {};

      if (limitations && limitations.length) {
        const share_restriction_limitation = limitations.find(
          limitation => limitation.type === SPECIAL_ACCESS_LIMITATION_TYPES.SHARE_RESTRICTION,
        );
        if (
          share_restriction_limitation.values &&
          typeof share_restriction_limitation.values.limit === 'number'
        ) {
          maximumShareRestriction = share_restriction_limitation.values.limit;
        }
      }
    }

    const userAvailableShares = maximumShareRestriction - userShares;

    if (totalAvailableShares < maximumShareRestriction) {
      maximumShareRestriction = totalAvailableShares;
    }
    if (userAvailableShares < totalAvailableShares) {
      maximumShareRestriction = userAvailableShares;
    }
    if (maximumShareRestriction < 0) {
      maximumShareRestriction = 0;
    }

    this.setState({ maxShares: maximumShareRestriction }, () => {
      // if no shares count was provided, default to 5
      const startingSharesCount =
        typeof this.props.data.shares === 'number' ? this.props.data.shares : 5;
      this.onSharesChange(
        startingSharesCount < maximumShareRestriction
          ? startingSharesCount
          : maximumShareRestriction,
      );
      this.validateStep();
    });
  };

  onSharesChange = shares => {
    shares = this.validateShares(Number(shares));
    this.props.onFormUpdate(
      {
        shares,
        cost: Math.max(
          shares * roundAccurately(this.state.pricePerShare, 2) - this.props.data.giftCode.value,
          0,
        ),
      },
      this.validateStep,
    );
  };

  validateShares = shares => {
    const { maxShares } = this.state;
    const { asset } = this.props;

    if (shares > maxShares) shares = maxShares;
    if (shares > asset.max_share_investment) shares = asset.max_share_investment;
    if (shares < 0) shares = 0;

    return shares;
  };

  validateStep = () => {
    const {
      data: { shares },
    } = this.props;
    const isStepValid = Number.isInteger(shares) && shares > 0;
    this.setState({ isStepValid });
  };

  openGiftPopup = () => {
    this.setState({ giftPopupOpened: true });
    // Track window opening.
    analytics.page('Promo Code Entry View', {
      title: 'Promo Code Entry View',
    });
  };

  closeGiftPopup = () => this.setState({ giftPopupOpened: false });

  handleGiftCodeApply = giftCode => {
    this.props.setGiftCodeApplied(giftCode);
    this.props.onFormUpdate({
      giftCode,
      cost: Math.max(
        this.props.data.shares * roundAccurately(this.state.pricePerShare, 2) - giftCode.value,
        0,
      ),
    });
  };

  // Handles progressing the order form while tracking the user's action.
  progressAndTrack = shares => {
    analytics.track('Buy Shares Order Entry - Next', {
      action: SEGMENT_ACTIONS.NEXT_TAPPED_CLICKED,
      shares: this.props.data.shares,
      cost: this.props.data.cost,
      assetTicker: this.props.asset.ticker,
      category: SEGMENT_CATEGORIES.BUY_SHARES,
    });
    this.props.onStepForward();
    return;
  };

  // Handles reverting the order form while tracking the user's action.
  backAndTrack = shares => {
    analytics.track('Buy Shares Order Entry - Back', {
      action: SEGMENT_ACTIONS.BACK_TAPPED_CLICKED,
      shares: this.props.data.shares,
      cost: this.props.data.cost,
      assetTicker: this.props.asset.ticker,
      category: SEGMENT_CATEGORIES.BUY_SHARES,
    });
    this.props.onStepBackward();
    return;
  };

  render() {
    const { maxShares, pricePerShare, giftPopupOpened, loading } = this.state;
    const { asset, saPopup } = this.props;
    const { shares, cost } = this.props.data;
    const isOnFreeShareFlow =
      saPopup && !isEmpty(saPopup.asset) && saPopup.asset?.type === 'free_share_program';

    return (
      <div className="SharesSelect">
        <h2>
          Each share of&nbsp;
          <span className="primary">{asset.ticker}</span>=
          <span className="primary">
            <Price price={pricePerShare} />
          </span>
        </h2>
        <p>
          {maxShares > 0 ? (
            <span>
              You can purchase up to
              <br />
              {maxShares} shares
            </span>
          ) : (
            <span>
              Sorry, looks like you've already purchased the <br />
              maximum number of allowed shares.
            </span>
          )}
        </p>
        {maxShares > 0 && (
          <>
            <p>
              I want to buy
              <NumberInput
                readOnly={giftPopupOpened} // Safari fix: unfocusing input when popup is opened
                value={shares}
                onChange={this.onSharesChange}
                precision={0} // integers only
                min={1}
                max={maxShares}
              />
              share{shares === '1' ? '' : 's'}
            </p>

            <p>
              at a total cost of:{' '}
              <b>
                <Price price={cost} />
              </b>
            </p>
            <BackNextButtons
              buttons={{ back: { text: 'back' }, next: { text: 'next' } }}
              nextDisabled={!this.state.isStepValid || loading}
              onBackClick={() => this.backAndTrack()}
              onNextClick={() => this.progressAndTrack()}
            />
            {!isOnFreeShareFlow && (
              <button className="SharesSelect-gift-btn" onClick={this.openGiftPopup}>
                <img src={giftImage} alt="" />
                Have a Gift Code?
              </button>
            )}
          </>
        )}
        {!loading && (
          <GiftPopup
            show={giftPopupOpened}
            onRequestClose={this.closeGiftPopup}
            onCodeApply={this.handleGiftCodeApply}
          />
        )}
      </div>
    );
  }
}

// Extract asset data directly from redux store,
// to make sure that accessing actual data even after updating the assets list:
const mapStateToProps = ({
  Assets: { activeAsset, assetList },
  Auth: { user },
  UI: { applicationMode, saPopup },
}) => {
  return {
    applicationMode,
    asset: assetList.find(asset => asset.id === activeAsset.id),
    user,
    saPopup,
  };
};

const mapDispatchToProps = {
  setGiftCodeApplied,
  setAutoGiftCode,
  removeGiftCodeApplied,
  setSaPopup,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SharesSelect);
