import React, { Component } from 'react';
import { connect } from 'react-redux';

import './DisclaimerPage.css';

class DisclaimerPage extends Component {
  render() {
    return (
      <div className="DisclaimerPage">
        <p>
          <strong>COMMUNICATION DISCLAIMER </strong>This disclaimer is deemed to be incorporated by
          reference in its entirety into any social media communication, advertisement, email or
          other communication or disclosure which contains the link{' '}
          <a href="https://www.rallyrd.com/disclaimer">www.rallyrd.com/disclaimer</a> (each, a “
          <u>Communication</u>”).
        </p>
        <p>
          RSE Collection, LLC and RSE Archive, LLC may be referred to individually as a “Rally
          Entity” or together as the “Rally Entities”.
        </p>
        <p>
          The offer and sale of securities is being facilitated by an unaffiliated third-party
          registered broker-dealer (member FINRA/SIPC) only in U.S. states where such broker-dealer
          is registered. Neither RSE Markets, RSE Collection, RSE Archive nor any third-party
          broker-dealer provides any investment advice or make any investment recommendations to any
          persons, ever, and no communication through the rallyrd.com website or in any other medium
          should be construed as such. The Communication and the subject matter contained within it,
          does not constitute a solicitation to purchase or an offer to sell any securities.
        </p>
        <p>
          <strong>Offerings in Rally Entities</strong>
        </p>
        <p>
          Investors are able to acquire membership interests in only a series of a Rally Entity, a
          Delaware series limited liability company (the “<u>Company</u>”), via the Rally™ platform
          and app (the “<u>Rally™ Platform”</u> or “<u>Platform</u>”). The use of the word “
          <u>Share</u>” or ”<u>Stock</u>” in any Communication refers to membership interests (an “
          <u>Interest</u>” or “<u>Interests</u>”) in a series of a Rally Entity (a “<u>Series</u>”).
          Each offering of shares in each Series of the Company is defined herein as an “
          <u>Offering</u>” and is subject to its own private placement memorandum (each, a “
          <u>Memorandum</u>”) or offering circular (each an “<u>Offering Circular</u>”) as the case
          may be. These important documents are available via the Rally™ Platform in the “Legal” tab
          for each Offering, or by requesting a copy by e-mailing hello@rallyrd.com, or, in the case
          of the Offering Circular, publicly via the U.S. Securities & Exchange Commission EDGAR
          service, and should be read by all investors prior to acquiring any membership interests.
        </p>
        <p>
          The Company is structured as a Delaware series limited liability company that issues
          different Series of Interests specific to one or more collectible underlying assets (“
          <u>Underlying Assets</u>”). Each Series of interests is not a separate legal entity, but
          is intended to segregate assets, liabilities, profits and taxes pertaining to the
          Underlying Asset from each other Series of Interests (which may own other assets). Each
          Offering entitles a person to acquire an ownership Interest in a Series of the Company and
          not, for the avoidance of doubt, in (i) the Company, (ii) any other Series of the Company
          other than the Series of Interests subject to the Offering at that time, (iii) RSE
          Markets, Inc. (the “<u>Manager</u>” and the “<u>Asset Manager</u>”), (iv) the Rally™
          Platform or (v) any Underlying Asset held by each Series of the Company.
        </p>
        <p>
          Each Offering is being conducted (i) under Rule 506(c) of the Securities Act of 1933, as
          amended (the “<u>Securities Act</u>”) or under Tier II of Regulation A of the Securities
          Act as amended, (ii) only through a Private Placement Memorandum or Offering Circular and
          (iii) exclusively through a broker-dealer registered with the U.S. Securities and Exchange
          Commission (the “SEC”) and a member of the Financial Industry Regulatory Authority, Inc.
          and the Securities Investor Protection Corporation and other necessary state or other
          regulators, and only in such states where the broker-dealer is registered. Membership
          Interests offered under Rule 506(c) are being offered and sold only to “accredited
          investors” within the meaning of Rule 501 of Regulation D under the Securities Act,
          pursuant to the Memorandum and related subscription documents. Individuals are accredited
          investors only if they meet certain minimum net worth or sustained annual income
          thresholds. Entities are accredited investors only if they hold assets of at least $5
          million or are completely owned by accredited investors. Other membership Interests are
          being offered and sold to “qualified investors” under Tier II of Regulation A under the
          Securities Act, pursuant to an Offering Circular as qualified by the SEC. Even so, each
          investor must rely on its own examination of the Company, the Series, the Interests and
          the Underlying Asset and the terms of the Offering, including the risks and merits
          involved, before making any investment.
        </p>
        <p>
          From time to time the Company will conduct “testing the waters” campaigns to gauge market
          demand from potential investors for an Offering under Tier II of Regulation A of the
          Securities Act. No money or other consideration will be solicited, and if sent in
          response, it will not be accepted. No sales of securities will be made or commitment to
          purchase accepted until qualification of the Offering Circular by the SEC and approval of
          any other required government or regulatory agency. An indication of interest made by a
          prospective investor is non-binding and involves no obligation or commitment of any kind.
          Any offer to buy securities may be withdrawn or revoked, without obligation or commitment
          of any kind, at any time before notice of its acceptance given after the qualification
          date. No offer to buy securities can be accepted and no part of the purchase price can be
          received without an Offering Circular that has been qualified by the SEC, which we urge
          prospective investors to read carefully. A copy of the most recent version of the Offering
          Circular may be obtained by contacting the Company at 250 Lafayette Street, 3rd Floor, New
          York, NY 10012, (347) 952-8058, emailing hello@rallyrd.com, or via the Rally™ Platform in
          the “Legal” tab for each Offering, or accessed online here:{' '}
          <a href="https://www.sec.gov/cgi-bin/browse-edgar?CIK=0001688804&amp;action=getcompany">
            SEC EDGAR SERVICE
          </a>
        </p>
        <p>
          No Offering is being made in any jurisdiction where such an offer or solicitation is not
          lawful or is prohibited or where the broker-dealer, through whom each Offering is being
          conducted, is not registered. Each Offering of the membership Interests is made pursuant
          to an exemption from the registration requirements of the Securities Act and certain state
          securities laws. The Company is not required to file periodic reports (such as reports on
          Forms 10-K and 10-Q) with the SEC, so there is little publicly available information about
          its business, assets, liabilities, results of operations and other information that would
          typically be available regarding publicly traded securities. The Company is not registered
          as an investment company under the Investment Company Act of 1940, as amended (the “
          <u>Investment Company Act</u>”) and the membership Interests do not have the benefit of
          the protections of the Investment Company Act. Furthermore, the Manager is not registered
          as an investment adviser under the Investment Advisers Act of 1940, as amended (the “
          <u>Investment Advisers Act</u>”), and the members of the Company will not have the benefit
          of the protections of the Investment Advisers Act.
        </p>
        <p>
          Neither the SEC nor any state securities commission has approved or disapproved the
          membership Interests nor have any of the foregoing passed upon or endorsed the merits of
          an Offering or the accuracy or adequacy of any of the Memorandum, Offering Circular, or
          any Communication. Any representation to the contrary is a criminal offense.
        </p>
        <p>
          There is no trading market for the membership Interests at this time and there can be no
          assurance that such a market will develop in the foreseeable future. The membership
          Interests may not be resold or otherwise disposed of by an investor unless there are
          available exemptions from registration under federal and applicable state securities laws
          (and other requirements are met, which may include an opinion of counsel), or such
          transfer is made in compliance with the registration requirements of such laws.
          Accordingly, investors must bear the risk of loss for an indefinite period of time.
        </p>
        <p>
          An investment in membership Interests may involve significant risks. Only investors who
          can bear the economic risk of the investment for an indefinite period of time and the loss
          of their entire investment should invest in the membership Interests. See “Risk Factors”
          below and in the Memorandum or Offering Circular as the case may be.
        </p>
        <p>
          No offeree will be accepted as a subscriber who does not make the representations set
          forth in the subscription agreement accompanying each Memorandum or Offering Circular,
          including, when applicable, the representation that such offeree is an accredited investor
          and is acquiring the membership Interests for investment and not with a view to resale or
          distribution thereof in violation of applicable securities laws, and, when applicable,
          that the investment amount does not exceed 10% of the offeree’s net worth or annual
          income. Investors also will be required to represent that they are familiar with and
          understand the terms of each relevant Offering, among other things. Investors may also be
          required to provide additional information to verify their identity or investor status.
        </p>
        <p>
          The Interests will not be offered or sold to prospective investors subject to the Employee
          Retirement Income Security Act of 1974 and regulations thereunder, as amended (“
          <u>ERISA</u>”).
        </p>
        <p>
          <strong>
            <em>Participation in Trading Windows</em>
          </strong>
        </p>
        <p>
          If you purchase shares during a Trading Window, you agree to be admitted as a member of a
          Rally Entity and further agree to be bound by the provisions of, and deemed to be a party
          to, the Operating Agreement of the Rally Entity. As a party to the Operating Agreement, a
          buyer of shares grants to the manager of Rally Entities, RSE Markets, Inc., a power of
          attorney to, among other things, execute and file documents required for the Rally
          Entity’s qualification, continuance or dissolution. The power of attorney also grants to
          the manager the authority to make certain amendments to, and to execute and deliver such
          other documents as may be necessary or appropriate to carry out the provisions or purposes
          of, the Operating Agreement, which includes all decisions regarding the asset underlying
          your shares including the sale of that asset.
        </p>
        <p>
          <strong>
            <em>Notice to Foreign Investors</em>
          </strong>
        </p>
        <p>
          The Communication is directed solely to persons located within the United States. If the
          recipient of the Communication lives outside the United States, it is their responsibility
          to fully observe the laws of any relevant territory or jurisdiction outside the United
          States in connection with any purchase of membership Interests, including obtaining
          required governmental or other consents or observing any other required legal or other
          formalities.
        </p>
        <p>
          <strong>
            <em>Risk Factors</em>
          </strong>
        </p>
        <p>
          Each Offering of membership Interests is highly speculative in nature, involves a high
          degree of risk and should be purchased only by persons who can afford to lose their entire
          investment. The investments in any Offering are not bank deposits (and thus not insured by
          the FDIC or by any other federal governmental agency), are not guaranteed by RSE Markets,
          RSE Collection, RSE Archive or any third-party broker-dealer and may lose value. The risks
          described below should not be considered as an exhaustive list of the risks that
          prospective investors should consider before investing in the membership Interests. All
          prospective investors should consult the relevant Memorandum or Offering Circular before
          purchasing membership Interests in a Series of the Company, which Memorandum or Offering
          Circular may describe the following risks in more detail and additional risks pertinent to
          the specific Offering.
        </p>
        <p>
          <em>Risks relating to the structure, operation and performance of the Company</em>
        </p>
        <ul>
          <li>
            An investment in an Offering constitutes only an investment in that Series and not in
            the Company or directly in any Underlying Asset.
          </li>
          <li>
            There is currently no trading market for our securities. An active market in which
            investors can resell their Interests may not develop.
          </li>
          <li>
            The Company does not have a significant operating history and, as a result, there is a
            limited amount of information about us on which to base an investment decision.
          </li>
          <li>
            There can be no guarantee that the Company will reach its funding target from potential
            investors with respect to any Series or future proposed Series of Interests.
          </li>
          <li>There is substantial doubt about our ability to continue as a going concern.</li>
          <li>
            There are few businesses that have pursued a strategy or investment objective similar to
            the Company’s.
          </li>
          <li>
            The amount raised at each Offering may exceed the value of the Underlying Asset acquired
            in connection with such Offering.
          </li>
          <li>
            The operating expenses related to a Series of the company may exceed the revenues
            generated by such Series and excess operating expenses could then materially and
            adversely affect the value of Interests and result in dilution to Investors.
          </li>
          <li>
            The success of the Company is dependent on the Manager and its team to source, acquire
            and manage Underlying Assets.
          </li>
          <li>
            The Company is reliant on the Manager and Asset Manager and their respective personnel.
            Our business and operations could be adversely affected if the Manager or Asset Manager
            lose key personnel.
          </li>
          <li>
            If the Company’s series limited liability company structure is not respected, then
            Investors may have to share any liabilities of the Company with all Investors and not
            just those who hold the same Series of Interests as them.
          </li>
          <li>
            The Company is currently expanding and improving our information technology systems and
            use security measures designed to protect our systems against breaches and
            cyber-attacks. If these efforts are not successful, our business and operations could be
            disrupted, our operating results and reputation could be harmed, and the value of the
            Interests could be materially and adversely affected.
          </li>
          <li>
            System limitations or failures could harm our business and may cause the Asset Manager
            or Manager to intervene into activity on the Rally™ Platform.
          </li>
          <li>The Rally™ Platform is highly technical and may be at a risk to malfunction.</li>
          <li>
            There can be no guarantee that any liquidity mechanism for secondary sales of Interests
            will develop on the Rally™ Platform in the manner described, that registered
            broker-dealers will desire to facilitate liquidity in the Interests for a level of fees
            that would be acceptable to Investors or at all, that such Trading Windows will occur
            with high frequency if at all, that a market-clearing price (e.g., a price at which
            there is overlap between bid and ask prices) will be established during any Trading
            Window or that any buy or sell orders will be filled.
          </li>
          <li>
            The Company does not anticipate the use of Manager-owned Interests for liquidity or to
            facilitate the resale of Interests held by Investors.
          </li>
          <li>
            Abuse of our advertising or social platforms may harm our reputation or user engagement.
          </li>
          <li>
            If the Company is unable to protect our intellectual property rights, our competitive
            position could be harmed, or we could be required to incur significant expenses to
            enforce our rights.
          </li>
        </ul>
        <p>
          <em>Risks related to an Offering</em>
        </p>
        <ul>
          <li>
            The Company is offering our Interests pursuant to Tier 2 of Regulation A and we cannot
            be certain if the reduced disclosure requirements applicable to Tier 2 issuers will make
            our Interests less attractive to Investors as compared to a traditional initial public
            offering.
          </li>
          <li>
            There may be deficiencies with our internal controls that require improvements, and if
            we are unable to adequately evaluate internal controls, we may be subject to sanctions.
          </li>
          <li>
            If a regulator determines that the activities of either the Manager or Asset Manager
            require its registration as a broker-dealer, the Asset Manager or Manager may be
            required to cease operations and any Series of Interests offered and sold without such
            proper registration may be subject to a right of rescission.
          </li>
          <li>
            If at any time regulators deem the liquidity platform a securities exchange or
            alternative trading system this may require us to cease operating the platform and will
            materially and adversely affect your ability to transfer your Interests.
          </li>
          <li>
            If we are required to register under the Exchange Act, it would result in significant
            expense and reporting requirements that would place a burden on the Manager and Asset
            Manager and may divert attention from management of the Underlying Assets by the Manager
            and Asset Manager or could cause Asset Manager to no longer be able to afford to run our
            business.
          </li>
          <li>
            If the Company were to be required to register under the Investment Company Act or the
            Manager or the Asset Manager were to be required to register under the Investment
            Advisers Act, it could have a material and adverse impact on the results of operations
            and expenses of each Series and the Manager and the Asset Manager may be forced to
            liquidate and wind up each Series of Interests or rescind the Offerings for any of the
            Series or the offering for any other Series of Interests.
          </li>
          <li>
            Changes in Federal Tax Laws may adversely affect securities issued by the Company.
          </li>
        </ul>
        <p>
          <em>Risks Specific to the Industry and the Asset Class</em>
        </p>
        <ul>
          <li>There may be potential negative changes within the asset class.</li>
          <li>
            Investment in a limited number of investment opportunities on the Rally™ Platform may
            result in a lack of diversification for an investor.
          </li>
          <li>
            There could be a general downtown in the industry which would likely impact the value of
            the Underlying Assets given the concentrated nature of the industry.
          </li>
          <li>There may be a volatile demand for the assets in the asset class.</li>
          <li>
            The company will rely on data from past auction sales and insurance data, among other
            sources, in determining the value of the Underlying Assets, and have not independently
            verified the accuracy or completeness of this information. As such, valuations of the
            Underlying Assets may be subject to a high degree of uncertainty and risk.
          </li>
        </ul>
        <p>
          <em>Risks relating to the Underlying Assets</em>
        </p>
        <ul>
          <li>
            The value of the Underlying Assets and, consequently, the value of an Investor’s
            Interests can go down as well as up.
          </li>
          <li>The company may have to compete with other business models in the asset class.</li>
          <li>
            The value of the Underlying Asset is likely to be connected to its association with, a
            certain person or group or in connection with certain events. In the Event that such
            person, group or event loses public affection, then this may adversely impact the value
            of the Underlying Asset.
          </li>
          <li>There may be title, authenticity or infringement claims on an Underlying Asset.</li>
          <li>There are risks associated with reliance on third party authenticators.</li>
          <li>
            An Underlying Asset may be lost or damaged by causes beyond the Company’s control while
            being transported or when in storage or on display. There can be no guarantee that
            insurance proceeds will be sufficient to pay the full market value of an Underlying
            Asset which has been damaged or lost which will result in a material and adverse effect
            in the value of the related Interests.
          </li>
          <li>
            Insurance of Underlying Assets may not cover all losses which will result in a material
            and adverse effect in the valuation of the Series related to such damaged Underlying
            Assets.
          </li>
          <li>
            The Company may be forced to cause its various Series to sell one or more of the
            Underlying Assets at an inopportune time.
          </li>
          <li>
            There can be no guarantee of distributions or return of capital being made by a Series
            of the Company to its investors.
          </li>
          <li>Market manipulation or overproduction may be a risk with the asset class.</li>
          <li>
            There may be potentially high storage, maintenance and insurance costs for the
            Underlying Assets.
          </li>
          <li>
            Refurbishment of Underlying Assets is dependent on third parties and ability to source
            original parts.
          </li>
          <li>Underlying Assets may not be held long term.</li>
          <li>
            General sentiment of underlying fan base may be a risk with the asset class as
            Underlying Assets go in and out of favor with collectors.
          </li>
        </ul>
        <p>
          <em>Risks Related to Ownership of our Interests</em>
        </p>
        <ul>
          <li>Members of a Series in the Company have very limited voting rights.</li>
          <li>
            The offering price for the Interests determined by us may not necessarily bear any
            relationship to established valuation criteria such as earnings, book value or assets
            that may be agreed to between purchasers and sellers in private transactions or that may
            prevail in the market if and when our Interests can be traded publicly.
          </li>
          <li>
            If a market ever develops for the Interests, the market price and trading volume of our
            Interests may be volatile.
          </li>
          <li>
            Funds from purchasers accompanying subscriptions for the Interests will not accrue
            interest while in escrow.
          </li>
          <li>
            Any dispute in relation to the Operating Agreement is subject to the exclusive
            jurisdiction of the Court of Chancery of the State of Delaware, except where Federal law
            requires that certain claims be brought in Federal courts. Our Operating Agreement, to
            the fullest extent permitted by applicable law, provides for Investors to waive their
            right to a jury trial.
          </li>
        </ul>
        <p>
          <em>Conflicts of Interest</em>
        </p>
        <ul>
          <li>
            The Company’s operating agreement contains provisions that reduce or eliminate duties
            (including fiduciary duties) of the Manager.
          </li>
          <li>The Company does not have a conflicts of interest policy.</li>
          <li>
            The Manager may receive in-kind discounts from service providers when engaging on behalf
            of the Company.
          </li>
          <li>
            Members of the expert network and the advisory board of the Manager are often dealers
            and brokers so may be incentivized to sell the Company their own collectibles at
            potentially inflated market prices.
          </li>
          <li>
            Members of the expert network and the advisory board of the Manager may be investors in
            the Company and therefore promote their own self-interests when providing advice to the
            Manager.
          </li>
          <li>
            In the event that operating expenses for a series exceed revenues generated by the
            series, the Manager may choose to cause the series to incur debt rather than look for
            additional sources of income elsewhere to cover the costs.
          </li>
          <li>
            The Manager determines the timing and amount of distributions made to investors from
            free cash flow generated by a particular series. Since the Manager participates in these
            distributions in various ways the Manager may choose for a particular series to make
            frequent distributions rather than keeping cash on the balance sheet for future
            operating expenses.
          </li>
          <li>
            If the Manager becomes a broker, it may be financially incentivized to ensure increased
            volume of trading and volatility of interests in a series of the Company, rather than
            focusing its efforts on monetizing or realizing the underlying collectible.
          </li>
          <li>
            The Manager or its affiliates will acquire interests in each series and may sell such
            interests from time to time. The timing of such series may negatively impact the market
            value of the interests for other investors.
          </li>
          <li>
            The use of one service provider for all series of the Company may be economical but the
            service provider may not necessarily be the most appropriate for each specific
            underlying collectible.
          </li>
          <li>
            Allocation of costs and expenses across series of the Company may be difficult. In such
            circumstances the Manager may be conflicted from acting in the best interests of the
            Company as a whole or the individual series.
          </li>
          <li>
            The Manager may choose to use higher cash generating underlying collectibles at certain
            member events. This may result in lower returns for series of the Company which own the
            other lower cash generating underlying collectibles.
          </li>
          <li>
            The Manager is a party to the operating agreement and therefore, may be incentivized to
            amend it in a way that is more favorable to it rather than the investors.
          </li>
          <li>
            The Manager may receive sponsorship from servicing providers to reduce the costs of
            servicing the collectibles. Should such sponsorship not be obtained, the Manager may
            decide to perform a lower standard of service on the collectibles.
          </li>
          <li>
            The Manager will determine whether or not a particular asset should be liquidated,
            should a offer for such asset be received. The price achieved in such liquidation may
            not be in the best interest for all investors in the asset.
          </li>
          <li>
            As the Manager will acquire a percentage of each series of the Company, it may be
            incentivized to attempt to generate more earnings with those underlying collectibles in
            which it holds a greater stake.
          </li>
          <li>
            As part of the remuneration package for advisory board members, they may receive an
            ownership stake in the Manager. This may incentivize the advisory board members to make
            decisions in relation to the underlying collectibles that benefit the Manager rather
            than the Company.
          </li>
          <li>
            The same legal counsel represents both the Company and certain related Rally entities.
          </li>
        </ul>
        <p>
          <strong>
            <em>Third Party Information and Past Performance</em>
          </strong>
        </p>
        <p>
          Certain information, including statistical data, third-party quotes and other factual
          statements, contained in the Communication has been obtained from published sources
          prepared by other parties considered to be generally reliable. However, none of the
          Company, the Manager or any affiliate of the Manager or any of their respective directors,
          shareholders, members, officers, employees or agents assumes any responsibility for the
          accuracy of such information. There is no representation or warranty, express or implied,
          as to the accuracy, adequateness or completeness of any such information used in the
          Communication.
        </p>
        <p>
          Past performance is not necessarily indicative of future results of the interests or the
          assets in a given series. Furthermore, to the extent the Communication relates to prior
          performance of assets similar to an Underlying Asset acquired or to be acquired by the
          Company, those similar assets may be materially different from, or may not be of the same
          quality as, the assets acquired or to be acquired by the Company. Values of comparable
          assets may vary depending on a number of factors, including market conditions, location of
          sale, associated taxes, originality of parts, condition of the asset, operating quality,
          historical significance, ownership history, level of wear and other factors.
        </p>
        <p>
          Furthermore, the value of interests in a series of the Company may materially differ from
          the value of the Underlying Asset for many reasons, including market factors, fees charged
          by the Asset Manager, and restrictions on liquidity.
        </p>
        <p>
          <strong>
            <em>Forward-looking Statements</em>
          </strong>
        </p>
        <p>
          The information contained in the Communication including the Company’s Offering Circulars
          or Private Placement Memorandums may include some statements that are not historical and
          that are considered “forward-looking statements” within the meaning of Section 27A of the
          Securities Act. Such forward-looking statements may include, but are not limited to:
          statements regarding the development plans for the Company’s business; its strategies and
          business outlook; it’s market sector; anticipated development of the Company, the Manager
          and the Rally™ Platform; and various other matters (including contingent liabilities and
          obligations and changes in accounting policies, standards and interpretations). These
          forward-looking statements typically express the Manager’s expectations, hopes, beliefs,
          and intentions regarding the future. In addition, without limiting the foregoing, any
          statements that refer to projections, forecasts or other characterizations of future
          events or circumstances, including any underlying assumptions, are forward-looking
          statements. The words “anticipates”, “believes”, “continue”, “could”, “estimates”,
          “expects”, “intends”, “may”, “might”, “plans”, “possible”, “potential”, “predicts”,
          “projects”, “seeks”, “should”, “will”, “would” and similar expressions and variations, or
          comparable terminology, or the negatives of any of the foregoing, may identify
          forward-looking statements, but the absence of these words does not mean that a statement
          is not forward-looking.
        </p>
        <p>
          Any forward-looking statements contained in the Communication will be based on current
          expectations and beliefs concerning future developments that are difficult to predict.
          Neither the Company nor the Manager can guarantee future performance, or that future
          developments affecting the Company, the Manager or the Rally™ Platform will be as
          currently anticipated. These forward-looking statements involve a number of risks,
          uncertainties (some of which are beyond our control) or other assumptions that may cause
          actual results or performance to be materially different from those expressed or implied
          by these forward-looking statements, including those risks set forth in “Risk Factors”
          above.
        </p>
        <p>
          All forward-looking statements attributable to the Company are expressly qualified in
          their entirety by these risks and uncertainties. Should one or more of these risks or
          uncertainties materialize, or should any of the parties’ assumptions prove incorrect,
          actual results may vary in material respects from those projected in these forward-looking
          statements. The recipient of the Communication should not place undue reliance on any
          forward-looking statements and should not make an investment decision based solely on
          forward-looking statements. The Company undertakes no obligation to update or revise any
          forward-looking statements, whether as a result of new information, future events or
          otherwise, except as may be required under applicable securities laws.
        </p>
        <p>
          <strong>
            <em>Obtain Your Own Advice</em>
          </strong>
        </p>
        <p>
          Prospective investors are not to construe the contents of the Communication as legal,
          business or tax advice. Each prospective investor should consult its own advisors as to
          legal, business, tax and related matters concerning the subject matter of the
          Communication and any applicable Offering.
        </p>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DisclaimerPage);
