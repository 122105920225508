import React, { Component } from 'react';

export default class CollectInfoMessage extends Component {
  render() {
    const completeWhat = this.props.completeWhat || 'your account';
    return (
      <p className="CollectInfoMessage light">
        We need to collect some basic info to complete {completeWhat}. This info will only be
        attached to your account and will NEVER be sold.
      </p>
    );
  }
}
