import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';

import { Button } from 'components/shared/Buttons/Button';
import { SecondaryButton } from 'components/shared/Buttons/SecondaryButton';
import { DotsLoader } from 'components/shared/Loaders';
import { formatAssetPrice, getSecondaryMarketEnableDiff } from 'utils';
import { getAssetDetails } from 'actions';
import './AssetPreview.scss';
import { ASSET_STATUS } from 'constants/main';
import { SecondaryMarketStatus, AssetStatus } from 'types/assets';

class AssetPreview extends Component {
  constructor(props) {
    super(props);
    const { fetchEbAssets, asset } = this.props;
    const ebAssets = fetchEbAssets ? fetchEbAssets() : new Map();
    let isEbAsset = false;
    const ebAsset = ebAssets.get(asset.id);
    if (ebAsset && ebAsset.accessType === 'early_io') isEbAsset = true;
    this.state = { isImageLoaded: false, isEbAsset, priceShiftPercentage: 0, priceShift: 0 };
    this.image = null;
  }

  componentDidMount() {
    this.processImageLoading();
    this.updatePriceShift();
  }

  componentDidUpdate(prevProps) {
    this.processImageLoading();
    if (prevProps !== this.props) {
      this.updatePriceShift();
    }
  }

  componentWillUnmount() {
    if (this.image) this.image.removeEventListener('load', this.onImageLoad);
  }

  updatePriceShift = () => {
    const { activeAsset, asset } = this.props;
    const { secondaryMarket } = asset.financialInstrument?.markets ?? {};
    const timestampDiff = getSecondaryMarketEnableDiff(secondaryMarket);
    const showPrices =
      (asset.asset_status === ASSET_STATUS.POST_ONLY && timestampDiff <= 0) ||
      asset.asset_status === ASSET_STATUS.TRADING_CLOSED ||
      (asset.asset_status === ASSET_STATUS.TRADING_OPENED &&
        secondaryMarket.status !== SecondaryMarketStatus.OPEN);

    const isTrading =
      (asset.asset_status === ASSET_STATUS.POST_ONLY && timestampDiff <= 0) ||
      (asset.asset_status === ASSET_STATUS.TRADING_OPENED &&
        secondaryMarket.status !== SecondaryMarketStatus.DISABLED);

    const isLiveTrading =
      asset.asset_status === ASSET_STATUS.TRADING_OPENED &&
      secondaryMarket.status === SecondaryMarketStatus.OPEN;

    const isTradingClosed =
      (asset.asset_status === ASSET_STATUS.POST_ONLY && timestampDiff > 0) ||
      asset.asset_status === ASSET_STATUS.TRADING_CLOSED ||
      (asset.asset_status === ASSET_STATUS.TRADING_OPENED &&
        secondaryMarket.status === SecondaryMarketStatus.DISABLED);

    const isSameAsset = asset.id === activeAsset.id && !!activeAsset.trading.pricing;

    const { pricing } = isSameAsset ? activeAsset.trading : asset;
    const numberOfShares = isSameAsset ? activeAsset.number_of_shares : asset.number_of_shares;
    let price;
    let priceShift = 0;
    let priceShiftPercentage = 0;

    if (isTrading || isTradingClosed) {
      price =
        (pricing?.pricePerUnit?.session?.lastClose || pricing?.pricePerUnit?.issue) *
        numberOfShares;
    }
    if (isLiveTrading) {
      price =
        (pricing?.pricePerUnit?.session?.latestFilled || pricing?.pricePerUnit?.current) *
        numberOfShares;
    }

    const { sessionsStart } = secondaryMarket ?? {};

    const neverTraded = sessionsStart && moment(sessionsStart).isSameOrAfter(moment.now());

    if (!price) {
      this.setState({
        showPrices: neverTraded && showPrices ? false : showPrices,
        price: 0,
        priceShift,
        priceShiftPercentage,
      });
      return;
    }
    priceShift = price - activeAsset.asset_value;
    priceShiftPercentage = (priceShift / (pricing.pricePerUnit?.issue * numberOfShares)) * 100;
    this.setState({
      showPrices: neverTraded && showPrices ? false : showPrices,
      price,
      priceShift,
      priceShiftPercentage,
    });
  };

  openAssetDetails = async () => {
    // await this.props.getAssetDetails(this.props.asset.id);
    this.props.openDetailsCallback();
  };

  /**
   * Asynchronously loads the image if the asset is selected
   */
  processImageLoading = () => {
    if (this.state.isImageLoaded) return;
    const findIndexActiveAsset = this.props.assetList.findIndex(
      item => item.id === this.props.activeAsset.id,
    );

    const findIndexNextAsset =
      findIndexActiveAsset + 1 <= this.props.assetList.length - 1
        ? this.props.assetList[findIndexActiveAsset + 1].id
        : '';
    const findIndexNext2Asset =
      findIndexActiveAsset + 2 <= this.props.assetList.length - 1
        ? this.props.assetList[findIndexActiveAsset + 2].id
        : '';
    const findIndexPrevAsset =
      findIndexActiveAsset - 1 >= 0 ? this.props.assetList[findIndexActiveAsset - 1].id : '';
    const findIndexPrev2Asset =
      findIndexActiveAsset - 2 >= 0 ? this.props.assetList[findIndexActiveAsset - 2].id : '';

    if (
      this.props.activeAsset.id === this.props.asset.id ||
      findIndexNextAsset === this.props.asset.id ||
      findIndexPrevAsset === this.props.asset.id ||
      findIndexNext2Asset === this.props.asset.id ||
      findIndexPrev2Asset === this.props.asset.id
    ) {
      const isLiveTrading =
        this.props.activeAsset?.asset_status === ASSET_STATUS.TRADING_OPENED &&
        this.props.activeAsset?.financialInstrument?.markets?.secondaryMarket?.status ===
          SecondaryMarketStatus.OPEN;

      this.image = new Image();
      this.image.src = isLiveTrading
        ? this.props.asset.investment_image
        : this.props.asset.home_image;

      if (this.image.complete) {
        this.onImageLoad();
      } else {
        this.image.addEventListener('load', this.onImageLoad);
      }
    }
  };

  onImageLoad = () => this.setState({ isImageLoaded: true });

  getFundedText(percent) {
    let progressBarMessage = `${percent}% FUNDED`;
    if (percent > 50) {
      const isFinishing = percent > 75;
      progressBarMessage = (
        <span className={isFinishing ? 'AssetPreview-red-text' : ''}>
          {100 - percent}% REMAINING
        </span>
      );
    }
    return <span className="AssetPreview-funded">{progressBarMessage}</span>;
  }

  getAssetStatusText({ asset_status, is_exited, exited_date }) {
    if (is_exited) {
      return `EXITED / SOLD ${exited_date && 'ON ' + moment(exited_date).format('MM/DD/YYYY')}`;
    }

    let statusText = asset_status.toUpperCase();
    const percentage = Math.min(Math.floor(this.props.asset.progress_bar), 100);
    let fundedText = this.getFundedText(percentage);

    if (percentage === 100 && asset_status === AssetStatus.IO_OPENED) {
      return (
        <span>
          <span className="AssetPreview-complete">INITIAL OFFERING COMPLETE | </span>
          <span className="AssetPreview-red-text">SOLD OUT</span>
        </span>
      );
    }

    switch (statusText) {
      case 'OPEN ICO':
        statusText = <span>{fundedText} | OPEN</span>;
        break;
      case 'CLOSED ICO':
        statusText = <span>{fundedText} | CLOSED</span>;
        break;
      case 'COMING SOON':
        const { isEbAsset } = this.state;
        statusText = isEbAsset ? (
          <span>
            <span className="AssetPreview-funded">EARLY ACCESS</span> | OPEN
          </span>
        ) : (
          'COMING SOON!'
        );
        break;
      case 'POST ONLY':
      case 'TRADING':
        statusText = '';
        break;
      default:
    }
    return statusText;
  }

  renderPriceShift = () => {
    const { priceShift, priceShiftPercentage, showPrices } = this.state;
    if (!showPrices) return null;
    const isPositiveShift = Number(priceShift) > 0;
    let shiftSymbol = '';
    if (isPositiveShift) shiftSymbol = '+';
    if (Number(priceShift) < 0) shiftSymbol = '-';
    const shiftPercentage = `(${shiftSymbol}${
      Number.isInteger(priceShiftPercentage)
        ? Math.abs(priceShiftPercentage)
        : Math.abs(priceShiftPercentage.toFixed(2))
    }%)`;
    const className = classnames('AssetPreview-price-shift', {
      'AssetPreview-price-shift-negative': Number(priceShift < 0),
    });

    const formatedNumber = formatAssetPrice(priceShift < 0 ? priceShift * -1 : priceShift);

    return (
      <span className={className}>
        {shiftSymbol}
        <span className="Price">${priceShift || priceShift === 0 ? formatedNumber : ''}</span>
        &nbsp;{shiftPercentage}
      </span>
    );
  };

  getAssetValue = () => {
    const { asset_value } = this.props.asset;
    const { price } = this.state;
    if (price) {
      return Number(price);
    }
    return Number(asset_value);
  };

  render() {
    const { isEbAsset } = this.state;
    const { is_exited, asset_status, progress_bar, ticker } = this.props.asset;
    const { home_image, investment_image, display_name } = this.props.asset;
    const { positions } = this.props.investmentsData;
    const { financialInstrument } = this.props.activeAsset;
    const statusText = this.getAssetStatusText(this.props.asset);
    const ButtonComponent = asset_status === 'Coming Soon' && !isEbAsset ? SecondaryButton : Button;

    const isAssetActiveTrading = asset_status === 'Trading' || asset_status === 'Post Only';

    const activeShares = positions?.[financialInstrument.id]?.units?.total ?? 0;

    let ctaText = <b>EXPLORE</b>;

    if (isAssetActiveTrading || isEbAsset || (asset_status === 'Open ICO' && progress_bar < 100)) {
      ctaText = <b>EXPLORE & INVEST</b>;
    }

    const isLiveTrading =
      this.props.activeAsset?.asset_status === ASSET_STATUS.TRADING_OPENED &&
      this.props.activeAsset?.financialInstrument?.markets?.secondaryMarket?.status ===
        SecondaryMarketStatus.OPEN;

    return (
      <div className={`AssetPreview ${asset_status}`}>
        <div className="AssetPreview-ticker">
          <span>{ticker}</span>
        </div>
        <div className="AssetPreview-price">
          <span className="AssetPreview-price-symbol">$</span>
          <span>{formatAssetPrice(this.getAssetValue())}</span>
          {this.renderPriceShift()}
        </div>
        <div className="AssetPreview-imagewrapper">
          <div
            className="AssetPreview-image"
            onClick={!is_exited ? this.openAssetDetails : undefined}
          >
            {this.state.isImageLoaded ? (
              <img
                src={isLiveTrading && investment_image ? investment_image : home_image}
                alt={ticker}
              />
            ) : (
              <DotsLoader color="#ccc" />
            )}
            {isAssetActiveTrading && activeShares > 0 && this.state.isImageLoaded && (
              <div className="AssetPreview-sharesOwned">
                <span>{`${activeShares} ${activeShares > 1 ? `shares` : `share`}`}</span>
              </div>
            )}
          </div>
        </div>
        <div className="AssetPreview-name">
          <span>{display_name}</span>
        </div>
        <div className="AssetPreview-cta">
          <ButtonComponent text={ctaText} disabled={is_exited} onClick={this.openAssetDetails} />
        </div>
        <div className="AssetPreview-status">
          <span>{statusText}</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeAsset: state.Assets.activeAsset,
  assetList: state.Assets.assetList,
  investmentsData: state.Investments,
  total_approved_shares: state.Investments.total_approved_shares,
  activeOrdersShares: state.Trading.activeOrdersShares,
  fetchEbAssets: state.Auth.user.getSpeciallyAccessibleAssetsIdAccessTypeAndLimitationsMap,
});
const mapDispatchToProps = { getAssetDetails };
export default connect(mapStateToProps, mapDispatchToProps)(AssetPreview);
