import React, { useState } from 'react';
import { SimpleComponent } from 'types/components';
import {
  CalendarItemContainer,
  CalendarTicker,
  CalendarImage,
  CalendarButton,
  CalendarTimer,
  CalendarAssetName,
  CalendarInfoContent,
} from './styled';
import { Asset } from 'types/assets';
import moment from 'moment';
import AddToCalendarButton from '../AddToCalendarButton';
import { getAssetDates } from 'utils/calendar';
import { getAssetRoute } from 'utils/navigation';
import { useDispatch } from 'react-redux';
import { assetsCategories } from 'constants/main';
import {
  setActiveAssetCategory,
  setActiveTradingWindow,
  setActiveAsset,
  toggleAssetsDetails,
} from 'actions';
import { withRouter, RouteComponentProps } from 'react-router';
import { track } from 'services/analytics';
import { SEGMENT_EVENTS, SEGMENT_ACTIONS, SEGMENT_CATEGORIES } from 'constants/analytics';
import { getTimeInTimezone } from 'utils/main';

type CalendarItemProps = {
  asset: Asset;
  tradingDate: string;
  disableScroll: (param: boolean) => void;
} & RouteComponentProps;

const PostOnlyCalendarItem: SimpleComponent<CalendarItemProps> = ({
  asset,
  history,
  tradingDate,
  disableScroll,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const dispatch = useDispatch();

  const dates = getAssetDates(asset);

  const openCalendar = () => {
    setShowOptions(true);
    disableScroll(true);
  };

  const closeCalendar = () => {
    setShowOptions(false);
    disableScroll(false);
  };

  const navigateToAsset = () => {
    const route = getAssetRoute(asset);
    const targetAssetCategory = assetsCategories[asset.category];
    dispatch(setActiveAssetCategory(targetAssetCategory));
    dispatch(setActiveTradingWindow(asset.financialInstrument.id));
    dispatch(setActiveAsset(asset));
    dispatch(toggleAssetsDetails(true));
    track(SEGMENT_EVENTS.CALENDAR_LIVE_ASSET_TAP, {
      action: SEGMENT_ACTIONS.OPEN,
      category: SEGMENT_CATEGORIES.INVESTMENT_CALENDAR,
      assetState: 'ACCEPTING ORDERS',
      assetTicker: asset.ticker,
      assetName: asset.display_name,
      tradingDate,
    });
    history.push(route);
  };
  const startTime = getTimeInTimezone(dates.start, true);
  const closeTime = getTimeInTimezone(dates.end, true);

  return (
    <CalendarItemContainer>
      <CalendarImage url={asset.portal_image} onClick={navigateToAsset} />
      <CalendarInfoContent onClick={navigateToAsset}>
        <CalendarTimer>
          {startTime.time} {startTime.ampm} - {closeTime.time} {closeTime.ampm} {closeTime.timezone}
        </CalendarTimer>
        <CalendarTicker color={asset.tertiary_color}>{asset.ticker}</CalendarTicker>
        <CalendarAssetName>{asset.short_name}</CalendarAssetName>
      </CalendarInfoContent>
      <CalendarButton onClick={openCalendar}>
        <AddToCalendarButton
          showOptions={showOptions}
          color="#A7A7A8"
          onHideOptions={closeCalendar}
          onShowOptions={openCalendar}
          startDate={moment(dates.start).toDate()}
          endDate={moment(dates.end).toDate()}
          title={`Trading Window: ${asset.display_name}`}
          description=""
        />
      </CalendarButton>
    </CalendarItemContainer>
  );
};

export default withRouter(PostOnlyCalendarItem);
