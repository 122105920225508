import React from 'react';

const BooksIcon = ({ className, style, ...props }) => {
  return (
    <svg
      className={`BooksIcon${className ? ` ${className}` : ''}`}
      style={{ fill: 'currentColor', stroke: 'currentColor', ...style }}
      strokeWidth={1}
      strokeMiterlimit={10}
      viewBox="0 0 55.934 50.606"
      {...props}
    >
      <g transform="translate(-62.747 -96.316)">
        <g transform="matrix(.26458 0 0 .26458 47.905 82.986)">
          <path d="M266 215.1c-18.3-.3-36.2.5-53.7 5.2-17.4 4.7-33.6 12.3-49.9 19.7-16.4-7.4-32.7-14.9-50.1-19.4-17.5-4.6-35.4-5.3-54-4.7-.2-49.2-.5-98.2-.7-147.5 4.5-.6 8.6 0 13.1-.5V53.4c1-1.1 2-1 2.8-1 25.5.7 50.2 4.9 73 17.1 5.7 3.1 10.8 7 15.3 11.7 2.1-1.9 4-3.7 6-5.4 8.6-7 18.5-11.7 28.9-15 17.1-5.5 34.6-8.6 52.6-8.9 1 0 2.1-.2 3.5.6v15H266v147.6zM164.7 84.6v141.1c13.4-6.7 26.7-12.7 40.6-17.5 14-4.8 28.6-6.4 43.4-7.5V56.6c-1.1-.7-2.1-.5-3-.4-7.8.2-15.6 1.1-23.2 2.5-14.8 2.7-29.2 6.4-42.4 13.9-5.9 3.3-11.1 7-15.4 12zm-4.5 141.2c-.3-47.3-.6-94.1-.8-141.9-.6-.5-1.9-1.3-2.9-2.3-5-4.6-10.5-8.2-16.6-11C120 61.4 99 57.5 77.4 56.7c-.7 0-1.5-.1-2.4.8.3 47.5.6 95.2.8 143.6 30.6-.1 57.7 11.2 84.4 24.7zM262 211.2V71.7c-3.1-.1-6-.3-9.2.1v133c-4.8.4-9.4.6-13.9 1-12.4 1.2-24.7 3.3-36.4 7.8-12.8 4.9-25.5 10.3-37.8 16.5-1.6.8-3 .8-4.6 0-7.9-4-16.1-7.4-24.1-11-15.4-6.9-31.4-11.6-48.2-13-5.2-.5-10.4-.8-16-1.3-.3-44.2-.5-88.3-.8-132.3h-9.3l.9 139.1c17.8-.3 35 .7 51.8 5.3 16.7 4.6 32.5 11.7 48.3 19.1 31-16.3 63.4-27.2 99.3-24.8z" />
          <path d="M203.2 167.4c-2.4-2.4-2.9-6.3-5.7-8.3-2.1.1-3.6.8-5 2-4.6 4.1-9.2 8-13.8 12-.6.5-1 1.3-2 1.2-.4-1.1.3-2 .7-2.9 2.1-5.3 4.3-10.6 6.4-15.8.7-1.7 1.2-3.5.5-5.7-2.6-1.9-6.2-1.1-9.5-2.6 3.3-4.7 6.4-9.2 9.4-13.5.3-2.2-.4-3.9-1.5-5.4-2.7-3.6-5.3-7.3-7.9-11-.6-.8-1.3-1.6-1.4-2.7 1-.6 1.8-.2 2.7 0 4.8 1.1 9.7 2.3 14.5 3.4 3.2.7 4.5.2 6.4-2.4l6.9-9.9c.5-.6.8-1.4 1.8-1.7 2.4 2.1 2.9 5.9 5.7 7.6 2.3-.1 3.8-1.2 5.2-2.5 4.8-4.4 9.7-8.7 14.6-13 .5-.4.9-1.2 1.8-.9.4 1-.3 1.9-.6 2.7-2.3 5.8-4.6 11.6-7 17.4-.6 1.6-.8 3.1-.2 5 3.1 1.8 6.9 1 10.3 2.1.4 1-.4 1.5-.8 2.1-2.5 3.5-5.1 7-7.6 10.6-1.9 2.7-1.9 4.2 0 6.7 3.5 4.8 7.1 9.5 10.6 14.3.4.6 1.3 1.1.9 2.4-2.7-.7-5.3-1.4-8-2.1-3.8-1-7.6-2.1-11.4-3.1-3.7-1-4.9-.6-7.1 2.5l-6.9 9.6c-.6.7-1 1.5-2 1.9zm21.1-59.5c-.1-.1-.2-.2-.4-.3-3.4 3-6.7 6-10.1 8.9-2.6 2.2-4.2 1.9-6-1-.9-1.5-1.4-3.2-2.8-4.7-2.3 3.2-4.6 6.1-6.7 9.2-1.7 2.5-3.6 3.4-6.6 2.5-2.9-.9-5.9-1.4-8.9-2.1 0 .4 0 .6.1.7 1.6 2.3 3.1 4.5 4.7 6.7 2.2 3.2 2.2 4.1 0 7.3-2.1 2.9-4.1 5.9-6.4 9 2.9 1 5.7.5 8.5 1.8.9 5.9-3.6 10.6-4.4 16.4 3.3-2.8 6.3-5.5 9.3-8.1 2.8-2.5 4.9-2.1 6.7 1.3.8 1.6 1.4 3.4 2.8 4.7 2.5-3.4 4.9-6.6 7.1-9.9 1.5-2.2 3.5-2.8 6.1-2.1 3.5 1 7 1.8 10.5 2.7 0-.3.1-.5 0-.6-1.8-2.4-3.5-4.8-5.3-7.2-2.9-3.9-2.9-4.3-.1-8.2 2.1-3 4.3-6 6.7-9.4-2.7-.3-4.9-.7-7.1-.8-2-.1-2.6-1.2-2.8-3-.1-1.1.3-2.1.6-3 1.5-3.5 3-7.1 4.5-10.8z" />
          <path d="M94.7 164.9V86.5c.5-.6 1.4-.4 2.3-.4 12.2.4 24.1 2.4 35.3 7.8.9.5 1.8 1 2.8 1.5 6 3.2 8.9 7.5 8.7 15-.5 21.4 0 42.8.1 64.2 0 1.1.2 2.3-.6 3.2-.9 1-2 1.3-3.3.6-13.3-6.7-27.6-10-42.1-12.6-.7-.1-1.4-.3-2.1-.4-.3-.1-.5-.3-1.1-.5zm4.1-74.6v70.9c14 3.1 27.8 5.7 40.6 11.9v-69.4c-11.9-10.1-26-12.3-40.6-13.4z" />
          <path d="M142.4 195.5c-14.5-11-31.2-14.8-48.5-15.9-.5-1.6-.3-2.8.3-4.1 17.4 1.2 34 4.6 48.5 15.2.1 1.4.3 2.8-.3 4.8z" />
        </g>
      </g>
    </svg>
  );
};

export default BooksIcon;
