import React, { Component } from 'react';
import './Cross.css';

export class Cross extends Component {
  render() {
    return (
      <span className="Cross">
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
          <path d="M33.121,5.122 L21.243,17.000 L33.121,28.879 L33.121,28.879 C33.664,29.422 34.000,30.172 34.000,31.000 C34.000,32.657 32.657,34.000 31.000,34.000 C30.172,34.000 29.422,33.664 28.879,33.121 L28.879,33.121 L17.000,21.243 L5.121,33.121 L5.121,33.121 C4.579,33.664 3.828,34.000 3.000,34.000 C1.343,34.000 0.000,32.657 0.000,31.000 C0.000,30.172 0.336,29.422 0.879,28.879 L0.879,28.879 L12.757,17.000 L0.879,5.122 L0.879,5.122 C0.336,4.579 0.000,3.829 0.000,3.000 C0.000,1.343 1.343,0.000 3.000,0.000 C3.828,0.000 4.579,0.336 5.121,0.879 L5.121,0.879 L17.000,12.758 L28.879,0.879 L28.879,0.879 C29.422,0.336 30.172,0.000 31.000,0.000 C32.657,0.000 34.000,1.343 34.000,3.000 C34.000,3.829 33.664,4.579 33.121,5.122 L33.121,5.122 Z" />
        </svg>
      </span>
    );
  }
}
