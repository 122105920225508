import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from 'components/shared/Buttons/Button';
import { DotsLoader } from 'components/shared/Loaders';
import DwollaStatusPopup from 'components/shared/DwollaStatusPopup';
import { FUNDING_SOURCE_STATUS, PASSPORT_SECTION } from 'constants/main';
import stoplightsImage from 'images/stoplights.png';
import * as specialAccessHelper from 'utils/specialAccess';

import './StartedInfo.css';

const defaultText = `
  We need to collect some basic info from you to process your investment. You will be asked
  to answer some questions regarding your suitability as an investor. We will NEVER sell
  this information and all data is encrypted and locked securely in your account.
`;

class StartedInfo extends Component {
  static propTypes = {
    bankFundingSource: PropTypes.object.isRequired,
    onFormClose: PropTypes.func.isRequired,
    onStepForward: PropTypes.func.isRequired,
    text: PropTypes.string,
  };

  renderVerificationWarning = () => {
    if (this.props.saPopup?.asset?.type === 'free_share_program') {
      specialAccessHelper.increaseAmount({
        userId: this.props.user.id,
        assetId: this.props.saPopup.asset.id,
        assetType: this.props.saPopup.asset.type,
      });
    }

    return (
      <div className="StartedInfo">
        <h2>VERIFICATION</h2>
        <p>You must add or verify your bank account prior to investing.</p>
        <Button text={<b>OKAY!</b>} onClick={this.props.onFormClose} />
      </div>
    );
  };

  renderStartedInfo = () => {
    const availableSharesIsUndefined =
      this.props.section !== PASSPORT_SECTION.TRADING &&
      this.props.data.availableShares === undefined;

    const { text } = this.props;

    const {
      location: { state },
    } = this.props;

    if (state?.autoGoForward) {
      this.props.onStepForward();
      return null;
    }

    return (
      <div className="StartedInfo">
        <img src={stoplightsImage} alt="stoplights" className="StartedInfo-img" />
        <h2>Here's what's about to happen...</h2>
        <p>{text || defaultText}</p>
        <Button
          disabled={availableSharesIsUndefined}
          text={!availableSharesIsUndefined && <b>OKAY!</b>}
          arrow="forward"
          onClick={() => this.props.onStepForward()}
        >
          {availableSharesIsUndefined && <DotsLoader color="rgba(255, 255, 255, 0.7)" />}
        </Button>
        <DwollaStatusPopup
          status={this.props.dwollaCustomerStatus}
          onClick={this.props.onFormClose}
          showForRetry={false}
        />
      </div>
    );
  };

  render() {
    const { bankFundingSource } = this.props;
    const showWarning = bankFundingSource.info.status === FUNDING_SOURCE_STATUS.UNVERIFIED;
    return showWarning ? this.renderVerificationWarning() : this.renderStartedInfo();
  }
}

const mapStateToProps = state => ({
  dwollaCustomerStatus: state.Auth.dwollaCustomerStatus,
  bankFundingSource: state.Investments.bankFundingSource,
  saPopup: state.UI.saPopup,
  user: state.Auth.user,
});

export default connect(mapStateToProps)(StartedInfo);
