import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Error from 'components/shared/Error';
import { EditableField } from 'components/shared/Inputs/EditableField';
import { testPoBox } from 'utils';
import { updateUserData } from 'actions';
import './UserSetting.css';

class UserSetting extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string,
    onSave: PropTypes.func,
  };

  state = {
    originalValue: this.props.value,
    editableValue: this.props.value,
    error: null,
  };

  handleSave = async () => {
    const { originalValue, editableValue } = this.state;

    if (this.props.type === 'address') {
      // po box validation (if true, then it is a po box -> throw error)
      if (testPoBox(editableValue)) {
        this.setState({ error: null, editableValue: originalValue });
        return;
      }
    }
    if (editableValue !== originalValue) {
      if (this.props.onSave) return this.props.onSave(editableValue);
      try {
        await this.props
          .updateUserData({ [this.props.name]: editableValue })
          .then(() => this.setState({ originalValue: editableValue }));
      } catch (err) {
        const error = this.props.type === 'date_of_birth' ? 'Date is invalid' : err;
        this.setState({ error });
      }
    }
  };

  handleChange = value => {
    const isPoBox = testPoBox(value);
    this.setState({ editableValue: value, error: isPoBox ? 'PO Boxes are not permitted' : null });
  };

  render() {
    return (
      <div className="UserSetting">
        <div className="UserSetting-name">{this.props.label}</div>
        <div className="UserSetting-value">
          <EditableField
            autoFocus
            fieldType={this.props.type}
            onChange={this.handleChange}
            onSave={this.handleSave}
            value={this.state.editableValue}
          />
          <Error error={this.state.error} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = { updateUserData };

export default connect(null, mapDispatchToProps)(UserSetting);
