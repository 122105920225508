import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { checkAuth } from 'actions';

/**
 * HOC for auth-protecting
 * @param  {React Component} ComposedComponent - protected component
 * @return {React Component} - wrapper component
 */
const requireAuth = ComposedComponent => {
  const AuthWrapper = props => {
    const dispatch = useDispatch();
    const { isAuth } = useSelector(state => state.Auth);
    const stableDispatch = useCallback(dispatch, []);

    useEffect(() => {
      stableDispatch(checkAuth());
    }, [stableDispatch]);

    if (!isAuth && !props.location.pathname.includes('/logout')) {
      localStorage.setItem('redirectPath', props.location.pathname);
    }

    return isAuth ? <ComposedComponent {...props} /> : null;
  };

  return AuthWrapper;
};

export default requireAuth;
