import React, { Component } from 'react';
import { Redirect } from 'react-router';
import Bugsnag from '@bugsnag/js';

import './DocusignCallback.css';

class DocusignCallback extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.parseArgs = this.parseArgs.bind(this);
  }

  parseArgs(urlArgs) {
    const equalPosition = urlArgs.indexOf('=');
    const result = {
      event: urlArgs.substring(equalPosition + 1),
    };

    return result;
  }

  componentDidMount() {
    const parsed = this.parseArgs(this.props.location.search);
    let message = 'error';

    switch (parsed.event) {
      case 'signing_complete':
        this.setState({ text: '' });
        message = 'OK';
        setTimeout(this.sendMessage(message), 10);
        break;
      case 'cancel':
        this.setState({ text: '' });
        message = 'cancel';
        setTimeout(this.sendMessage(message), 10);
        break;
      case 'decline':
        this.setState({ text: '' });
        message = 'decline';
        setTimeout(this.sendMessage(message), 10);
        break;
      default:
        this.setState({ text: 'Docusign error: please contact support@rallyrd.com' });
        Bugsnag.notify(new Error('Fatal DocusignCallback Exception: ' + parsed.event), function(
          event,
        ) {
          event.severity = 'error';
          event.context = 'docusign';
          event.addMetadata('docusign', { event: parsed });
        });
        setTimeout(this.sendMessage(message), 3000);
    }
  }

  sendMessage = msg => () => {
    window.parent.postMessage(msg, '*');
  };

  render() {
    if (window.self === window.top) {
      return <Redirect to="/" />;
    }

    return (
      <div className="DocusignCallback">
        <h1>{this.state.text}</h1>
      </div>
    );
  }
}

export default DocusignCallback;
