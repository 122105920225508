import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './SquareButton.scss';

/**
 * SquareButton component
 * @param  {String}    theme: 'light' - to apply light theme without background
 * @param  {String}    arrow:  'back'|'left'|'previous' or 'forward'|'right'|'next'
 * @param  {Object}    style:  styles object
 * @param  {String}    className:  additional css classes
 * @param  {Node}      children:  button content
 * @param  {String}    text:  text content (same as 'children' prop)
 */
export const SquareButton = ({ theme, arrow, style, className, children, text, ...props }) => {
  const buttonClasses = classnames('SquareButton', {
    'SquareButton-left-arrow': arrow === 'back' || arrow === 'left' || arrow === 'previous',
    'SquareButton-right-arrow': arrow === 'forward' || arrow === 'right' || arrow === 'next',
    [theme]: theme,
    [className]: className,
  });

  return (
    <button className={buttonClasses} style={style} {...props}>
      {children || text}
    </button>
  );
};

SquareButton.propTypes = {
  theme: PropTypes.string,
  arrow: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
};

SquareButton.defaultProps = {
  text: 'OKAY',
};

export default SquareButton;
