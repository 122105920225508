import React, { Component } from 'react';
import { Input } from './Input';

export class EmailInput extends Component {
  constructor(props) {
    super(props);
    this.checkEmail = this.checkEmail.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.value !== this.props.value) {
      this.onChange(newProps.value);
      this.checkEmail();
    }
  }

  checkEmail() {
    this.emailValid = this.validateEmail(this.email);

    if (typeof this.props.validationChange === 'function') {
      this.props.validationChange(this.emailValid);
    }

    this.forceUpdate();
  }

  onChange(value) {
    this.email = value;
    this.props.onChange(value);
  }

  validateEmail(email) {
    return typeof email === 'string' && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  }

  render() {
    let isNotValid = this.props.valid === false || this.emailValid === false;
    return (
      <Input
        {...this.props}
        type="email"
        name="email"
        placeholder={this.props.placeholder || 'Email Address'}
        checkValid={this.checkEmail}
        onChange={this.onChange}
        valid={!isNotValid}
      />
    );
  }
}

export default EmailInput;
