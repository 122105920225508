import React, { Component } from 'react';

import { EmailInput } from 'components/shared/Inputs/EmailInput';
import * as AuthAPI from 'services/AuthAPI';
import history from 'services/history';
import { setPageTitle } from 'utils';
import './PasswordReset.css';

class PasswordReset extends Component {
  state = {
    email: '',
    isFormValid: false,
    isFormSubmitted: false,
  };

  componentDidMount() {
    setPageTitle('Reset Password');
  }

  onEmailChange = email => this.setState({ email });

  onValidationChange = isFormValid => this.setState({ isFormValid });

  goToLogin = () => history.push('/login', { email: this.state.email });

  onFormSubmit = e => {
    e.preventDefault();
    AuthAPI.sendPasswordResetEmail({ email: this.state.email });
    this.setState({ isFormSubmitted: true });
  };

  render() {
    const { email, isFormValid, isFormSubmitted } = this.state;

    if (isFormSubmitted) {
      return (
        <div className="PasswordReset">
          <div className="PasswordReset-message">
            <h3>Check your email for a secure link to reset your password!</h3>
            <button className="AuthenticationPages-actionButton" onClick={this.goToLogin}>
              Okay
            </button>
          </div>
        </div>
      );
    }

    return (
      <form className="PasswordReset" onSubmit={this.onFormSubmit} noValidate>
        <div className="PasswordReset-inputs">
          <p>Enter your email address and we will send you a link to reset your password.</p>
          <EmailInput
            value={email}
            onChange={this.onEmailChange}
            validationChange={this.onValidationChange}
            valid={false}
          />
          <button
            className="AuthenticationPages-actionButton"
            disabled={!isFormValid}
            type="submit"
          >
            Send Link
          </button>
        </div>
      </form>
    );
  }
}

export default PasswordReset;
