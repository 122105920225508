import { ASSET_STATUS } from 'constants/main';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SecondaryMarketStatus } from 'types/assets';

import TradingCloseCountdown from '../TradingCloseCountdown';

import './TradingWindowBanner.css';
import TradesOnText from './TradesOnText';

class TradingWindowBanner extends Component {
  render() {
    const { activeAsset } = this.props;
    const { financialInstrument } = activeAsset ?? {};
    const { secondaryMarket } = financialInstrument?.markets ?? {};

    const timestampDiff = financialInstrument?.markets?.secondaryMarket?.enabled
      ? new Date(secondaryMarket.enabled).getTime() - Date.now()
      : undefined;

    const isPostOnly =
      (activeAsset.asset_status === ASSET_STATUS.POST_ONLY && timestampDiff <= 0) ||
      (activeAsset.asset_status === ASSET_STATUS.TRADING_OPENED &&
        financialInstrument.markets.secondaryMarket.status === SecondaryMarketStatus.CLOSED);

    const isLiveTrading =
      activeAsset.asset_status === ASSET_STATUS.TRADING_OPENED &&
      financialInstrument.markets.secondaryMarket.status === SecondaryMarketStatus.OPEN;

    if (!isPostOnly && !isLiveTrading) {
      return null;
    }

    const {
      sessionsFollowMarketHours,
      sessionHours: { lastDateClose },
      sessionsStart,
    } = secondaryMarket;

    return (
      <span className={`TradingWindowBanner${isLiveTrading ? '' : ' post-only'}`}>
        {isLiveTrading ? (
          <>
            <b>TRADING WINDOW CLOSES:</b>&nbsp;
            <TradingCloseCountdown />
          </>
        ) : (
          <TradesOnText
            sessionsFollowMarketHours={sessionsFollowMarketHours}
            lastDateClose={lastDateClose}
            sessionsStart={sessionsStart}
          />
        )}
      </span>
    );
  }
}

const mapStateToProps = state => ({
  activeAsset: state.Assets.activeAsset,
});

export default connect(mapStateToProps, null)(TradingWindowBanner);
