import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import classnames from 'classnames';

import ImageLoader from './ImageLoader.js';

import './LazyImage.scss';

/**
 * Lazy load images by tracking window or overflow element scroll position.
 */
class LazyImage extends Component {
  static propTypes = {
    // Image display option: 'image' (image tag) or 'background' (css-background)
    mode: PropTypes.string,
    // Image source:
    src: PropTypes.string.isRequired,
    // Offset in pixels, to start image loading before it appears in the viewport
    offset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    // Delay before triggering scroll listener
    scrollListenerDelay: PropTypes.number,
    // Placeholder to be rendered inside the wrapper when image is loading
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    // Alt attribute for the image
    alt: PropTypes.string,
    // Styles:
    className: PropTypes.string,
    style: PropTypes.object,
    imageClassName: PropTypes.string,
    imageStyle: PropTypes.object,
    // Callbacks
    onClick: PropTypes.func,
    onStartLoad: PropTypes.func,
    onBeforeLoad: PropTypes.func,
    onAfterLoad: PropTypes.func,
  };

  static defaultProps = {
    mode: 'image',
    offset: 100,
    scrollListenerDelay: 200,
    placeholder: <ImageLoader />,
  };

  state = {
    isLoading: false, // image is loading
    loaded: false, // image was loaded
    scrollPosition: {
      x: window.scrollX || window.pageXOffset,
      y: window.scrollY || window.pageYOffset,
    },
  };

  image = null; // virtual image instance, used to load image src file
  imageWrapper = null; // image container
  scrollableParent = null; // parent element, to apply scroll listeners to

  /**
   * Find scrollable parent element.
   * Setup scroll listeners, check if the image is visible in the viewport.
   */
  componentDidMount() {
    this.scrollableParent = this.getScrollableParent(this.imageWrapper);
    this.debounceScrollChange = debounce(this.onScrollChange, this.props.scrollListenerDelay);

    this.scrollableParent.addEventListener('scroll', this.debounceScrollChange);
    this.scrollableParent.addEventListener('resize', this.debounceScrollChange);

    window.addEventListener('scroll', this.debounceScrollChange);
    window.addEventListener('resize', this.debounceScrollChange);

    this.checkImageVisibility();
  }

  /**
   * Remove all event listeners before unmount
   */
  componentWillUnmount() {
    this.scrollableParent.removeEventListener('scroll', this.debounceScrollChange);
    this.scrollableParent.removeEventListener('resize', this.debounceScrollChange);

    window.removeEventListener('scroll', this.debounceScrollChange);
    window.removeEventListener('resize', this.debounceScrollChange);

    if (this.image) {
      this.image.removeEventListener('load', this.onImageLoad);
    }
  }

  componentDidUpdate() {
    this.checkImageVisibility();
  }

  /**
   * Find scrollable parent element (element with overflow scroll/auto or documentElement)
   * @param  {DOMNode} node
   * @return {DOMNode}
   */
  getScrollableParent = node => {
    if (!node) return document.documentElement;

    const overflowRegex = /(scroll|auto)/;
    let parent = node;

    while (parent) {
      if (!parent.parentElement) return document.documentElement;
      const style = window.getComputedStyle(parent);

      if (style.position === 'static' && node.style.position === 'absolute') {
        parent = parent.parentElement;
        continue;
      }

      const ov = style.overflow;
      const ovX = style['overflow-x'];
      const ovY = style['overflow-y'];

      if (overflowRegex.test(ov) && overflowRegex.test(ovX) && overflowRegex.test(ovY)) {
        return parent;
      }

      parent = parent.parentElement;
    }
  };

  onScrollChange = () => {
    this.setState({
      scrollPosition: {
        x: this.scrollableParent.scrollLeft,
        y: this.scrollableParent.scrollTop,
      },
    });
  };

  checkImageVisibility = () => {
    if (this.state.isLoading || this.state.loaded) return;
    if (this.isImageInViewport()) {
      this.loadImageSource(this.props.src);
    }
  };

  loadImageSource = src => {
    this.image = new Image();
    this.image.src = src;

    this.setState({ isLoading: true });
    if (this.props.onStartLoad) this.props.onStartLoad();

    if (this.image.complete) return this.onImageLoad();
    this.image.addEventListener('load', this.onImageLoad);
  };

  onImageLoad = () => {
    if (this.props.onBeforeLoad) this.props.onBeforeLoad();

    this.setState({ isLoading: false, loaded: true }, () => {
      if (this.props.onAfterLoad) this.props.onAfterLoad();
    });
  };

  /**
   * Check if the image is visible in the viewport.
   * Take offset prop in count.
   * @return {Boolean}
   */
  isImageInViewport = () => {
    if (!this.imageWrapper) return false;

    const { y } = this.state.scrollPosition;
    const wrapperBoundingBox = this.imageWrapper.getBoundingClientRect();
    const parentBoundingBox = this.scrollableParent.getBoundingClientRect();
    // Use offset top value in calculations, it's important for overflow containers:
    const parentOffset = parentBoundingBox.top > 0 ? parentBoundingBox.top : 0;

    const viewportBottom = y + this.scrollableParent.clientHeight + parentOffset;
    const wrapperTop = wrapperBoundingBox.top + y;

    const isInViewport = viewportBottom + Number(this.props.offset) >= wrapperTop;
    return isInViewport;
  };

  render() {
    const wrapperClassNames = classnames('LazyImage', {
      [this.props.className]: this.props.className,
    });
    const imageClassNames = classnames('LazyImage-img', {
      [this.props.imageClassName]: this.props.imageClassName,
    });

    let image = this.props.placeholder;

    if (this.state.loaded) {
      if (this.props.mode === 'image') {
        image = (
          <img
            className={imageClassNames}
            style={this.props.imageStyle}
            src={this.props.src}
            alt={this.props.alt}
          />
        );
      } else if (this.props.mode === 'background') {
        image = (
          <span
            className={imageClassNames}
            style={{ ...this.props.imageStyle, backgroundImage: `url(${this.props.src})` }}
          />
        );
      }
    }

    return (
      <span
        className={wrapperClassNames}
        ref={element => (this.imageWrapper = element)}
        style={this.props.style}
        onClick={this.props.onClick}
      >
        {image}
      </span>
    );
  }
}

export default LazyImage;
