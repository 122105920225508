import React, { Component } from 'react';
import classnames from 'classnames';

import { ASSET_STATUS } from 'constants/main';
import { getSecondaryMarketEnableDiff } from 'utils';

import './AssetDetailsPage.scss';

export default class AssetDetailsPage extends Component {
  render() {
    const asset = this.props.asset;

    const { secondaryMarket } = asset?.financialInstrument?.markets ?? {};
    const timestampDiff = getSecondaryMarketEnableDiff(secondaryMarket);

    const wrapperClassName = classnames('AssetDetailsPage', this.props.applicationMode, {
      trading:
        asset.asset_status === ASSET_STATUS.TRADING_OPENED ||
        (asset.asset_status === ASSET_STATUS.POST_ONLY && timestampDiff <= 0),
      [this.props.className]: this.props.className,
    });

    return (
      <span className={wrapperClassName}>
        <span className="AssetDetailsPage-contentswrapper">
          <span className="AssetDetailsPage-contents">{this.props.children}</span>
        </span>
      </span>
    );
  }
}
