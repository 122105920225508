import React from 'react';
import RallyLogo from 'components/shared/RallyLogo';
import './ErrorPage.scss';

/**
 * ErrorPage component
 * @param  {Node|String} title   - page title
 * @param  {Node|String} message - error message
 */
const ErrorPage = ({ title, message }) => (
  <div className="ErrorPage">
    <div className="ErrorPage-logo">
      <a href="https://app.rallyrd.com/">
        <RallyLogo color="black" size="normal" />
      </a>
    </div>

    {title && <h1 className="ErrorPage-title">{title}</h1>}

    <div className="ErrorPage-body">
      <div className="ErrorPage-message">{message}</div>
    </div>
  </div>
);

ErrorPage.defaultProps = {
  title: null,
  message: 'Sorry, something went wrong',
};

export default ErrorPage;
