import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const show = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

const hide = keyframes({
  from: {
    opacity: 1,
  },
  to: {
    opacity: 0,
  },
});

const animationTime = '.5s';
const animationDelay = '.9s';

export const LoaderContainer = styled.div(({ theme }) => ({
  position: 'fixed',
  height: '100%',
  width: '100%',
  padding: '0 10vh',
  boxSizing: 'border-box',
  top: 0,
  left: 0,
  zIndex: 100,
  background: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  [theme.breakpoints.sm]: {
    position: 'absolute',
  },
}));

export const LoadingImg = styled.img<{ hidden: boolean }>(({ hidden }) => ({
  width: '35vh',
  opacity: 0,
  animation: `${hidden ? hide : show} ${animationTime} ease forwards`,
}));

export const LoadingText = styled.div<{ hidden: boolean }>(({ hidden }) => ({
  fontFamily: 'Gotham Book, sans serif',
  animation: `${hidden ? hide : show} ${animationTime} ease forwards`,
  animationDelay: hidden ? '0s' : animationDelay,
  fontSize: '2.2vh',
  opacity: 0,
  color: '#000',
}));

export const SuccessImg = styled(LoadingImg)<{ halfcheck: boolean }>(({ halfcheck, theme }) => ({
  width: halfcheck ? '20vh' : '14vh',
  marginBottom: halfcheck ? '6.4vh' : '11vh',
  [theme.breakpoints.sm]: {
    marginBottom: halfcheck ? '3.2vh' : '5vh',
  },
}));

export const SuccessText = styled(LoadingText)({
  fontFamily: 'Gotham Bold, sans serif',
});
