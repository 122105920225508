import React, { useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { SecondaryButton } from './Buttons/SecondaryButton';

import './Popup.scss';

/**
 * @prop  {Bool} show / shown - shows or hides popup window
 * @prop  {Bool} disableAutoClose - prevent self closing (controlled mode)
 * @prop  {Bool} fullWidth - popup content will take all available space (no backdrop)
 * @prop  {Function} onRequestClose - сalled when trying to close popup in a controlled mode
 * @prop  {Function} onClick
 * @prop  {Node} children - popup content
 * @prop  {Object} style - styles for popup root
 * @prop  {Object} contentStyle - styles for popup content
 * @prop  {String} className - CSS class for popup root
 * @prop  {String} contentClassName - CSS class for popup content
 */

export const Popup = props => {
  const popupButtonRef = useRef(null);
  const popupWrapperRef = useRef(null);

  const handleClick = e => {
    if (popupButtonRef && popupWrapperRef) {
      if (
        !e.target.contains(popupWrapperRef.current) &&
        !e.target.contains(popupButtonRef.current)
      ) {
        return;
      }
    }
    e.stopPropagation();

    if (props.onClick && !props.disableAutoClose) {
      props.onClick(e);
    }

    props.onRequestClose && props.onRequestClose(e);
  };

  const { style, contentStyle, fullWidth, className, contentClassName, subCta } = props;
  const wrapperClasses = classnames('Popup', { [className]: className });
  const contentClasses = classnames('Popup-contents', {
    'Popup-contents-fullWidth': fullWidth,
    [contentClassName]: contentClassName,
  });

  if (!props.show) return null;

  if (props.useDiv) {
    return (
      <div className={wrapperClasses} style={style} onClick={handleClick} ref={popupWrapperRef}>
        <div className={contentClasses} style={contentStyle}>
          {props.children}
          {props.button && <SecondaryButton onClick={handleClick} buttonRef={popupButtonRef} />}
        </div>
        {subCta}
      </div>
    );
  }

  return (
    <span className={wrapperClasses} style={style} onClick={handleClick} ref={popupWrapperRef}>
      <span className={contentClasses} style={contentStyle}>
        {props.children}
        {props.button && <SecondaryButton onClick={handleClick} buttonRef={popupButtonRef} />}
      </span>
      {subCta}
    </span>
  );
};

Popup.propTypes = {
  show: PropTypes.bool,
  subCta: PropTypes.node,
  useDiv: PropTypes.bool,
  children: PropTypes.node,
  disableAutoClose: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

Popup.defaultProps = {
  show: false,
  subCta: null,
  useDiv: false,
};

export default Popup;
