import React from 'react';
import { SimpleComponent } from 'types/components';
import { AvailableCalendarItem, ComingSoonCalendarItem } from '../CalendarItem';
import { CalendarListContainer, CalendarListTitle } from './styled';
import { IOCategories, GroupedAssetList } from '../types';
import { sortAssetsByGroup } from 'utils/calendar';
import { PickRename } from 'types/utils';
import { isEmpty } from 'utils';

type IOListProps = PickRename<GroupedAssetList, 'io', 'list'>;

const IOList: SimpleComponent<IOListProps> = ({ list }) => {
  return (
    <CalendarListContainer>
      {!isEmpty(list.available) && (
        <div>
          <CalendarListTitle>{IOCategories.Available}</CalendarListTitle>
          {sortAssetsByGroup(list.available, 'io').map(asset => (
            <AvailableCalendarItem key={asset.id} asset={asset} />
          ))}
        </div>
      )}
      {!isEmpty(list.comingSoon) && (
        <div>
          <CalendarListTitle>{IOCategories.ComingSoon}</CalendarListTitle>
          {sortAssetsByGroup(list.comingSoon, 'comingSoon').map(asset => (
            <ComingSoonCalendarItem key={asset.id} asset={asset} />
          ))}
        </div>
      )}
    </CalendarListContainer>
  );
};

export default IOList;
