import React, { useRef, useEffect, useState, useCallback } from 'react';

import clamp from 'lodash.clamp';
import { useSprings, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';

import AssetPreviewItem from '../AssetPreview/AssetPreview';

const RallyCarousel = ({
  parentRef,
  assetList,
  openDetailsCallback,
  onSlideChange,
  initIndex,
  updateInitIndex,
  activeIndex,
}) => {
  const index = useRef(0);
  const [listWidth, setListWidth] = useState(window.innerWidth);

  // bind the list with spring animation library
  const [props, set] = useSprings(assetList.length, i => ({
    transform: `translateX(${i * (listWidth * 0.8)}px)`,
    scale: `scale(1)`,
    display: 'flex',
    opacity: '1',
  }));

  useEffect(() => {
    parentRef <= 600 ? setListWidth(parentRef) : setListWidth(parentRef * 0.9); // adjust listwidth based on width size value provided from parent
  }, [parentRef]);

  const updateSlideIndex = useCallback(() => {
    // reposition to provided index when initially load the screen (open the app/back to certain URL path with asset ticker)
    let indexValue = 0;
    initIndex !== 0 ? (indexValue = initIndex) : (indexValue = activeIndex);

    set(i => {
      let transform = `translateX(${(i - indexValue) * (listWidth * 0.8)}px)`;
      let scale = `scale(0.8)`;
      let opacity = '0.4';
      let display = 'flex';

      if (indexValue === i) {
        scale = `scale(1)`;
        opacity = '1';
        index.current = i;
      }

      if (indexValue < i - 1 || indexValue > i + 1) display = 'none';
      return { transform, scale, display, opacity };
    });
  }, [initIndex, set, activeIndex, listWidth]);

  useEffect(() => {
    updateSlideIndex();
    if (initIndex !== 0) onSlideChange(initIndex);
    updateInitIndex(0);
  }, [initIndex, onSlideChange, updateSlideIndex, updateInitIndex]);

  useEffect(() => {
    if (index.current !== activeIndex) {
      onSlideChange(activeIndex);
      updateSlideIndex();
    }
  }, [activeIndex, onSlideChange, updateSlideIndex, index]);

  // drag gesture handler
  const bind = useDrag(
    ({
      down,
      active,
      buttons,
      last,
      vxvy: [vx, vy],
      movement: [mx],
      direction: [dirx, diry],
      offset: [ox, oy],
      initial: [ix, iy],
      distance,
      cancel,
      canceled,
    }) => {
      if (buttons) cancel(); // prevent desktop mouse dragging
      if (diry > 0.95 || diry < -0.95) cancel(); // cancel vertical drag gesture
      let distanceAbs = Math.abs(distance);

      try {
        set(i => {
          if (i < index.current - 2 || i > index.current + 2) {
            // set display to none for all other items beyond the area to prevent costy re-painting/calculation
            return {
              display: 'none',
              transform: `translateX(${(i - index.current) * (listWidth * 0.8)}px)`,
            };
          }

          let transformXValue = (i - index.current) * (listWidth * 0.8) + (down ? mx * 2 : 0);
          let transform = `translateX(${transformXValue}px)`;
          let scale = `scale(1)`;
          let opacity = '1';
          let display = 'flex';

          if (down && Math.abs(transformXValue) < listWidth / 2) {
            // if dragging reaches to certain point, it tries to save the current item as a temporary current index target item
            localStorage.setItem(
              'carouselCenterTarget',
              clamp(i, index.current - 2, index.current + 2),
            );
          }

          if (last) {
            // if this is the last drag moment, it cancels the gesture interaction and set the current index accordingly
            // this should happen before current index repositioning, otherwise the latest current index item won't be centered.
            let newCurrent = clamp(
              localStorage.getItem('carouselCenterTarget'),
              0,
              assetList.length - 1,
            );

            if (index.current !== newCurrent) onSlideChange(newCurrent); // call parent function to update activeindex
            cancel((index.current = newCurrent));
          }

          let centerTargetIndex = parseInt(localStorage.getItem('carouselCenterTarget'));

          if (i === index.current) {
            // if this item is the current index item, either
            // 1. if mouse/touch finger is down, scale/reposition accordingly
            // 2. if not, reposition it to center with full scale and opacity value

            if (!down) transform = `translateX(0px)`;
            scale = down ? `scale(${1 - distanceAbs * 0.00055})` : `scale(1)`;
            opacity = down ? `${1.0 - distanceAbs * 0.002}` : '1';
          } else {
            if (down) {
              // if mouse/touch finger is down
              if (i === centerTargetIndex) {
                scale = `scale(${0.8 + distanceAbs * 0.0008})`;
                opacity = `${0.4 + distanceAbs * 0.007}`;
              } else {
                scale = `scale(${0.7 + distanceAbs * 0.0001})`;
                opacity = `${0.4 + distanceAbs * 0.001}`;
              }
            } else {
              // all other items repositioned accordingly when interaction is done
              transformXValue = (i - index.current) * (listWidth * 0.8) + (down ? mx : 0);
              transform = `translateX(${transformXValue}px)`;
              scale = `scale(${0.8})`;
              opacity = '0.4';
            }
          }

          return { transform, scale, display, opacity };
        });
      } catch (e) {
        console.error(e);
      }
    },
  );

  return props.map(({ transform, scale, display, opacity }, i) => (
    <animated.div
      className="AssetCarousel-swipeableitem"
      {...bind()}
      key={i}
      style={{ display, transform }}
    >
      <animated.div style={{ transform: scale, opacity: opacity }}>
        <AssetPreviewItem asset={assetList[i]} openDetailsCallback={openDetailsCallback} />
      </animated.div>
    </animated.div>
  ));
};

export default RallyCarousel;
