import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import ContentHeader from 'components/shared/ContentHeader';
import AccountSettings from './AccountSettings';
import PersonalInformation from './PersonalInformation';
import ChangePasswordPopup from './ChangePasswordPopup';
import { setPageTitle } from 'utils';
import analytics from 'services/analytics';

import { SEGMENT_ACTIONS, SEGMENT_CATEGORIES } from 'constants/analytics';

import './Settings.css';

class Settings extends Component {
  state = {
    notAuth: false,
    showChangePasswordPopup: false,
    isPersonalInfoExpanded: false,
  };

  componentDidMount() {
    setPageTitle('Settings');
    analytics.track('Settings View', {
      category: SEGMENT_CATEGORIES.SETTINGS,
      action: SEGMENT_ACTIONS.OPEN,
    });
  }

  togglePersonalInfoExpansion = () => {
    this.setState({ isPersonalInfoExpanded: !this.state.isPersonalInfoExpanded });
  };

  toggleChangePasswordPopup = () => {
    this.setState({
      showChangePasswordPopup: !this.state.showChangePasswordPopup,
      isPersonalInfoExpanded: false,
    });
  };

  render() {
    if (this.state.notAuth) {
      return <Redirect to="/login" />;
    }
    return (
      <div className="Settings">
        <ContentHeader>SETTINGS</ContentHeader>
        <AccountSettings toggleChangePasswordPopup={this.toggleChangePasswordPopup} />
        <PersonalInformation
          expanded={this.state.isPersonalInfoExpanded}
          onExpandCollapseClick={this.togglePersonalInfoExpansion}
        />
        <ChangePasswordPopup
          show={this.state.showChangePasswordPopup}
          onRequestClose={this.toggleChangePasswordPopup}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Settings);
