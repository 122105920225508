import React, { createContext, useContext, useState, useEffect, cloneElement } from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';
import { CSSTransition } from 'react-transition-group';

import './Modal.css';

export const ModalViewContext = createContext();

export const ModalViewProvider = ({ children }) => {
  const { viewRenderSequence, setModalViewRenderSequence } = useModalView();
  const [viewArray, setViewArray] = useState([]);

  // update handler for multiple views and view render sequencing - mostly for chain modal interactions
  const updateViews = () => {
    if (viewRenderSequence.length <= 0) return;
    let viewExitDelayForTransition = 0;

    viewRenderSequence.reduce(
      (
        viewArrayChanges,
        { state, id, children = undefined, onClose = undefined, ...viewProps },
      ) => {
        const latestViewArrayData = viewArrayChanges.length
          ? viewArrayChanges.slice(-1).pop()
          : viewArray;

        // if new view is going to be created, add a new view object to view array
        if (state) {
          const revisedArray = [
            ...latestViewArrayData,
            {
              state: state,
              id: id,
              content: children,
              onClose: (completionType = undefined) =>
                setModalViewRenderSequence([
                  { state: false, id: id, viewCompletionType: completionType },
                ]),
              contextHandlers: viewProps || undefined,
            },
          ];

          viewArrayChanges.push(revisedArray);
          setTimeout(() => setViewArray(revisedArray), viewExitDelayForTransition || 0);
        } else {
          // if existing view is going to be removed/unloaded
          // add view exit delay and a boolean field indicating exit for DOM exit transition to view object and save to view array
          const exitDelaySetup = latestViewArrayData.filter(vw => {
            if (vw.id !== id) return vw;

            vw.contextHandlers.delay = 450;
            vw.contextHandlers.isExiting = true;

            return vw;
          });

          setViewArray(exitDelaySetup);
          viewExitDelayForTransition = 450;

          const revisedArray = latestViewArrayData.filter(vw => vw.id !== id);
          const getCloseCompletionHandler =
            latestViewArrayData.find(vw => vw.id === id).contextHandlers.onCloseCompletion ||
            undefined;
          viewArrayChanges.push(revisedArray); // update viewArrayChanges to reflect any pending view DOM changes for sequences

          // save a new view array without the view targeted for removal/unload -- after transition
          setTimeout(() => {
            setViewArray(revisedArray);
            viewExitDelayForTransition = 0;

            getCloseCompletionHandler && getCloseCompletionHandler(viewProps.viewCompletionType);
          }, 450);
        }

        return viewArrayChanges;
      },
      [],
    );
  };

  useEffect(updateViews, [viewRenderSequence]);

  return (
    <ModalViewContext.Provider value={{ viewArray, setModalViewRenderSequence }}>
      <Modal />
      {children}
    </ModalViewContext.Provider>
  );
};

export const useModalView = () => {
  const [viewRenderSequence, setViewRenderSequence] = useState([]);

  const setModalViewRenderSequence = views => setViewRenderSequence(views);

  return { viewRenderSequence, setModalViewRenderSequence };
};

export const Modal = () => {
  const { viewArray } = useContext(ModalViewContext);

  return viewArray.length > 0
    ? ReactDOM.createPortal(
        <ModalContainer>
          {viewArray.map(v => (
            <ModalOverlay key={v.id}>
              <ModalBackgroundLayer
                {...(v.contextHandlers.modalStyle || undefined)}
                onClick={() => !v.contextHandlers.blockForceDismiss && v.onClose()}
              ></ModalBackgroundLayer>
              <CSSTransition
                in={!v.contextHandlers.isExiting}
                timeout={{
                  appear: 400,
                  enter: 400,
                  exit: v.contextHandlers.isExiting ? 450 : 0,
                }}
                classNames={v.contextHandlers.transition || 'modalViewSlideIn'}
                appear
              >
                <ModalContent {...(v.contextHandlers.modalStyle || undefined)}>
                  {cloneElement(v.content, {
                    viewId: v.id,
                  })}
                </ModalContent>
              </CSSTransition>
            </ModalOverlay>
          ))}
        </ModalContainer>,
        document.querySelector('#modal-root'),
      )
    : null;
};

export const getViewContexts = (viewArray, id) => {
  // this is used for getting context props (contextHandlers) for each modal object. this should be used to avoid prop drilling
  return viewArray.filter(v => v.id === id)[0];
};

const fadeIn = keyframes`from { opacity: 0; } to { opacity: 1; }`;

const ModalOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
`;

const ModalContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 99;
`;

const ModalContent = styled.div`
  box-sizing: border-box;
  max-height: ${({ fillEntireScreen }) => (fillEntireScreen ? '100%' : '80%')};
  height: ${({ fillEntireScreen }) => (fillEntireScreen ? '100%' : 'auto')};
  width: ${({ fillEntireScreen, mobileFillEntireScreen }) =>
    fillEntireScreen || mobileFillEntireScreen ? '100%' : '40%'};
  background: ${({ bgColor }) => bgColor || 'white'};
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  ${({ mobileFillEntireScreen }) =>
    mobileFillEntireScreen &&
    `
      @media only screen and (max-width: 600px) {
        height: 100%;
        max-height: 100%;
        justify-content: center;
      }
    `}

  @media only screen and (max-width: 600px) {
    width: ${({ fillEntireScreen, mobileFillEntireScreen }) =>
      fillEntireScreen || mobileFillEntireScreen ? '100%' : '85%'};
  }
`;

const ModalBackgroundLayer = styled.div`
  animation: ${fadeIn} 100ms ease;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ overlayBgColor }) => overlayBgColor || 'rgba(0, 0, 0, 0.05)'};
  display: flex;
  justify-content: center;
  align-items: center;
`;
