import React, { ComponentClass } from 'react';
import { useSelector } from 'react-redux';
import { postPrevalidateEditingOrder, postPrevalidateOrder } from 'services/AssetsAPI';
import { RootState } from 'store';
import { SimpleComponent } from 'types/components';
import { OrderContext, OrderTerm, OrderType, PostOrderData } from 'types/orders';

const withAssetPrevalidation = (ComponentToEnhace: SimpleComponent<any> | ComponentClass) => {
  const EnhancedComponent = (props: any) => {
    const customer: any = useSelector<RootState>(state => state.Auth.user.customer);
    const prevalidateOrder = async () => {
      const { data } = props;
      const { buySellOrderType } = data;

      const unitPrice = Number(
        buySellOrderType === OrderType.INSTANT ? data.askLow : data.pricePerShare,
      );
      const units = buySellOrderType === OrderType.INSTANT ? 1 : data.shares;
      const orderData: PostOrderData = {
        financialInstrumentId: data.asset.financialInstrument.id,
        type: 'LIMIT',
        term:
          buySellOrderType === OrderType.INSTANT
            ? OrderTerm.IMMEDIATE_OR_CANCEL
            : OrderTerm.GOOD_TIL_CANCELED,
        units,
        context: data.side === OrderContext.ASK ? OrderContext.ASK : OrderContext.BID,
        unitPrice,
        disclosureReadAndAgreedTo: true,
      };
      if (data.giftCode) orderData.promotionalCode = data.giftCode;
      let responseData;
      if (data.isEditing) {
        const response = await postPrevalidateEditingOrder(
          {
            units: orderData.units,
            unitPrice: orderData.unitPrice,
          },
          customer.accounts[0].id,
          data.originalOrderData?.id,
        );
        responseData = response.data;
      } else {
        const response = await postPrevalidateOrder(orderData, customer.accounts[0].id);
        responseData = response.data;
      }
      return { responseData, orderData };
    };

    return <ComponentToEnhace {...props} prevalidateOrder={prevalidateOrder} />;
  };
  return EnhancedComponent;
};

export default withAssetPrevalidation;
