import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Loader from 'components/shared/Media/ImageLoader';
import { Checkbox } from 'components/shared/Inputs/Checkbox';
import { Button } from 'components/shared/Buttons/Button';
import { Checkmark } from 'components/shared/Icons/Checkmark';
import { Price } from 'components/shared/Price';
import { SecondaryButton } from 'components/shared/Buttons/SecondaryButton';
import Error from 'components/shared/Error';

import {
  createInvestment,
  removeGiftCodeApplied,
  removeAssetsFromAccessGroups,
  setAutoGiftCode,
  setSaPopup,
} from 'actions';

import './Success.scss';
import { BASE_EXCEPTION, NO_FREE_PROMOCODES_EXCEPTION } from 'constants/errors';

class Success extends Component {
  static propTypes = {
    data: PropTypes.object,
    onStepForward: PropTypes.func.isRequired,
    createInvestment: PropTypes.func.isRequired,
  };

  state = { isUserAgree: false, loading: false, error: null };

  onConfirmPurchase = async () => {
    const {
      saPopup,
      data = {},
      removeAssetsFromAccessGroups,
      setAutoGiftCode,
      setSaPopup,
      agreementTimestamp,
      createInvestment,
      onStepForward,
      removeGiftCodeApplied,
    } = this.props;

    let payload = {
      shares: data.shares,
      asset: data.asset?.id,
      investment_info: {
        sec_or_finra_organization_name: data.sec_or_finra_organization_name,
        publicly_traded_company_tickers: data.publicly_traded_company_tickers,
        accredited_status: data.accredited_status,
      },
      promo_value: data.giftCode?.value || undefined,
      redeemCode: data.giftCode?.redeemCode || undefined,
    };

    if (saPopup?.asset?.type === 'free_share_program') {
      payload = {
        ...payload,
        [saPopup.asset.type]: true,
      };

      if (payload.promo_value) delete payload.promo_value;

      removeAssetsFromAccessGroups(saPopup.asset.type, [data.asset?.id]);
      setAutoGiftCode(null);
    }

    setSaPopup({});

    if (data.asset?.docusign_template_id) {
      payload.envelope_id = data.docusignEnvelopeId;
    } else {
      payload.sa_signed_and_agreed_at = agreementTimestamp;
    }

    this.setState({ loading: true });
    try {
      await createInvestment(payload);
      onStepForward();
    } catch (error) {
      this.setState({ error, loading: false });
    }

    removeGiftCodeApplied();
  };

  onUserAgreeChange = () => {
    this.setState({ isUserAgree: !this.state.isUserAgree });
  };

  renderLoadingMessage = () => (
    <div className="Success-loading">
      <h1>Processing</h1>
      <p>It may take a few seconds...</p>
      <div className="Success-loader">
        <Loader width="150" height="150" />
      </div>
    </div>
  );

  renderErrorMessage = () => {
    const { error } = this.state;
    const data = error.response ? error.response.data : error;

    let title = 'An error has occurred';

    if (data.error_type && data.error_type === NO_FREE_PROMOCODES_EXCEPTION) {
      title = 'Promo Code Not Valid';
    }
    // This is not the ideal way of handling this; we should raise a NOT_ENOUGH_SHARES_EXCEPTION which requires API work.
    if (data.error_type === BASE_EXCEPTION) {
      title = 'Not enough shares';
    }

    return (
      <div className="Success-error">
        <h1>{title}</h1>
        <Error error={data} />
        <SecondaryButton onClick={this.props.onFormClose}>
          <b>OKAY</b>
        </SecondaryButton>
      </div>
    );
  };

  renderConfirmation = () => (
    <div className="Success">
      <h1>
        <Checkmark />
        Success!
      </h1>
      <p className="Success-description">
        By agreeing to the Terms of Service below, you confirm your purchase of{' '}
        <b>
          {this.props.data.shares} share{this.props.data.shares === 1 ? '' : 's'}
        </b>{' '}
        in&nbsp;
        <b>{this.props.data.asset.ticker}</b> for a total cost of{' '}
        <span className="Success-description-price">
          <Price price={this.props.data.cost} />
        </span>
        .
        {this.props.data.giftCode.value && (
          <span className="Success-promo-info">
            (${this.props.data.giftCode.value} Credit Applied towards total)
          </span>
        )}
      </p>
      <div className="Success-tos">
        <Checkbox
          name="agree"
          size="large"
          checked={this.state.isUserAgree}
          onChange={this.onUserAgreeChange}
        />
        <label htmlFor="agree">
          I have read and agree to the Rally{' '}
          <a href="https://www.rallyrd.com/privacy" target="_blank" rel="noopener noreferrer">
            Terms of Service
          </a>
        </label>
      </div>
      <Button onClick={this.onConfirmPurchase} disabled={!this.state.isUserAgree}>
        <b>CONFIRM PURCHASE</b>
      </Button>
    </div>
  );

  render() {
    const { loading, error } = this.state;
    if (loading) return this.renderLoadingMessage();
    if (error) return this.renderErrorMessage();
    // if (success) this.props.onStepForward();
    return this.renderConfirmation();
  }
}

const mapStateToProps = state => ({
  activeAssetMerchandisingOptions: state.Assets.activeAssetMerchandisingOptions,
  agreementTimestamp: state.Investments.agreementTimestamp,
  saPopup: state.UI.saPopup,
  user: state.Auth.user,
});

const mapDispatchToProps = {
  createInvestment,
  removeGiftCodeApplied,
  removeAssetsFromAccessGroups,
  setAutoGiftCode,
  setSaPopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Success);
