import React, { Component } from 'react';
import { connect } from 'react-redux';

import { CloseButton } from 'components/shared/Buttons/CloseButton';
import DetailsDocumentButton from './DetailsDocumentButton';
import DetailCategory from './DetailCategory';

import { SEGMENT_ACTIONS, SEGMENT_CATEGORIES } from 'constants/analytics';

import analytics from 'services/analytics';
import { isEmpty } from 'utils';
import './Details.scss';

class Details extends Component {
  onCloseDetails = () => this.props.history.goBack();

  componentDidMount() {
    const { activeAsset } = this.props;

    if (!isEmpty(activeAsset)) {
      const assetTicker = activeAsset.ticker;

      analytics.track('2.0 Details', {
        category: SEGMENT_CATEGORIES.ASSET2_0_PORTAL,
        action: SEGMENT_ACTIONS.OPEN,
        assetTicker: assetTicker,
      });

      analytics.page(`${assetTicker} Details View`);
    }
  }

  render() {
    const activeAsset = this.props.activeAsset || {};

    return (
      <div className={`Details ${this.props.applicationMode}`}>
        <CloseButton onClickHandler={this.onCloseDetails} />
        <div className="Details-contents">
          <div className="Details-header" style={{ backgroundColor: activeAsset.tertiary_color }}>
            <div className="Details-header-ticker">{activeAsset.ticker}</div>
            <div className="Details-header-name">{activeAsset.display_name}</div>
            <div className="Details-header-documents">
              {activeAsset &&
                activeAsset.document &&
                activeAsset.document.map(object => (
                  <DetailsDocumentButton
                    key={object['id']}
                    media={object['document_media']}
                    style={{ backgroundColor: activeAsset.tertiary_color }}
                  >
                    {object['text']}
                  </DetailsDocumentButton>
                ))}
            </div>
          </div>
          <div className="Details-detailcategories">
            {activeAsset &&
              activeAsset.categories &&
              activeAsset.categories.map(object => (
                <DetailCategory key={object['id']} details_info={object} />
              ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeAsset: state.Assets.activeAsset,
  applicationMode: state.UI.applicationMode,
});

export default connect(mapStateToProps)(Details);
