import React, { useCallback, useMemo, useState } from 'react';
import { BackNextButtons } from 'components/shared/Buttons';
import { Radio, IRadioOption } from 'components/shared/Inputs';
import { EMPLOYMENT_STATUS, STATUSES_TO_SKIP_EMPLOYER } from 'constants/main';

import './EmploymentStatus.scss';
import { SimpleComponent } from 'types/components';
import { putEmploymentDetails } from 'services/CustomerApi';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { PassportData } from 'utils/passport';
import Bugsnag from '@bugsnag/js';
import { useGeneralErrorAlert } from 'hooks';
import { setEmploymentDetails } from 'actions/customer';

// TODO in the future we will create a type for data at MakeInvestment file
// and here we will use a Pick to get employmentStatus from Data type
// and use it as type for onFormUpdate param

type Customer = { customer: { id: string } };

type EmploymentStatusProps = {
  onStepForward: Function;
  onStepBackward: Function;
  onFormUpdate: (param: { employmentStatus: string }) => void;
  data: PassportData;
};

export const employmentOptions: IRadioOption[] = [
  {
    text: 'Employed',
    value: EMPLOYMENT_STATUS.EMPLOYED,
  },
  {
    text: 'Self-Employed',
    value: EMPLOYMENT_STATUS.SELF,
  },
  {
    text: 'Unemployed',
    value: EMPLOYMENT_STATUS.UNEMPLOYED,
  },
  {
    text: 'Retired',
    value: EMPLOYMENT_STATUS.RETIRED,
  },
  {
    text: 'Student',
    value: EMPLOYMENT_STATUS.STUDENT,
  },
];

const EmploymentStatus: SimpleComponent<EmploymentStatusProps> = ({
  onStepForward,
  onStepBackward,
  data,
  onFormUpdate,
}) => {
  const [loading, setLoading] = useState(false);
  const { customer } = useSelector<RootState, Customer>(state => state.Auth.user);
  const showErrorAlert = useGeneralErrorAlert();
  const dispatch = useDispatch();

  const handleOnChange = useCallback(
    (employmentStatus: string) => {
      onFormUpdate({ employmentStatus });
    },
    [onFormUpdate],
  );

  const { employmentStatus } = data;

  const nextDisabled = useMemo(() => !employmentStatus, [employmentStatus]);

  const handleNextStep = () => {
    if (!STATUSES_TO_SKIP_EMPLOYER.includes(employmentStatus)) {
      // if the employer is required will send to the backend the employment status
      // at employer information screen
      onStepForward();
      return;
    }
    setLoading(true);
    const employmentDetails = {
      employer: '',
      occupationOrJobTitle: '',
      status: employmentStatus,
    };
    putEmploymentDetails(employmentDetails, customer.id)
      .then(() => {
        dispatch(setEmploymentDetails(employmentDetails));
        onStepForward();
      })
      .catch(error => {
        showErrorAlert();
        Bugsnag.notify(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="employment-status">
      <h3 className="employment-status__title">Current Employment Status</h3>
      <div>
        <Radio
          value={employmentStatus}
          options={employmentOptions}
          onChange={handleOnChange}
          name="employment-status"
        />
      </div>
      <BackNextButtons
        buttons={{ back: { text: 'back' }, next: { text: 'next' } }}
        loading={loading}
        nextDisabled={nextDisabled}
        onBackClick={onStepBackward}
        onNextClick={handleNextStep}
      />
    </div>
  );
};

export default EmploymentStatus;
