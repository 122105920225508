import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/shared/Buttons/Button';

import stoplightsImage from 'images/stoplights.png';
import './OnboardingIntro.css';

class OnboardingIntro extends Component {
  static propTypes = {
    onStepForward: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className="OnboardingIntro">
        <img src={stoplightsImage} alt="stoplights" className="OnboardingIntro-img" />
        <h2>Creating Your Profile</h2>
        <p>
          Before Investing on Rally, we'll need some details to create your account + you'll have
          the option to fund your balance now. All information is encrypted and will NEVER be sold.
        </p>
        <p className="light">
          You can read more about our&nbsp;
          <a href="https://www.rallyrd.com/privacy" target="_blank" rel="noopener noreferrer">
            privacy policy here
          </a>
          , and can learn about our platform security&nbsp;
          <a href="https://www.rallyrd.com/privacy" target="_blank" rel="noopener noreferrer">
            here
          </a>
          .
        </p>
        <Button text={<b>OKAY!</b>} arrow="forward" onClick={() => this.props.onStepForward()} />
      </div>
    );
  }
}

export default OnboardingIntro;
