import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BackNextButtons } from 'components/shared/Buttons/BackNextButtons';
import { YesNoSelect } from 'components/shared/Buttons/YesNoSelect';

import './BankAccountType.css';

class BankAccountType extends Component {
  static propTypes = {
    data: PropTypes.object,
    onFormUpdate: PropTypes.func.isRequired,
    onStepForward: PropTypes.func.isRequired,
    onStepBackward: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { isStepValid: this.props.data.accountType !== undefined };
  }

  validateStep = () => {
    const isStepValid = this.props.data.accountType !== undefined;
    this.setState({ isStepValid });
  };

  onChoiceChange = value => {
    this.props.onFormUpdate(
      {
        accountType: value,
      },
      this.validateStep,
    );
  };

  render() {
    const {
      data: { accountType },
    } = this.props;
    const wantedSelected = accountType && accountType === 'SAVINGS';

    return (
      <div className="BankAccountType">
        <h2>Link Your Account</h2>
        <p>Select Account Type</p>
        <YesNoSelect
          name="accountType"
          options={['SAVINGS', 'CHECKING']}
          onChange={this.onChoiceChange}
          wanted="SAVINGS"
          wantedSelected={wantedSelected}
          showColors={false}
        />

        <BackNextButtons
          buttons={{ back: { text: 'back' }, next: { text: 'next' } }}
          nextDisabled={!this.state.isStepValid}
          onBackClick={() => this.props.onStepBackward()}
          onNextClick={() => this.props.onStepForward()}
        />
      </div>
    );
  }
}

export default BankAccountType;
