import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

class TradingCloseCountdown extends Component {
  static propTypes = { closingTime: PropTypes.instanceOf(Date) };

  state = {
    countdownTimer: null,
    countdown: null,
  };

  componentDidMount() {
    const countdown = this.getFormattedTimeLeft(this.getClosingTime());
    const countdownTimer = setInterval(this.countdownTick, 1000);
    this.setState({ countdown, countdownTimer });
  }

  componentWillUnmount() {
    clearInterval(this.state.countdownTimer);
  }

  countdownTick = () => {
    this.setState({ countdown: this.getFormattedTimeLeft(this.getClosingTime()) });
  };

  getFormattedTimeLeft(endTime) {
    const diff = moment(endTime).diff(moment()); // <- diff between end time and current time
    const timeLeft = moment.duration(diff);

    return (
      <span>
        {diff <= 0 || !endTime ? '0' : timeLeft.hours()}hr,&nbsp;
        {diff <= 0 || !endTime ? '0' : timeLeft.minutes()}m,&nbsp;
        {diff <= 0 || !endTime ? '0' : timeLeft.seconds()}sec
      </span>
    );
  }

  getClosingTime = () => {
    if (this.props.closingTime) return this.props.closingTime;
    const { secondaryMarket } = this.props.activeAsset.financialInstrument.markets;
    return secondaryMarket.sessionHours.dateClose;
  };

  render() {
    // defaultClosingTime is 4PM ET
    // todo: timezone to 'America/New_York'
    // const defaultClosingTime = moment().tz('America/New_York').startOf('day').add(16, 'hours').fromNow();
    return <span className="TradingCloseCountdown">{this.state.countdown}</span>;
  }
}

const mapStateToProps = state => ({
  activeAsset: state.Assets.activeAsset,
});

export default connect(mapStateToProps, null)(TradingCloseCountdown);
