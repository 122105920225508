import React, { Component } from 'react';
import { connect } from 'react-redux';

import withDeviceDetection from 'hoc/withDeviceDetection';
import AssetDetails from '../AssetDetails/AssetDetails';
import AssetBanner from './AssetBanner';
import AssetEarlyAccessBanner from './AssetEarlyAccessBanner';
import CarouselNavButton from './CarouselNavButton';
import RallyCarousel from './RallyCarousel';
import { LeftCaret } from 'components/shared/Icons/LeftCaret';
import { RightCaret } from 'components/shared/Icons/RightCaret';
import { DotsLoader } from 'components/shared/Loaders';

import { SUPPORTED_APPLICATION_MODES } from 'constants/main';

import { subscribeUserOnAsset, unsubscribeUserFromAsset } from 'actions';

import './AssetCarousel.scss';

class AssetCarousel extends Component {
  state = { activeIndex: 0, initIndex: 0, carouselContainerWidth: 0 };

  componentDidMount() {
    if (this.props.activeIndex !== this.state.activeIndex) {
      this.setState({ activeIndex: this.props.activeIndex, initIndex: this.props.activeIndex });
    }

    if (this.props.applicationMode === SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS) {
      this.props.openDetailsCallback();
    }

    if (this.assetCarouselParentContainer)
      this.setState({ carouselContainerWidth: this.assetCarouselParentContainer.offsetWidth });
  }

  /**
   * Update slide when active index was changed from outside by clicking on assests menu list
   */

  static getDerivedStateFromProps(nextProps, prevState) {
    return nextProps.activeIndex === prevState.activeIndex
      ? {}
      : { activeIndex: nextProps.activeIndex };
  }

  /**
   * Prevent unnecessary rerendering.
   * Makes swiping more smooth, especially on mobile.
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.detailsOpened !== this.props.detailsOpened) return true;
    if (nextProps.assetsOverlayVisible !== this.props.assetsOverlayVisible) return true;

    if (JSON.stringify(nextProps.activeAsset) !== JSON.stringify(this.props.activeAsset)) {
      return true;
    }

    if (nextProps.detailsOpened && nextProps.activeAsset.id !== this.props.activeAsset.id) {
      return true;
    }

    if (!this.props.userDevice.isMobile) {
      if (this.state.activeIndex !== nextState.activeIndex) return true;
    }

    if (nextProps.activeIndex === this.state.activeIndex) return false;
    return true;
  }

  onSlideChange = slideIndex => {
    if (
      this.state.activeIndex !== slideIndex &&
      slideIndex < this.props.assetList.length &&
      slideIndex >= 0
    ) {
      this.setState({ activeIndex: slideIndex }, () => {
        this.props.handleSlideSwitch(slideIndex);
      });
    }
  };

  moveSlideNext = () => {
    let indexNum = this.state.activeIndex + 1;
    this.onSlideChange(indexNum);
  };

  moveSlidePrev = () => {
    let indexNum = this.state.activeIndex - 1;
    this.onSlideChange(indexNum);
  };

  updateInitIndex = index => {
    this.setState({ initIndex: index });
  };

  render() {
    const { detailsOpened, activeAsset, assetsOverlayVisible } = this.props;

    return (
      <div className={`AssetCarousels ${this.props.applicationMode}`}>
        {assetsOverlayVisible && (
          <div className="AssetCarousels-overlay">
            <DotsLoader color="#6b6666" />
          </div>
        )}

        {!detailsOpened ? (
          <div
            className="AssetCarousel"
            ref={assetCarouselParentContainer =>
              (this.assetCarouselParentContainer = assetCarouselParentContainer)
            }
          >
            <RallyCarousel
              parentRef={window.innerWidth}
              assetList={this.props.assetList}
              openDetailsCallback={this.props.openDetailsCallback}
              onSlideChange={this.onSlideChange}
              initIndex={this.state.initIndex}
              updateInitIndex={this.updateInitIndex}
              activeIndex={this.state.activeIndex}
            />

            {this.props.assetList.length > 1 && (
              <>
                <CarouselNavButton
                  className="AssetCarousel-arrow-prev"
                  onClick={this.moveSlidePrev}
                >
                  <LeftCaret />
                </CarouselNavButton>
                <CarouselNavButton
                  className="AssetCarousel-arrow-next"
                  onClick={this.moveSlideNext}
                >
                  <RightCaret />
                </CarouselNavButton>
              </>
            )}
            <AssetBanner />
          </div>
        ) : (
          <>
            {this.props.applicationMode === SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS && (
              <AssetEarlyAccessBanner />
            )}
            <AssetDetails
              key={activeAsset.id}
              closeDetalsCallback={this.props.closeDetalsCallback}
              history={this.props.history}
              subscribeUserOnAsset={this.props.subscribeUserOnAsset}
              unsubscribeUserFromAsset={this.props.unsubscribeUserFromAsset}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  applicationMode: state.UI.applicationMode,
  activeAsset: state.Assets.activeAsset,
  subscriptions: state.Auth.subscriptions,
  assetsOverlayVisible: state.UI.assetsOverlayVisible,
});

const mapDispatchToProps = dispatch => ({
  subscribeUserOnAsset: payload => dispatch(subscribeUserOnAsset(payload)),
  unsubscribeUserFromAsset: payload => dispatch(unsubscribeUserFromAsset(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withDeviceDetection(AssetCarousel));
