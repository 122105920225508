import React from 'react';

const AddToCalendar = props => (
  <svg
    className="addToCalendar"
    xmlns="http://www.w3.org/2000/svg"
    width="96"
    height="96"
    viewBox="0 0 96 96"
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path d="M0 72h72V0H0z" />
      </clipPath>
    </defs>
    <g clipPath="url(#a)" transform="matrix(1.33333 0 0 -1.33333 0 96)">
      <path
        d="M56.27 10.81H16.012a3.74 3.74 0 0 0-3.735 3.734v29.852h47.727V14.544a3.738 3.738 0 0 0-3.733-3.734M16.011 57.688h3.944v-3.722h3.977v3.722H48.35v-3.722h3.977v3.722h3.944a3.738 3.738 0 0 0 3.734-3.734v-5.58H12.277v5.58a3.739 3.739 0 0 0 3.735 3.734m40.258 3.977h-3.944v3.501H48.35v-3.5H23.933v3.5h-3.977v-3.5h-3.944A7.711 7.711 0 0 1 8.3 53.955v-39.41a7.713 7.713 0 0 1 7.712-7.712H56.27a7.712 7.712 0 0 1 7.712 7.712v39.41a7.71 7.71 0 0 1-7.712 7.71"
        fill={props.fill || '#737373'}
      />
      <path
        d="M38.13 36.663h-3.977v-6.77h-6.77v-3.977h6.77v-6.77h3.977v6.77h6.77v3.976h-6.77z"
        fill={props.fill || '#737373'}
      />
    </g>
  </svg>
);

export default AddToCalendar;
