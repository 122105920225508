import { combineReducers } from 'redux';

import Assets from './Assets';
import Investments from './Investments';
import Trading from './Trading';
import Auth from './Auth';
import UI from './UI';

import { WIPE_REDUX_STATE } from 'actions/types';

const appReducer = combineReducers({
  Assets,
  Auth,
  Investments,
  Trading,
  UI,
});

const rootReducer = (state, action) => {
  // clean up redux state (return to initial state) after receiving 'WIPE_REDUX_STATE' action:
  if (action.type === WIPE_REDUX_STATE) state = undefined;
  return appReducer(state, action);
};

export default rootReducer;
