import React, { Component } from 'react';
import { connect } from 'react-redux';

import DwollaStatusPopup from 'components/shared/DwollaStatusPopup';
import MultistepForm from 'components/shared/MultistepForm';
import StateAvailabilityError from 'components/shared/StateAvailabilityError';
import InvestmentRestrictedPopup from 'components/shared/InvestmentRestrictedPopup';
import { CloseButton } from 'components/shared/Buttons/CloseButton';
import { PassportSteps } from 'components/shared/MultistepForm/steps/profile';
import {
  StartedInfo,
  SharesSelect,
  InvestingPassport,
  NotEnoughCash,
  Docusign,
  SubscriptionAgreement,
  Success,
  Congrats,
  Tos3Screen,
} from 'components/shared/MultistepForm/steps/investment';
import {
  getBalance,
  getInvestments,
  getBankFundingSource,
  lockActiveAsset,
  unlockActiveAsset,
  getDwollaCustomerStatus,
  startInvestments,
} from 'actions';
import { isEmpty } from 'utils';
import analytics from 'services/analytics';
import giftImage from 'images/gift.png';
import './MakeInvestment.scss';

import { SEGMENT_ACTIONS, SEGMENT_CATEGORIES } from 'constants/analytics';
import {
  STATUSES_TO_SKIP_EMPLOYER,
  FUNDING_SOURCE_STATUS,
  PASSPORT_SECTION,
  ACCOUNT_STATUS,
  MIN_INCOME,
} from 'constants/main';
import { createPassportData } from 'utils/passport';
import { RiskToleranceLevel } from 'types/customer';

class MakeInvestment extends Component {
  constructor(props) {
    super(props);

    const initialFormData = this.setInitialFormData();

    this.state = { ...initialFormData };
  }

  steps = [];

  componentDidMount() {
    this.props.lockActiveAsset();
    this.props.getBankFundingSource();
    this.props.getBalance();
    this.props.getInvestments();
    this.props.getDwollaCustomerStatus();
    this.trackAnalyticsViewEvent(0);
    this.startInvestment();
  }

  startInvestment = async () => {
    const availableShares = await this.props.startInvestments(this.props.activeAsset.id);
    availableShares && this.setState({ availableShares: availableShares.available_shares });
  };

  componentWillReceiveProps(nextProps) {
    // Update user shares with actual data
    if (nextProps.total_shares) {
      const { activeAsset } = this.props;
      const userShares = nextProps.total_shares[activeAsset.id] || 0;
      this.setState({ userShares });
    }
  }

  componentDidUpdate({ user: prevUser }, prevState) {
    const { user } = this.props;
    const { investingPassportInfoCompleted } = this.state;

    const fundingSourceExists =
      !isEmpty(this.props.bankFundingSource.info) || this.state.fundingSourceCreated;

    if (
      !prevState.investingPassportInfoCompleted &&
      investingPassportInfoCompleted &&
      fundingSourceExists
    ) {
      this.handleFormUpdate({ investingPassportApplied: false });
    }

    if (!prevUser.customer.employmentDetails && user.customer.employmentDetails) {
      const { investment_info } = user;
      const {
        employmentStatus,
        employer,
        occupationOrJobTitle,
        liquidNetWorth,
        pretaxAnnualIncome,
        riskToleranceLevel,
      } = createPassportData({
        user,
        basicProfileCompleted: true,
        giftCode: 0,
        ...investment_info,
        balance: {},
        activeAsset: {},
        userShares: 1,
      });
      this.setState({
        ...this.state,
        employmentStatus,
        employer,
        occupationOrJobTitle,
        liquidNetWorth,
        pretaxAnnualIncome,
        riskToleranceLevel,
      });
    }
  }

  componentWillUnmount() {
    this.props.unlockActiveAsset();
  }

  setInitialFormData = () => {
    const { user, balance, activeAsset, total_shares, giftCodeApplied, bankFundingSource } =
      this.props;
    const userShares = total_shares[activeAsset.id];

    const { accredited_status, publicly_traded_company_tickers, sec_or_finra_organization_name } =
      user.investment_info ? user.investment_info : {};

    let giftCode = { redeemCode: null, value: null };

    const basicProfileFields = [
      'first_name',
      'last_name',
      'phone_number',
      'date_of_birth',
      'address',
      'city',
      'state',
      'ssn',
      'agreed_with_DriveWealth_TOS',
    ];

    const basicProfileCompleted = basicProfileFields.reduce((completed, field) => {
      return completed ? Boolean(user[field]) : false;
    }, true);

    // TODO add employment validation probably for old users
    const investmentDetailsCompleted =
      typeof accredited_status === 'number' &&
      typeof publicly_traded_company_tickers === 'string' &&
      typeof sec_or_finra_organization_name === 'string';

    // Use previously applied gift code if any:
    if (giftCodeApplied) {
      if (!giftCodeApplied.assetTicker || giftCodeApplied.assetTicker === activeAsset.ticker) {
        giftCode = giftCodeApplied;
      }
    }

    const fundingSourceExists = !isEmpty(bankFundingSource.info);
    const agreed_with_DriveWealth_TOS =
      user.customer?.accounts?.[0]?.status !== ACCOUNT_STATUS.ENROLLMENT_PENDING ?? false;
    const { riskToleranceLevel } = user.customer?.investorDetails ?? {};
    const hasValidRiskProfile =
      riskToleranceLevel && riskToleranceLevel !== RiskToleranceLevel.Conservative;
    const { liquidNetWorth, pretaxAnnualIncome } = user.customer?.personalInformation ?? {};
    const validIncome = liquidNetWorth >= MIN_INCOME || pretaxAnnualIncome >= MIN_INCOME;

    const shouldForcePassport =
      !basicProfileCompleted ||
      !agreed_with_DriveWealth_TOS ||
      !investmentDetailsCompleted ||
      !fundingSourceExists ||
      !hasValidRiskProfile ||
      !validIncome;

    const initialFormData = createPassportData({
      user,
      investingPassportApplied: !shouldForcePassport,
      investmentDetailsCompleted,
      basicProfileCompleted,
      sec_or_finra_organization_name,
      publicly_traded_company_tickers,
      accredited_status,
      balance,
      activeAsset,
      userShares,
      giftCode,
    });

    return initialFormData;
  };

  handleFormClose = (param = undefined) => {
    const { closeActionOrigin } = param || {};
    // Log cancelation
    analytics.track('Buy Shares Canceled/Closed', {
      action: SEGMENT_ACTIONS.CLOSE,
      category: SEGMENT_CATEGORIES.BUY_SHARES,
      assetTicker: this.props.activeAsset.ticker,
      step: this.steps[this.state.formStepIndex],
    });

    // if checkout available depending on api response
    if (!isEmpty(this.props.activeAssetMerchandisingOptions)) {
      /* following logic below is for the current single-product upsell offering at the end of IO flow.
       * @todo: below two 'merchant' and 'upsellProductInfo' aren't needed when design is ready for rendering multiple product items */
      const merchant = this.props.activeAssetMerchandisingOptions[0];
      const upsellProductInfo = merchant.products[0];

      // right now this only works as an accumulator as we don't know what our multi-product checkout ui would be like.
      const availableProductsCount = this.props.activeAssetMerchandisingOptions.reduce(
        (allMerchantsData, merchant) => {
          const availableProducts = merchant.products.filter(product => {
            return (
              (product.inventory.enabled && product.inventory.total > 0) ||
              !product.inventory.enabled
            );
          });

          return allMerchantsData + availableProducts.length;
        },
        0,
      );

      availableProductsCount > 0 &&
        closeActionOrigin === 'IO_PURCHASED' &&
        this.props.onCheckoutAvailable({
          asset: this.props.activeAsset,
          upsellProductInfo: upsellProductInfo,
        });
    }

    const { activeAssetCategory: category } = this.props;
    const ticker = this.props.activeAsset.ticker.replace('#', '');
    this.props.history.push(`/app/assets/${category.pathname}/${ticker}`);
  };

  /**
   * Updates form data in component's state
   * This is async operation! Use callback if needed
   * @param  {Object}   updates  - updates object
   * @param  {Function} callback - callback function (optional)
   */
  handleFormUpdate = (updates, callback) => {
    this.setState(
      state => ({
        ...state,
        ...updates,
      }),
      () => callback && callback(),
    );
  };

  handleDwollaRetry = () => {
    this.props.history.replace('/app/make-investment/name-phone-info');
    this.setState({ renderDwollaStatusPopup: false });
  };

  trackAnalyticsViewEvent(stepIndex) {
    if (!isEmpty(this.props.activeAsset) && !isEmpty(this.steps[stepIndex])) {
      const screenName = this.steps[stepIndex].name;
      // track activity
      analytics.track(screenName, {
        category: SEGMENT_CATEGORIES.BUY_SHARES,
        action: SEGMENT_ACTIONS.OPEN,
        assetTicker: this.props.activeAsset.ticker,
      });
    }
  }

  onStepChange = ({ nextStepIndex }) => {
    this.trackAnalyticsViewEvent(nextStepIndex);
    this.setState({ formStepIndex: nextStepIndex });
  };

  render() {
    const {
      giftCode,
      formStepIndex,
      cost,
      isAssociated,
      isShareholder,
      fundingSourceCreated,
      isSmallDepositNeeded,
      investingPassportApplied,
      employmentStatus,
      agreedWithTos3,
    } = this.state;

    const { activeAsset, user, bankFundingSource, saPopup } = this.props;
    const isOnFreeShareFlow = saPopup && !isEmpty(saPopup.asset);

    const shouldShowGiftbanner = giftCode.value && !(formStepIndex > 17);
    const isNotEnoughCash = Number(cost) - Number(this.props.balance.cash || 0) > 0;
    const fundingSourceExists = !isEmpty(bankFundingSource.info) || fundingSourceCreated;
    const shouldSkipTransfer = (!isNotEnoughCash && !fundingSourceCreated) || isOnFreeShareFlow;
    const shouldSkipConfirmation =
      (!isSmallDepositNeeded || !fundingSourceCreated) && fundingSourceExists;
    const shouldSkipBankVerification =
      bankFundingSource?.info?.status !== FUNDING_SOURCE_STATUS.UNVERIFIED;

    const shouldSkipEmployerInfo = STATUSES_TO_SKIP_EMPLOYER.includes(employmentStatus);

    const isSA = this.props.location.pathname.includes('/subscription-agreement');

    const passportStepsArray = PassportSteps({
      investingPassportApplied: investingPassportApplied || false,
      shouldSkipEmployerInfo,
      isAssociated,
      isShareholder,
      fundingSourceExists,
      shouldSkipConfirmation,
      shouldSkipBankVerification,
    });

    this.steps = [
      {
        path: '/dwolla-tos',
        exact: true,
        render: Tos3Screen,
        name: 'Buy Shares Introduction',
        needToSkip: !investingPassportApplied || agreedWithTos3,
      },
      {
        path: '/',
        exact: true,
        render: StartedInfo,
        name: 'Buy Shares Introduction',
      },
      {
        path: '/select-shares',
        render: SharesSelect,
        name: 'Buy Shares Amount Input',
      },
      ...passportStepsArray,
      {
        path: '/investing-passport',
        render: InvestingPassport,
        name: 'Buy Shares Investing Passport',
      },
      {
        path: '/not-enough-cash',
        render: NotEnoughCash,
        name: 'Buy Shares Insufficient Funds',
        needToSkip: shouldSkipTransfer,
      },
      {
        path: activeAsset.docusign_template_id ? '/docusign' : '/subscription-agreement',
        render: activeAsset.docusign_template_id ? Docusign : SubscriptionAgreement,
        name: `Buy Shares${activeAsset.docusign_template_id ? ' Docusign' : ''}`,
      },
      {
        path: '/success',
        render: Success,
        name: 'Buy Shares Confirmation',
      },
      {
        path: '/congrats',
        render: Congrats,
        name: 'Buy Shares Congrats',
      },
    ];

    let initialPassportIndex = 0;
    this.steps.forEach((step, index) => {
      if (step?.path === passportStepsArray?.[0]?.path) {
        initialPassportIndex = index;
      }
    });

    let content = (
      <div className={`MakeInvestment-wrapper${isSA ? ' MakeInvestment-wrapper--100' : ''}`}>
        <MultistepForm
          rootUrl="/app/make-investment"
          steps={this.steps}
          initialPassportIndex={initialPassportIndex}
          section={PASSPORT_SECTION.IO}
          onFormClose={this.handleFormClose}
          onFormUpdate={this.handleFormUpdate}
          onStepChange={this.onStepChange}
          data={{ ...this.state, cash: this.props.balance.cash }}
          resetOnBackPress
        />
        {this.state.renderDwollaStatusPopup && (
          <DwollaStatusPopup
            status={this.props.dwollaCustomerStatus}
            onClick={this.handleFormClose}
            onRetry={this.handleDwollaRetry}
          />
        )}
      </div>
    );

    if (user.state && user.state_status.is_state_blocked) {
      content = <StateAvailabilityError onClose={this.handleFormClose} />;
    }

    if (user.state && !user.state_status.is_state_blocked && !user.state_status.io_enabled) {
      content = <InvestmentRestrictedPopup show fullWidth onRequestClose={this.handleFormClose} />;
    }

    return (
      <div className={`MakeInvestment ${this.props.applicationMode}`}>
        <div
          className="MakeInvestment-gift-banner"
          style={{ display: shouldShowGiftbanner ? 'flex' : 'none' }}
        >
          <span>${this.state.giftCode.value}</span>
          <img src={giftImage} alt="" />
        </div>
        <CloseButton onClickHandler={this.handleFormClose} />
        {content}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  applicationMode: state.UI.applicationMode,
  activeAsset: state.Assets.activeAsset,
  activeAssetCategory: state.Assets.activeAssetCategory,
  activeAssetMerchandisingOptions: state.Assets.activeAssetMerchandisingOptions,
  balance: state.Investments.balance,
  bankFundingSource: state.Investments.bankFundingSource,
  dwollaCustomerStatus: state.Auth.dwollaCustomerStatus,
  giftCodeApplied: state.Investments.giftCodeApplied,
  total_shares: state.Investments.total_shares,
  user: state.Auth.user,
  saPopup: state.UI.saPopup,
});

const mapDispatchToProps = {
  getBankFundingSource,
  getBalance,
  getInvestments,
  lockActiveAsset,
  unlockActiveAsset,
  getDwollaCustomerStatus,
  startInvestments,
};

export default connect(mapStateToProps, mapDispatchToProps)(MakeInvestment);
