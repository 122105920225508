import styled from '@emotion/styled';

export const AssetsHeaderContainer = styled.div(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.md]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.colors.lightBorder}`,
    borderTop: `0.7vh solid ${theme.colors.darkGrey}`,
    boxSizing: 'border-box',
  },
}));

export const HeaderTitle = styled.div(({ theme }) => ({
  margin: `0 2vh`,
  fontSize: '1.8vh',
  letterSpacing: '0.2vh',
  lineHeight: '5vh',
  fontWeight: 600,
  color: theme.colors.lightText,
  textTransform: 'uppercase',
}));

export const AssetsSearchContainer = styled.div(({ theme }) => ({
  padding: '0 1vh',
  borderLeft: `1px solid ${theme.colors.lightBorder}`,
}));
