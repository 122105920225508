import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'components/shared/Popup';
import { SecondaryButton } from 'components/shared/Buttons/SecondaryButton';

import './TradingClosedPopup.scss';

const TradingClosedPopup = ({ show, onConfirm }) => {
  return (
    <Popup show={show} disableAutoClose>
      <h2 className="TradingClosedPopup-header">
        Could not place your order: <br />
        the trading window is now closed
      </h2>
      <SecondaryButton text="Okay" onClick={onConfirm} />
    </Popup>
  );
};

TradingClosedPopup.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
};

TradingClosedPopup.defaultProps = { show: false };

export default TradingClosedPopup;
