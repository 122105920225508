import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Redirect } from 'react-router-dom';
import { Switch } from 'react-router';

import history from 'services/history';
import Mainpage from 'components/views/app/Mainpage';
import LoginSignupScreen from 'components/views/public/LoginSignupScreen/LoginSignupScreen';
import NewPassword from 'components/views/public/NewPassword/NewPassword';
import EmailConfirmation from 'components/views/public/EmailConfirmation/EmailConfirmation';
import Preloader from 'components/shared/Preloader';
import FullscreenLoader from 'components/shared/Loaders/FullscreenLoader';
import DocusignCallback from 'components/views/public/DocusignCallback/DocusignCallback';
import { ModalViewProvider } from 'components/shared/Modals/Modal';

import { SUPPORTED_APPLICATION_MODES } from 'constants/main';
import { ASSET_CATEGORIES } from 'constants/main';

const REDIRECTS = {
  '/cash': 'https://mailchi.mp/rs/greenwichcc',
  '/autoplicity': 'https://mailchi.mp/1bfbc3ffa79e/autoplicity',
  '/huseyinerturk': 'https://app.adjust.com/ix1ra73',
  '/dash': 'https://mailchi.mp/2584b509f34d/dash',
  '/download': 'https://o17c.app.link/Axe5195MtN',
  '/media': 'http://rallyrd.vip/media',
  '/mdj': 'https://app.adjust.com/1kcxdvi?fallback=http%3A%2F%2Frallyrd.com%2Fapp',
};

export const goToAssetItem = (asset, location) => {
  const { category, ticker } = asset;
  const targetAssetCategory = Object.values(ASSET_CATEGORIES).find(({ key }) => key === category);

  return {
    pathname: `/app/assets/${targetAssetCategory?.pathname}/${ticker.slice(1)}`,
    from: location?.pathname,
    assetTicker: ticker,
  };
};

const RootRouter = ({ applicationMode, globalLoading, globalFullscreenLoader, ...props }) => {
  const [routes] = useState([
    {
      pathname: ['/signup', '/login', '/password-reset'],
      component: LoginSignupScreen,
      excludeModes: [SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS],
      exact: true,
      authenticationPage: true,
    },
    { pathname: '/new-password', component: NewPassword, authenticationPage: true },
    { pathname: '/email/confirm', component: EmailConfirmation, authenticationPage: true },
    { pathname: '/dcsigncllbck', component: DocusignCallback },
    { pathname: '/app', component: Mainpage },
  ]);

  useEffect(() => {
    if (window && REDIRECTS[window.location.pathname]) {
      window.location.href = REDIRECTS[window.location.pathname];
      return true;
    }
  }, []);

  const getComponentByPathNameAndApplicationMode = pathname => {
    switch (pathname) {
      case '/signup':
        if (applicationMode === SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS)
          return <Redirect to="/login" />;
      // "eslint-disable-next-line no-fallthrough" <- this is intentional as a failsafe
      default:
        return <LoginSignupScreen />;
    }
  };

  return (
    <Router history={history}>
      <ModalViewProvider>
        <Preloader isLoaded={!globalLoading} />
        <FullscreenLoader visible={globalFullscreenLoader} />
        <Switch>
          {routes.map(route => {
            return (
              <Route
                exact={route.exact}
                path={route.pathname}
                key={route.pathname}
                render={props =>
                  route.excludeModes && route.excludeModes.includes(applicationMode) ? (
                    getComponentByPathNameAndApplicationMode(route.pathname)
                  ) : (
                    <route.component {...props} />
                  )
                }
              />
            );
          })}
          <Route exact path="/" render={() => <Redirect to="/app" />} />
        </Switch>
      </ModalViewProvider>
    </Router>
  );
};

const mapStateToProps = state => ({
  applicationMode: state.UI.applicationMode,
  globalLoading: state.UI.globalLoading,
  globalFullscreenLoader: state.UI.globalFullscreenLoader,
});

export default connect(mapStateToProps)(RootRouter);
