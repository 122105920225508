import React, { useRef } from 'react';
import { ToggleSearchInputContainer, SearchButton, SearchInput, CloseButton } from './styled';
import SearchIcon from './SearchIcon';
import { SimpleComponent } from 'types/components';
import { onChangeEvent } from 'types/events';

type ToggleSearchInputProps = {
  onOpen: () => void;
  onClose: () => void;
  onChange: (param: onChangeEvent) => void;
  value: string;
  opened: boolean;
};

const ToggleSearchInput: SimpleComponent<ToggleSearchInputProps> = ({
  onOpen,
  onClose,
  opened,
  value,
  onChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOpen = () => {
    inputRef.current?.focus();
    onOpen();
  };

  return (
    <ToggleSearchInputContainer active={opened}>
      <SearchButton active={opened} type="button" disabled={opened} onClick={handleOpen}>
        <SearchIcon />
      </SearchButton>
      <SearchInput
        ref={inputRef}
        value={value}
        onChange={onChange}
        active={opened}
        placeholder="Search..."
        type="text"
        inputMode="search"
      />
      <CloseButton type="button" active={opened} onClick={onClose} />
    </ToggleSearchInputContainer>
  );
};

export default ToggleSearchInput;
