import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SecondaryButton } from 'components/shared/Buttons/SecondaryButton';
import { TextButton } from 'components/shared/Buttons/TextButton';

import { subscribeOnStateAvailable } from 'actions';

import './StateAvailabilityError.scss';

class StateAvailabilityError extends Component {
  static propTypes = { onClose: PropTypes.func.isRequired };

  constructor(props) {
    super(props);

    const { stateNotifications } = this.props;

    let subscribed = false;

    if (
      stateNotifications &&
      stateNotifications.length &&
      stateNotifications.find(notification => notification.blocked_state === this.props.state)
    ) {
      subscribed = true;
    }

    this.state = { subscribed };
  }

  state = { subscribed: false };

  onNotify = () => {
    this.props.subscribeOnStateAvailable(this.props.state);
    this.setState({ subscribed: true });
  };

  renderDefaultControls = () => (
    <div>
      <SecondaryButton onClick={this.onNotify}>Notify Me!</SecondaryButton>
      <br />
      <TextButton className="StateAvailabilityError-cancel" onClick={this.props.onClose}>
        Cancel
      </TextButton>
    </div>
  );

  renderSuccessControls = () => (
    <div>
      <p className="StateAvailabilityError-success">
        You will be notified when your state becomes available!
      </p>
      <SecondaryButton onClick={this.props.onClose}>Ok</SecondaryButton>
    </div>
  );

  render() {
    return (
      <div className="StateAvailabilityError">
        <div style={{ fontSize: '5vh' }}>
          <span role="img" aria-label="Pensive Face">
            😔
          </span>
        </div>

        <h2>State Availability Issue...</h2>

        <div className="StateAvailabilityError-desc">
          Investing on Rally is not yet available in your state. You can still explore, learn and
          chat. We will notify you as soon as investing is available in your state. Investing is
          currently available in 32 states and we are working quickly to support the entire U.S.
        </div>

        {this.state.subscribed ? this.renderSuccessControls() : this.renderDefaultControls()}
      </div>
    );
  }
}

const mapStateToProps = ({ Auth }) => ({
  stateNotifications: Auth.user.state_availability_notification,
  state: Auth.user.state,
});

const mapDispatchToProps = { subscribeOnStateAvailable };

export default connect(mapStateToProps, mapDispatchToProps)(StateAvailabilityError);
