import React, { useCallback, useEffect, useState } from 'react';
import {
  CardPriceVariation,
  CarouselCardContainer,
  CarouselCardImage,
  CarouselContainer,
  HomeHeading,
  ViewCalendarButton,
  ViewCalendarImage,
} from './styled';
import 'glider-js/glider.min.css';
import { Asset, Calendar, Carousel, CarouselType } from 'services/HomeAPI.types';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import * as AssetsAPI from 'services/AssetsAPI';
import { getSecondaryMarketEnableDiff } from 'utils';
import { ASSET_STATUS } from 'constants/main';
import { SecondaryMarketStatus } from 'types/assets';
import { goToAssetItem } from 'components/RootRouter';
import { setActiveCalendarTab } from 'actions/ui';
import { track } from 'services/analytics';
import { SEGMENT_EVENTS, SEGMENT_ACTIONS, SEGMENT_CATEGORIES } from 'constants/analytics';
import Glider, { GliderMethods } from 'react-glider';
import { useDrag } from '@use-gesture/react';
import { useTracking } from 'hooks/useTracking';
const CarouselCard = ({
  position,
  assetId,
  assets,
  isTrading,
  ...props
}: {
  position: number;
  assetId: string;
  assets: Asset[];
  isTrading: boolean;
}) => {
  const { homeTracking } = useTracking();

  const [assetPricing, setAssetPricing] = useState<{
    showPrices: boolean;
    priceShift: number;
    priceShiftPercentage: number;
  } | null>(null);

  const storeAssets = useSelector<RootState, any>((store) => store.Assets);

  const updatePriceShift = useCallback(async () => {
    const activeAsset = storeAssets?.assetList?.find(
      (_asset: { id: string }) => _asset.id === assetId,
    );

    const assetPricing = (await AssetsAPI.getSecurityPricing(
      activeAsset?.financialInstrument?.id,
    )) as any;

    const { secondaryMarket } = activeAsset.financialInstrument?.markets ?? {};

    const timestampDiff = getSecondaryMarketEnableDiff(secondaryMarket);
    // @ts-ignore
    // prettier-ignore
    const showPrices = (activeAsset.asset_status === ASSET_STATUS.POST_ONLY && timestampDiff <= 0) ||
        activeAsset.asset_status === ASSET_STATUS.TRADING_CLOSED ||
        (activeAsset.asset_status === ASSET_STATUS.TRADING_OPENED &&
            secondaryMarket.status !== SecondaryMarketStatus.OPEN);

    const pricePerUnit = assetPricing.data.pricePerUnit;
    let price;
    let priceShift = 0;
    let priceShiftPercentage = 0;
    price =
      (pricePerUnit?.current || pricePerUnit?.session?.lastClose) * activeAsset.number_of_shares;
    if (!price) {
      setAssetPricing({
        showPrices,
        priceShift,
        priceShiftPercentage,
      });
      return;
    }
    priceShift = price - activeAsset.asset_value;
    priceShiftPercentage =
      (priceShift / (pricePerUnit?.issue * activeAsset.number_of_shares)) * 100;
    setAssetPricing({
      showPrices,
      priceShift,
      priceShiftPercentage,
    });
    return;
  }, [assetId, storeAssets]);

  useEffect(() => {
    (async () => updatePriceShift())();
  }, [assetId, updatePriceShift]);

  const [hasDrag, setHasDrag] = useState(false);

  const bind = useDrag((state) => setHasDrag(state.active));

  const currentAsset = assets?.find((asset) => asset.id === assetId);

  const storeAsset = storeAssets?.assetList?.find(
    (_asset: { id: string }) => _asset.id === currentAsset?.id,
  ) as any;

  const handleTracking = async () => {
    if (isTrading) {
      return await homeTracking.homeTradingCarouselAssetTapped(assetId, currentAsset, position);
    } else {
      return await homeTracking.homeOfferingCarouselAssetTapped(assetId, currentAsset, position);
    }
  };

  return (
    <CarouselCardContainer
      {...bind()}
      to={goToAssetItem(currentAsset, { pathname: '/app/home' })}
      hasDrag={hasDrag}
      onClick={() => handleTracking()}
    >
      <div>
        <h1>{currentAsset?.shortName}</h1>
        <div className={'price-variation'}>
          <p className={'price'}>
            ${Number(currentAsset?.currentPricePerShare).toFixed(2)}
            {storeAsset?.asset_status === 'Trading' ? '' : isTrading ? '' : '/share'}{' '}
          </p>
          <CardPriceVariation sign={Math.sign(assetPricing?.priceShift || 1)} show={isTrading}>
            ({Math.sign(assetPricing?.priceShift || 0) >= 0 ? '+' : ''}
            {Number(assetPricing?.priceShiftPercentage).toFixed(2)}%)
          </CardPriceVariation>
        </div>
      </div>
      <CarouselCardImage
        backgroundImage={storeAsset?.portal_image || currentAsset?.media[0]?.thumbnail}
      />
    </CarouselCardContainer>
  );
};

const HomeCarousel = React.memo(function HomeCarousel({
  data,
  enableCalendar,
  assets,
  ...props
}: {
  data: Carousel;
  enableCalendar: Calendar['enabled'];
  assets: Asset[];
}) {
  const { enabled, items, type } = data;
  const dispatch = useDispatch();

  const setHeading = () => {
    switch (type) {
      case CarouselType.ASSET_OFFERING:
        return 'Offerings';

      case CarouselType.ASSET_TRADING:
        return 'Popular Trading Assets';

      default:
        return '';
    }
  };

  const showCalendarButton = () => {
    switch (type) {
      case CarouselType.ASSET_OFFERING:
        return true;

      case CarouselType.ASSET_TRADING:
        return enableCalendar;

      default:
        return false;
    }
  };

  const handleCalendarClick = () => {
    const isOffering = CarouselType.ASSET_OFFERING === type;
    track(
      isOffering ? SEGMENT_EVENTS.HOME_VIEW_ALL_OFFERINGS_TAPPED : SEGMENT_EVENTS.HOME_CALENDAR_TAP,
      {
        action: SEGMENT_ACTIONS.OPEN,
        category: SEGMENT_CATEGORIES.INVESTMENT_CALENDAR,
      },
    );
    dispatch(setActiveCalendarTab(isOffering ? 1 : 0));
  };

  const ViewButton = () => {
    const isOffering = CarouselType.ASSET_OFFERING === type;
    return (
      <ViewCalendarButton
        to="/app/investments-calendar"
        onClick={handleCalendarClick}
        show={showCalendarButton()}
      >
        {isOffering ? 'View All' : 'View Calendar'}
        <ViewCalendarImage />
      </ViewCalendarButton>
    );
  };
  const gliderRef = React.useRef<GliderMethods>(null);
  return enabled ? (
    <CarouselContainer>
      <div className={'carousel-heading-container'}>
        <HomeHeading>{setHeading()}</HomeHeading>
        <ViewButton />
      </div>
      <Glider
        slidesToShow={items.length}
        ref={gliderRef}
        draggable={true}
        easing={function (x, t, b, c, d) {
          return c * (t /= d) * t + b;
        }}
      >
        {items.map(({ assetId }, index) => {
          return (
            <CarouselCard
              position={index + 1}
              assetId={assetId}
              assets={assets}
              isTrading={CarouselType.ASSET_TRADING === type}
            />
          );
        })}
      </Glider>
    </CarouselContainer>
  ) : null;
});

export default HomeCarousel;
