import React, { Component } from 'react';
import moment from 'moment';

import { TextButton } from 'components/shared/Buttons/TextButton';
import AssetDetailsPage from '../Common/AssetDetailsPage';
import TickerTitle from '../Common/TickerTitle';
import Circle from './Circle';

import './AssetTimeline.css';

class AssetTimeline extends Component {
  scrollableContainerRef = React.createRef(null);

  /**
   * Handles the scrolling of the timeline items container.
   * Stops the propagation of scroll event to the parents, thus allowing the user to scroll freely
   * without triggering the swipe/slide in 2.0 carousel component.
   */
  handleScroll = e => {
    if (this.scrollableContainerRef.current && this.scrollableContainerRef.current.scrollTop > 0) {
      e.stopPropagation();
    }
  };

  render() {
    const { activeAsset: car } = this.props;
    return (
      <div id="AssetTimeline" className={`AssetTimeline ${this.props.applicationMode}`}>
        <AssetDetailsPage
          asset={car}
          className="AssetDetailsPage-assetTimeline"
          last={true}
          {...this.props}
        >
          <span className="AssetDetailsPage-nextslide previous">
            <TextButton onClick={this.props.previousSlide}>&or;</TextButton>
          </span>

          <TickerTitle ticker={car.ticker} color={car.tertiary_color} title="Timeline" />
          <div className="AssetTimeline-timeline">
            <div className="AssetTimeline-topfog" />
            <div
              className="AssetTimeline-timeline-container"
              ref={this.scrollableContainerRef}
              onWheel={this.handleScroll}
              onScroll={this.handleScroll}
            >
              {car.timeline_items &&
                car.timeline_items.map((item, index) => (
                  <div key={index} className="AssetTimeline-timeline-item">
                    <div className="AssetTimeline-timeline-item-date">
                      {moment(item.date).format('MMM. YYYY')}
                    </div>
                    <Circle radius={1.4} borderSize={0.6} scale="vh" borderColor="#545a5c" />
                    <div
                      className="AssetTimeline-timeline-item-text"
                      style={index < car.timeline_items.length - 1 ? {} : { border: 'none' }}
                    >
                      {item.text}
                    </div>
                  </div>
                ))}
            </div>
            <div className="AssetTimeline-bottomfog" />
          </div>
        </AssetDetailsPage>
      </div>
    );
  }
}

export default AssetTimeline;
