import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { Price } from 'components/shared/Price';
import { SimpleComponent } from 'types/components';
import { SecondaryMarketStatus } from 'types/assets'
import { setActiveTradingWindow } from 'actions';
import HowItWorksPopup from './HowItWorksPopup';
import { useHasChanged } from 'hooks';

import { OrderBookWrapper } from './styled';
import { DotsLoader } from 'components/shared/Loaders';
import { formatAssetPrice } from 'utils';
import { getTimeInTimezone } from 'utils/main'

type OrderBookProps = {
  history: Record<string, any>;
};

const OrderBook: SimpleComponent<OrderBookProps> = props => {
  const [ showPopup, setShowPopup ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const [ pricing, setPricing ] = useState<Record<string, any> | undefined>();
  const { activeAsset, activeAssetCategory } = useSelector((store: any) => store.Assets);
  const isLiveTrading = activeAsset.financialInstrument.markets.secondaryMarket.status === SecondaryMarketStatus.OPEN;
  const { history } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    async function getOrderBookAndPricing() {
      await dispatch(setActiveTradingWindow(activeAsset.financialInstrument.id, true));
      setLoading(false)
    }
    getOrderBookAndPricing();
  }, [activeAsset.financialInstrument.id, dispatch])

  const handleHowItWorks = () => {
    setShowPopup(true);
  };

  const handleClose = () => {
    const ticker = activeAsset.ticker.replace('#', '');
    history.push(`/app/assets/${activeAssetCategory.pathname}/${ticker}`);
  };

  const renderBidAskList = (type: string) => {
    const arr = activeAsset.trading?.orderBook || [];

    const isBid = type === 'bid';

    const sortedTypedArr = arr.filter((a: Record<string, any>) => a.taxonomy?.context === type.toUpperCase())
      .sort((a: Record<string, any>, b: Record<string, any>) => isBid ? b.pricing?.unit - a?.pricing?.unit : a.pricing?.unit - b?.pricing?.unit);

    return sortedTypedArr.map((a: Record<string, any>, i: number) => {
      const leftContent = a.taxonomy?.context === 'BID' ? a.units : <strong>{Number(a.pricing?.unit).toFixed(2)}</strong>;
      const rightContent = a.taxonomy?.context === 'BID' ? <strong>{Number(a.pricing?.unit).toFixed(2)}</strong> : a.units;

      const bidAskAssetValue = a.pricing?.unit * activeAsset.number_of_shares;
      const bidAskFormattedValue = formatAssetPrice(bidAskAssetValue);
      const label = (
        <span className="label">{`$${bidAskFormattedValue} (${
          type === 'bid' ? 'High Bid' : 'Low Ask'
        })`}</span>
      );

      return (
        <div className={`item ${type}`} key={i}>
          <div className="itemTitle">
            {!isBid && i === 0 && label}
            {leftContent} <span className="times">x</span> {rightContent}
            {isBid && i === 0 && label}
          </div>
        </div>
      );
    });
  };

  const getNextOpen = () => {
    const { secondaryMarket } = activeAsset?.financialInstrument?.markets || {};
    const formatted = secondaryMarket?.sessionHours?.dateOpen ? moment(secondaryMarket.sessionHours.dateOpen).format('MM/DD') : false;
    const formattedTime = getTimeInTimezone(secondaryMarket.sessionHours.dateOpen, true);
    return formatted ? `OPENS ${formatted}, ${formattedTime.time}${formattedTime.ampm} ${formattedTime.timezone}` : null;
  };

  const recalculatePricing = useCallback(() => {
    if (!activeAsset?.trading) return;
    
    const { 
      trading: {
        pricing: {
          pricePerUnit = {}
        } = {}
      } = {}
     } = activeAsset;

    const lastTradeClosePrice = pricePerUnit?.current || (activeAsset?.asset_value / activeAsset?.number_of_shares); // IO price

    const priceShiftPercentage = Number(
      isLiveTrading
      ? (lastTradeClosePrice - (pricePerUnit?.session?.lastClose || pricePerUnit?.issue)) / (pricePerUnit?.session?.lastClose || pricePerUnit?.issue)
      : (lastTradeClosePrice - pricePerUnit?.issue) / pricePerUnit?.issue
    ) * 100;

    const currentAssetValue = 
      pricePerUnit?.current * activeAsset.number_of_shares ||
      pricePerUnit?.session?.lastClose * activeAsset.number_of_shares ||
      activeAsset.value;

    setPricing({
      lastTradeClosePrice,
      priceShiftPercentage,
      currentAssetValue
    })
  }, [activeAsset, isLiveTrading])

  const activeAssetChanged = useHasChanged(activeAsset);
  
  useEffect(() => {
    if (activeAssetChanged) {
      recalculatePricing()
    }
  }, [activeAssetChanged, recalculatePricing])

  return (
    <OrderBookWrapper isLiveTrading={isLiveTrading} priceShiftPercentage={pricing?.priceShiftPercentage}>
      <div className="header">
        <div className="left">
          {isLiveTrading ? 'All Bids & Asks' : getNextOpen()}
        </div>
        <div className="right">
          {activeAsset.ticker}
        </div>
      </div>
      {loading ? (
        <div className="loader">
          <DotsLoader color={isLiveTrading ? '#9BA0A5' : 'white'} />
        </div>
      ) : (
        <>
          <div className="bidAskContainer reverse">
            <div className="bidAskWrapper">
              {renderBidAskList('ask')}
            </div>
          </div>
          <div className="lastPrice">
            <div className="title"><span>{isLiveTrading ? 'Last Trade' : 'Last Close'}</span></div>
            <Price price={pricing?.lastTradeClosePrice} keepZeros />
          </div>
          <div className="info">
            <span>( <Price price={pricing?.currentAssetValue} /> | <strong>{pricing?.priceShiftPercentage < 0 ? '' : '+'}{pricing?.priceShiftPercentage.toFixed(2)}%</strong> <span className="when">{isLiveTrading ? 'Today' : 'All Time'}</span> )</span>
          </div>
          <div className="bidAskContainer">
            <div className="bidAskWrapper">
              {renderBidAskList('bid')}
            </div>
          </div>
        </>
      )}
      <div className="footer">
        <button className="button cta" onClick={handleHowItWorks}><strong>How It Works</strong></button>
        <button className="button" onClick={handleClose}>Close Window</button>
      </div>

      <HowItWorksPopup show={showPopup} history={history} handleClose={() => setShowPopup(false)} />
    </OrderBookWrapper>
  );
};

export default OrderBook;