import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';

import { logoutUser } from 'actions';
import './Logout.scss';

class Logout extends Component {
  componentDidMount() {
    this.props.logoutUser();
  }

  render() {
    return <Redirect to="/login" />;
  }
}

const mapDispatchToProps = { logoutUser };

export default connect(null, mapDispatchToProps)(Logout);
