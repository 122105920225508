import React from 'react';
import { Link } from 'react-router-dom';
import { Popup } from './Popup';
import { Button } from './Buttons/Button';

import stoplightsImage from 'images/stoplights.png';

const DwollaConfirmationNeeded = ({ onClick }) => (
  <Popup show disableAutoClose fullWidth>
    <div>
      <h1>
        <img src={stoplightsImage} alt="stoplights" className="StartedInfo-img" />
        <br />
        Confirmation Needed
      </h1>
    </div>
    <div>
      <p>
        There appears to have been an issue with your verification <br />
        One or more fields may been entered incorrectly. Please confirm all information and retry
      </p>
      <p>
        <small>
          You can read more about our &nbsp;
          <Link target="_blank" to="/faq">
            privacy policy here
          </Link>
          , and can learn about &nbsp;
          <Link target="_blank" to="/faq">
            platform security here
          </Link>
          .
        </small>
      </p>
    </div>
    <div>
      <Button onClick={onClick}>
        <b>OKAY!</b>
      </Button>
    </div>
  </Popup>
);

export default DwollaConfirmationNeeded;
