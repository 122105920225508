import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/shared/Buttons/Button';
import './SharePurchaseInfo.css';
import CHECKERED_FLAG from 'images/checkered-flag.png';

class SharePurchaseInfo extends Component {
  static propTypes = {
    onStepForward: PropTypes.func.isRequired,
  };

  static defaultProps = {
    formMode: 'investment',
  };

  render() {
    const { formMode } = this.props;
    const title = formMode === 'investment' ? 'Share Purchase' : 'Fund Your Account';
    const body =
      formMode === 'investment' ? (
        <span>
          {' '}
          to Rally Once linked, you can transfer additional funds to your Rally balance for future
          share purchases.
        </span>
      ) : (
        <span>
          {' '}
          and transfer funds into your Rally account. You can withdraw or transfer additional funds
          anytime.
        </span>
      );

    return (
      <div className="SharePurchaseInfo">
        <img src={CHECKERED_FLAG} alt="checkered-flag" className="checkered-flag-img" />
        <h2>{title}</h2>
        <p>
          In order to make your first investment, you'll need to link your bank account
          {body}
        </p>
        <Button text={<b>OKAY!</b>} arrow="forward" onClick={() => this.props.onStepForward()} />
      </div>
    );
  }
}

export default SharePurchaseInfo;
