import React from 'react';
import ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';

export const FullscreenLoader = ({ visible }) => {
  return visible
    ? ReactDOM.createPortal(
        <FullscreenLoaderOverlay>
          <LoadingSpinner viewBox="0 0 50 50">
            <circle
              className="path"
              fill="none"
              strokeWidth="5"
              strokeLinecap="round"
              cx="25"
              cy="25"
              r="20"
            ></circle>
          </LoadingSpinner>
        </FullscreenLoaderOverlay>,
        document.querySelector('#fullscreenLoader-root'),
      )
    : null;
};

const FullscreenLoaderOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.15);
`;

const spinnerRotate = keyframes`{
  0% {
    transform: rotate(0deg);
  }
  100% {
    ransform: rotate(360deg);
  }
}`;

const dash = keyframes`{
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
    transform: rotate(360deg);
  }
}`;

const LoadingSpinner = styled.svg`
  width: 50px;
  height: 50px;
  animation: ${spinnerRotate} 1500ms linear infinite;

  .path {
    stroke: #000;
    transform-origin: center;
    animation: ${dash} 1500ms ease-in-out infinite;
  }
`;

export default FullscreenLoader;
