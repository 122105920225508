import styled from '@emotion/styled';

export const OnboardingATSCardsWrapper = styled.div(
  () => ({
    '.Fullpage-contents': {
      width: '100%',
      height: '100%'
    }
  }),
);
