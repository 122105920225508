export enum PrevalidateErrorCode {
  CROSS_TRADING_PROHIBITED = 'CROSS_TRADING_PROHIBITED',
  FINANCIAL_ACCOUNT_RESTRICTED = 'FINANCIAL_ACCOUNT_RESTRICTED',
  FINANCIAL_INSTRUMENT_RESTRICTED = 'FINANCIAL_INSTRUMENT_RESTRICTED',
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  INSUFFICIENT_UNITS = 'INSUFFICIENT_UNITS',
  INALIENABLE_UNITS = 'INALIENABLE_UNITS',
  UNIT_PRICE_OUT_OF_BOUNDS = 'UNIT_PRICE_OUT_OF_BOUNDS',
}
// some day in the future if we want to type the responses
export type PrevalidateResponse = {
  code?: PrevalidateErrorCode;
  context: Record<string, any>;
  valid: boolean;
};
