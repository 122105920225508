import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Bugsnag from '@bugsnag/js';

import { BackNextButtons } from 'components/shared/Buttons';
import { CurrencyInput } from 'components/shared/Inputs';
import { ValidationWarning } from 'components/shared/ValidationWarning';
import { patchPersonalInformation } from 'services/CustomerApi';
import { useAlert, useGeneralErrorAlert } from 'hooks';
import { setPersonalInformation } from 'actions/customer';
import { isEmpty } from 'utils';

import './EmploymentDetails.scss';
import { MIN_INCOME } from 'constants/main';

const EmploymentDetails = ({ onStepForward, onStepBackward, data, onFormUpdate }) => {
  const { pretaxAnnualIncome, liquidNetWorth } = data;
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const id = useSelector(store => store.Auth.user.customer.id);
  const dispatch = useDispatch();
  const showAlert = useAlert();
  const showErrorAlert = useGeneralErrorAlert();

  const handleOnChange = useCallback(
    (value, name) => {
      onFormUpdate({ [name]: value });
    },
    [onFormUpdate],
  );

  const isQualified = useMemo(
    () => Number(pretaxAnnualIncome) >= MIN_INCOME || Number(liquidNetWorth) >= MIN_INCOME,
    [pretaxAnnualIncome, liquidNetWorth],
  );

  const handleNextStep = async () => {
    try {
      setLoading(true);

      const validationErrors = {};
      if (pretaxAnnualIncome === '') {
        validationErrors.pretaxAnnualIncome = `Annual income is required`;
      }
      if (liquidNetWorth === '') {
        validationErrors.liquidNetWorth = `Individual net worth is required`;
      }

      if (isEmpty(validationErrors) && !isQualified) {
        showAlert(
          'Risk Profile Alert',
          <span>
            Trading on Rally involves a degree of risk which may not be suitable for investors with
            less than <strong>$10,000</strong> in annual income or individual net worth
          </span>,
        );
        return;
      }

      const personalInformation = { liquidNetWorth, pretaxAnnualIncome };
      await patchPersonalInformation(personalInformation, id);
      dispatch(setPersonalInformation(personalInformation));

      if (isEmpty(validationErrors)) {
        onStepForward();
        return;
      }
      setErrors(validationErrors);
    } catch (err) {
      Bugsnag.notify(err);
      showErrorAlert();
    } finally {
      setLoading(false);
    }
  };

  const isStepDisabled = useMemo(() => isEmpty(pretaxAnnualIncome) || isEmpty(liquidNetWorth), [
    pretaxAnnualIncome,
    liquidNetWorth,
  ]);

  return (
    <div className="employment-details">
      <h3 className="employment-details__title">Employment Details</h3>
      <div className="employment-details__input">
        <CurrencyInput
          description="Annual income"
          placeholder="$100,000"
          allowZero
          value={pretaxAnnualIncome}
          valid={!errors.pretaxAnnualIncome}
          onChange={({ inputValue }) => handleOnChange(inputValue, 'pretaxAnnualIncome')}
        />
        <ValidationWarning valid={!errors.pretaxAnnualIncome} message={errors.pretaxAnnualIncome} />
      </div>
      <div className="employment-details__input">
        <CurrencyInput
          description="Individual net worth - value of all assets including investments but not including equity in primary residence, minus any debts"
          placeholder="$250,000"
          value={liquidNetWorth}
          allowZero
          valid={!errors.liquidNetWorth}
          onChange={({ inputValue }) => handleOnChange(inputValue, 'liquidNetWorth')}
        />
        <ValidationWarning valid={!errors.liquidNetWorth} message={errors.liquidNetWorth} />
      </div>
      <BackNextButtons
        buttons={{ back: { text: 'back' }, next: { text: 'next' } }}
        nextDisabled={isStepDisabled}
        onBackClick={onStepBackward}
        loading={loading}
        onNextClick={handleNextStep}
      />
    </div>
  );
};

export default EmploymentDetails;
