import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './SecondaryButton.scss';

/**
 * Button component
 * @param  {String}    arrow:  'back'|'left'|'previous' or 'forward'|'right'|'next'
 * @param  {Object}    style:  styles object
 * @param  {String}    className:  additional css classes
 * @param  {Node}      children:  button content
 * @param  {String}    text:  text content (same as 'children' prop)
 */
export const SecondaryButton = props => {
  const { arrow, style, className, children, text, buttonRef, ...restProps } = props;

  const buttonClasses = classnames('SecondaryButton', {
    'SecondaryButton-left-arrow': arrow === 'back' || arrow === 'left' || arrow === 'previous',
    'SecondaryButton-right-arrow': arrow === 'forward' || arrow === 'right' || arrow === 'next',
    [className]: className,
  });

  return (
    <button className={buttonClasses} style={style} ref={buttonRef} {...restProps}>
      {children || text}
    </button>
  );
};

SecondaryButton.propTypes = {
  arrow: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
};

SecondaryButton.defaultProps = {
  text: 'OKAY',
};

export default SecondaryButton;
