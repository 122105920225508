import React, { FC } from 'react';
import { AnimatedItem } from './styled';

type AssetAnimatedListItemProps = {
  index: number;
};

const AssetAnimatedListItem: FC<AssetAnimatedListItemProps> = ({ children, index }) => {
  return <AnimatedItem index={index}>{children}</AnimatedItem>;
};

export default AssetAnimatedListItem;
