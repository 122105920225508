import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateUserData, updateInvestmentFlow } from 'actions';
import { YesNoSelect } from 'components/shared/Buttons/YesNoSelect';
import { BackNextButtons } from 'components/shared/Buttons/BackNextButtons';

class IsShareholder extends Component {
  static propTypes = {
    data: PropTypes.object,
    onFormUpdate: PropTypes.func.isRequired,
    onStepForward: PropTypes.func.isRequired,
    onStepBackward: PropTypes.func.isRequired,
    updateUserData: PropTypes.func.isRequired,
    updateInvestmentFlow: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { isStepValid: this.props.data.isShareholder !== undefined, isLoading: false };
    this.initialData = this.props.data;
  }

  validateStep = () => {
    const isStepValid = this.props.data.isShareholder !== undefined;
    this.setState({ isStepValid });
  };

  onChoiceChange = value => {
    const isShareholder = value === 'Yes';
    const updates = { isShareholder };

    // Remove company tickers if user is not shareholder
    if (!isShareholder) {
      updates.publicly_traded_company_tickers = '';
    }
    this.props.onFormUpdate(updates, this.validateStep);
  };

  handleNextClick = () => {
    const { isShareholder } = this.props.data;
    if (!isShareholder) {
      const {
        investmentFlow: { investment_info },
      } = this.props;
      this.setState({ isLoading: true });
      this.props
        .updateUserData({
          investment_info: { ...investment_info, publicly_traded_company_tickers: '' },
        })
        .then(() => {
          this.setState({ isLoading: false });
          this.props.updateInvestmentFlow({
            investment_info: { publicly_traded_company_tickers: '' },
          });
          this.props.onStepForward();
        })
        .catch(() => this.setState({ isLoading: false }));
    } else {
      this.props.onStepForward();
    }
  };

  handleBackClick = () => {
    if (this.props.resetOnBackPress) {
      const { isShareholder } = this.initialData;
      this.props.onFormUpdate({ isShareholder });
    }
    this.props.onStepBackward();
  };

  render() {
    const {
      data: { isShareholder },
    } = this.props;

    return (
      <div>
        <div>
          <p>
            Are you or anyone in your household or immediate family a 10% shareholder, officer, or
            member of the board of directors of a publicly traded company?
          </p>
          <YesNoSelect
            name="isShareholder"
            onChange={this.onChoiceChange}
            wanted="Yes"
            wantedSelected={isShareholder}
            showColors={false}
          />
        </div>
        <BackNextButtons
          buttons={{ back: { text: 'back' }, next: { text: 'next' } }}
          nextDisabled={!this.state.isStepValid}
          loading={this.state.isLoading}
          onBackClick={this.handleBackClick}
          onNextClick={this.handleNextClick}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  investmentFlow: state.Investments.investmentFlow,
});

const mapDispatchToProps = { updateUserData, updateInvestmentFlow };

export default connect(mapStateToProps, mapDispatchToProps)(IsShareholder);
