import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './NumberInput.scss';

const INCOMPLETE_NUMBER_REGEX = /^([+-]|\.0*|[+-]\.0*|[+-]?\d+\.)?$/;

class NumberInput extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // input value
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // initial value
    step: PropTypes.number, // increase/decrease interval
    min: PropTypes.number, // min value
    max: PropTypes.number, // max value
    precision: PropTypes.number, // precision (number of decimals), use 0 for integers only
    format: PropTypes.func, // function for formatting the number to display representation
    prefix: PropTypes.oneOfType([PropTypes.node, PropTypes.string]), // input prefix
    autoFocus: PropTypes.bool, // auto focus for input element
    readOnly: PropTypes.bool,
    placeholder: PropTypes.string, // input placeholder
    hideButtons: PropTypes.bool,
    // Styling
    className: PropTypes.string, // additional css class for wrapper element
    inputClassName: PropTypes.string, // additional css class for input
    btnClassName: PropTypes.string, // additional css class for buttons
    style: PropTypes.object, // wrapper styles
    // Callbacks
    onChange: PropTypes.func,
    onInput: PropTypes.func,
    onKeyDown: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
  };

  static defaultProps = {
    step: 1,
    min: Number.MIN_SAFE_INTEGER || -9007199254740991,
    max: Number.MAX_SAFE_INTEGER || 9007199254740991,
    precision: null,
    style: {},
  };

  // When click and hold on a button - the delay before initial changing the value
  static DELAY = 500;
  // When click and hold on a button - the speed of auto changing the value.
  static SPEED = 50;

  inputElement = {};
  buttonUpElement = {};
  buttonDownElement = {};
  isInitialized = false;
  timer = null;

  constructor(props) {
    super(props);

    this.state = {
      selectionStart: null,
      selectionEnd: null,
      stringValue: '',
    };
  }

  componentDidMount() {
    this.isInitialized = true;
    const nextState = this.mapPropsToState(this.props);
    if (Object.keys(nextState).length) this.setState(nextState);
  }

  componentWillUnmount() {
    this.isInitialized = false;
    this.stopTimer();
  }

  /**
   * If the number is valid, invoke onChange callback with formatted value.
   * Update cursor position.
   */
  componentDidUpdate(prevProps, prevState) {
    const isValueChanged = prevState.stringValue !== this.state.stringValue;
    const { value, precision, formatOnReceivingValue } = this.props;

    if (prevProps.value === undefined && prevProps.value !== value && formatOnReceivingValue) {
      const formattedValue = Number(value).toFixed(precision);
      this.onChange({
        target: { value: formattedValue },
        nativeEvent: {},
      });
    }

    if (isValueChanged && !isNaN(this.state.stringValue)) {
      this.invokeEventCallback('onChange', this.state.stringValue);
    }
    if (this.state.selectionStart || this.state.selectionStart === 0) {
      this.inputElement.selectionStart = this.state.selectionStart;
    }
    if (this.state.selectionEnd || this.state.selectionEnd === 0) {
      this.inputElement.selectionEnd = this.state.selectionEnd;
    }
  }

  componentWillReceiveProps(nextProps) {
    const nextState = this.mapPropsToState(nextProps);
    if (Object.keys(nextState).length) this.setState(nextState);
  }

  mapPropsToState = props => {
    let state = {};
    if (props.value && String(props.value) !== this.state.stringValue) {
      // simulate 'change' event to trigger validation and formatting of the incoming value:
      this.onChange({
        target: { value: props.value },
        nativeEvent: { data: null },
      });
    } else if (!this.isInitialized && props.defaultValue) {
      state.stringValue = String(props.defaultValue).trim();
    }

    return state;
  };

  /**
   * Parses the value to it's numeric representation.
   * If precision props was specified, rounds the number with that precision
   */
  toNumber = value => {
    const { precision, max, min } = this.props;
    const parsedValue = parseFloat(value);
    let n = isNaN(parsedValue) || !isFinite(parsedValue) ? 0 : parsedValue;

    const q = Math.pow(10, precision === null ? 10 : precision);
    n = Math.min(Math.max(n, min), max);
    n = Math.round(n * q) / q;

    return n;
  };

  /**
   * Format a number to display representation. Uses this.props.format func if one is provided.
   * @return {String}
   */
  formatNumber = value => {
    const { precision } = this.props;
    let number = this.toNumber(value);

    if (precision !== null) {
      number = number.toFixed(precision);
    }
    if (this.props.format) {
      return this.props.format(number);
    }
    return number + '';
  };

  /**
   * Auto-changes the value after button clicks/presses
   * @param  {String}  direction 'up' or 'down' (increase or decrease the value)
   * @param  {Boolean} isLoop Recursive call using setTimeout
   */
  autoStep = (direction, isLoop = false) => {
    this.stopTimer();
    this.step(direction);
    this.timer = setTimeout(
      () => this.autoStep(direction, true),
      isLoop ? NumberInput.SPEED : NumberInput.DELAY,
    );
  };

  /**
   * Sets new value on the input after clicking on up and down keys/buttons
   * @param  {String} direction 'up' or 'down' (increases or decreases the value)
   */
  step = direction => {
    let stepValue = 0;
    if (direction === 'up') stepValue = this.props.step;
    else if (direction === 'down') stepValue = -this.props.step;

    const currentValue = this.toNumber(this.state.stringValue || 0);
    let nextValue = this.toNumber(currentValue + stepValue);
    nextValue = this.formatNumber(nextValue);

    this.setState({ stringValue: nextValue });
  };

  /**
   * Stop auto-changing after button press
   */
  stopTimer = () => this.timer && clearTimeout(this.timer);

  /**
   * Invoke callbacks ('onChange', 'onInput', etc.') if they are specified in the props
   * @param {String} callbackName The name of the callback function
   * @param {Array} args Callback arguments
   */
  invokeEventCallback = (callbackName, ...args) => {
    if (typeof this.props[callbackName] === 'function') {
      this.props[callbackName].call(null, ...args);
    }
  };

  /**
   * Saves current input selection (cursor position) to restore it later
   */
  saveSelection = () => {
    this.setState({
      selectionStart: this.inputElement.selectionStart,
      selectionEnd: this.inputElement.selectionEnd,
    });
  };

  onChange = e => {
    const { precision, min, max } = this.props;
    const value = e.target.value;
    const data = e.nativeEvent.data;

    let newValue = null;

    const isIncompleteNumber = INCOMPLETE_NUMBER_REGEX.test(value);
    const isPrecisionDefined = precision > 0 || precision === NumberInput.defaultProps.precision;

    // If the value is empty, leave it as is
    if (value === '') {
      newValue = value;
      // Leave 'minus' if the user allows negative numbers
    } else if (value === '-' || data === '-') {
      if (!(max < 0 || min < 0)) return;
      newValue = value;
      // newValue = max < 0 || min < 0 ? value : '';
      // If number is incomplete and the user allows decimals, use the string as is
    } else if (isIncompleteNumber && isPrecisionDefined) {
      newValue = value;
      // If the value is not a number, try to format it
    } else if (isNaN(value)) {
      newValue = this.formatNumber(value);
      // If the value is valid and the user allows decimals, parse it as a float number
    } else if (isPrecisionDefined) {
      const parsed = this.toNumber(value);
      newValue = parsed === parseFloat(value) ? value : parsed;
      newValue = this.props.format ? this.props.format(newValue) : newValue;
      // Otherwise, format it as usual
    } else {
      // does not make sense call toNumber if the precision is not defined
      // as it uses precision to calculate the values
      newValue = this.toNumber(value);
      newValue = this.props.format ? this.props.format(newValue) : newValue;
    }

    this.setState({ stringValue: String(newValue) });
  };

  /**
   * Key down listeners for input element
   */
  onKeyDown = e => {
    const { value, selectionEnd } = this.inputElement;
    this.invokeEventCallback('onKeyDown', e);
    if (e.isDefaultPrevented()) return;

    // Up Key
    if (e.keyCode === 38) {
      e.preventDefault();
      this.step('up');
    }
    // Down key
    else if (e.keyCode === 40) {
      e.preventDefault();
      this.step('down');
    }

    if (e.key === '.' && value.charAt(selectionEnd) === '.') {
      e.preventDefault();
      this.ignoreValueChange = true;
      this.inputElement.selectionStart = selectionEnd + 1;
      this.inputElement.selectionEnd = selectionEnd + 1;
    }
  };

  onInput = e => {
    // this.saveSelection(); NOT WORKING IN SAFARI
    this.invokeEventCallback('onInput', e);
  };

  onFocus = e => this.invokeEventCallback('onFocus', e);

  onBlur = e => {
    if (this.props.min !== NumberInput.defaultProps.min || this.props.precision > 0) {
      this.setState({ stringValue: this.formatNumber(e.target.value) });
    }
    this.invokeEventCallback('onBlur', e);
  };

  onMouseDown = e => {
    if (e.target.contains(this.buttonUpElement)) this.autoStep('up');
    else if (e.target.contains(this.buttonDownElement)) this.autoStep('down');
  };

  onTouchStart = e => {
    if (e.target.contains(this.buttonUpElement)) this.autoStep('up');
    else if (e.target.contains(this.buttonDownElement)) this.autoStep('down');
  };

  onMouseUp = () => this.stopTimer();

  onMouseLeave = () => this.stopTimer();

  onTouchEnd = e => {
    e.preventDefault(); // <- prevent onMouseDown: https://github.com/facebook/react/issues/9809
    this.stopTimer();
  };

  render() {
    const {
      className,
      inputClassName,
      btnClassName,
      prefix,
      style,
      placeholder,
      hideButtons,
      disabled,
    } = this.props;

    const wrapperClass = classnames('NumberInput', {
      [className]: className,
      hiddenButtons: hideButtons,
    });
    const inputClass = classnames('NumberInput-input', { [inputClassName]: inputClassName });
    const btnClass = classnames('NumberInput-btn', { [btnClassName]: btnClassName });

    return (
      <span className={wrapperClass} style={style}>
        {prefix && <span className="NumberInput-prefix">{prefix}</span>}
        <input
          className={inputClass}
          type="text"
          disabled={disabled}
          value={this.state.stringValue}
          ref={element => (this.inputElement = element)}
          placeholder={placeholder}
          onChange={this.onChange}
          onInput={this.onInput}
          onKeyDown={this.onKeyDown}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          autoFocus={this.props.autoFocus}
          readOnly={this.props.readOnly}
        />
        {!hideButtons && (
          <>
            <button
              className={btnClass}
              ref={element => (this.buttonUpElement = element)}
              onMouseDown={this.onMouseDown}
              onMouseUp={this.onMouseUp}
              onMouseLeave={this.onMouseLeave}
              onTouchStart={this.onTouchStart}
              onTouchEnd={this.onTouchEnd}
              style={{ touchAction: 'none' }}
            >
              <i>+</i>
            </button>
            <button
              className={btnClass}
              ref={element => (this.buttonDownElement = element)}
              onMouseDown={this.onMouseDown}
              onMouseUp={this.onMouseUp}
              onMouseLeave={this.onMouseLeave}
              onTouchStart={this.onTouchStart}
              onTouchEnd={this.onTouchEnd}
            >
              <i>-</i>
            </button>
          </>
        )}
      </span>
    );
  }
}

export default NumberInput;
