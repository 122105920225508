import { handleActions } from 'redux-actions';
import { groupBy, isEmpty } from 'utils';
import moment from 'moment';

import {
  ASSET_CATEGORIES,
  ASSET_SEARCH_MIN_CHARS,
  SPECIAL_ACCESS_TYPE_BLACKLISTED_ASSET_STATUS_MAP,
  SUPPORTED_APPLICATION_MODES,
} from 'constants/main';
import {
  SET_ASSETS,
  SET_ASSET_DETAILS,
  TOGGLE_ASSETS_DETAILS,
  SET_TRADING_DATES,
  SET_ACTIVE_ASSET,
  SET_ACTIVE_ASSET_CATEGORY,
  SET_ACTIVE_ASSET_PRICING,
  SET_ACTIVE_ASSET_HISTORY_PRICING,
  LOCK_ACTIVE_ASSET,
  UNLOCK_ACTIVE_ASSET,
  UPDATE_ASSETS_UPDATE_SCHEDULE,
  UPDATE_ACTIVEASSET_MERCHANDISING_OPTIONS,
  SET_ASSETS_SEARCH_VALUE,
  OPEN_ASSETS_SEARCH,
  CLOSE_ASSETS_SEARCH,
  SET_SEARCH_RESULT_LIST,
  SET_ASSET_SEARCH_SELECTED,
  SET_ORDER_BOOK,
  SET_HOME_ASSETS,
  SET_CATEGORY_ASSETS,
} from 'actions/types';

const defaultState = {
  assetList: [],
  /**
   * Arrays of assets mapped by category
   * @type {{[assetCategoryKey: string]: [Object]}}
   */
  assetCategoryLists: {
    [ASSET_CATEGORIES.COMICS_LITERATURE.key]: [],
    [ASSET_CATEGORIES.CARS.key]: [],
    [ASSET_CATEGORIES.HISTORY.key]: [],
    [ASSET_CATEGORIES.LUXURY.key]: [],
    [ASSET_CATEGORIES.WINE_SPIRITS.key]: [],
  },
  /**
   * Currently selected asset category
   * @type { key: string; title: string; pathname: string; }
   */
  activeAssetCategory: {},
  activeAsset: {},
  activeAssetMerchandisingOptions: [],
  detailsOpened: false,
  /**
   * A map of asset trading dates
   * @type {{[assetID: string]: [Object]}}
   */
  tradingDates: {},
  /**
   * Prevents selecting another asset from asset list when set to "true"
   * @type {Boolean}
   */
  isLocked: false,
  /**
   * Timestamp used for next /assets/status/ call
   */
  assetsUpdateScheduleNextTimestamp: null,
  /**
   * Seconds interval used to set the interval of recurring /assets/status/ call
   */
  assetsUpdateScheduleSecondsInterval: null,
  assetsSearchValue: '',
  isAssetsSearchActive: false,
  shouldShowAssetList: false,
  searchResultList: [],
  isSearchAssetSelected: false,
  homeAssets: {},
};

const reducerMap = {};

/**
 * Populates the asset list with sorted assets data by "order" or "ticker" props.
 */
reducerMap[SET_ASSETS] = (state, action) => {
  let activeAssetCategory = state.activeAssetCategory;
  let assets = action.payload.assets;
  const applicationMode = action.payload.applicationMode;
  const isAssetDetails = action.payload.isAssetDetails;
  // for special access mode we should limit accessible assets to only those that authenticated user has some kind of
  //  special access to.
  if (
    assets.length &&
    !isAssetDetails &&
    applicationMode === SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS
  ) {
    const specialAccessAssetIdAccessTypeLimitationsMap =
      action.payload.specialAccessAssetIdAccessTypeLimitationsMap;

    if (!specialAccessAssetIdAccessTypeLimitationsMap) {
      assets = [];
    } else {
      assets = assets.filter(asset => {
        if (specialAccessAssetIdAccessTypeLimitationsMap.has(asset.id)) {
          const assetAccessTypeAndLimitations = specialAccessAssetIdAccessTypeLimitationsMap.get(
            asset.id,
          );
          const accessType = assetAccessTypeAndLimitations.accessType;
          const blacklistedAssetStatuses =
            SPECIAL_ACCESS_TYPE_BLACKLISTED_ASSET_STATUS_MAP[accessType] || [];

          return (
            assetAccessTypeAndLimitations.accessEnds > Date.now() &&
            !blacklistedAssetStatuses.includes(asset.status)
          );
        }
        return false;
      });
      // TODO: if we move to showing more than one asset in special access mode the below will likely need to change.
      // default the active asset category to the first asset returned
      if (assets.length) {
        activeAssetCategory = Object.values(ASSET_CATEGORIES).find(
          ({ key }) => key === assets[0].category,
        );
      }
    }
  }

  // Sort assets by order or ticker:
  const assetList = assets.sort((a, b) => {
    return a.order !== b.order ? a.order - b.order : a.ticker > b.ticker;
  });
  const assetCategoriesKeys = Object.values(ASSET_CATEGORIES).map(({ key }) => key);

  const assetCategoryLists = assetList.reduce((accumulator, asset) => {
    if (!asset.category || !assetCategoriesKeys.includes(asset.category)) return accumulator;

    const assetToUpdate = state.assetCategoryLists[asset.category].find(
      currentAsset => currentAsset.id === asset.id,
    );

    const updatedAsset = assetToUpdate
      ? {
          ...assetToUpdate,
          ...asset,
        }
      : asset;

    if (!accumulator[asset.category]) return { ...accumulator, [asset.category]: [updatedAsset] };

    return {
      ...accumulator,
      [asset.category]: [...accumulator[asset.category], updatedAsset],
    };
  }, defaultState.assetCategoryLists);

  return {
    ...state,
    activeAssetCategory,
    assetList,
    assetCategoryLists,
  };
};

/**
 * Updates active asset with newly fetched data if necessary
 */
reducerMap[SET_ASSET_DETAILS] = (state, action) => {
  let activeAsset = state.activeAsset.id === action.payload.id ? action.payload : state.activeAsset;
  return {
    ...state,
    activeAsset: {
      ...state.activeAsset,
      ...activeAsset,
      trading: {
        ...state.activeAsset.trading,
        historyPricing: state?.activeAsset?.trading?.historyPricing ?? {},
      },
    },
  };
};

/**
 * Track asset details status globally
 */
reducerMap[TOGGLE_ASSETS_DETAILS] = (state, action) => {
  return {
    ...state,
    detailsOpened: action.payload,
  };
};

reducerMap[SET_ACTIVE_ASSET] = (state, action) => {
  const { activeAsset, isLocked } = state;
  if (isLocked) {
    return state;
  }
  if (isEmpty(activeAsset) || activeAsset.id !== action.payload.id) {
    return {
      ...state,
      activeAsset: action.payload,
    };
  }
  // if is the same asset we update and not overwrite
  return {
    ...state,
    activeAsset: {
      ...activeAsset,
      ...action.payload,
    },
  };
};

reducerMap[SET_ACTIVE_ASSET_CATEGORY] = (state, action) => {
  return {
    ...state,
    activeAssetCategory: action.payload,
    activeAsset: {},
  };
};

reducerMap[SET_ACTIVE_ASSET_PRICING] = (state, action) => {
  if (action.payload.financialInstrumentId !== state.activeAsset?.financialInstrument?.id) {
    return state;
  }
  return {
    ...state,
    activeAsset: {
      ...state.activeAsset,
      trading: {
        ...state.activeAsset.trading,
        pricing: action.payload,
      },
    },
  };
};

reducerMap[SET_ACTIVE_ASSET_HISTORY_PRICING] = (state, action) => {
  return {
    ...state,
    activeAsset: {
      ...state.activeAsset,
      trading: {
        ...state.activeAsset.trading,
        historyPricing: action.payload.dataPoints,
      },
    },
  };
};

reducerMap[SET_HOME_ASSETS] = (state, action) => {
  return {
    ...state,
    homeAssets: action.payload,
  };
};

reducerMap[SET_ORDER_BOOK] = (state, action) => {
  return {
    ...state,
    activeAsset: {
      ...state.activeAsset,
      trading: {
        ...state.activeAsset.trading,
        orderBook: action.payload,
      },
    },
  };
};

/**
 * Set upsell products data of the active asset
 */
reducerMap[UPDATE_ACTIVEASSET_MERCHANDISING_OPTIONS] = (state, action) => {
  return {
    ...state,
    activeAssetMerchandisingOptions: action.payload,
  };
};

/**
 * Groups assets trading dates by asset id.
 * Sort trading dates (early dates go first) and filers out the past dates
 */
reducerMap[SET_TRADING_DATES] = (state, action) => {
  let datesGroupedByAsset = groupBy(action.payload, 'asset');
  Object.keys(datesGroupedByAsset).forEach(assetId => {
    datesGroupedByAsset[assetId] = datesGroupedByAsset[assetId]
      .sort((a, b) => new Date(a.trading_open_date) - new Date(b.trading_open_date))
      .filter(date => moment(date.trading_open_date).startOf('day') - moment().startOf('day') >= 0);
  });
  return {
    ...state,
    tradingDates: datesGroupedByAsset,
  };
};

reducerMap[LOCK_ACTIVE_ASSET] = (state, action) => {
  return {
    ...state,
    isLocked: true,
  };
};

reducerMap[UNLOCK_ACTIVE_ASSET] = (state, action) => {
  return {
    ...state,
    isLocked: false,
  };
};

reducerMap[UPDATE_ASSETS_UPDATE_SCHEDULE] = (state, action) => {
  return {
    ...state,
    assetsUpdateScheduleNextTimestamp: action.payload.next_update_timestamp,
    assetsUpdateScheduleSecondsInterval: action.payload.update_seconds_interval,
  };
};

reducerMap[SET_ASSETS_SEARCH_VALUE] = (state, action) => {
  let shouldShowAssetList = state.shouldShowAssetList;
  if (action.payload.length >= ASSET_SEARCH_MIN_CHARS) {
    shouldShowAssetList = true;
  }
  if (action.payload.length === 0) {
    shouldShowAssetList = false;
  }
  return {
    ...state,
    assetsSearchValue: action.payload,
    shouldShowAssetList,
  };
};

reducerMap[OPEN_ASSETS_SEARCH] = state => ({
  ...state,
  isAssetsSearchActive: true,
});

reducerMap[CLOSE_ASSETS_SEARCH] = state => ({
  ...state,
  isAssetsSearchActive: false,
});

reducerMap[SET_SEARCH_RESULT_LIST] = (state, action) => ({
  ...state,
  searchResultList: action.payload,
});

reducerMap[SET_ASSET_SEARCH_SELECTED] = (state, action) => ({
  ...state,
  isSearchAssetSelected: action.payload,
});

reducerMap[SET_CATEGORY_ASSETS] = (state, action) => {
  const { assets, category } = action.payload;
  return {
    ...state,
    assetCategoryLists: {
      ...state.assetCategoryLists,
      [category]: assets,
    },
  };
};

const assets = handleActions(reducerMap, defaultState);

export default assets;
