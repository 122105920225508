import { createActions } from 'redux-actions';
import * as TradingAPI from 'services/TradingAPI';
import * as AssetsAPI from 'services/AssetsAPI';
import {
  UPDATE_ACTIVE_TRADING_WINDOW,
  UNSET_ACTIVE_TRADING_WINDOW,
  SET_ORDERS,
  SET_ORDER_AS_PENDING_CANCEL,
  SET_NONOPEN_TRADING_WINDOW_ORDERS,
  SET_TRADING_WINDOW_LOADING,
  SET_TRADING_WINDOW_LOADED,
  SET_ORDER_BOOK,
} from 'actions/types';

import { getSecurityPricing } from 'actions';

// === Action creators: ===

export const {
  updateActiveTradingWindow,
  unsetActiveTradingWindow,
  setOrders,
  setOrderAsPendingCancel,
  setOrderBook,
} = createActions(
  UPDATE_ACTIVE_TRADING_WINDOW,
  UNSET_ACTIVE_TRADING_WINDOW,
  SET_ORDERS,
  SET_ORDER_AS_PENDING_CANCEL,
  SET_ORDER_BOOK,
);

// === Thunks: ===

/**
 * Updates redux state, fetches fresh data for provided trading window
 * @param  {Object} tradingWindow
 * @return {Promise}
 */
export const setActiveTradingWindow =
  (finInstId, withLoader = true) =>
  async dispatch => {
    // if (!tradingWindow) return;

    if (withLoader) dispatch({ type: SET_TRADING_WINDOW_LOADING });
    try {
      await dispatch(getSecurityPricing(finInstId));
      // order book
      const { data } = await TradingAPI.getOrderBook(finInstId);
      dispatch(setOrderBook(data));
    } catch (error) {
      return error;
    } finally {
      if (withLoader) dispatch({ type: SET_TRADING_WINDOW_LOADED });
    }
  };

export const refreshActiveTradingWindow = finInstId => dispatch => {
  return dispatch(setActiveTradingWindow(finInstId));
};

export const getOrders = () => async (dispatch, getState) => {
  const accountId = await getState().Auth.user.customer?.accounts?.[0]?.id;

  return TradingAPI.getOrders(accountId).then(
    orders => dispatch(setOrders(orders)),
    err => console.log('orders fetching error', err),
  );
};

export const deleteOrder = orderId => async (dispatch, getState) => {
  const accountId = await getState().Auth.user.customer?.accounts?.[0]?.id;
  const orders = await getState().Trading.userOrders;

  return AssetsAPI.deleteOrder(orderId, accountId).then(
    () => dispatch(setOrderAsPendingCancel({ orders, orderId })),
    err => console.log('delete order error', err),
  );
};

export const getNonOpenTradingWindowOrders = () => dispatch => {
  return TradingAPI.getOrders('~trading-window-status=OPEN').then(
    orders => dispatch({ type: SET_NONOPEN_TRADING_WINDOW_ORDERS, payload: orders }),
    err => {
      return err;
    },
  );
};
