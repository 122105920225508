import React, { Component } from 'react';
import { connect } from 'react-redux';

import ContentHeader from 'components/shared/ContentHeader';
import DisclaimerPage from 'components/shared/Pages/Disclaimer/DisclaimerPage';
import { setPageTitle } from 'utils';
import './Disclaimer.css';

class Disclaimer extends Component {
  componentDidMount() {
    setPageTitle('Disclaimer');
  }

  render() {
    return (
      <div className="Disclaimer">
        <ContentHeader>DISCLAIMER</ContentHeader>
        <div className="Disclaimer-body">
          <DisclaimerPage />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Disclaimer);
