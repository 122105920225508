import React, { Component } from 'react';
import { connect } from 'react-redux';

import SettingsBlock from './SettingsBlock';
import UserSetting from './UserSetting';
import Popup from 'components/shared/Popup';
import AdminButton from 'components/shared/Buttons/AdminButton';
import Error from 'components/shared/Error';
import * as UsersAPI from 'services/UsersAPI';

import './AccountSettings.css';

class AccountSettings extends Component {
  state = {
    expanded: true,
    showEmailUpdatePopup: false,
    newEmail: null,
    emailUpdateError: null,
  };

  onExpandCollapseClick = () => this.setState({ expanded: !this.state.expanded });

  handleEmailSave = async email => {
    if (email === this.state.newEmail) return;
    try {
      await UsersAPI.setUserEmail(email);
      this.setState({ showEmailUpdatePopup: true, newEmail: email });
    } catch (emailUpdateError) {
      this.setState({ showEmailUpdatePopup: true, emailUpdateError });
    }
  };

  handleEmailPopupClose = () =>
    this.setState({ showEmailUpdatePopup: false, emailUpdateError: null });

  render() {
    const { showEmailUpdatePopup, newEmail, emailUpdateError } = this.state;

    return (
      <div className="AccountSettings">
        <SettingsBlock
          title="ACCOUNT INFORMATION"
          expanded={this.state.expanded}
          onExpandCollapseClick={this.onExpandCollapseClick}
        >
          <UserSetting
            name="email"
            label="Email"
            type="email"
            value={this.props.user.email}
            onSave={this.handleEmailSave}
          />
          <div className="UserSetting UserPassword">
            <span className="UserPassword-display">
              <span className="UserPassword-display-dots">• • • • • • • • • • • •</span>
            </span>
            <AdminButton onClick={this.props.toggleChangePasswordPopup}>
              Change Password
            </AdminButton>
          </div>
        </SettingsBlock>
        <Popup
          className="AccountSettings-email-popup"
          show={showEmailUpdatePopup}
          button
          onClick={this.handleEmailPopupClose}
        >
          <h2>{emailUpdateError ? 'Sorry, an error occurred' : 'Please Verify Your Email'}</h2>
          {emailUpdateError ? (
            <Error error={emailUpdateError} />
          ) : (
            <p>
              In order to update your email address you must verify it,&nbsp;
              <span>{newEmail}</span>
            </p>
          )}
        </Popup>
      </div>
    );
  }
}

const mapStateToProps = state => ({ user: state.Auth.user });

export default connect(mapStateToProps)(AccountSettings);
