import React, { Component } from 'react';

import MultistepForm from 'components/shared/MultistepForm';
import {
  IntroMessage,
  Explore,
  Learn,
  Invest,
  Manage,
} from 'components/shared/MultistepForm/steps/tour';
import { Fullpage } from 'components/shared/Fullpage';
import { CloseButton } from 'components/shared/Buttons/CloseButton';
import { TextButton } from 'components/shared/Buttons/TextButton';

import analytics from 'services/analytics';

import './Tour.css';
import { SEGMENT_CATEGORIES, SEGMENT_ACTIONS } from 'constants/analytics';

class Tour extends Component {
  state = { signupOnboardingFlow: false };
  steps = [
    {
      path: '/',
      render: IntroMessage,
    },
    {
      path: '/explore',
      render: Explore,
      analyticsEventName: 'Introduction Tour (Explore) Screen',
    },
    {
      path: '/learn',
      render: Learn,
      analyticsEventName: 'Introduction Tour (Learn) Screen',
    },
    {
      path: '/invest',
      render: Invest,
      analyticsEventName: 'Introduction Tour (Invest) Screen',
    },
    {
      path: '/manage',
      render: Manage,
      analyticsEventName: 'Introduction Tour (Manage Buy + Sell) Screen',
    },
  ];

  componentDidMount() {
    localStorage.setItem('firstLaunch', true);

    if (this.props.location.state && this.props.location.state.signupOnboardingFlow) {
      this.setState({ signupOnboardingFlow: true });
    }
  }

  handleFormClose = () => {
    this.props.history.push({
      pathname: '/app',
      state: {
        signupOnboardingFlow: this.state.signupOnboardingFlow,
      },
    });
  };

  onSkipTour = () => {
    analytics.track('Introduction Tour Skip', {
      category: SEGMENT_CATEGORIES.TOUR,
      action: SEGMENT_ACTIONS.SKIP,
    });

    this.props.history.push({
      pathname: '/app/onboarding',
      state: {
        signupOnboardingFlow: this.state.signupOnboardingFlow,
      },
    });
  };

  onStepChange = ({ nextStepIndex: index }) => {
    const { analyticsEventName } = this.steps[index];
    if (analyticsEventName) {
      analytics.track(analyticsEventName, { category: 'Tour', action: 'Open' });
    }
  };

  render() {
    return (
      <Fullpage>
        <div className="Tour">
          <CloseButton onClickHandler={this.handleFormClose} />
          <div className="Tour-wrapper">
            <MultistepForm
              rootUrl="/app/tour"
              steps={this.steps}
              onStepChange={this.onStepChange}
              onFormClose={this.handleFormClose}
              data={this.state}
            />
            <TextButton className="Tour-skipbutton" onClick={this.onSkipTour}>
              Skip
            </TextButton>
          </div>
        </div>
      </Fullpage>
    );
  }
}

export default Tour;
