import { createActions } from 'redux-actions';

import {
  SHOW_GLOBAL_LOADER,
  HIDE_GLOBAL_LOADER,
  SET_FULLSCREEN_LOADER,
  SHOW_ASSETS_LOADING_OVERLAY,
  HIDE_ASSETS_LOADING_OVERLAY,
  SHOW_NAV_MENU_NOTIFICATION,
  HIDE_NAV_MENU_NOTIFICATION,
  TOGGLE_MENU,
  TOGGLE_ASSETS_MENU,
  SET_APPLICATION_MODE,
  TOGGLE_SAPOPUP,
  SET_SAPOPUP,
  SET_AUTO_GIFTCODE,
  CLEAR_SAPOPUP,
  SET_ACTIVE_CALENDAR_TAB,
  SET_TRADING_CALENDAR_ENABLED,
} from 'actions/types';

import { removeGiftCodeApplied } from 'actions';

import { ASSET_STATUS, SUPPORTED_APPLICATION_MODES, ASSET_CATEGORIES } from 'constants/main';
import Bugsnag from '@bugsnag/js';

// === Action creators: ===

export const {
  showGlobalLoader,
  hideGlobalLoader,
  showAssetsLoadingOverlay,
  hideAssetsLoadingOverlay,
  toggleMenu,
  setFullscreenLoader,
  setActiveCalendarTab,
  setTradingCalendarEnabled,
} = createActions(
  SHOW_GLOBAL_LOADER,
  HIDE_GLOBAL_LOADER,
  SHOW_ASSETS_LOADING_OVERLAY,
  HIDE_ASSETS_LOADING_OVERLAY,
  TOGGLE_MENU,
  SET_FULLSCREEN_LOADER,
  SET_ACTIVE_CALENDAR_TAB,
  SET_TRADING_CALENDAR_ENABLED,
);

// === Thunks: ===

const ioNotificationFadeTimers = [];

/**
 * Custom action creator to track previous state if the second argument is present, otherwise it will work as a toggle
 */
export const toggleAssetsMenu = (category, track) => {
  return {
    type: TOGGLE_ASSETS_MENU,
    payload: category,
    track,
  };
};

/**
 * Sets the holistic application mode.
 * @param {string} mode - a supported application mode. see `constants.main.SUPPORTED_APPLICATION_MODES` for available
 * options.
 */
export const setApplicationMode =
  (mode = '') =>
  dispatch => {
    if (mode && !Object.values(SUPPORTED_APPLICATION_MODES).includes(mode)) {
      throw new Error(`the specified mode: "${mode}" is not a supported application mode.`);
    }

    dispatch({ type: SET_APPLICATION_MODE, payload: mode });
  };

/**
 * Displays notification icon for specified navigation menu item.
 * @param {string} path - the path for nav item that should display the notification icon.
 * @param {boolean} autoFade - hides notification icon automatically over time.
 */
export const showNavMenuNotification =
  (path, autoFade = true) =>
  dispatch => {
    dispatch({ type: SHOW_NAV_MENU_NOTIFICATION, payload: path });
    if (autoFade) {
      const timer = setTimeout(() => dispatch(hideNavMenuNotification(path)), 6000);
      ioNotificationFadeTimers.push(timer);
    }
  };

/**
 * Hides notification icon for specified navigation menu item.
 * @param {string} path - the path for nav item that should hide the notification icon.
 */
export const hideNavMenuNotification = path => dispatch => {
  dispatch({ type: HIDE_NAV_MENU_NOTIFICATION, payload: path });
};

/**
 * Displays notifcation indicator for asset category in the nav menu when
 * some of the assets in the category has Open ICO status + asset is <100% funded.
 * Intended to happen when:
 * 1) user launches the app for the first time and one of the assets meets criterion above.
 * 2) user launches/re-launches the app and one of the assets has been updated
 * since the previous launch and now meets the criteria above.
 * @param {boolean} refreshNotifications - cancel existing notifications to emulate the #1 case.
 */
export const notifyOnIoOpenedCategories =
  (refreshNotifications = false) =>
  (dispatch, getState) => {
    if (refreshNotifications) {
      const currentNotifications = getState().UI.navMenuNotifications;
      ioNotificationFadeTimers.forEach(timer => clearTimeout(timer));
      currentNotifications.forEach(notification => dispatch(hideNavMenuNotification(notification)));
      localStorage.removeItem('ioOpenedAssets');
    }

    let categoriesToNotify = [];

    try {
      /**
       * Asset categories along with the asset id's that have IO-opened status.
       * @type {{[category: string]: [string]}}
       */
      const ioOpenedAssets = getState().Assets.assetList.reduce((map, asset) => {
        const { id, category, asset_status, progress_bar } = asset;
        if (asset_status === ASSET_STATUS.IO_OPENED && progress_bar < 100) {
          map[category] = map[category] ? [...map[category], id] : [id];
        }
        return map;
      }, {});

      const prevIoOpenedAssets = JSON.parse(localStorage.getItem('ioOpenedAssets'));

      // Check if the new initial offerings were started since the previous launch:
      if (prevIoOpenedAssets) {
        Object.keys(ioOpenedAssets).forEach(category => {
          if (!prevIoOpenedAssets[category]) return categoriesToNotify.push(category);

          const isNewIoOpened = ioOpenedAssets[category].reduce((isNewIo, asset) => {
            return isNewIo || !prevIoOpenedAssets[category].includes(asset);
          }, false);

          if (isNewIoOpened) categoriesToNotify.push(category);
        });
      } else {
        categoriesToNotify = Object.keys(ioOpenedAssets);
      }

      categoriesToNotify.forEach(category => {
        const pathname = Object.values(ASSET_CATEGORIES).find(
          ({ key }) => key === category,
        ).pathname;
        dispatch(showNavMenuNotification(`/app/assets/${pathname}`));
      });

      localStorage.setItem('ioOpenedAssets', JSON.stringify(ioOpenedAssets));
    } catch (err) {
      Bugsnag.notify(err);
      console.error('failed on showing IO category indicator', err);
    }
  };

export const toggleSaPopup = () => ({ type: TOGGLE_SAPOPUP });
export const setSaPopup = asset => ({ type: SET_SAPOPUP, payload: asset });

export const setAutoGiftCode = price => ({ type: SET_AUTO_GIFTCODE, payload: price });

export const clearSaPopup = () => dispatch => {
  dispatch({ type: CLEAR_SAPOPUP });
  dispatch(setSaPopup({}));
  dispatch(setAutoGiftCode(null));
  dispatch(removeGiftCodeApplied());
};
