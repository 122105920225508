import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import createSagaMiddleware from 'redux-saga';
import rootReducer from 'reducers/RootReducer';
import { actionAuthGuard } from './middleware';

export const sagaMiddleware = createSagaMiddleware();

const middlewares =
  process.env.NODE_ENV === 'production'
    ? applyMiddleware(thunk, actionAuthGuard, sagaMiddleware)
    : composeWithDevTools(applyMiddleware(thunk, actionAuthGuard, sagaMiddleware));

function configureStore() {
  return createStore(rootReducer, {}, middlewares);
}

export default configureStore;
