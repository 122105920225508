import React, { Component } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import DetailsCarousel from './DetailsCarousel';
import OneHundredPercentFundedPopup from './scenes/Common/OneHundredPercentFundedPopup';
import VerifyEmailPopup from 'components/shared/VerifyEmailPopup';
import TradingInfoPopup from 'components/views/app/Assets/Trading/Common/TradingInfoPopup';
import { BackButton } from 'components/shared/Buttons/BackButton';
import { CloseButton } from 'components/shared/Buttons/CloseButton';
import { SelectDots } from 'components/shared/SelectDots';

import MainPage from './scenes/MainPage/MainPage';
import AssetQuotes from './scenes/AssetQuotes/AssetQuotes';
import AssetStory from './scenes/AssetStory/AssetStory';
import AssetVisuals from './scenes/AssetVisuals/AssetVisuals';
import AssetTimeline from './scenes/AssetTimeline/AssetTimeline';

import BuySharesButton from './scenes/Common/BuySharesButton';
import NotifyMeButton from './scenes/Common/NotifyMeButton';
import BidAskButtons from 'components/views/app/Assets/Trading/Common/BidAskButtons';
import { TextButton } from 'components/shared/Buttons/TextButton';
import TradingWindowBanner from 'components/views/app/Assets/Trading/Common/TradingWindowBanner';
import CalendarIcon from 'components/shared/Icons/Calendar';

import { SUPPORTED_APPLICATION_MODES } from 'constants/main';

import { getUserData, removeActiveAssetMerchandiseProductData } from 'actions';
import analytics from 'services/analytics';
import withDeviceDetection from 'hoc/withDeviceDetection';

import { SEGMENT_ACTIONS, SEGMENT_CATEGORIES } from 'constants/analytics';
import { ASSET_STATUS } from 'constants/main';
import { getSecondaryMarketEnableDiff } from 'utils';

import './AssetDetails.css';
import { SecondaryMarketStatus } from 'types/assets';
import { setActiveCalendarTab } from 'actions/ui';
import AssetValueChart from 'components/views/app/Assets/AssetDetails/scenes/AssetValueChart/AssetValueChart.tsx';

class AssetDetailsCarousel extends Component {
  constructor(props) {
    super(props);
    const { user, activeAsset } = this.props;

    const { getSpeciallyAccessibleAssetsIdAccessTypeAndLimitationsMap } = user;
    const ebAssets = getSpeciallyAccessibleAssetsIdAccessTypeAndLimitationsMap
      ? getSpeciallyAccessibleAssetsIdAccessTypeAndLimitationsMap()
      : new Map();
    const ebAsset = ebAssets.get(activeAsset.id);

    const isEbAsset = ebAsset && ebAsset.accessType === 'early_io';
    this.state = {
      currentSlide: 0,
      showVerifyEmailPopup: false,
      showAssetFundedPopup: false,
      showTradingInfoPopup: false,
      carouselContainerHeight: window.innerHeight,
      carouselViewsList: [
        { id: 'AssetDetailsMain', name: 'Asset 2.0 Main' },
        { id: 'AssetValueChart', name: 'Asset 2.0 Graph' },
        { id: 'AssetQuotes', name: 'Asset 2.0 Quote' },
        { id: 'AssetStory', name: 'Asset 2.0 Description' },
        { id: 'AssetVisuals', name: 'Asset 2.0 Media' },
        { id: 'AssetTimeline', name: 'Asset 2.0 Timeline' },
      ],
      isEbAsset,
    };
  }

  componentDidMount() {
    if (this.assetDetailsCarouselParentContainer)
      this.setState({
        carouselContainerHeight: this.assetDetailsCarouselParentContainer.offsetHeight,
      });

    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    this.props.removeActiveAssetMerchandiseProductData();
    window.removeEventListener('resize', this.handleWindowResize);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.activeAsset.id !== this.props.activeAsset.id) this.goToSlide(0);
    this.setState({
      showVerifyEmailPopup: false,
      showAssetFundedPopup: false,
      showTradingInfoPopup: false,
    });
  }

  trackAnalyticsViewEvent(slideIndex) {
    if (slideIndex === this.state.currentSlide) return;
    const view = this.state.carouselViewsList[slideIndex];
    const assetTicker = this.props.activeAsset.ticker;

    analytics.page(`${assetTicker} ${view.name}`);
    analytics.track(view.name, {
      category: SEGMENT_CATEGORIES.ASSET2_0_PORTAL,
      action: SEGMENT_ACTIONS.OPEN,
      asset: assetTicker,
      label: assetTicker,
    });
  }

  handleWindowResize = () => {
    if (this.assetDetailsCarouselParentContainer)
      this.setState({
        carouselContainerHeight: this.assetDetailsCarouselParentContainer.offsetHeight,
      });
  };

  toggleVerifyEmailPopup = () => {
    this.setState({ showVerifyEmailPopup: !this.state.showVerifyEmailPopup });
  };

  toggleAssetFundedPopup = () => {
    this.setState({ showAssetFundedPopup: !this.state.showAssetFundedPopup });
  };

  toggleTradingInfoPopup = () => {
    this.setState({ showTradingInfoPopup: !this.state.showTradingInfoPopup });
  };

  buyShares = async (isTrading = false) => {
    const emailIsVerified = await this.isEmailVerifiedAsync();
    if (!emailIsVerified) {
      this.toggleVerifyEmailPopup();
    } else if (!isTrading) {
      if (this.props.activeAsset.progress_bar >= 100) {
        this.setState({ showAssetFundedPopup: true });
      } else {
        this.props.history.push('/app/make-investment');
      }
    }

    if (isTrading) return emailIsVerified;
  };

  async isEmailVerifiedAsync() {
    const { emails } = await this.props.getUserData({ showGlobalLoader: false });
    return emails && !!emails.find(email => email.primary && email.verified);
  }

  subscribeUserOnAsset = asset => this.props.subscribeUserOnAsset(asset);

  unsubscribeUserFromAsset = asset => this.props.unsubscribeUserFromAsset(asset);

  goToSlide = index => {
    this.onSlideChange(index);
  };

  goToNextSlide = () => {
    this.onSlideChange(this.state.currentSlide + 1);
  };

  goToPreviousSlide = () => {
    this.onSlideChange(this.state.currentSlide - 1);
  };

  onSlideChange = slideIndex => {
    const { currentSlide, carouselViewsList } = this.state;

    let indexNum = parseInt(slideIndex);
    if (indexNum !== currentSlide && indexNum < carouselViewsList.length && indexNum >= 0) {
      this.trackAnalyticsViewEvent(slideIndex);
      this.setState({ currentSlide: indexNum });
    }
  };

  close = () => this.props.closeDetalsCallback();

  getBottomButtonsByApplicationMode = () => {
    switch (this.props.applicationMode) {
      case SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS:
        return this.getBottomButtonsSpecialAccess();

      default:
        return this.getBottomButtonsDefault();
    }
  };

  getBottomButtonsDefault = () => {
    const asset = this.props.activeAsset;
    const { calendarEnabled, setActiveCalendarTab } = this.props;
    const { isEbAsset } = this.state;
    const { secondaryMarket } = asset.financialInstrument?.markets ?? {};
    const timestampDiff = getSecondaryMarketEnableDiff(secondaryMarket);

    const isEnabledForTrading = timestampDiff <= 0;
    const isInTradingStatus =
      asset.asset_status === ASSET_STATUS.POST_ONLY ||
      asset.asset_status === ASSET_STATUS.TRADING_OPENED;

    const isTrading =
      isInTradingStatus &&
      isEnabledForTrading &&
      secondaryMarket.status !== SecondaryMarketStatus.DISABLED;
    const isTradingClosed =
      asset.asset_status === ASSET_STATUS.TRADING_CLOSED ||
      (isInTradingStatus && !isEnabledForTrading) ||
      (isInTradingStatus && secondaryMarket.status === SecondaryMarketStatus.DISABLED);

    const notTradingEB = isEbAsset && !isTrading && !isTradingClosed;

    if (asset.asset_status === 'Open ICO' || notTradingEB) {
      return <BuySharesButton asset={asset} buyShares={() => this.buyShares()} />;
    } else if (isTrading && !isTradingClosed) {
      return (
        <BidAskButtons history={this.props.history} verifyEmail={() => this.buyShares(true)} />
      );
    }

    const isComingSoon = asset.asset_status === ASSET_STATUS.COMING_SOON;
    const shouldDisplayCalendar = isComingSoon || calendarEnabled;

    return (
      <div
        className={`AssetDetails-assetActionbutton-container${
          shouldDisplayCalendar ? '' : ' hidden-calendar'
        }`}
      >
        <NotifyMeButton
          asset={asset}
          subscribeUserOnAsset={this.props.subscribeUserOnAsset}
          subscriptions={this.props.subscriptions}
          unsubscribeUserFromAsset={this.props.unsubscribeUserFromAsset}
        />
        {shouldDisplayCalendar && (
          <Link
            className="AssetDetails-assetCalendar-link"
            to="/app/investments-calendar"
            onClick={() => setActiveCalendarTab(isComingSoon ? 1 : 0)}
            title="Trading Calendar"
          >
            <CalendarIcon />
          </Link>
        )}
      </div>
    );
  };

  getBottomButtonsSpecialAccess = () => {
    const { activeAsset } = this.props;
    // TODO: if/when user access groups go beyond early io access then this will need to reference the user's access groups to determine if the that type allows for early investment.
    return <BuySharesButton asset={activeAsset} buyShares={this.buyShares} earlyAccess={true} />;
  };

  render() {
    const { applicationMode, activeAsset, user } = this.props;
    const { currentSlide, carouselViewsList } = this.state;
    const showCloseButton =
      this.props.applicationMode !== SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS;

    const { secondaryMarket } = activeAsset.financialInstrument?.markets ?? {};
    const timestampDiff = getSecondaryMarketEnableDiff(secondaryMarket);
    const assetIsDisabled =
      (activeAsset.asset_status === ASSET_STATUS.POST_ONLY ||
        activeAsset.asset_status === ASSET_STATUS.TRADING_OPENED) &&
      secondaryMarket.status === SecondaryMarketStatus.DISABLED;

    const isTrading =
      (activeAsset.asset_status === ASSET_STATUS.POST_ONLY && timestampDiff <= 0) ||
      (activeAsset.asset_status === ASSET_STATUS.TRADING_OPENED &&
        secondaryMarket.status !== SecondaryMarketStatus.DISABLED);
    const isTradingOpened =
      activeAsset.asset_status === ASSET_STATUS.TRADING_OPENED ||
      (activeAsset.asset_status === ASSET_STATUS.POST_ONLY && timestampDiff <= 0);

    const button = this.getBottomButtonsByApplicationMode();

    const wrapperClassName = classnames('AssetDetails', applicationMode, {
      trading: assetIsDisabled ? false : isTrading,
    });

    const gutterClassName = classnames('AssetDetails-lastslide', {
      trading: assetIsDisabled ? false : isTrading,
    });

    const gutter =
      currentSlide === carouselViewsList.length - 1 ? (
        <div className={gutterClassName}>
          Questions / Need help?&nbsp;
          <a href="mailto:hello@rallyrd.com">Email us!</a>
        </div>
      ) : (
        <span className="AssetDetails-nextslide">
          <TextButton onClick={this.goToNextSlide}>&or;</TextButton>
        </span>
      );

    const detailPageProps = {
      applicationMode,
      buyShares: this.buyShares,
      activeAsset,
      history: this.props.history,
      nextSlide: this.goToNextSlide,
      previousSlide: this.goToPreviousSlide,
      subscribeUserOnAsset: this.subscribeUserOnAsset,
      subscriptions: this.props.subscriptions,
      unsubscribeUserFromAsset: this.unsubscribeUserFromAsset,
      toggleTradingInfoPopup: this.toggleTradingInfoPopup,
      buySellOrderType: this.props.buySellOrderType,
    };

    return (
      <span className={wrapperClassName}>
        {showCloseButton && (
          <React.Fragment>
            <MediaQuery minDeviceWidth={768}>
              <CloseButton onClickHandler={this.close} />
            </MediaQuery>
            <MediaQuery maxDeviceWidth={767}>
              <BackButton onClickHandler={this.close} />
            </MediaQuery>
          </React.Fragment>
        )}
        <div className="AssetDetails-dots">
          <SelectDots onSelected={this.goToSlide} number={6} selected={currentSlide} />
        </div>

        {isTradingOpened && <TradingWindowBanner />}

        <div
          className={`AssetDetails-carousel ${applicationMode} ${`index-` + currentSlide}`}
          ref={assetDetailsCarouselParentContainer =>
            (this.assetDetailsCarouselParentContainer = assetDetailsCarouselParentContainer)
          }
        >
          <DetailsCarousel
            parentRef={this.state.carouselContainerHeight}
            detailPageComponents={[
              <MainPage {...detailPageProps} />,
              <AssetValueChart {...detailPageProps} />,
              <AssetQuotes {...detailPageProps} />,
              <AssetStory {...detailPageProps} />,
              <AssetVisuals {...detailPageProps} />,
              <AssetTimeline {...detailPageProps} />,
            ]}
            onSlideChange={this.onSlideChange}
            activeIndex={currentSlide}
          />

          <span className="AssetDetails-gutter">
            <span className="AssetDetails-assetActionbutton">{button}</span>
            {gutter}
          </span>
        </div>
        <TradingInfoPopup
          onClick={this.toggleTradingInfoPopup}
          show={this.state.showTradingInfoPopup}
        />
        <OneHundredPercentFundedPopup
          show={this.state.showAssetFundedPopup}
          disableAutoClose
          onRequestClose={this.toggleAssetFundedPopup}
        />
        <VerifyEmailPopup
          show={this.state.showVerifyEmailPopup}
          onRequestClose={this.toggleVerifyEmailPopup}
          email={user.email}
        />
      </span>
    );
  }
}

const mapStateToProps = state => ({
  applicationMode: state.UI.applicationMode,
  calendarEnabled: state.UI.calendar.tradingEnabled,
  activeAsset: state.Assets.activeAsset,
  subscriptions: state.Auth.subscriptions,
  user: state.Auth.user,
});

const mapDispatchToProps = {
  getUserData,
  removeActiveAssetMerchandiseProductData,
  setActiveCalendarTab,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withDeviceDetection(AssetDetailsCarousel));
