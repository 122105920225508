import React from 'react';

import {
  NamePhoneInfo,
  DateOfBirth,
  HomeAddress,
  SocialSecurityNumber,
} from 'components/shared/MultistepForm/steps/profile';
import {
  SharePurchaseInfo,
  ConnectBank,
  BankAccountType,
  BankInfo,
  Confirmation,
} from 'components/shared/MultistepForm/steps/funding';
import {
  AccreditationStatus,
  FinraOrSec,
  OrganizationName,
  IsShareholder,
  CompanyTicker,
  EmploymentStatus,
  EmploymentDetails,
  EmployerInformation,
  RiskProfile,
} from 'components/shared/MultistepForm/steps/investment';

import StateAvailabilityError from 'components/shared/StateAvailabilityError';
import { PASSPORT_SECTION } from 'constants/main'

interface PassportStepsProps {
  investingPassportApplied?: boolean;
  investingPassportCompleted?: boolean;
  shouldSkipEmployerInfo?: boolean;
  isAssociated?: boolean;
  isShareholder?: boolean;
  fundingSourceExists?: boolean;
  shouldSkipConfirmation?: boolean;
  shouldSkipBankVerification?: boolean;
  isUserStateInfo?: boolean;
  user?: Record<string, any>;
  handleFormClose?: () => void;
}

const PassportSteps = (props: PassportStepsProps = {}) => ([
  {
    path: '/name-phone-info',
    render: NamePhoneInfo,
    name: 'Buy Shares Name & Phone #',
    analyticsEventName: 'Create Profile Name & Phone # Screen',
    partOf: [PASSPORT_SECTION.ONBOARDING, PASSPORT_SECTION.IO, PASSPORT_SECTION.TRADING],
    needToSkip: props.investingPassportApplied,
  },
  {
    path: '/date-of-birth',
    render: DateOfBirth,
    name: 'Buy Shares DOB',
    analyticsEventName: 'Create Profile DOB Screen',
    partOf: [PASSPORT_SECTION.ONBOARDING, PASSPORT_SECTION.IO, PASSPORT_SECTION.TRADING],
    needToSkip: props.investingPassportApplied,
  },
  {
    path: '/home-address',
    render: HomeAddress,
    name: 'Buy Shares Address Input',
    analyticsEventName: 'Create Profile Address Screen',
    partOf: [PASSPORT_SECTION.ONBOARDING, PASSPORT_SECTION.IO, PASSPORT_SECTION.TRADING],
    needToSkip: props.investingPassportApplied,
  },
  {
    path: '/state-availability-issue',
    render: () => <StateAvailabilityError onClose={() => props.handleFormClose?.()} />,
    partOf: [PASSPORT_SECTION.ONBOARDING],
    needToSkip: !props.user?.state_status.is_state_blocked,
  },
  {
    path: '/ssn',
    render: SocialSecurityNumber,
    name: 'Buy Shares SSN',
    analyticsEventName: 'Create Profile SSN Screen',
    partOf: [PASSPORT_SECTION.ONBOARDING, PASSPORT_SECTION.IO, PASSPORT_SECTION.TRADING],
    needToSkip: props.investingPassportApplied,
  },
  {
    path: '/accreditation-status',
    render: AccreditationStatus,
    name: 'Buy Shares Accredited Status Entry',
    partOf: [PASSPORT_SECTION.IO, PASSPORT_SECTION.TRADING],
    needToSkip: props.investingPassportApplied,
  },
  {
    path: '/employment-status',
    render: EmploymentStatus,
    name: 'Buy Shares Employment Status Entry',
    partOf: [PASSPORT_SECTION.IO, PASSPORT_SECTION.TRADING],
    needToSkip: props.investingPassportApplied,
  },
  {
    path: '/employer-information',
    render: EmployerInformation,
    name: 'Buy Shares Employer Information Entry',
    partOf: [PASSPORT_SECTION.IO, PASSPORT_SECTION.TRADING],
    needToSkip: props.investingPassportApplied || props.shouldSkipEmployerInfo,
  },
  {
    path: '/employment-details',
    render: EmploymentDetails,
    name: 'Buy Shares Employment Details Entry',
    partOf: [PASSPORT_SECTION.IO, PASSPORT_SECTION.TRADING],
    needToSkip: props.investingPassportApplied,
  },
  {
    path: '/risk-profile',
    render: RiskProfile,
    name: 'Buy Shares Risk Profile Entry',
    partOf: [PASSPORT_SECTION.IO, PASSPORT_SECTION.TRADING],
    needToSkip: props.investingPassportApplied,
  },
  {
    path: '/finra-or-sec',
    render: FinraOrSec,
    name: 'FINRA or SEC Accreditation',
    partOf: [PASSPORT_SECTION.IO, PASSPORT_SECTION.TRADING],
    needToSkip: props.investingPassportApplied,
  },
  {
    path: '/organization-name',
    render: OrganizationName,
    name: 'Buy Shares FINRA Status Entry',
    partOf: [PASSPORT_SECTION.IO, PASSPORT_SECTION.TRADING],
    needToSkip: props.investingPassportApplied || !props.isAssociated,
  },
  {
    path: '/is-shareholder',
    render: IsShareholder,
    name: 'Buy Shares Shareholder Status Entry',
    partOf: [PASSPORT_SECTION.IO, PASSPORT_SECTION.TRADING],
    needToSkip: props.investingPassportApplied,
  },
  {
    path: '/company-ticker',
    render: CompanyTicker,
    name: 'Buy Shares Company Ticker',
    partOf: [PASSPORT_SECTION.IO, PASSPORT_SECTION.TRADING],
    needToSkip: props.investingPassportApplied || !props.isShareholder,
  },
  {
    path: '/share-purchase-info',
    render: SharePurchaseInfo,
    name: 'Buy Shares Funding Source Creation Intro',
    partOf: [PASSPORT_SECTION.IO, PASSPORT_SECTION.TRADING],
    needToSkip: props.fundingSourceExists,
  },
  {
    path: '/connect-bank',
    render: ConnectBank,
    name: 'Buy Shares IAV or Manually',
    partOf: [PASSPORT_SECTION.IO, PASSPORT_SECTION.TRADING],
    needToSkip: props.fundingSourceExists,
  },
  {
    path: '/bank-account-type',
    render: BankAccountType,
    name: 'Buy Shares Bank Account Type',
    partOf: [PASSPORT_SECTION.IO, PASSPORT_SECTION.TRADING],
    needToSkip: props.fundingSourceExists,
  },
  {
    path: '/bank-info',
    render: BankInfo,
    name: 'Buy Shares Bank Account Info Input',
    partOf: [PASSPORT_SECTION.IO, PASSPORT_SECTION.TRADING],
    needToSkip: props.fundingSourceExists,
  },
  {
    path: '/confirmation',
    render: Confirmation,
    name: 'Buy Shares Bank Account Microdeposit Verification',
    partOf: [PASSPORT_SECTION.IO, PASSPORT_SECTION.TRADING],
    needToSkip: (props.shouldSkipConfirmation && props.shouldSkipBankVerification),
  }
]);

export default PassportSteps;
