import React, { Component } from 'react';

import { Cross } from '../Icons/Cross';

import './CloseButton.css';

export class CloseButton extends Component {
  render() {
    return (
      <div
        className={`Closebutton ${this.props.isHowItWorksPopup ? 'isHowItWorksPopup' : ''}`}
        onClick={this.props.onClickHandler || this.props.onClick}
      >
        <span className="Closebutton-cross">
          <Cross />
        </span>
      </div>
    );
  }
}

export default CloseButton;
