import React, { Component } from 'react';
import { SelectButton } from './SelectButton';
import './YesNoSelect.css';

export class YesNoSelect extends Component {
  constructor(props) {
    super(props);
    this.options = this.props.options || ['No', 'Yes'];
    if (this.props.wantedSelected === true) {
      this.selected = this.props.wanted;
    } else if (this.props.wantedSelected === false) {
      this.options.map(option => {
        if (option !== this.props.wanted) {
          this.selected = option;
        }
        return option;
      });
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    this.selected = value;
    this.props.onChange(value);
  }

  render() {
    let selectOptions = this.options.map(option => {
      let wanted = this.props.wanted === option;
      let wantedSelected = this.props.wantedSelected;
      let selected = (wantedSelected === true && wanted) || (wantedSelected === false && !wanted);
      return (
        <SelectButton
          key={option}
          name={option}
          wanted={wanted}
          selected={selected}
          onSelect={this.handleChange}
          showColors={this.props.showColors}
        />
      );
    });
    return <div className="YesNoSelect">{selectOptions}</div>;
  }
}

export default YesNoSelect;
