import React, { Component } from 'react';

import Popup from '../Popup';
import { SecondaryButton } from 'components/shared/Buttons';
import history from 'services/history';
import './TradingApprovedPopup.scss';

class TradingApprovedPopup extends Component {
  handleRequestClose = () => {
    localStorage.setItem('notifiedOnTradingApproved', true);
    this.props.onClose && this.props.onClose();
  };

  handleClick = () => {
    localStorage.setItem('notifiedOnTradingApproved', true);
    history.push('/app/onboarding', { step: '1' });
    this.props.onClose && this.props.onClose();
  };

  render() {
    const { show, onContinue, onRequestClose, ...props } = this.props;
    return (
      <Popup
        className="TradingApprovedPopup"
        show={show}
        disableAutoClose
        onRequestClose={this.handleRequestClose}
        {...props}
      >
        <div className="TradingApprovedPopup-content">
          <h1>Approved For Trading!</h1>
          <p>
            Your state has been approved for participation in Trading Windows. Fund your account to
            buy shares within a Trading Window by clicking continue below.
          </p>
          <SecondaryButton text="Continue" onClick={this.handleClick} />
          <footer>
            For any additional information or details, email{' '}
            <a href="mailto:hello@rallyrd.com">hello@rallyrd.com</a>, or call/text:{' '}
            <a href="tel:347-952-8058">347-952-8058</a>
          </footer>
        </div>
      </Popup>
    );
  }
}

export default TradingApprovedPopup;
