import React, { Component } from 'react';
import Dropzone from 'react-dropzone';

import ID_ICON from 'images/photo-id.png';

import './ImageUpload.css';

export class ImageUpload extends Component {
  constructor(props) {
    super(props);

    this.onDrop = this.onDrop.bind(this);
    this.state = {
      isFileDropError: null,
    };
  }

  onDrop(files, unsupportedFiles) {
    if (files.length > 0) {
      this.setState({ isFileDropError: null });
      this.props.onDrop(files[0], null);
    } else if (unsupportedFiles.length > 0) {
      this.setState({
        isFileDropError: 'The file you selected is an unsupported type (JPG and PNG only)',
      });
    } else {
      this.setState({
        isFileDropError:
          'Something went wrong while reading the file. Please try again or choose a different file.',
      });
    }
  }

  componentWillUnmount() {
    this.setState({ isFileDropError: null });
  }

  render() {
    return (
      <div className="ImageUpload">
        <Dropzone
          multiple={false}
          accept="image/jpg,image/jpeg,image/png"
          onDrop={this.onDrop}
          style={{
            minHeight: '24vh',
            border: '0.3vmin dashed #ccc',
            cursor: 'pointer',
          }}
        >
          <div className="ImageUpload-inner">
            <img src={ID_ICON} alt="id-document" className="ImageUpload-img" />
            <p className="ImageUpload-description">
              {!this.props.isRallyAndroidClient
                ? `Drag and drop a file or click here to select an image file`
                : `Select a photo or take a photo of your government-issued ID`}
            </p>
            {this.state.isFileDropError && (
              <span className="ImageUpload-rules ImageUpload-rules__error">
                {this.state.isFileDropError}
              </span>
            )}
            <p className="ImageUpload-rules">JPG or PNG only. Maximum file size - 5MB</p>
          </div>
        </Dropzone>
      </div>
    );
  }
}

export default ImageUpload;
