import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Popup from 'components/shared/Popup';
import { Button, TextButton } from 'components/shared/Buttons';
import { DotsLoader } from 'components/shared/Loaders';
import ErrorMessage from 'components/shared/Error';

import analytics from 'services/analytics';

import CONFIG from 'config';
import giftImage from 'images/gift.png';
import './GiftPopup.scss';

import { SEGMENT_CATEGORIES } from 'constants/analytics';

const initialState = {
  isLoading: false,
  isFormSubmitted: false,
  redeemCode: '',
  codeValue: '',
  error: null,
};

class GiftPopup extends Component {
  state = initialState;

  componentDidMount() {
    const { saPopup } = this.props;

    if (saPopup?.autoGiftCode) {
      this.setState({ isFormSubmitted: true, codeValue: saPopup.autoGiftCode }, () => {
        this.props.onCodeApply({ value: saPopup.autoGiftCode });
      });
    }
  }

  handleClose = () => {
    this.setState(initialState);
    this.props.onRequestClose();
  };

  handleRedeemCodeChange = e => this.setState({ redeemCode: e.target.value, error: null });

  handleFormSubmit = async e => {
    const { redeemCode, isLoading } = this.state;
    e.preventDefault();

    if (isLoading) return;
    if (!redeemCode) return this.setState({ error: 'Please enter a gift code' });

    this.setState({ isLoading: true });

    try {
      const token = localStorage.getItem('token');
      const res = await fetch(`${CONFIG.apiUrlPromocodes}/promocode/validate/`, {
        method: 'POST',
        headers: { Authorization: token },
        body: JSON.stringify({ redeemCode: this.state.redeemCode.toUpperCase().trim() }),
      });

      const data = await res.json();
      if (res.status !== 200) throw data;
      if (data.assetTicker && data.assetTicker !== this.props.activeAsset.ticker) {
        throw new Error(`This promo code is only valid for asset "${data.assetTicker}".`);
      }
      this.props.onCodeApply(data);

      // log the successful usage of a promo code.
      analytics.track('Promo Code Entry Success', {
        promocode: data,
        category: SEGMENT_CATEGORIES.PROMO_CODES,
        assetTicker: this.props.activeAsset.ticker,
      });

      this.setState({ isFormSubmitted: true, codeValue: data.value });
    } catch (error) {
      // log the unsuccessful usage of a promo code.
      analytics.track('Promo Code Entry Invalid Code', {
        promocode: this.state.redeemCode.toUpperCase().trim(),
        category: SEGMENT_CATEGORIES.PROMO_CODES,
        error: error,
        assetTicker: this.props.activeAsset.ticker,
      });

      this.setState({ isLoading: false, error: error.detail || error.message || error });
    }
  };

  render() {
    const { show } = this.props;
    const { redeemCode, codeValue, isLoading, isFormSubmitted, error } = this.state;

    return (
      <Popup
        contentClassName="GiftPopup"
        show={show}
        onRequestClose={this.handleClose}
        disableAutoClose
      >
        {isFormSubmitted ? (
          <div className="GiftPopup-success">
            <h2>${codeValue} Credit Applied!</h2>
            <Button text="Okay!" onClick={this.handleClose} />
          </div>
        ) : (
          <form onSubmit={this.handleFormSubmit}>
            <img src={giftImage} alt="" />
            <h2>Enter Your Gift Code</h2>
            <p className="GiftPopup-description">
              If you have a gift code or promo, enter it below and it will be automatically applied
              to this investment!
            </p>
            <div className="GiftPopup-input-container">
              <input
                autoFocus
                className={`GiftPopup-input ${error ? 'error' : ''}`}
                value={redeemCode}
                onChange={this.handleRedeemCodeChange}
              />
              <ErrorMessage error={error} />
            </div>
            <Button type="submit" style={{ pointerEvents: isLoading ? 'none' : 'auto' }}>
              <span style={{ visibility: isLoading ? 'hidden' : 'visible' }}>Redeem Gift</span>
              {isLoading && <DotsLoader color="#fff" />}
            </Button>
            <TextButton type="button" text="Cancel" onClick={this.handleClose} />
          </form>
        )}
      </Popup>
    );
  }
}

GiftPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onCodeApply: PropTypes.func.isRequired,
};

const mapStateToProps = ({ Assets: { activeAsset }, Auth, UI: { saPopup } }) => ({
  activeAsset,
  user: Auth.user,
  saPopup,
});

export default connect(mapStateToProps)(GiftPopup);
