import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ReceiptsList from './ReceiptsList';
import { LeftCaret } from 'components/shared/Icons/LeftCaret';
import { TRANSFER_TYPE } from 'constants/main';
import { isEmpty } from 'utils';

import './Receipts.css';

class Receipts extends Component {
  static defaultProps = {
    transfers: [],
    orders: [],
    assetList: [],
  };

  state = {
    receipts: [],
  };

  componentDidMount() {
    const { transfers, orders, assetList, history } = this.props;

    // prevent direct access without loading transactions and orders
    if (isEmpty(transfers) && isEmpty(orders) && isEmpty(assetList))
      history.push('/app/investments');

    this.mountReceipts();
  }

  mountReceipts = async () => {
    const { transfers, orders, assetList } = this.props;

    const receipts = await Promise.all(
      transfers.map(async transfer => {
        const isOldTradingTransaction =
          (transfer.type === TRANSFER_TYPE.ORDER || transfer.type === TRANSFER_TYPE.INVESTMENT) &&
          !transfer.financial_instrument_id &&
          !!transfer.side;

        // remove the ones (investments and orders) that don't have shares_count
        if (
          !isOldTradingTransaction &&
          (transfer.type === TRANSFER_TYPE.INVESTMENT || transfer.type === TRANSFER_TYPE.ORDER) &&
          !transfer.shares_count
        )
          return {};

        const targetAsset = await assetList.find(
          asset => asset.financialInstrument.id === transfer.financialInstrumentId,
        );

        const targetOrder = await orders.find(order => transfer.order_id === order.id);

        if (isOldTradingTransaction) {
          return {
            ...targetOrder,
            oldTradingId: targetOrder.id,
            orderStatus: targetOrder?.status || transfer.status,
            status: transfer.status,
            type: TRANSFER_TYPE.ORDER,
          };
        }

        return {
          ...targetOrder,
          ...transfer,
          orderStatus: targetOrder?.status || transfer.status,
          status: transfer.status,
          asset_ticker: transfer.asset_ticker || targetAsset?.ticker,
          amount: transfer.amount || targetAsset?.pricing?.order,
          type: !!transfer.order_id ? TRANSFER_TYPE.ORDER : transfer.type || TRANSFER_TYPE.ORDER,
        };
      }),
    ).then(result => {
      // create unique array (used to delete duplicated non-ats trading transfers)
      const arr = Array.from(new Set(result.map(JSON.stringify))).map(JSON.parse);

      return arr
        .filter(transfer => !isEmpty(transfer))
        .sort((a, b) => new Date(b.created) - new Date(a.created));
    });

    this.setState({ receipts });
  };

  render() {
    const { receipts } = this.state;

    return (
      <div className="Receipts">
        <div className="Receipts-header">
          <div className="Receipts-back">
            <NavLink to="/app/investments">
              <LeftCaret />
            </NavLink>
          </div>
          <span className="Receipts-header-title">Transaction History</span>
        </div>
        <ReceiptsList receiptsList={receipts} />
      </div>
    );
  }
}

export default Receipts;
