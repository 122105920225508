import React, { Component } from 'react';
import { connect } from 'react-redux';

import { CloseButton } from 'components/shared/Buttons/CloseButton';
import { SecondaryButton } from 'components/shared/Buttons/SecondaryButton';

import './Chat.scss';

class Chat extends Component {
  onCloseHandler = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <div className={`Chat ${this.props.applicationMode}`}>
        <CloseButton onClickHandler={this.onCloseHandler} />
        <div className="Chat-contents">
          <h2>Coming Soon!</h2>
          <p>Check back soon for chat & in-app messaging!</p>
          <SecondaryButton text="okay" onClick={this.onCloseHandler} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  applicationMode: state.UI.applicationMode,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Chat);
