import React, { Component } from 'react';
import './Details.css';

export class Details extends Component {
  render() {
    return (
      <span className="DetailsIcon">
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="31" viewBox="0 0 38 31">
          <path d="M36.643,2.696 L1.357,2.696 C0.607,2.696 -0.000,2.092 -0.000,1.348 C-0.000,0.603 0.607,0.000 1.357,0.000 L36.643,0.000 C37.392,0.000 38.000,0.603 38.000,1.348 C38.000,2.092 37.392,2.696 36.643,2.696 ZM1.357,9.435 L25.786,9.435 C26.535,9.435 27.143,10.038 27.143,10.783 C27.143,11.527 26.535,12.130 25.786,12.130 L1.357,12.130 C0.607,12.130 -0.000,11.527 -0.000,10.783 C-0.000,10.038 0.607,9.435 1.357,9.435 ZM1.357,18.869 L36.643,18.869 C37.392,18.869 38.000,19.473 38.000,20.217 C38.000,20.962 37.392,21.565 36.643,21.565 L1.357,21.565 C0.607,21.565 -0.000,20.962 -0.000,20.217 C-0.000,19.473 0.607,18.869 1.357,18.869 ZM1.357,28.305 L21.714,28.305 C22.464,28.305 23.071,28.908 23.071,29.652 C23.071,30.397 22.464,31.000 21.714,31.000 L1.357,31.000 C0.607,31.000 -0.000,30.397 -0.000,29.652 C-0.000,28.908 0.607,28.305 1.357,28.305 Z" />
        </svg>
      </span>
    );
  }
}
