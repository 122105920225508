import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SecondaryButton } from 'components/shared/Buttons';

import './TradingApproved.scss';

class TradingApproved extends Component {
  static propTypes = { onStepForward: PropTypes.func.isRequired };

  render() {
    return (
      <div className="TradingApproved">
        <h2>Approved For Trading!</h2>
        <p>
          Your State is only approved for Buying and Selling during Trading Windows. Initial
          Offering investing will be available soon.
        </p>
        <p>
          To be notified when the next Trading Window opens for this vehicle, select the "Notify
          When Trading" button at the bottom of each unique vehicle page.
        </p>
        <footer>
          For any additional information or details, email{' '}
          <a href="mailto:hello@rallyrd.com">hello@rallyrd.com</a>, or call/text:{' '}
          <a href="tel:347-952-8058">347-952-8058</a>
        </footer>
        <SecondaryButton text="Continue" onClick={() => this.props.onStepForward()} />
      </div>
    );
  }
}

export default TradingApproved;
