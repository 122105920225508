import CONFIG from 'config.js';
import { IEmploymentDetails, IInvestorDetails, IPersonalInformation } from 'types/customer';
import { getRequest, patchRequest, putRequest } from './RequestHandler';

export const patchInvestorDetails = (investorDetails: Partial<IInvestorDetails>, id: string) => {
  const config: any = CONFIG; // TODO types for config file
  const endpoint = config.apiUrlCustomers + `/customers/${id}/profile/investor-details`;
  return patchRequest(endpoint, investorDetails);
};

export const patchPersonalInformation = (
  investorDetails: Partial<IPersonalInformation>,
  id: string,
) => {
  const config: any = CONFIG; // TODO types for config file
  const endpoint = config.apiUrlCustomers + `/customers/${id}/profile/personal-information`;
  return patchRequest(endpoint, investorDetails);
};

export const getCustomerById = (id: string) => {
  const config: any = CONFIG;
  const endpoint = config.apiUrlCustomers + `/customers/${id}/profile`;
  return getRequest(endpoint, { conditionalType: 'if-match' });
};

export const putEmploymentDetails = (employmentDetails: IEmploymentDetails, id: string) => {
  const config: any = CONFIG; // TODO types for config file
  const endpoint = config.apiUrlCustomers + `/customers/${id}/profile/employment-details`;
  return putRequest(endpoint, employmentDetails);
};
