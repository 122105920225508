import React from 'react';
import { SimpleComponent } from 'types/components';
import { onChangeEvent } from 'types/events';

import './Radio.scss';

export interface IRadioOption {
  text: string;
  value: string;
  description?: string;
}

type RadioProps = {
  name: string;
  options: IRadioOption[];
  onChange: Function;
  style?: 'checkbox' | 'radio';
  value: string;
  className?: string;
};

export const Radio: SimpleComponent<RadioProps> = ({
  name,
  options,
  onChange,
  style = 'checkbox', // for now we are assuming that all radios are checkbox, we may change in the future
  value: selectedValue,
  className = '',
}) => {
  const handleOnChange = ({ currentTarget }: onChangeEvent) => onChange(currentTarget.value, currentTarget.name);
  return (
    <div className={`input-radio ${className}`}>
      {options.map(({ text, value, description }) => (
        <div className="input-radio__container" key={value}>
          <input
            type="radio"
            className="input-radio__input"
            onChange={handleOnChange}
            checked={value === selectedValue}
            id={`${name}-${value}`}
            value={value}
            name={name}
          />
          <label className="input-radio__label" htmlFor={`${name}-${value}`}>
            <span className={`input-radio__mask input-radio__mask--${style}`} />
            {text}
          </label>
          {description && <div className="input-radio__description">{description}</div>}
        </div>
      ))}
    </div>
  );
};
