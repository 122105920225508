import React from 'react';
import { SimpleComponent } from 'types/components';
import { OrderItemContainer } from './styled';
import { IOrder } from 'types/orders';
import OrderItem from 'components/views/app/Investments/OrderItem';

const PendingOrders: SimpleComponent<{ orders: Array<IOrder> }> = ({ orders }) => {
  const renderOrders = () => (
    <OrderItemContainer>
      {orders.map((order) => (
        <OrderItem order={order} key={order.id} />
      ))}
    </OrderItemContainer>
  );

  return (
    <>
      <div className="InvestmentsList-header">Pending transactions</div>
      {orders && renderOrders()}
    </>
  );
};

export default PendingOrders;
