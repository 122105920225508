import parseDomain from 'parse-domain';
import { handleActions } from 'redux-actions';

import {
  SHOW_GLOBAL_LOADER,
  HIDE_GLOBAL_LOADER,
  SET_FULLSCREEN_LOADER,
  SHOW_ASSETS_LOADING_OVERLAY,
  HIDE_ASSETS_LOADING_OVERLAY,
  TOGGLE_MENU,
  TOGGLE_ASSETS_MENU,
  SHOW_NAV_MENU_NOTIFICATION,
  HIDE_NAV_MENU_NOTIFICATION,
  SET_APPLICATION_MODE,
  TOGGLE_SAPOPUP,
  SET_SAPOPUP,
  SET_AUTO_GIFTCODE,
  SET_ACTIVE_CALENDAR_TAB,
  SET_TRADING_CALENDAR_ENABLED,
} from 'actions/types';

import {
  RALLY_SUBDOMAIN_APPLICATION_MODE_MAP,
  SUPPORTED_APPLICATION_MODES,
} from '../constants/main';

const getApplicationModeFromWindow = () => {
  // Adding a try catch for some reason it will fail when trying to open the app
  // using a WebView (Android Implementation)
  try {
    let href = window.location.href;

    // setting localhost to localhost.dev ensures that parsing will work correctly for subdomains, which is how what we
    //  use to infer different application modes.
    if (href.indexOf('localhost') !== -1) {
      href = href.replace('localhost', 'localhost.dev');
    }

    const parsed = parseDomain(href, { privateTlds: true });
    const subdomain = parsed.subdomain.split('.', 2)[0];

    let mode = SUPPORTED_APPLICATION_MODES.DEFAULT;
    if (Object.keys(RALLY_SUBDOMAIN_APPLICATION_MODE_MAP).includes(subdomain)) {
      mode = RALLY_SUBDOMAIN_APPLICATION_MODE_MAP[subdomain];
    }

    return mode;
  } catch (ex) {
    return SUPPORTED_APPLICATION_MODES.DEFAULT;
  }
};

const defaultState = {
  /**
   * Displays animated main application loader.
   * @type {boolean}
   */
  globalLoading: false,
  globalLoadingJobs: 0,
  /**
   * Displays full screen loader.
   * @type {boolean}
   */
  globalFullscreenLoader: false,
  /**
   * Displays overlay loader during the navigation to asset details view ("2.0" screen).
   * @type {boolean}
   */
  assetsOverlayVisible: false,
  /**
   * Toggles nav menu on mobile devices.
   * @type {boolean}
   */
  menuIsOpen: false,
  /**
   * Displays asset list on mobile devices.
   * @type {boolean}
   */
  displayAssetsMenu: false,
  /**
   * Navigation menu paths that should display the notification icon for appropriate nav item.
   * @type {[string]}
   */
  navMenuNotifications: [],
  /**
   * Specifies what mode the ui application is running in.
   * @type {string}
   */
  applicationMode: getApplicationModeFromWindow(),
  saPopup: {
    show: false,
  },
  calendar: {
    activeTab: 0,
    tradingEnabled: true,
  },
};
const reducerMap = {};
reducerMap[SHOW_GLOBAL_LOADER] = (state, action) => ({
  ...state,
  globalLoading: true,
  globalLoadingJobs: state.globalLoadingJobs + 1,
});

reducerMap[HIDE_GLOBAL_LOADER] = (state, action) => ({
  ...state,
  globalLoadingJobs: state.globalLoadingJobs > 0 ? state.globalLoadingJobs - 1 : 0,
  globalLoading: state.globalLoadingJobs - 1 > 0,
});

reducerMap[SET_FULLSCREEN_LOADER] = (state, action) => ({
  ...state,
  globalFullscreenLoader: action.payload,
});

reducerMap[SHOW_ASSETS_LOADING_OVERLAY] = (state, action) => ({
  ...state,
  assetsOverlayVisible: true,
});

reducerMap[HIDE_ASSETS_LOADING_OVERLAY] = (state, action) => ({
  ...state,
  assetsOverlayVisible: false,
});

reducerMap[TOGGLE_MENU] = (state, action) => ({ ...state, menuIsOpen: !state.menuIsOpen });

reducerMap[TOGGLE_ASSETS_MENU] = (state, action) => ({
  ...state,
  displayAssetsMenu: !state.displayAssetsMenu,
});

reducerMap[SHOW_NAV_MENU_NOTIFICATION] = (state, action) => ({
  ...state,
  navMenuNotifications: [...state.navMenuNotifications, action.payload],
});

reducerMap[HIDE_NAV_MENU_NOTIFICATION] = (state, action) => ({
  ...state,
  navMenuNotifications: state.navMenuNotifications.filter(i => i !== action.payload),
});

reducerMap[SET_APPLICATION_MODE] = (state, action) => ({
  ...state,
  applicationMode: action.payload ? action.payload : getApplicationModeFromWindow(),
});

reducerMap[TOGGLE_SAPOPUP] = state => ({
  ...state,
  saPopup: {
    ...state.saPopup,
    show: !state.saPopup.show,
  },
});

reducerMap[SET_SAPOPUP] = (state, action) => {
  let activeSaAsset = action.payload || state.saPopup.asset || {};

  if (action.payload?.saAsset?.[0] && action.payload?.saAsset?.[1]) {
    activeSaAsset = {
      id: action.payload.saAsset[0],
      type: action.payload.saAsset[1].accessType,
      accessEnds: action.payload.saAsset[1].accessEnds,
      limitations: action.payload.saAsset[1].limitations,
    };
  }

  return {
    ...state,
    saPopup: {
      ...state.saPopup,
      asset: activeSaAsset,
    },
  };
};

reducerMap[SET_AUTO_GIFTCODE] = (state, action) => ({
  ...state,
  saPopup: {
    ...state.saPopup,
    autoGiftCode: action.payload,
  },
});
reducerMap[SET_ACTIVE_CALENDAR_TAB] = (state, action) => ({
  ...state,
  calendar: {
    ...state.calendar,
    activeTab: action.payload,
  },
});
reducerMap[SET_TRADING_CALENDAR_ENABLED] = (state, action) => ({
  ...state,
  calendar: {
    ...state.calendar,
    tradingEnabled: action.payload,
  },
});

const UI = handleActions(reducerMap, defaultState);

export default UI;
