import styled from '@emotion/styled';

export const CardWrapper = styled.div(
  () => ({
    width: '100%',
    height: '100%',
    color: '#323232',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
  })
);

export const CardWrapperTop = styled.div(
  () => ({
    height: '60%',
    width: '100%',
    backgroundColor: 'white',
    padding: 20,
    boxSizing: 'border-box',
    fontFamily: 'Gotham Bold, sans-serif',
    '.topWrapper': {
      margin: 'auto',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-end',
      maxWidth: '540px',
      '.title': {
        fontSize: '1.25em',
        marginBottom: 30,
      },
      '.subtitle': {
        fontSize: '1em',
        marginBottom: 15,
      },
      '.image': {
        flexShrink: 1,
        height: '23vh',
        padding: '4vh 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        'img': {
          minHeight: 0,
          maxHeight: '100%',
        },
        '.imageText': {
          marginTop: 20,
          fontFamily: 'Gotham Medium, sans-serif',
        }
      }
    }
  })
);

export const CardWrapperBottom = styled.div(
  ({ theme }) => ({
    height: '40%',
    width: '100%',
    backgroundColor: theme.colors.newGreen,
    padding: 20,
    boxSizing: 'border-box',
    fontFamily: 'Gotham Regular, sans-serif',
    display: 'flex',
    flexDirection: 'column',
    'strong': {
      fontFamily: 'Gotham Bold, sans-serif',
    },
    '.bottomWrapper': {
      margin: 'auto',
      maxWidth: '540px',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      '.button': {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        paddingTop: 20,
        marginBottom: 'clamp(15px, 4vh, 50px)',
        'button': {
          background: 'none',
          textTransform: 'uppercase',
          color: 'white',
          border: 'none',
          fontFamily: 'Gotham Bold, sans-serif',
          fontSize: '1.1em',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          transition: '.15s',
          '.RightCaret': {
            height: '100%',
            width: 'auto',
            'svg': {
              height: '1.6em',
              width: 'auto',
            }
          },
          '&:hover': {
            opacity: '0.7'
          }
        }
      }
    }
  })
);
