import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input } from 'components/shared/Inputs/Input';
import { USStatesSelect } from 'components/shared/USStatesSelect';
import CollectInfoMessage from 'components/shared/CollectInfoMessage';
import { BackNextButtons } from 'components/shared/Buttons/BackNextButtons';
import Error from 'components/shared/Error';
import { updateUserData } from 'actions';
import { testPoBox } from 'utils';

import withDeviceDetection from 'hoc/withDeviceDetection';

import './HomeAddress.css';

class HomeAddress extends Component {
  static propTypes = {
    data: PropTypes.object,
    onFormUpdate: PropTypes.func.isRequired,
    onStepForward: PropTypes.func.isRequired,
    onStepBackward: PropTypes.func.isRequired,
    updateUserData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    formMode: 'investment',
  };

  constructor(props) {
    super(props);

    const { isStepValid, isAddressNotPoBox, isAptNotPoBox } = this.validate();
    this.state = {
      isStepValid,
      error: isAddressNotPoBox && isAptNotPoBox ? null : 'PO Boxes are not permitted',
      isLoading: false,
    };
    this.initialData = this.props.data;
  }

  validate = () => {
    const {
      data: { address, city, state, postal_code, apt },
    } = this.props;
    const isAddressValid = typeof address === 'string' && address !== '';
    const isAptValid = true; // optional field
    const isCityValid = typeof city === 'string' && city !== '';
    const isStateValid = typeof state === 'string' && state !== '';
    const isZipValid = typeof postal_code === 'string' && postal_code !== '';
    const isAddressNotPoBox = !testPoBox(address);
    const isAptNotPoBox = !testPoBox(apt);

    const isStepValid =
      isAddressValid &&
      isAptValid &&
      isCityValid &&
      isStateValid &&
      isZipValid &&
      isAddressNotPoBox &&
      isAptNotPoBox;

    return { isStepValid, isAddressNotPoBox, isAptNotPoBox };
  };

  validateStep = () => {
    const { isStepValid, isAddressNotPoBox, isAptNotPoBox } = this.validate();
    this.setState({
      isStepValid,
      error: isAddressNotPoBox && isAptNotPoBox ? null : 'PO Boxes are not permitted',
    });
  };

  onFieldChange = (fieldKey, fieldValue) =>
    this.props.onFormUpdate(
      {
        [fieldKey]: fieldValue,
      },
      this.validateStep,
    );

  onStateChange = value => this.onFieldChange('state', value);

  handleNextClick = async () => {
    const { address, apt, city, state, postal_code } = this.props.data;
    this.setState({ isLoading: true });
    // Prevent showing "trading approved" popup after setting up a new address info:
    localStorage.setItem('notifiedOnTradingApproved', true);

    try {
      await this.props.updateUserData({ address, address2: apt, city, state, postal_code });
      this.props.onStepForward();
    } catch (err) {
      this.setState({ isLoading: false, isStepValid: false, error: err });
    }
  };

  handleBackClick = () => {
    if (this.props.resetOnBackPress) {
      const { address, apt, city, state, postal_code } = this.initialData;
      this.props.onFormUpdate({ address, apt, city, state, postal_code });
    }
    this.props.onStepBackward();
  };

  render() {
    const {
      formMode,
      data: { address, apt, city, state, postal_code },
    } = this.props;
    const completeWhat = formMode === 'investment' ? 'your investment' : 'your profile';

    return (
      <div className="HomeAddress">
        <CollectInfoMessage completeWhat={completeWhat} />
        <Input
          type="address"
          placeholder="Home Address"
          value={address}
          onChange={value => this.onFieldChange('address', value)}
          autoFocus={this.props.userDevice.isMobile ? false : true}
        />
        <Input
          placeholder="Apt # (optional)"
          value={apt}
          onChange={value => this.onFieldChange('apt', value)}
        />
        <Input
          placeholder="City"
          value={city}
          onChange={value => this.onFieldChange('city', value)}
        />
        <USStatesSelect
          placeholder="State"
          placeholderHidden={true}
          selected={state}
          onChange={this.onStateChange}
          noValidate
        />
        <Input
          type="postal_code"
          placeholder="Zip Code"
          value={postal_code}
          onChange={value => this.onFieldChange('postal_code', value)}
        />
        <Error error={this.state.error} />
        <BackNextButtons
          buttons={{ back: { text: 'back' }, next: { text: 'next' } }}
          loading={this.state.isLoading}
          nextDisabled={!this.state.isStepValid}
          onBackClick={this.handleBackClick}
          onNextClick={this.handleNextClick}
        />
      </div>
    );
  }
}

const mapDispatchToProps = { updateUserData };

export default connect(null, mapDispatchToProps)(withDeviceDetection(HomeAddress));
