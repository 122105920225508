import React, { Component } from 'react';
import ReactSwipeEvents from 'react-swipe-events';
import moment from 'moment';

import { ChartPoint } from './ChartPoint';
import { LineChart } from './LineChart';

import './TimelineChart.css';
import { TABS } from 'components/views/app/Assets/AssetDetails/scenes/AssetValueChart/AssetValueChart.tsx';

export default class TimelineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPoint: null,
    };
    this.minY = this.minX = 0;
    this.maxY = this.maxX = 1;
    this.chartPoints = [];
    this.chartPointChange = this.chartPointChange.bind(this);
    this.chartMouseOut = this.chartMouseOut.bind(this);
    this.onSwiping = this.onSwiping.bind(this);
  }

  componentDidMount() {
    const chartData = this.props.chartData || [];
    this.getChartPointsFromChartData(chartData);
  }

  saveRef = ref => (this.containerNode = ref);

  chartPointChange(pointIndex) {
    this.setState({
      selectedPoint: pointIndex,
    });
    this.props.onChange(pointIndex);
  }

  chartMouseOut(pointIndex) {
    if (this.state.selectedPoint === pointIndex) {
      this.setState({
        selectedPoint: null,
      });
      this.props.onChange(null);
    }
  }

  onSwiping(e, originalX, originalY, currentX, currentY, deltaX, deltaY) {
    let width = this.containerNode.clientWidth || 100;
    let rect = this.containerNode.getBoundingClientRect();
    let newXPos = ((currentX - rect.left) / width) * 100;
    let newIndex = this.state.selectedPoint;
    let sign = Math.sign(deltaX);
    if (sign < 0) {
      while (newIndex > 0 && this.getXPosFromIndex(newIndex) > newXPos) {
        newIndex--;
      }
    } else if (sign > 0) {
      while (newIndex < this.chartPoints.length - 1 && this.getXPosFromIndex(newIndex) < newXPos) {
        newIndex++;
      }
    } else {
      return;
    }
    this.chartPointChange(newIndex);
  }

  getEpoch(date) {
    return moment(date).unix();
  }

  getValues(data) {
    return data.map(x => x.value);
  }

  getEpochDates(data) {
    return data.map(x => this.getEpoch(x.date));
  }

  getMinValues(data) {
    let chartValues = this.getValues(data);
    this.minY = Math.min(...chartValues);
    this.maxY = Math.max(...chartValues);

    let chartDates = this.getEpochDates(data);
    this.minX = Math.min(...chartDates);
    this.maxX = Math.max(...chartDates);
  }

  getPercentFromValue(value) {
    let spread = Math.max(this.maxY - this.minY, 1);
    let distFromMin = value - this.minY;
    return distFromMin / spread;
  }

  getPercentFromDate(date) {
    let epoch = this.getEpoch(date);
    let spread = Math.max(this.maxX - this.minX, 1);
    let distFromMin = epoch - this.minX;
    return distFromMin / spread;
  }

  getYPosFromValue(value) {
    let percent = this.getPercentFromValue(value);
    return percent * 100;
  }

  getYPosFromIndex(index) {
    let point = this.getPointFromIndex(index);
    return point[1];
  }

  getXPosFromDate(date) {
    let percent = this.getPercentFromDate(date);
    return percent * 100;
  }

  getXPosFromIndex(index) {
    let point = this.getPointFromIndex(index);
    return point[0];
  }

  getPointFromIndex(index) {
    const chartPoints = this.chartPoints || [];
    index = Math.min(Math.max(index, 0), chartPoints.length - 1);
    return chartPoints[index] || [];
  }

  getChartPointsFromChartData(chartData) {
    this.getMinValues(chartData);

    this.chartPoints = chartData.map((object, index) => {
      let date = object['date'];
      let value = object['value'];
      let xPos = this.getXPosFromDate(date);
      let yPos = this.getYPosFromValue(value);
      return [xPos, yPos];
    });

    this.bestFitLine = this.chartPoints
      .map((object, index) => {
        if (index === 0) {
          return object;
        }
        let x = object[0];
        let total = 0;
        total += 1 * this.getYPosFromIndex(index - 1);
        total += 1 * this.getYPosFromIndex(index);
        total += 1 * this.getYPosFromIndex(index + 1);
        total += 0.8 * this.getYPosFromIndex(index - 2);
        total += 0.8 * this.getYPosFromIndex(index + 2);
        total += 0.5 * this.getYPosFromIndex(index - 3);
        total += 0.5 * this.getYPosFromIndex(index + 3);
        total += 0.2 * this.getYPosFromIndex(index - 4);
        total += 0.2 * this.getYPosFromIndex(index + 4);
        let yAverage = total / 6;

        if (index % 3 === 0 || index === this.chartPoints.length - 1) {
          return [x, yAverage];
        } else {
          return null;
        }
      })
      .filter(function (point) {
        return point != null;
      });
  }

  componentWillReceiveProps(nextProps) {
    const chartData = nextProps.chartData || [];
    this.getChartPointsFromChartData(chartData);
  }

  render() {
    let dots = this.chartPoints.map((object, index) => {
      let xPos = object[0];
      let yPos = object[1];
      let pointColor = this.state.selectedPoint === index ? 'white' : 'rgba(255,255,255,0)';

      let previousXPos = this.getXPosFromIndex(index - 1);
      let nextXPos = this.getXPosFromIndex(index + 1);
      let leftPadding = (xPos - previousXPos) / 2;
      let rightPadding = (nextXPos - xPos) / 2;
      let totalWidth = leftPadding + rightPadding;

      return (
        <div
          className="TimelineChart-point"
          key={index}
          style={{
            left: xPos - leftPadding + '%',
            width: totalWidth + '%',
          }}
        >
          <ChartPoint
            pointData={index}
            yPos={yPos}
            leftPadding={leftPadding}
            rightPadding={rightPadding}
            chartPointChange={this.chartPointChange}
            chartMouseOut={this.chartMouseOut}
            color={pointColor}
          />
        </div>
      );
    });

    let lines = (
      <LineChart
        points={this.chartPoints}
        lineColor={this.props.activeTab === TABS.SIMILAR_ASSET_SALE ? '#323232' : 'white'}
        lineWidth={2}
      />
    );

    let selectedValueXPos = this.getXPosFromIndex(this.state.selectedPoint);
    let displayLine = this.state.selectedPoint === null ? 'none' : 'block';

    return (
      <ReactSwipeEvents onSwiping={this.onSwiping}>
        <div className="TimelineChart" ref={this.saveRef}>
          <div className="TimelineChart-selectedValue" style={{ left: selectedValueXPos + '%' }}>
            {this.props.pointValue}
          </div>
          <div
            className="TimelineChart-selectedLine"
            style={{
              left: selectedValueXPos + '%',
              display: displayLine,
            }}
          />
          <div className="TimelineChart-dots">{dots}</div>
          <div className="TimelineChart-lines">
            <div className="TimelineChart-lines-all">{lines}</div>
          </div>
        </div>
      </ReactSwipeEvents>
    );
  }
}
