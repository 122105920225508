import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { setTosAgreement } from 'actions';
import { ScreenWrapper, ScreenTop, ScreenBottom } from './styled';
import { SecondaryButtonV2 } from 'components/shared/Buttons';
import { DotsLoader } from 'components/shared/Loaders';

import Tos3Icon from 'components/shared/Icons/Tos3Icon';

interface ITos3Screen {
  onStepForward: Function;
}

const Tos3Screen = ({ onStepForward }: ITos3Screen) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const handleAgreement = () => {
    setLoading(true);
    try {
      const agreedWithLatestTOSAt = new Date().toISOString();
      dispatch(setTosAgreement(agreedWithLatestTOSAt));
      onStepForward();
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <ScreenWrapper>
      <ScreenTop>
        <div className="topWrapper">
          <div className="icon">
            <Tos3Icon />
          </div>
          <div className="title">Enable More Robust Trading</div>
          <div className="subtitle">
            We are transitioning payment services to add additional payment functionality to our platform. 
            This change will take place on or around April 1, 2022. 
            We will notify you at the time your new account is created. 
            By agreeing, you authorize us and our payment services partner, Dwolla, to create a new Dwolla account for you. 
            Dwolla is a partner of Evolve Bank & Trust, member FDIC, and will use Evolve to create your account.
          </div>
        </div>
      </ScreenTop>
      <ScreenBottom>
        <div className="bottomWrapper">
          <p className="footerText">
            By clicking the “AGREE & CONTINUE” button, you acknowledge that you have read and understand, and that you agree to
            <a href="https://www.dwolla.com/legal/tos/" target="_blank" rel="noopener noreferrer">{' Dwolla Terms of Service'}</a>, 
            <a href="https://www.dwolla.com/legal/privacy/#legal-content" target="_blank" rel="noopener noreferrer">{' Privacy Policy'}</a>,
            <a href="https://www.dwolla.com/legal/virtual-account-number-personal-account-demand-deposit-agreement-2/#legal-content" target="_blank" rel="noopener noreferrer">{' Evolve’s Customer Account Terms'}</a>, and 
            <a href="https://evolve.mymortgage-online.com/PrivacyPolicy.html" target="_blank" rel="noopener noreferrer">{' Privacy Policy'}</a>.
          </p>
          <div className="button">
            <SecondaryButtonV2
              onClick={handleAgreement}
              fullWidth
              style={{ height: '9vh' }}
            >
              {loading ? <DotsLoader color="#fff" /> : 'Agree & Continue'}
            </SecondaryButtonV2>
          </div>
        </div>
      </ScreenBottom>
    </ScreenWrapper>
  );
};

export default Tos3Screen;
