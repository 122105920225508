import React, { useCallback, useMemo, useState } from 'react';
import { BackNextButtons } from 'components/shared/Buttons';
import { Input } from 'components/shared/Inputs';
import { hasInvalidLength } from 'utils/validations';
import { ValidationWarning } from 'components/shared/ValidationWarning';

import './EmployerInformation.scss';
import { isEmpty } from 'utils';
import { putEmploymentDetails } from 'services/CustomerApi';
import Bugsnag from '@bugsnag/js';
import { useDispatch, useSelector } from 'react-redux';
import { useGeneralErrorAlert } from 'hooks';
import { setEmploymentDetails } from 'actions/customer';

const MIN_LENGTH = 3;
const MAX_LENGTH = 64;

const EmployerInformation = ({ onStepForward, onStepBackward, data, onFormUpdate }) => {
  const [loading, setLoading] = useState(false);
  const { customer } = useSelector(state => state.Auth.user);
  const showErrorAlert = useGeneralErrorAlert();
  const { employer, occupationOrJobTitle, employmentStatus } = data;
  const dispatch = useDispatch();

  const validateField = useCallback((value, name) => {
    if (name === 'employer') {
      return hasInvalidLength(value, 'Employer name', MIN_LENGTH, MAX_LENGTH) || '';
    }
    if (name === 'occupationOrJobTitle') {
      return hasInvalidLength(value, 'Occupation', MIN_LENGTH, MAX_LENGTH) || '';
    }
  }, []);

  const [errors, setErrors] = useState({
    employer: employer ? validateField(employer, 'employer') : undefined,
    occupationOrJobTitle: occupationOrJobTitle
      ? validateField(occupationOrJobTitle, 'occupationOrJobTitle')
      : undefined,
  });

  const handleOnChange = useCallback(
    (value, name) => {
      onFormUpdate({ [name]: value });

      const validationErrors = { ...errors };
      validationErrors[name] = validateField(value, name);
      setErrors(validationErrors);
    },
    [onFormUpdate, validateField, errors],
  );

  const hasErrors = useMemo(() => Object.values(errors).some(value => value), [errors]);

  const handleNextStep = useCallback(() => {
    if (!hasErrors) {
      setLoading(true);
      const employmentDetails = {
        employer,
        occupationOrJobTitle,
        status: employmentStatus,
      };
      putEmploymentDetails(
        {
          employer,
          occupationOrJobTitle,
          status: employmentStatus,
        },
        customer.id,
      )
        .then(() => {
          dispatch(setEmploymentDetails(employmentDetails));
          onStepForward();
        })
        .catch(error => {
          showErrorAlert();
          Bugsnag.notify(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    onStepForward,
    hasErrors,
    employer,
    employmentStatus,
    occupationOrJobTitle,
    customer.id,
    showErrorAlert,
    dispatch,
  ]);

  const isStepDisabled = useMemo(
    () => isEmpty(employer) || isEmpty(occupationOrJobTitle) || hasErrors,
    [employer, occupationOrJobTitle, hasErrors],
  );

  return (
    <div className="employer-information">
      <h3 className="employer-information__title">Employer Information</h3>
      <div className="employer-information__input">
        <Input
          description="Employer name"
          placeholder="XYZ Company"
          text="text"
          value={employer}
          valid={!errors.employer}
          onChange={value => handleOnChange(value, 'employer')}
        />
        <ValidationWarning valid={!errors.employer} message={errors.employer} />
      </div>
      <div className="employer-information__input">
        <Input
          description="Occupation / job title"
          placeholder="Head of Sales"
          text="text"
          value={occupationOrJobTitle}
          valid={!errors.occupationOrJobTitle}
          onChange={value => handleOnChange(value, 'occupationOrJobTitle')}
        />
        <ValidationWarning
          valid={!errors.occupationOrJobTitle}
          message={errors.occupationOrJobTitle}
        />
      </div>
      <BackNextButtons
        buttons={{ back: { text: 'back' }, next: { text: 'next' } }}
        nextDisabled={isStepDisabled}
        loading={loading}
        onBackClick={onStepBackward}
        onNextClick={handleNextStep}
      />
    </div>
  );
};

export default EmployerInformation;
