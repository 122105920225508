import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Price } from 'components/shared/Price';
import { Link, withRouter } from 'react-router-dom';

import './InvestmentItem.scss';
import { goToAssetItem } from '../../../RootRouter';
import { track } from 'services/analytics';
import { SEGMENT_EVENTS, SEGMENT_ACTIONS, SEGMENT_CATEGORIES } from 'constants/analytics';
import { getAssetSegmentStatus } from 'actions/assets';

class InvestmentItem extends Component {
  static propTypes = { investment: PropTypes.object.isRequired };

  state = {
    asset: null,
    tradingDates: [],
  };

  componentDidMount() {
    const asset = this.props.assetList.find(
      asset =>
        asset.financialInstrument.id === this.props.investment.financialInstrument.id ||
        asset.id === this.props.investment.asset,
    );
    if (!asset) return;
    this.setState({ asset });
  }

  handleSoldAssetClick = () => {
    const { investment, user } = this.props;
    const { ticker, category, display_name, shares } = investment;
    track(SEGMENT_EVENTS.PORTFOLIO_SOLD_INVESTMENT_TO_ASSET, {
      action: SEGMENT_ACTIONS.OPEN,
      category: SEGMENT_CATEGORIES.ASSET_2_0,
      assetTicker: ticker,
      assetCategory: category,
      assetStatus: getAssetSegmentStatus(investment, user),
      assetName: display_name,
      numberOfSharesSold: shares,
    });
  };

  handleHeldAssetClick = () => {
    const { investment, user } = this.props;
    const { ticker, category, display_name, investmentDisplay, pricePerUnit } = investment;
    track(SEGMENT_EVENTS.PORTFOLIO_INVESTMENT_TO_ASSET, {
      action: SEGMENT_ACTIONS.OPEN,
      category: SEGMENT_CATEGORIES.ASSET_2_0,
      assetTicker: ticker,
      assetCategory: category,
      assetState: getAssetSegmentStatus(investment, user),
      assetName: display_name,
      lastTradeValue: pricePerUnit.session.latestFilled || pricePerUnit.session.lastClose,
      avgCost: Number(investmentDisplay.averageCost.toFixed(2)),
      value: investmentDisplay.currentTotal,
      valuePerShare: investmentDisplay.currentPricePerShare,
      gainLoss: (Number(investmentDisplay.gainLoss) || 0) / 100,
      numberOfSharesOwned: investmentDisplay.shares,
      numberOfSharesSold: investmentDisplay.sharesSold,
    });
  };

  renderActiveInvestment() {
    const { asset } = this.state;
    const { location, investment } = this.props;
    const { investmentDisplay } = investment;

    if (investment.type !== 'active') return null;

    return (
      <Link
        to={goToAssetItem(asset, location)}
        onClick={this.handleHeldAssetClick}
        className={'InvestmentItem InvestmentItem-goToAsset'}
      >
        <div className="InvestmentItem-image">
          <img src={asset.portal_image} alt="Asset thumbnail" />
        </div>
        <div className="InvestmentItem-content">
          <div className="InvestmentItem-header">
            {asset.ticker} - {asset.display_name}
          </div>
          <div>
            Cost Basis: <Price keepZeros price={investmentDisplay.totalCost} />
            &nbsp;({investmentDisplay.shares} Share{investmentDisplay.shares !== 1 ? 's' : ''})
          </div>
          <div>
            Avg Cost: <Price keepZeros price={investmentDisplay.averageCost} /> per share
          </div>
          <div>
            Value:&nbsp;
            <Price keepZeros price={investmentDisplay.currentTotal} />
            &nbsp;(
            <Price keepZeros price={investmentDisplay.currentPricePerShare} /> per share)
          </div>
          <div>
            Your Gain/Loss:&nbsp;
            {asset.trading ? (
              <span
                className={`InvestmentItem-${investmentDisplay.gainLoss >= 0 ? 'gain' : 'loss'}`}
              >
                {investmentDisplay.gainLoss > 0 ? '+' : ''}
                {investmentDisplay.gainLoss}%
              </span>
            ) : (
              <span className="InvestmentItem-nogainloss">N/A</span>
            )}
          </div>
        </div>
      </Link>
    );
  }

  renderExitedInvestment() {
    const { asset } = this.state;
    const { location, investment } = this.props;

    if (investment.type !== 'exited') return null;

    return (
      <Link
        onClick={this.handleSoldAssetClick}
        to={goToAssetItem(asset, location)}
        className={'InvestmentItem InvestmentItem-goToAsset InvestmentItem--exited'}
      >
        <div className="InvestmentItem-image">
          <img src={asset.portal_image} alt="Asset thumbnail" />
        </div>
        <div className="InvestmentItem-content">
          <div className="InvestmentItem-header">
            {asset.ticker} - {asset.display_name}
          </div>
          <div>
            EXITED ({investment.shares} SHARE{investment.shares !== 1 ? 'S' : ''}) SOLD
          </div>
        </div>
      </Link>
    );
  }

  render() {
    const { investment } = this.props;
    const { asset } = this.state;
    if (!this.props.assetList.length || !this.state.asset || !investment || !asset) return null;

    return (
      <>
        {this.renderActiveInvestment()}
        {this.renderExitedInvestment()}
      </>
    );
  }
}

const mapStateToProps = ({ Assets, Auth, Investments }) => ({
  assetList: Assets.assetList,
  tradingDates: Assets.tradingDates,
  user: Auth.user,
});

export default connect(mapStateToProps)(withRouter(InvestmentItem));
