import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';

import { Price } from 'components/shared/Price';

import { TRANSFER_TYPE } from 'constants/main';
import { OrderStatus, OrderContext } from 'types/orders';

import './ReceiptsList.scss';

class ReceiptsList extends Component {
  transformType(type) {
    return type === TRANSFER_TYPE.DEPOSIT
      ? TRANSFER_TYPE.TRANSFER.toUpperCase()
      : type.toUpperCase();
  }

  transformSourceName(sourceName) {
    return sourceName === 'balance' ? 'Rally account' : sourceName;
  }

  concatTransferDescription(transfer) {
    const { product_titles, shares_count, asset_ticker, amount } = transfer;
    let price = <Price price={transfer.amount} keepZeros={true} />;
    let text = ' transferred ';

    switch (transfer.type) {
      case TRANSFER_TYPE.DEPOSIT:
        text += 'to your Rally account';
        break;
      case TRANSFER_TYPE.WITHDRAWAL:
        text += 'to your bank';
        break;
      case TRANSFER_TYPE.INVESTMENT:
        const s = shares_count > 1 ? 's' : '';
        return `${shares_count} Share${s} of ${asset_ticker} purchased for $${amount}`;
      case TRANSFER_TYPE.MERCHANDISE:
        const productTitleString = product_titles.length ? product_titles.join(', ') : 'A product';
        return `${productTitleString} purchased for $${amount}`;
      case TRANSFER_TYPE.REFUND:
        text += 'from Rally';
        break;
      case TRANSFER_TYPE.TRADE:
        text += 'to ' + transfer.source_name;
        break;
      default:
        break;
    }
    return (
      <span>
        {price} {text}
      </span>
    );
  }

  renderTransferItem = (transfer, index) => {
    if (transfer.type === TRANSFER_TYPE.ORDER) return null;

    const investment = this.props.investments.find(investment => investment.id === transfer.id);
    const promoCodeValue = investment && !!+investment.promo_value ? investment.promo_value : null;

    return (
      <div className="Receipt" key={transfer.id + index}>
        <div className="Receipt-type">{this.transformType(transfer.type)}</div>
        <div className="Receipt-description">
          <span>{this.concatTransferDescription(transfer)}</span>
        </div>
        <div className="Receipt-source">
          <span>{this.transformSourceName(transfer.source_name)}</span>
          {promoCodeValue && (
            <span>
              &nbsp; (<Price price={promoCodeValue} /> Promo Applied)
            </span>
          )}
        </div>
        <div className="Receipt-footer">
          <span className="Receipt-date">
            {moment(transfer.created, moment.ISO_8601).format('hh:mma - MMM DD,YYYY')}
          </span>
          <span className={`Receipt-status ${transfer.status}`}>{transfer.status}</span>
        </div>
      </div>
    );
  };

  renderOrderItem = (order, index) => {
    // do not display confirmed_matched_status: unmatched orders in transaction history
    if (order.orderStatus === OrderStatus.SUBMITTED) {
      return null;
    }

    const isBid = order.taxonomy?.context === OrderContext.BID;
    const type = isBid ? 'purchase' : 'sale';
    const purchasedSold = isBid ? 'purchased' : 'sold';
    const amount = order.amount || order.pricing?.debited || order.pricing?.credited;
    const sharesCount = order.shares_count || order.units?.filled;
    const orderPrice =
      amount / sharesCount || order.pricing?.unit || order.paid_price_per_share || order.price;

    const totalPrice = Number(order.shares_count) * Number(orderPrice) || amount;
    const s = Number(sharesCount) > 1 ? 's' : '';

    return (
      <div className="Receipt" key={order.id + index}>
        <div className="Receipt-type">
          <span className="Receipt-dark">Trading Window - </span>
          <span className={`Receipt-${type}`}>{type}</span>
        </div>
        <div className="Receipt-description">
          {sharesCount} Share
          {s} of {order.ticker} {purchasedSold} for <Price price={totalPrice} keepZeros={true} />
        </div>
        <div className="Receipt-description">
          Executed at <Price price={orderPrice} keepZeros={true} /> per share
        </div>
        <div className="Receipt-transaction">Transaction ID: {order.id.substr(0, 8)}</div>
        <div className="Receipt-footer">
          <span className="Receipt-date">
            {moment(order.created, moment.ISO_8601).format('hh:mma - MMM DD,YYYY')}
          </span>
          <span className={`Receipt-status ${order.status}`}>{order.status}</span>
        </div>
      </div>
    );
  };

  render() {
    const { receiptsList } = this.props;

    return (
      <div className="ReceiptsList">
        {receiptsList.length > 0 &&
          receiptsList.map((receiptsItem, index) => {
            if (receiptsItem?.type === TRANSFER_TYPE.ORDER) {
              return this.renderOrderItem(receiptsItem, index);
            } else {
              return this.renderTransferItem(receiptsItem, index);
            }
          })}
      </div>
    );
  }
}

const mapStateToProps = ({ Assets, Investments }) => ({
  assetList: Assets.assetList,
  investments: Investments.user_investments,
});

export default connect(mapStateToProps)(ReceiptsList);
