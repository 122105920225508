import useAlert from './useAlert';

const useGeneralErrorAlert = () => {
  const showAlert = useAlert();
  const showGeneralErrorAlert = (onClose?: () => void) =>
    showAlert('Oops!', 'Something went wrong. Please try again.', onClose);

  return showGeneralErrorAlert;
};

export default useGeneralErrorAlert;
