import {
  CTAButton,
  HomeBannerContainer,
  HomeBannerHeading,
  HomeBannerImage,
  HomeBannerParagraph,
} from './styled';
import React, { useEffect } from 'react';
import { CloseButton } from 'components/shared/Buttons';
import { BannersItem } from 'services/HomeAPI.types';
import { goToAssetItem } from 'components/RootRouter';
import { ConditionalSimpleComponent } from 'types/components';
import useLocalStorage from 'hooks/useLocalStorage';
import { useTracking } from 'hooks/useTracking';
import withDeviceDetection from '../../../../hoc/withDeviceDetection';

export const VISIBILITY = {
  VISIBLE: 'VISIBLE',
  HIDDEN: 'HIDDEN',
};

const useBannerVisibility = (text: string) => {
  const [bannerVisibility, setBannerVisibility] = useLocalStorage('bannerVisibility');
  const [bannerText, setBannerText] = useLocalStorage('bannerText');

  useEffect(() => {
    if (bannerText !== text) {
      setBannerVisibility(VISIBILITY.VISIBLE);
      setBannerText(text);
    }
  }, [text, bannerText, setBannerText, setBannerVisibility]);

  const hideBanner = () => {
    setBannerVisibility(VISIBILITY.HIDDEN);
  };

  return {
    bannerVisibility,
    hideBanner,
  };
};

const HomeBanner: ConditionalSimpleComponent<{ data: any; assets: any; userDevice: any }> = ({
  data,
  assets,
  userDevice,
}) => {
  const {
    enabled,
    items: [item],
  } = data[0] ?? {};

  const { bannerVisibility, hideBanner } = useBannerVisibility(item?.content?.subheader?.text);

  const { homeTracking } = useTracking();
  useEffect(() => {
    if (bannerVisibility) {
      homeTracking.homeBannerViewed({
        bannerTitle: item?.content?.header?.text,
        bannerCTA: item?.content?.cta?.text ?? 'Learn More',
        bannerSubHeader: item?.content?.subheader?.text,
      });
    }
  }, [bannerVisibility, homeTracking, item]);

  const handleCloseBanner = () => {
    homeTracking.homeBannerClosed({
      bannerTitle: item?.content?.header?.text,
      bannerCTA: item?.content?.cta?.text ?? 'Learn More',
      bannerSubHeader: item?.content?.subheader?.text,
    });

    hideBanner();
  };

  const goTo = (item: BannersItem): any => {
    if (item?.content?.cta?.reference?.type === 'LINK' && userDevice.isMobile) {
      return {
        pathname: `/app/external-link`,
        state: { url: item?.content?.cta?.reference?.url },
      };
    }
    if (item?.content?.cta?.reference?.type === 'LINK') {
      return { pathname: item?.content?.cta?.reference?.url };
    }
    const asset = assets?.find(
      (asset: any) => asset.id === item?.content?.cta?.reference?.assetId,
    ) as any;

    return goToAssetItem(asset, { pathname: '/app/home' });
  };

  return enabled ? (
    <HomeBannerContainer show={bannerVisibility === VISIBILITY.VISIBLE}>
      <CloseButton onClick={() => handleCloseBanner()} />
      <div>
        <HomeBannerHeading>{item?.content?.header?.text}</HomeBannerHeading>
        <HomeBannerParagraph>{item?.content?.subheader?.text}</HomeBannerParagraph>
        <CTAButton
          show={item?.content?.cta?.enabled}
          isBanner
          to={goTo(item)}
          target={
            item?.content?.cta?.reference?.type === 'LINK' && !userDevice.isMobile ? '_self' : ''
          }
          onClick={() =>
            homeTracking.homeBannerTapped({
              bannerTitle: item?.content?.header?.text,
              bannerCTA: item?.content?.cta?.text ?? 'Learn More',
              bannerSubHeader: item?.content?.subheader?.text,
            })
          }
        >
          {item?.content?.cta?.text ? item?.content?.cta?.text : 'Learn More'}
        </CTAButton>
      </div>
      <HomeBannerImage src={item?.content?.image?.link} />
    </HomeBannerContainer>
  ) : null;
};

export default withDeviceDetection(HomeBanner);
