import React, { Component } from 'react';
import { connect } from 'react-redux';

import { AdminButton } from 'components/shared/Buttons/AdminButton';
import { DownloadAppButton } from 'components/shared/Buttons/DownloadAppButton';

import './ContactPage.css';

/* eslint-disable jsx-a11y/anchor-has-content */

class ContactPage extends Component {
  render() {
    return (
      <div className="ContactPage">
        <div className="menuContainer">
          <h2>Contact Rally</h2>
          <p>
            <b>Rally</b> HQ is based in the Soho District of New York City.
          </p>
          <p>Have questions? Need Info? Want to grab coffee?</p>
          <div className="contactEmailContainer">
            <p>
              <span>Text us:</span>&nbsp;<a href="sms:347-952-8058">347.952.8058</a>
            </p>
            <p>
              <span>Email us:</span> <a href="mailto:hello@rallyrd.com">hello@rallyrd.com</a>
            </p>
            <div className="socialNetworkicons">
              <a
                href="https://www.instagram.com/rally/"
                target="_blank"
                rel="noopener noreferrer"
              />
              <a href="https://medium.com/@onrallyrd" target="_blank" rel="noopener noreferrer" />
              <a
                href="https://www.linkedin.com/company/rally-rd."
                target="_blank"
                rel="noopener noreferrer"
              />
            </div>
            <div className="prPolicy">
              <a href="https://www.rallyrd.com/privacy" target="_blank" rel="noopener noreferrer">
                <AdminButton text="PRIV. POLICY" />
              </a>
              <a
                href="https://www.rallyrd.com/disclaimer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AdminButton text="DISCLAIMER" />
              </a>
            </div>
            <div className="ContactPage-cta">
              <DownloadAppButton theme="light" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContactPage);
