import React from 'react';

const SearchIcon = () => (
  <svg width="17" height="17" viewBox="0 0 513 513" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M221.102 64.9827C190.032 64.9827 159.661 74.1958 133.827 91.4571C107.994 108.718 87.8595 133.252 75.9697 161.957C64.0799 190.661 60.969 222.247 67.0304 252.719C73.0917 283.192 88.0531 311.183 110.023 333.152C131.992 355.122 159.983 370.083 190.455 376.144C220.928 382.206 252.513 379.095 281.218 367.205C309.922 355.315 334.456 335.181 351.717 309.347C368.979 283.514 378.192 253.142 378.192 222.073C378.189 180.411 361.638 140.456 332.178 110.996C302.719 81.5367 262.764 64.9853 221.102 64.9827V64.9827Z"
      strokeWidth="32"
      strokeMiterlimit="10"
    />
    <path
      d="M338.302 339.273L448.012 448.983"
      strokeWidth="32"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default SearchIcon;
