import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Fullpage } from 'components/shared/Fullpage';
import { CloseButton } from 'components/shared/Buttons/CloseButton';
import { TextButton } from 'components/shared/Buttons/TextButton';
import { ImageUpload } from 'components/shared/Inputs/ImageUpload';
import { Spinner } from 'components/shared/Spinner';

import { getDwollaCustomerStatus } from 'actions';
import { setPageTitle, isRunningOnRallyAndroidClient } from 'utils';

import { requestDocumentUploadS3URL, uploadDocumentToS3URL } from 'services/UsersAPI';

import './DocumentUpload.scss';

class DocumentUpload extends Component {
  state = {
    isUploading: false,
    uploadCompleted: false,
    uploadFailed: false,
    documentType: null,
  };

  componentDidMount() {
    setPageTitle('Document Upload');
    isRunningOnRallyAndroidClient({ checkNativeBridge: true }) &&
      window.AndroidBridge.askCameraPermission();
  }

  onDocumentDropped = async file => {
    this.setState({ isUploading: true, uploadFailed: false });

    try {
      const presignedS3URL = await requestDocumentUploadS3URL({
        file_name: file.name,
        content_type: file.type,
        document_type: this.state.documentType,
      });

      if (presignedS3URL.url) {
        const s3UploadResponse = await uploadDocumentToS3URL(file, presignedS3URL.url);
        if (s3UploadResponse.status === 200)
          this.setState({ uploadCompleted: true, isUploading: false });
      } else {
        throw new Error('No presigned URL returned');
      }
    } catch (e) {
      this.setState({ uploadFailed: true, isUploading: false });
    }
  };

  onDocumentTypeSelect = ({ target: { name } }) => this.setState({ documentType: name });

  onDocumentTypeReset = () => this.setState({ documentType: null });

  onCloseHandler = () => {
    this.props.history.push('/app/');
  };

  render() {
    const { isUploading, uploadCompleted, uploadFailed, documentType } = this.state;
    const dropzoneDisplay = !isUploading && !uploadCompleted ? 'shown' : 'hidden';
    const spinnerDisplay = isUploading ? 'shown' : 'hidden';
    const successDisplay = uploadCompleted && !isUploading ? 'shown' : 'hidden';

    const title = !uploadCompleted ? 'Verify Your Identity' : 'Upload Complete!';
    const description = !uploadCompleted ? (
      <span>
        To complete this process, we will need you to upload a picture or scan of your license or
        government issued I.D. This image will remain encrypted and will NEVER EVER be shared for
        any purpose other than verification of your account details
      </span>
    ) : (
      <span>
        Your photo I.D. has been uploaded successfully. You will receive a notification once you
        have been approved (usually within 2-3 days). If you have any questions about this process,{' '}
        <a href="mailto:hello@rallyrd.com">email us here (hello@rallyrd.com)</a>
      </span>
    );

    return (
      <Fullpage>
        <div className="DocumentUpload">
          <CloseButton onClickHandler={this.onCloseHandler} />
          <div className="DocumentUpload-wrapper">
            <h2 className="DocumentUpload-title">{title}</h2>
            <p className="DocumentUpload-description">{description}</p>
            {uploadFailed && (
              <p className="DocumentUpload-description-failure">
                Something went wrong, please try again
              </p>
            )}

            <div className={'DocumentUpload-dropzone ' + dropzoneDisplay}>
              {documentType ? (
                <>
                  <ImageUpload
                    onDrop={this.onDocumentDropped}
                    isRallyAndroidClient={isRunningOnRallyAndroidClient()}
                  />
                  <TextButton onClick={this.onCloseHandler}>Cancel</TextButton>
                </>
              ) : (
                <div className="DocumentUpload-type-select">
                  <button name="passport" onClick={this.onDocumentTypeSelect}>
                    Passport
                  </button>
                  <button name="idCard" onClick={this.onDocumentTypeSelect}>
                    ID Card
                  </button>
                  <button name="license" onClick={this.onDocumentTypeSelect}>
                    License
                  </button>
                </div>
              )}
            </div>
            <div className={'DocumentUpload-spinner ' + spinnerDisplay}>
              <div className="DocumentUpload-spinner-image">
                <Spinner />
              </div>
              <p className="DocumentUpload-description">Uploading Document...</p>
            </div>
            <div className={'DocumentUpload-success ' + successDisplay}>
              <TextButton text="OKAY" arrow="forward" onClick={this.onCloseHandler} />
            </div>
          </div>
        </div>
      </Fullpage>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  getDwollaCustomerStatus: () => dispatch(getDwollaCustomerStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentUpload);
