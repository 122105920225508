import styled from '@emotion/styled';

export type OrderBookType = {
  isLiveTrading: boolean;
  priceShiftPercentage: number;
};

export const OrderBookWrapper = styled.div<OrderBookType>(
  ({ theme, isLiveTrading, priceShiftPercentage }) => ({
    backgroundColor: isLiveTrading ? 'white' : '#323232',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: '16px',
    padding: 20,
    boxSizing: 'border-box',
    textTransform: 'uppercase',
    color: isLiveTrading ? '#323232' : 'white',
    position: 'relative',
    [theme.breakpoints.sm]: {
      height: '84.2vh',
      padding: 30,
    },
    '.header': {
      color: isLiveTrading ? '#1C1B1B' : 'white',
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: 10,
      marginBottom: 10,
      borderBottom: isLiveTrading ? '1px solid #323232' : '1px solid #666',
      flexShrink: 0,
      flexGrow: 0,
      width: '100%',
      '.left': {
        fontFamily: 'Gotham Bold',
        fontSize: '1em',
        textTransform: 'uppercase',
      },
      '.right': {
        fontSize: '1em',
        textTransform: 'uppercase',
        color: isLiveTrading ? '#1C1B1B' : '#9BA0A5',
      },
    },
    '.loader': {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
    },
    '.bidAskContainer': {
      flexGrow: 1,
      width: '100%',
      position: 'relative',
      overflow: 'hidden',
      height: '100%',
      '.bidAskWrapper': {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '100%',
        paddingBottom: '10vh',
        boxSizing: 'border-box',
        '.item': {
          position: 'relative',
          width: '100%',
          '.itemTitle': {
            width: 'auto',
            fontSize: '0.9em',
            color: '#9BA0A5',
            '.times': {
              padding: '0 .7em',
              fontSize: '1em',
              textTransform: 'none',
            },
            strong: {
              fontSize: '1.2em',
              color: isLiveTrading ? '#323232' : 'white',
            },
          },
          '.label': {
            position: 'absolute',
            fontSize: '0.8em',
            top: '50%',
            transform: 'translate(calc(0% + 15px), -50%)',
            color: '#9BA0A5',
          },
          '&.ask': {
            '.label': {
              transform: 'translate(calc(-100% - 15px), -50%)',
            },
          },
        },
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        pointerEvents: 'none',
        background: isLiveTrading
          ? 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)'
          : 'linear-gradient(180deg, rgba(50,50,50,0) 0%, rgba(50,50,50,1) 100%)',
        zIndex: 1,
      },
      '&.reverse': {
        '.bidAskWrapper': {
          flexDirection: 'column-reverse',
          paddingBottom: 0,
          paddingTop: '10vh',
        },
        '&:before': {
          top: 'auto',
          bottom: 0,
          background: isLiveTrading
            ? 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)'
            : 'linear-gradient(0deg, rgba(50,50,50,0) 0%, rgba(50,50,50,1) 100%)',
        },
      },
      '.item': {
        margin: '12px 5px',
      },
    },
    '.lastPrice': {
      flexShrink: 0,
      flexGrow: 0,
      width: '100%',
      '.title': {
        width: '100%',
        position: 'relative',
        span: {
          position: 'relative',
          display: 'inline-block',
          backgroundColor: isLiveTrading ? 'white' : '#323232',
          color: isLiveTrading ? '#9BA0A5' : '#737373',
          zIndex: 1,
          padding: '10px 15px',
          fontSize: '0.9em',
        },
        '&:before': {
          content: '""',
          position: 'absolute',
          top: '50%',
          left: 0,
          transform: 'translateY(-50%)',
          height: 1,
          width: '100%',
          backgroundColor: isLiveTrading ? '#323232' : '#666',
        },
      },
      '.Price': {
        fontFamily: 'Bebasneue',
        display: 'block',
        fontSize: '6.4vh',
        marginLeft: '-0.2em',
        lineHeight: 1,
        paddingTop: '0.1em',
      },
    },
    '.info': {
      flexShrink: 0,
      flexGrow: 0,
      width: '100%',
      position: 'relative',
      fontSize: '0.8em',
      color: '#9BA0A5',
      '& > span': {
        position: 'relative',
        display: 'inline-block',
        backgroundColor: isLiveTrading ? 'white' : '#323232',
        zIndex: 1,
        padding: 15,
        strong: {
          fontFamily: 'Gotham Bold',
          color: priceShiftPercentage >= 0 ? theme.colors.newGreen : theme.colors.pastelRed,
        },
        '.when': {
          color: isLiveTrading ? '#9BA0A5' : 'white',
        },
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: 0,
        transform: 'translateY(-50%)',
        height: 1,
        width: '100%',
        backgroundColor: isLiveTrading ? '#323232' : '#666',
      },
    },
    '.footer': {
      flexShrink: 0,
      flexGrow: 0,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '.button': {
        fontSize: 13,
        padding: '5px 10px',
        height: '2.3em',
        margin: 5,
        width: '100%',
        maxWidth: '11.5em',
        borderRadius: '1.15em',
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        color: isLiveTrading ? '#1C1B1B' : 'white',
        textTransform: 'uppercase',
        cursor: 'pointer',
        '&.cta': {
          backgroundColor: '#1C1B1B',
          color: isLiveTrading ? 'white' : '#aaa',
        },
      },
    },
    '.Popup': {
      color: '#1C1B1B',
      fontFamily: 'Gotham Book',
      textTransform: 'none',
      '&-contents': {
        textAlign: 'left',
        overflowY: 'auto',
        justifyContent: 'flex-start',
        padding: '5px 15px 15px',
        'header, footer': {
          width: '100%',
        },
      },
      '.iframed-hiw': {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        iframe: {
          border: 'none',
        },
        '.close-button': {
          fontSize: 13,
          padding: '5px 10px',
          height: '2.3em',
          margin: 5,
          width: '100%',
          maxWidth: '11.5em',
          borderRadius: '1.15em',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: 'none',
          textTransform: 'uppercase',
          cursor: 'pointer',
          backgroundColor: '#1C1B1B',
          color: 'white',
        },
        '.DotsLoader': {
          position: 'absolute',
          left: 0,
          top: 0,
          height: '100%',
          width: '100%',
          background: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
      header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #1C1B1B',
        padding: '15px 0',
        '.close': {
          fontSize: 14,
          background: 'none',
          border: 'none',
          cursor: 'pointer',
        },
      },
      section: {
        width: '100%',
      },
      'h2, h3': {
        fontSize: 16,
        padding: '15px 0',
        margin: 0,
      },
      '.trading-now': {
        marginLeft: '10px',
        position: 'relative',
        top: '-3px',
        borderRadius: '20px',
        color: 'white',
        fontWeight: 'bold',
        padding: '4px 10px',
        textTransform: 'uppercase',
        backgroundColor: theme.colors.mediumGreen,
        fontFamily: 'Gotham Bold',
        fontSize: '9px',
      },
      '.title': {
        padding: 0,
      },
      p: {
        fontSize: 14,
        margin: 0,
        lineHeight: 1.5,
      },
      strong: {
        fontFamily: 'Gotham Bold',
      },
      footer: {
        color: '#aaa',
        fontSize: 12,
        paddingTop: 15,
        button: {
          color: theme.colors.newGreen,
          background: 'none',
          border: 'none',
          fontSize: 'inherit',
          padding: 0,
          cursor: 'pointer',
        },
      },
    },
  }),
);
