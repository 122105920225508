import React, { Component } from 'react';
import './Chat.css';

export class Chat extends Component {
  render() {
    return (
      <span className="ChatIcon">
        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="42" viewBox="0 0 45 42">
          <path d="M40.232,35.950 L28.022,35.950 L22.571,41.361 L17.120,35.950 L4.910,35.950 C2.659,35.950 0.834,34.138 0.834,31.903 L0.834,4.929 C0.834,2.694 2.659,0.882 4.910,0.882 L40.232,0.882 C42.482,0.882 44.307,2.694 44.307,4.929 L44.307,31.903 C44.307,34.138 42.482,35.950 40.232,35.950 ZM41.590,6.277 C41.590,4.787 40.374,3.580 38.873,3.580 L6.268,3.580 C4.768,3.580 3.551,4.787 3.551,6.277 L3.551,30.555 C3.551,32.044 4.768,33.252 6.268,33.252 L18.478,33.252 L22.571,37.315 L26.663,33.252 L38.873,33.252 C40.374,33.252 41.590,32.044 41.590,30.555 L41.590,6.277 ZM34.798,14.369 L10.344,14.369 C9.593,14.369 8.985,13.766 8.985,13.021 C8.985,12.276 9.593,11.672 10.344,11.672 L34.798,11.672 C35.548,11.672 36.156,12.276 36.156,13.021 C36.156,13.766 35.548,14.369 34.798,14.369 ZM15.778,19.765 L29.363,19.765 C30.114,19.765 30.722,20.369 30.722,21.113 C30.722,21.858 30.114,22.462 29.363,22.462 L15.778,22.462 C15.028,22.462 14.420,21.858 14.420,21.113 C14.420,20.369 15.028,19.765 15.778,19.765 Z" />
        </svg>
      </span>
    );
  }
}
