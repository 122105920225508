import styled from '@emotion/styled';

export const ScreenWrapper = styled.div(
  () => ({
    width: '100%',
    height: '100%',
    color: '#323232',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 16,
    padding: '2vh 0!important',
  })
);

export const ScreenTop = styled.div(
  ({ theme }) => ({
    width: '100%',
    backgroundColor: 'white',
    padding: 20,
    boxSizing: 'border-box',
    fontFamily: 'Gotham Book, sans-serif',
    overflow: 'auto',
    '.topWrapper': {
      margin: 'auto',
      maxWidth: '450px',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      '.icon': {
        marginBottom: 40,
      },
      '.title': {
        fontFamily: 'Gotham Bold, sans-serif',
        fontSize: '1.25em',
        marginBottom: 15,
        textAlign: 'left',
      },
      '.subtitle': {
        fontSize: '0.88em',
        marginBottom: 15,
        textAlign: 'left',
        lineHeight: 1.4,
        color: theme.colors.grey,
      },
    }
  })
);

export const ScreenBottom = styled.div(
  ({ theme }) => ({
    width: '100%',
    padding: 20,
    boxSizing: 'border-box',
    fontFamily: 'Gotham Regular, sans-serif',
    '.bottomWrapper': {
      margin: 'auto',
      maxWidth: '450px',
      '.footerText': {
        fontSize: '.65em',
        color: theme.colors.grey,
        lineHeight: 1.4,
        marginTop: 0,
        marginBottom: 30,
      }
    },
    'SecondaryButtonV2': {
      fontSize: '4vh',
    }
  })
);
