import React, { Component } from 'react';
import Popup from 'components/shared/Popup';
import { Input } from 'components/shared/Inputs/Input';
import { CloseButton } from 'components/shared/Buttons/CloseButton';
import Button from 'components/shared/Buttons/Button';

import * as uuid from 'uuid';
import { changePassword, login } from 'services/AuthAPI';

import { ModalViewContext } from 'components/shared/Modals/Modal';
import './ChangePasswordPopup.scss';
import { connect } from 'react-redux';
import AlertModal from 'components/shared/Modals/AlertModal';
import Bugsnag from '@bugsnag/js';

const initialState = {
  old_password: '',
  new_password1: '',
  new_password2: '',
  validationErrors: {},
  showSuccessMessage: false,
};

class ChangePasswordPopup extends Component {
  state = initialState;

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      this.setState(initialState);
    }
  }

  onFieldChange = (fieldName, fieldValue) => {
    this.setState({
      [fieldName]: fieldValue,
      validationErrors: { ...this.state.validationErrors, [fieldName]: null },
    });
  };

  validateField = fieldName => {
    const { new_password1, new_password2 } = this.state;
    let error = null;
    if (fieldName === 'new_password1' || fieldName === 'old_password') {
      const fieldValue = this.state[fieldName];
      if (!fieldValue)
        error = `${
          fieldName === 'new_password1' ? 'New password' : 'Current password'
        } is required`;
      else if (!/[A-Z]+/.test(fieldValue)) error = 'One uppercase character is required';
      else if (!/\d+/.test(fieldValue)) error = 'One digit is required';
      else if (fieldValue.length < 8) error = 'Password should be at least 8 characters long';
      if (fieldName === 'new_password1' && fieldValue === this.state.old_password) {
        error = 'The current password and new password should be different';
      }
    }
    if (fieldName === 'new_password2') {
      if (!new_password2) error = 'Confirm password is required';
      else if (new_password2 !== new_password1) error = 'Passwords should match';
    }

    this.setState({
      validationErrors: { ...this.state.validationErrors, [fieldName]: error },
    });

    return !error;
  };

  isFormValid = () => {
    return (
      this.validateField('new_password1') &&
      this.validateField('new_password2') &&
      this.validateField('old_password')
    );
  };

  showAlert = (title, message) => {
    const { setModalViewRenderSequence } = this.context;
    setModalViewRenderSequence([
      {
        state: true,
        id: uuid.v4(),
        modalStyle: {
          overlayBgColor: 'rgba(0,0,0, .7)',
        },
        children: <AlertModal viewId={uuid.v4()} />,
        childrenProps: {
          title,
          message,
        },
      },
    ]);
  };

  onFormSubmit = e => {
    e.preventDefault();
    if (!this.isFormValid()) return;

    const { old_password, new_password1, new_password2 } = this.state;
    const { user } = this.props;

    login(
      JSON.stringify({
        email: user.username,
        password: old_password,
      }),
    ).then(response => {
      // TODO fix the implementation that prevents errors to be passed to the promise
      if (response.errors) {
        this.showAlert('Oops', 'Something went wrong. Please verify your password and try again.');
        return;
      }
      changePassword({ new_password1, new_password2 })
        .then(() => this.setState({ showSuccessMessage: true }))
        .catch(error => {
          Bugsnag.notify(error);
          this.showAlert('Oops', 'Something went wrong. Please try again.');
        });
    });
  };

  render() {
    const { show, onRequestClose } = this.props;
    const { validationErrors } = this.state;

    return (
      <Popup
        className="ChangePasswordPopup"
        fullWidth
        show={show}
        disableAutoClose
        onRequestClose={onRequestClose}
      >
        {this.state.showSuccessMessage ? (
          <div>
            <h2>New password has been saved!</h2>
            <Button text={<b>Okay</b>} onClick={this.props.onRequestClose} />
          </div>
        ) : (
          <form onSubmit={this.onFormSubmit}>
            <CloseButton onClickHandler={this.props.onRequestClose} />
            <h2>Update Password</h2>
            <div className="ChangePasswordPopup-inputs">
              <div className="ChangePasswordPopup-input">
                <Input
                  name="old_password"
                  type="password"
                  placeholder="Current Password"
                  value={this.state.old_password}
                  onChange={value => this.onFieldChange('old_password', value)}
                  onBlur={() => this.validateField('old_password')}
                  valid={!validationErrors.old_password}
                  description={validationErrors.old_password}
                />
              </div>
              <div className="ChangePasswordPopup-input">
                <Input
                  name="new_password1"
                  type="password"
                  placeholder="New Password"
                  value={this.state.new_password1}
                  onChange={value => this.onFieldChange('new_password1', value)}
                  onBlur={() => this.validateField('new_password1')}
                  valid={!validationErrors.new_password1}
                  description={validationErrors.new_password1}
                />
              </div>
              <div className="ChangePasswordPopup-input">
                <Input
                  name="new_password2"
                  type="password"
                  placeholder="Confirm New Password"
                  value={this.state.new_password2}
                  onChange={value => this.onFieldChange('new_password2', value)}
                  onBlur={() => this.validateField('new_password2')}
                  valid={!validationErrors.new_password2}
                  description={validationErrors.new_password2}
                />
              </div>
            </div>

            <Button type="submit" text={<b>Update Password</b>} />

            <div className="ChangePasswordPopup-footer">
              For security purposes, your new password must have an Uppercase letter, a number, and
              be at least 8 characters long.
            </div>
          </form>
        )}
      </Popup>
    );
  }
}

ChangePasswordPopup.contextType = ModalViewContext;

const mapStateToProps = state => ({
  user: state.Auth.user,
});

export default connect(mapStateToProps, null)(ChangePasswordPopup);
