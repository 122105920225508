import React, { Component } from 'react';
import isEqual from 'lodash.isequal';

import AssetsMenuItem from './AssetsMenuItem';
import AssetAnimatedListItem from '../AssetAnimatedListItem';

class AssetsAnimeList extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.assetListStatus !== nextProps.assetListStatus) {
      return true;
    }
    if (!isEqual(this.props.assetList, nextProps.assetList)) return true;
    if (!isEqual(this.props.activeAsset, nextProps.activeAsset)) return true;

    return false;
  }

  render() {
    const { assetList, assetListStatus, handleSlideSwitch } = this.props;

    if (!assetListStatus) {
      return null;
    }

    return assetList.map((asset, index) => (
      <AssetAnimatedListItem index={index} key={asset.id}>
        <AssetsMenuItem
          index={index}
          asset={asset}
          isActive={this.props.activeAsset.id === asset.id}
          onClick={handleSlideSwitch}
        />
      </AssetAnimatedListItem>
    ));
  }
}

export default AssetsAnimeList;
