import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'components/shared/Popup';
import RallyLogo from 'components/shared/RallyLogo';
import SecondaryButton from 'components/shared/Buttons/SecondaryButton';
import driveWealthLogo from 'images/drivewealth-logo.png';

import './TradingLegalPopup.scss';

const TradingLegalPopup = ({ show, onConfirm, onRequestClose }) => (
  <Popup show={show} onRequestClose={onRequestClose} disableAutoClose>
    <h2 className="TradingLegalPopup-header">Trading Disclosure</h2>
    <div className="TradingLegalPopup-content">
      <p>
        Trading Windows allow the owners of shares purchased on Rally to offer them for sale
        directly to other members who are interested buyers. Rally has partnered with DriveWealth,
        LLC, a registered broker-dealer and member{' '}
        <a target="_blank" href="http://www.finra.org/" rel="noopener noreferrer">
          FINRA
        </a>
        /
        <a target="_blank" href="https://www.sipc.org/" rel="noopener noreferrer">
          SIPC
        </a>
        , to execute these securities transactions on behalf of the buyer & seller and to be the
        exclusive custodian of shares for all investors on Rally Neither Rally nor DriveWealth
        charge you anything to participate in Trading Windows as a buyer or seller.
      </p>
      <p>
        If you purchase shares during a Trading Window, you agree to be admitted as a member of a
        Rally Entity and further agree to be bound by the provisions of, and deemed to be a party
        to, the Operating Agreement of the Rally Entity. For additional information regarding your
        rights and obligations as a member of the Rally Entity, please see our{' '}
        <a target="_blank" href="https://rallyrd.com/disclaimer" rel="noopener noreferrer">
          Disclaimer
        </a>
        .
      </p>
      <p>
        Further information about Trading Windows, DriveWealth, and the risks related to investing
        on Rally can be found by carefully reviewing the Rally{' '}
        <a
          target="_blank"
          href="https://www.sec.gov/cgi-bin/browse-edgar?action=getcompany&CIK=0001688804&owner=exclude&count=40"
          rel="noopener noreferrer"
        >
          SEC filings
        </a>{' '}
        and{' '}
        <a target="_blank" href="https://www.rallyrd.com/faq" rel="noopener noreferrer">
          FAQs
        </a>
        .
      </p>
      <div className="TradingLegalPopup-logos">
        <div className="TradingLegalPopup-logo TradingLegalPopup-logo-rally">
          <RallyLogo />
        </div>
        <div className="TradingLegalPopup-logo TradingLegalPopup-logo-drivewealth">
          <img src={driveWealthLogo} alt="DriveWealth Logo" />
        </div>
      </div>
    </div>
    <div>
      <SecondaryButton onClick={onConfirm} text="I understand" arrow="next" />
    </div>
  </Popup>
);

TradingLegalPopup.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func,
};

TradingLegalPopup.defaultProps = {
  show: false,
};

export default TradingLegalPopup;
