export class NetworkRequestError extends Error {
  constructor(type = 'GENERIC', status = 400, message = null, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NetworkRequestError);
    }

    this.name = 'NetworkRequestError';
    this.type = type;
    this.status = status;
    this.message = message;
  }
}
