import React, { useState } from 'react';
import Input from './Input';

const CurrencyInput = ({ value, onChange, placeholder, description, valid, allowZero = true }) => {
  const [displayValue, setDisplayValue] = useState(value !== '' ? `$${value}` : '');

  const handleOnchange = value => {
    let inputValue = value.replace(/\D/g, '');
    let displayValue = '';
    const showZero = allowZero && inputValue === '0';

    if (inputValue !== '' || showZero) {
      inputValue = Number(inputValue); // changing 01 into 1
      displayValue = `$${inputValue}`;
    }

    setDisplayValue(displayValue);
    onChange({ inputValue, displayValue });
  };

  return (
    <Input
      placeholder={placeholder}
      type="text"
      description={description}
      valid={valid}
      inputMode="numeric"
      value={displayValue}
      onChange={handleOnchange}
    />
  );
};

export default CurrencyInput;
