import React from 'react';
import { Price } from 'components/shared/Price';
import { ConditionalSimpleComponent } from 'types/components';
import { BidAskPriceInfo } from './styled';

type BidAskInfoProps = {
  isBid?: boolean;
  price: number;
  className?: string;
};

export const BidAskInfo: ConditionalSimpleComponent<BidAskInfoProps> = ({
  isBid,
  price,
  className = '',
}) => {
  return (
    <>
      <BidAskPriceInfo isBid={!!isBid} className={`bidAskPriceInfo ${className}`}>
        {price && (
          <>
            <div className="title">{isBid ? 'High Bid' : 'Low Ask'}</div>
            <Price keepZeros price={price} />
          </>
        )}
      </BidAskPriceInfo>
    </>
  );
};
