import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import './UserLogin.css';

class UserLogin extends Component {
  render() {
    if (this.props.isAuth && typeof this.props.userEmail !== 'undefined') {
      return (
        <div className="UserLogin">
          <p className="UserLogin-email">{this.props.userEmail}</p>
          <NavLink to="/app/logout" className="UserLogin-button" activeClassName="active">
            LOGOUT
          </NavLink>
        </div>
      );
    } else {
      return (
        <div className="UserLogin">
          <span className="UserLogin-link UserLogin-login">
            <NavLink to="/login" activeClassName="active" location={this.props.location}>
              LOG IN
            </NavLink>
          </span>
          <div className="UserLogin-divider" />
          <span className="UserLogin-link UserLogin-signup">
            <NavLink to="/signup" activeClassName="active" location={this.props.location}>
              SIGN UP
            </NavLink>
          </span>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  userEmail: state.Auth.user.email,
  isAuth: state.Auth.isAuth,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserLogin);
