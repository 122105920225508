import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BackNextButtons } from 'components/shared/Buttons/BackNextButtons';
import exploreImage from 'images/tour/01_explore.gif';

import './Explore.css';

class Explore extends Component {
  static propTypes = {
    onStepForward: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className="Explore">
        <h2>Explore</h2>
        <p className="light">
          Easily browse through each asset in the main carousel to see a summary of its current
          price and that asset's investment status.
        </p>
        <img src={exploreImage} alt="explore" className="Explore-img Tour-gif" />
        <BackNextButtons
          buttons={{ next: { text: 'next' }, style: 'centered' }}
          onNextClick={() => this.props.onStepForward()}
        />
      </div>
    );
  }
}

export default Explore;
