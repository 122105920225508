import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateInvestmentFlow } from 'actions';
import { BackNextButtons } from 'components/shared/Buttons/BackNextButtons';

import './AccreditationStatus.css';
const AccreditationStatus = props => {
  const [isStepValid, setIsStepValid] = useState(props.data.accredited_status.toString() !== '0');
  const dispatch = useDispatch();

  const validateStep = value => {
    const isValid = value.toString() !== '0';
    setIsStepValid(isValid);
  };

  const onOptionChange = ({ target: { value } }) =>
    props.onFormUpdate(
      {
        accredited_status: value,
      },
      () => validateStep(value),
    );

  const handleNextClick = () => {
    const { accredited_status } = props.data;
    dispatch(updateInvestmentFlow({ investment_info: { accredited_status } }));
    props.onStepForward();
  };

  const handleBackClick = () => {
    if (props.resetOnBackPress) {
      const { accredited_status } = props.data || {};
      props.onFormUpdate({ accredited_status });
    }
    props.onStepBackward();
  };

  const accredited_status = props.data.accredited_status.toString();

  return (
    <div className="AccreditationStatus">
      <p className="AccreditationStatus-title">What is your Accreditation status?</p>
      <div className="AccreditationStatus-labels">
        <input
          type="radio"
          id="accreditedByIndividualIncome"
          value="1"
          name="accreditedStatus"
          checked={accredited_status === '1'}
          onChange={onOptionChange}
        />
        <label htmlFor="accreditedByIndividualIncome" className="AccreditationStatus-label">
          <b>I am an Accredited Investor</b>
          <p>
            because I have an individual income of more than $200,000 per year, or a joint income of
            $300,000 in each of the last two years.{' '}
          </p>
        </label>
        <input
          type="radio"
          id="accreditedByNetWorth"
          value="2"
          name="accreditedStatus"
          checked={accredited_status === '2'}
          onChange={onOptionChange}
        />
        <label htmlFor="accreditedByNetWorth" className="AccreditationStatus-label">
          <b>I am an Accredited Investor</b>
          <p>
            because I have a net worth exceeding $1,000,000, excluding my primary residence, either
            individuality or jointly with my spouse.
          </p>
        </label>
        <input
          type="radio"
          id="notAccredited"
          value="3"
          name="accreditedStatus"
          checked={accredited_status === '3'}
          onChange={onOptionChange}
        />
        <label htmlFor="notAccredited" className="AccreditationStatus-label">
          <b>I am not Accredited</b>
          <p>
            I acknowledge that my investment(s) in this offering is less than 10% of my net worth or
            annual gross income.
          </p>
        </label>
      </div>
      <BackNextButtons
        buttons={{ back: { text: 'back' }, next: { text: 'next' } }}
        nextDisabled={!isStepValid}
        onBackClick={handleBackClick}
        onNextClick={handleNextClick}
      />
    </div>
  );
};

AccreditationStatus.propTypes = {
  data: PropTypes.object,
  onFormUpdate: PropTypes.func.isRequired,
  onStepForward: PropTypes.func.isRequired,
  onStepBackward: PropTypes.func.isRequired,
};

export default AccreditationStatus;
