import { createContext } from 'react';
import { OrderType } from 'types/orders';

export enum SuccessType {
  FULL = 'full',
  PARTIAL = 'partial',
  INSTANT = 'instant',
  NOMATCH = 'noMatch',
}

type ReviewLoaderContextState = {
  successType?: SuccessType;
  type: OrderType;
  active: boolean;
};

export const ReviewLoaderContext = createContext<ReviewLoaderContextState>({
  active: false,
  type: OrderType.INSTANT,
});
