import React, { Component } from 'react';
import './Gallery.css';

export class Gallery extends Component {
  render() {
    return (
      <span className="GalleryIcon">
        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41">
          <path d="M38.437,41.000 L2.562,41.000 C1.147,41.000 0.000,39.853 0.000,38.437 L0.000,2.563 C0.000,1.147 1.147,-0.000 2.562,-0.000 L38.437,-0.000 C39.853,-0.000 41.000,1.147 41.000,2.563 L41.000,38.437 C41.000,39.853 39.853,41.000 38.437,41.000 ZM38.437,3.844 C38.437,3.136 37.864,2.563 37.156,2.563 L3.844,2.563 C3.136,2.563 2.562,3.136 2.562,3.844 L2.562,37.156 C2.562,37.864 3.136,38.437 3.844,38.437 L37.156,38.437 C37.864,38.437 38.437,37.864 38.437,37.156 L38.437,3.844 ZM24.045,30.338 C23.775,30.608 23.417,30.732 23.062,30.723 C22.708,30.732 22.350,30.608 22.080,30.338 L14.360,24.852 L7.389,34.181 C6.866,34.704 6.019,34.704 5.496,34.181 C4.973,33.658 4.973,32.811 5.496,32.288 L13.069,22.152 C13.351,21.871 13.725,21.753 14.094,21.775 C14.462,21.753 14.837,21.871 15.118,22.152 L22.862,27.655 L33.569,15.746 C34.092,15.223 34.940,15.223 35.462,15.746 C35.985,16.269 35.985,17.116 35.462,17.639 L24.045,30.338 ZM12.813,15.375 C10.690,15.375 8.969,13.654 8.969,11.531 C8.969,9.408 10.690,7.688 12.813,7.688 C14.935,7.688 16.656,9.408 16.656,11.531 C16.656,13.654 14.935,15.375 12.813,15.375 ZM14.094,10.250 L11.531,10.250 L11.531,12.813 L14.094,12.813 L14.094,10.250 Z" />
        </svg>
      </span>
    );
  }
}
