import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter, matchPath } from 'react-router-dom';
import MediaQuery from 'react-responsive';

import { SUPPORTED_APPLICATION_MODES } from 'constants/main';
import RallyLogo from 'components/shared/RallyLogo';
import BackgroundVideoPoster from 'images/video_poster.png';
import BackgroundVideo from 'videos/bg_video.mp4';

import './LoginSignup.css';
import 'components/views/public/AuthenticationPages.css';

class LoginSignup extends Component {
  render() {
    const isApplicationModeSpecialAccess =
      this.props.applicationMode === SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS;
    const isSignupPath = matchPath(this.props.location.pathname, {
      path: '/signup',
      exact: true,
    });

    const isSigninPath = matchPath(this.props.location.pathname, {
      path: '/login',
      exact: true,
    });
    const signupOrSigninContent = !isApplicationModeSpecialAccess &&
      (isSignupPath || isSigninPath) && (
        <>
          <div className="LoginSignup-signupOrSignin">
            {isSigninPath ? (
              <>
                Don't have an account? <NavLink to="/signup">Sign Up Here</NavLink>
              </>
            ) : (
              isSignupPath && (
                <>
                  Already Have An Account? <NavLink to="/login">Log-In Here</NavLink>
                </>
              )
            )}
          </div>
          <ul className="LoginSignup-appDownloadLinks">
            <a
              href="https://apps.apple.com/us/app/rally-rd/id1136945620"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li className="apple-appstore"></li>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.exchange.rs.rallyrd"
              target="_blank"
              rel="noopener noreferrer"
            >
              <li className="google-playstore"></li>
            </a>
          </ul>
        </>
      );

    return (
      <div className="AuthenticationPages LoginSignup">
        <MediaQuery maxDeviceWidth={767}>
          <video className="LoginSignup-video" poster={BackgroundVideoPoster} autoPlay muted loop>
            <source src={BackgroundVideo} type="video/mp4" />
          </video>
          <div className="LoginSignup-video-overlay"></div>

          <div className="LoginSignup-top">
            <div className="LoginSignup-logo">
              <RallyLogo color="#FFFFFF" viewBox="80 75 200 200" />
            </div>
            <div className="LoginSignup-subtitle">Assets to Appreciate</div>
            <div className="LoginSignup-text">
              <p>
                Buy & Sell equity shares in some of the
                <br />
                rarest collectible assets in the world.
              </p>
            </div>
          </div>
          <div className="LoginSignup-box">
            <div className="LoginSignup-form">{this.props.children}</div>
            {signupOrSigninContent}
          </div>
        </MediaQuery>
        <MediaQuery minDeviceWidth={768}>
          <div className="LoginSignup-box">
            <div className="LoginSignup-logo">
              <RallyLogo size="large" />
            </div>
            <div className="LoginSignup-form">{this.props.children}</div>
          </div>
          {signupOrSigninContent}
        </MediaQuery>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  applicationMode: state.UI.applicationMode,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(LoginSignup));
