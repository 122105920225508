export const ASSET_CATEGORIES = {
  CARS: {
    key: 'cars',
    title: 'Cars',
    pathname: 'cars',
  },
  HISTORY: {
    key: 'history',
    title: 'Memorabilia',
    pathname: 'history',
  },
  WINE_SPIRITS: {
    key: 'wine-spirits',
    title: 'Wine + Whisky',
    pathname: 'wine-spirits',
  },
  COMICS_LITERATURE: {
    key: 'art',
    title: 'Comics + Literature',
    pathname: 'comics-literature',
  },
  LUXURY: {
    key: 'watches',
    title: 'Watches + Luxury',
    pathname: 'luxury',
  },
};

// Mode 0: Carousel (default)
// Mode 1: List view
export const ASSET_UI_STATE = {
  cars: false,
  history: false,
  'wine-spirits': false,
  art: false,
  watches: false,
};

export const ASSET_STATUS = {
  COMING_SOON: 'Coming Soon',
  IO_OPENED: 'Open ICO',
  IO_CLOSED: 'Closed ICO',
  TRADING_OPENED: 'Trading',
  POST_ONLY: 'Post Only',
  TRADING_CLOSED: 'Trading closed',
};

export const ERROR_CODES = {
  NO_ACCESSIBLE_ASSETS: 1,
  NOT_AUTHORIZED_FOR_EARLY_ACCESS: 2,
  UNAUTHORIZED_401: 3,
};

export const ERROR_CODE_ERROR_MESSAGE_MAP = {
  [ERROR_CODES.NO_ACCESSIBLE_ASSETS]: 'Sorry, there are no assets available at this time.',
  [ERROR_CODES.NOT_AUTHORIZED_FOR_EARLY_ACCESS]:
    'Sorry, you are not authorized for early access at this time.',
  [ERROR_CODES.UNAUTHORIZED_401]: 'Your session has expired, please try logging in again.',
};

export const INVESTMENTS_STATUS = {
  APPROVED: 'Approved',
  IN_PROGRESS: 'In progress',
  REJECTED: 'Rejected',
};

export const TRADINGWINDOW_STATUS = {
  CLOSED: 'CLOSED',
  CONFIRMATION: 'CONFIRMATION',
  FINALIZED: 'FINALIZED',
  OPEN: 'OPEN',
  REVIEW: 'REVIEW',
  SECONDARY_REVIEW: 'SECONDARY_REVIEW',
};

export const TRADINGWINDOW_ORDER_TYPE = {
  BUY: 'BUY',
  SELL: 'SELL',
};

export const TRADINGWINDOW_ORDER_STATUS = {
  CLOSED: 'CLOSED',
  CONFIRMED: 'CONFIRMED',
  MATCHED: 'MATCHED',
  PARTIALLY_MATCHED: 'PARTIALLY_MATCHED',
  MATCHING: 'MATCHING',
  OPEN: 'OPEN',
};

export const TRADINGWINDOW_ORDER_CONFIRMED_MATCHED_STATUS = {
  MATCHED: 'MATCHED',
  PARTIALLY_MATCHED: 'PARTIALLY_MATCHED',
  UNMATCHED: 'UNMATCHED',
};

export const TRANSFER_TYPE = {
  DEPOSIT: 'deposit',
  WITHDRAWAL: 'withdrawal',
  INVESTMENT: 'investment',
  REFUND: 'refund',
  TRADE: 'trade',
  TRANSFER: 'transfer',
  MERCHANDISE: 'merchandise',
  ORDER: 'order',
};

export const DWOLLA_CUSTOMER_STATUS = {
  NOT_CREATED: 'not-created',
  SUSPENDED: 'suspended',
  UNVERIFIED: 'unverified',
  RETRY: 'retry',
  DOCUMENT: 'document',
  DOCUMENT_PENDING: 'document-pending',
  DOCUMENT_REVIEWED: 'document-reviewed',
  VERIFIED: 'verified',
};

export const FUNDING_SOURCE_STATUS = {
  UNVERIFIED: 'unverified',
  VERIFIED: 'verified',
};

export const SPECIAL_ACCESS_LIMITATION_TYPES = {
  SHARE_RESTRICTION: 'share_count',
};

export const SPECIAL_ACCESS_TYPES = {
  EARLY_IO: 'early_io',
};

export const SPECIAL_ACCESS_TYPE_BLACKLISTED_ASSET_STATUS_MAP = {
  // when users have special access to investing early, they should no longer see assets when they enter into open io in special access mode.
  [SPECIAL_ACCESS_TYPES.EARLY_IO]: [ASSET_STATUS.IO_OPENED],
};

export const SPECIAL_ACCESS_TYPE_PRECEDENCE = [SPECIAL_ACCESS_TYPES.EARLY_IO];

export const SUPPORTED_APPLICATION_MODES = {
  DEFAULT: 'default',
  SPECIAL_ACCESS: 'special-access',
};

export const RALLY_SUBDOMAIN_APPLICATION_MODE_MAP = {
  early: SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS,
};

export const ACCREDITATION_STATUSES_TEXT = {
  0: "I'm Not Accredited",
  1: 'I am an Accredited Investor',
  2: 'I am an Accredited Investor',
  3: "I'm Not Accredited",
};

export const EMPLOYMENT_STATUS = {
  EMPLOYED: 'EMPLOYED',
  SELF: 'SELF_EMPLOYED',
  UNEMPLOYED: 'UNEMPLOYED',
  RETIRED: 'RETIRED',
  STUDENT: 'STUDENT',
};

export const MIN_INCOME = 10001;

export const STATUSES_TO_SKIP_EMPLOYER = [
  EMPLOYMENT_STATUS.UNEMPLOYED,
  EMPLOYMENT_STATUS.RETIRED,
  EMPLOYMENT_STATUS.STUDENT,
];

export const ASSET_SEARCH_MIN_CHARS = 3;

export const PASSPORT_SECTION = {
  IO: 'io',
  ONBOARDING: 'onboarding',
  TRADING: 'trading',
};

export const ACCOUNT_STATUS = {
  APPROVED: 'APPROVED',
  ENROLLMENT_PENDING: 'ENROLLMENT_PENDING',
  SUSPENDED: 'SUSPENDED',
};

export const TRADING_PRICE_TYPE = {
  LAST_TRADE: 'LAST TRADE',
  VWAP: 'VWAP',
};

// TODO remove the ASSET_CATEGORIES from the code base and after that,
// remove the key porperty from the objects it doesn't really need
export const assetsCategories = {
  cars: {
    key: 'cars',
    title: 'Cars',
    pathname: 'cars',
  },
  history: {
    key: 'history',
    title: 'Memorabilia',
    pathname: 'history',
  },
  'wine-spirits': {
    key: 'wine-spirits',
    title: 'Wine + Whisky',
    pathname: 'wine-spirits',
  },
  art: {
    key: 'art',
    title: 'Comics + Literature',
    pathname: 'comics-literature',
  },
  watches: {
    key: 'watches',
    title: 'Watches + Luxury',
    pathname: 'luxury',
  },
};
