import { useEffect, useRef } from 'react';

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useHasChanged = (value: any) => {
  const prevValue = usePrevious(value);
  return JSON.stringify(prevValue) !== JSON.stringify(value)
}

export default useHasChanged;
