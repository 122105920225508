import styled from '@emotion/styled';

export const CalendarItemContainer = styled.div(({ theme }) => ({
  border: `1px solid ${theme.colors.borderGrey}`,
  margin: 0,
  marginBottom: '2vmin',
  background: 'none',
  borderRadius: 4,
  cursor: 'pointer',
  display: 'grid',
  color: 'inherit',
  gridTemplateColumns: '25% 58% 17%',
  [theme.breakpoints.lg]: {
    display: 'inline-grid',
    width: 'calc(49% - 2px)',
    '&:nth-child(even)': {
      marginRight: '1%',
    },
    '&:nth-child(odd)': {
      marginLeft: '1%',
    },
  },
}));

export const CalendarInfoContent = styled.div(({ theme }) => ({
  height: '100%',
  boxSizing: 'border-box',
  padding: '4vmin 3vmin',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.md]: {
    padding: '2vmin 1vmin',
  },
}));

export const CalendarAssetName = styled.div(({ theme }) => ({
  fontFamily: 'Gotham Book',
  fontSize: '3vmin',
  textAlign: 'right',
  textOverflow: 'ellipsis',
  maxWidth: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  [theme.breakpoints.md]: {
    fontSize: '1.6vmin',
    lineHeight: 1.5,
  },
}));

export const CalendarTimer = styled(CalendarAssetName)(({ theme }) => ({
  color: theme.colors.darkGreen,
  fontFamily: 'Gotham Bold',
}));

export const CalendarTicker = styled(CalendarAssetName)(({ color }) => ({
  color,
}));

type ImgProps = {
  url: string;
};

export const CalendarImage = styled.div<ImgProps>(({ theme, url }) => ({
  height: '100%',
  width: '100%',
  background: `url(${url}) no-repeat center`,
  backgroundSize: 'contain',
}));

export const CalendarButton = styled.div(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  padding: '2vmin',
  borderLeft: `1px solid ${theme.colors.borderGrey}`,
  '.addToCalendar, .AddToCalendarButton-btn': {
    width: '7.5vmin',
    height: '7.5vmin',
    [theme.breakpoints.md]: {
      width: '4.1vmin',
      height: '4.1vmin',
    },
  },
  '.arrow': {
    width: '2.6vmin',
    [theme.breakpoints.md]: {
      width: '1.4vmin',
    },
  },
  '.double-arrow': {
    width: '5vmin',
    [theme.breakpoints.md]: {
      width: '2.8vmin',
    },
  },
}));
