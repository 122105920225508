const atsCards = async (userId: string) => {
  const key = 'atsEducationalCards';
  
  const get = () => JSON.parse(localStorage.getItem(key) || '{}');

  const set = (lastShown: number, amountShown: number) => {
    const item = get();

    const obj = {
      ...item,
      [userId]: {
        lastShown,
        amountShown,
      }
    }
    localStorage.setItem(key, JSON.stringify(obj));
  };

  const check = () => {
    /*
      RULES:
      - shows once every 24h
      - shows 3 times max
  
      STORAGE OBJECT:
      {
        <userId>: {
          lastShown: <timestamp>,
          amountShown: 1-3
        }
      }
    */

    const getHours = (lastShown: number) => {
      const diff =  Date.now() - lastShown;
      return Math.floor(diff/1000/60/60);
    }
  
    const item = get();

    if (!item[userId]) {
      set(Date.now(), 1);
      return true;
    }

    const { lastShown, amountShown } = item[userId];

    const shownInTheLast24Hours = !isNaN(lastShown) ? getHours(lastShown) < 24 : false;
    const shownLessThan3Times = amountShown ? amountShown <= 3 : true;
    
    const shouldShow = !shownInTheLast24Hours && shownLessThan3Times;
    if (shouldShow) set(Date.now(), amountShown + 1);

    return shouldShow;
  };

  return {
    set,
    get,
    check,
  };
};

export { atsCards };
