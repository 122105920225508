import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formatAssetPrice } from 'utils';
import './TickerPrice.css';

class TickerPrice extends Component {
  render() {
    return (
      <div className="TickerPrice">
        <div className="TickerPrice-content">
          <span className="TickerPrice-ticker">{this.props.ticker}</span>
          <span className="TickerPrice-divider">|</span>
          <span className="TickerPrice-price" style={{ color: this.props.color }}>
            {`$${formatAssetPrice(this.props.price)}`}
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TickerPrice);
