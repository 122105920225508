import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { createBankFundingSource } from 'actions';
import { Input } from 'components/shared/Inputs/Input';
import { TextButton } from 'components/shared/Buttons/TextButton';
import { BackNextButtons } from 'components/shared/Buttons/BackNextButtons';

import './BankInfo.css';

class BankInfo extends Component {
  static propTypes = {
    data: PropTypes.object,
    onFormUpdate: PropTypes.func.isRequired,
    onStepForward: PropTypes.func.isRequired,
    onStepBackward: PropTypes.func.isRequired,
    createBankFundingSource: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const {
      data: { nameOnAccount, routingNumber, accountNumber },
    } = this.props;

    const isNameValid = typeof nameOnAccount === 'string' && nameOnAccount !== '';
    const isRoutingValid = typeof routingNumber === 'string' && routingNumber !== '';
    const isAccountValid = typeof accountNumber === 'string' && accountNumber !== '';
    const isStepValid = isNameValid && isRoutingValid && isAccountValid;

    this.state = { isStepValid };
  }

  validateStep = () => {
    const {
      data: { nameOnAccount, routingNumber, accountNumber },
    } = this.props;
    const isNameValid = typeof nameOnAccount === 'string' && nameOnAccount !== '';
    const isRoutingValid = typeof routingNumber === 'string' && routingNumber !== '';
    const isAccountValid = typeof accountNumber === 'string' && accountNumber !== '';
    const isStepValid = isNameValid && isRoutingValid && isAccountValid;
    this.setState({ isStepValid });
  };

  onFieldChange = (fieldKey, fieldValue) =>
    this.props.onFormUpdate(
      {
        [fieldKey]: fieldValue,
      },
      this.validateStep,
    );

  createBankFundingSource = async () => {
    const {
      data: { nameOnAccount, routingNumber, accountNumber, accountType },
    } = this.props;

    const payload = {
      routingNumber,
      accountNumber,
      name: nameOnAccount,
      type: accountType.toLowerCase(),
    };

    await this.props.createBankFundingSource(payload);
    this.props.onFormUpdate({ isSmallDepositNeeded: true }, this.props.onStepForward);
  };

  render() {
    const {
      data: { nameOnAccount, routingNumber, accountNumber },
      bankFundingSource,
    } = this.props;

    return (
      <div className="BankInfo">
        <Input
          placeholder="Name On Account"
          value={nameOnAccount}
          onChange={value => this.onFieldChange('nameOnAccount', value)}
          autoFocus={true}
        />
        <Input
          placeholder="Routing Number"
          value={routingNumber}
          onChange={value => this.onFieldChange('routingNumber', value)}
        />
        <Input
          placeholder="Account Number"
          value={accountNumber}
          onChange={value => this.onFieldChange('accountNumber', value)}
        />
        {bankFundingSource.error && <p className="Error">{bankFundingSource.error}</p>}
        {!bankFundingSource.loading && (
          <TextButton
            arrow="forward"
            text="continue"
            onClick={this.createBankFundingSource}
            disabled={!this.state.isStepValid}
          />
        )}
        <BackNextButtons
          buttons={{ back: { text: 'back' }, style: 'centered' }}
          onBackClick={() => this.props.onStepBackward()}
          loading={bankFundingSource.loading}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  bankFundingSource: state.Investments.bankFundingSource,
});

const mapDispatchToProps = { createBankFundingSource };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BankInfo);
