import React, { Component } from 'react';

import { Input } from 'components/shared/Inputs/Input';
import { Price } from 'components/shared/Price';
import { Button } from 'components/shared/Buttons/Button';
import { CloseButton } from 'components/shared/Buttons/CloseButton';
import { SecondaryButton } from 'components/shared/Buttons/SecondaryButton';
import { TextButton } from 'components/shared/Buttons/TextButton';
import { TRANSFER_TYPE } from 'constants/main';
import { RESPONSE_ERROR_MESSAGES, ERROR_CUSTOM_OVERRIDE_MESSAGES } from 'constants/errors';
import Popup from 'components/shared/Popup';
import Error from 'components/shared/Error';
import * as DwollaAPI from 'services/DwollaAPI';

import './DepositWithdrawal.scss';
import analytics from 'services/analytics';
import { SEGMENT_CATEGORIES, SEGMENT_ACTIONS } from 'constants/analytics';

class DepositWithdrawal extends Component {
  initialState = {
    loading: false,
    amount: 0,
    amountValid: false,
    error: null,
    showReview: false,
    submitted: false,
    transferType: TRANSFER_TYPE.DEPOSIT,
    isCustomizedErrorDisplayed: undefined,
  };

  state = this.initialState;

  componentDidUpdate(prevProps, prevState) {
    prevState.error !== this.state.error &&
      this.state.error &&
      this.state.error[0] &&
      this.state.error[0].message === RESPONSE_ERROR_MESSAGES.BALANCE_TRANSFER_SEVENDAYS_LIMIT &&
      this.setState({ isCustomizedErrorDisplayed: true });
  }

  handleTransferTypeChange = e => {
    this.setState({ transferType: e.target.value }, () =>
      this.handleAmountChange(this.state.amount),
    );
  };

  handleTransferInit = async () => {
    this.setState({ loading: true });
    try {
      await DwollaAPI.makeTransfer({ type: this.state.transferType, amount: this.state.amount });
      this.setState({ loading: false, submitted: true });
      this.props.onTransferInitiated();
      analytics.track(
        `Fund Account Transfer Completed - ${this.state.transferType.toUpperCase()}`,
        {
          category: SEGMENT_CATEGORIES.TRANSFER,
          action: SEGMENT_ACTIONS.MONEY_TRANSFER_TO_RALLY,
          amount: this.state.amount,
          type: this.state.transferType,
        },
      );
    } catch (err) {
      this.setState({ loading: false, error: err || 'Sorry, something went wrong...' });
    }
  };

  handleReviewCancel = () => this.setState({ showReview: false });

  handleAmountChange = amount => {
    const amountValid =
      amount &&
      !(this.state.transferType === TRANSFER_TYPE.WITHDRAWAL && amount > this.props.balance.cash);

    this.setState({ amount, amountValid });
  };

  handleFormSubmit = async e => {
    e.preventDefault();
    document.activeElement.blur();
    this.setState({ showReview: true });
  };

  handleClose = () => {
    analytics.track('Transfers & Withdrawals Opened', {
      category: SEGMENT_CATEGORIES.FUNDING_SOURCE,
      action: SEGMENT_ACTIONS.OPEN,
    });

    if (this.state.loading) return;
    this.setState(this.initialState);
    this.props.onRequestClose();
  };

  onEmailContactSupport = () => {
    window.location.href = 'mailto:support@rallyrd.com?subject=Withdraw Balance';
  };

  renderTransferForm = () => {
    const { onSourceDelete, balance, account } = this.props;

    const isDeposit = this.state.transferType === TRANSFER_TYPE.DEPOSIT;
    const TransferButton = isDeposit ? Button : SecondaryButton;

    const accName = this.props.account.name;
    const accNumber = account.accountNumberTail && account.accountNumberTail.slice(-4);

    return (
      <form onSubmit={this.handleFormSubmit} noValidate>
        {this.state.showReview && (
          <div className="DepositWithdrawal-review">
            <h2>Review {isDeposit ? 'Deposit' : 'Withdrawal'}</h2>
            <p>
              Transfer <Price price={this.state.amount} keepZeros /> to my
              <br />
              {isDeposit
                ? `Rally account from ${accName} ${accNumber ? `ending in *${accNumber}` : ''}`
                : `${accName} account ${accNumber ? `ending in *${accNumber}` : ''}`}
            </p>
            <footer>
              <button type="button" onClick={this.handleReviewCancel}>
                Cancel
              </button>
              <button onClick={this.handleTransferInit}>Submit</button>
            </footer>
          </div>
        )}

        {this.state.loading && <div className="DepositWithdrawal-loader" />}
        <div className="DepositWithdrawal-top">
          <div className="DepositWithdrawal-accountinfo">
            <div className="DepositWithdrawal-title">Transfers & Withdrawals</div>
            <div className="DepositWithdrawal-header">Linked Account</div>
            <div className="DepositWithdrawal-accountname">{accName || 'No Account Linked'}</div>
            <div className="DepositWithdrawal-deletesource">
              <TextButton onClick={onSourceDelete} text="Delete" type="button" />
            </div>
            <div className="DepositWithdrawal-balancesummary">
              <div className="DepositWithdrawal-balance-group">
                <div className="DepositWithdrawal-balance-header">Acct. Value</div>
                <div className="DepositWithdrawal-balance-value">
                  <Price price={balance.total} />
                </div>
              </div>
              <div className="DepositWithdrawal-balance-group">
                <div className="DepositWithdrawal-balance-header">Investments</div>
                <div className="DepositWithdrawal-balance-value">
                  <Price price={balance.investments} />
                </div>
              </div>
              <div className="DepositWithdrawal-balance-group">
                <div className="DepositWithdrawal-balance-header">Avail. for Withdrawal</div>
                <div className="DepositWithdrawal-balance-value">
                  <Price price={balance.cash} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="DepositWithdrawal-bottom">
          <div className="DepositWithdrawal-transferform">
            <div className="DepositWithdrawal-transfertofrom">
              <input
                type="radio"
                id={TRANSFER_TYPE.DEPOSIT}
                value={TRANSFER_TYPE.DEPOSIT}
                checked={isDeposit}
                onChange={this.handleTransferTypeChange}
              />
              <label htmlFor={TRANSFER_TYPE.DEPOSIT}>Transfer to Rally</label>

              <input
                type="radio"
                id={TRANSFER_TYPE.WITHDRAWAL}
                value={TRANSFER_TYPE.WITHDRAWAL}
                checked={!isDeposit}
                onChange={this.handleTransferTypeChange}
              />
              <label htmlFor={TRANSFER_TYPE.WITHDRAWAL}>Transfer to Your Bank</label>
            </div>

            <div className="DepositWithdrawal-moneyinput">
              <Input
                type="text"
                decimalsOnly
                placeholder="0.00"
                value={this.state.amount}
                onChange={this.handleAmountChange}
              />
              <TransferButton
                text={isDeposit ? 'Send to Rally' : 'Transfer to Bank'}
                type="submit"
                disabled={!this.state.amountValid}
              />
            </div>
            <div className="DepositWithdrawal-transferaccount">
              <div className="DepositWithdrawal-transferaccount-direction">
                {this.state.transferType === TRANSFER_TYPE.DEPOSIT
                  ? 'Transfer from:'
                  : 'Send withdrawal to:'}
              </div>
              <div className="DepositWithdrawal-transferaccount-name">
                {accName || 'No Account Linked'}
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  };

  renderSuccessMessage = () => (
    <div className="DepositWithdrawal-transferpage">
      <div className="DepositWithdrawal-transferpage-content">
        <div className="DepositWithdrawal-transferpage-title">Transfer Initiated</div>
        <div className="DepositWithdrawal-transferpage-message">
          <span>Your funds should be available shortly.</span>&nbsp;
          <span>
            You can find a receipt for this transaction in the "Transaction History" section of your
            Portfolio.
          </span>
        </div>
      </div>
      <SecondaryButton text="OKAY!" onClick={this.handleClose} />
    </div>
  );

  renderError = () => {
    const isErrorDepositSevenDayLimitation =
      this.state.error[0] &&
      this.state.error[0].message === RESPONSE_ERROR_MESSAGES.BALANCE_TRANSFER_SEVENDAYS_LIMIT;

    let errorContent;
    isErrorDepositSevenDayLimitation
      ? (errorContent = (
          <div className="DepositWithdrawal-errors">
            <div className="DepositWithdrawal-errors-content">
              <div className="DepositWithdrawal-errors-title">Balance Transfers</div>
              <p className="DepositWithdrawal-errors-message">
                {ERROR_CUSTOM_OVERRIDE_MESSAGES.BALANCE_TRANSFER_SEVENDAYS_LIMIT}
              </p>
            </div>
            <Button
              className="DepositWithdrawal-contactsupport-button"
              onClick={this.onEmailContactSupport}
            >
              Contact Support
            </Button>
          </div>
        ))
      : (errorContent = (
          <div className="DepositWithdrawal-transferpage">
            <div className="DepositWithdrawal-transferpage-content">
              <div className="DepositWithdrawal-transferpage-title error">Error</div>
              <Error error={this.state.error} />
            </div>
            <SecondaryButton text="OKAY!" onClick={this.handleClose} />
          </div>
        ));

    return errorContent;
  };

  render() {
    let content = this.renderTransferForm();
    if (this.state.submitted) content = this.renderSuccessMessage();
    if (this.state.error) content = this.renderError();

    return (
      <Popup
        show={this.props.show}
        disableAutoClose
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
        onRequestClose={this.handleClose}
        className="DepositWithdrawal"
        contentClassName={`DepositWithdrawal-content ${this.state.isCustomizedErrorDisplayed &&
          `customizedErrorContent`}`}
      >
        {content}
        {this.state.isCustomizedErrorDisplayed ? (
          <Button className="DepositWithdrawal-closeAndContinue-button" onClick={this.handleClose}>
            Close & Continue
          </Button>
        ) : (
          <CloseButton onClickHandler={this.handleClose} />
        )}
      </Popup>
    );
  }
}

export default DepositWithdrawal;
