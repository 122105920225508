import React, { Component } from 'react';

import { DotsLoader } from 'components/shared/Loaders';
import { TextButton } from './TextButton';
import './BackNextButtons.css';

export class BackNextButtons extends Component {
  constructor(props) {
    super(props);
    this.handleNextButtonClick = this.handleNextButtonClick.bind(this);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
  }

  handleNextButtonClick(e) {
    this.props.onNextClick();
  }

  handleBackButtonClick(e) {
    this.props.onBackClick();
  }

  render() {
    let buttons = this.props.buttons || {};

    let backButton = '';
    if (typeof buttons.back === 'function') {
      backButton = buttons.back();
    } else if (typeof buttons.back === 'object') {
      backButton = (
        <TextButton
          text={buttons.back.text}
          arrow="back"
          onClick={this.handleBackButtonClick}
          disabled={this.props.backDisabled}
        />
      );
    }

    let nextButton = '';
    if (typeof buttons.next === 'function') {
      nextButton = buttons.next();
    } else if (typeof buttons.next === 'object') {
      nextButton = (
        <TextButton
          text={buttons.next.text}
          arrow="forward"
          onClick={this.handleNextButtonClick}
          disabled={this.props.nextDisabled}
        />
      );
    }

    let centeredClass =
      buttons.style === 'centered' ? 'BackNextButtons-centered' : 'BackNextButtons-inline';

    let buttonElements = (
      <span className={centeredClass}>
        {this.props.loading && (
          <div className="BackNextButtons-loader">
            <DotsLoader />
          </div>
        )}
        <span className="BackNextButtons-next">{nextButton}</span>
        <span className="BackNextButtons-back">{backButton}</span>
      </span>
    );

    return <div className="BackNextButtons">{buttonElements}</div>;
  }
}

export default BackNextButtons;
