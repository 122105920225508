import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextButton } from 'components/shared/Buttons/TextButton';

import checkmarkImage from 'images/green-check.png';
import './IntroMessage.css';

class IntroMessage extends Component {
  static propTypes = {
    onStepForward: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className="IntroMessage">
        <img src={checkmarkImage} alt="checkmark" className="IntroMessage-img" />
        <h1 className="IntroMessage-title">Success!</h1>
        <p>
          Welcome to Rally - a new world of investing at the intersection of People, Passion, &
          Profit. Let’s start with some of the basics to get you familiar with the platform!
        </p>
        <TextButton arrow="forward" onClick={() => this.props.onStepForward()}>
          Start the Tour
        </TextButton>
      </div>
    );
  }
}

export default IntroMessage;
