import { NetworkRequestError } from 'services/errors';
import { NETWORK_ERROR_TYPES } from 'constants/errors';

export const fetchTimeout = (endpoint, requestInit, timeout = 60000) => {
  const controller = new AbortController();
  const abortSignal = controller.signal;

  const promise = fetch(endpoint, { signal: abortSignal, ...requestInit });
  const abortTimer = setTimeout(() => {
    controller.abort();
    return new NetworkRequestError(NETWORK_ERROR_TYPES.NETWORK_TIMEOUT, null, 'Network timeout');
  }, timeout);

  return promise.finally(() => clearTimeout(abortTimer));
};
