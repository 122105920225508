import { Asset } from 'types/assets';

export enum IOCategories {
  ComingSoon = 'Coming Soon',
  Available = 'Available Now',
}

export enum DefaultCategories {
  ComingSoon = 'comingSoon',
  Available = 'available',
  Live = 'live',
}

export enum TradingCategories {
  Live = 'Trading Now',
}

export type GroupedAssetList = {
  io: {
    [DefaultCategories.ComingSoon]: Asset[];
    [DefaultCategories.Available]: Asset[];
  };
  trading: {
    [DefaultCategories.Live]: Asset[];
    [key: string]: Asset[];
  };
};
