import React, { Component } from 'react';

import { UpCaret } from './UpCaret';

import './RightCaret.css';

export class RightCaret extends Component {
  render() {
    return (
      <span className="RightCaret">
        <UpCaret />
      </span>
    );
  }
}
