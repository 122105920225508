import { setAssetsSearchValue, setSearchResultList } from 'actions/assets';
import { ASSET_SEARCH_MIN_CHARS, ASSET_STATUS } from 'constants/main';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import analytics from 'services/analytics';
import { SEGMENT_ACTIONS, SEGMENT_CATEGORIES, SEGMENT_EVENTS } from 'constants/analytics';

type useAssetSearchHook = () => [string, (value: string) => void];

type Asset = {
  category: string;
  id: string;
  display_name: string;
  ticker: string;
  asset_status: string;
};

let lastNoResultQuery = '';

const useAssetSearch: useAssetSearchHook = () => {
  const assetsSearchValue = useSelector((store: any) => store.Assets.assetsSearchValue);
  const assetCategoryLists = useSelector((store: any) => store.Assets.assetCategoryLists);
  const dispatch = useDispatch();
  const tracker: any = analytics;

  useEffect(() => {
    if (assetsSearchValue.length >= ASSET_SEARCH_MIN_CHARS) {
      const searchRegex = new RegExp(assetsSearchValue, 'gi');
      let result: Asset[] = [];
      Object.keys(assetCategoryLists).forEach(category => {
        const filteredList = assetCategoryLists[category].filter(
          ({ display_name, ticker, asset_status }: Asset) =>
            display_name.match(searchRegex) ||
            ticker.match(searchRegex) ||
            (asset_status !== ASSET_STATUS.TRADING_CLOSED && asset_status.match(searchRegex)),
        );
        result = [...result, ...filteredList];
      });
      if (result.length === 0) {
        const isNewQuery = lastNoResultQuery !== assetsSearchValue;
        if (!isNewQuery) return;
        lastNoResultQuery = assetsSearchValue;

        tracker.track(SEGMENT_EVENTS.SEARCH_NO_RESULTS, {
          action: SEGMENT_ACTIONS.OPEN,
          label: assetsSearchValue,
          category: SEGMENT_CATEGORIES.SEARCH,
        });
      }
      dispatch(setSearchResultList(result));
      return;
    }

    if (assetsSearchValue.length === 0) {
      dispatch(setSearchResultList([]));
      return;
    }
  }, [assetsSearchValue, assetCategoryLists, dispatch, tracker]);

  const setValue = (value: string) => {
    const newValue = value.trimStart();
    let twoWhiteSpaces = newValue.match(/ {2}/g)?.length ?? 0;
    // preventing search for "test  " that allows one space between the words and one space in the end
    if (twoWhiteSpaces === 0) {
      dispatch(setAssetsSearchValue(newValue));
    }
  };

  return [assetsSearchValue, setValue];
};

export default useAssetSearch;
