import React, { useContext } from 'react';
import styled from 'styled-components';

import {
  BaseAlertModalView,
  BaseModalTitle,
  BaseModalMessage,
  BaseModalUserActionButtonContainer,
  BaseModalUserActionButton,
} from './ModalStyles';
import { ModalViewContext, getViewContexts } from 'components/shared/Modals/Modal';

const AlertModal = ({ viewId, ...props }) => {
  const { viewArray } = useContext(ModalViewContext);
  const { title, message, primaryButton, buttonText } = getViewContexts(viewArray, viewId)
    .contextHandlers.childrenProps;

  const closeModalHandler = () => {
    getViewContexts(viewArray, viewId).onClose();
  };

  return (
    <AlertModalView>
      <BaseModalTitle>{title}</BaseModalTitle>
      <BaseModalMessage>{message}</BaseModalMessage>
      <BaseModalUserActionButtonContainer>
        <BaseModalUserActionButton
          primaryButton={primaryButton}
          onClick={() => closeModalHandler()}
        >
          {buttonText ? buttonText : 'OK'}
        </BaseModalUserActionButton>
      </BaseModalUserActionButtonContainer>
    </AlertModalView>
  );
};

const AlertModalView = styled(BaseAlertModalView)`
  width: 100%;
  padding-bottom: 0;

  @media only screen and (max-width: 600px) {
    padding-bottom: 0;
  }
`;

export default AlertModal;
