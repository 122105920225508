import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Popup from 'components/shared/Popup';
import SecondaryButton from 'components/shared/Buttons/SecondaryButton';
import TextButton from 'components/shared/Buttons/TextButton';
import { Price } from 'components/shared/Price';
import { DotsLoader } from 'components/shared/Loaders';
import analytics from 'services/analytics';

import './CancelOrderPopup.scss';
import { SEGMENT_CATEGORIES, SEGMENT_ACTIONS } from 'constants/analytics';

const CancelOrderPopup = ({
  show,
  onRequestClose,
  cancelOrder,
  order,
  assetTicker,
  loading,
  ...props
}) => {
  useEffect(() => {
    if (!show || !order) return;
    const side = order.taxonomy.context;

    // track pageview
    const trackingTitle = `${side} Cancel Order Pop-up`;

    analytics.page(trackingTitle, {
      title: trackingTitle,
    });
    // track action(s)
    analytics.track(trackingTitle, {
      category: SEGMENT_CATEGORIES.TRADING_WINDOW,
      action: SEGMENT_ACTIONS.OPEN,
      assetTicker: assetTicker,
    });
  }, [show, order, assetTicker]);

  return (
    <Popup
      className="CancelOrderPopup"
      show={show}
      disableAutoClose
      onRequestClose={onRequestClose}
      {...props}
    >
      <div className="CancelOrderPopup-content">
        <h1>Cancel Order</h1>
        <p>
          You are about to cancel a pending order to {order.taxonomy.context.toLowerCase()}{' '}
          {order.units.unfilled} {`share${order.units.unfilled > 1 ? 's' : ''}`} of {assetTicker} at{' '}
          <Price price={order.pricing.unit} /> per share. By confirming this cancellation below,
          your order will be permanently removed from the queue.
        </p>
        <SecondaryButton
          disabled={loading}
          className="CancelOrderPopup-cancel"
          onClick={cancelOrder}
        >
          {loading ? <DotsLoader color="rgba(255, 255, 255, 0.7)" /> : 'Cancel My Order'}
        </SecondaryButton>
        <TextButton
          disabled={loading}
          className="CancelOrderPopup-back"
          text="Go Back"
          onClick={onRequestClose}
        />
      </div>
    </Popup>
  );
};

CancelOrderPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  cancelOrder: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  assetTicker: PropTypes.string,
};

export default CancelOrderPopup;
