import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LightBox from 'components/shared/Media/LightBox';
import SquareButton from 'components/shared/Buttons/SquareButton';

import './DetailsDocumentButton.scss';

class DetailsDocumentButton extends Component {
  static propTypes = {
    media: PropTypes.array,
    style: PropTypes.object,
  };

  static defaultProps = {
    media: [],
    style: {},
  };

  state = {
    isLightBoxOpen: false,
    activeMediaIndex: 0,
    mediaItems: [], // media (images and videos) to be rendered
  };

  componentDidMount() {
    const mediaItems = this.props.media
      .sort((a, b) => a.order - b.order)
      .map(mediaItem => ({
        src: mediaItem.file,
        type: mediaItem.type.toLowerCase(),
        alt: 'Document',
      }));

    this.setState({ mediaItems });
  }

  onPrevClick = () => {
    let { activeMediaIndex, mediaItems } = this.state;

    if (activeMediaIndex - 1 < 0) {
      activeMediaIndex = mediaItems.length - 1;
    } else {
      activeMediaIndex--;
    }

    this.setState({ activeMediaIndex });
  };

  onNextClick = () => {
    let { activeMediaIndex, mediaItems } = this.state;

    if (activeMediaIndex + 1 > mediaItems.length - 1) {
      activeMediaIndex = 0;
    } else {
      activeMediaIndex++;
    }

    this.setState({ activeMediaIndex });
  };

  showLightBox = () => {
    if (!this.state.mediaItems.length) return;
    this.setState({ isLightBoxOpen: true });
  };

  hideLightBox = () => this.setState({ isLightBoxOpen: false });

  render() {
    const { mediaItems, activeMediaIndex, isLightBoxOpen } = this.state;
    const { style } = this.props;

    const isDisabled = !this.state.mediaItems.length;

    return (
      <span className="DetailsDocumentButton">
        <SquareButton style={style} onClick={this.showLightBox} disabled={isDisabled}>
          {this.props.children}
        </SquareButton>
        <LightBox
          isOpen={isLightBoxOpen}
          activeIndex={activeMediaIndex}
          media={mediaItems}
          onPrevClick={this.onPrevClick}
          onNextClick={this.onNextClick}
          onClose={this.hideLightBox}
        />
      </span>
    );
  }
}

export default DetailsDocumentButton;
