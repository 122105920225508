import React, { Component } from 'react';
import { connect } from 'react-redux';

import './PrivacyPage.css';

class PrivacyPage extends Component {
  render() {
    return (
      <div className="PrivacyPage">
        <p>
          TERMS OF USE & PRIVACY POLICY PLEASE READ THESE TERMS AND CONDITIONS OF SERVICE & PRIVACY
          POLICY (THIS “AGREEMENT”) CAREFULLY BEFORE USING ANY SERVICES (AS DEFINED BELOW) OFFERED
          BY RSE MARKETS, INC. (“Rally”, “WE” OR “US”) INCLUDING ANY OF OUR WEBSITES AND MOBILE
          APPLICATIONS (collectively, the “Services”). By accessing and using any of the Services,
          you are hereby agreeing to be legally bound by the terms and conditions of this Agreement.
          Use of and access to the Services is subject to the terms of this Agreement and all
          applicable laws and regulations. Please read this Agreement carefully. By accessing,
          viewing or otherwise using this website or mobile application you, on behalf of yourself
          and all of your authorized representatives (collectively, the “User” or “you”) agree to
          the terms and conditions of this Agreement. User and Rally are individually hereinafter
          referred to as a “Party” and collectively as the “Parties”. 1. Update to Terms of Use.
          From time to time, Rally may without advance notice: (a) supplement or make changes to
          this Agreement and other rules or access and use procedures, documentation, security
          procedures and standards for equipment related to the Services, (b) change the type and
          location of Rally’s system equipment, facilities or software, or (c) modify or withdraw
          any Service or any Rally database, material, service or system. Any updates to this
          Agreement shall be posted within the Service. User acknowledges its responsibility to
          review the Agreement’s terms and conditions from time to time and to be aware of any such
          changes. Rally reserves the right to terminate access to the Service or take other actions
          it reasonably believes necessary to comply with the law or to protect its rights or those
          of its Users. Any access or attempt to access or use a Service for any unauthorized or
          illegal purpose is strictly prohibited. 2. Services. (a) Subject to the terms of this
          Agreement, Rally grants User a limited, terminable, non-sublicensable, non-transferable,
          non-exclusive right to access and use the Services on a personal computer or mobile device
          that User owns or controls in accordance with the terms of this Agreement and as permitted
          by the applicable terms of service of the mobile app store from where User downloaded any
          mobile application necessary to access the Services and the specific usage rules set forth
          therein. Rally shall have no obligation to support User’s use of the Services in the event
          that: (i) User modifies the Service (or any component thereof) without Rally’s prior
          written consent; (ii) any error is caused in whole or in part by persons other than Rally,
          including without limitation, User’s failure to properly enter or transmit data; or (iii)
          any error is caused in whole or in part by User’s use of the Service in association with
          operating environments and platforms other than those specified by Rally (b) To the extent
          that the Service incorporates any third-party software licensed by Rally, then, in
          addition to the terms set forth herein, User must comply with any additional terms,
          restrictions, or limitations applicable to such third-party software. Additionally, User
          acknowledges that Rally shall have the right to subcontract performance of its hosting
          services, credit card and payment processing services and other services, in which event
          the service levels provided by any third-party hosting provider (including, without
          limitation, any service levels that are stricter than the service levels offered by
          Rally), credit card or payment processor or other service provider will be incorporated
          herein by reference.
        </p>
        <p>4846-8548-9976.7</p>

        <p>
          (c) User shall procure, install and maintain all equipment, data plans, Internet
          connections and other hardware (other than the hardware constituting the support center
          maintained at Rally’s facilities) necessary for User to connect to and access the Service.
          Rally is not responsible for equipment defects, lack of service, or other issues arising
          from third party services or equipment. User is responsible for any applicable charges and
          fees associated with any data plan fees or other subscription charges or fees of any kind
          whatsoever that may be required by User’s carrier to access the Service. 3. User IDs. User
          will comply with all Rally rules and regulations and security restrictions in connection
          with use of the Service. Each User will be assigned a unique User identification name and
          password for access to and use of the Service (“User ID”). User shall be solely
          responsible for ensuring the security and confidentiality of all User IDs. User
          acknowledges that it will be fully responsible for all liabilities incurred through use of
          any User ID and that any transactions under a User ID will be deemed to have been
          performed by User. Use of any User ID other than as provided in this Agreement shall be
          considered a breach of this Agreement by User. 4. Proprietary Rights. User acknowledges
          Rally’s proprietary rights in the Service and associated documentation and shall protect
          the proprietary nature thereof. If User suggests any new features, functionality or
          performance for the Service that Rally subsequently incorporates into the Service (or any
          other software or service), User hereby acknowledges that (i) Rally shall own, and has all
          rights to use, such suggestions and the Service (or any other service) incorporating such
          new features, functionality, or performance shall be the sole and exclusive property of
          Rally; and (ii) all such suggestions shall be free from any confidentiality restrictions
          that might otherwise be imposed upon Rally. User shall not sell, lease, or otherwise
          transfer or distribute the Service or its associated documentation, in whole or in part,
          without prior authorization in writing from Rally In the event of any breach of this
          paragraph, User agrees that Rally will suffer irreparable harm and shall therefore be
          entitled to obtain injunctive relief against User. 5. Conduct Within Public Areas of Site.
          The Service may contain e-mail services, bulletin board services, forums, communities or
          other message or communication facilities designed to enable Users to communicate and
          interact with other Users (the “Public Areas”). User agrees to use the Public Areas only
          to post, send and receive messages and materials that are proper and, when applicable,
          related to the particular Public Area. Rally has no obligation to monitor the Public
          Areas, provided, however, Rally reserves the right to review materials posted to the
          Public Areas and to remove any materials at any time, without notice, for any reason and
          in our sole discretion. Rally reserves the right to terminate or suspend User’s access to
          any or all of the Public Areas at any time, without notice, for any reason whatsoever.
          User acknowledges that postings and other communications posted in the Public Areas by
          other Users are not controlled or endorsed by Rally, and such communications shall not be
          considered reviewed, screened or approved by Rally Statements made in postings, forums,
          bulletin boards and other Public Areas reflect only the views of their respective authors.
          Rally specifically disclaims any liability with regard to the Public Areas and any actions
          resulting from a User’s participation in any Public Areas. User acknowledges and agrees
          that User’s communications with other Users via the Public Area or otherwise are public
          and not private communications, and that User has no expectation of privacy concerning
          User’s use of the Public Areas. User acknowledges that personal information that User
          communicates on the Public Areas may be seen and used by others and result in unsolicited
          communications; therefore, RALLY STRONGLY ENCOURAGES USERS NOT TO DISCLOSE ANY PERSONAL
          INFORMATION ABOUT THEMSELF THROUGH THE PUBLIC AREAS. Rally is not responsible for
          information that User chooses to communicate via the Public Areas, or for the actions of
          other Users. Further, User grants Rally a non-exclusive, transferable, sub-licensable,
        </p>
        <p>4846-8548-9976.7</p>

        <p>
          royalty-free, worldwide license to use, in the promotion of Rally or the Service, any
          content that User posts on or in connection with a Public Area, including, without
          limitation, the posting of such content on Rally’s social media accounts. 6. User
          Information & Privacy; Data Retention and Collection. (a) User grants to Rally a
          non-exclusive license to copy, use and display any and all personally identifiable data,
          information or communications sent, or entered by Users while accessing the Service (“User
          Information”) solely to the extent necessary for Rally to provide the Services, including,
          without limitation, communicating with Users, responding to requests that User makes, to
          improve the Services and to better tailor the features, performance and support of the
          Services, and to offer User’s additional information, opportunities, and functionality.
          User agrees that Rally may provide the User Information to third party service providers
          who work on behalf of or with us to provide some of the features of the Services (“Service
          Providers”) and to help us communicate with you. However, these Service Providers do not
          have any independent right to share this User Information (except pursuant to a legal
          requirement such as a subpoena or warrant). User acknowledges that Rally exercises no
          control whatsoever over the content of the User Information and it is the sole
          responsibility of User, at its own expense, to provide the information, and to ensure that
          the information Users transmit or receive complies with all applicable laws and
          regulations now in place or enacted in the future. Rally is under no obligation, however,
          to review User Information for accuracy, potential liability or for any other reason. (b)
          User agrees that Rally’s and any Service Provider’s obligation to keep or maintain any
          User Information obtained in the course of performance of the Service shall not extend
          beyond the expiration of thirty (30) days following the User’s ceasing use of the Service.
          Rally will provide a copy of User Information in the hosted format (at User’s expense)
          provided that User requests such copy, in writing, within ten (10) days following the User
          ceasing to use the Service. (c) Rally and any Service Providers shall have the right to
          utilize data capture, syndication and analysis tools, and other similar tools, to extract,
          compile, synthesize, and analyze any non-personally identifiable data or information
          (including, without limitation, User Information) resulting from User’s access and use of
          the Service (“Blind Data”). To the extent that any Blind Data is collected by Rally or any
          Service Providers, such Blind Data shall be solely owned by Rally or the Service Provider
          (as applicable) and may be used by Rally or the Service Provider (as applicable) for any
          lawful business purpose without a duty of accounting to User, including, without
          limitation, providing User with the opportunity for User to benchmark itself against its
          peers, provided that the Blind Data is used only in an aggregated form, without
          specifically identifying the source of the Blind Data. Without assuming any obligations or
          liabilities of User, Rally agrees to, and shall procure that any Service Providers agree
          to, use commercially reasonable efforts to comply with the applicable U.S. laws and
          regulations respecting the dissemination and use such Blind Data. (d) As User navigates
          the Services, certain information may also be collected passively, including your Internet
          protocol address, browser type, mobile device and operating system. Rally and any Service
          Providers may also use “Cookies” and navigational data like Uniform Resource Locators
          (URL) to gather information regarding the date and time of your visit and the information
          for which you searched and viewed. This type of information is collected to better
          understand how User’s use the Service and to make the Services more useful to you. Most
          browsers are automatically set to accept Cookies. If User would like more information
          about cookies and how to control and delete cookies in various browsers, such as Internet
          Explorer, Firefox, Safari and Chrome, please visit https://aboutcookies.org/.
        </p>
        <p>4846-8548-9976.7</p>

        <p>
          (e) Except as otherwise stated in this Agreement, Rally does not trade, rent, or share
          User’s “Personal Information” (ie. information about a User that is personally
          identifiable to the User, such as name, address, email address, phone number, social
          security number, employment details, answers to investor suitability questions and credit
          card and payment information) with third parties, unless you ask or authorize us to do so,
          provided, however, that Rally may provide User Information and your Personal Information
          to Service Providers or to any third person as is required by law, rule or regulation
          applicable to Rally, or by any court, governmental agency, commission, authority,
          regulatory body, board or similar entity or body having jurisdiction over Rally that has
          requested the information in accordance with its lawful authority. However, these Service
          Providers do not have any independent right to share this information (except pursuant to
          a legal requirement such as a subpoena or warrant). (f) Rally uses reasonable
          administrative, technical, personnel and physical measures to safeguard Personal
          Information in Rally’s possession against loss, theft and unauthorized use, disclosure or
          modification, provided, however, User acknowledges and agrees that no website, application
          or Internet transmission is completely secure. Accordingly, Rally cannot guarantee that
          unauthorized access, hacking, data loss, or other breaches will never occur and cannot be
          held liable for any unauthorized access or use of the User’s data. User’s use of the
          Services is at User’s own risk. (g) If Rally or its assets are acquired by a third-party,
          that third-party will possess the User Information and Personal Information collected by
          Rally and it will assume the rights and obligations regarding User Information and
          Personal Information as described in this Agreement. Additionally, Rally may disclose User
          Information and Personal Information if Rally believes in good faith that such disclosure
          is necessary to (a) comply with relevant laws or to respond to subpoenas or warrants
          served on us; or (b) to protect and defend the rights or property of us, users of the
          Services or third parties. By using the Services, you hereby agree and consent to the
          transfer or assignment of your User Information and Personal Information as provided
          herein, and you further waive any claims that could be made against us in relation
          thereto. (h) Rally offers you choices regarding the collection, use and sharing of your
          Personal Information. When you receive communications from us, you may indicate a
          preference to stop receiving further communications from us and you will have the
          opportunity to “opt-out” by following the unsubscribe instructions provided in the email
          you receive or by contacting us directly (please see contact information below). You may
          change any of the Personal Information in your Service profile by editing your information
          in your profile. 7. Location Data. Rally may collect, maintain, process and use a User’s
          location data, including the real-time geographic location of a User’s mobile device as
          necessary to provide the Services’ full functionality. By using or activating any
          location-based services on a mobile device, User agrees and consents to Rally’s
          collection, maintenance, publishing, processing and use of User’s location data to provide
          user with the Services. Rally does not collect location data in a form that personally
          identifies Users. User may withdraw this consent at any time by turning off the
          location-based feature on a mobile device or by not using any location-based features.
          Turning off or not using these features may impact the functionality of the Service.
          Location data provided by the Service is for basic navigational purposes only and is not
          intended to be relied upon in situations where precise location information is needed or
          where erroneous, inaccurate or incomplete location data may lead to death, personal
          injury, property or environmental damage. Use of real time route guidance is at the User’s
          sole risk. Location data may not be accurate. Rally does not guaranty the availability,
          accuracy, completeness, reliability or timeliness of information or location displayed by
          the Service.
        </p>
        <p>4846-8548-9976.7</p>

        <p>
          8. User Restrictions. User agrees not to: (i) copy, decompile, reverse engineer,
          disassemble, attempt to derive the source code, modify or create derivative works of the
          Service or any Service related documentation; (ii) upload or distribute in any way files
          that contain viruses, trojans, worms, time bombs, logic bombs, corrupted files, or any
          other similar software or programs that may damage the operation of the Service or
          another’s computer; (iii) use the Service for illegal purposes; (iv) violate or attempt to
          violate the security of the Service and from using the Service to violate the security of
          other websites by any method; (v) access data not intended for User or logging into a
          server or account which User is not authorized to access; (vi) interfere or disrupt
          networks connected to the Service; (vii) upload, post, promote or transmit through the
          Service any unlawful, harassing, libelous, abusive, threatening, harmful, vulgar, obscene,
          hateful, racially, ethnically or otherwise objectionable material of any kind or nature;
          (viii) upload amounts of data and/or materials in excess of any limits specified by Rally
          from time to time and not to create large numbers of accounts or otherwise transmit large
          amounts of data so as to clog the Service or comprise a denial of service attack or
          otherwise so as to have a detrimental effect on the Services; or (ix) upload, promote,
          transmit or post any material that encourages conduct that could constitute a criminal
          offence or give rise to civil liability. Rally may take whatever remedial action it
          determines in its sole discretion is appropriate if User violates these restrictions,
          including, but not limited to, immediate suspension or cancellation of the Service. Rally
          operates the Service from its headquarters in the United States, and the Service is
          intended only for users within the United States. If User uses the Service outside of the
          United States, User is responsible for following applicable local laws and determining,
          among other things, whether User’s use of the Service violates any of those local laws. 9.
          Investment Opportunities. User acknowledges that the Service may, from time to time,
          provide a User with the opportunity to make an investment in the partial ownership of a
          collectible or a legal entity that owns collectible(s). Any such opportunity for a User to
          make any such investment shall, at all times, be made available only through a
          broker-dealer registered with FINRA and the U.S. Securities Exchange Commission and
          subject to the qualification of the User as a qualified purchaser (as determined by the
          applicable securities laws of the United States, as amended from time to time) and further
          application, as determined by Rally in its sole discretion. User acknowledges that it has
          no right to make any investment solely by downloading and/or otherwise accessing the
          Service. Furthermore, any User who invests in a legal entity that owns collectible(s) via
          the Service acknowledges that transfer of such ownership interests is restricted by
          federal and state securities law. As such, Users are prohibited from offering such
          ownership interests for sale via Public Areas. User acknowledges and understands that
          Rally makes no recommendation with respect to the purchase of, or any investment decision
          made in respect of, any security, and is providing User with no investment advice
          whatsoever. User acknowledges that Rally does not and will not provide User with any
          legal, tax, estate planning or accounting advice. Further, Rally will not provide User
          with any advice regarding the suitability, profitability or appropriateness for me of any
          security, investment, or other matter. Unless otherwise specified in writing, I
          acknowledge that Rally employees are not authorized to give any such advice, and I will
          neither solicit nor rely on any investment advice from any Rally employee. Nothing in this
          Agreement constitutes an offer, or a solicitation of an offer, to buy or sell securities.
          User further acknowledges that Rally neither assumes responsibility for nor guarantees the
          accuracy, currency, completeness or usefulness of information, commentary,
          recommendations, advice, investment ideas or other materials that may be accessed by User
          through Public Areas. 10. Electronic Disclosures & Transactions. Because Rally operates
          only on the Internet or as a mobile application, it is necessary for Users to consent to
          transact business with Rally electronically. As part of doing business with Rally we also
          need Users to consent to Rally
        </p>
        <p>4846-8548-9976.7</p>

        <p>
          giving User certain disclosures electronically. By agreeing to this Agreement and use of
          the Service, User agrees to receive electronically all documents, communications, notices,
          contracts, and agreements arising from or relating to User’s use of the Service, including
          User’s registration as an investor through our Service (each, a “Disclosure”). Any
          Disclosures will be provided to User electronically through the Service or via electronic
          mail to the email address User provided. If User requires paper copies of such
          Disclosures, User may request a paper copy will be sent to you at a cost of up to $5.00
          per document requested. Rally may discontinue electronic provision of Disclosures at any
          time in Rally’s sole discretion. User’s consent to receive Disclosures and transact
          business electronically, and Rally’s agreement to do so, applies to any transactions to
          which such Disclosures relate. User’s consent will remain in effect for so long as you are
          a User and, if you are no longer a User, will continue until such a time as all
          Disclosures relevant to transactions that occurred while you were a User have been made.
          11. Payment Processing. (a) Rally, or a third-party partner, may process payment
          transactions on behalf of Users through the appropriate bank, credit card or debit card
          network, as applicable. When a User chooses to use the Service to make an investment in an
          collectible or a collectible holding company the User hereby consents to any third party
          escrow agent appointed by Rally facilitating and/or processing payments made or received
          through the Service, including, without limitation, the consent to access, charge or debit
          a payor’s credit card, debit card, bank account or other payment mechanism. (b) Each User
          acknowledges and agrees that a payment transaction made through the Service is a
          transaction between Users wishing to invest in a collectible or a collectible holding
          company and those offering such investment and not with Rally Rally is not a party to any
          payment transaction related to any such investment and Rally is not a buyer or seller in
          connection with any such investment transaction. (c) Users may only use the Service to
          process a payment transaction for investment in a collectible or a collectible holding
          company through a legitimate, bona fide sale of such product or service. Users may not use
          the Service to purchase any illegal goods or services or for any other underlying illegal
          transaction. (d) Unless held in escrow in accordance with the terms governing an
          investment by the User, as agreed between the User and Rally at the time of investment,
          all payment transactions processed through the Service are non-refundable to a User by
          Rally and are non-reversible by a User through the Service. Users may have additional
          refund or charge-back rights under the agreement a User has with the issuer of the User’s
          payment instrument (i.e. its credit or debit card agreement or bank) or applicable state
          and federal laws. User should review its periodic statement received from the issuer of
          its payment instrument, which will reflect all purchase transactions through the Service.
          12. Disclaimer of Warranties. THE SERVICE IS PROVIDED “AS IS” AND “AS AVAILABLE” WITH ALL
          FAULTS AND WITHOUT WARRANTY OF ANY KIND. RALLY MAKES NO REPRESENTATION OR WARRANTY
          (EXPRESS, IMPLIED OR STATUTORY) WITH RESPECT TO THE SERVICE, INCLUDING, WITHOUT
          LIMITATION, ANY WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE, ACCURACY MERCHANTABILITY, OR
          NONINFRINGEMENT. RALLY DOES NOT WARRANT THAT THE SERVICE WILL OPERATE UNINTERRUPTED OR
          ERROR-FREE. USER ASSUMES THE RESPONSIBILITY TO TAKE ADEQUATE PRECAUTIONS AGAINST DAMAGES
          TO USER’S EQUIPMENT WHICH COULD BE CAUSED BY DEFECTS OR DEFICIENCIES IN ANY SERVICE, ANY
          RALLY INFORMATION, PRODUCT, SERVICE,
        </p>
        <p>4846-8548-9976.7</p>

        <p>
          SYSTEM, DATABASE OR MATERIAL, OR PART THEREOF. USER ALSO ACKNOWLEDGES THAT ELECTRONIC
          COMMUNICATIONS AND DATABASES ARE SUBJECT TO ERRORS, TAMPERING AND BREAK-INS AND THAT WHILE
          RALLY WILL IMPLEMENT REASONABLE SECURITY PRECAUTIONS TO ATTEMPT TO PREVENT SUCH
          OCCURRENCES, RALLY DOES NOT GUARANTEE THAT SUCH EVENTS WILL NOT TAKE PLACE. USER’S
          INSTALLATION AND INPUTS, AS WELL AS THIRD PARTY SYSTEMS AND PROCEDURES, MAY INFLUENCE THE
          OUTPUT AND ERRORS IN ANY ORDER OR ELECTRONIC TRANSMISSION OR COMMUNICATION, AND CAN RESULT
          IN SUBSTANTIAL ERRORS IN OUTPUT, INCLUDING INCORRECT INFORMATION, ORDERS AND AGREEMENTS.
          IN ADDITION, ERRORS MAY BE INTRODUCED INTO INFORMATION OR ORDERS IN THE COURSE OF THEIR
          TRANSMISSION OVER ELECTRONIC NETWORKS. USER SHALL IMPLEMENT AND TAKE RESPONSIBILITY FOR
          APPROPRIATE REVIEW AND CONFIRMATION PROCEDURES TO VERIFY AND CONFIRM ORDERS OR OTHER
          TRANSACTIONS IN WHICH USER PARTICIPATES USING RALLY’S WEBSITE, INFORMATION, SERVICES,
          DATABASES, SYSTEMS OR OTHER MATERIAL. 13. Limitation of Liability. IN NO EVENT SHALL RALLY
          OR ANY SERVICE PROVIDER BE LIABLE FOR ANY LOST OR ANTICIPATED PROFITS, OR ANY DIRECT,
          INDIRECT, INCIDENTAL, PUNITIVE, EXEMPLARY, SPECIAL, RELIANCE OR CONSEQUENTIAL DAMAGES,
          REGARDLESS OF WHETHER IT WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. 14.
          Indemnification. By accessing this Service, User agrees to indemnify, defend, and hold
          harmless Rally and its affiliates and each of their respective directors, officers,
          employees, agents, licensors, advisors, independent contractors and providers
          (collectively referred to herein as “Indemnified Parties”) from and against any and all
          claims, losses, expenses or demands of liability, including attorneys’ fees and costs
          incurred by any Indemnified Party in connection with any claim (including any third-party
          intellectual property claim) arising out of: (i) information, materials and content
          submitted, posted or transmitted by User on or through the Service, (ii) User’s use of the
          Service, and (iii) User’s breach of any provision of this Agreement. User further agrees
          that User will cooperate with Rally as is reasonably required in the defense of any such
          claims. Rally, on behalf of each Indemnified Party, reserves each Indemnified Part’s
          right, at its own expense, to assume the exclusive defense and control of any matter
          otherwise subject to User’s foregoing indemnification obligations, and User shall not, in
          any event, settle any claim or matter without the written consent of Rally and each
          Indemnified Party named as a party in such claim. 15. Term; Termination (a) This Agreement
          shall commence upon Rally providing User with access to the Service and shall continue
          until a Party provides notice of its termination of this Agreement. (b) Upon termination
          of the Agreement for any reason, User’s right to use the Service shall immediately cease.
          Termination of this Agreement shall not relieve either party of any obligation accrued
          prior to the termination date or which by its nature should survive any termination of
          this Agreement. 16. Confidential Information. (a) “Confidential Information” means the
          Service, its associated documentation, Rally’s pricing for the Service, and other
          information and documentation disclosed by Rally under this Agreement that is designated
          as confidential or that by its nature would reasonably be expected to be kept
          confidential. (b) Notwithstanding the previous paragraph, Rally’s Confidential Information
          shall not include information that (i) is or becomes publicly available through no act or
          omission of User; or (ii)
        </p>
        <p>4846-8548-9976.7</p>

        <p>
          was in User’s lawful possession prior to the disclosure and had not been obtained by User
          either directly or indirectly from Rally; or (iii) is lawfully disclosed to User by a
          third party not bound by a duty of non-disclosure; or (iv) is independently developed by
          User without access to or use of the Confidential Information. (c) User agrees to hold the
          Confidential Information in confidence. User agrees not to make the Confidential
          Information available in any form to any third party or to use the Confidential
          Information for any purpose other than performing its obligations or enjoying its rights
          under this Agreement. User agrees to use the same degree of care that it uses to protect
          its own confidential information of a similar nature and value, but in no event less than
          a reasonable standard of care, to ensure that Confidential Information is not disclosed or
          distributed by its employees or agents in violation of the provisions of this Agreement.
          User represents that it has, with each of its employees who may have access to any
          Confidential Information, an appropriate agreement sufficient to enable it to comply with
          all of the confidentiality terms hereof. (d) Notwithstanding the foregoing, User may
          disclose the Confidential Information to the extent that such disclosure is required by
          law or court order, provided, however, that User provides to Rally prior written notice of
          such disclosure and reasonable assistance in obtaining an order protecting the
          Confidential Information from public disclosure. (e) After termination or expiration of
          this Agreement, User shall return to Rally any Confidential Information in User’s
          possession or control. 17. Regarding Children Rally’s Services are not directed toward
          anyone under the age of 18. If User is under the age of 18, User may not create a user
          profile or register for the Services. If you are a parent or guardian who has discovered
          that your child has submitted his or her Personal Information without your permission or
          consent, Rally will take reasonable steps to promptly remove that information from Rally’s
          database at your request. To request removal of your child’s information, please contact
          us at: hello@rallyrd.com. 18. Miscellaneous (a) Relationship Between The Parties. This
          Agreement shall not be construed as creating any agency, partnership, joint venture, or
          other similar legal relationship between the Parties; nor will either Party hold itself
          out as an agent, partner, or joint venture party of the other party. (b) Compliance With
          Law. Each Party shall comply with all applicable laws and regulations of governmental
          bodies or agencies in its performance under this Agreement. (c) Notice. Whenever notice is
          required to be given under this Agreement, such notice shall be in writing and shall be
          addressed to the recipient at the Notice Address of such Party, or to such other address
          as the addressee shall have last furnished in writing to addressor. (d) Waiver. No waiver
          shall be implied from conduct or failure to enforce rights. No waiver shall be effective
          unless in a writing signed by both Parties. (e) Severability. If any provision of this
          Agreement is held to be invalid, void or unenforceable, such provision shall be deemed to
          be restated to reflect as nearly as possible the original intentions of the parties in
          accordance with applicable law, and the remaining provisions of this Agreement shall
          remain in full force and effect. (f) Assignment. User may not assign or delegate any of
          its rights, interest or obligations hereunder, whether by operation of law or otherwise,
          without the prior written consent of Rally
        </p>
        <p>4846-8548-9976.7</p>

        <p>
          The sale of a controlling interest in User through a single transaction or a series of
          transactions shall be deemed an assignment hereunder for which Rally’s consent is
          required. Rally may assign and delegate this Agreement to successors in the event of a
          merger, acquisition or other change in control. This Agreement shall bind and inure to the
          benefit of the parties and their respective successors and permitted assigns. (g)
          Governing Law; Arbitration. This Agreement and all claims related to it, its execution or
          the performance of the parties under it, shall be construed and governed in all respects
          according to the internal laws of the State of New York without regard to the conflict of
          law provisions thereof. User hereby waives any right to bring any claim against Rally in
          court (including any right to a trial by jury), except as provided by the rules of the
          arbitration forum in which a claim is filed. User waives any right it may have to start or
          participate in, and agrees to opt out of, any class action against Rally arising from or
          relating to the Services or this Agreement. Any controversy or claim arising out of or
          relating to an alleged breach of this Agreement or the operation of this Service shall be
          settled by binding arbitration in accordance with the commercial arbitration rules of the
          American Arbitration Association before a single arbitrator. Any such controversy or claim
          shall be arbitrated on an individual basis, and shall not be consolidated in any
          arbitration with any claim or controversy of any other party. The arbitration shall be
          conducted in New York, New York and the language of such arbitration shall be English.
          Rally may seek any interim or preliminary relief from a court of competent jurisdiction in
          New York, New York, necessary to protect Rally’s rights or property pending the completion
          of arbitration. The arbitrator shall not have the authority, power, or right to alter,
          change, amend, modify, add, or subtract from any provision of this Agreement. The
          arbitrator shall have the power to issue mandatory orders and restraining orders in
          connection with the arbitration. The award rendered by the arbitrator shall be final and
          binding on the parties, and judgment may be entered thereon in any court of competent
          jurisdiction. (h) Force Majeure. Rally shall not be in default or otherwise liable for any
          delay in or failure of its performance under this Agreement where such delay or failure
          arises by reason of any Act of God, or any government or any governmental body, war,
          insurrection, acts of terrorism, the elements, strikes or labor disputes, or other similar
          or dissimilar causes beyond Rally’s control. User acknowledges that the performance of
          certain of Rally’s obligations may require the cooperation of third parties designated by
          User and outside the control of Rally In the event such third parties fail to cooperate
          with Rally in a manner that reasonably permits Rally to perform its obligations, such
          failures shall be consider as causes beyond the control of the Rally for the purposes of
          this Section and shall not be the basis for a determination that Rally is in breach of any
          of its obligations under this Agreement or is otherwise liable. (i) Entire Agreement. This
          Agreement shall constitute the complete agreement between the Parties and supersedes all
          previous agreements or representations, written or oral, with respect to the subject
          matter hereof. (j) Contacting Us. If you think that the use of your Personal Information
          is not in alignment with the purpose for which you have provided it to Rally, or if you
          have any questions or suggestions regarding this Agreement or the Service, please contact
          us at: hello@rallyrd.com.
        </p>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivacyPage);
