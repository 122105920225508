export interface IHomeAPI {
  data: Data;
  features: Features;
  sections: Sections;
}

export interface Data {
  assets: Asset[];
}

export interface Asset {
  colors: Colors;
  category: string;
  currentPricePerShare: number;
  displayName: string;
  financialInstrument: FinancialInstrument;
  id: string;
  investedShares: string;
  maxShareInvestment: string;
  media: Media[];
  offering: Offering;
  progressBar: number;
  status: string;
  ticker: string;
  shortName: string;
}

export interface Colors {
  background: string;
  tertiary: string;
}

export interface FinancialInstrument {
  markets: Markets;
  id: string;
  status: 'CLOSED' | 'DISABLED' | 'OPEN';
}

export interface Markets {
  secondaryMarket: SecondaryMarket;
}

export interface SecondaryMarket {
  enabled: boolean;
  sessionHours: SessionHours;
  sessionsFollowMarketHours: string;
  status: string;
}

export interface SessionHours {
  dateClose: Date;
  dateOpen: Date;
  lastDateClose: Date;
  lastDateOpen: Date;
  nextDateClose: Date;
  nextDateOpen: Date;
}

export interface Media {
  type: string;
  file: string;
  id: string;
  modified: string;
  order: string;
  caption: string;
  thumbnail: string;
}

export interface Offering {
  begins: string;
  ends: string;
}

export interface Features {
  calendar: Calendar;
}

export interface Calendar {
  enabled: boolean;
}

export interface Sections {
  banners: Banners;
  carousels: Carousel[];
  highlights: Carousel[];
}

export interface Banners {
  enabled: boolean;
  items: BannersItem[];
}

export interface BannersItem {
  content: Content;
}

export interface Content {
  cta: Cta;
  header: Header;
  image: Image;
  subheader: Header;
}

export interface Cta {
  enabled: boolean;
  text: string;
  reference: Reference;
}

export enum ReferenceType {
  LINK = 'LINK',
  ASSET = 'ASSET',
}

type ReferenceTypeUnion = typeof ReferenceType[keyof typeof ReferenceType];

export interface Reference {
  assetId: string;
  url: string;
  type: ReferenceTypeUnion
}

export interface Header {
  text: string;
}

export interface Image {
  link: string;
}

export enum CarouselType {
  ASSET_OFFERING = 'ASSET_OFFERING',
  ASSET_TRADING = 'ASSET_TRADING',
  ASSET_TRADING_LIVE = 'ASSET_TRADING_LIVE',
  ASSET_INITIAL_OFFERING = 'ASSET_INITIAL_OFFERING',
}

type CarouselTypeUnion = typeof CarouselType[keyof typeof CarouselType];

export interface Carousel {
  enabled: boolean;
  items: CarouselItem[];
  type: CarouselTypeUnion;
}

export interface CarouselItem {
  assetId: string;
}
