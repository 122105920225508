import React, { Component } from 'react';
import moment from 'moment';

import { Input } from './Input';
import { USStatesSelect } from '../USStatesSelect';
import { Button } from '../Buttons/Button';
import { Pencil } from '../Icons/Pencil';

import './EditableField.css';

export class EditableField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      ssnInitialValue: '',
    };
    this.enterEditMode = this.enterEditMode.bind(this);
    this.onSave = this.onSave.bind(this);
    this.checkValid = this.checkValid.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  enterEditMode() {
    if (this.props.fieldType === 'ssn') {
      this.setState({ ssnInitialValue: this.props.value });
      this.onChange('');
    }
    this.setState({ editing: !this.state.editing });
  }
  onSave() {
    this.exitEditMode();
  }
  checkValid() {
    this.exitEditMode();
    this.forceUpdate();
  }
  exitEditMode() {
    if (this.props.fieldType === 'ssn' && !this.props.value) {
      this.onChange(this.state.ssnInitialValue);
      this.setState({ editing: false });
      return;
    }
    this.setState({ editing: false });
    this.props.onSave(this.getSaveValue());
  }

  onChange(value) {
    this.props.onChange(value);
  }

  getSaveValue() {
    let value = this.props.value;
    if (this.props.fieldType === 'date_of_birth') {
      value = this.formatDate(value);
    }
    return value;
  }

  formatPhoneNumber(phoneNumber) {
    let pn = this.digitsOnly(phoneNumber);
    if (pn.length >= 4) {
      phoneNumber = '(' + pn.substr(0, 3) + ') ' + pn.substr(3);
    }
    if (pn.length >= 7) {
      phoneNumber = phoneNumber.substr(0, 9) + '-' + phoneNumber.substr(9, 4);
    }
    return phoneNumber;
  }

  formatSSN(socialSecurityNumber) {
    let ssn = this.digitsOnly(socialSecurityNumber);
    if (ssn.length >= 4) {
      socialSecurityNumber = ssn.substr(0, 3) + '-' + ssn.substr(3);
    }
    if (ssn.length >= 6) {
      socialSecurityNumber =
        socialSecurityNumber.substr(0, 6) + '-' + socialSecurityNumber.substr(6, 4);
    }
    return socialSecurityNumber;
  }

  formatZIP(zipcode) {
    let zip = this.digitsOnly(zipcode);
    if (zip.length >= 5) {
      zipcode = zip.substr(0, 5);
    }
    return zipcode;
  }

  formatAddress(address) {
    let lastComma = address.indexOf(',');
    return lastComma === -1 ? address : address.substr(0, lastComma);
  }

  formatDate(date) {
    return moment(new Date(date), 'YYYY-MM-DD').format('MM/DD/YYYY'); // <- to US format
  }

  digitsOnly(mixedString) {
    return ('' + mixedString).replace(/\D/g, '');
  }

  formatDisplayValue() {
    let value = this.props.value;
    let type = this.props.fieldType || 'text';

    if (typeof value !== 'undefined' && value !== '') {
      switch (type) {
        case 'date':
        case 'date_of_birth':
          value = this.formatDate(value);
          break;
        case 'ssn':
          value = '∗∗∗-∗∗-∗∗∗∗';
          break;
        case 'phone':
          value = this.formatPhoneNumber(value);
          break;
        case 'zip':
          value = this.formatZIP(value);
          break;
        case 'address':
          value = this.formatAddress(value);
          break;
        default:
      }
    }
    return value;
  }

  render() {
    let isNotValid = this.props.valid === false;
    let editableInput =
      this.props.fieldType === 'us_state' ? (
        <USStatesSelect
          placeholder="Select U.S. State"
          selected={this.props.value}
          onChange={this.onChange}
          noValidate
        />
      ) : (
        <Input
          {...this.props}
          value={this.props.value}
          type={this.props.fieldType}
          onBlur={this.handleBlur}
          checkValid={this.checkValid}
          valid={!isNotValid}
        />
      );

    if (this.state.editing === true) {
      return (
        <span className="EditableField">
          {editableInput}
          <Button text="Save" onClick={this.onSave} theme="light" />
        </span>
      );
    } else {
      return (
        <span className="EditableField" onClick={this.enterEditMode}>
          <span className="EditableField-text">{this.formatDisplayValue()}</span>
          <span className="EditableField-icon">
            <Pencil />
          </span>
        </span>
      );
    }
  }
}

export default EditableField;
