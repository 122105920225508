import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const show = keyframes({
  from: {
    opacity: 0,
    transform: 'translateY(-60px)',
  },
  to: {
    opacity: 1,
    transform: 'translateY(0)',
  },
});

export const AnimatedItem = styled.div<{ index: number }>(({ index }) => ({
  animation: `${show} .3s ease forwards`,
  opacity: 0,
  transform: 'translateY(-60px)',
  animationDelay: `${(index + 1) * 0.05}s`,
}));
