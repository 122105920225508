let CONFIG = {};

const featureFlags = {
  gainLossEnabled: true,
  atsCards: 0, // 0 = off, 1 = show all, 2 = show post only (screen 3)
  postOnlyEducationalEnabled: true,
  evolveMigration: false,
};

const PRODUCTION_CONFIG = {
  apiUrl: 'api.production.rallyrd.com',
  apiUrlPromocodes: 'https://ms-promocodes.production.rallyrd.com',
  apiUrlStore: 'https://store-api.production.rallyrd.com',
  apiUrlCustomers: 'https://service-customers.production.rallyrd.com',
  apiUrlFinancialAccounts: 'https://service-financial-accounts.production.rallyrd.com',
  apiUrlFinancialInstruments: 'https://service-financial-instruments.production.rallyrd.com',
  assetRefreshInterval: 15000, // 15 seconds
  docusignCallback: 'https://app.rallyrd.com/dcsigncllbck',
  plaidKey: '4753e5db17bb0fa3b931fc7ef7f409',
  plaidEnv: 'production',
  scheme: 'https://',
  specialAccessUrlSubdomain: '',
  stripeKey: 'pk_live_mtGtSVNVUHrVM9eLG68KQPnK',
  segmentAnalyticsKey: '1CUz66mSNXzwepfXqXbrqxxg30f2rGTU',
  bugsnagKey: '214d33684762390fe1f0fc48690268d9',
  featureFlags: {
    ...featureFlags,
    gainLossEnabled: false,
  },
  poolingAssetTimeout: 7000,
};

const STAGING_CONFIG = {
  apiUrl: 'api.staging.rallyrd.com',
  apiUrlPromocodes: 'https://ms-promocodes.staging.rallyrd.com',
  apiUrlStore: 'https://store-api.staging.rallyrd.com',
  apiUrlCustomers: 'https://service-customers.staging.rallyrd.com',
  apiUrlFinancialAccounts: 'https://service-financial-accounts.staging.rallyrd.com',
  apiUrlFinancialInstruments: 'https://service-financial-instruments.staging.rallyrd.com',
  assetRefreshInterval: 15000, // 15 seconds
  docusignCallback: 'https://app.staging.rallyrd.com/dcsigncllbck',
  plaidEnv: 'sandbox',
  plaidKey: '4753e5db17bb0fa3b931fc7ef7f409',
  scheme: 'https://',
  specialAccessUrlSubdomain: '',
  stripeKey: 'pk_test_RaBGbF44CRCn70Z6wbNTP8cy',
  segmentAnalyticsKey: '2gwjho3ja2rHa4fCRGHfUX0sc18CnUzd',
  bugsnagKey: '214d33684762390fe1f0fc48690268d9',
  featureFlags,
  poolingAssetTimeout: 7000,
};

const DEVELOP_CONFIG = {
  apiUrl: 'api.dev.rallyrd.com',
  apiUrlPromocodes: 'https://ms-promocodes.dev.rallyrd.com',
  apiUrlStore: 'https://store-api.dev.rallyrd.com',
  apiUrlCustomers: 'https://service-customers.dev.rallyrd.com',
  apiUrlFinancialAccounts: 'https://service-financial-accounts.dev.rallyrd.com',
  apiUrlFinancialInstruments: 'https://service-financial-instruments.dev.rallyrd.com',
  assetRefreshInterval: 15000, // 15 seconds
  docusignCallback: 'https://app.dev.rallyrd.com/dcsigncllbck',
  plaidEnv: 'sandbox',
  plaidKey: '4753e5db17bb0fa3b931fc7ef7f409',
  scheme: 'https://',
  stripeKey: 'pk_test_RaBGbF44CRCn70Z6wbNTP8cy',
  segmentAnalyticsKey: 'oRfkLGtGcORia5gRwzIsTA681mprDj5U',
  bugsnagKey: '214d33684762390fe1f0fc48690268d9',
  featureFlags,
  poolingAssetTimeout: 10000,
};

export const IS_PRODUCTION =
  process.env.NODE_ENV === 'production' &&
  !['develop', 'staging'].includes(process.env.REACT_APP_ENV);

if (process.env.REACT_APP_ENV === 'staging') {
  CONFIG = STAGING_CONFIG;
} else if (process.env.REACT_APP_ENV === 'develop') {
  CONFIG = DEVELOP_CONFIG;
} else if (IS_PRODUCTION || process.env.REACT_APP_ENV === 'build-master') {
  CONFIG = PRODUCTION_CONFIG;
} else {
  CONFIG = DEVELOP_CONFIG;
}

if (!IS_PRODUCTION && process.env.USE_LOCAL_API) {
  // CONFIG.apiUrl = 'localhost:8000';
  // CONFIG.scheme = 'http';
}

export default CONFIG;
