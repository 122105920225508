export const ORDER_FLOW_USERACTIONS = {
  INIT_FLOW_EDIT: 'INIT_FLOW_EDIT',
  INIT_FLOW_CANCEL: 'INIT_FLOW_CANCEL',
  EDIT: 'EDIT',
  EDIT_FAILED: 'EDIT_FAILED',
  CANCEL: 'CANCEL',
  CANCEL_FAILED: 'CANCEL_FAILED',
  PROCESSING_UPDATE_CANCEL: 'PROCESSING_UPDATE_CANCEL',
  PROCESSING_UPDATE_EDIT: 'PROCESSING_UPDATE_EDIT',
};

const getModalContent = (type: string) => {
  let title = '';
  let message = '';
  let userActions = [];

  switch(type) {
    case ORDER_FLOW_USERACTIONS.INIT_FLOW_EDIT:
      title = "Attempt to Edit?";
      message = `
        <span>
        Submitting this request does not guarantee order edit. If attempt was successful, you will see your updated order in Your Portfolio.
        </span>
      `
      userActions = [
        {
          id: "dismiss-alertmodal",
          buttonText: "Nevermind",
          buttonActionCompletionType: undefined,
        },
        {
          id: "edit-order-confirmation",
          buttonText: "Yes, Let's try",
          buttonActionCompletionType: ORDER_FLOW_USERACTIONS.EDIT,
        },
      ];
      break;
  
    case ORDER_FLOW_USERACTIONS.INIT_FLOW_CANCEL:
      title = "Attempt to Cancel?";
      message = `
        <span>
          Submitting this request does not guarantee cancellation. If attempt was successful, you will no longer see your order in Your Portfolio.
        </span>
      `
      userActions = [
        {
          id: "dismiss-alertmodal",
          buttonText: "Nevermind",
          buttonActionCompletionType: undefined,
        },
        {
          id: "cancel-order-confirmation",
          buttonText: "Yes, Let's try",
          buttonActionCompletionType: ORDER_FLOW_USERACTIONS.CANCEL,
        },
      ];
      break;
    
    case ORDER_FLOW_USERACTIONS.CANCEL_FAILED:
      title = "Unable to Process Cancelation";
      message = `
        <span>
          Your original order may have been matched & executed before we could fulfill your cancellation request.
        </span>
      `
      userActions = [
        {
          id: "dismiss-alertmodal",
          buttonText: "Okay",
          buttonActionCompletionType: undefined,
        }
      ];
      break;
    
    case ORDER_FLOW_USERACTIONS.EDIT_FAILED:
      title = "Unable to Process your Edit";
      message = `
        <span>
          Your original order may have been matched & executed before we could fulfill your Edit request.
        </span>
      `
      userActions = [
        {
          id: "dismiss-alertmodal",
          buttonText: "Okay",
          buttonActionCompletionType: undefined,
        }
      ];
      break;
    
    case ORDER_FLOW_USERACTIONS.PROCESSING_UPDATE_CANCEL:
      title = "Unable to Cancel Order";
      message = `
        <span>
          Your previous order is still processing. Please try again. If issues persist, please contact us at support@rallyrd.com
        </span>
      `
      userActions = [
        {
          id: "dismiss-alertmodal",
          buttonText: "Okay",
          buttonActionCompletionType: undefined,
        }
      ];
      break;

    case ORDER_FLOW_USERACTIONS.PROCESSING_UPDATE_EDIT:
      title = "Unable to Edit Order";
      message = `
        <span>
          Your previous order is still processing. Please try again. If issues persist, please contact us at support@rallyrd.com
        </span>
      `
      userActions = [
        {
          id: "dismiss-alertmodal",
          buttonText: "Okay",
          buttonActionCompletionType: undefined,
        }
      ];
      break;
  
    default:
      title = "Are You Sure?";
      message = `
        <span>
          You are about to edit your order. Your existing order will be removed from the queue
          and replaced.
        </span>
      `
      userActions = [
        {
          id: "edit-order",
          buttonText: "Edit Order",
          buttonActionCompletionType: ORDER_FLOW_USERACTIONS.INIT_FLOW_EDIT,
        },
        {
          id: "cancel-order",
          buttonText: "Cancel Order",
          buttonActionCompletionType: ORDER_FLOW_USERACTIONS.INIT_FLOW_CANCEL,
        },
        {
          id: "dismiss-alertmodal",
          buttonText: "Go Back",
          buttonActionCompletionType: undefined,
        },
      ]
      break;
  }

  return { title, message, userActions };
}

export default getModalContent;
