import CONFIG from 'config.js';
import * as RequestHelper from './RequestHelper';

export function getMerchandiseProductData(productIds) {
  const endpoint = CONFIG.apiUrlStore + `/store/products/?id=${productIds}`;
  const token = localStorage.getItem('token');

  return RequestHelper.getRequestNoAuth(endpoint, token);
}

export function createMerchandiseProductOrder(payload) {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/merchandising/orders/';
  const token = localStorage.getItem('token');
  const body = JSON.stringify(payload);

  return RequestHelper.postRequest(endpoint, token, body);
}
