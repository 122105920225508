import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'components/shared/Buttons/Button';
import { Price } from 'components/shared/Price';
import { BackNextButtons } from 'components/shared/Buttons/BackNextButtons';

import './NotEnoughCash.css';

class NotEnoughCash extends Component {
  static propTypes = {
    data: PropTypes.object,
    onStepForward: PropTypes.func.isRequired,
    onStepBackward: PropTypes.func.isRequired,
    bankFundingSource: PropTypes.object,
  };

  render() {
    const {
      data: { cost, cash },
    } = this.props;
    const difference = Number(cost) - Number(cash);

    return (
      <div className="NotEnoughCash">
        <div className="NotEnoughCash-description">
          This share purchase is{' '}
          <span className="NotEnoughCash-difference">
            <Price price={difference} />
          </span>{' '}
          greater than your current balance (Your current cash balance = <Price price={cash} />, the
          total purchase = <Price price={cost} />
          ). The remainder will be transferred from your linked account listed below
        </div>
        <div className="NotEnoughCash-linkedbanktransfer">
          <div className="NotEnoughCash-amount">
            <Price price={difference} />
          </div>
          <div className="NotEnoughCash-bankinfo">{this.props.bankFundingSource.info.name}</div>
        </div>
        <Button
          text={<b>TRANSFER FUNDS</b>}
          arrow="right"
          onClick={() => this.props.onStepForward()}
        />
        <BackNextButtons
          buttons={{ back: { text: 'back' }, style: 'centered' }}
          onBackClick={() => this.props.onStepBackward()}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ Investments: { bankFundingSource } }) => ({ bankFundingSource });

export default connect(mapStateToProps)(NotEnoughCash);
