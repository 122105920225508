import CONFIG from 'config.js';
import * as RequestHelper from './RequestHelper';
import {
  deleteRequest,
  getOrderRequest,
  getRequest,
  postRequest,
  putRequest,
  postPrevalidateRequest,
} from './RequestHandler';

export function getAssets(queryParams = undefined) {
  if (typeof queryParams === 'string') queryParams = new URLSearchParams(queryParams);
  queryParams = queryParams ? `?${queryParams.toString()}` : '';

  const endpoint = CONFIG.scheme + CONFIG.apiUrl + `/api/v2/assets/${queryParams}`;
  return getRequest(endpoint, { conditionalType: 'if-none-match' });
}

/**
 * Fetches basic asset info (required for preview screen) that have been modified since the time elapsed from the specified date
 * @param {String} gteTimestamp - unix timestamp in seconds rounded to last 15 seconds
 */
export function getAssetsUpdates(gteTimestamp) {
  const endpoint =
    CONFIG.scheme + CONFIG.apiUrl + `/api/v2/asset/status/?modified_gte=${gteTimestamp}`;
  const token = localStorage.getItem('token');
  return RequestHelper.getRequest(endpoint, token);
}

/**
 * Fetches next tick timestamp value and update interval when initially loading the app or the app needs refresh after certain time not updating.
 */
export function getAssetsUpdatesSchedule() {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + `/api/v2/asset/status/update-schedule/`;
  const token = localStorage.getItem('token');
  return RequestHelper.getRequest(endpoint, token);
}

export function getAssetDetails(assetId) {
  let endpoint = CONFIG.scheme + CONFIG.apiUrl + `/api/v2/assets/${assetId}/`;
  let token = localStorage.getItem('token');
  return RequestHelper.getRequest(endpoint, token);
}

export function getPublicAssets() {
  let endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/59246973-9e2e-4c05-9cb3-e521d4d25ea3/';
  return RequestHelper.getRequestNoAuth(endpoint);
}

export function getTradingDates() {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + `/api/assets/trading-dates/`;
  const token = localStorage.getItem('token');
  return RequestHelper.getRequest(endpoint, token);
}

export function subscribeUserOnAsset(payload) {
  const { asset_status, asset_id } = payload || {};
  const notifyOnTrading = asset_status === 'Closed ICO' || asset_status === 'Trading closed';
  const endpoint = notifyOnTrading ? 'notify-on-trading/' : 'notify_me/';
  const url = CONFIG.scheme + CONFIG.apiUrl + '/api/assets/' + endpoint;
  const token = localStorage.getItem('token');
  const body = JSON.stringify({ asset: asset_id });

  return RequestHelper.postFetchRequestWithoutHandler(url, token, body);
}

export function unsubscribeUserFromAsset(payload) {
  const { asset_status, subscription } = payload || {};
  const notifyOnTrading = asset_status === 'Closed ICO' || asset_status === 'Trading closed';
  const endpoint = notifyOnTrading ? 'notify-on-trading/' : 'notify_me/';
  const url = CONFIG.scheme + CONFIG.apiUrl + '/api/assets/' + endpoint + subscription.id + '/';
  let token = localStorage.getItem('token');

  return RequestHelper.deleteRequestWithoutHandler(url, token);
}

export function addGift(payload) {
  let endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/gift/gifts/';
  let giver_full_name = 'Befana';
  const { gift, onSuccess } = payload;

  if (typeof gift.payment.card !== 'undefined' && typeof gift.payment.card.name !== 'undefined') {
    giver_full_name = gift.payment.card.name;
  }
  if (giver_full_name === 'Befana' && typeof gift.payment.email !== 'undefined') {
    giver_full_name = gift.recipient.email;
  }

  let body_vars = {
    giver_full_name: giver_full_name,
    giver_email: gift.payment.email,
    giver_note: 'gift',
    giver_payment_info: 'gift',
    recipient_email: gift.recipient.email,
    recipient_full_name: gift.recipient.name,
    amount: gift.cost,
    asset: gift.asset.id,
    stripe_token: gift.payment.id,
  };
  let body = JSON.stringify(body_vars);

  RequestHelper.postRequestNoAuth(endpoint, body, onSuccess);
}

export async function getSecurityPricing(finInstId) {
  const endpoint =
    CONFIG.apiUrlFinancialInstruments +
    `/financial-instruments/securities/${finInstId}/markets/pricing`;
  const resp = await getRequest(endpoint, { conditionalType: 'if-none-match' });
  return resp;
}

export async function getSecurityHistoryPricing(finInstId) {
  const endpoint =
    CONFIG.apiUrlFinancialInstruments +
    `/financial-instruments/securities/${finInstId}/markets/pricing/history`;
  const resp = await getRequest(endpoint, { conditionalType: 'if-none-match' });
  return resp;
}

export const postPrevalidateOrder = async (order, accountId) => {
  const endpoint =
    CONFIG.apiUrlFinancialAccounts + `/financial-accounts/${accountId}/orders/prevalidate`;
  const resp = await postPrevalidateRequest(endpoint, order);
  return resp;
};

export const postPrevalidateEditingOrder = async (order, accountId, orderId) => {
  const endpoint =
    CONFIG.apiUrlFinancialAccounts +
    `/financial-accounts/${accountId}/orders/${orderId}/prevalidate`;
  const resp = await postPrevalidateRequest(endpoint, order);
  return resp;
};

export const postOrder = async (order, accountId, idempotencyKey) => {
  const endpoint = CONFIG.apiUrlFinancialAccounts + `/financial-accounts/${accountId}/orders`;
  const resp = await postRequest(endpoint, order, { idempotencyKey });
  return resp;
};

export const getOrder = async (orderId, accountId) => {
  const endpoint =
    CONFIG.apiUrlFinancialAccounts + `/financial-accounts/${accountId}/orders/${orderId}`;
  return await getOrderRequest(endpoint, { conditionalType: 'if-none-match' });
};

export const getOrderById = async (orderId, accountId) => {
  const endpoint =
    CONFIG.apiUrlFinancialAccounts + `/financial-accounts/${accountId}/orders/${orderId}`;
  return await getRequest(endpoint, { conditionalType: 'if-none-match' });
};

export const putOrder = async (orderId, accountId, idempotencyKey, order) => {
  const endpoint =
    CONFIG.apiUrlFinancialAccounts + `/financial-accounts/${accountId}/orders/${orderId}`;
  const resp = await putRequest(endpoint, order, { idempotencyKey, conditionalType: 'if-match' });
  return resp;
};

export const deleteOrder = async (orderId, accountId) => {
  const endpoint =
    CONFIG.apiUrlFinancialAccounts + `/financial-accounts/${accountId}/orders/${orderId}`;
  return await deleteRequest(endpoint, { conditionalType: 'if-match' });
};
