export enum RiskToleranceLevel {
  Conservative = 'CONSERVATIVE',
  Moderate = 'MODERATE',
  Growth = 'GROWTH',
}

export interface IInvestorDetails {
  riskToleranceLevel: RiskToleranceLevel;
}

export interface IPersonalInformation {
  liquidNetWorth: Number;
  pretaxAnnualIncome: Number;
}
export interface IEmploymentDetails {
  status: RiskToleranceLevel;
  employer: string;
  occupationOrJobTitle: string;
}

export enum EnrollmentStatus {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE',
  Processing = 'PROCESSING',
  Rejected = 'REJECTED',
}
