import React, { Component } from 'react';
import './ValidationWarning.css';

export class ValidationWarning extends Component {
  render() {
    let warningMessage = '';
    if (this.props.valid === false) {
      warningMessage = this.props.message || 'invalid';
    }
    return <div className="ValidationWarning">{warningMessage}</div>;
  }
}
