import { Tabs, Tab } from 'components/shared/Tabs';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { AssetStatus, Asset } from 'types/assets';
import { IOList, LiveList } from './CalendarList';
import {
  CalendarTitle,
  CalendarTitleContainer,
  InvestmentCalendarContainer,
  CalendarSimpleList,
} from './styled';
import { DefaultCategories, GroupedAssetList } from './types';
import { CloseButton } from 'components/shared/Buttons';
import { withRouter, RouteComponentProps } from 'react-router';
import { SimpleComponent } from 'types/components';
import { track } from 'services/analytics';
import { SEGMENT_EVENTS, SEGMENT_ACTIONS, SEGMENT_CATEGORIES } from 'constants/analytics';
import { setActiveCalendarTab } from 'actions/ui';
import { addWeekdays } from 'utils/calendar';

const defaultTimezone = 'America/New_York';

// const isAssetInPostOnly = ({ asset_status, financialInstrument }: any) => {
//   const { secondaryMarket } = financialInstrument?.markets ?? {};
//   const timestampDiff = getSecondaryMarketEnableDiff(secondaryMarket);
//   return (
//     ((asset_status === AssetStatus.POST_ONLY && timestampDiff && timestampDiff <= 0) ||
//       (asset_status === AssetStatus.TRADING_OPENED && timestampDiff && timestampDiff <= 0)) &&
//     secondaryMarket.status !== SecondaryMarketStatus.DISABLED
//   );
// };

// const isAssetInLiveTrading = ({ asset_status, financialInstrument }: any) => {
//   const { secondaryMarket } = financialInstrument?.markets ?? {};
//   return (
//     asset_status === AssetStatus.TRADING_OPENED &&
//     secondaryMarket.status === SecondaryMarketStatus.OPEN
//   );
// };

const isInFirstSession = ({ financialInstrument }: Asset) => {
  const { secondaryMarket } = financialInstrument?.markets ?? {};
  if (!secondaryMarket.sessionsStart) {
    return false;
  }
  const sessionsEnd = moment
    .tz(addWeekdays(secondaryMarket.sessionsStart, 4).toString(), defaultTimezone)
    .set('h', 16)
    .set('minutes', 30);
  const sessionsStart = moment
    .tz(secondaryMarket.sessionsStart, defaultTimezone)
    .set('h', 10)
    .set('minutes', 30);

  const isSessionAfterNow = moment.tz(moment.now(), defaultTimezone).isSameOrAfter(sessionsStart);
  const isSessionBeforeEnd = moment.tz(moment.now(), defaultTimezone).isSameOrBefore(sessionsEnd);
  return isSessionAfterNow && isSessionBeforeEnd;
};

const isBeforeFirstSession = ({ financialInstrument }: Asset) => {
  const { secondaryMarket } = financialInstrument?.markets ?? {};
  if (!secondaryMarket.sessionsStart) {
    return false;
  }
  const sessionsStart = moment
    .tz(secondaryMarket.sessionsStart, defaultTimezone)
    .set('h', 10)
    .set('minutes', 30);
  return moment.tz(moment.now(), defaultTimezone).isBefore(sessionsStart);
};

const setAssetHours = (asset: Asset, date?: string) => {
  const { sessionsStart } = asset.financialInstrument.markets.secondaryMarket;
  const dateToSet = date || sessionsStart;
  asset.financialInstrument.markets.secondaryMarket.sessionHours.dateOpen = moment
    .tz(dateToSet, defaultTimezone)
    .set('h', 10)
    .set('m', 30)
    .toString();
  asset.financialInstrument.markets.secondaryMarket.sessionHours.dateClose = moment
    .tz(dateToSet, defaultTimezone)
    .set('h', 16)
    .set('m', 30)
    .toString();
  return asset;
};

const InvestmentCalendar: SimpleComponent<RouteComponentProps> = ({ history }) => {
  const assetList = useSelector<RootState, Asset[]>(store => store.Assets.assetList);
  const isTradingEnabled = useSelector<RootState, boolean>(
    store => store.UI.calendar.tradingEnabled,
  );
  const activeTab = useSelector<RootState, number>(store => store.UI.calendar.activeTab);
  const dispatch = useDispatch();

  const initialList: GroupedAssetList = {
    io: {
      available: [],
      comingSoon: [],
    },
    trading: {
      live: [],
    },
  };

  const result = assetList.reduce<GroupedAssetList>((acc, cur) => {
    const { asset_status, progress_bar } = cur;
    let category = '';
    const { sessionsStart } = cur.financialInstrument?.markets?.secondaryMarket ?? {};
    if (isInFirstSession(cur)) {
      category = DefaultCategories.Live;
      const asset = setAssetHours(cur, moment().toString());
      acc.trading[category] = [...(acc.trading[category] || []), asset];
      return acc;
    }

    if (isBeforeFirstSession(cur)) {
      const { sessionsStart } = cur.financialInstrument.markets.secondaryMarket;
      category = moment(sessionsStart).format('YYYY-MM-DD');
      const asset = setAssetHours(cur);
      acc.trading[category] = [...(acc.trading[category] || []), asset];
      return acc;
    }

    if (asset_status === AssetStatus.COMING_SOON) {
      acc.io[DefaultCategories.ComingSoon] = [...(acc.io[DefaultCategories.ComingSoon] || []), cur];
      return acc;
    }
    if (asset_status === AssetStatus.IO_OPENED && progress_bar < 100) {
      acc.io[DefaultCategories.Available] = [...(acc.io[DefaultCategories.Available] || []), cur];
      return acc;
    }

    if (!isTradingEnabled) {
      return acc;
    }
    if (!sessionsStart) return acc;
    // this part is commented because the product team decided
    // to not show the assets that are scheduled for trading

    // if (isAssetInLiveTrading(cur)) {
    //   category = DefaultCategories.Live;
    //   acc.trading[category] = [...(acc.trading[category] || []), cur];
    //   return acc;
    // }
    // if (isAssetInPostOnly(cur)) {
    //   const { sessionHours } = cur.financialInstrument.markets.secondaryMarket;
    //   if (moment(sessionHours.dateOpen).isAfter(moment.now())) {
    //     category = moment(sessionHours.dateOpen).format('YYYY-MM-DD');
    //     acc.trading[category] = [...(acc.trading[category] || []), cur];
    //   } else if (moment(sessionHours.nextDateOpen).isAfter(moment.now())) {
    //     category = moment(sessionHours.nextDateOpen).format('YYYY-MM-DD');
    //     acc.trading[category] = [...(acc.trading[category] || []), cur];
    //   }
    // }
    return acc;
  }, initialList);

  const handleLiveClick = (index: number) => {
    dispatch(setActiveCalendarTab(index));
    track(SEGMENT_EVENTS.CALENDAR_LIVE_TAP, {
      action: SEGMENT_ACTIONS.OPEN,
      category: SEGMENT_CATEGORIES.INVESTMENT_CALENDAR,
    });
  };

  const handleIOClick = (index: number) => {
    dispatch(setActiveCalendarTab(index));
    track(SEGMENT_EVENTS.CALENDAR_IO_TAP, {
      action: SEGMENT_ACTIONS.OPEN,
      category: SEGMENT_CATEGORIES.INVESTMENT_CALENDAR,
    });
  };

  const handleCalendarClose = () => {
    track(SEGMENT_EVENTS.CALENDAR_CLOSED, {
      action: SEGMENT_ACTIONS.OPEN,
      category: SEGMENT_CATEGORIES.INVESTMENT_CALENDAR,
    });
    history.goBack();
  };

  useEffect(() => {
    return () => {
      dispatch(setActiveCalendarTab(0));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs: Tab[] = [
    {
      title: 'Live Trading',
      content: <LiveList list={result.trading} />,
      onClick: handleLiveClick,
    },
    { title: 'Initial Offering', content: <IOList list={result.io} />, onClick: handleIOClick },
  ];

  return (
    <InvestmentCalendarContainer>
      <CloseButton onClick={handleCalendarClose} />
      <CalendarTitleContainer>
        <CalendarTitle>
          {isTradingEnabled ? 'Investment calendar' : 'Initial offering calendar'}
        </CalendarTitle>
      </CalendarTitleContainer>
      {isTradingEnabled ? (
        <Tabs
          tabs={tabs}
          initialIndex={activeTab}
          mainClassName="investment-tabbar"
          tabsClassName="investment-tab"
          containerClassName="investment-content"
        />
      ) : (
        <CalendarSimpleList className="investment-content">
          <IOList list={result.io} />
        </CalendarSimpleList>
      )}
    </InvestmentCalendarContainer>
  );
};

export default withRouter(InvestmentCalendar);
