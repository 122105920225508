import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Input } from 'components/shared/Inputs/Input';
import { Checkbox } from 'components/shared/Inputs/Checkbox';
import { BackNextButtons } from 'components/shared/Buttons/BackNextButtons';

import { createDwollaCustomer, updateUserData, setTosAgreement } from 'actions';
import { DWOLLA_CUSTOMER_STATUS } from 'constants/main';

import './SocialSecurityNumber.css';
import { isEmpty } from 'utils';
import CONFIG from 'config';

class SocialSecurityNumber extends Component {
  static propTypes = {
    data: PropTypes.object,
    dwollaCustomerStatus: PropTypes.string,
    onFormUpdate: PropTypes.func.isRequired,
    onStepForward: PropTypes.func.isRequired,
    onStepBackward: PropTypes.func.isRequired,
    updateUserData: PropTypes.func.isRequired,
    createDwollaCustomer: PropTypes.func.isRequired,
    validSsn: false,
  };

  state = {
    isStepValid: false,
    tos2: false,
    tos3: false,
  };

  constructor(props) {
    super(props);

    const {
      data: { ssn },
    } = this.props;
    const { tos2, tos3 } = this.state;
    const isSsnValid = this.checkSsn(ssn);

    const isStepValid = isSsnValid && ((tos2 && tos3) || CONFIG.featureFlags.evolveMigration);
    this.state = { isStepValid, loading: false };
    this.initialData = this.props.data;
  }

  componentDidMount() {
    const {
      data: { ssn, agreed_with_DriveWealth_TOS, agreedWithTos3 },
    } = this.props;
    const isSsnValid = this.checkSsn(ssn);

    this.setState(
      {
        validSsn: isSsnValid,
        tos2: agreed_with_DriveWealth_TOS,
        tos3: agreedWithTos3,
      },
      () => this.validateStep(),
    );
  }

  componentWillReceiveProps(newProps) {
    if (this.props.dwollaCustomerStatus !== newProps.dwollaCustomerStatus) {
      if (newProps.dwollaCustomerStatus === DWOLLA_CUSTOMER_STATUS.VERIFIED) {
        this.props.onFormUpdate({ hasDwollaCustomer: true });
      }
    }
  }

  onFieldChange = (fieldKey, fieldValue) =>
    this.props.onFormUpdate(
      {
        [fieldKey]: fieldValue,
      },
      this.validateStep,
    );

  // Navigate user back to 'name and phone' screen
  // handleDwollaRetry = () => this.props.history.push('/app/make-investment/name-phone-info')

  validateStep = () => {
    const {
      data: { ssn },
    } = this.props;
    const { tos2, tos3 } = this.state;
    const isSsnValid = this.checkSsn(ssn);

    const isStepValid = isSsnValid && ((tos2 && tos3) || CONFIG.featureFlags.evolveMigration);
    this.setState({ isStepValid });
  };

  checkSsn = ssn => {
    const validPattern =
      !isEmpty(ssn) && typeof ssn === 'string' && ssn.replace(/\d{3}-\d{2}-\d{4}/, '') === '';

    return validPattern;
  };

  handleNextClick = tosNotCompleted => {
    const {
      data: { ssn, agreed_with_DriveWealth_TOS, agreedWithTos3 },
    } = this.props;
    const { tos2, tos3 } = this.state;

    this.setState({ loading: true });

    const agreedWithLatestTOSAt = new Date().toISOString();

    this.props.updateUserData({ ssn, agreed_with_DriveWealth_TOS, agreedWithTos3 });
    this.onFieldChange('agreed_with_DriveWealth_TOS', tos2);
    this.onFieldChange('agreedWithTos3', tos3);
    this.props.onFormUpdate({ renderDwollaStatusPopup: true });
    if (!this.props.data.hasDwollaCustomer) {
      this.props.createDwollaCustomer({ ssn });
    }

    if (tosNotCompleted) {
      this.props.setTosAgreement(agreedWithLatestTOSAt, () => {
        this.setState({ loading: false });
        this.props.onFormUpdate({ agreeAndContinue: false });
        this.props.onStepForward();
      });
      return;
    }
    this.props.onStepForward();
  };

  handleBackClick = () => {
    if (this.props.resetOnBackPress) {
      this.props.onFormUpdate({ ssn: this.initialData.ssn });
    }
    this.props.onStepBackward();
  };

  handleTosAgreement = (key, value) => {
    this.setState({ [key]: value }, this.validateStep);
  };

  renderTos2and3 = tosDisplay => {
    const {
      data: { agreedWithTos3, agreed_with_DriveWealth_TOS },
    } = this.props;
    const { validSsn, tos2, tos3 } = this.state;
    const tos3Display = !agreed_with_DriveWealth_TOS || !agreedWithTos3 ? 'shown' : 'hidden';

    return (
      <>
        <div className={`SocialSecurityNumber-TOS ${tosDisplay}`}>
          <Checkbox
            name="tos2"
            size="large"
            checked={tos2}
            onChange={e => this.handleTosAgreement('tos2', e.target.checked)}
            disabled={agreed_with_DriveWealth_TOS && !validSsn}
          />
          <label htmlFor="tos2">
            <p className="SocialSecurityNumber-TOS-label">
              I understand that I am opening a brokerage account with Drivewealth LLC, member
              FINRA/SIPC and Dalmore Group LLC, member FINRA/SIPC, and I have read and agree to
              <a
                href="https://share-media-applications-production-rallyrd.s3.amazonaws.com/media/legalmedia/rally-dalmore-group-secondary-trading-agreement-compressed-2021-05-18.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                Dalmore's Secondary Market Trading Agreement,{' '}
              </a>
              <a
                href="https://legal.drivewealth.com/terms-of-use/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                the Drivewealth: Terms of Service,{' '}
              </a>
              <a
                href="https://legal.drivewealth.com/customer-account-agreement/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Client Agreement,{' '}
              </a>
              <a
                href="https://legal.drivewealth.com/alternative-investments-agreement/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Alternative Investment Agreement,{' '}
              </a>
              <a
                href="https://legal.drivewealth.com/disclosures-disclaimers/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Related Disclosures,{' '}
              </a>
              <a href="https://www.dwolla.com/legal/tos/" target="_blank" rel="noopener noreferrer">
                {' '}
                Dwolla: Terms of Service{' '}
              </a>
              and
              <a
                href="https://www.dwolla.com/legal/privacy/#legal-content"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                Privacy Policy.
              </a>
            </p>
          </label>
        </div>
        <div className={`SocialSecurityNumber-TOS ${tos3Display}`}>
          <Checkbox
            name="tos3"
            size="large"
            checked={tos3}
            onChange={e => this.handleTosAgreement('tos3', e.target.checked)}
            disabled={agreedWithTos3 && !validSsn}
          />
          <label htmlFor="tos3">
            <p className="SocialSecurityNumber-TOS-label">
              We are transitioning payment services to add additional payment functionality to our
              platform. This change will take place on or around April 1, 2022. We will notify you
              at the time your new account is created. By agreeing, you authorize us and our payment
              services partner, Dwolla, to create a new Dwolla account for you. Dwolla is a partner
              of Evolve Bank & Trust, member FDIC, and will use Evolve to create your account. I
              have read and agree to
              <a
                href="https://www.dwolla.com/legal/virtual-account-number-personal-account-demand-deposit-agreement-2/#legal-content"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                Evolve’s Customer Account Terms{' '}
              </a>
              and
              <a
                href="https://evolve.mymortgage-online.com/PrivacyPolicy.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '}
                Privacy Policy
              </a>
              .
            </p>
          </label>
        </div>
      </>
    );
  };

  renderTos3Only = () => {
    return (
      <p className="footerText">
        By clicking the “AGREE & CONTINUE” button, you understand that you are opening a brokerage
        account with DriveWealth LLC, member FINRA/SIPC and Dalmore Group LLC, member FINRA/SIPC,
        that you authorize us and our payment services partner, Dwolla, to create a Dwolla account
        for you, and that you have read and agree to
        <a
          href="https://share-media-applications-production-rallyrd.s3.amazonaws.com/media/legalmedia/rally-dalmore-group-secondary-trading-agreement-compressed-2021-05-18.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          Dalmore's Secondary Market Trading Agreement,{' '}
        </a>
        <a
          href="https://legal.drivewealth.com/terms-of-use/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          the Drivewealth: Terms of Service,{' '}
        </a>
        <a
          href="https://legal.drivewealth.com/customer-account-agreement/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Client Agreement,{' '}
        </a>
        <a
          href="https://legal.drivewealth.com/alternative-investments-agreement/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Alternative Investment Agreement,{' '}
        </a>
        <a
          href="https://legal.drivewealth.com/disclosures-disclaimers/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Related Disclosures,{' '}
        </a>
        <a href="https://www.dwolla.com/legal/tos/" target="_blank" rel="noopener noreferrer">
          {' '}
          Dwolla: Terms of Service
        </a>
        and
        <a href="https://www.dwolla.com/legal/privacy/" target="_blank" rel="noopener noreferrer">
          {' '}
          Privacy Policy.
        </a>
        .
      </p>
    );
  };

  render() {
    const {
      data: { ssn, agreedWithTos3, agreed_with_DriveWealth_TOS },
    } = this.props;
    const { loading, validSsn, isStepValid, tos2 } = this.state;
    const tosNotCompleted = !agreedWithTos3 || !agreed_with_DriveWealth_TOS;
    const tosDisplay = tosNotCompleted || !validSsn ? 'shown' : 'hidden';
    const introText = tosNotCompleted ? 'are opening' : 'hold';

    return (
      <div className={`SocialSecurityNumber ${tosDisplay}`}>
        <div className="topWrapper">
          <p className="light">
            Since you {introText} an account with an SEC regulated broker-dealer, you are required
            to provide your Social Security number. Your information is encrypted and securely
            transmitted using SSL.
          </p>
          <Input
            type="ssn"
            description={<span>9-DIGIT SOCIAL SECURITY #</span>}
            value={ssn}
            onChange={value => this.onFieldChange('ssn', value)}
            autoFocus={true}
            shouldMaskSsn={tos2 && validSsn}
            disabled={tos2 && validSsn}
          />
          {!CONFIG.featureFlags.evolveMigration && this.renderTos2and3(tosDisplay)}
        </div>
        <div className="bottomWrapper">
          {CONFIG.featureFlags.evolveMigration && tosNotCompleted && this.renderTos3Only()}
          <BackNextButtons
            buttons={{
              back: { text: 'back' },
              next: { text: tosNotCompleted ? 'agree & continue' : 'next' },
            }}
            loading={loading}
            nextDisabled={!isStepValid}
            onBackClick={this.handleBackClick}
            onNextClick={() => this.handleNextClick(tosNotCompleted)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ Auth: { dwollaCustomerStatus } }) => ({ dwollaCustomerStatus });
const mapDispatchToProps = { createDwollaCustomer, updateUserData, setTosAgreement };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SocialSecurityNumber));
