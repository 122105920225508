import React from 'react';

const CategoryIcon = ({ className, style, ...props }) => {
  return (
    <svg
      className={`CarIcon${className ? ` ${className}` : ''}`}
      style={{ fill: 'currentColor', ...style, width: '24px' }}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.5 28.5H25.5V6H10.5V28.5ZM3 25.5H9V9H3V25.5ZM27 9V25.5H33V9H27Z"
        style={{ fill: 'currentcolor' }}
      />
      <rect x="12" y="7.5" width="12" height="19.5" fill="white" />
      <rect x="4.5" y="10.5" width="3" height="13.5" fill="white" />
      <rect x="28.5" y="10.5" width="3" height="13.5" fill="white" />
    </svg>
  );
};

export default CategoryIcon;
