import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SecondaryButton from 'components/shared/Buttons/SecondaryButton';
import TextButton from 'components/shared/Buttons/TextButton';
import { Spinner } from 'components/shared/Spinner';
import Popup from 'components/shared/Popup';
import ErrorMessage from 'components/shared/Error';
import { deleteBankFundingSource } from 'actions';

import './FundingSourceDeletePopup.scss';

class FundingSourceDeletePopup extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    onAfterDelete: PropTypes.func,
    investmentsData: PropTypes.object,
  };

  state = {
    showUnableToRemoveScreen: false,
    showSuccessScreen: false,
    isLoading: false,
    error: null,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.props.show && !this.isSourceReadyToRemove()) {
      this.setState({ showUnableToRemoveScreen: true });
    }
  }

  isSourceReadyToRemove = () => {
    const { balance, user_investments, transfers } = this.props.investmentsData;

    const isPendingInvestments = !!user_investments.find(i => i.status === 'In progress');
    const isPendingTransfers = !!transfers.find(t => t.status === 'pending');
    const isNonZeroBalance = balance.pending > 0 || balance.cash > 0;

    return !isPendingInvestments && !isPendingTransfers && !isNonZeroBalance;
  };

  onDeleteSource = async () => {
    try {
      this.setState({ isLoading: true });
      await this.props.deleteBankFundingSource();
      this.setState({ isLoading: false, showSuccessScreen: true });
    } catch (err) {
      const error = err.message || 'Something went wrong, please try again later';
      this.setState({ isLoading: false, showUnableToRemoveScreen: true, error });
    }
    this.props.onAfterDelete && this.props.onAfterDelete();
  };

  renderSuccessScreen = () => (
    <div className="FundingSourceDeletePopup-content">
      <h2>Success!</h2>
      <p>
        Your bank account has been deleted. <br />
        You can always link a bank account by clicking on "Transfer/Withdraw".
      </p>
      <SecondaryButton onClick={this.props.onRequestClose} text="Okay" />
    </div>
  );

  renderUnableToRemoveScreen = () => (
    <div className="FundingSourceDeletePopup-content">
      <h2>{this.state.error ? 'Error' : 'Whoops!'}</h2>
      {this.state.error ? (
        <ErrorMessage error={this.state.error} />
      ) : (
        <p>
          You must have a total balance of $0 and no pending transfers or investments, in order to
          remove your bank account.
        </p>
      )}
      <SecondaryButton onClick={this.props.onRequestClose} text="Okay" />
    </div>
  );

  renderWarningScreen = () => (
    <div className="FundingSourceDeletePopup-content">
      <h2>Linked Account</h2>
      <p>You will need to have a verified account on file to transfer or withdraw funds.</p>
      <div className="FundingSourceDeletePopup-info">
        <div className="FundingSourceDeletePopup-name">
          {this.props.investmentsData.bankFundingSource.info.name}
        </div>
        <div className="FundingSourceDeletePopup-status">
          {this.props.investmentsData.bankFundingSource.info.status}
        </div>
      </div>

      <div className="FundingSourceDeletePopup-delete">
        <SecondaryButton onClick={this.onDeleteSource} text="Delete" />
      </div>
      <div className="FundingSourceDeletePopup-cancel">
        <TextButton onClick={this.props.onRequestClose} text="Close" />
      </div>
    </div>
  );

  renderLoader = () => (
    <div className="FundingSourceDeletePopup-loader">
      <Spinner />
    </div>
  );

  render() {
    const { show, onRequestClose } = this.props;
    let content = this.renderWarningScreen();

    if (this.state.showUnableToRemoveScreen) content = this.renderUnableToRemoveScreen();
    if (this.state.isLoading) content = this.renderLoader();
    if (this.state.showSuccessScreen) content = this.renderSuccessScreen();

    return (
      <Popup
        className="FundingSourceDeletePopup"
        show={show}
        disableAutoClose
        onRequestClose={onRequestClose}
      >
        {content}
      </Popup>
    );
  }
}

const mapStateToProps = ({ Investments }) => ({ investmentsData: Investments });
const mapDispatchToProps = { deleteBankFundingSource };

export default connect(mapStateToProps, mapDispatchToProps)(FundingSourceDeletePopup);
