import React, { useState, useLayoutEffect, useRef, ReactChild, ReactChildren, useEffect } from 'react';
import { onClickEvent } from 'types/events';
import { TooltipWrapper } from './styled'

interface ITooltip {
  children?: ReactChild | ReactChildren;
  buttonText?: string;
  type?: 'hover' | 'click';
  icon?: Function;
};

const Tooltip = ({ children, buttonText, type = 'click', icon: Icon }: ITooltip) => {
  const [show, toggleShow] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const wrapperRef = useRef<HTMLDivElement>(null);

  const closeTooltip = (event: any) => {
    const { target }: onClickEvent = event;
    const eventTarget: any = target;
    if (!wrapperRef.current?.contains(eventTarget)) {
      toggleShow(false);
    }
  };

  useEffect(() => {
    if (show) {
      window.addEventListener('click', closeTooltip);
    } else {
      window.removeEventListener('click', closeTooltip);
    }
  });

  const renderTriggerButton = () => {
    const buttonProps = {
      ...(type === 'click' && { onClick: () => toggleShow(!show) }),
      ...(type === 'hover' && {
        onMouseEnter: () => toggleShow(true),
        onMouseLeave: () => toggleShow(false),
      }),
    };

    return (
      <span {...buttonProps}>
        {buttonText || null}
        {Icon ? <Icon /> : null}
      </span>
    );
  }

  function updatePosition() {
    const {
      current
    } = wrapperRef;

    const newPosition = {
      top: current?.offsetTop || 0,
      left: current?.offsetLeft || 0
    };

    setPosition(newPosition)
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', updatePosition);
    updatePosition();
    return () => window.removeEventListener('resize', updatePosition);
  }, []);

  return (
    <TooltipWrapper ref={wrapperRef} show={show} tooltipPosition={position}>
      {renderTriggerButton()}
      <span className="tooltip">
        {children}
      </span>
    </TooltipWrapper>
  )
};

export default Tooltip;

