import React, { useContext } from 'react';
import dompurify from 'dompurify';
import styled from 'styled-components';

import {
  BaseAlertModalView,
  BaseModalTitle,
  BaseModalMessage,
  BaseModalUserActionButtonContainer,
  BaseModalUserActionButton,
} from './ModalStyles';
import { ModalViewContext, getViewContexts } from 'components/shared/Modals/Modal';

const MultiActionAlertModal = ({ viewId, ...props }) => {
  const { viewArray } = useContext(ModalViewContext);
  const { title, message, userActions } = getViewContexts(viewArray, viewId).contextHandlers
    .childrenProps;

  const onUserActionButtonClick = buttonActionCompletionType => {
    getViewContexts(viewArray, viewId).onClose(buttonActionCompletionType);
  };

  return (
    <MultiActionAlertModalView>
      <BaseModalTitle>{title}</BaseModalTitle>
      <BaseModalMessage
        dangerouslySetInnerHTML={{
          __html: dompurify.sanitize(message, { ALLOWED_TAGS: [] }),
        }}
      />
      <UserActionButtonContainer userActionsVerticalAlignment={userActions.length > 2}>
        {userActions.map(action => {
          return (
            action && (
              <UserActionButton
                key={action.id}
                onClick={() =>
                  onUserActionButtonClick(action.buttonActionCompletionType || undefined)
                }
              >
                {action.buttonText}
              </UserActionButton>
            )
          );
        })}
      </UserActionButtonContainer>
    </MultiActionAlertModalView>
  );
};

const MultiActionAlertModalView = styled(BaseAlertModalView)`
  padding-bottom: 0;

  @media only screen and (max-width: 600px) {
    padding-bottom: 0;
  }
`;

const UserActionButtonContainer = styled(BaseModalUserActionButtonContainer)`
  display: flex;
  flex-direction: ${({ userActionsVerticalAlignment }) =>
    userActionsVerticalAlignment ? 'column' : 'row'};

  button {
    border-bottom: ${({ userActionsVerticalAlignment }) => !userActionsVerticalAlignment && 'none'};
  }
`;

const UserActionButton = styled(BaseModalUserActionButton)`
  border-bottom: 1px solid rgb(198, 198, 198);

  :nth-last-child(1) {
    border-bottom: none;
  }
`;

export default MultiActionAlertModal;
