import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import MediaQuery from 'react-responsive';

import { ERROR_CODE_ERROR_MESSAGE_MAP } from 'constants/main';

import LoginSignupFormErrors from 'components/shared/LoginSignup/LoginSignupFormErrors';
import { DotsLoader } from 'components/shared/Loaders';
import { EmailInput, Input, Checkbox } from 'components/shared/Inputs';

import { trimString, setPageTitle } from 'utils';
import { loginUser } from 'actions';

import './LoginForm.css';

const LoginForm = props => {
  const [loading, setLoading] = useState(false);
  const [logoutErrorCode, setLogoutErrorCode] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validationError, setValidationError] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const dispatch = useDispatch();

  const { isAuth } = useSelector(state => state.Auth);

  useEffect(() => {
    const { state } = props.history.location;
    if (state && (state.email || state.password)) {
      setEmail(state.email || '');
      setPassword(state.password || '');
    }

    if (state && state.logoutErrorCode) {
      setLogoutErrorCode(ERROR_CODE_ERROR_MESSAGE_MAP[state.logoutErrorCode]);
    }

    setPageTitle('Login');
  }, [props.history.location]);

  const validateForm = () => {
    const emailTrimmed = trimString(email);
    const passwordTrimmed = trimString(password);
    let logoutErrorCode = '';
    let validationError = '';

    if (!emailTrimmed || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailTrimmed)) {
      validationError = 'Enter a valid email address';
    } else if (!passwordTrimmed) {
      validationError = 'Enter a password';
    }
    setLogoutErrorCode(logoutErrorCode);
    setValidationError(validationError);
    return !validationError;
  };

  // const goToSignUp = e => {
  //   e.preventDefault();
  //   props.history.push('/signup', { email, password });
  // };

  const onFieldChange = (fieldName, fieldValue) => {
    if (fieldName === 'email') {
      setEmail(fieldValue);
    } else if (fieldName === 'password') {
      setPassword(fieldValue);
    } else if (fieldName === 'rememberMe') {
      setRememberMe(fieldValue);
    }

    setLogoutErrorCode('');
    setValidationError('');
    setSubmitError('');
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    document.activeElement.blur();
    if (loading || !validateForm()) return;
    setLoading(true);

    try {
      await dispatch(
        loginUser(
          JSON.stringify({
            email: trimString(email),
            password: trimString(password),
            remember_me: rememberMe,
          }),
        ),
      );
    } catch (err) {
      setSubmitError(err);
      setLoading(false);
    }
  };

  if (isAuth) {
    return <Redirect to={'/app/home'} />;
  }

  return (
    <form className="LoginForm" onSubmit={onFormSubmit} noValidate>
      <div className="LoginForm-inputs">
        <EmailInput
          value={email}
          name="email"
          placeholder="Email Address"
          onChange={email => onFieldChange('email', email)}
        />
        <Input
          value={password}
          name="password"
          type="password"
          placeholder="Password"
          onChange={password => onFieldChange('password', password)}
        />
        <MediaQuery minDeviceWidth={768}>
          <button
            className="AuthenticationPages-actionButton"
            disabled={loading}
            type="submit"
            arrow={loading ? '' : 'forward'}
          >
            {loading ? <DotsLoader color="#fff" /> : 'Login'}
          </button>
          <div className="LoginForm-memory">
            <div className="LoginForm-remember">
              <Checkbox
                checked={rememberMe}
                name="remember_me"
                onChange={() => onFieldChange('rememberMe', !rememberMe)}
              />
              <span className="LoginForm-remember-text">Remember Me</span>
            </div>
            <div className="LoginForm-forget">
              <NavLink to="/password-reset">Forgot Password?</NavLink>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={767}>
          <div className="LoginForm-memory">
            <div className="LoginForm-forget">
              <NavLink to="/password-reset">Forgot?</NavLink>
            </div>
          </div>
          <button className="AuthenticationPages-actionButton" disabled={loading} type="submit">
            {loading ? <DotsLoader color="#fff" /> : 'Login'}
          </button>
        </MediaQuery>
        <LoginSignupFormErrors errors={logoutErrorCode || validationError || submitError} />
      </div>
    </form>
  );
};

export default withRouter(LoginForm);
