import React, { useState } from 'react';
import { SimpleComponent } from 'types/components';
import { Tab as StyledTab, TabActiveBorder, TabBar, TabContent, TabContainer } from './styled';

export type Tab = {
  title: string;
  className?: string;
  content: JSX.Element;
  onClick?: (index: number) => void;
};

type TabsProps = {
  tabs: Tab[];
  tabsClassName?: string;
  containerClassName?: string;
  mainClassName?: string;
  initialIndex?: number;
};

export const Tabs: SimpleComponent<TabsProps> = ({
  tabs,
  initialIndex = 0,
  tabsClassName,
  containerClassName,
  mainClassName,
}) => {
  const [activeIndex, setIndex] = useState(initialIndex);

  const totalTabs = tabs.length;

  return (
    <TabBar className={mainClassName}>
      <TabContainer className={tabsClassName}>
        {tabs.map(({ title, className, onClick }, index) => (
          <StyledTab
            key={title}
            active={index === activeIndex}
            total={totalTabs}
            onClick={() => {
              setIndex(index);
              onClick && onClick(index);
            }}
            className={className}
          >
            {title}
          </StyledTab>
        ))}
        <TabActiveBorder total={totalTabs} position={activeIndex} />
      </TabContainer>
      <TabContent>
        <div className={containerClassName}>{tabs[activeIndex].content}</div>
      </TabContent>
    </TabBar>
  );
};
