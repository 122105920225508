import styled from '@emotion/styled';

const borderHeight = '2px';

export const TabBar = styled.div(({ theme }) => ({
  position: 'relative',
  height: '100%',
}));

export const TabContainer = styled.div(({ theme }) => ({
  width: '100%',
  borderBottom: `${borderHeight} solid ${theme.colors.borderGrey}`,
  boxSizing: 'border-box',
  position: 'absolute',
}));

type TabProps = {
  total: number;
  active: boolean;
};

export const Tab = styled.button<TabProps>(({ theme, active, total }) => ({
  color: active ? theme.colors.newGreen : theme.colors.borderGrey,
  width: `${100 / total}%`,
  background: 'none',
  border: 'none',
  fontSize: '3.4vmin',
  padding: '1.7vmin 0',
  cursor: 'pointer',
  display: 'inline-block',
  fontFamily: 'Gotham Bold',
  textAlign: 'center',
  fontWeight: '700',
  transition: 'all .3s',
  outline: 'none',
  [theme.breakpoints.md]: {
    fontSize: '1.8vmin',
  },
}));

type TabBorderProps = {
  total: number;
  position: number;
};

export const TabActiveBorder = styled.div<TabBorderProps>(({ theme, total, position }) => {
  const widthPercentage = 100 / total;
  return {
    backgroundColor: theme.colors.newGreen,
    width: `${widthPercentage}%`,
    height: borderHeight,
    position: 'absolute',
    bottom: `-${borderHeight}`,
    left: `${widthPercentage * position}%`,
    transition: 'all .3s',
  };
});

export const TabContent = styled.div(({ theme }) => ({
  boxSizing: 'border-box',
  paddingTop: 'calc(6.8vmin + 2px)',
  height: '100%',
  '& > div': {
    height: '100%',
  },
  [theme.breakpoints.md]: {
    paddingTop: 'calc(5.3vmin + 2px)',
  },
}));
