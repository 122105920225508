import styled from '@emotion/styled';
import { StyledWithActiveProp } from 'types/components';

const placeholderColor = '#aaa';

const ButtonBase = styled.button({
  background: 'none',
  cursor: 'pointer',
  border: 'none',
  padding: '0',
  stroke: placeholderColor,
  '&:disabled': {
    cursor: 'default',
  },
});

export const ToggleSearchInputContainer = styled.div<StyledWithActiveProp>(({ theme, active }) => ({
  position: 'relative',
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  backgroundColor: active ? '#444' : 'none',
  padding: '1vw 2vw',
  borderRadius: '5vh',
  transition: 'all .3s',
  [theme.breakpoints.md]: {
    padding: '.35vh 1vh',
    backgroundColor: active ? '#ddd' : 'none',
    display: 'flex',
    transform: 'none',
    top: '0',
    left: '0',
  },
}));

export const SearchInput = styled.input<StyledWithActiveProp>(({ theme, active }) => ({
  margin: 0,
  padding: active ? '0px 1vw' : '0',
  border: 'none',
  outline: 'none',
  background: 'none',
  fontSize: '3.2vw',
  color: '#f7f7f7',
  width: '82vw',
  maxWidth: active ? '81.6vw' : '0',
  opacity: active ? 1 : 0,
  transition: 'all .3s',
  [theme.breakpoints.md]: {
    color: '#222',
    fontWeight: 800,
    fontSize: '1.5vh',
    width: '14vw',
    maxWidth: active ? '14vw' : '0',
    opacity: active ? 1 : 0,
    padding: active ? '0px 5px' : '0',
  },
  '::placeholder': {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: theme.colors.darkGrey,
    fontWeight: 400,
    opacity: 1, // Firefox
    [theme.breakpoints.md]: { color: placeholderColor },
  },

  ':-ms-input-placeholder': {
    /* Internet Explorer 10-11 */
    color: theme.colors.darkGrey,
    fontWeight: 'normal',
    [theme.breakpoints.md]: { color: placeholderColor },
  },

  '::-ms-input-placeholder': {
    /* Microsoft Edge */
    color: theme.colors.darkGrey,
    fontWeight: 'normal',
    [theme.breakpoints.md]: { color: placeholderColor },
  },
}));

export const CloseButton = styled(ButtonBase)<StyledWithActiveProp>(({ theme, active }) => ({
  display: active ? 'flex' : 'none',
  position: 'relative',
  height: '3.4vw',
  width: '3.4vw',
  [theme.breakpoints.md]: {
    height: '1.4vh',
    width: '1.4vh',
  },
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    height: '3.4vw',
    left: '1.7vw',
    width: '1px',
    backgroundColor: placeholderColor,
    [theme.breakpoints.md]: {
      left: '0.7vh',
      height: '1.4vh',
    },
  },
  '::before': {
    transform: 'rotate(45deg)',
  },
  '::after': {
    transform: 'rotate(-45deg)',
  },
}));

export const SearchButton = styled(ButtonBase)<StyledWithActiveProp>(({ theme, active }) => ({
  display: 'flex',
  stroke: active ? theme.colors.lightText : '#333',
  [theme.breakpoints.md]: {
    stroke: theme.colors.lightText,
  },
  svg: {
    width: '5vw',
    height: '5vw',
    [theme.breakpoints.md]: {
      height: '1.9vh',
      width: '1.9vh',
    },
  },
}));
