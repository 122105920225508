import React, { Component } from 'react';

import './TickerTitle.css';

export default class TickerTitle extends Component {
  render() {
    return (
      <div className="TickerTitle">
        <div className="TickerTitle-ticker">{this.props.ticker}</div>
        <div className="TickerTitle-divider" />
        <div className="TickerTitle-title" style={{ color: this.props.color }}>
          {this.props.title}
        </div>
      </div>
    );
  }
}
