import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import history from 'services/history';
import { TextButton } from 'components/shared/Buttons/TextButton';
import { Button } from 'components/shared/Buttons/Button';
import {
  toggleSaPopup,
  toggleAssetsDetails,
  setActiveAsset,
  setAutoGiftCode,
  getAssetDetails,
  clearSaPopup,
} from 'actions';
import { Price } from 'components/shared/Price';
import { Spinner } from 'components/shared/Spinner';
import { ASSET_CATEGORIES } from 'constants/main';
import { mapToCategory } from 'utils';
import * as specialAccessHelper from 'utils/specialAccess';
import analytics from 'services/analytics';
import { SEGMENT_CATEGORIES, SEGMENT_ACTIONS } from 'constants/analytics';
import Popup from 'components/shared/Popup';

import getPopupData from './popupData';
import './index.scss';

const SpecialAccessPopup = props => {
  const dispatch = useDispatch();
  const { saPopup } = useSelector(state => state.UI);
  const { assetList } = useSelector(state => state.Assets);
  const { user } = useSelector(state => state.Auth);

  const [imageLoading, setImageLoading] = useState(true);
  const [isTracked, setIsTracked] = useState(false);
  const [popupData, setPopupData] = useState();

  const { toggleVerifyEmailPopup, ...rest } = props;

  const closePopup = async (shouldClear = true) => {
    dispatch(toggleSaPopup());

    const isVerified = await isEmailVerifiedAsync();

    if (isVerified) {
      specialAccessHelper.setItem({
        userId: user.id,
        assetId: saPopup.asset?.id,
        assetType: saPopup.asset?.type,
      });

      if (saPopup?.asset?.type === 'free_share_program' && shouldClear) {
        dispatch(clearSaPopup());
      }
    }
  };

  const assetInfo = useMemo(() => assetList.find(el => el.id === saPopup?.asset?.id), [
    assetList,
    saPopup,
  ]);

  const isEmailVerifiedAsync = async () => {
    const { emails } = user;
    return emails && !!emails.find(email => email.primary && email.verified);
  };

  const handleClick = async () => {
    if (assetInfo) {
      const updatedAsset = assetList.find(asset => asset.id === assetInfo.id);
      dispatch(setActiveAsset(updatedAsset));
      dispatch(toggleAssetsDetails(true));
      const mappedCategory = mapToCategory(assetInfo.category);
      const category = ASSET_CATEGORIES[mappedCategory];
      const ticker = assetInfo.ticker.replace('#', '');

      await history.push(`/app/assets/${category.pathname}/${ticker}?sapopup=true`);

      if (saPopup?.asset?.type === 'free_share_program') {
        const isVerified = await isEmailVerifiedAsync();

        if (!isVerified) {
          toggleVerifyEmailPopup && toggleVerifyEmailPopup();
        } else {
          // navigate to Select Shares with auto apply
          const sharePrice = (assetInfo.asset_value / assetInfo.number_of_shares).toFixed(2);
          dispatch(setAutoGiftCode(sharePrice));
          dispatch(getAssetDetails(updatedAsset.id));
          history.push('/app/make-investment/', { initialStep: 0, autoGoForward: true });
        }
      } else {
        dispatch(getAssetDetails(updatedAsset.id));
      }
    }
    closePopup(false);
  };

  useEffect(() => {
    if (saPopup?.asset) {
      setPopupData(getPopupData(saPopup?.asset?.type));
    }
  }, [saPopup]);

  useEffect(() => {
    if (assetInfo && !isTracked) {
      analytics.track('EB - Splash Screen Displayed', {
        category: SEGMENT_CATEGORIES.EARLY_BIRD,
        action: SEGMENT_ACTIONS.SCREEN,
        assetTicker: assetInfo.ticker,
      });
      setIsTracked(true);
    }
  }, [assetInfo, isTracked]);

  return (
    <Popup
      className="sapopup__container"
      contentClassName="sapopup__wrapper"
      show={saPopup.show}
      onRequestClose={closePopup}
      subCta={
        popupData?.subCta ? (
          <TextButton
            onClick={popupData.subCta.onClick || closePopup}
            className="sapopup__btn--cancel"
          >
            {popupData.subCta.title}
          </TextButton>
        ) : null
      }
      useDiv
      {...rest}
    >
      <div className="sapopup__wrapper">
        {popupData?.title && <h2 className="sapopup__h2">{popupData.title}</h2>}
        {assetInfo?.display_name && <h1 className="sapopup__h1">{assetInfo.display_name}</h1>}
        {assetInfo?.number_of_shares && assetInfo.asset_value && (
          <h5 className="sapopup__h5">
            {assetInfo.number_of_shares} SHARES
            <span className="sapopup__h5--gray"> | </span>
            <Price price={assetInfo.asset_value / assetInfo.number_of_shares} /> PER SHARE
          </h5>
        )}
        {assetInfo?.home_image && (
          <div className="sapopup__img-container">
            <img
              className="sapopup__img"
              style={{ display: imageLoading ? 'none' : 'block' }}
              onLoad={() => setImageLoading(false)}
              src={assetInfo.portal_image}
              alt={assetInfo.ticker}
            />
            {imageLoading && <Spinner />}
          </div>
        )}
        {popupData?.description && <p className="sapopup__p">{popupData.description}</p>}
      </div>
      {popupData?.cta && (
        <div className="sapopup__btn-wrapper">
          <Button className="sapopup__btn" onClick={handleClick}>
            {popupData.cta.title}
          </Button>
        </div>
      )}
    </Popup>
  );
};

SpecialAccessPopup.propTypes = {
  toggleVerifyEmailPopup: PropTypes.func,
};

export default SpecialAccessPopup;
