//@ts-nocheck
import { useDispatch } from 'react-redux';
import { getActiveAssetDetails } from 'utils/trading';
import analytics from 'services/analytics';
import { SEGMENT_ACTIONS, SEGMENT_CATEGORIES, SEGMENT_EVENTS } from 'constants/analytics';
import React, { useEffect, useState } from 'react';
import {
  TABS,
  TABSTypeUnion,
} from 'components/views/app/Assets/AssetDetails/scenes/AssetValueChart/AssetValueChart.tsx';

type AssetId = string | number | undefined;

export const useTracking = () => {
  const dispatch = useDispatch();

  const homeIOHeroAssetTapped = async (assetId: AssetId, currentAsset) => {
    const assetDetails = await dispatch(getActiveAssetDetails(assetId));
    return analytics.track(SEGMENT_EVENTS.HOME_IO_HERO_ASSET_TAPPED, {
      action: SEGMENT_ACTIONS.OPEN,
      category: SEGMENT_CATEGORIES.HOME,
      assetTicker: assetDetails.assetTicker,
      assetState: assetDetails.assetStatus,
      assetName: assetDetails.assetName,
      pricePerShare: currentAsset.currentPricePerShare,
    });
  };

  const homeTradingHeroAssetTapped = async (assetId: AssetId, currentAsset) => {
    const assetDetails = await dispatch(getActiveAssetDetails(assetId));
    return analytics.track(SEGMENT_EVENTS.HOME_TRADING_HERO_ASSET_TAPPED, {
      action: SEGMENT_ACTIONS.OPEN,
      category: SEGMENT_CATEGORIES.HOME,
      assetTicker: assetDetails.assetTicker,
      assetState: assetDetails.assetStatus,
      assetName: assetDetails.assetName,
      pricePerShare: currentAsset.currentPricePerShare,
      currentValue: assetDetails.currentTotalValue,
    });
  };

  const homeTradingCarouselAssetTapped = async (assetId: AssetId, currentAsset, position) => {
    const assetDetails = await dispatch(getActiveAssetDetails(assetId));
    return analytics.track(SEGMENT_EVENTS.HOME_TRADING_CAROUSEL_ASSET_TAPPED, {
      action: SEGMENT_ACTIONS.OPEN,
      category: SEGMENT_CATEGORIES.HOME,
      assetTicker: assetDetails.assetTicker,
      assetState: assetDetails.assetStatus,
      assetName: assetDetails.assetName,
      pricePerShare: currentAsset.currentPricePerShare,
      currentValue: assetDetails.currentTotalValue,
      position: position,
    });
  };

  const homeOfferingCarouselAssetTapped = async (assetId: AssetId, currentAsset, position) => {
    const assetDetails = await dispatch(getActiveAssetDetails(assetId));
    return analytics.track(SEGMENT_EVENTS.HOME_OFFERING_CAROUSEL_ASSET_TAPPED, {
      action: SEGMENT_ACTIONS.OPEN,
      category: SEGMENT_CATEGORIES.HOME,
      assetTicker: assetDetails.assetTicker,
      assetState: assetDetails.assetStatus,
      assetName: assetDetails.assetName,
      pricePerShare: assetDetails.currentSharePrice,
      currentValue: assetDetails.currentTotalValue,
      position: position,
    });
  };

  const homeViewed = () => {
    return analytics.track(SEGMENT_EVENTS.HOME_VIEW, {
      action: SEGMENT_ACTIONS.OPEN,
      category: SEGMENT_CATEGORIES.HOME,
    });
  };

  const homeBannerViewed = ({ bannerTitle, bannerSubHeader, bannerCTA }) => {
    return analytics.track(SEGMENT_EVENTS.HOME_BANNER_VIEW, {
      action: SEGMENT_ACTIONS.OPEN,
      category: SEGMENT_CATEGORIES.HOME_BANNER,
      bannerTitle,
      bannerSubHeader,
      bannerCTA,
    });
  };

  const homeBannerTapped = ({ bannerTitle, bannerSubHeader, bannerCTA }) => {
    return analytics.track(SEGMENT_EVENTS.HOME_BANNER_TAPPED, {
      action: SEGMENT_ACTIONS.OPEN,
      category: SEGMENT_CATEGORIES.HOME_BANNER,
      bannerTitle,
      bannerSubHeader,
      bannerCTA,
    });
  };

  const homeBannerClosed = ({ bannerTitle, bannerSubHeader, bannerCTA }) => {
    return analytics.track(SEGMENT_EVENTS.HOME_BANNER_CLOSED, {
      action: SEGMENT_ACTIONS.CLOSE,
      category: SEGMENT_CATEGORIES.HOME_BANNER,
      bannerTitle,
      bannerSubHeader,
      bannerCTA,
    });
  };

  const homeClosed = () => {
    return analytics.track(SEGMENT_EVENTS.HOME_CLOSED, {
      action: SEGMENT_ACTIONS.CLOSE,
      category: SEGMENT_CATEGORIES.HOME,
    });
  };

  interface IChartViewed {
    assetId: AssetId;
    chartType: TABSTypeUnion;
    timeFrame: string;
    valueChange: string | number;
    transactions: number;
  }
  const chartViewed = async ({
    assetId,
    chartType,
    timeFrame,
    valueChange,
    transactions,
  }: IChartViewed) => {
    const assetDetails = await dispatch(getActiveAssetDetails(assetId));
    return analytics.track(SEGMENT_EVENTS.CHART_VIEW, {
      action: SEGMENT_ACTIONS.VIEW,
      category: SEGMENT_CATEGORIES.CHART,
      assetState: assetDetails.assetStatus,
      assetCategory: assetDetails.assetCategory,
      assetTicker: assetDetails.assetTicker,
      valueChange,
      assetValue: assetDetails.currentTotalValue,
      timeFrame,
      chartType: chartType,
      transactions,
      ioSharePrice: assetDetails.ioPrice,
      sinceIO: '',
      lastTradingWindow: assetDetails?.trading?.lastTradingWindow,
    });
  };

  interface IChartTimeframeTapped {
    assetId: AssetId;
    chartType: TABSTypeUnion;
    timeFrame: string;
    transactions: number;
    sinceIO: number | string;
  }
  const chartTimeframeTapped = async ({
    assetId,
    sinceIO,
    transactions,
    chartType,
  }: IChartTimeframeTapped) => {
    const assetDetails = await dispatch(getActiveAssetDetails(assetId));
    return analytics.track(SEGMENT_EVENTS.CHART_TIMEFRAME_TAPPED, {
      action: SEGMENT_ACTIONS.TAPPED,
      category: SEGMENT_CATEGORIES.CHART,
      assetState: assetDetails.assetStatus,
      assetCategory: assetDetails.assetCategory,
      assetTicker: assetDetails.assetTicker,
      valueChange: '',
      assetValue: assetDetails.currentTotalValue,
      chartType,
      transactions,
      ioSharePrice: assetDetails.ioPrice,
      sinceIO,
      lastTradingWindow: assetDetails?.trading?.lastTradingWindow,
    });
  };

  interface IChartValueTapped {
    assetId: AssetId;
    activeChartPointValue: any;
    transactions: number;
    timeFrame: string;
    chartType: TABSTypeUnion;
    sinceIO: string | number;
    valueChange: string | number;
  }
  const chartValueTapped = async ({
    assetId,
    activeChartPointValue,
    transactions,
    timeFrame,
    chartType,
    sinceIO,
    valueChange,
  }: IChartValueTapped) => {
    const assetDetails = await dispatch(getActiveAssetDetails(assetId));
    return analytics.track(SEGMENT_EVENTS.CHART_VALUE_TAPPED, {
      action: SEGMENT_ACTIONS.TAPPED,
      category: SEGMENT_CATEGORIES.CHART,
      assetState: assetDetails.assetStatus,
      assetCategory: assetDetails.assetCategory,
      assetTicker: assetDetails.assetTicker,
      valueChange,
      assetValue: assetDetails.currentTotalValue,
      timeFrame,
      chartType,
      transactions,
      ioSharePrice: assetDetails.ioPrice,
      sinceIO,
      lastTradingWindow: assetDetails?.trading?.lastTradingWindow,
    });
  };

  const useChartViewed = ({
    assetId,
    chartType,
    timeFrame,
    valueChange,
    transactions,
  }: IChartViewed) => {
    const [currentChartType, setCurrentChartType] = useState(TABS.SIMILAR_ASSET_SALE);
    const renders = React.useRef(0);

    // track only first render
    useEffect(() => {
      if (!assetId) return;
      if (renders.current) return;
      renders.current++;
      (async () => chartViewed({ assetId, chartType, timeFrame, valueChange, transactions }))();
      // eslint-disable-next-line
    }, [assetId, chartType]);

    // track on change of chartType
    useEffect(() => {
      if (chartType === currentChartType) return;
      (async () =>
        chartViewed({ assetId, chartType, timeFrame, valueChange, transactions }).then(() =>
          setCurrentChartType(chartType),
        ))();
        // eslint-disable-next-line
    }, [chartType]);
  };

  const useChartValueTapped = ({
    assetId,
    activeChartPointValue,
    transactions,
    timeFrame,
    chartType,
    sinceIO,
    valueChange,
  }: IChartValueTapped) => {
    useEffect(() => {
      if (!assetId || !activeChartPointValue) return;
      (async () =>
        chartValueTapped({
          assetId,
          activeChartPointValue,
          transactions,
          timeFrame,
          chartType,
          sinceIO,
        }))();
        // eslint-disable-next-line
    }, [assetId, activeChartPointValue]);
  };

  return {
    chartTracking: {
      useChartViewed,
      useChartValueTapped,
      chartTimeframeTapped,
      chartValueTapped,
    },
    homeTracking: {
      homeIOHeroAssetTapped,
      homeViewed,
      homeTradingHeroAssetTapped,
      homeTradingCarouselAssetTapped,
      homeOfferingCarouselAssetTapped,
      homeBannerViewed,
      homeBannerTapped,
      homeBannerClosed,
      homeClosed,
    },
  };
};
