import React, { Component } from 'react';
import { connect } from 'react-redux';

import { isEmpty } from 'utils';
import { Price } from 'components/shared/Price';

class ClearingPrice extends Component {
  render() {
    const { activeTradingWindow, isLoadingTradingWindow } = this.props;
    if (!activeTradingWindow) return null;

    const { closing_price, matched_asks, matched_bids } = activeTradingWindow || {};
    const isMatchedOrders = !isEmpty(matched_asks) && !isEmpty(matched_bids);

    return (
      <span
        className="ClearingPrice"
        style={{ visibility: isLoadingTradingWindow ? 'hidden' : 'visible' }}
      >
        {closing_price && isMatchedOrders ? <Price price={closing_price} keepZeros /> : 'N/A'}
      </span>
    );
  }
}

const mapStateToProps = ({ Trading: { activeTradingWindow, isLoadingTradingWindow } }) => ({
  activeTradingWindow,
  isLoadingTradingWindow,
});

export default connect(mapStateToProps)(ClearingPrice);
