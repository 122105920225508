import React, { Component } from 'react';
import { Provider } from 'react-redux';
import RootRouter from './RootRouter';
import ErrorPage from 'components/shared/Pages/Error/ErrorPage';
import { TextButton } from 'components/shared/Buttons/TextButton';
import { ThemeProvider } from '@emotion/react';
import store from 'store';

import analytics from 'services/analytics';

import './App.css';
import { theme } from 'theme';

class App extends Component {
  constructor(props) {
    super(props);

    const browserCompatibilityError = this.checkBrowserCompatibility();

    this.state = { browserCompatibilityError: browserCompatibilityError || null };
  }

  componentDidMount() {
    analytics.init();
  }

  checkBrowserCompatibility = () => {
    const cookiesError = {
      title: 'Please Enable Cookies to Continue.',
      message: 'In order to use Rally Please enable cookies in your web browser',
    };

    const localStorageError = {
      title: 'Please Enable Local Storage to Continue',
      message: 'In order to use Rally Please enable local storage in your web browser',
    };

    if (!navigator.cookieEnabled) return cookiesError;

    if (!window.localStorage) return localStorageError;

    try {
      localStorage.setItem('test', 'test');
      localStorage.removeItem('test');
    } catch (e) {
      if (e.name === 'QUOTA_EXCEEDED_ERR' || e.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
        return Object.assign(localStorageError, {
          title: 'Browser Local Storage Quota is Exceeded',
          message: 'In order to use Rally Please clear local storage in your web browser',
        });
      }
      return localStorageError;
    }

    return null;
  };

  renderCompatibilityError = error => {
    const messageContent = (
      <div>
        <div>{error.message}</div>
        <div>
          Once you finish
          <span style={{ display: 'inline-block', margin: '0 -4vmin' }}>
            <TextButton style={{ padding: '0' }} onClick={() => window.location.reload()}>
              Click Here
            </TextButton>
          </span>
          to continue
        </div>
      </div>
    );

    return <ErrorPage title={error.title} message={messageContent} />;
  };

  render() {
    const { browserCompatibilityError } = this.state;

    if (browserCompatibilityError) {
      return this.renderCompatibilityError(browserCompatibilityError);
    }

    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <RootRouter />
        </ThemeProvider>
      </Provider>
    );
  }
}

export default App;
