import React from 'react';
import { withRouter } from 'react-router';

const ExternalLink = ({ history }: { history: any }) => {
  const headerHeight = 65.93;
  console.log('history', history);
  return (
    <iframe
      title={'external-link'}
      src={history?.location?.state?.url}
      style={{ width: '100vw', height: `calc(100vh - ${headerHeight}px)` }}
      frameBorder={0}
    />
  );
};
export default withRouter(ExternalLink);
