import React from 'react';
import './Fullpage.scss';

export const Fullpage = ({ children }) => (
  <div className="Fullpage">
    <div className="Fullpage-contents">{children}</div>
  </div>
);

export default Fullpage;
