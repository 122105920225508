import styled from '@emotion/styled';

type BidAskPriceInfoProps = {
  isBid: boolean;
};

export const BidAskPriceInfo = styled.div<BidAskPriceInfoProps>(({ theme, isBid }) => [
  {
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '5.5vmin',
    position: 'absolute',
    minWidth: '17vmin',
    '.title': {
      fontFamily: 'Gotham Bold',
      fontWeight: 700,
      fontSize: '0.6em',
      color: '#9BA0A5',
    },
    '.Price': {
      fontFamily: 'Gotham Black',
      fontWeight: 900,
      fontSize: '1em',
      color: '#FFFFFF',
    },
    ...(isBid && {
      right: '100%',
    }),
    ...(!isBid && {
      left: '100%',
    }),
    [theme.breakpoints.sm]: {
      fontSize: '3vmin',
      verticalAlign: 'top',
      minWidth: '10.5vmin',
    },
  },
]);
