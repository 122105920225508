export const NETWORK_ERROR_TYPES = {
  GENERIC: 'GENERIC',
  CLIENT: 'CLIENT',
  CLIENT_UNAUTHORIZED: 'CLIENT_UNAUTHORIZED',
  SERVER: 'SERVER',
  UNKNOWN: 'UNKNOWN',
  UNKNOWN_ONLINE: 'UNKNOWN_ONLINE',
  UNKNOWN_OFFLINE: 'UNKNOWN_OFFLINE',
  REQ_SETUP_FAIL: 'REQUEST_SETUP_FAILURE',
  NETWORK_TIMEOUT: 'NETWORK_TIMEOUT',
};

export const RESPONSE_ERROR_MESSAGES = {
  BALANCE_TRANSFER_SEVENDAYS_LIMIT:
    'Deposits must stay in your account for seven days before they are available for withdraw.',
  TRADINGWINDOW_ORDER_INSUFFICIENT_FUNDS: 'Insufficient funds.',
  TRADINGWINDOW_ORDER_NOT_ENOUGH_SHARES: 'Not enough shares.',
  TRADINGWINDOW_ORDER_CROSSING:
    'Unfortunately, you are not able to place crossing Bid/Ask orders in the same trading window.',
};

export const ERROR_CUSTOM_OVERRIDE_MESSAGES = {
  BALANCE_TRANSFER_SEVENDAYS_LIMIT:
    'For security purposes, deposits must fully process to your cash balance before being withdrawn - this may take up to 5 business days. For immediate assistance, contact support below.',
  TRADINGWINDOW_ORDER_INSUFFICIENT_FUNDS:
    'You do not have enough of a cash balance to cover the estimated cost. Please adjust your order accordingly. You may fund your account for future trading windows.',
  TRADINGWINDOW_ORDER_NOT_ENOUGH_SHARES:
    'You do not have enough shares of the asset. Please adjust your order accordingly.',
  GENERAL_ORDER_ADJUST: 'Please adjust your order accordingly.',
};

export const BASE_EXCEPTION = 'BaseException';
export const NO_FREE_PROMOCODES_EXCEPTION = 'NoFreePromocodesException';
