import styled from '@emotion/styled';
import { StyledWithActiveProp } from 'types/components';

const headerHeight = '7vh';

const handlerLeftProp = ({ active, isHome }: any) => {
  if (active && isHome) return '0px';
  if (isHome) return '0px';
  if (active) return '0px';
  return '35px';
};

export const MobileSearchHeaderContainer = styled.div<StyledWithActiveProp & { isHome: boolean }>(
  ({ theme, active, isHome }) => ({
    position: active ? 'fixed' : 'absolute',
    left: handlerLeftProp({ active, isHome }),
    top: 0,
    width: active ? '100%' : '7.4vh',
    height: headerHeight,
    transition: '0.3s width',
    zIndex: active ? 1200 : 1,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '2vw',
    [theme.breakpoints.sm]: {
      display: 'none',
    },
  }),
);

export const MobileSearchOverlay = styled.div<StyledWithActiveProp>(({ theme, active }) => ({
  backgroundColor: 'rgba(0, 0, 0, .90)',
  position: 'fixed',
  paddingTop: headerHeight,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 10,
  transform: active ? 'translateY(0%)' : 'translateY(100%)',
  transition: '0.3s transform',
  [theme.breakpoints.sm]: {
    display: 'none',
  },
}));
