import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { SUPPORTED_APPLICATION_MODES } from 'constants/main';

import './AssetEarlyAccessBanner.scss';

class AssetEarlyAccessBanner extends Component {
  render() {
    if (!this.props.applicationMode === SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS) return '';

    const offeringStarts = this.props.activeAsset.offering_starts;

    let bannerText = 'OFFERING FOR THIS ASSET IS NOT YET SCHEDULED';
    if (offeringStarts) {
      bannerText = `OFFERING OPEN TO THE PUBLIC ${moment(offeringStarts).format('MM/DD/YYYY, hA')}`;
    }

    return (
      <div className={'assets-early-access-banner'}>
        <b>EARLY ACCESS</b>&nbsp;| {bannerText}
      </div>
    );
  }
}

const mapStateToProps = ({ Assets: { activeAsset }, UI: { applicationMode } }) => ({
  activeAsset,
  applicationMode,
});

export default connect(mapStateToProps)(AssetEarlyAccessBanner);
