import ats1 from 'images/ats1.png';
import ats2 from 'images/ats2.png';
import ats3 from 'images/ats3.png';

const stepsProps = [
  {
    title: 'Instant Trade is Here!',
    subtitle: 'You’ll be able to Buy & Sell Shares instantly during Market Hours',
    image: {
      src: ats1,
      alt: 'ats1',
    },
    text: `
      <p>
        Place a BID or ASK during Market Hours, <strong>Weekdays 10:30 AM - 4:30 PM ET</strong>. If your BID or ASK is eligible to match, it will execute immediately!
      </p>
      <p>
        Instant Trading will be available for any asset in <strong>“TRADING NOW”</strong> status.
      </p>
    `,
    buttonText: 'Continue',
    caret: true,
  },
  {
    title: 'With Instant Buy',
    subtitle: 'You can place an order to buy 1 share immediately at the lowest ASK',
    image: {
      src: ats2,
      alt: 'ats2',
      text: 'Choose between Instant Buy <br /> OR <br /> Buy Limit Order'
    },
    text: `
      <p>
        Instant Buy & Custom Limit Order options will be available for assets that are in Live Trading.
      </p>
      <p>
        Like Instant Buy, Custom Limit Orders may also match & execute immediately.
      </p>
    `,
    buttonText: 'Continue',
    caret: true,
  },
  {
    title: 'Place Orders 24/7',
    subtitle: 'You can still place orders outside of Market Hours!',
    image: {
      src: ats3,
      alt: 'ats3'
    },
    text: `
      <p>
        You can place a BID or ASK outside of Market Hours, which will be eligible to match when Market Hours open.
      </p>
      <p>
        Assets outside of Market Hours will be in <strong>“ACCEPTING ORDERS”</strong> status.
      </p>
    `,
    buttonText: 'Explore',
    caret: true,
  },
];

export default stepsProps;
