import React from 'react';

const Arrow = () => {
  return (
    <svg className="arrow" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1L9 9L1 17"
        stroke="#A7A7A8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Arrow;
