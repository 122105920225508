import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';

/**
 * Renders a set of routes for each of controlled 'steps' components.
 * Form data is passed to steps components via 'data' prop.
 */
class MultistepForm extends Component {
  static propTypes = {
    // The root url to which the step's paths will be attached
    rootUrl: PropTypes.string.isRequired,
    // Array of form steps
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string.isRequired, // url path for this step
        render: PropTypes.elementType.isRequired, // render function should return some JSX
        needToSkip: PropTypes.bool, // should the step be skipped
      }),
    ),
    // The section it belongs (used for skipping routes on similar flows e.g. Passport)
    section: PropTypes.string,
    // Array index of the step to be initially rendered
    initialStepIndex: PropTypes.string,
    // Fires every time when step is changed (optional)
    onStepChange: PropTypes.func,
    // Form close handler
    onFormClose: PropTypes.func,
    // Takes updates object and passes it to container component
    onFormUpdate: PropTypes.func,
    // form data
    data: PropTypes.object,
    // initial passport step index
    initialPassportIndex: PropTypes.number,
  };

  state = {
    currentStepIndex: 0,
    initialStepPassed: false,
  };

  // Automatically redirect the user to initial step
  componentDidMount() {
    const {
      steps,
      initialStepIndex,
      rootUrl,
      history,
      location: { state: { initialStep } = {} } = {},
    } = this.props;

    if (initialStepIndex || initialStep >= 0) {
      const initialPath = steps[initialStepIndex || initialStep].path;
      return history.replace(rootUrl + initialPath, this.props.location.state);
    }

    if (!this.state.initialStepPassed) {
      const firstStep = steps?.[0];
      const firstStepPath = firstStep?.path;
      // if (firstStepPath.startsWith('/')) firstStepPath = firstStepPath.slice(1);
      if (!firstStep.needToSkip) {
        history.push(rootUrl + firstStepPath);
      } else {
        this.handleFormStep(1, 'forward');
      }
    }
  }

  // Update current step index after using browser's native back/forward buttons
  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = history.location.pathname.replace(this.props.rootUrl, '');

    if (history.action === 'POP' || history.action === 'REPLACE') {
      if (history.location.pathname === this.props.rootUrl) {
        return this.setState({ currentStepIndex: 0 });
      }

      this.props.steps.forEach((step, index) => {
        step.path === pathname && this.setState({ currentStepIndex: index });
      });
    }
  }

  /**
   * Pushes form state forward/backward to the specified number of steps
   * @param  {Number} stepsCount - number of steps
   * @param  {String} direction  - 'forward' | 'backward'
   */
  handleFormStep = (stepsCount = 1, direction = 'forward') => {
    const { currentStepIndex } = this.state;
    let nextStepIndex =
      direction === 'forward' ? currentStepIndex + stepsCount : currentStepIndex - stepsCount;
    let nextStep = this.props.steps[nextStepIndex];

    const isLastStep = currentStepIndex >= this.props.steps?.length - 1;
    if (isLastStep && this.props.onLastStep) return this.props.onLastStep();

    this.checkStepValidity(nextStep);

    const isNextStepOutOfTheSection =
      !!nextStep.partOf && !nextStep.partOf.includes(this.props.section);
    const shouldSkipNextStep = !!nextStep.needToSkip || isNextStepOutOfTheSection;

    if (shouldSkipNextStep) {
      const isReverse = direction === 'backward' ? true : false;

      for (
        var i = !isReverse ? 0 : this.props.steps.length - 1;
        !isReverse ? i < this.props.steps.length : i >= 0;
        !isReverse ? i++ : i--
      ) {
        const step = this.props.steps[i];

        const currentStepOutOfTheSection =
          !!step.partOf && !step.partOf.includes(this.props.section);
        const shouldSkipStep = !!step.needToSkip || currentStepOutOfTheSection;

        if (shouldSkipStep) continue;

        if (!isReverse && i <= currentStepIndex) continue;
        else if (isReverse && i >= currentStepIndex) continue;

        nextStepIndex = i;
        nextStep = step;
        if (!isReverse) this.checkStepValidity(nextStep);
        break;
      }
    }

    this.setState({ currentStepIndex: nextStepIndex, initialStepPassed: true });
    this.props.history.push(this.props.rootUrl + nextStep.path);
    this.props.onStepChange &&
      this.props.onStepChange({
        nextStepIndex,
        nextStep,
        stepForward: this.handleStepForward,
        stepBackward: this.handleStepBackward,
      });
  };

  handleStepForward = steps => this.handleFormStep(steps, 'forward');

  handleStepBackward = steps => this.handleFormStep(steps, 'backward');

  /**
   * Checks step validity
   * @param  {Object} step - step item from 'this.props.steps' array
   * @return {Boolean}
   */
  checkStepValidity = step => {
    if (!step)
      throw new Error(`
      <MultistepForm />: Next form step does not exist.
      Please check MultistepForm "steps" prop!
    `);
    if (!step.path || typeof step.path !== 'string')
      throw new Error(`
      <MultistepForm />: Form step should have a valid path prop.
      Please check MultistepForm "steps" prop!
    `);
    if (!step.render)
      throw new Error(`
      <MultistepForm />: Form step should have a render prop.
      Please check MultistepForm "steps" prop!
    `);
  };

  handleRestartPassport = () => {
    const { initialPassportIndex, steps, rootUrl, history } = this.props;

    this.setState({ currentStepIndex: initialPassportIndex });
    history.push(rootUrl + steps[initialPassportIndex].path);
  };

  /**
   * Render a set of routes for each form step
   */
  renderStepsRoutes = () => {
    const { steps, rootUrl } = this.props;
    if (!steps || steps.length === 0) return null;

    return steps.map(({ path, render, componentProps }) => {
      if (path.startsWith('/')) path = path.slice(1);

      const StepComponent = render || null;
      const StepComponentProps = {
        onStepForward: this.handleStepForward,
        onStepBackward: this.handleStepBackward,
        restartPassport: this.handleRestartPassport,
        ...this.props,
        ...componentProps,
      };

      return (
        <Route
          key={path}
          exact
          path={`${rootUrl}/${path}`}
          render={() => <StepComponent {...StepComponentProps} />}
        />
      );
    });
  };

  render() {
    return <Switch>{this.renderStepsRoutes()}</Switch>;
  }
}

export default withRouter(MultistepForm);
