import React, { Component } from 'react';

import Popup from 'components/shared/Popup';
import { CloseButton } from 'components/shared/Buttons/CloseButton';
import { Input } from 'components/shared/Inputs/Input';
import { TextButton } from 'components/shared/Buttons/TextButton';
import { SecondaryButton } from 'components/shared/Buttons/SecondaryButton';

import './MicroDepositVerification.css';

class MicroDepositVerification extends Component {
  state = {
    amount1: 0,
    amount2: 0,
  };

  handleFieldChange = (fieldName, fieldValue) => {
    const digitsValue = fieldValue.replace(/\D/g, '').slice(0, 1);
    this.setState({ [fieldName]: digitsValue });
  };

  sendMicroDeposits = () => {
    const payload = {
      value1: Number('0.0' + this.state.amount1),
      value2: Number('0.0' + this.state.amount2),
    };
    this.props.sendMicroDeposits(payload);
  };

  handleClose = () => {
    this.setState({ amount1: 0, amount2: 0 }, () => this.props.onRequestClose());
  };

  render() {
    const infoContent = (
      <div className="MicroDepositVerification-infoContent">
        <div className="MicroDepositVerification-infoContent-content">
          <div className="MicroDepositVerification-infoContent-title">
            Micro Deposit Checking {this.props.hasErrors ? 'Failure' : 'Success'}
          </div>
          <div className="MicroDepositVerification-infoContent-message">
            <span>
              {this.props.hasErrors ? this.props.errors : 'You bank account was verified.'}
            </span>
          </div>
        </div>
        <div className="MicroDepositVerification-infoContent-button">
          <SecondaryButton text="OKAY!" onClick={this.handleClose} />
        </div>
      </div>
    );

    const microDepositContent = (
      <span>
        <h2 className="MicroDepositVerification-title">Micro Deposit Verification</h2>
        <p className="MicroDepositVerification-message">
          Please enter the exact amount for each micro-deposit you received in order to verify your
          account and complete your account setup.
        </p>
        <div className="MicroDepositVerification-inputs">
          <div className="MicroDepositVerification-input">
            <span className="MicroDepositVerification-input-title">Deposit #1</span>
            <span className="MicroDepositVerification-input-zero">$0.0</span>
            <Input
              type="text"
              value={this.state.amount1}
              placeholder="0"
              onChange={value => this.handleFieldChange('amount1', value)}
            />
          </div>
          <div className="MicroDepositVerification-input">
            <span className="MicroDepositVerification-input-title">Deposit #2</span>
            <span className="MicroDepositVerification-input-zero">$0.0</span>
            <Input
              type="text"
              value={this.state.amount2}
              placeholder="0"
              onChange={value => this.handleFieldChange('amount2', value)}
            />
          </div>
        </div>
        <div className="MicroDepositVerification-button">
          <TextButton text="Submit" arrow="next" onClick={this.sendMicroDeposits} />
        </div>
        <p className="MicroDepositVerification-help">
          If it has been more than 72 hours since linking your account and you have not yet received
          these deposits, please contact{' '}
          <a href="mailto:support@rallyrd.com">support@rallyrd.com</a>
        </p>
      </span>
    );

    return (
      <Popup
        show={this.props.show}
        disableAutoClose
        onRequestClose={this.handleClose}
        className="MicroDepositVerification"
        contentClassName="MicroDepositVerification-content"
      >
        <CloseButton onClickHandler={this.handleClose} />
        {this.props.isMicroDepositsChecked ? infoContent : microDepositContent}
      </Popup>
    );
  }
}

export default MicroDepositVerification;
