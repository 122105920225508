import React from 'react';
import ReactDOM from 'react-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import App from 'components/App';
import * as serviceWorker from './serviceWorker';
import './index.css';

import packageJson from '../package.json';
import CONFIG from './config';

Bugsnag.start({
  apiKey: CONFIG.bugsnagKey,
  plugins: [new BugsnagPluginReact()],
  appVersion: packageJson.version,
  releaseStage: process.env.REACT_APP_ENV,
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
