import React, { useState, useEffect, useCallback } from 'react';

import { Fullpage } from 'components/shared/Fullpage';
import MultistepForm from 'components/shared/MultistepForm';
import { SimpleComponent } from 'types/components';
import { isEmpty } from 'utils';
import CONFIG from 'config';

import Card from './Card/Card';

import stepsProps from './stepsProps';
import { OnboardingATSCardsWrapper } from './styled';

type OnboardingATSCardsProps = {
  history: Record<string, any>;
};

const OnboardingATSCards: SimpleComponent<OnboardingATSCardsProps> = props => {
  const [steps, setSteps] = useState<Record<string, any>[]>([]);
  const [goToUrl, setGoToUrl] = useState<string | undefined>();

  const handleLastStep = useCallback((url: string) => {
    props.history.push(url || goToUrl);
  }, [goToUrl, props.history])

  const mountSteps = useCallback(() => {
    const { featureFlags: { atsCards }} = CONFIG as any;
    const { lastUrl = '/app', shouldShow } = props.history?.location?.state || {};

    const arr = [
      ...(atsCards === 1 ? [{
        path: '/live-trading',
        render: Card,
        componentProps: {
          ...stepsProps[0]
        }
      }] : []),
      ...(atsCards === 1 ? [{
        path: '/instant-buy',
        render: Card,
        componentProps: {
          ...stepsProps[1]
        }
      }] : []),
      ...((atsCards === 1 || atsCards === 2) ? [{
        path: '/post-only',
        render: Card,
        componentProps: {
          ...stepsProps[2]
        }
      }] : []),
    ];

    if (isEmpty(arr) || !shouldShow) handleLastStep(lastUrl);
    
    setSteps(arr);
    setGoToUrl(lastUrl);
  }, [props.history, handleLastStep]);

  useEffect(() => {
    mountSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OnboardingATSCardsWrapper>
      <Fullpage>
        {!isEmpty(steps) && (
          <MultistepForm
            rootUrl="/app/ats"
            steps={steps}
            onLastStep={handleLastStep}
          />
        )}
      </Fullpage>
    </OnboardingATSCardsWrapper>
  );
};

export default OnboardingATSCards;
