import { closeAssetSearchAndClearList, getAssetDetails, toggleAssetsDetails } from 'actions';
import { setActiveAssetById, setAssetSearchSelected } from 'actions/assets';
import { ASSET_CATEGORIES } from 'constants/main';
import React, { useEffect, RefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import history from 'services/history';
import { SimpleComponent } from 'types/components';
import AssetsMenuItem from './AssetsMenuItem';

type AssetsSearchResultListProps = SimpleComponent<
  RouteComponentProps & {
    isMobileSearch?: boolean;
    containerRef?: RefObject<HTMLDivElement>;
  }
>;

const AssetsSearchResultList: AssetsSearchResultListProps = ({ isMobileSearch, containerRef }) => {
  const { activeAsset, searchResultList, isSearchAssetSelected, assetsSearchValue } = useSelector(
    (store: any) => store.Assets,
  );
  const dispatch = useDispatch();

  const getResulTitle = () => {
    const itemCount = searchResultList.length;
    if (itemCount > 0) {
      return `${itemCount} result${itemCount !== 1 ? 's' : ''}`;
    }
    return 'no results';
  };

  const handleClick = async (index: number) => {
    const asset = searchResultList[index];
    if (asset.is_exited) {
      return;
    }
    if (isMobileSearch) {
      dispatch(closeAssetSearchAndClearList());
    }
    await dispatch(getAssetDetails(asset.id));
    dispatch(setAssetSearchSelected(true));
    const assetCategoryPath = Object.values(ASSET_CATEGORIES).find(
      ({ pathname, key }) => pathname === asset.category || key === asset.category,
    );
    history.push(`/app/assets/${assetCategoryPath?.pathname}/${asset.ticker.replace('#', '')}`);
    dispatch(setActiveAssetById(asset.id, index, assetsSearchValue));
    dispatch(toggleAssetsDetails(true));
  };

  useEffect(() => {
    if (containerRef && isSearchAssetSelected) {
      const selectedIndex = searchResultList.findIndex((asset: any) => asset === activeAsset);
      if (selectedIndex !== -1) {
        const { id } = searchResultList[selectedIndex];
        const selectedAsset = document.getElementById(id);
        containerRef.current?.scrollTo({
          top: selectedAsset?.offsetTop ?? 0,
          behavior: 'smooth',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="AssetList-search-result">{getResulTitle()}</div>
      <div className="AssetList-search-list">
        {searchResultList.map((asset: any, index: number) => (
          <div id={asset.id} key={asset.id}>
            <AssetsMenuItem
              isMobileSearch={isMobileSearch}
              index={index}
              asset={asset}
              isActive={activeAsset.id === asset.id}
              onClick={handleClick}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default withRouter(AssetsSearchResultList);
