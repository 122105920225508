import React from 'react';
import CONFIG from 'config';
import moment from 'moment';
import { SimpleComponent } from 'types/components';
import { getTimeInTimezone } from 'utils/main'

type TradesOnTextProps = {
  sessionsFollowMarketHours: boolean;
  lastDateClose: string;
  sessionsStart: string;
};

const TradesOnText: SimpleComponent<TradesOnTextProps> = ({ sessionsFollowMarketHours, lastDateClose, sessionsStart }) => {
  const config: any = CONFIG;

  // for when we'd like to make time dynamic in the future
  // const shouldUseDefault = sessionsFollowMarketHours || !sessionsStart;
  // const from = getTimeInTimezone(sessionsStart, true);
  // const to = getTimeInTimezone(lastDateClose, true);

  // for now we use default adjusted locally
  const today = moment().format('YYYY-MM-DD');
  const from = getTimeInTimezone(`${today}T10:30:00.000-0500`, true);
  const to = getTimeInTimezone(`${today}T16:30:00.000-0500`, true);

  const fromFormatted = from.time + from.ampm;
  const toFormatted = to.time + to.ampm;
  const timezoneFormatted = from.timezone;

  if (!config.featureFlags.postOnlyEducationalEnabled || (sessionsStart && moment(sessionsStart).isBefore(moment.now()))) {
    return (
      <>
        <b>TRADES ON:</b> WEEKDAYS,{` ${fromFormatted} - ${toFormatted} ${timezoneFormatted}`}
      </>
    );
  }

  return (
    <>
      <b>TRADES ON:</b> {sessionsStart ? moment(sessionsStart).format('MM/DD/YYYY') : 'NEXT WEEK'},
      {` ${fromFormatted} - ${toFormatted} ${timezoneFormatted}`}
    </>
  );
};

export default TradesOnText;
