/* ===================== User ===================== */
/* ================================================ */

export const ADD_ASSET_SUBSCRIPTION = 'ADD_ASSET_SUBSCRIPTION';
export const REMOVE_ASSETS_FROM_ACCESS_GROUPS = 'REMOVE_ASSETS_FROM_ACCESS_GROUPS';
export const ADD_STATE_SUBSCRIPTION = 'ADD_STATE_SUBSCRIPTION';
export const REMOVE_ASSET_SUBSCRIPTION = 'REMOVE_ASSET_SUBSCRIPTION';
export const SET_DWOLLA_CUSTOMER_ERROR = 'SET_DWOLLA_CUSTOMER_ERROR';
export const SET_DWOLLA_CUSTOMER_STATUS = 'SET_DWOLLA_CUSTOMER_STATUS';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_STATE_STATUS = 'SET_USER_STATE_STATUS';
export const USER_INFO_UPDATE_INITIATED = 'USER_INFO_UPDATE_INITIATED';
export const USER_INFO_UPDATE_COMPLETED = 'USER_INFO_UPDATE_COMPLETED';
export const SET_INVESTOR_DETAILS = 'SET_INVESTOR_DETAILS';
export const SET_PERSONAL_INFORMATION = 'SET_PERSONAL_INFORMATION';
export const SET_TOS_AGREEMENT = 'SET_TOS_AGREEMENT';
export const SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA';
export const SET_EMPLOYMENT_DETAILS = 'SET_EMPLOYMENT_DETAILS';

/* ===================== Common ===================== */
/* ================================================== */

export const WIPE_REDUX_STATE = 'WIPE_REDUX_STATE';

/* ======================= UI ======================= */
/* ================================================== */

export const HIDE_ASSETS_LOADING_OVERLAY = 'HIDE_ASSETS_LOADING_OVERLAY';
export const HIDE_GLOBAL_LOADER = 'HIDE_GLOBAL_LOADER';
export const HIDE_NAV_MENU_NOTIFICATION = 'HIDE_NAV_MENU_NOTIFICATION';
export const SET_APPLICATION_MODE = 'SET_APPLICATION_MODE';
export const SHOW_ASSETS_LOADING_OVERLAY = 'SHOW_ASSETS_LOADING_OVERLAY';
export const SHOW_GLOBAL_LOADER = 'SHOW_GLOBAL_LOADER';
export const SHOW_NAV_MENU_NOTIFICATION = 'SHOW_NAV_MENU_NOTIFICATION';
export const TOGGLE_ASSETS_MENU = 'TOGGLE_ASSETS_MENU';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const SET_FULLSCREEN_LOADER = 'SET_FULLSCREEN_LOADER';
export const TOGGLE_SAPOPUP = 'TOGGLE_SAPOPUP';
export const SET_SAPOPUP = 'SET_SAPOPUP';
export const SET_AUTO_GIFTCODE = 'SET_AUTO_GIFTCODE';
export const CLEAR_SAPOPUP = 'CLEAR_SAPOPUP';
export const SET_ACTIVE_CALENDAR_TAB = 'SET_ACTIVE_CALENDAR_TAB';
export const SET_TRADING_CALENDAR_ENABLED = 'SET_TRADING_CALENDAR_ENABLED';

/* ===================== Trading ===================== */
/* =================================================== */

export const SET_ACTIVE_TRADING_WINDOW = 'SET_ACTIVE_TRADING_WINDOW';
export const SET_ORDERS = 'SET_ORDERS';
export const SET_ORDER_AS_PENDING_CANCEL = 'SET_ORDER_AS_PENDING_CANCEL';
export const SET_NONOPEN_TRADING_WINDOW_ORDERS = 'SET_NONOPEN_TRADING_WINDOW_ORDERS';
export const SET_TRADING_WINDOW_LOADED = 'SET_TRADING_WINDOW_LOADED';
export const SET_TRADING_WINDOW_LOADING = 'LOADING_TRADING_WINDOW';
export const UNSET_ACTIVE_TRADING_WINDOW = 'UNSET_ACTIVE_TRADING_WINDOW';
export const UPDATE_ACTIVE_TRADING_WINDOW = 'UPDATE_ACTIVE_TRADING_WINDOW';
export const SET_ORDER_BOOK = 'SET_ORDER_BOOK';

/* ===================== Assets ===================== */
/* ================================================== */

export const LOCK_ACTIVE_ASSET = 'LOCK_ACTIVE_ASSET';
export const SET_ACTIVE_ASSET = 'SET_ACTIVE_ASSET';
export const SET_ACTIVE_ASSET_CATEGORY = 'SET_ACTIVE_ASSET_CATEGORY';
export const SET_ACTIVE_ASSET_PRICING = 'SET_ACTIVE_ASSET_PRICING';
export const SET_ACTIVE_ASSET_HISTORY_PRICING = 'SET_ACTIVE_ASSET_HISTORY_PRICING';
export const TOGGLE_ASSETS_DETAILS = 'TOGGLE_ASSETS_DETAILS';
export const SET_ASSET_DETAILS = 'SET_ASSET_DETAILS';
export const SET_ASSETS = 'SET_ASSETS';
export const SET_TRADING_DATES = 'SET_TRADING_DATES';
export const UNLOCK_ACTIVE_ASSET = 'UNLOCK_ACTIVE_ASSET';
export const UPDATE_ACTIVEASSET_MERCHANDISING_OPTIONS = 'UPDATE_ACTIVEASSET_MERCHANDISING_OPTIONS';
export const UPDATE_ASSETS_UPDATE_SCHEDULE = 'UPDATE_ASSETS_UPDATE_SCHEDULE';
export const SET_ASSETS_SEARCH_VALUE = 'SET_ASSETS_SEARCH_VALUE';
export const OPEN_ASSETS_SEARCH = 'OPEN_ASSETS_SEARCH';
export const CLOSE_ASSETS_SEARCH = 'CLOSE_ASSETS_SEARCH';
export const SET_SEARCH_RESULT_LIST = 'SET_SEARCH_RESULT_LIST';
export const SET_ASSET_SEARCH_SELECTED = 'SET_ASSET_SEARCH_SELECTED';
export const SET_HOME_ASSETS = 'SET_HOME_ASSETS';
export const GET_ASSET_PRICING = 'GET_ASSET_PRICING';
export const SET_CATEGORY_ASSETS = 'SET_CATEGORY_ASSETS';

/* ===================== Investments ===================== */
/* ======================================================= */

export const REMOVE_GIFT_CODE_APPLIED = 'REMOVE_GIFT_CODE_APPLIED';
export const SET_BALANCE = 'SET_BALANCE';
export const SET_BANK_FUNDING_SOURCE = 'SET_BANK_FUNDING_SOURCE';
export const DELETE_BANK_FUNDING_SOURCE = 'DELETE_BANK_FUNDING_SOURCE';
export const SET_GIFT_CODE_APPLIED = 'SET_GIFT_CODE_APPLIED';
export const SET_INVESTMENT_ERROR = 'SET_INVESTMENT_ERROR';
export const SET_INVESTMENTS = 'SET_INVESTMENTS';
export const SET_GAIN_LOSS = 'SET_GAIN_LOSS';
export const SET_RECEIPTS = 'SET_RECEIPTS';
export const SET_TRADING_WINDOW = 'SET_TRADING_WINDOW';
export const SET_LOADING_TRADING_WINDOW = 'SET_LOADING_TRADING_WINDOW';
export const SET_BANK_FUNDING_SOURCE_ERROR = 'SET_BANK_FUNDING_SOURCE_ERROR';
export const SET_BANK_FUNDING_SOURCE_LOADING = 'SET_BANK_FUNDING_SOURCE_LOADING';
export const SET_AGREEMENT_TIMESTAMP = 'SET_AGREEMENT_TIMESTAMP';
export const SET_INVESTMENT_FLOW = 'SET_INVESTMENT_FLOW';
export const SET_POSITIONS = 'SET_POSITIONS';
