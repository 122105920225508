import React, { Component } from 'react';
import './ChartPoint.css';

export class ChartPoint extends Component {
  constructor(props) {
    super(props);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }

  handleMouseOver(e) {
    this.props.chartPointChange(this.props.pointData);
  }

  handleMouseOut(e) {
    this.props.chartMouseOut(this.props.pointData);
  }

  render() {
    const backgroundColor = this.props.color || 'white';
    const yPos = this.props.yPos || 0;
    const width = Math.max(this.props.leftPadding + this.props.rightPadding, 0.01);
    const left = (this.props.leftPadding / width) * 100;
    return (
      <div
        className="ChartPoint"
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        onTouchStart={this.handleMouseOver}
        onTouchMove={this.handleMouseOver}
        // onTouchEnd={this.handleMouseOut}
      >
        <div
          className="ChartPoint-point"
          style={{
            bottom: yPos + '%',
            left: left + '%',
            backgroundColor: backgroundColor,
          }}
        />
      </div>
    );
  }
}
