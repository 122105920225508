import React, { useMemo, useState } from 'react';
import { SimpleComponent } from 'types/components';
import { TradingCategories, GroupedAssetList } from '../types';
import { CalendarListContainer, CalendarListTitle } from './styled';
import { PostOnlyCalendarItem, LiveCalendarItem } from '../CalendarItem';
import moment from 'moment';
import { sortAssetsByGroup } from 'utils/calendar';
import { PickRename } from 'types/utils';
import { isEmpty } from 'utils';

type LiveListProps = PickRename<GroupedAssetList, 'trading', 'list'>;

const LiveList: SimpleComponent<LiveListProps> = ({ list }) => {
  const [scrollDisabled, disableScroll] = useState(false);
  const { live } = list;
  const orderedKeys = useMemo(() => {
    const { live, ...rest } = list;
    return Object.keys(rest).sort((a, b) => (moment(a).isAfter(moment(b)) ? 1 : -1));
  }, [list]);

  return (
    <CalendarListContainer scrollDisabled={scrollDisabled}>
      {!isEmpty(live) && (
        <div>
          <CalendarListTitle>{TradingCategories.Live}</CalendarListTitle>
          {sortAssetsByGroup(live, 'live').map(asset => (
            <LiveCalendarItem key={asset.id} asset={asset} />
          ))}
        </div>
      )}
      {orderedKeys.map(category => {
        return (
          <div key={category}>
            <CalendarListTitle>{moment(category).format('ddd, MMMM Do')}</CalendarListTitle>
            {sortAssetsByGroup(list[category], 'postOnly').map(asset => (
              <PostOnlyCalendarItem
                key={asset.id}
                disableScroll={disableScroll}
                tradingDate={moment(category).format('ddd, MMMM Do')}
                asset={asset}
              />
            ))}
          </div>
        );
      })}
    </CalendarListContainer>
  );
};

export default LiveList;
