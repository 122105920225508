import { isEmpty } from 'utils';
import { IActiveAsset } from 'types/assets';
import { getAssetSegmentStatus } from 'actions/assets';

export interface IAssetLayer {
  assetCategory: string;
  assetName: string;
  assetStatus: string;
  assetTicker: string;
  currentSharePrice: number;
  currentTotalValue: number;
  gainLoss: {
    allTime: number;
    today: number;
  };
  ioPrice: number;
  lastCloseSharePrice: number;
  askLow: number;
  bidHigh: number;
  comparableAssets: {
    compAssetValue: number | string | undefined;
    compTransactions: number | undefined;
    compAssetGain: number | string | undefined;
  };
}

const getActiveAssetDetails =
  (assetId?: number | string) =>
  async (dispatch: Function, getState: Function): Promise<IAssetLayer> => {
    const getTotalAssetValueIncrease = (transactions: Record<string, any>[]) => {
      const firstTransaction = transactions[0];
      const lastTransaction = transactions[transactions.length - 1];
      const firstValue = Math.max(firstTransaction.value, 1);
      const lastValue = Math.max(lastTransaction.value, 1);
      return getAssetValueIncreaseFixed(lastValue, firstValue);
    };

    const getAssetValueIncreaseFixed = (lastValue: number, pastValue: number) => {
      return Number((lastValue / pastValue - 1).toFixed(3));
    };

    return new Promise<IAssetLayer>(async (resolve) => {
      const activeAsset = assetId
        ? getState().Assets.assetList.find((asset: IActiveAsset) => asset.id === assetId)
        : getState().Assets.activeAsset;

      const { user } = getState().Auth;
      const { pricePerUnit } = activeAsset?.trading?.pricing || {};

      const ioPrice = activeAsset?.asset_value / activeAsset?.number_of_shares;
      const lastTradeClosePrice = pricePerUnit?.session?.lastClose || ioPrice;

      const currentAssetValue =
        pricePerUnit?.current * activeAsset.number_of_shares ||
        pricePerUnit?.session?.lastClose * activeAsset.number_of_shares ||
        activeAsset.asset_value;

      // comp assets

      const chartPoints = activeAsset.history_value_points;
      let compAssetValue;
      let compTransactions;
      let compAssetGain: string | number = 0;
      if (chartPoints) {
        compAssetValue = Number(activeAsset.current_value || activeAsset.asset_value);
        compTransactions = chartPoints?.length || 0;
        compAssetGain = !isEmpty(chartPoints) ? getTotalAssetValueIncrease(chartPoints) : 0;
      }

      return resolve({
        assetCategory: activeAsset.category,
        assetName: activeAsset.display_name,
        assetStatus: getAssetSegmentStatus(activeAsset, user),
        assetTicker: activeAsset.ticker,
        currentSharePrice: pricePerUnit?.current,
        currentTotalValue: currentAssetValue,
        gainLoss: {
          allTime:
            ((pricePerUnit?.current || pricePerUnit?.session?.lastClose) - pricePerUnit?.issue) /
            pricePerUnit?.issue,
          today:
            ((pricePerUnit?.current || pricePerUnit?.session?.lastClose) -
              pricePerUnit?.session?.lastClose) /
            pricePerUnit?.session?.lastClose,
        },
        ioPrice,
        lastCloseSharePrice: lastTradeClosePrice,
        askLow: pricePerUnit?.orderBook?.askLow,
        bidHigh: pricePerUnit?.orderBook?.bidHigh,
        comparableAssets: {
          compAssetValue,
          compTransactions,
          compAssetGain,
        },
      });
    });
  };

export { getActiveAssetDetails };
