import React from 'react';

import Popup from '../Popup';
import { SecondaryButton } from 'components/shared/Buttons';
import './InvestmentRestrictedPopup.scss';

const InvestmentRestrictedPopup = ({ show, onRequestClose, ...props }) => {
  return (
    <Popup className="InvestmentRestrictedPopup" show={show} disableAutoClose {...props}>
      <div className="InvestmentRestrictedPopup-content">
        <h1>Initial Offering Investments are Not Yet Available in your State</h1>
        <p>
          This vehicle is in the Initial Offering phase. Your state is currently only approved for
          Buying & Selling during Trading Windows. Initial Offerings will be available in your state
          soon, and the first Trading Window for this vehicle will be scheduled shortly.
        </p>
        <SecondaryButton text="Okay" onClick={onRequestClose} />
        <footer>
          For any additional information or details, email{' '}
          <a href="mailto:hello@rallyrd.com">hello@rallyrd.com</a>, or call/text:{' '}
          <a href="tel:347-952-8058">347-952-8058</a>
        </footer>
      </div>
    </Popup>
  );
};

export default InvestmentRestrictedPopup;
