import React, { Component } from 'react';
import { Switch, Route } from 'react-router';
import { connect } from 'react-redux';
import * as uuid from 'uuid';

import AssetsMenuList from './AssetsMenuList/AssetsMenuList';
import AssetCarousel from './AssetCarousel/AssetCarousel';
import MakeInvestment from './MakeInvestment/MakeInvestment';
import MerchandiseCheckout from './Merchandise/MerchandiseCheckout';
import PlaceBid from './Trading/PlaceBid';
import OrderBook from './Trading/OrderBook/OrderBook';
import Chat from './AssetDetails/Chat/Chat';
import Details from './AssetDetails/Details/Details';
import Gallery from './AssetDetails/Gallery/Gallery';
import Legal from './AssetDetails/Legal/Legal';
import AssetsHeader from './AssetsHeader';
import { ModalViewContext } from 'components/shared/Modals/Modal';

import { getActiveAssetDetails } from 'utils/trading';
import { isEmpty, setPageTitle } from 'utils';
import analytics from 'services/analytics';
import withDeviceDetection from 'hoc/withDeviceDetection';

import {
  ASSET_CATEGORIES,
  ASSET_STATUS,
  ERROR_CODES,
  SPECIAL_ACCESS_TYPE_BLACKLISTED_ASSET_STATUS_MAP,
  SPECIAL_ACCESS_TYPE_PRECEDENCE,
  SUPPORTED_APPLICATION_MODES,
} from 'constants/main';
import { SEGMENT_ACTIONS, SEGMENT_CATEGORIES } from 'constants/analytics';
import { OrderContext } from 'types/orders';

import {
  getAssetDetails,
  logoutUser,
  setActiveAsset,
  setActiveTradingWindow,
  unsetActiveTradingWindow,
  toggleAssetsMenu,
  toggleAssetsDetails,
  getSecurityPricing,
  getSecurityHistoryPricing,
  setActiveAssetCategory,
  closeAssetSearchAndClearList,
} from 'actions';

import './Assets.css';
import Home from 'components/views/app/Home';
import InvestmentCalendar from './InvestmentCalendar';
import moment from 'moment';
import ExternalLink from 'components/views/app/ExternalLink';

class Assets extends Component {
  state = {
    noAccessToAssets: false,
    hasTracked: false,
  };

  static contextType = ModalViewContext;

  componentDidMount() {
    this.processAssetsLoad();
    /*
      @TODO Need some work here around properly naming each section. The asset's ticker and title should be displayed as part of the page title.
      For example: Rally | Invest in Cars #88LL1 '88 LAMBORGHINI LM002
    */
    setPageTitle('Home');

    if (this.props.applicationMode === SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS) {
      this.props.toggleAssetsDetails(true);
    }
  }

  componentDidUpdate(prevProps) {
    if (isEmpty(this.props.assetList) && this.state.noAccessToAssets) {
      const errorCode =
        this.props.applicationMode === SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS
          ? ERROR_CODES.NOT_AUTHORIZED_FOR_EARLY_ACCESS
          : ERROR_CODES.NO_ACCESSIBLE_ASSETS;

      this.props.logoutUser(errorCode);
    }
    this.processAssetsLoad();

    if (
      this.props.activeAsset.trading?.pricing?.financialInstrumentId === undefined ||
      this.props.activeAsset.tabChange
    )
      return;
    // --- Track the switch event between "1.0" and "2.0" screens:
    this.tracking(prevProps);
    // ---
    if (
      !this.state.hasTracked ||
      this.props.activeAsset.trading?.pricing?.financialInstrumentId !==
        prevProps.activeAsset.trading?.pricing?.financialInstrumentId
    ) {
      this.tracking(prevProps, true);
    }
  }

  async tracking(prevProps, forceTracking = false) {
    const { activeAsset } = this.props;

    if (
      (!isEmpty(activeAsset) &&
        (activeAsset.id !== prevProps.activeAsset.id ||
          this.props.detailsOpened !== prevProps.detailsOpened)) ||
      forceTracking
    ) {
      const screenName = this.props.detailsOpened ? '2.0' : '1.0';
      const eventCategory = this.props.detailsOpened
        ? SEGMENT_CATEGORIES.ASSET2_0_PORTAL
        : SEGMENT_CATEGORIES.ASSET1_0_SCREEN;
      const eventName = `Asset ${screenName} View`;
      const assetTicker = activeAsset.ticker;
      const assetCategory = this.props.activeAssetCategory;
      const pageTitle = `Investments in ${activeAsset.display_name} (${assetTicker})`;

      setPageTitle(pageTitle);

      // track page
      analytics.page(`${screenName} View - ${pageTitle}`);

      let obj = {};

      if (!activeAsset.trading?.pricing) return;
      // don't track 2.0 until we have the pricing info
      else if (screenName === '2.0') {
        const assetDetails = await this.props.getActiveAssetDetails();
        const lastTw =
          activeAsset.financialInstrument?.markets?.secondaryMarket?.sessionHours?.lastDateClose ||
          activeAsset.offering_ends;

        obj = {
          assetTicker: assetDetails.assetTicker,
          assetState: assetDetails.assetStatus,
          assetCategory: assetDetails.assetCategory,
          currentValue: assetDetails.currentTotalValue,
          currentSharePrice: assetDetails.currentSharePrice,
          lastTradedPrice: assetDetails.lastCloseSharePrice,
          ioValue: Number(assetDetails.ioPrice * activeAsset.number_of_shares),
          ioSharePrice: Number(assetDetails.ioPrice),
          ioInvested: Number(activeAsset.confirmed_investment + activeAsset.pending_investment),
          sinceIO: Number(assetDetails.gainLoss?.allTime),
          lastTradingWindow: moment(lastTw).format('MM/DD/YYYY'),
          investorCount: activeAsset.investor_count,
          compAssetValue: assetDetails.comparableAssets?.compAssetValue,
          compAssetGain: assetDetails.comparableAssets?.compAssetGain,
          compTransactions: assetDetails.comparableAssets?.compTransactions,
        };

        this.setState({ hasTracked: true });
      }

      // track event
      analytics.track(eventName, {
        category: eventCategory,
        action: SEGMENT_ACTIONS.OPEN,
        asset: assetTicker,
        label: assetTicker,
        assetCategory: assetCategory.key,
        ...obj,
      });
    }
  }

  componentWillUnmount() {
    this.props.setActiveAsset({});
    this.props.setActiveAssetCategory({});
  }

  getAssetList = () => {
    const assetList = this.props.assetList;

    // currently, when the ui is in special access mode, only the first asset in the list that a user has special access
    //  to will be displayed.
    if (this.props.applicationMode === SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS) {
      const specialAccessAssetIdAccessTypeLimitationsMap =
        this.props.user.getSpeciallyAccessibleAssetsIdAccessTypeAndLimitationsMap();
      // users shouldn't even be able to reach this point unless they have active assets groups, but just in case...
      if (!specialAccessAssetIdAccessTypeLimitationsMap) return [assetList[0]];

      const now = Date.now();

      const specialAccessTypeAccessibleAssetIdsMap = {};
      for (const [
        assetId,
        { accessType, accessEnds },
      ] of specialAccessAssetIdAccessTypeLimitationsMap) {
        // first ensure that the asset is still accessible for special access before adding
        if (accessEnds < now) continue;

        if (!specialAccessTypeAccessibleAssetIdsMap.hasOwnProperty(accessType)) {
          specialAccessTypeAccessibleAssetIdsMap[accessType] = [];
        }
        specialAccessTypeAccessibleAssetIdsMap[accessType].push(assetId);
      }

      // retrieve the first asset to be show in early access according to access type precedence.
      for (const accessType of SPECIAL_ACCESS_TYPE_PRECEDENCE) {
        if (!specialAccessTypeAccessibleAssetIdsMap.hasOwnProperty(accessType)) continue;

        const specialAccessTypeAssetIds = specialAccessTypeAccessibleAssetIdsMap[accessType];
        const blacklistedAssetStatuses =
          SPECIAL_ACCESS_TYPE_BLACKLISTED_ASSET_STATUS_MAP[accessType] || [];

        for (const asset of assetList) {
          if (
            !specialAccessTypeAssetIds.includes(asset.id) ||
            blacklistedAssetStatuses.includes(asset.status)
          )
            continue;

          return [asset];
        }
      }

      // this should really be unreachable, but for good measure...
      if (assetList && assetList.length) return [assetList[0]];
      // if reaching this point then it means that the user does not have access to any assets, so we redirect them to
      //  the login screen to inform them of it.
      this.setState({ noAccessToAssets: true });
    }

    return assetList;
  };

  getRoutes = () => {
    const { activeAsset, history, detailsOpened } = this.props;

    return (
      <Switch>
        <Route
          path="/app/make-investment"
          render={props => (
            <MakeInvestment {...props} onCheckoutAvailable={this.displayCheckoutView} />
          )}
        />
        <Route path="/app/place-bid" render={props => <PlaceBid {...props} />} />
        <Route
          path="/app/place-ask"
          render={props => <PlaceBid {...props} side={OrderContext.ASK} />}
        />
        <Route path="/app/orders" render={props => <OrderBook {...props} />} />
        <Route path="/app/chat" render={props => <Chat {...props} />} />
        <Route path="/app/details" render={props => <Details {...props} />} />
        <Route path="/app/gallery" render={props => <Gallery {...props} />} />
        <Route path="/app/legal" render={props => <Legal {...props} />} />
        <Route path="/app/home" render={props => <Home {...props} />} />
        <Route path="/app/external-link" render={props => <ExternalLink {...props} />} />
        <Route
          path="/app/investments-calendar"
          render={props => <InvestmentCalendar {...props} />}
        />
        <Route
          render={props => {
            const assetList = this.getAssetList();
            const selectedAssetIndex = assetList.findIndex(
              ({ ticker }) => ticker === activeAsset.ticker,
            );

            return (
              <AssetCarousel
                assetList={assetList}
                activeIndex={selectedAssetIndex}
                handleSlideSwitch={this.handleAssetSlideChange}
                detailsOpened={detailsOpened}
                openDetailsCallback={this.onAssetDetailsOpen}
                closeDetalsCallback={this.onAssetDetailsClose}
                history={history}
              />
            );
          }}
        />
      </Switch>
    );
  };

  displayCheckoutView = ({ asset, upsellProductInfo }) => {
    const { setModalViewRenderSequence } = this.context;
    setModalViewRenderSequence([
      {
        state: true,
        id: uuid.v4(),
        children: <MerchandiseCheckout />,
        childrenProps: {
          asset: asset,
          upsellProductInfo: upsellProductInfo,
        },
        modalStyle: {
          fillEntireScreen: true,
          mobileFillEntireScreen: true,
          bgColor: 'rgba(0,0,0,0.9)',
        },
        transition: 'modalViewFade',
      },
    ]);
  };

  /**
   * Switch active asset after clicking on assets menu list or after using assets carousel
   */
  handleAssetSlideChange = async index => {
    const selectedAsset = this.props.assetList[index];
    const updatedAsset = this.props.assetList.find(asset => asset.id === selectedAsset.id);

    if (this.props.activeAsset.id === selectedAsset.id || this.props.isAssetLocked) {
      return;
    }

    this.setActiveAsset(updatedAsset);
  };

  handleAssetListItemClick = async index => {
    const {
      activeAsset,
      getAssetDetails,
      isAssetLocked,
      toggleAssetsDetails,
      toggleAssetsMenu,
      displayAssetsMenu,
      detailsOpened,
      userDevice,
    } = this.props;

    const selectedAsset = this.props.assetList[index];

    // Stop proceeding any further if the clicked asset and current active asset is same OR asset is locked. Only on desktop mode except if coming from home
    if (
      (activeAsset.id === selectedAsset.id || isAssetLocked) &&
      !userDevice.isMobile &&
      this.props.history.location.pathname !== '/app/home'
    )
      return;

    // open 2.0 screen if the clicked asset isn't exited and (either 1.0 list view is visible or 2.0 detailsOpened is already set to true via toggleAssetsDetails).
    const shouldOpenDetails =
      (!selectedAsset.is_exited && (displayAssetsMenu || detailsOpened)) ||
      this.props.history.location.pathname === '/app/home';
    if (shouldOpenDetails) {
      await getAssetDetails(selectedAsset.id);
    }

    const updatedAsset = this.props.assetList.find(asset => asset.id === selectedAsset.id);
    this.setActiveAsset(updatedAsset);
    if (displayAssetsMenu && selectedAsset.is_exited) toggleAssetsMenu();
    toggleAssetsDetails(shouldOpenDetails);
  };

  resetAssetsMenuScrollPosition = () => null;

  onAssetDetailsOpen = async () => {
    await this.props.getAssetDetails(this.props.activeAsset.id);

    if (this.props.activeAsset.financialInstrument?.markets?.secondaryMarket) {
      await this.props.setActiveTradingWindow(this.props.activeAsset.financialInstrument.id);
    }

    const updatedAsset = this.props.assetList.find(asset => asset.id === this.props.activeAsset.id);
    this.setActiveAsset(updatedAsset);
    this.props.toggleAssetsDetails(true);
  };

  onAssetDetailsClose = () => {
    const { toggleAssetsDetails } = this.props;
    toggleAssetsDetails(false);
    this.props.closeAssetSearchAndClearList();
    this.setState({ hasTracked: false });
  };

  processAssetsLoad = async () => {
    const { location, isAssetsSearchActive } = this.props;
    if (isEmpty(this.props.assets)) return;

    let nextAssetCategoryPathname = this.props.match.params.category;

    // ignore lack of category in the URL if there is an active asset (investment & trading flows):
    if (!isEmpty(this.props.activeAsset) && !nextAssetCategoryPathname) return;

    let nextAssetCategory = Object.values(ASSET_CATEGORIES).find(
      ({ pathname }) => pathname === nextAssetCategoryPathname,
    );

    // set default 'cars' category, if none was specified in the URL:
    if (!nextAssetCategory) nextAssetCategory = ASSET_CATEGORIES.HISTORY;

    // if application mode is special access then force the category to what was set during loading of assets.
    if (this.props.applicationMode === SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS) {
      nextAssetCategory = this.props.activeAssetCategory;
    }

    if (this.props.activeAssetCategory.key !== nextAssetCategory.key) {
      const hasQueryParam =
        location.search && location.search.includes('sapopup=true') ? true : false;
      if (!hasQueryParam && !isAssetsSearchActive) this.props.toggleAssetsDetails(false);
      await this.props.setActiveAssetCategory(nextAssetCategory);
    }

    this.setInitialActiveAsset();
  };

  renderByApplicationMode = () => {
    switch (this.props.applicationMode) {
      case SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS:
        return this.renderSpecialAccess();

      default:
        return this.renderDefault();
    }
  };

  renderDefault = () => {
    return (
      <div className="Assets">
        <AssetsHeader />
        <div className="clearfix" style={{ height: '100%' }}>
          <AssetsMenuList
            registerResetScrollCallback={cb => (this.resetAssetsMenuScrollPosition = cb)}
            handleSlideSwitch={this.handleAssetListItemClick}
          />
          {this.getRoutes()}
        </div>
      </div>
    );
  };

  renderSpecialAccess = () => {
    return (
      <div className="Assets special-access">
        <div className="clearfix" style={{ height: '100%' }}>
          {this.getRoutes()}
        </div>
      </div>
    );
  };

  /**
   * If no active asset specified, find active asset based on URL parameter or set defualt one:
   */
  setInitialActiveAsset = () => {
    const { assetList, activeAsset, match, location } = this.props;

    if (location.from === '/app/home') {
      const asset = assetList.find(asset => asset.ticker === location.assetTicker) || assetList[0];
      this.setActiveAsset(asset);

      return;
    }
    if (!match.params.asset) this.resetAssetsMenuScrollPosition();
    if ((match.params.asset && !isEmpty(activeAsset)) || isEmpty(assetList)) return;

    const ticker = match.params.asset ? `#${match.params.asset}` : null;
    const asset = assetList.find(asset => asset.ticker === ticker) || assetList[0];
    this.setActiveAsset(asset, { onLoad: true });
  };

  /**
   * Actually sets new active asset globally, updates URL to reflect asset ticker.
   * If active asset is in trading status, updates active trading window globally,
   * otherwise removes active trading window info from the redux store
   */
  setActiveAsset = (asset, { onLoad = false } = {}) => {
    const homePath = `/app/home`;
    const { activeAssetCategory, history, location } = this.props;
    const defaultCategoryPath = ASSET_CATEGORIES.HISTORY.pathname;

    // if asset is undefined we redirect the user to the current category page
    if (isEmpty(asset) && history.location.pathname !== homePath) {
      history.push(`/app/assets/${activeAssetCategory.pathname || defaultCategoryPath}`);
      return;
    }
    const path = `/app/assets/${activeAssetCategory.pathname}/${asset.ticker.slice(1)}`;
    let hasQueryParam = false;
    if (location.search && location.search.includes('sapopup=true')) hasQueryParam = true;
    /**
     * if location is same as current path don't push into history again to avoid duplicated path on history stack
     */
    if (history.location.pathname !== path && history.location.pathname !== homePath) {
      history.push(`${path}${hasQueryParam ? location.search : ''}`);
    }

    if ((history.location.pathname === homePath || history.location.from === homePath) && !onLoad) {
      history.push(`${path}`);
      this.props.setActiveAsset(asset);
    } else {
      this.props.setActiveAsset(asset);
    }

    /**
     * When coming from the investments or home route show asset 2.0 screen
     */
    if (
      this.props.location.from === '/app/investments' ||
      this.props.location.from === '/app/home'
    ) {
      this.props.toggleAssetsDetails(true);
      this.props.getAssetDetails(asset.id);
    }

    if (
      asset.asset_status === ASSET_STATUS.TRADING_OPENED ||
      asset.asset_status === ASSET_STATUS.POST_ONLY ||
      asset.asset_status === ASSET_STATUS.TRADING_CLOSED
    ) {
      this.props.setActiveTradingWindow(asset.financialInstrument?.id);
    } else {
      this.props.unsetActiveTradingWindow();
    }
  };

  render() {
    const { activeAsset, assetList } = this.props;

    if (isEmpty(activeAsset) || isEmpty(assetList)) return null;

    return this.renderByApplicationMode();
  }
}

const mapStateToProps = state => ({
  activeAsset: state.Assets.activeAsset,
  assets: state.Assets.assetList,
  detailsOpened: state.Assets.detailsOpened,
  activeAssetCategory: state.Assets.activeAssetCategory,
  activeTradingWindow: state.Trading.activeTradingWindow,
  applicationMode: state.UI.applicationMode,
  assetCategoryLists: state.Assets.assetCategoryLists,
  assetList: state.Assets.assetCategoryLists[state.Assets.activeAssetCategory.key],
  displayAssetsMenu: state.UI.displayAssetsMenu,
  globalLoading: state.UI.globalLoading,
  isAssetLocked: state.Assets.isLocked,
  isAssetsSearchActive: state.Assets.isAssetsSearchActive,
  user: state.Auth.user,
});

const mapDispatchToProps = {
  getAssetDetails,
  logoutUser,
  setActiveAsset,
  unsetActiveTradingWindow,
  toggleAssetsMenu,
  setActiveTradingWindow,
  setActiveAssetCategory,
  toggleAssetsDetails,
  getSecurityPricing,
  getSecurityHistoryPricing,
  closeAssetSearchAndClearList,
  getActiveAssetDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(withDeviceDetection(Assets));
