import styled from '@emotion/styled';

export const BaseAlertModalView = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding: 3vh 0 0;
  overflow: auto;
  background: #fff;
  border-radius: 0;

  @media only screen and (max-width: 600px) {
    border-radius: 8px;
    padding: 3vh 0 0;
  }
`;

export const BaseModalTitle = styled.span`
  width: auto;
  font-weight: 700;
  color: #000;
  font-size: 2.5vmin;
  margin-bottom: 10px;
  padding: 0 3vw;

  @media only screen and (max-width: 600px) {
    font-size: 5vmin;
    padding: 0 6vw;
  }
`;

export const BaseModalMessage = styled.span`
  width: auto;
  font-weight: 400;
  color: #000;
  font-size: 2vmin;
  padding: 0 3vw;

  @media only screen and (max-width: 600px) {
    font-size: 4.5vmin;
    padding: 0 6vw;
  }
`;

export const BaseModalUserActionButtonContainer = styled.div`
  width: 100%;
  margin-top: 2vh;
`;

export const BaseModalUserActionButton = styled.button`
  border-radius: none;
  border: none;
  font-size: 2vmin;
  font-weight: 800;
  background: ${({ primaryButton, theme }) =>
    primaryButton ? theme.colors.newGreen : 'rgba(244, 244, 244, 1)'};
  color: ${({ primaryButton }) => (primaryButton ? '#fff' : '#202020')};
  letter-spacing: normal;
  padding: 14px 21px;
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  outline: none;
  width: 100%;

  @media only screen and (max-width: 600px) {
    font-size: 4vmin;
    padding: 3.6min 5.4vmin;
  }
`;
