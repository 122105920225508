import React from 'react';

import './CarouselNavButton.css';

const CarouselNavButton = ({ children, className, onClick }) => (
  <span className={`CarouselNavButton ${className ? className : ''}`} onClick={onClick}>
    <span className="CarouselNavButton-button">{children}</span>
  </span>
);

export default CarouselNavButton;
