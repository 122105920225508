import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AssetsSearchContainer } from './styled';
import { ToggleSearchInput } from 'components/shared/Inputs';
import { onChangeEvent } from 'types/events';
import { closeAssetSearchAndClearList, openAssetsSearch } from 'actions/assets';
import { useAssetSearch } from 'hooks';
import analytics from 'services/analytics';
import { SEGMENT_ACTIONS, SEGMENT_CATEGORIES, SEGMENT_EVENTS } from 'constants/analytics';

const AssetsHeaderSearch = () => {
  const { isAssetsSearchActive, assetsSearchValue } = useSelector(
    (store: any) => store.Assets, // TODO create store type
  );
  const dispatch = useDispatch();
  const [value, setValue] = useAssetSearch();
  const tracker: any = analytics;

  const handleChange = ({ target }: onChangeEvent) => setValue(target.value);

  const handleClose = () => {
    tracker.track(SEGMENT_EVENTS.SEARCH_EXITED, {
      action: SEGMENT_ACTIONS.CLOSE,
      category: SEGMENT_CATEGORIES.SEARCH,
      label: assetsSearchValue,
    });
    dispatch(closeAssetSearchAndClearList());
  };

  const handleOpen = () => {
    tracker.track(SEGMENT_EVENTS.SEARCH_TAPPED, {
      action: SEGMENT_ACTIONS.OPEN,
      category: SEGMENT_CATEGORIES.SEARCH,
    });
    dispatch(openAssetsSearch());
  };

  return (
    <AssetsSearchContainer>
      <ToggleSearchInput
        opened={isAssetsSearchActive}
        value={value}
        onOpen={handleOpen}
        onClose={handleClose}
        onChange={handleChange}
      />
    </AssetsSearchContainer>
  );
};

export default AssetsHeaderSearch;
