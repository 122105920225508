import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './USStatesSelect.scss';

const STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export class USStatesSelect extends Component {
  static propTypes = {
    selected: PropTypes.string,
    placeholder: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = { selected: this.props.selected || '' };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selected !== this.state.selected) {
      this.setState({ selected: nextProps.selected });
    }
  }

  onChange = e => {
    const selected = e.target.value || '';
    this.setState({ selected });
    this.props.onChange && this.props.onChange(selected);
  };

  render() {
    const StateOptions = Object.keys(STATES).map((abbreviation, index) => (
      <option key={index} value={abbreviation}>
        {STATES[abbreviation]}
      </option>
    ));

    const value = STATES[this.state.selected] || this.props.placeholder || 'Select...';

    const selectClasses = classnames('USStatesSelect-select', {
      'USStatesSelect-select-selected': this.state.selected,
    });

    return (
      <div className="USStatesSelect">
        <select
          className={selectClasses}
          defaultValue={this.state.selected}
          onChange={this.onChange}
        >
          {StateOptions}
        </select>
        <span className="USStatesSelect-value">{value}</span>
      </div>
    );
  }
}
