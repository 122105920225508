import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import MediaQuery from 'react-responsive';

import { EmailInput, Input } from 'components/shared/Inputs';
import { DotsLoader } from 'components/shared/Loaders';
import LoginSignupFormErrors from 'components/shared/LoginSignup/LoginSignupFormErrors';
import VerifyEmailPopup from 'components/shared/VerifyEmailPopup';

import { trimString, getUrlParameter, setPageTitle } from 'utils';
import { signupUser } from 'actions';

import './SignupForm.css';

class SignupForm extends Component {
  state = {
    loading: false,
    email: '',
    password: '',
    validationError: '',
    submitError: '',
    ref: undefined,
  };

  componentDidMount() {
    const { state } = this.props.history.location;
    const ref = getUrlParameter('ref');

    if (state && (state.email || state.password)) {
      this.setState({ email: state.email || '', password: state.password || '' });
    }
    if (ref) {
      this.setState({ ref });
    }

    setPageTitle('Signup');
  }

  onFormSubmit = async e => {
    e.preventDefault();
    document.activeElement.blur();
    if (this.state.loading || !this.validateForm()) return;
    this.setState({ loading: true });

    try {
      await this.props.signupUser(
        JSON.stringify({
          email: trimString(this.state.email),
          password: trimString(this.state.password),
          referral_code: this.state.ref,
        }),
      );
      // After successful registration mark the app launch as the first one:
      if (!localStorage.getItem('firstLaunch')) localStorage.setItem('firstLaunch', true);
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ submitError: err, loading: false });
    }
  };

  validateForm = () => {
    const email = trimString(this.state.email);
    const password = trimString(this.state.password);
    let validationError = '';

    if (!email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      validationError = 'Enter a valid email address';
    } else if (!password) {
      validationError = 'Enter a password';
    }
    this.setState({ validationError });
    return !validationError;
  };

  onFieldChange = (fieldName, fieldValue) =>
    this.setState({
      [fieldName]: fieldValue,
      validationError: '',
      submitError: '',
    });

  render() {
    const { loading, email, password, validationError, submitError } = this.state;
    const { isAuth, user, history } = this.props;

    return (
      <form className="SignupForm" onSubmit={this.onFormSubmit} noValidate>
        <MediaQuery minDeviceWidth={768}>
          <div className="SignupForm-callout">Create a New Account</div>
        </MediaQuery>
        <div className="SignupForm-inputs">
          <EmailInput
            value={email}
            name="email"
            placeholder="Email Address"
            onChange={email => this.onFieldChange('email', email)}
          />
          <Input
            value={password}
            name="password"
            type="password"
            placeholder="Password"
            onChange={password => this.onFieldChange('password', password)}
          />
          <button className="AuthenticationPages-actionButton" disabled={loading} type="submit">
            {loading ? <DotsLoader color="#fff" /> : 'Create Account'}
          </button>

          <LoginSignupFormErrors errors={validationError || submitError} />
        </div>

        <VerifyEmailPopup
          show={isAuth}
          onRequestClose={() =>
            history.replace({
              pathname: '/app/tour',
              state: {
                signupOnboardingFlow: true,
              },
            })
          }
          email={user.email}
          text={`An email has been sent to ${user.email}. Please verify to access all features on Rally`}
        />
      </form>
    );
  }
}

const mapStateToProps = state => ({ isAuth: state.Auth.isAuth, user: state.Auth.user });

const mapDispatchToProps = { signupUser };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignupForm));
