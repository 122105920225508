import React from 'react';

const Circle = props => {
  const scale = props.scale || 'vmax';
  return (
    <div
      className="Circle"
      style={{
        width: props.radius * 2 + scale,
        height: props.radius * 2 + scale,
        border: `${props.borderSize}${scale} solid ${props.borderColor}`,
        borderRadius: '50%',
        ...props.style,
      }}
    />
  );
};

export default Circle;
