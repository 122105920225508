import React, { Component } from 'react';
import { connect } from 'react-redux';

import { CloseButton } from 'components/shared/Buttons/CloseButton';
import TradingWindowBanner from './Common/TradingWindowBanner';
import AvailableFundsBanner from './Common/AvailableFundsBanner';

import MultistepForm from 'components/shared/MultistepForm';
import SelectBidAmount from 'components/shared/MultistepForm/steps/trading/SelectBidAmount';
import ReviewBid from 'components/shared/MultistepForm/steps/trading/ReviewBid';
import BidSummary from 'components/shared/MultistepForm/steps/trading/BidSummary';
import TradingLegalPopup from './Common/TradingLegalPopup';
import DwollaStatusPopup from 'components/shared/DwollaStatusPopup';
// import BasicUserInfoPopup from 'components/shared/BasicUserInfoPopup';
import StateAvailabilityError from 'components/shared/StateAvailabilityError';
import { getFinancialInfo } from 'actions';
import {
  DWOLLA_CUSTOMER_STATUS,
  STATUSES_TO_SKIP_EMPLOYER,
  FUNDING_SOURCE_STATUS,
  PASSPORT_SECTION,
  MIN_INCOME,
  ASSET_STATUS,
} from 'constants/main';
import { SEGMENT_ACTIONS, SEGMENT_CATEGORIES } from 'constants/analytics';
import { isEmpty } from 'utils';
import { createPassportData } from 'utils/passport';
import { PassportSteps } from 'components/shared/MultistepForm/steps/profile';
import {
  InvestingPassport,
  StartedInfo,
  Tos3Screen,
} from 'components/shared/MultistepForm/steps/investment';
import analytics from 'services/analytics';
import './PlaceBid.scss';
import { RiskToleranceLevel } from 'types/customer';
import { ReviewLoader, ReviewLoaderContext } from './ReviewLoader';
import { OrderContext, OrderType, OrderStatus } from 'types/orders';
import { SecondaryMarketStatus } from 'types/assets';

class PlaceBid extends Component {
  constructor(props) {
    super(props);

    const initialFormData = this.setInitialFormData();

    this.state = {
      showTradingLegalPopup: false,
      incompleteBasicUserInfo: null,
      renderDwollaStatusPopup: false,
      formStepIndex: 0,
      stepAmount: 1,
      isEditing: false,
      loaderState: {
        successType: null,
        type: null,
        active: false,
      },
      ...initialFormData,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return nextProps.balance && nextProps.balance.cash ? { cash: nextProps.balance.cash } : {};
  }

  steps = [];

  componentDidMount() {
    this.checkUserInfoCompletion();
    this.checkTradingLegalPopupRendering();
    this.checkDwollaStatusPopupRendering();
    this.props.getFinancialInfo();
    this.trackAnalyticsViewEvent(0);

    if (this.props.location.state && this.props.location.state.orderData) {
      const originalOrderData = this.props.location.state.orderData;

      this.setState({
        side: originalOrderData.taxonomy.context,
        shares: originalOrderData.units.unfilled,
        pricePerShare: Number(originalOrderData.pricing.unit).toFixed(2),
        originalOrderData,
        isEditing: true,
      });
    }
  }

  componentWillUnmount() {
    // this.props.unlockActiveAsset();
  }

  handleLoaderChange = loaderState => {
    this.setState({
      loaderState: {
        ...this.state.loaderState,
        ...loaderState,
      },
    });
  };

  onPassportMount = stepAmount => {
    this.setState({ stepAmount: stepAmount + this.steps?.length - 1 });
  };

  // Show TradingLegalPopup once per user per trading window
  checkTradingLegalPopupRendering = () => {
    const { activeTradingWindow } = this.props;
    if (!activeTradingWindow) return;

    const tradingWindowAgreementId = localStorage.getItem('tradingWindowAgreementId');
    if (!tradingWindowAgreementId || tradingWindowAgreementId !== activeTradingWindow.id) {
      this.setState({ showTradingLegalPopup: true });

      // track trading window disclosure modal
      analytics.page('Trading Window Disclosure', { title: 'Trading Window Disclosure Modal' });
    }
  };

  onTradingLegalConfirm = () => {
    localStorage.setItem('tradingWindowAgreementId', this.props.activeTradingWindow.id);
    this.setState({ showTradingLegalPopup: false });

    // Track disclosure agreement
    analytics.track('Trading Window Disclosure Agreed', {
      category: SEGMENT_CATEGORIES.TRADING_WINDOW,
      action: SEGMENT_ACTIONS.BUTTON_TAPPED_CLICKED,
      assetTicker: this.props.activeAsset.ticker,
      userId: this.props.user.id,
    });
  };

  setInitialFormData = () => {
    const {
      side = OrderContext.BID,
      user,
      balance,
      activeAsset,
      total_approved_shares,
      activeOrdersShares,
      pricing,
      user_investments,
    } = this.props;

    // Calculate active asset shares:
    // combinine investment and orders shares, substract pending sell shares:
    const activeInvestmentsSharesCount = total_approved_shares[activeAsset.id] || 0;
    const activeOrdersSharesCount = activeOrdersShares[activeAsset.financialInstrument?.id] || 0;
    const pendingSellShares = this.props.userOrders
      .filter(
        o =>
          (o.status === OrderStatus.SUBMITTED ||
            o.status === OrderStatus.PARTIALLY_FILLED ||
            o.status === OrderStatus.PENDING_CANCEL ||
            o.status === OrderStatus.PENDING_REPLACE) &&
          o.units?.unfilled > 0 &&
          o.taxonomy?.context === OrderContext.ASK &&
          (o.asset === activeAsset.id ||
            o.financialInstrumentId === activeAsset.financialInstrument.id),
      )
      .map(o => o.shares ?? o.units.unfilled)
      .reduce((prev, curr) => prev + curr, 0);

    const activeShares = activeInvestmentsSharesCount + activeOrdersSharesCount - pendingSellShares;

    const userShares = isEmpty(activeAsset) ? null : activeShares;

    if (isEmpty(activeAsset)) {
      this.handleFormClose();
    }

    const { accredited_status, publicly_traded_company_tickers, sec_or_finra_organization_name } =
      user.investment_info || {};

    const basicProfileFields = [
      'first_name',
      'last_name',
      'phone_number',
      'date_of_birth',
      'address',
      'city',
      'state',
      'ssn',
      'agreed_with_DriveWealth_TOS',
    ];

    const basicProfileCompleted = basicProfileFields.reduce((completed, field) => {
      return completed ? Boolean(user[field]) : false;
    }, true);

    const investmentDetailsCompleted =
      typeof accredited_status === 'number' &&
      typeof publicly_traded_company_tickers === 'string' &&
      typeof sec_or_finra_organization_name === 'string';

    const isSell = side === OrderContext.ASK;
    const secondaryMarketStatus = activeAsset.financialInstrument.markets.secondaryMarket.status;
    const isLiveTrading =
      activeAsset.asset_status === ASSET_STATUS.TRADING_OPENED &&
      secondaryMarketStatus === SecondaryMarketStatus.OPEN;
    const buySellOrderType =
      !isSell && isEmpty(user_investments) && isEmpty(this.props.userOrders) && isLiveTrading
        ? OrderType.INSTANT
        : OrderType.LIMIT;

    const initialFormData = createPassportData({
      side: side,
      asset: activeAsset || {},
      userShares: userShares || 0,
      userOrders: this.props.userOrders,
      user,
      investmentDetailsCompleted,
      basicProfileCompleted,
      sec_or_finra_organization_name,
      publicly_traded_company_tickers,
      accredited_status,
      balance,
      activeAsset,
      pricing,
      buySellOrderType,
      isLiveTrading,
    });

    return initialFormData;
  };

  checkDwollaStatusPopupRendering = () => {
    const isCreated = this.props.dwollaCustomerStatus !== DWOLLA_CUSTOMER_STATUS.NOT_CREATED;
    const isVerified = this.props.dwollaCustomerStatus === DWOLLA_CUSTOMER_STATUS.VERIFIED;
    this.setState({ renderDwollaStatusPopup: isCreated && !isVerified });
  };

  checkUserInfoCompletion = () => {
    const infoFields = [
      'first_name',
      'last_name',
      'phone_number',
      'date_of_birth',
      'address',
      'city',
      'state',
      'postal_code',
      // 'ssn',
    ];

    const isUserInfoCompleted = infoFields.reduce((isFieldCompleted, field) => {
      if (!isFieldCompleted) return false;
      return !isEmpty(this.props.user[field]);
    }, true);

    this.setState({ incompleteBasicUserInfo: !isUserInfoCompleted });
  };

  handleFormClose = () => {
    const { activeAssetCategory: category } = this.props;
    const ticker = this.props.activeAsset.ticker.replace('#', '');
    this.props.history.push(`/app/assets/${category.pathname}/${ticker}`);
  };

  /**
   * Updates form data in component's state
   * This is async operation! Use callback if needed
   * @param  {Object}   updates  - updates object
   * @param  {Function} callback - callback function (optional)
   */
  handleFormUpdate = (updates, callback) => {
    this.setState(
      state => ({
        ...state,
        ...updates,
      }),
      () => callback && callback(),
    );
  };

  trackAnalyticsViewEvent(stepIndex) {
    if (!isEmpty(this.props.activeAsset) && !isEmpty(this.steps[stepIndex])) {
      const { side } = this.state;
      const screenName = this.steps[stepIndex].name;

      // Track trading window progress
      analytics.track(`${side} ${screenName}`, {
        category: SEGMENT_CATEGORIES.TRADING_WINDOW,
        action: SEGMENT_ACTIONS.OPEN,
        assetTicker: this.props.activeAsset.ticker,
        order: stepIndex === this.steps?.length ? this.state.order.id.substring(0, 8) : null,
        orderData: this.state.order,
      });
    }
  }

  onStepChange = ({ nextStepIndex }) => {
    this.setState({ formStepIndex: nextStepIndex });
    this.trackAnalyticsViewEvent(nextStepIndex);
  };

  /**
   * Track when a user closes the Trading Window view.
   * This method requires more data, such as the step the user's on so it is not yet 100% complete.
   * Important not to track this at the last step since the user has successfully placed the order at this stage!
   */
  closeAndTrack = () => {
    const { side } = this.state;
    const assetTicker = this.props.activeAsset.ticker;

    // If this is the last step, do not trigger a cancel event since the user has technically placed the bid.
    if (this.state.formStepIndex !== this.state.steps?.length) {
      analytics.track(`${side} Canceled`, {
        category: SEGMENT_CATEGORIES.TRADING_WINDOW,
        action: SEGMENT_ACTIONS.CLOSE,
        assetTicker: assetTicker,
        step: this.steps[this.state.formStepIndex],
      });
    }
    // Finally, close the form.
    this.handleFormClose();
    return;
  };

  render() {
    const { renderDwollaStatusPopup, showTradingLegalPopup, incompleteBasicUserInfo, loaderState } =
      this.state;
    const isUserDwollaAndInfoValid = !renderDwollaStatusPopup && !incompleteBasicUserInfo;
    const isStateBlocked = this.props.user.state && this.props.user.state_status.is_state_blocked;

    if (incompleteBasicUserInfo === null) return null; // waits for basic user info to load and then go or not to passport

    const {
      isAssociated,
      isShareholder,
      fundingSourceCreated,
      isSmallDepositNeeded,
      investingPassportInfoCompleted,
      employmentStatus,
      willGoThroughPassport,
      agreed_with_DriveWealth_TOS,
      agreedWithTos3,
      investingPassportApplied,
      isLiveTrading,
      side,
      originalOrderData,
    } = this.state;
    const { bankFundingSource, user } = this.props;

    const fundingSourceExists = !isEmpty(bankFundingSource.info) || fundingSourceCreated;
    const shouldSkipConfirmation =
      (!isSmallDepositNeeded || !fundingSourceCreated) && fundingSourceExists;
    const shouldSkipBankVerification =
      bankFundingSource?.info?.status !== FUNDING_SOURCE_STATUS.UNVERIFIED;
    const { riskToleranceLevel } = user.customer?.investorDetails ?? {};
    const hasValidRiskProfile =
      riskToleranceLevel && riskToleranceLevel !== RiskToleranceLevel.Conservative;
    const { liquidNetWorth, pretaxAnnualIncome } = user.customer?.personalInformation ?? {};
    const validIncome = liquidNetWorth >= MIN_INCOME || pretaxAnnualIncome >= MIN_INCOME;

    const investingPassportCompleted =
      investingPassportInfoCompleted &&
      agreed_with_DriveWealth_TOS &&
      shouldSkipConfirmation &&
      fundingSourceExists &&
      hasValidRiskProfile &&
      validIncome;

    const shouldSkipEmployerInfo = STATUSES_TO_SKIP_EMPLOYER.includes(employmentStatus);

    if (!investingPassportCompleted && !willGoThroughPassport) {
      this.handleFormUpdate({ willGoThroughPassport: true });
    }

    const passportStepsArray = PassportSteps({
      investingPassportApplied:
        investingPassportCompleted && investingPassportApplied ? true : false,
      investingPassportCompleted,
      shouldSkipEmployerInfo,
      isAssociated,
      isShareholder,
      fundingSourceExists,
      shouldSkipConfirmation,
      shouldSkipBankVerification,
      isUserDwollaAndInfoValid,
    });

    let initialPassportIndex = 0;
    this.steps.forEach((step, index) => {
      if (step?.path === passportStepsArray?.[0]?.path) {
        initialPassportIndex = index;
      }
    });

    // IMPORTANT - if you update the steps, please remember to update closeAndTrack() to
    // reflect the index of the last step.
    this.steps = [
      {
        path: '/dwolla-tos',
        exact: true,
        render: Tos3Screen,
        name: 'Place Bid+Ask Tos 3',
        needToSkip: !investingPassportCompleted || agreedWithTos3,
      },
      {
        path: '/',
        render: StartedInfo,
        name: 'Place Bid+Ask Introduction',
        needToSkip: investingPassportCompleted && shouldSkipBankVerification,
      },
      ...passportStepsArray,
      {
        path: '/investing-passport',
        render: InvestingPassport,
        name: 'Place Bid+Ask',
        needToSkip: !willGoThroughPassport,
      },
      {
        path: '/select-bid',
        render: SelectBidAmount,
        name: 'Place Bid+Ask',
      },
      {
        path: '/review',
        render: ReviewBid,
        name: 'Review Bid+Ask',
      },
      {
        path: '/summary',
        render: BidSummary,
        name: 'Order Summary',
      },
    ];

    const isPassportRoute = !/select-bid|review|summary/.test(this.props.location.pathname);

    return (
      <div className={`PlaceBid ${isLiveTrading ? '' : ' post-only'}`}>
        <ReviewLoaderContext.Provider value={{ ...loaderState }}>
          <ReviewLoader />
          <CloseButton onClickHandler={() => this.closeAndTrack()} />
          <TradingWindowBanner />
          {!isStateBlocked && (
            <AvailableFundsBanner side={side} originalOrderData={originalOrderData} />
          )}

          <div className={`PlaceBid-wrapper ${isPassportRoute && 'PassportView'}`}>
            {isStateBlocked ? (
              <StateAvailabilityError onClose={this.handleFormClose} />
            ) : (
              <MultistepForm
                rootUrl="/app/place-bid"
                steps={this.steps}
                initialPassportIndex={initialPassportIndex}
                section={PASSPORT_SECTION.TRADING}
                onFormClose={this.handleFormClose}
                onFormUpdate={this.handleFormUpdate}
                onStepChange={this.onStepChange}
                onLoaderChange={this.handleLoaderChange}
                data={this.state}
                tradingLegalPopupPresented={showTradingLegalPopup}
              />
            )}
          </div>

          {isUserDwollaAndInfoValid && (
            <TradingLegalPopup
              show={showTradingLegalPopup}
              onConfirm={this.onTradingLegalConfirm}
            />
          )}

          {/* {!isStateBlocked && incompleteBasicUserInfo && (
            <BasicUserInfoPopup show={incompleteBasicUserInfo} />
          )} */}

          {renderDwollaStatusPopup && !incompleteBasicUserInfo && (
            <DwollaStatusPopup
              status={this.props.dwollaCustomerStatus}
              onClick={this.handleFormClose}
              onRetry={() => this.props.history.push('/app/onboarding')}
            />
          )}
          <TradingLegalPopup
            show={this.state.showTradingLegalPopup}
            onConfirm={this.onTradingLegalConfirm}
            onRequestClose={this.handleFormClose}
          />
        </ReviewLoaderContext.Provider>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeAsset: state.Assets.activeAsset,
  activeAssetCategory: state.Assets.activeAssetCategory,
  user: state.Auth.user,
  bankFundingSource: state.Investments.bankFundingSource,
  balance: state.Investments.balance,
  total_shares: state.Investments.total_shares,
  user_investments: state.Investments.user_investments,
  total_approved_shares: state.Investments.total_approved_shares,
  activeOrdersShares: state.Trading.activeOrdersShares,
  activeTradingWindow: state.Trading.activeTradingWindow,
  userOrders: state.Trading.userOrders,
  dwollaCustomerStatus: state.Auth.dwollaCustomerStatus,
  pricing: state.Assets.activeAsset.trading.pricing,
});

const mapDispatchToProps = {
  getFinancialInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaceBid);
