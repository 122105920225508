import {
  SET_USER_DATA,
  WIPE_REDUX_STATE,
  SHOW_GLOBAL_LOADER,
  HIDE_GLOBAL_LOADER,
  TOGGLE_MENU,
} from 'actions/types';

const UNAUTH_ALLOWED_ACTIONS = [
  SET_USER_DATA,
  WIPE_REDUX_STATE,
  SHOW_GLOBAL_LOADER,
  HIDE_GLOBAL_LOADER,
  TOGGLE_MENU,
];

/**
 * A redux middleware function that limits a set of dispatchable actions when the user is NOT authorized.
 * If the type of an action is not allowed for unauthorized user, the dispatch will be aborted,
 * thus preventing further state updates.
 */
export const actionAuthGuard = store => next => action => {
  const isAuthRequiredAction = !UNAUTH_ALLOWED_ACTIONS.includes(action.type);
  const { isAuth } = store.getState().Auth;

  if (isAuthRequiredAction && !isAuth) return;
  return next(action);
};
