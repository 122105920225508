import React from 'react';
import styled from '@emotion/styled';

export type TooltipType = { show: Boolean, ref?: React.MutableRefObject<HTMLDivElement | null>, tooltipPosition: Record<any, number> };

export const TooltipWrapper = styled.div<TooltipType>(
  ({ theme, show, tooltipPosition }) => ({
    '.tooltip': {
      position: 'absolute',
      textTransform: 'none',
      fontWeight: 400,
      width: 'calc(100% - 60px)',
      top: tooltipPosition.top - 12,
      left: '50%',
      transform: 'translate(-50%, -100%)',
      backgroundColor: '#DFB571',
      textAlign: 'left',
      fontSize: 14,
      borderRadius: '0.4em',
      padding: 10,
      fontFamily: 'Gotham Book',
      color: '#FFFFFF',
      lineHeight: 1,
      zIndex: 2,
      display: show ? 'block' : 'none',
      [theme.breakpoints.md]: {
        maxWidth: 250,
        left: tooltipPosition.left,
      },
      'strong': {
        fontFamily: 'Gotham Bold',
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: tooltipPosition.left - 27 / 2,
        transform: 'translate(-50%, 100%)',
        width: 0,
        height: 0,
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '12px solid #DFB571',
        
        [theme.breakpoints.md]: {
          left: 'calc(50% + 6px)',
        }
      }
    }
  }),
);
