import CONFIG from 'config.js';
import * as RequestHelper from './RequestHelper';

export function getBalance() {
  const userId = localStorage.getItem('user_id');
  const token = localStorage.getItem('token');
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + `/api/users/${userId}/financials/balance`;

  return RequestHelper.getRequest(endpoint, token);
}

export function getDwollaCustomerStatus() {
  const userId = localStorage.getItem('user_id');
  const token = localStorage.getItem('token');
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + `/api/users/${userId}/financials/status`;

  return RequestHelper.getRequest(endpoint, token);
}

export function getDwollaCustomerInfo() {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/dwolla/customers/info/';
  const token = localStorage.getItem('token');

  return RequestHelper.getRequest(endpoint, token);
}

export function getTransfers() {
  const userId = localStorage.getItem('user_id');
  const token = localStorage.getItem('token');
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + `/api/users/${userId}/financials/transactions`;

  return RequestHelper.getRequest(endpoint, token);
}

export function getBankFundingSource() {
  const userId = localStorage.getItem('user_id');
  const token = localStorage.getItem('token');
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + `/api/users/${userId}/financials/funding-source`;

  return RequestHelper.getRequest(endpoint, token);
}

export function makeTransfer(payload) {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/dwolla/transfers/';
  let body = JSON.stringify(payload);
  const token = localStorage.getItem('token');

  return RequestHelper.postRequestWithoutHandler(endpoint, token, body);
}

export function uploadDocument(payload) {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/dwolla/documents/';
  var formData = new FormData();

  formData.append('document_type', payload.document_type);
  formData.append('document', payload.document);

  const token = localStorage.getItem('token');

  return RequestHelper.postRequestWithoutHandlerWithFormData(endpoint, token, formData);
}

export function createDwollaCustomer(payload) {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/dwolla/customers/';
  let body = JSON.stringify(payload);
  const token = localStorage.getItem('token');

  return RequestHelper.postRequestWithoutHandler(endpoint, token, body);
}

export function createFundingSourceUsingPlaid(payload) {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/dwolla/plaid/';
  let body = JSON.stringify(payload);
  const token = localStorage.getItem('token');

  return RequestHelper.postRequestWithoutHandler(endpoint, token, body);
}

export function createFundingSourceUsingRouting(payload) {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/dwolla/funding-sources/';
  let body = JSON.stringify(payload);
  const token = localStorage.getItem('token');

  return RequestHelper.postRequestWithoutHandler(endpoint, token, body);
}

export function deleteFundingSource() {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/dwolla/bank-funding-source/';
  const token = localStorage.getItem('token');
  return RequestHelper.deleteRequestWithoutHandler(endpoint, token);
}

export function checkMicroDeposits(payload) {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/dwolla/micro-deposits/';
  let body = JSON.stringify(payload);
  const token = localStorage.getItem('token');

  return RequestHelper.postRequestWithoutHandler(endpoint, token, body).catch(errors => {
    throw errors[0].message;
  });
}
