import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import arrowDown from 'images/arrow-down.png';
import IframeComm from 'react-iframe-comm';
import { Spinner } from 'components/shared/Spinner';
import { Button } from 'components/shared/Buttons/Button';
import { Checkbox } from 'components/shared/Inputs/Checkbox';
import { setAgreementTimestamp } from 'actions';
import Mustache from 'mustache';
import moment from 'moment';
import { roundAccurately } from 'utils';
import analytics from 'services/analytics';
import { SEGMENT_CATEGORIES, SEGMENT_ACTIONS } from 'constants/analytics';

import './SubscriptionAgreement.scss';

const SubscriptionAgreement = props => {
  const { user } = useSelector(state => state.Auth);

  const { activeAsset } = useSelector(state => state.Assets);
  const { subscription_agreement_template } = activeAsset;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [checked, setChecked] = useState(false);
  const [rendered, setRendered] = useState(null);
  const [hasScrolled, setHasScrolled] = useState(false);

  const handleClick = () => {
    dispatch(setAgreementTimestamp(new Date().toISOString()));
    analytics.track('SA - Agreement Signed', {
      category: SEGMENT_CATEGORIES.BUY_SHARES,
      action: SEGMENT_ACTIONS.BUTTON_TAPPED_CLICKED,
      assetTicker: props.data.asset.ticker,
    });
    props.onStepForward();
  };

  useEffect(() => {
    const INVESTOR_ADDRESS = `
    ${user.address}
    ${user.address2 ? `${user.address2}` : ''}
    ${user.city}, ${user.state} ${user.postal_code}
    `;
    const INVESTOR_LEGAL_NAME = `${user.first_name}${
      user.middle_initial ? `${user.middle_initial} ` : ' '
    }${user.last_name}`;
    const totalPrice =
      '$' + roundAccurately(activeAsset.ipo_price_per_share * props.data.shares, 2);
    fetch(subscription_agreement_template?.hosted_template_url)
      .then(response => response.text())
      .then(template => {
        setRendered(
          Mustache.render(template, {
            ...subscription_agreement_template?.template_data?.values,
            INVESTMENT_SUBSCRIBED_MEMBERSHIP_INTERESTS_COUNT: props.data.shares,
            INVESTMENT_SUBSCRIBED_MEMBERSHIP_INTERESTS_TOTAL_PRICE: totalPrice,
            INVESTMENT_DATE_OF_AGREEMENT: moment().format('YYYY-MM-DD'),
            INVESTOR_DATE_OF_BIRTH: moment(user.date_of_birth).format('YYYY-MM-DD'),
            INVESTOR_EMAIL_ADDRESS: user.email,
            INVESTOR_ACCREDITATION_STATUS: user.investment_info.accredited_status,
            INVESTOR_PUBLICLY_TRADED_COMPANY_TICKERS:
              user.investment_info.publicly_traded_company_tickers,
            INVESTOR_SEC_OR_FINRA_ORGANIZATION_NAME:
              user.investment_info.sec_or_finra_organization_name,
            INVESTOR_FIRST_NAME: user.first_name,
            INVESTOR_LAST_NAME: user.last_name,
            INVESTOR_LEGAL_NAME,
            INVESTOR_SOCIAL_SECURITY_NUMBER: user.ssn,
            INVESTOR_TELEPHONE_NUMBER: user.phone_number,
            INVESTOR_ADDRESS,
          }),
        );
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    analytics.track('SA - Agreement Displayed', {
      category: SEGMENT_CATEGORIES.BUY_SHARES,
      action: SEGMENT_ACTIONS.SCREEN,
      assetTicker: props.data.asset.ticker,
    });
    // eslint-disable-next-line
  }, [])

  const onReady = () => {
    const iframe = document.getElementById('sa_iframe');
    if (iframe && iframe.contentDocument && iframe.contentDocument.firstChild) {
      iframe.contentDocument.onscroll = () => {
        if (!hasScrolled) setHasScrolled(true);
        const offset = iframe.contentDocument.firstChild.scrollTop;
        const totalHeight = iframe.contentDocument.firstChild.scrollHeight;
        // 1.16 means that the checkbox/button should enable 16% from the bottom of the scrollable window.
        // This number was chosen to be more "generous" when users are scrolling through the SA.
        if (offset * 1.16 >= totalHeight) {
          setDisabled(false);
        }
      };
    }
  };

  const onCheckboxChange = () => {
    if (!checked && btnDisabled) {
      setBtnDisabled(false);
    } else if (checked && !btnDisabled) {
      setBtnDisabled(true);
    }
    setChecked(!checked);
  };

  if (loading) {
    return (
      <div className="PreparingDocusign">
        <h2 className="SubscriptionAgreement__loading-text">
          We are preparing your subscription agreement.
          <br />
          Please review & sign to complete your investment.
        </h2>
        <div className="PreparingDocusign-spinner">
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className="SubscriptionAgreement__wrapper" onScroll={() => alert()}>
      <IframeComm
        attributes={{
          id: 'sa_iframe',
          width: '100%',
          height: '85%',
          srcDoc: rendered,
        }}
        handleReady={onReady}
      />
      <div className="SubscriptionAgreement__ctas-container">
        {!hasScrolled && (
          <div className="SubscriptionAgreement__ctas-scroller">
            <img src={arrowDown} alt="Arrow down" className="SubscriptionAgreement__ctas-img" />
            <p className="SubscriptionAgreement__ctas-scroller-text">scroll to acknowledge</p>
          </div>
        )}
        <div className="SubscriptionAgreement__ctas-wrapper">
          <Checkbox
            name="agree"
            size="large"
            checked={checked}
            disabled={disabled}
            type="green"
            onChange={onCheckboxChange}
          />
          <Button
            disabled={btnDisabled}
            className="SubscriptionAgreement__btn"
            onClick={handleClick}
          >
            I Agree
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionAgreement;
