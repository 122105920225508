import React, { Component } from 'react';

import { Price } from 'components/shared/Price';

import './OrderSummary.scss';

export class OrderSummary extends Component {
  render() {
    const { shares, ticker, price } = this.props;
    const s = shares > 1 ? 's' : '';
    return (
      <div className="OrderSummary">
        {shares} share{s}, {ticker} @ <Price price={price} />
      </div>
    );
  }
}
