import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import * as uuid from 'uuid';

import { Checkmark } from 'components/shared/Icons/Checkmark';
import { OrderSummary } from 'components/views/app/Assets/Trading/Common/OrderSummary';
import { TroughButtons } from 'components/views/app/Assets/Trading/Common/TroughButtons';
import CancelOrderPopup from 'components/views/app/Assets/Trading/Common/CancelOrderPopup';
import TextButton from 'components/shared/Buttons/TextButton';

import './BidSummary.scss';
import analytics from 'services/analytics';
import { SEGMENT_CATEGORIES, SEGMENT_ACTIONS, SEGMENT_EVENTS } from 'constants/analytics';
import { SecondaryButtonV2 } from 'components/shared/Buttons';
import { OrderStatus, OrderContext, OrderNewEditType } from 'types/orders';
import { getOrderById, deleteOrder } from 'services/AssetsAPI';
import { connect } from 'react-redux';
import { getActiveAssetDetails } from 'utils/trading';

import MultiActionAlertModal from 'components/shared/Modals/MultiActionAlertModal';
import getModalContent, {
  ORDER_FLOW_USERACTIONS,
} from 'components/views/app/Investments/OrderModalContent';
import { ModalViewContext } from 'components/shared/Modals/Modal';

class BidSummary extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    onFormUpdate: PropTypes.func.isRequired,
    onStepForward: PropTypes.func.isRequired,
    onStepBackward: PropTypes.func.isRequired,
  };

  state = { showCancelOrderPopup: false };

  componentDidMount() {
    if (!this.props.data.order) this.props.history.push('/app/orders');

    this.fireScreenEntryEvent();
  }

  fireScreenEntryEvent = async () => {
    const { side, isEditing, shares, buySellOrderType, pricePerShare, order } = this.props.data;
    const assetDetails = await this.props.getActiveAssetDetails();

    const eventName =
      side === OrderContext.BID ? SEGMENT_EVENTS.BID_SUMMARY : SEGMENT_EVENTS.ASK_SUMMARY;

    analytics.track(eventName, {
      action: SEGMENT_ACTIONS.OPEN,
      category: SEGMENT_CATEGORIES.TRADING,
      assetName: assetDetails.assetName,
      assetState: assetDetails.assetStatus,
      assetCategory: assetDetails.assetCategory,
      assetTicker: assetDetails.assetTicker,
      currentValue: assetDetails.currentTotalValue,
      ioValue: assetDetails.ioPrice,
      sinceIO: assetDetails.gainLoss?.allTime,
      currentSharePrice: assetDetails.currentSharePrice,
      lastCloseSharePrice: assetDetails.lastCloseSharePrice,
      side: side === OrderContext.ASK ? 'SELL' : 'BUY',
      orderType: isEditing ? OrderNewEditType.EDIT : OrderNewEditType.NEW,
      matchType: order.status,
      flow: buySellOrderType,
      shareQuantity: shares,
      sharePrice: Number(pricePerShare),
      lowestAsk: assetDetails.askLow,
    });
  };

  handleCancelOrder = async () => {
    const { side, shares, pricePerShare, order } = this.props.data;

    try {
      await getOrderById(order.id, order.financialAccount.id);

      if (order.status === OrderStatus.PROCESSING_UPDATE) {
        this.showErrorModal(ORDER_FLOW_USERACTIONS.PROCESSING_UPDATE_CANCEL);
        return;
      } else {
        await deleteOrder(order.id, order.financialAccount.id);
      }

      const assetDetails = await this.props.getActiveAssetDetails();
      analytics.track(SEGMENT_EVENTS.CANCEL_ORDER, {
        action: SEGMENT_ACTIONS.OPEN,
        category: SEGMENT_CATEGORIES.TRADING,
        assetName: assetDetails.assetName,
        assetState: assetDetails.assetStatus,
        assetCategory: assetDetails.assetCategory,
        assetTicker: assetDetails.assetTicker,
        currentValue: assetDetails.currentTotalValue,
        ioValue: assetDetails.ioPrice,
        sinceIO: assetDetails.gainLoss?.allTime,
        currentSharePrice: assetDetails.currentSharePrice,
        lastCloseSharePrice: assetDetails.lastCloseSharePrice,
        side: side === OrderContext.ASK ? 'SELL' : 'BUY',
        shareQuantity: shares,
        sharePrice: Number(pricePerShare),
        lowestAsk: assetDetails.askLow,
      });

      this.props.onFormClose();
    } catch (error) {
      this.props.onFormClose();
    }
  };

  showErrorModal = type => {
    const { setModalViewRenderSequence } = this.context;

    const modalContent = getModalContent(type);

    setModalViewRenderSequence([
      {
        state: true,
        id: uuid.v4(),
        children: <MultiActionAlertModal />,
        childrenProps: {
          title: modalContent.title,
          message: modalContent.message,
          userActions: modalContent.userActions,
        },
        transition: 'modalViewScaleDown',
      },
    ]);
  };

  onViewBidListClick = () => {
    this.props.history.push('/app/orders');
  };

  toggleCancelOrderPopup = () => {
    this.setState({ showCancelOrderPopup: !this.state.showCancelOrderPopup });
  };

  getOrderTitle = () => {
    const { order, side } = this.props.data;
    const orderStatus = order.status;
    if (side === OrderContext.ASK) {
      return 'Your ask to sell';
    }
    if (orderStatus === OrderStatus.FILLED) {
      return 'Your purchase';
    }
    return 'Your order';
  };

  getInfoStatusText = () => {
    const { order, side } = this.props.data;
    const orderStatus = order.status;
    const isSell = side === OrderContext.ASK;
    const bidAsk = isSell ? 'ask' : 'bid';
    switch (orderStatus) {
      case OrderStatus.FILLED:
        return 'Has been fulfilled!';
      case OrderStatus.PARTIALLY_FILLED:
        return 'Has been received and partially completed!';
      default:
        return `Has been received and is now in the ${bidAsk} queue!`;
    }
  };

  renderSharesUnits = () => {
    const { side, order } = this.props.data;
    const { units } = order;
    const isSell = side === OrderContext.ASK;
    const buySell = isSell ? 'sell' : 'buy';
    const hasFilledUnits = units.filled > 0;
    const hasUnfilledUnits = units.unfilled > 0;
    const showSharesColumn = hasFilledUnits && hasUnfilledUnits;

    return (
      <div className="BidSummary-type">
        {showSharesColumn && (
          <div className="BidSummary-type-column">
            <div className="BidSummary-type-header">Shares</div>
            <div className="BidSummary-type-value">{units.filled}</div>
            <div className="BidSummary-type-value">{units.unfilled}</div>
          </div>
        )}
        <div className="BidSummary-type-column">
          <div className="BidSummary-type-header">Action</div>
          {hasFilledUnits && <div className="BidSummary-type-value">{buySell}</div>}
          {hasUnfilledUnits && <div className="BidSummary-type-value">{buySell}</div>}
        </div>
        <div className="BidSummary-type-column">
          <div className="BidSummary-type-header">Status</div>
          {hasFilledUnits && <div className="BidSummary-type-value">executed</div>}
          {hasUnfilledUnits && <div className="BidSummary-type-value">open</div>}
        </div>
      </div>
    );
  };

  renderSummaryText = () => {
    const { side, order, isLiveTrading } = this.props.data;
    const isSell = side === OrderContext.ASK;
    const bidAsk = isSell ? 'ask' : 'bid';
    if (isLiveTrading) {
      if (order.status === OrderStatus.FILLED) {
        if (isSell) {
          return (
            <div className="BidSummary-tip">
              Your order has been matched and executed! Shares will now be withdrawn from your
              account and cash will be posted within 2 business days.
            </div>
          );
        }
        return (
          <div className="BidSummary-tip">
            Your order has been matched and executed! Funds will now be withdrawn from your account.
            Purchased shares must be held for 5 business days before re-selling.
          </div>
        );
      }
      if (order.status === OrderStatus.PARTIALLY_FILLED) {
        return (
          <div className="BidSummary-tip">
            Your remaining open order will stay in the{' '}
            <NavLink to="/app/orders">{bidAsk.toUpperCase()} queue</NavLink>. Open orders will also
            remain in your “pending” tab in your Portfolio, until the order is fully matched or
            canceled.
          </div>
        );
      }
    }
    return (
      <div className="BidSummary-tip">
        Your open {bidAsk.toUpperCase()} is now in{' '}
        <NavLink to="/app/orders">{bidAsk.toUpperCase()} queue.</NavLink> Open orders will remain in
        your “pending” tab in your Portfolio, until the order is fully matched or canceled.
      </div>
    );
  };

  render() {
    const { side, asset, order, isLiveTrading } = this.props.data;

    if (!order) {
      return null;
    }
    const isSell = side === OrderContext.ASK;
    const styleClass = isSell ? 'BidSummary-red' : '';

    const displayOrderNumber = order.id.substring(0, 8);
    const currentShares = order.units.requested;

    return (
      <div className="BidSummary">
        <div className="BidSummary-info">
          <Checkmark />
          <div className="BidSummary-info-title">
            <span className={styleClass}>{this.getOrderTitle()}</span>
          </div>
          <OrderSummary shares={currentShares} ticker={asset.ticker} price={order.pricing.unit} />
          <div className="BidSummary-info-status">
            <span className={styleClass}>{this.getInfoStatusText()}</span>
          </div>
          <div className="BidSummary-info-order">Order #: {displayOrderNumber}</div>
        </div>
        {this.renderSharesUnits()}
        {this.renderSummaryText()}
        <div className="BidSummary-contact">
          If you have any questions about your order, please email{' '}
          <a href="mailto:hello@rallyrd.com">hello@rallyrd.com</a>
        </div>
        <TroughButtons className="fixed-buttons">
          <SecondaryButtonV2
            className="TroughButtons-cta"
            outlined
            onClick={() => this.props.history.push('/app/investments')}
          >
            okay
          </SecondaryButtonV2>
          {!isLiveTrading && (
            <TextButton className="BidSummary-cancel-btn" onClick={this.toggleCancelOrderPopup}>
              Cancel Order
            </TextButton>
          )}
        </TroughButtons>

        <CancelOrderPopup
          show={this.state.showCancelOrderPopup}
          history={this.props.history}
          onRequestClose={this.toggleCancelOrderPopup}
          cancelOrder={this.handleCancelOrder}
          order={this.props.data.order}
          assetTicker={this.props.data.asset.ticker}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeAsset: state.Assets.activeAsset,
});

const mapDispatchToProps = {
  getActiveAssetDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(BidSummary);

BidSummary.contextType = ModalViewContext;
