import React from 'react';
import PropTypes from 'prop-types';

/**
 * SVG loader component
 * Renders three flashing dots
 */
const GalleryLoader = ({ width, height, dotColor }) => (
  <svg width={width} height={height} viewBox="0 0 50 100">
    <circle fill={dotColor} stroke="none" cx="6" cy="50" r="6">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.1"
      />
    </circle>
    <circle fill={dotColor} stroke="none" cx="26" cy="50" r="6">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.2"
      />
    </circle>
    <circle fill={dotColor} stroke="none" cx="46" cy="50" r="6">
      <animate
        attributeName="opacity"
        dur="1s"
        values="0;1;0"
        repeatCount="indefinite"
        begin="0.3"
      />
    </circle>
  </svg>
);

GalleryLoader.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  dotColor: PropTypes.string,
};

GalleryLoader.defaultProps = {
  width: '100',
  height: '100',
  dotColor: '#ccc',
};

export default GalleryLoader;
