import React, { useState, useContext, useEffect } from 'react';
import { ConditionalSimpleComponent } from 'types/components';
import instantImg from 'images/loaders/matching.gif';
import limitImg from 'images/loaders/searching.gif';
import check from 'images/loaders/check.png';
import partial from 'images/loaders/partial.png';
import { LoaderContainer, LoadingImg, LoadingText, SuccessImg, SuccessText } from './styled';
import { ReviewLoaderContext } from './ReviewLoaderContext';
import { OrderType } from 'types/orders';

const loaderTexts = {
  [OrderType.LIMIT]: 'Searching for a match...',
  [OrderType.INSTANT]: 'Matching you with a seller...',
};

const successTexts = {
  full: 'you’ve been matched!',
  partial: 'you’ve been partially matched!',
  instant: 'all set!',
  noMatch: 'no matches yet!',
};

export const ReviewLoader: ConditionalSimpleComponent<{}> = () => {
  const [loaderHidden, setLoaderHidden] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { active, type, successType } = useContext(ReviewLoaderContext);

  useEffect(() => {
    if (active && loaderHidden === false && successType) {
      setLoaderHidden(true);
      setTimeout(() => setShowSuccess(true), 500);
    }
  }, [active, loaderHidden, successType]);

  if (!active) {
    return null;
  }

  if (successType === 'noMatch') {
    return (
      <LoaderContainer>
        <SuccessText hidden={false}>{successTexts[successType]}</SuccessText>
        <LoadingText key="noMatch" hidden={false}>
          but we’ll add you to the queue
        </LoadingText>
      </LoaderContainer>
    );
  }

  if (successType && showSuccess) {
    return (
      <LoaderContainer>
        <SuccessImg
          hidden={false}
          halfcheck={successType === 'partial'}
          src={successType === 'partial' ? partial : check}
          alt="loading bid"
        />
        <SuccessText hidden={false}>{successTexts[successType]}</SuccessText>
      </LoaderContainer>
    );
  }
  return (
    <LoaderContainer>
      <LoadingImg
        hidden={!!successType}
        src={type === OrderType.INSTANT ? instantImg : limitImg}
        alt="loading bid"
      />
      <LoadingText key="default" hidden={!!successType}>
        {loaderTexts[type]}
      </LoadingText>
    </LoaderContainer>
  );
};
