import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Price } from 'components/shared/Price';

import './AvailableFundsBanner.scss';
import { SecondaryMarketStatus } from 'types/assets';
import { OrderContext } from 'types/orders';

class AvailableFundsBanner extends Component {
  render() {
    const { positions, originalOrderData } = this.props;
    const { financialInstrument } = this.props.activeAsset;
    const financialId = financialInstrument.id;

    const position = positions[financialId];

    const investmentsData = this.props.investmentsData || {};
    const balances = investmentsData.balance || {};
    let activeShares = 0;
    let availableShares = 0;

    const isLiveTrading =
      financialInstrument.markets.secondaryMarket.status === SecondaryMarketStatus.OPEN;
    const isSell = this.props.side === OrderContext.ASK;

    if (isSell) {
      // if for some reason the back is not able to set the right value
      // for the active shares in the positions endpoint, uncommenting this code back should solve the issue
      // const activeInvestmentsSharesCount = this.props.total_approved_shares[id] || 0; active asset id
      // const activeOrdersSharesCount = this.props.activeOrdersShares[financialId] || 0;
      // activeShares = activeOrdersSharesCount + activeInvestmentsSharesCount;
      if (position?.units) {
        activeShares = position.units.total;
        availableShares = position.units.openAndAlienable;
      }

      if (originalOrderData?.units) {
        availableShares += originalOrderData.units.unfilled;
      }
    }

    return (
      <span className={`AvailableFundsBanner ${isLiveTrading ? '' : ' post-only'}`}>
        {isSell ? (
          <>
            <div className="AvailableFundsBanner-cashsection">
              <div className="AvailableFundsBanner-section">
                <div className="AvailableFundsBanner-sectionheader">Shares Owned</div>
                <div className="AvailableFundsBanner-sectionvalue">{activeShares}</div>
              </div>
            </div>
            <div className="AvailableFundsBanner-cashsection">
              <div className="AvailableFundsBanner-section">
                <div className="AvailableFundsBanner-sectionheader">Available to Sell</div>
                <div className="AvailableFundsBanner-sectionvalue">{availableShares}</div>
              </div>
            </div>
          </>
        ) : (
          <div className="AvailableFundsBanner-cashsection">
            <div className="AvailableFundsBanner-section">
              <div className="AvailableFundsBanner-sectionheader">Available Cash</div>
              <div className="AvailableFundsBanner-sectionvalue">
                <Price price={balances.cash} keepZeros={true} />
              </div>
            </div>
          </div>
        )}
        <div className="AvailableFundsBanner-totalsection">
          <div className="AvailableFundsBanner-section">
            <div className="AvailableFundsBanner-sectionheader">Account Value</div>
            <div className="AvailableFundsBanner-sectionvalue">
              <Price price={balances.total} keepZeros={true} />
            </div>
          </div>
        </div>
      </span>
    );
  }
}

const mapStateToProps = state => ({
  investmentsData: state.Investments,
  activeAsset: state.Assets.activeAsset,
  positions: state.Investments.positions,
  // total_approved_shares: state.Investments.total_approved_shares,
  // activeOrdersShares: state.Trading.activeOrdersShares,
});

export default connect(mapStateToProps, null)(AvailableFundsBanner);
