import { ACCOUNT_STATUS } from 'constants/main';
import { EnrollmentStatus } from 'types/customer';
import { OrderContext, OrderType } from 'types/orders';

type PassportDataArgs = {
  [key: string]: any;
};

export type PassportData = ReturnType<typeof createPassportData>;

export const createPassportData = ({
  user,
  investingPassportApplied,
  basicProfileCompleted,
  investmentDetailsCompleted,
  sec_or_finra_organization_name,
  publicly_traded_company_tickers,
  accredited_status,
  balance,
  activeAsset,
  userShares,
  giftCode,
  side,
  userOrders,
  originalOrderData,
  pricePerShare,
  order,
  pricing,
  buySellOrderType = OrderType.INSTANT,
  isLiveTrading = false,
}: PassportDataArgs) => {
  const { enrollment: { status, termsOfService: { signedAndAgreedAt, lastUpdated }} } = user.customer?.accounts?.[0] || {};

  const tos2NotAgreed = status === EnrollmentStatus.Incomplete && !signedAndAgreedAt; // newUser
  const tos3NotAgreed = (status === EnrollmentStatus.Complete && (new Date(signedAndAgreedAt).getTime() < new Date(lastUpdated).getTime())) || tos2NotAgreed; // existing user with tos2 but no tos3

  const passportData = {
    // investing passport will allow the user to go through the investment flow in a simplified way,
    // bypassing the basic profile info steps:
    investingPassportApplied: investingPassportApplied === false ? false : true,
    basicProfileCompleted: basicProfileCompleted || null,
    investingPassportInfoCompleted: basicProfileCompleted && investmentDetailsCompleted,
    isAssociated: user.investment_info && !!sec_or_finra_organization_name,
    isShareholder: user.investment_info && !!publicly_traded_company_tickers,
    date_of_birth: user.date_of_birth || '',
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    phone_number: user.phone_number || '',
    address: user.address || '',
    apt: user.address2 || '',
    city: user.city || '',
    state: user.state || '',
    postal_code: user.postal_code || '',
    ssn: user.ssn || '',
    // agreed_with_DriveWealth_TOS:
    //   (user.customer?.accounts?.[0]?.status !== ACCOUNT_STATUS.ENROLLMENT_PENDING && !!user.ssn) ?? false,
    agreed_with_DriveWealth_TOS: !tos2NotAgreed,
    agreedWithTos3: !tos3NotAgreed,
    asset: activeAsset,
    accredited_status: accredited_status || 0,
    publicly_traded_company_tickers: publicly_traded_company_tickers || '',
    sec_or_finra_organization_name: sec_or_finra_organization_name || '',
    hasDwollaCustomer: user.has_dwolla_customer,
    agreeAndContinue:
      user.customer?.accounts?.[0]?.status === ACCOUNT_STATUS.ENROLLMENT_PENDING ?? false,
    cash: balance.cash,
    userShares: userShares || 0,
    availableShares: undefined,
    shares: null, // number of shares to buy
    cost: undefined, // purchase price
    accountType: undefined, // bank account type (SAVINGS or CHECKING)
    isSmallDepositNeeded: false, // verification via micro deposits
    fundingSourceCreated: false, // funding source was created with plaid
    renderDwollaStatusPopup: false,
    formStepIndex: 0,
    giftCode,
    docusignEnvelopeId: null,
    upsellProductData: [],
    employmentStatus: user.customer?.employmentDetails?.status ?? '',
    employer: user.customer?.employmentDetails?.employer ?? '',
    occupationOrJobTitle: user.customer?.employmentDetails?.occupationOrJobTitle ?? '',
    pretaxAnnualIncome: user.customer?.personalInformation?.pretaxAnnualIncome ?? '',
    liquidNetWorth: user.customer?.personalInformation?.liquidNetWorth ?? '',
    riskToleranceLevel: user.customer?.investorDetails?.riskToleranceLevel ?? '',
    side: side || OrderContext.BID,
    userOrders: userOrders || undefined,
    pricePerShare: pricePerShare || undefined,
    order: order || undefined, // created order,
    originalOrderData: originalOrderData || undefined,
    buySellOrderType,
    instantBuyDisabled: !pricing?.pricePerUnit?.orderBook?.askLow,
    askLow: pricing?.pricePerUnit?.orderBook?.askLow || null,
    isLiveTrading,
  };
  return passportData;
};
