import { handleActions } from 'redux-actions';

import {
  SET_ACTIVE_TRADING_WINDOW,
  UNSET_ACTIVE_TRADING_WINDOW,
  UPDATE_ACTIVE_TRADING_WINDOW,
  SET_ORDERS,
  SET_ORDER_AS_PENDING_CANCEL,
  SET_NONOPEN_TRADING_WINDOW_ORDERS,
  SET_TRADING_WINDOW_LOADING,
  SET_TRADING_WINDOW_LOADED,
} from 'actions/types';
import { OrderContext, OrderStatus } from 'types/orders';

const defaultState = {
  userOrders: [],
  // map of 'active' orders shares that were bought in secondary trading
  activeOrdersShares: {},
  // user orders that are matched during the review or fully processed
  nonOpenTradingWindowOrders: [],
  activeTradingWindow: null,
  isLoadingTradingWindow: false,
  errors: null,
};

const reducerMap = {};

reducerMap[SET_ACTIVE_TRADING_WINDOW] = (state, action) => {
  return {
    ...state,
    activeTradingWindow: action.payload,
  };
};

reducerMap[UNSET_ACTIVE_TRADING_WINDOW] = (state, action) => {
  return {
    ...state,
    activeTradingWindow: defaultState.activeTradingWindow,
  };
};

reducerMap[UPDATE_ACTIVE_TRADING_WINDOW] = (state, action) => {
  return {
    ...state,
    activeTradingWindow: { ...state.activeTradingWindow, ...action.payload },
  };
};

reducerMap[SET_TRADING_WINDOW_LOADING] = state => ({ ...state, isLoadingTradingWindow: true });

reducerMap[SET_TRADING_WINDOW_LOADED] = state => ({ ...state, isLoadingTradingWindow: false });

reducerMap[SET_ORDERS] = (state, action) => {
  const userOrders = action.payload.data;
  const activeOrdersShares = userOrders.reduce((result, current) => {
    if (!result[current.financialInstrumentId]) {
      result[current.financialInstrumentId] = 0;
    }
    let shares = 0;

    // Find active purchased ('BUY') investments, subtract exited ('SELL') investments:
    if (
      current.status === OrderStatus.FILLED ||
      current.status === OrderStatus.PARTIALLY_FILLED ||
      current.status === OrderStatus.PARTIALLY_FILLED_AND_CANCELED
    ) {
      const sharesCount = current.units.filled;
      shares = current.taxonomy.context === OrderContext.BID ? sharesCount : -sharesCount;
    }
    // Assign calculated shares value to appropriate asset id:
    result[current.financialInstrumentId] += shares;

    return result;
  }, {});

  return {
    ...state,
    userOrders,
    activeOrdersShares,
  };
};

reducerMap[SET_ORDER_AS_PENDING_CANCEL] = (state, action) => {
  const userOrders = action.payload.orders;
  const newUserOrders = userOrders.map(order => {
    if (order.id === action.payload.orderId) {
      return {
        ...order,
        status: OrderStatus.PENDING_CANCEL,
      };
    }

    return order;
  });

  return {
    ...state,
    userOrders: newUserOrders,
  };
};

reducerMap[SET_NONOPEN_TRADING_WINDOW_ORDERS] = (state, action) => {
  return {
    ...state,
    nonOpenTradingWindowOrders: action.payload,
  };
};

const assets = handleActions(reducerMap, defaultState);

export default assets;
