import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Input } from 'components/shared/Inputs/Input';
import { Button } from 'components/shared/Buttons/Button';
import { BackNextButtons } from 'components/shared/Buttons/BackNextButtons';

import { TRANSFER_TYPE } from 'constants/main';

import { makeTransfer } from 'actions';

import './Transfer.scss';

class Transfer extends Component {
  static propTypes = {
    data: PropTypes.object,
    onFormUpdate: PropTypes.func.isRequired,
    onStepForward: PropTypes.func.isRequired,
    onStepBackward: PropTypes.func.isRequired,
    bankFundingSource: PropTypes.object,
  };

  state = { transfer_amount: 0, showLimitError: false };

  handleAmountChange = value =>
    this.props.onFormUpdate(
      {
        transfer_amount: value,
      },
      this.validateStep,
    );

  validateStep = () => {
    const { transfer_amount } = this.props.data;
    if (transfer_amount && +transfer_amount > 5000) {
      return this.setState({ showLimitError: true, isStepValid: false });
    }
    this.setState({ isStepValid: +transfer_amount > 0, showLimitError: false });
  };

  handleTransferClick = () => {
    const { transfer_amount } = this.props.data;
    const payload = { type: TRANSFER_TYPE.DEPOSIT, amount: transfer_amount };
    this.props.makeTransfer(payload);
    this.props.onStepForward();
  };

  render() {
    const { isStepValid, showLimitError } = this.state;
    const {
      bankFundingSource,
      data: { transfer_amount },
    } = this.props;

    return (
      <div className="Transfer">
        <h2>Transfer to Rally</h2>
        <div className="Transfer-input">
          <Input
            decimalsOnly
            placeholder="0.00"
            value={transfer_amount}
            onChange={this.handleAmountChange}
          />
          {showLimitError && (
            <div className="Transfer-error">ONE-TIME TRANSFER CAN NOT EXCEED $5000</div>
          )}
        </div>
        <div className="Transfer-bankinfo">Transfer From: {bankFundingSource.info.name}</div>
        <Button
          text={<b>TRANSFER FUNDS</b>}
          onClick={this.handleTransferClick}
          disabled={!isStepValid || Object.keys(bankFundingSource.info).length === 0}
        />
        <BackNextButtons
          buttons={{ back: { text: 'back' }, style: 'centered' }}
          onBackClick={() => this.props.onStepBackward()}
        />
        <div className="Transfer-explanation">
          While you do need to maintain a cash balance to participate in a Trading Window, you can
          instantly purchase shares in Initial Offerings.
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  bankFundingSource: state.Investments.bankFundingSource,
});

const mapDispatchToProps = dispatch => ({
  makeTransfer: payload => dispatch(makeTransfer(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Transfer);
