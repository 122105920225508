import styled from '@emotion/styled';

export const RiskTitle = styled.h3(({ theme }) => ({
  fontSize: '2.2vh',
  marginBottom: '7vh',
  [theme.breakpoints.md]: {
    fontSize: 'inherit',
    marginBottom: '5vh',
  },
}));

export const RiskProfileContainer = styled.div(({ theme }) => ({
  marginBottom: '4vh',
  maxWidth: '100%',
  [theme.breakpoints.md]: {
    width: '400px',
  },
}));
