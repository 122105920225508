import React from 'react';
import { Popup } from 'components/shared/Popup';

import CHECKERED_FLAG from 'images/checkered-flag.png';
import './OneHundredPercentFundedPopup.css';

const OneHundredPercentFundedPopup = props => (
  <Popup className="OneHundredPercentFundedPopup" button={true} {...props}>
    <span className="OneHundredPercentFundedPopup-body">
      <img src={CHECKERED_FLAG} alt="checkered-flag" className="checkered-flag-img" />
      <h2>100% Funded!</h2>
      <p>
        The Initial Offering for this asset is now complete. That means this asset is closed to new
        investors until the first Trading Window, which usually occurs within 90 days of the Initial
        Offering’s official close. Check back soon to see the Trading calendar for this asset.
      </p>
    </span>
  </Popup>
);

export default OneHundredPercentFundedPopup;
