export const colors = {
  lightGrey: 'rgba(255,255,255,0.8)',
  borderGrey: '#E0E0E0',
  darkGreen: '#56AB9F',
  mediumGreen: '#67BF74',
  primary: '#53cd7f',
  red: '#c54e48',
  green: '#41c883',
  paleGreen: '#56AB9F',
  orange: '#e6ac4a',
  deepOrange: '#E8833A',
  moneyGreen: '#41c84b',
  grey: '#929591',
  greyHome: '#4F4F4F',
  greyPrimary: '#575D5E',
  blue: '#4689ca',
  textBlack: '#181818',
  lightText: '#888',
  clear: 'rgba(0,0,0,0)',
  darkGrey: '#666',
  lightBorder: '#ccc',
  newGreen: '#44D39C',
  pastelRed: '#D65151',
  placeBid: {
    border: '#C4C4C4',
  },
};
