import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import AssetDetailsPage from '../Common/AssetDetailsPage';
import TickerTitle from '../Common/TickerTitle';
import TextButton from 'components/shared/Buttons/TextButton';
import { Gallery } from 'components/shared/Icons/Gallery';

import './AssetVisuals.css';

class AssetVisuals extends Component {
  render() {
    const { activeAsset: car } = this.props;
    return (
      <div
        id="AssetVisuals"
        className={`AssetVisuals ${this.props.applicationMode}`}
        style={{ background: `url(${car.media_image})` }}
      >
        <div className="AssetVisuals-gradient">
          <AssetDetailsPage asset={car} className="AssetDetailsPage-assetVisuals" {...this.props}>
            <TickerTitle ticker={car.ticker} color={car.tertiary_color} title="Visuals" />
            <div className="AssetVisuals-description">{car.media_description}</div>
            <NavLink to="/app/gallery">
              <TextButton icon={<Gallery />} arrow="forward" style={{ color: car.tertiary_color }}>
                Launch Gallery
              </TextButton>
            </NavLink>
          </AssetDetailsPage>
        </div>
      </div>
    );
  }
}

export default AssetVisuals;
