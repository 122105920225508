import React from 'react';

import { Price } from 'components/shared/Price';
import { AdminButton } from 'components/shared/Buttons/AdminButton';
import { DWOLLA_CUSTOMER_STATUS } from 'constants/main';

import './MonetaryData.css';

const MonetaryData = props => {
  const isDocumentNeeded = props.dwollaCustomerStatus === DWOLLA_CUSTOMER_STATUS.DOCUMENT;
  let buttons = null;

  if (props.isVerificationNeeded) {
    buttons = (
      <div className="MonetaryData-buttons">
        <AdminButton text="Micro-deposit Verification" onClick={props.toggleMicroDepositsPopup} />
      </div>
    );
  } else {
    buttons = (
      <div className="MonetaryData-buttons">
        <AdminButton
          text={isDocumentNeeded ? 'Upload ID' : 'Transaction History'}
          onClick={isDocumentNeeded ? props.toDocument : props.toReceipts}
        />
        <AdminButton text="Statements" onClick={props.toStatements} />
        <AdminButton text="Deposit / Withdraw" onClick={props.toggleDepositWithdrawalPopup} />
      </div>
    );
  }

  return (
    <div className="MonetaryData">
      <div className="MonetaryData-section">
        <div>
          <div className="MonetaryData-section-header">Account Value</div>
          <div className="MonetaryData-section-content">
            <span className="MonetaryData-total">
              <Price price={props.balance.total} keepZeros={true} />
            </span>
          </div>
        </div>
      </div>
      <div className="MonetaryData-section">
        <div className="MonetaryData-section-header">Account Summary</div>
        <div className="MonetaryData-section-content">
          <span className="MonetaryData-group">
            <span className="MonetaryData-invested">
              <Price price={props.balance.investments} keepZeros={true} />
            </span>
            &nbsp;In Assets&nbsp;&nbsp;&nbsp;
          </span>
          <span className="MonetaryData-group">
            <span className="MonetaryData-cash">
              <Price price={props.balance.cash} keepZeros={true} />
            </span>
            &nbsp;In Cash&nbsp;&nbsp;&nbsp;
          </span>
          <span className="MonetaryData-group">
            <span className="MonetaryData-pending">
              (<Price price={props.balance.pending} keepZeros={true} />)
            </span>
            &nbsp;Pending
          </span>
        </div>
      </div>
      <div className="MonetaryData-section">{buttons}</div>
    </div>
  );
};

export default MonetaryData;
