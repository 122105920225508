import React from 'react';

const MailIcon = ({ className, style, ...props }) => {
  return (
    <svg
      className={`MailIcon${className ? ` ${className}` : ''}`}
      style={{ fill: 'currentColor', ...style }}
      width={67.733}
      height={51.858}
      {...props}
      viewBox="0 0 67.733 51.858"
    >
      <path d="M0 25.93V0h67.733v51.858H0zm63.5.924V6.083l-14.683 9.79c-8.075 5.386-14.803 9.792-14.95 9.792-.148 0-6.876-4.406-14.951-9.791L4.233 6.083v41.542H63.5zM46.302 12.435l12.303-8.201H9.128l12.303 8.201c6.767 4.511 12.363 8.202 12.436 8.202.072 0 5.668-3.69 12.435-8.202z" />
    </svg>
  );
};

export default MailIcon;
