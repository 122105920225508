import React, { PureComponent } from 'react';
import { Switch, Route } from 'react-router';
import { connect } from 'react-redux';

import PageVisibility from 'react-page-visibility';
import requireAuth from 'hoc/requireAuth';
import withDeviceDetection from 'hoc/withDeviceDetection';

import Header from './Header';
import NavigationMenu from './NavigationMenu';
import OnboardingATSCards from './OnboardingATSCards/OnboardingATSCards';
import Onboarding from './Onboarding/Onboarding';
import Tour from './Tour/Tour';
import FundAccount from './FundAccount/FundAccount';
import DocumentUpload from './DocumentUpload/DocumentUpload';
import Assets from './Assets/Assets';
import Contact from './Contact/Contact';
import Settings from './Settings/Settings';
import Investments from './Investments/Investments';
import Privacy from './Privacy/Privacy';
import Disclaimer from './Disclaimer/Disclaimer';
import Logout from './Logout/Logout';
import ConfirmTradePopup from 'components/views/app/Assets/Trading/ConfirmTradePopup';
import AssetsMobileFooter from 'components/views/app/Assets/AssetsMobileFooter/AssetsMobileFooter';
import TradingApprovedPopup from 'components/shared/TradingApprovedPopup';
import SpecialAccessPopup from 'components/shared/SpecialAccessPopup';
import VerifyEmailPopup from 'components/shared/VerifyEmailPopup';
import { ERROR_CODES, SUPPORTED_APPLICATION_MODES } from 'constants/main';
import { isRunningOnRallyAndroidClient } from 'utils';
import * as storageCheck from 'utils/storageCheck';
import * as specialAccessHelper from 'utils/specialAccess';
import { confirmOrder, getTradingWindow } from 'services/TradingAPI';
import {
  getAssets,
  getAssetsUpdates,
  getAssetsUpdateSchedule,
  getInvestments,
  getBalance,
  getBankFundingSource,
  getOrders,
  logoutUser,
  notifyOnIoOpenedCategories,
  unsetAssetsUpdateTimer,
  getFinancialInfo,
  toggleSaPopup,
  setSaPopup,
  clearSaPopup,
  setUserData,
  toggleAssetsDetails,
} from 'actions';

import './Mainpage.css';
import UserUpdates from './ProfileUpdates';
import { profileUpdateRoot } from 'constants/routes';

class Mainpage extends PureComponent {
  state = {
    activeIndex: 0,
    hasToken: true,
    isOrderConfirmationDeclined: false,
    isTokenChecked: false,
    orderToConfirm: null, // order that is pending for confirmation (ConfirmTradePopup)
    showTradingApprovedPopup: true,
    sideSignalIndex: 0,
    showHeaderMenuOnMobile: true,
    saAsset: {
      launched: false,
    },
    showVerifyEmailPopup: false,
  };

  renderSaPopup = (prevSaAssets = undefined, currentCount = 1) => {
    const { user, toggleSaPopup, setSaPopup, saPopup } = this.props;
    const { getSpeciallyAccessibleAssetsIdAccessTypeAndLimitationsMap } = user || {};
    let count = currentCount;

    if (getSpeciallyAccessibleAssetsIdAccessTypeAndLimitationsMap) {
      const map = user.getSpeciallyAccessibleAssetsIdAccessTypeAndLimitationsMap();
      const saAssets = prevSaAssets || map.entries();
      const saAsset = saAssets.next().value;

      if (saAsset) {
        const allowedToShow = specialAccessHelper.shouldShow({
          userId: user.id,
          assetId: saAsset[0],
          assetType: saAsset[1]?.accessType,
        });

        if (saAsset[0] && saAsset[1] && allowedToShow && !saPopup.show) {
          this.setState(
            { saAsset: { ...this.state.saAsset, id: saAsset[0], details: saAsset[1] } },
            () => {
              setSaPopup({ saAsset });
              toggleSaPopup();
            },
          );
        } else if (map.size > count) {
          // recursively goes to the next asset
          count++;
          this.renderSaPopup(saAssets, count);
        }
      }
    }
  };

  checkAtsCards = async () => {
    const { user, history } = this.props;
    const shouldShow = (await storageCheck.atsCards(user.id)).check();

    if (shouldShow) {
      history.replace('/app/ats/live-trading', { lastUrl: history.location.pathname, shouldShow });
    }
  };

  async componentDidMount() {
    if (
      localStorage.getItem('firebase_token') &&
      isRunningOnRallyAndroidClient({ checkNativeBridge: true })
    ) {
      window.AndroidBridge.pushNotificationTokenRegistered &&
        window.AndroidBridge.pushNotificationTokenRegistered();
      localStorage.removeItem('firebase_token');
    }

    // fires ats educational cards
    await this.checkAtsCards();

    await this.props.getAssets();
    await this.props.getFinancialInfo();
    await this.props.getOrders();
    await this.props.getBankFundingSource();
    this.validateApplicationState();
  }

  /**
   * Show indicators for initial offerings in the nav menu after completing onboarding flow.
   */
  componentDidUpdate(prevProps) {
    const isFirstLaunch = localStorage.getItem('firstLaunch');
    const isOnboardingRoute = /tour|onboarding|fund-account|document/.test(
      this.props.location.pathname,
    );

    if (isFirstLaunch && !isOnboardingRoute) {
      localStorage.removeItem('firstLaunch');
      this.props.notifyOnIoOpenedCategories(true);
      this.props.getBankFundingSource();
    }

    isOnboardingRoute
      ? this.setState({ showHeaderMenuOnMobile: false })
      : this.setState({ showHeaderMenuOnMobile: true });

    const bankFundingIsLoaded =
      prevProps.bankFundingSource?.loading === true &&
      this.props.bankFundingSource?.loading === false;

    if (!isOnboardingRoute && !this.state.saAsset.launched && bankFundingIsLoaded) {
      this.setState({ saAsset: { ...this.state.saAsset, launched: true } }, () =>
        this.renderSaPopup(),
      );
    }

    const exitedSaFlow =
      prevProps.location.pathname.includes('make-investment') &&
      !this.props.location.pathname.includes('make-investment');

    if (exitedSaFlow && (this.props.saPopup?.asset?.id || this.props.saPopup?.autoGiftCode)) {
      // clear auto gift code and saPopup
      this.props.clearSaPopup();

      if (this.props.saPopup?.asset.type === 'free_share_program') {
        this.props.toggleAssetsDetails(false);
      }
    }
  }

  async checkOrderConfirmationNeeded(orders) {
    const { assetList } = this.props;

    if (!orders.length || !assetList.length) return;

    let pendingOrders = [];

    for (const order of orders) {
      if (
        order.status === 'OPEN' &&
        (order.confirmed_matched_status === 'PARTIALLY_MATCHED' ||
          order.confirmed_matched_status === 'MATCHED')
      ) {
        const asset = assetList.find(asset => asset.id === order.asset);

        if (!asset) return;
        if (
          asset.trading.current_trading_window &&
          asset.trading.current_trading_window.status === 'CONFIRMATION'
        ) {
          pendingOrders.push(order);
        } else {
          try {
            const tradingData = await getTradingWindow(asset.trading.current_trading_window.id);
            if (tradingData.status === 'CONFIRMATION') {
              pendingOrders.push(order);
            }
          } catch (error) {}
        }
      }
    }

    if (pendingOrders.length) {
      this.setState({ orderToConfirm: pendingOrders[0] });
    }
  }

  // Check order on Will Receive Props
  componentWillReceiveProps(nextProps) {
    if (!this.state.isOrderConfirmationDeclined) {
      this.checkOrderConfirmationNeeded(nextProps.userOrders);
    }
  }

  toggleVerifyEmailPopup = () => {
    this.setState({ showVerifyEmailPopup: !this.state.showVerifyEmailPopup });
  };

  getPopups = () => {
    const { user } = this.props;
    const isNotifiedOnTradingApproved = localStorage.getItem('notifiedOnTradingApproved');

    return (
      <>
        <TradingApprovedPopup
          show={
            this.state.showTradingApprovedPopup &&
            !isNotifiedOnTradingApproved &&
            !user.state_status.io_enabled &&
            user.state_status.trading_enabled
          }
          onClose={this.onTradingApprovedNotified}
        />

        <ConfirmTradePopup
          show={!!this.state.orderToConfirm}
          order={this.state.orderToConfirm}
          onConfirm={this.onConfirmOrder}
          onCancel={this.onCancelOrderConfirmation}
        />

        <SpecialAccessPopup toggleVerifyEmailPopup={this.toggleVerifyEmailPopup} />

        <VerifyEmailPopup
          show={this.state.showVerifyEmailPopup}
          text={`In order to redeem your free share on Rally you must first verify your email ${user.email}`}
          onRequestClose={this.toggleVerifyEmailPopup}
          email={user.email}
        />
      </>
    );
  };

  onConfirmOrder = () => {
    confirmOrder(this.state.orderToConfirm)
      .then(() => {
        this.setState({ orderToConfirm: null });
        this.props.getOrders();
      })
      .catch(err => console.log(err));
  };

  onCancelOrderConfirmation = () => {
    this.setState({
      isOrderConfirmationDeclined: true,
      orderToConfirm: null,
    });
  };

  onTradingApprovedNotified = () => this.setState({ showTradingApprovedPopup: false });

  /**
   * called once the application is done initializing and determines whether the application should be rendered based
   *  upon its current state, or if the user should be returned login.
   * */
  validateApplicationState = () => {
    if (!this.props.assetList || !this.props.assetList.length) {
      const errorCode =
        this.props.applicationMode === SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS
          ? ERROR_CODES.NOT_AUTHORIZED_FOR_EARLY_ACCESS
          : ERROR_CODES.NO_ACCESSIBLE_ASSETS;

      this.props.logoutUser(errorCode);
    }
  };

  handleVisibilityChange = isVisible => {
    if (isVisible) {
      this.props.getAssets({ showGlobalLoader: false, forceFullRefresh: true, tabChange: true });
    } else if (!isVisible) {
      this.props.unsetAssetsUpdateTimer();
    }
  };

  renderByApplicationMode = () => {
    switch (this.props.applicationMode) {
      case SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS:
        return this.renderSpecialAccess();
      default:
        return this.renderDefault();
    }
  };

  renderDefault = () => {
    const { location } = this.props;

    return (
      <PageVisibility onChange={this.handleVisibilityChange}>
        <main className="Mainpage">
          <Header showHeaderMenuOnMobile={this.state.showHeaderMenuOnMobile} />

          <section className="Mainpage-body">
            <NavigationMenu location={location.pathname} />
            <div className="Mainpage-body-content">
              <Switch>
                <Route
                  path="/app/assets/:category/:asset?"
                  render={props => <Assets {...props} />}
                />
                <Route path="/app/ats" render={props => <OnboardingATSCards {...props} />} />
                <Route path="/app/onboarding" render={props => <Onboarding {...props} />} />
                <Route path="/app/tour" render={props => <Tour {...props} />} />
                <Route path="/app/fund-account" render={props => <FundAccount {...props} />} />
                <Route path="/app/document" render={props => <DocumentUpload {...props} />} />
                <Route path="/app/investments" render={props => <Investments {...props} />} />
                <Route path="/app/settings" render={() => <Settings />} />
                <Route path="/app/contact" render={props => <Contact {...props} />} />
                <Route path="/app/privacy" render={() => <Privacy />} />
                <Route path="/app/disclaimer" render={() => <Disclaimer />} />
                <Route path="/app/logout" render={() => <Logout />} />
                <Route path={profileUpdateRoot} render={() => <UserUpdates />} />
                <Route
                  path="/app"
                  render={props => {
                    // if user profile isn't configured properly and user is coming from verify email screen
                    if (
                      props.location.state &&
                      props.location.state.signupOnboardingFlow &&
                      isRunningOnRallyAndroidClient({ checkNativeBridge: true })
                    ) {
                      window.AndroidBridge.checkPushNotificationOptIn &&
                        window.AndroidBridge.checkPushNotificationOptIn();
                    }

                    return <Assets {...props} />;
                  }}
                />
              </Switch>
            </div>
            {!this.props.globalLoading && <AssetsMobileFooter location={location.pathname} />}
          </section>
          {this.getPopups()}
        </main>
      </PageVisibility>
    );
  };

  renderSpecialAccess = () => {
    const { location } = this.props;

    return (
      <main className="Mainpage special-access">
        <Header />
        <NavigationMenu location={location.pathname} />
        <section className="Mainpage-body special-access">
          <div className="Mainpage-body-content special-access">
            <Switch>
              <Route path="/app/assets/:category/:asset?" component={Assets} />
              <Route path="/app/contact" render={props => <Contact {...props} />} />
              <Route path="/app/logout" render={() => <Logout />} />
              <Route path="/app/privacy" render={() => <Privacy />} />
              <Route path="/app" render={props => <Assets {...props} />} />
            </Switch>
          </div>
        </section>
        {this.getPopups() /* TODO: determine if popups are needed for special access mode */}
      </main>
    );
  };

  render() {
    return this.renderByApplicationMode();
  }
}

const mapStateToProps = state => ({
  applicationMode: state.UI.applicationMode,
  globalLoading: state.UI.globalLoading,
  assetList: state.Assets.assetList,
  user: state.Auth.user,
  userOrders: state.Trading.userOrders,
  saPopup: state.UI.saPopup,
  bankFundingSource: state.Investments.bankFundingSource,
});

const mapDispatchToProps = {
  getAssets,
  getAssetsUpdateSchedule,
  getAssetsUpdates,
  getInvestments,
  unsetAssetsUpdateTimer,
  getBalance,
  getBankFundingSource,
  getOrders,
  logoutUser,
  notifyOnIoOpenedCategories,
  getFinancialInfo,
  toggleSaPopup,
  setSaPopup,
  setUserData,
  clearSaPopup,
  toggleAssetsDetails,
};

export default requireAuth(
  connect(mapStateToProps, mapDispatchToProps)(withDeviceDetection(Mainpage)),
);
