import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Input } from 'components/shared/Inputs/Input';
import { ValidationWarning } from 'components/shared/ValidationWarning';
import CollectInfoMessage from 'components/shared/CollectInfoMessage';
import { BackNextButtons } from 'components/shared/Buttons/BackNextButtons';
import { updateUserData } from 'actions';

import './DateOfBirth.css';

class DateOfBirth extends Component {
  static propTypes = {
    data: PropTypes.object,
    onFormUpdate: PropTypes.func.isRequired,
    onStepForward: PropTypes.func.isRequired,
    onStepBackward: PropTypes.func.isRequired,
    updateUserData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    formMode: 'investment',
  };

  state = {
    isStepValid: false,
    isDate: false,
    isAdult: false,
  };

  initialData = {};

  componentDidMount() {
    this.validateStep();
    this.initialData = this.props.data;
  }

  validateStep = () => {
    let {
      data: { date_of_birth },
    } = this.props;
    this.validationMessage = 'MM/DD/YYYY';
    if (!date_of_birth || date_of_birth.length < 8) {
      return this.setState({ isStepValid: false, isDate: false, isAdult: false });
    }
    let dob = new Date(date_of_birth);
    if (!(dob instanceof Date) || isNaN(dob)) {
      return this.setState({ isStepValid: false, isDate: false, isAdult: false });
    }

    const yearsFromNow = moment().diff(moment(dob, 'YYYY-MM-DD'), 'years');

    const isNotAdult = yearsFromNow > 0 && yearsFromNow < 18;
    const isAdult = !isNotAdult;
    const isDate = true;
    let isStepValid = true;

    if (!isAdult) {
      isStepValid = false;
      this.validationMessage = 'In order to invest on Rally you must be above the age of 18.';
    }
    if (yearsFromNow <= 0) {
      isStepValid = false;
      this.validationMessage = 'Birth year should be in the past: MM/DD/YYYY';
    }
    if (yearsFromNow > 150) {
      isStepValid = false;
      this.validationMessage = "You aren't that old. Check your birthday: MM/DD/YYYY";
    }

    this.setState({ isStepValid, isDate, isAdult });
  };

  handleNextClick = () => {
    const {
      data: { date_of_birth },
    } = this.props;
    const formatted_date_of_birth = moment(new Date(date_of_birth), 'YYYY-MM-DD').format(
      'MM/DD/YYYY',
    ); // <- to US format
    this.props.updateUserData({ date_of_birth: formatted_date_of_birth });
    this.props.onStepForward();
  };

  handleBackClick = () => {
    if (this.props.resetOnBackPress) {
      this.props.onFormUpdate({ date_of_birth: this.initialData.date_of_birth });
    }
    this.props.onStepBackward();
  };

  handleDateChange = date => {
    return this.props.onFormUpdate({ date_of_birth: date }, this.validateStep);
  };

  render() {
    const { isStepValid, isDate, isAdult } = this.state;
    const {
      formMode,
      data: { date_of_birth },
    } = this.props;
    const isValid = !(isDate && !isAdult);
    const completeWhat = formMode === 'investment' ? 'your investment' : 'your profile';
    const validationMessage = this.validationMessage ? this.validationMessage : 'MM/DD/YYYY';

    return (
      <div className="DateOfBirth">
        <CollectInfoMessage completeWhat={completeWhat} />
        <p>Please enter your birthday</p>
        <Input
          value={date_of_birth}
          type="date_of_birth"
          placeholder="MM/DD/YYYY"
          onChange={this.handleDateChange}
          valid={isValid}
        />
        <ValidationWarning valid={isValid} message={validationMessage} />
        <BackNextButtons
          buttons={{ back: { text: 'back' }, next: { text: 'next' } }}
          nextDisabled={!isStepValid}
          onBackClick={this.handleBackClick}
          onNextClick={this.handleNextClick}
        />
      </div>
    );
  }
}

const mapDispatchToProps = { updateUserData };

export default connect(null, mapDispatchToProps)(DateOfBirth);
