import React from 'react';
import ReactDOM from 'react-dom';
import { ToggleSearchInput } from 'components/shared/Inputs';
import { useDispatch, useSelector } from 'react-redux';
import { closeAssetSearchAndClearList, openAssetsSearch } from 'actions/assets';
import { MobileSearchHeaderContainer } from './styled';
import MobileSearchModal from './MobileSearchModal';
import { onChangeEvent } from 'types/events';
import { withRouter } from 'react-router';
import { useAssetSearch } from 'hooks';
import analytics from 'services/analytics';
import { SEGMENT_ACTIONS, SEGMENT_CATEGORIES, SEGMENT_EVENTS } from 'constants/analytics';

const MobileSearch = ({ isHome = false }: { isHome?: boolean }) => {
  const [value, setValue] = useAssetSearch();
  const { isAssetsSearchActive, assetsSearchValue } = useSelector((store: any) => store.Assets);
  const dispatch = useDispatch();
  const tracker: any = analytics;

  const handleOpen = () => {
    tracker.track(SEGMENT_EVENTS.SEARCH_TAPPED, {
      action: SEGMENT_ACTIONS.OPEN,
      category: SEGMENT_CATEGORIES.SEARCH,
    });
    dispatch(openAssetsSearch());
  };

  const handleClose = () => {
    tracker.track(SEGMENT_EVENTS.SEARCH_EXITED, {
      action: SEGMENT_ACTIONS.CLOSE,
      label: assetsSearchValue,
      category: SEGMENT_CATEGORIES.SEARCH,
    });
    dispatch(closeAssetSearchAndClearList());
  };

  const handleChange = ({ target }: onChangeEvent) => setValue(target.value);

  const modalRoot: any = document.querySelector('#modal-root');

  return (
    <>
      <MobileSearchHeaderContainer active={isAssetsSearchActive} isHome={isHome}>
        <ToggleSearchInput
          onOpen={handleOpen}
          value={value}
          onChange={handleChange}
          onClose={handleClose}
          opened={isAssetsSearchActive}
        />
      </MobileSearchHeaderContainer>
      {ReactDOM.createPortal(<MobileSearchModal />, modalRoot)}
    </>
  );
};

//@ts-ignore
export default withRouter(MobileSearch);
