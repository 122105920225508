import CONFIG from 'config.js';
import * as RequestHelper from './RequestHelper';
import { getRequest } from './RequestHandler';

export function getTradingWindows() {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/trading/windows/';
  const token = localStorage.getItem('token');
  return RequestHelper.getRequest(endpoint, token);
}

export async function getOrders(accountId) {
  const endpoint = CONFIG.apiUrlFinancialAccounts + `/financial-accounts/${accountId}/orders`;
  return await getRequest(endpoint, { conditionalType: 'if-none-match' });
}

export function getTradingWindow(windowId) {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + `/api/trading/windows/${windowId}/`;
  const token = localStorage.getItem('token');
  return RequestHelper.getRequest(endpoint, token);
}

export async function getOrderBook(securityId) {
  const endpoint =
    CONFIG.apiUrlFinancialInstruments +
    `/financial-instruments/securities/${securityId}/markets/order-book`;
  const resp = await getRequest(endpoint, { conditionalType: 'if-none-match' });
  return resp;
}

export function createBuyOrder(orderData) {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/trading/orders/';
  const token = localStorage.getItem('token');
  const body = JSON.stringify({ ...orderData, side: 'BUY' });
  return RequestHelper.postRequestWithoutHandler(endpoint, token, body);
}

export function createSellOrder(orderData) {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/trading/orders/';
  const token = localStorage.getItem('token');
  const body = JSON.stringify({ ...orderData, side: 'SELL' });
  return RequestHelper.postRequestWithoutHandler(endpoint, token, body);
}

export function editExistingOrder(orderId, orderData) {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + `/api/trading/orders/${orderId}/`;
  const token = localStorage.getItem('token');
  const body = JSON.stringify({ ...orderData });
  return RequestHelper.patchRequest(endpoint, token, body);
}

export function confirmOrder(orderData) {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + `/api/trading/orders/${orderData.id}/confirm/`;
  const token = localStorage.getItem('token');
  const body = JSON.stringify({ ...orderData });
  return RequestHelper.postRequestWithoutHandler(endpoint, token, body);
}

export function cancelOrder(orderId) {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + `/api/trading/orders/${orderId}/`;
  const token = localStorage.getItem('token');
  return RequestHelper.deleteRequestWithoutHandler(endpoint, token);
}
