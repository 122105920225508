import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as uuid from 'uuid';
import IframeComm from 'react-iframe-comm';
import { Spinner } from 'components/shared/Spinner';
import Error from 'components/shared/Error';
import { ModalViewContext } from 'components/shared/Modals/Modal';
import AlertModal from 'components/shared/Modals/AlertModal';
import { getDocusignUrl } from 'services/DocusignAPI';

import CONFIG from 'config';

import './Docusign.css';

class Docusign extends Component {
  static propTypes = {
    data: PropTypes.object,
    onStepForward: PropTypes.func.isRequired,
    onFormUpdate: PropTypes.func.isRequired,
  };

  state = { isLoading: false, error: null, envelopeViewUrl: '' };

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { envelope_id, envelope_view_url } = await getDocusignUrl({
        return_url: CONFIG.docusignCallback,
        shares: this.props.data.shares,
        asset: this.props.data.asset.id,
        investment_info: {
          sec_or_finra_organization_name: this.props.data.sec_or_finra_organization_name,
          publicly_traded_company_tickers: this.props.data.publicly_traded_company_tickers,
          accredited_status: this.props.data.accredited_status,
        },
      });
      this.setState({ envelopeViewUrl: envelope_view_url });
      this.props.onFormUpdate({ docusignEnvelopeId: envelope_id });
    } catch (err) {
      this.setState({ error: true });
    }
    this.setState({ isLoading: false });
  }

  onReceiveMessage = e => {
    const { setModalViewRenderSequence } = this.context;

    if (e.data === 'OK') return this.props.onStepForward();
    if (e.data === 'error' || e.data === 'cancel' || e.data === 'decline') {
      setModalViewRenderSequence([
        {
          state: true,
          id: uuid.v4(),
          children: <AlertModal />,
          childrenProps: {
            title: 'Unable to purchase the share',
            message: 'There was a problem with Docusign. Please contact support@rallyrd.com.',
          },
          transition: 'modalViewScaleDown',
        },
      ]);
      this.props.onFormClose();
    }
  };

  render() {
    const { isLoading, error, envelopeViewUrl } = this.state;

    if (isLoading) {
      return (
        <div className="PreparingDocusign">
          <h2>We are preparing a docusign, please review & sign to complete your investment.</h2>
          <div className="PreparingDocusign-spinner">
            <Spinner />
          </div>
        </div>
      );
    }

    if (error) return <Error error={error.message || 'Sorry, something went wrong'} />;

    if (envelopeViewUrl) {
      return (
        <div className="Docusign">
          <IframeComm
            attributes={{
              width: '100%',
              height: '100%',
              src: envelopeViewUrl,
            }}
            handleReceiveMessage={this.onReceiveMessage}
          />
        </div>
      );
    } else {
      return null;
    }
  }
}

export default Docusign;

Docusign.contextType = ModalViewContext;
