import React, { Component } from 'react';
import dompurify from 'dompurify';
import { NavLink } from 'react-router-dom';

import AssetDetailsPage from '../Common/AssetDetailsPage';
import TextButton from 'components/shared/Buttons/TextButton';
import { Details } from 'components/shared/Icons/Details';

import './AssetStory.css';

class AssetStory extends Component {
  scrollableContainerRef = React.createRef(null);

  // Prevent swipe/slide in 2.0 carousel component when scrolling.
  handleScroll = e => {
    if (!this.scrollableContainerRef.current) return;

    const { scrollTop, clientHeight, scrollHeight } = this.scrollableContainerRef.current;
    if (scrollHeight <= clientHeight) return; // ignore if container is not scrollable

    const isScrolledToTop = scrollTop === 0;
    const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight;

    if (e.wheelDelta || e.deltaY) {
      const isWheelScrollingToTop = e.wheelDelta > 0 || e.deltaY < 0;
      const isWheelScrollingToBottom = e.wheelDelta < 0 || e.deltaY > 0;
      if (isScrolledToTop && isWheelScrollingToBottom) return e.stopPropagation();
      if (isScrolledToBottom && isWheelScrollingToTop) return e.stopPropagation();
    }

    if (!isScrolledToTop && !isScrolledToBottom) e.stopPropagation();
  };

  render() {
    const { activeAsset: car } = this.props;
    return (
      <div id="AssetStory" className={`AssetStory ${this.props.applicationMode}`}>
        <AssetDetailsPage asset={car} className="AssetDetailsPage-assetStory" {...this.props}>
          <div className="AssetStory-ticker">{car.ticker}</div>
          <div className="AssetStory-assetname">{car.display_name}</div>
          <div
            className="AssetStory-description"
            ref={this.scrollableContainerRef}
            onWheel={this.handleScroll}
            onScroll={this.handleScroll}
            onTouchMove={this.handleScroll}
          >
            <p
              dangerouslySetInnerHTML={{
                __html: dompurify.sanitize(car.description, { ALLOWED_TAGS: [] }),
              }}
            ></p>
          </div>
          <NavLink to="/app/details">
            <TextButton icon={<Details />} arrow="forward" style={{ color: '#444' }}>
              Browse All Details
            </TextButton>
          </NavLink>
        </AssetDetailsPage>
      </div>
    );
  }
}

export default AssetStory;
