import React from 'react';

const Calendar = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" {...props}>
    <g transform="matrix(1.33333 0 0 -1.33333 0 96)">
      <path
        d="M60.005 48.374H12.278v5.58a3.738 3.738 0 0 0 3.735 3.734h3.943v-3.721h3.978v3.721H48.35v-3.721h3.976v3.721h3.945a3.738 3.738 0 0 0 3.734-3.734zm0-33.829a3.738 3.738 0 0 0-3.734-3.734H16.013a3.738 3.738 0 0 0-3.735 3.734v29.851h47.727zM56.27 61.666h-3.945v3.5H48.35v-3.5H23.934v3.5h-3.978v-3.5h-3.943A7.712 7.712 0 0 1 8.3 53.954V14.545a7.712 7.712 0 0 1 7.712-7.712H56.27a7.712 7.712 0 0 1 7.712 7.712v39.409a8.71 7.712 0 0 1-7.712 7.712"
        fill="#3ad88d"
      />
      <path
        d="M21.515 30.568a3.85 3.85 0 1 1 0 7.699 3.85 3.85 0 0 1 0-7.699M21.515 18.984a3.848 3.848 0 1 1 .001 7.697 3.848 3.848 0 0 1 0-7.697M36.142 30.568a3.849 3.849 0 1 1 0 7.698 3.849 3.849 0 0 1 0-7.698M36.142 18.984a3.85 3.85 0 1 1-.001 7.699 3.85 3.85 0 0 1 0-7.699M50.768 30.568a3.849 3.849 0 1 1 0 7.698 3.849 3.849 0 0 1 0-7.698M50.768 18.984a3.85 3.85 0 1 1-.001 7.699 3.85 3.85 0 0 1 0-7.699"
        fill="#3ad88d"
      />
    </g>
  </svg>
);

export default Calendar;
