import CONFIG from 'config.js';
import { getRequest } from './RequestHandler';

const config: any = CONFIG;

export const fetchUserData = () => {
  const userId = localStorage.getItem('user_id');
  const endpoint = config.scheme + config.apiUrl + `/api/users/${userId}`;

  return getRequest(endpoint);
};
