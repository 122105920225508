import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Popup from 'components/shared/Popup';
import SecondaryButton from 'components/shared/Buttons/SecondaryButton';

const DocumentUploadPopup = ({ history, show, onRequestClose }) => (
  <Popup show={show} disableAutoClose onRequestClose={onRequestClose}>
    <h2>Please Upload Photo ID</h2>
    <p>
      In order to verify your Rally account, you will need to upload another License or Government
      issued photo ID. Click Ok to upload ID.
    </p>
    <SecondaryButton text="Okay" onClick={() => history.push('/app/document')} />
  </Popup>
);

DocumentUploadPopup.propTypes = {
  show: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

DocumentUploadPopup.defaultProps = {
  show: false,
};

export default withRouter(DocumentUploadPopup);
