import { createActions } from 'redux-actions';
import * as AssetsAPI from 'services/AssetsAPI';
import * as StoreAPI from 'services/StoreAPI';
import * as HomeAPI from 'services/HomeAPI';
import { NetworkRequestError } from 'services/errors';
import { addAssetSubscription, removeAssetSubscription } from 'actions/user';
import { logoutUser } from 'actions/auth';
import { getInvestments } from 'actions/investments';
import { setActiveTradingWindow } from 'actions/trading';
import {
  showGlobalLoader,
  hideGlobalLoader,
  showAssetsLoadingOverlay,
  hideAssetsLoadingOverlay,
  notifyOnIoOpenedCategories,
  setTradingCalendarEnabled,
} from 'actions/ui';
import {
  SET_ASSETS,
  SET_ASSET_DETAILS,
  TOGGLE_ASSETS_DETAILS,
  SET_TRADING_DATES,
  SET_ACTIVE_ASSET,
  SET_ACTIVE_ASSET_CATEGORY,
  SET_ACTIVE_ASSET_PRICING,
  SET_ACTIVE_ASSET_HISTORY_PRICING,
  LOCK_ACTIVE_ASSET,
  UNLOCK_ACTIVE_ASSET,
  UPDATE_ASSETS_UPDATE_SCHEDULE,
  UPDATE_ACTIVEASSET_MERCHANDISING_OPTIONS,
  SET_ASSETS_SEARCH_VALUE,
  CLOSE_ASSETS_SEARCH,
  OPEN_ASSETS_SEARCH,
  SET_SEARCH_RESULT_LIST,
  SET_ASSET_SEARCH_SELECTED,
  SET_HOME_ASSETS,
  SET_CATEGORY_ASSETS,
} from 'actions/types';

import { isEmpty } from 'utils';
import { SUPPORTED_APPLICATION_MODES, ASSET_STATUS } from 'constants/main';
import { NETWORK_ERROR_TYPES } from 'constants/errors';
import Bugsnag from '@bugsnag/js';
import analytics from 'services/analytics';
import { SEGMENT_ACTIONS, SEGMENT_CATEGORIES, SEGMENT_EVENTS } from 'constants/analytics';

let assetsUpdateTimer;
let assetsUpdateFailureCount = 0;

// === Action creators: ===

export const {
  setAssets,
  setAssetDetails,
  updateActiveAssetMerchandiseProductData,
  toggleAssetsDetails,
  setTradingDates,
  setActiveAsset,
  setActiveAssetCategory,
  setActiveAssetPricing,
  setActiveAssetHistoryPricing,
  lockActiveAsset,
  unlockActiveAsset,
  updateAssetsUpdateSchedule,
  setAssetsSearchValue,
  openAssetsSearch,
  closeAssetsSearch,
  setSearchResultList,
  setAssetSearchSelected,
  setHomeAssets,
  setCategoryAssets,
} = createActions(
  SET_ASSETS,
  SET_ASSET_DETAILS,
  UPDATE_ACTIVEASSET_MERCHANDISING_OPTIONS,
  TOGGLE_ASSETS_DETAILS,
  SET_TRADING_DATES,
  SET_ACTIVE_ASSET,
  SET_ACTIVE_ASSET_CATEGORY,
  SET_ACTIVE_ASSET_PRICING,
  SET_ACTIVE_ASSET_HISTORY_PRICING,
  LOCK_ACTIVE_ASSET,
  UNLOCK_ACTIVE_ASSET,
  UPDATE_ASSETS_UPDATE_SCHEDULE,
  SET_ASSETS_SEARCH_VALUE,
  OPEN_ASSETS_SEARCH,
  CLOSE_ASSETS_SEARCH,
  SET_SEARCH_RESULT_LIST,
  SET_ASSET_SEARCH_SELECTED,
  SET_HOME_ASSETS,
  SET_CATEGORY_ASSETS,
);

// === Thunks: ===

/**
 * Loads basic assets data (required for asset preview "1.0" screen)
 * Uses locally cached data if caching time has not expired, otherwise fetches it from server
 * @param  {Object} options
 */
export const getAssets =
  (options = { showGlobalLoader: true, forceFullRefresh: false, tabChange: false }) =>
  async (dispatch, getState) => {
    if (options.showGlobalLoader) dispatch(showGlobalLoader());

    try {
      const applicationMode = getState().UI.applicationMode;
      const specialAccessAssetIdAccessTypeLimitationsMap =
        getState().Auth.user.getSpeciallyAccessibleAssetsIdAccessTypeAndLimitationsMap();
      dispatch(getAssetsUpdateSchedule());

      let { data } = await AssetsAPI.getAssets();
      const assets = data.items;
      dispatch(
        setAssets({
          applicationMode: applicationMode,
          assets,
          isAssetDetails: false,
          specialAccessAssetIdAccessTypeLimitationsMap:
            specialAccessAssetIdAccessTypeLimitationsMap,
        }),
      );

      const { activeAsset } = getState().Assets;
      if (options.forceFullRefresh && !isEmpty(activeAsset)) {
        const updatedActiveAsset = assets.find(asset => asset.id === activeAsset.id);
        dispatch(updateActiveTradingWindow(updatedActiveAsset));
        dispatch(
          getAssetDetails(activeAsset.id, {
            showLoadingOverlay: false,
            tabChange: options.tabChange,
          }),
        );
      }

      if (options.showGlobalLoader) dispatch(hideGlobalLoader());

      // TODO: reevaluate whether or not this notification should be displayed for special access mode as the feature evolves
      if (applicationMode !== SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS) {
        dispatch(notifyOnIoOpenedCategories());
      }

      // get investments/orders details
      dispatch(getInvestments());
    } catch (err) {
      console.error(`${JSON.stringify(err)}`);

      if (err instanceof NetworkRequestError) {
        if (err.message) throw new Error(err.message);
        if (err.type !== NETWORK_ERROR_TYPES.UNKNOWN_OFFLINE) {
          return dispatch(getAssets({ showGlobalLoader: true, forceFullRefresh: true }));
        }
      } else {
        throw new Error(err.message);
      }
    }
  };

export const getSecurityPricing = financialInstrumentId => async dispatch => {
  try {
    let resp = await AssetsAPI.getSecurityPricing(financialInstrumentId);
    dispatch(setActiveAssetPricing({ ...resp.data, financialInstrumentId }));
  } catch (err) {
    Bugsnag.notify(err);
  }
};

export const getSecurityHistoryPricing = financialInstrumentId => async dispatch => {
  try {
    let dataPoints = await AssetsAPI.getSecurityHistoryPricing(financialInstrumentId).then(
      res => res?.data ?? {},
    );

    if (!dataPoints?.dataPoints?.length) {
      dispatch(setActiveAssetHistoryPricing({ dataPoints: {}, financialInstrumentId }));
    } else {
      dispatch(
        setActiveAssetHistoryPricing({
          dataPoints,
          financialInstrumentId,
        }),
      );
    }
  } catch (err) {
    Bugsnag.notify(err);
  }
};

export const getAssetsUpdateSchedule =
  (scheduleData = null) =>
  async (dispatch, getState) => {
    try {
      const updateSchedule = scheduleData
        ? scheduleData
        : await AssetsAPI.getAssetsUpdatesSchedule();
      assetsUpdateFailureCount = 0;

      if (isEmpty(updateSchedule)) return;
      dispatch(updateAssetsUpdateSchedule(updateSchedule));

      const nowTimestamp = Math.round(new Date().getTime() / 1000);
      const futureTimestampTarget =
        updateSchedule.next_update_timestamp + updateSchedule.update_seconds_interval / 2;

      const timestampDiff = futureTimestampTarget - nowTimestamp;
      clearTimeout(assetsUpdateTimer);

      assetsUpdateTimer = setTimeout(async () => {
        dispatch(getAssetsUpdates());

        const { id } = getState()?.Assets?.activeAsset?.financialInstrument || {};
        if (id) dispatch(setActiveTradingWindow(id));
      }, timestampDiff * 1000);
    } catch (err) {
      console.error(`${JSON.stringify(err)}`);

      if (err instanceof NetworkRequestError) {
        if (err.type === NETWORK_ERROR_TYPES.CLIENT_UNAUTHORIZED) return;
        if (err.message) throw new Error(err.message);
        if (err.type !== NETWORK_ERROR_TYPES.UNKNOWN_OFFLINE) {
          if (assetsUpdateFailureCount <= 10) {
            assetsUpdateFailureCount++;
            return dispatch(getAssetsUpdateSchedule());
          } else {
            return dispatch(logoutUser());
          }
        }
      } else {
        throw new Error(err.message);
      }
    }
  };

export const getAssetsUpdates = () => async (dispatch, getState) => {
  try {
    const { assetsUpdateScheduleNextTimestamp } = getState().Assets;
    if (!assetsUpdateScheduleNextTimestamp) return;

    const assetsUpdatesData = await AssetsAPI.getAssetsUpdates(assetsUpdateScheduleNextTimestamp);
    const updatedAssets = assetsUpdatesData.items;
    const updatedSchedule = {
      next_update_timestamp: assetsUpdatesData.next_update_timestamp,
      update_seconds_interval: assetsUpdatesData.update_seconds_interval,
    };

    dispatch(getAssetsUpdateSchedule(updatedSchedule));

    if (!updatedAssets || !updatedAssets.length) return;

    const { assetList, activeAsset } = getState().Assets;
    const assets = assetList.map(asset => {
      const updates = updatedAssets.find(updatedAsset => updatedAsset.id === asset.id);
      return updates ? { ...asset, ...updates } : asset;
    });
    const applicationMode = getState().UI.applicationMode;
    const specialAccessAssetIdAccessTypeLimitationsMap =
      getState().Auth.user.getSpeciallyAccessibleAssetsIdAccessTypeAndLimitationsMap();

    dispatch(
      setAssets({
        applicationMode: applicationMode,
        assets: assets,
        isAssetDetails: false,
        specialAccessAssetIdAccessTypeLimitationsMap: specialAccessAssetIdAccessTypeLimitationsMap,
      }),
    );

    const activeAssetUpdates = updatedAssets.find(asset => asset.id === activeAsset.id);
    if (activeAssetUpdates) {
      // Update active trading window if it's data has been changed:
      dispatch(updateActiveTradingWindow(activeAssetUpdates));

      await dispatch(getAssetDetails(activeAsset.id, { showLoadingOverlay: false }));
    }
  } catch (err) {
    console.error(`${JSON.stringify(err)}`);

    if (err instanceof NetworkRequestError) {
      if (err.type === NETWORK_ERROR_TYPES.CLIENT_UNAUTHORIZED) return;
      if (err.message) throw new Error(err.message);
      if (err.type !== NETWORK_ERROR_TYPES.UNKNOWN_OFFLINE) {
        if (assetsUpdateFailureCount <= 10) {
          assetsUpdateFailureCount++;
          return dispatch(getAssetsUpdateSchedule());
        } else {
          return dispatch(logoutUser());
        }
      }
    } else {
      throw new Error(err.message);
    }
  }
};

export const unsetAssetsUpdateTimer = () => dispatch => {
  clearTimeout(assetsUpdateTimer);
};

export const updateActiveTradingWindow = asset => async dispatch => {
  // Update active trading window if it's data has been changed:
  if (
    (asset?.asset_status === ASSET_STATUS.TRADING_OPENED ||
      asset?.asset_status === ASSET_STATUS.TRADING_CLOSED) &&
    asset?.financialInstrument?.id
  ) {
    dispatch(setActiveTradingWindow(asset.financialInstrument.id, false));
  }
};

/**
 * Fetches asset details data (required for details "2.0" screen).
 * Dispatches an update of assets list with extended data, injects updates to local cache
 * @param  {String} assetId
 * @param  {Object} options
 */
export const getAssetDetails =
  (assetId, options = { showLoadingOverlay: true, tabChange: false }) =>
  async (dispatch, getState) => {
    const { assetList } = getState().Assets;
    if (options.showLoadingOverlay) dispatch(showAssetsLoadingOverlay());

    try {
      const currentId = assetId || getState().Assets.activeAsset.id;
      const updatedAsset = await AssetsAPI.getAssetDetails(currentId);
      const assets = assetList.map(asset => (asset.id === updatedAsset.id ? updatedAsset : asset));
      const applicationMode = getState().UI.applicationMode;
      const specialAccessAssetIdAccessTypeLimitationsMap =
        getState().Auth.user.getSpeciallyAccessibleAssetsIdAccessTypeAndLimitationsMap();

      dispatch(
        setAssets({
          applicationMode: applicationMode,
          assets: assets,
          isAssetDetails: true,
          specialAccessAssetIdAccessTypeLimitationsMap:
            specialAccessAssetIdAccessTypeLimitationsMap,
        }),
      );
      dispatch(setAssetDetails({ ...updatedAsset, tabChange: options.tabChange }));

      const { id } = getState()?.Assets?.activeAsset?.financialInstrument || {};
      if (id) dispatch(setActiveTradingWindow(id));

      if (updatedAsset.merchandising) {
        dispatch(getMerchandiseProductDataAndUpdate(updatedAsset.merchandising));
      }
    } catch (err) {
      const activeAsset = getState().Assets.activeAsset;
      Bugsnag.notify(err, event => {
        event.addMetadata('Active Asset', {
          activeAsset,
        });
      });
    } finally {
      if (options.showLoadingOverlay) dispatch(hideAssetsLoadingOverlay());
    }
  };

export const getMerchandiseProductDataAndUpdate = merchandisingObject => async dispatch => {
  try {
    if (merchandisingObject.enabled) {
      delete merchandisingObject.enabled;

      const allMerchantsProducts = await merchandisingObject.merchants.reduce(
        async (allMerchantsData, merchant) => {
          const productIds = merchant.products
            .map(product => {
              return product.id;
            })
            .join('&id=');

          const allProductsData =
            productIds !== '' ? await StoreAPI.getMerchandiseProductData(productIds) : [];
          if (allProductsData && !isEmpty(allProductsData))
            allMerchantsData.push({ ...merchant, products: allProductsData });

          return allMerchantsData;
        },
        [],
      );

      allMerchantsProducts && !isEmpty(allMerchantsProducts)
        ? dispatch({
            type: UPDATE_ACTIVEASSET_MERCHANDISING_OPTIONS,
            payload: allMerchantsProducts,
          })
        : dispatch({ type: UPDATE_ACTIVEASSET_MERCHANDISING_OPTIONS, payload: [] });
    }
  } catch (error) {
    console.log(error);
  }
};

export const checkMerchandiseProductsAvailability = () => async (dispatch, getState) => {
  try {
    const { activeAssetMerchandisingOptions } = getState().Assets;
    if (!isEmpty(activeAssetMerchandisingOptions)) {
      const allMerchantsProducts = await activeAssetMerchandisingOptions.reduce(
        async (allMerchantsData, merchant) => {
          //create a product availablility map by inventory tracking types
          const productAvailabilityMap = merchant.products.reduce(
            (productObjects, product) => {
              if (product.inventory.enabled) {
                return {
                  ...productObjects,
                  productsWithInventoryTrackingEnabled: [
                    ...productObjects.productsWithInventoryTrackingEnabled,
                    product,
                  ],
                  productsIdsWithInventoryTrackingEnabled:
                    productObjects.productsIdsWithInventoryTrackingEnabled === ''
                      ? product.id
                      : productObjects.productsIdsWithInventoryTrackingEnabled.concat(
                          '&id=',
                          product.id,
                        ),
                };
              } else {
                return {
                  ...productObjects,
                  productsWithoutInventory: [...productObjects.productsWithoutInventory, product],
                };
              }
            },
            {
              productsWithInventoryTrackingEnabled: [],
              productsIdsWithInventoryTrackingEnabled: '',
              productsWithoutInventory: [],
            },
          );

          // use product ids of those track inventory in the availability map for updating product data
          const updatedProductsDataWithInventoryTrackingEnabled =
            productAvailabilityMap.productsIdsWithInventoryTrackingEnabled !== ''
              ? await StoreAPI.getMerchandiseProductData(
                  productAvailabilityMap.productsIdsWithInventoryTrackingEnabled,
                )
              : [];

          // merge the arrays of objects of two different product types (whether inventory is tracked/or not)
          const allProductsData = [
            ...productAvailabilityMap.productsWithoutInventory,
            ...updatedProductsDataWithInventoryTrackingEnabled,
          ];
          if (allProductsData && !isEmpty(allProductsData))
            allMerchantsData.push({ ...merchant, products: allProductsData });

          return allMerchantsData;
        },
        [],
      );

      allMerchantsProducts && !isEmpty(allMerchantsProducts)
        ? dispatch({
            type: UPDATE_ACTIVEASSET_MERCHANDISING_OPTIONS,
            payload: allMerchantsProducts,
          })
        : dispatch({ type: UPDATE_ACTIVEASSET_MERCHANDISING_OPTIONS, payload: [] });
    }
  } catch (error) {
    /* if there was any error while refetching up-to-date product availability,
     * clear out the reducer object to prevent merchandise checkout component loaded for user as we can't guarantee availability */
    dispatch({ type: UPDATE_ACTIVEASSET_MERCHANDISING_OPTIONS, payload: [] });
    return error;
  }
};

export const removeActiveAssetMerchandiseProductData = () => async dispatch => {
  try {
    dispatch({ type: UPDATE_ACTIVEASSET_MERCHANDISING_OPTIONS, payload: [] });
  } catch (error) {
    console.log(error);
  }
};

export const getTradingDates = () => dispatch => {
  return AssetsAPI.getTradingDates().then(tradingDates => dispatch(setTradingDates(tradingDates)));
};

export const subscribeUserOnAsset = assetInfo => async dispatch => {
  const { asset_status: status } = assetInfo;
  const type = status === 'Closed ICO' || status === 'Trading closed' ? 'trading' : 'ipo';
  try {
    dispatch(showAssetsLoadingOverlay());
    const subscription = await AssetsAPI.subscribeUserOnAsset(assetInfo);
    dispatch(addAssetSubscription({ ...subscription, type }));
  } catch (err) {
    Bugsnag.notify(err);
  } finally {
    dispatch(hideAssetsLoadingOverlay());
  }
};

export const unsubscribeUserFromAsset = assetInfo => async dispatch => {
  const { asset_status: status, subscription } = assetInfo;
  const type = status === 'Closed ICO' || status === 'Trading closed' ? 'trading' : 'ipo';
  try {
    dispatch(showAssetsLoadingOverlay());
    await AssetsAPI.unsubscribeUserFromAsset(assetInfo);
    dispatch(removeAssetSubscription({ ...subscription, type }));
  } catch (err) {
    console.error(`${JSON.stringify(err)}`);
    throw new Error(err);
  } finally {
    dispatch(hideAssetsLoadingOverlay());
  }
};

export const closeAssetSearchAndClearList = () => dispatch => {
  dispatch(setAssetsSearchValue(''));
  dispatch(closeAssetsSearch());
  dispatch(setAssetSearchSelected(false));
  dispatch(setSearchResultList([]));
};

export const getAssetSegmentStatus = ({ asset_status, id, trading }, user) => {
  const ebAssets = user.getSpeciallyAccessibleAssetsIdAccessTypeAndLimitationsMap
    ? user.getSpeciallyAccessibleAssetsIdAccessTypeAndLimitationsMap()
    : new Map();

  switch (asset_status) {
    case ASSET_STATUS.COMING_SOON:
      if (ebAssets?.get(id)?.accessType === 'early_io') {
        return 'EARLY ACCESS';
      }
      return 'COMING SOON';
    case ASSET_STATUS.IO_OPENED:
      return 'OPEN OFFERING';
    case ASSET_STATUS.IO_CLOSED:
      return 'CLOSED OFFERING';
    case ASSET_STATUS.TRADING_CLOSED:
      return 'TRADING CLOSED';
    case ASSET_STATUS.TRADING_OPENED:
      const tradingWindow = trading && trading.current_trading_window;
      if (tradingWindow && tradingWindow.status !== 'OPEN') {
        return 'IN REVIEW';
      }
      return 'TRADING NOW';
    case ASSET_STATUS.POST_ONLY:
      return 'ACCEPTING ORDERS';
    default:
      return 'EXITED';
  }
};

export const setActiveAssetById = (id, listPosition, assetsSearchValue) => (dispatch, getState) => {
  const { assetList, activeAsset } = getState().Assets;
  const { user } = getState().Auth;
  const assetToBeMadeActive = assetList.find(asset => asset.id === id);
  analytics.track(SEGMENT_EVENTS.SEARCH_RESULTS_SELECTED, {
    action: SEGMENT_ACTIONS.OPEN,
    category: SEGMENT_CATEGORIES.SEARCH,
    assetTicker: assetToBeMadeActive.ticker,
    assetName: assetToBeMadeActive.display_name,
    label: assetsSearchValue,
    assetState: getAssetSegmentStatus(assetToBeMadeActive, user),
    searchPosition: listPosition + 1,
  });
  if (activeAsset.id !== assetToBeMadeActive.id) {
    dispatch(setActiveTradingWindow(assetToBeMadeActive.financialInstrument.id));
    dispatch(setActiveAsset(assetToBeMadeActive));
  }
};

export const getHomeAssets = () => async dispatch => {
  try {
    let resp = await HomeAPI.getHomeData();
    dispatch(setHomeAssets(resp.data));
    dispatch(setTradingCalendarEnabled(resp.data.features.calendar.enabled));
  } catch (err) {
    Bugsnag.notify(err);
  }
};
