import { handleActions } from 'redux-actions';
import moment from 'moment';

import { DWOLLA_CUSTOMER_STATUS } from 'constants/main';
import {
  SET_USER_DATA,
  REMOVE_ASSETS_FROM_ACCESS_GROUPS,
  ADD_STATE_SUBSCRIPTION,
  ADD_ASSET_SUBSCRIPTION,
  REMOVE_ASSET_SUBSCRIPTION,
  SET_DWOLLA_CUSTOMER_STATUS,
  SET_DWOLLA_CUSTOMER_ERROR,
  SET_USER_STATE_STATUS,
  USER_INFO_UPDATE_INITIATED,
  USER_INFO_UPDATE_COMPLETED,
  SET_INVESTOR_DETAILS,
  SET_PERSONAL_INFORMATION,
  SET_CUSTOMER_DATA,
  SET_EMPLOYMENT_DETAILS,
} from 'actions/types';
import { isEmpty } from 'utils';

const defaultState = {
  user: {
    customer: {
      accounts: [
        {
          id: 1,
          status: 'APPROVED',
        },
      ],
      id: '',
      status: '',
      investorDetails: {
        riskToleranceLevel: '',
      },
    },
    access_groups: [],
    state_status: {
      is_state_available: undefined,
      io_enabled: undefined,
      trading_enabled: undefined,
      // computable - assume that state is completely blocked if no trading and IO allowed:
      is_state_blocked: undefined,
    },
  },
  isAuth: false,
  subscriptions: {
    tradingSubscriptions: [],
    ipoSubscriptions: [],
  },
  dwollaCustomerStatus: '',
  // indicates that user info is currently being updated via http requests:
  updatingUserData: false,
};

const reducerMap = {};

reducerMap[SET_USER_DATA] = (state, { payload }) => {
  const assetIdAccessTypeAndLimitationsMap = new Map();

  for (const accessGroup of payload.access_groups || []) {
    if (isEmpty(accessGroup.assets)) continue;

    for (const specialAccessAsset of accessGroup.assets) {
      assetIdAccessTypeAndLimitationsMap.set(specialAccessAsset.asset_id, {
        accessEnds: moment(specialAccessAsset.access_end),
        accessType: specialAccessAsset.access_type,
        limitations: specialAccessAsset.limitations,
      });
    }
  }

  let accessGroupsObj = {};

  if (!isEmpty(payload?.access_groups)) {
    [...payload.access_groups].forEach(group => {
      group.assets.forEach(asset => {
        accessGroupsObj[asset.access_type] = [...(accessGroupsObj[asset.access_type] || []), asset];
      });
    });
  } else {
    accessGroupsObj = state.user.access_groups;
  }

  return {
    ...state,
    user: {
      ...state.user,
      ...payload,
      date_of_birth: payload.date_of_birth
        ? moment(payload.date_of_birth).format('MM/DD/YYYY') // <- to US format
        : null,
      access_groups: accessGroupsObj,
      /**
       * retrieves a mapping of assets ids to their (special, access group specific) access type as provided within the
       *  user's access groups. please note that this list does not specify those assets that the user is limited to in
       *  any way; these are specifically those assets that the user has a special type of access to.
       *
       *  @returns Map(string => string)
       */
      getSpeciallyAccessibleAssetsIdAccessTypeAndLimitationsMap: (
        options = { accessTypes: [], assetIds: [] },
      ) => {
        let saAssetsArr = [];
        let targets = new Map(assetIdAccessTypeAndLimitationsMap);

        Array.from(targets).forEach(item => {
          if (item[1].accessType === 'free_share_program') saAssetsArr.unshift(item);
          else saAssetsArr.push(item);
        });
        targets = new Map(saAssetsArr);

        if (
          (options.accessTypes && options.accessTypes.length) ||
          (options.assetIds && options.assetIds.length)
        ) {
          const accessTypes = options.accessTypes || [];
          const assetIds = options.assetIds || [];

          targets.forEach((target, assetId) => {
            if (
              (accessTypes.length && !accessTypes.includes(target.accessType)) ||
              (assetIds.length && !assetIds.includes(assetId))
            )
              targets.delete(assetId);
          });
        }

        return targets;
      },
      state_status: isEmpty(payload.state_availability)
        ? state.user.state_status
        : {
            ...payload.state_availability,
            is_state_blocked:
              !payload.state_availability.trading_enabled && !payload.state_availability.io_enabled,
          },
    },
    isAuth: true,
    subscriptions: {
      tradingSubscriptions: [
        ...state.subscriptions.tradingSubscriptions,
        ...(payload.on_trading_notification || []),
      ],
      ipoSubscriptions: [...state.subscriptions.ipoSubscriptions, ...(payload.notify_me || [])],
    },
    dwollaCustomerStatus: payload.dwolla_customer_status || '',
    passwordChangedSucceeded: false,
  };
};

reducerMap[REMOVE_ASSETS_FROM_ACCESS_GROUPS] = (state, action) => {
  return {
    ...state,
    user: {
      ...state.user,
      access_groups: action.payload,
    },
  };
};

reducerMap[ADD_STATE_SUBSCRIPTION] = (state, action) => {
  const oldNotifications = state.user.state_availability_notification;
  let newNotifications = [{ blocked_state: action.payload }];

  if (oldNotifications) {
    newNotifications = [...oldNotifications, { blocked_state: action.payload }];
  }

  return {
    ...state,
    user: {
      ...state.user,
      state_availability_notification: newNotifications,
    },
  };
};

reducerMap[ADD_ASSET_SUBSCRIPTION] = (state, action) => {
  let tradingSubscriptions = state.subscriptions.tradingSubscriptions;
  let ipoSubscriptions = state.subscriptions.ipoSubscriptions;

  if (action.payload.type === 'trading') {
    tradingSubscriptions = tradingSubscriptions.concat([action.payload]);
  }
  if (action.payload.type === 'ipo') {
    ipoSubscriptions = ipoSubscriptions.concat([action.payload]);
  }

  return { ...state, subscriptions: { tradingSubscriptions, ipoSubscriptions } };
};

reducerMap[REMOVE_ASSET_SUBSCRIPTION] = (state, action) => {
  let tradingSubscriptions = state.subscriptions.tradingSubscriptions;
  let ipoSubscriptions = state.subscriptions.ipoSubscriptions;

  if (action.payload.type === 'trading') {
    tradingSubscriptions = tradingSubscriptions.filter(item => action.payload.id !== item.id);
  }
  if (action.payload.type === 'ipo') {
    ipoSubscriptions = ipoSubscriptions.filter(item => action.payload.id !== item.id);
  }

  return { ...state, subscriptions: { tradingSubscriptions, ipoSubscriptions } };
};

reducerMap[SET_DWOLLA_CUSTOMER_STATUS] = (state, action) => ({
  ...state,
  user: {
    ...state.user,
    has_dwolla_customer: action.payload.status === DWOLLA_CUSTOMER_STATUS.VERIFIED,
  },
  dwollaCustomerStatus: action.payload.status,
});

reducerMap[SET_DWOLLA_CUSTOMER_ERROR] = (state, action) => ({
  ...state,
  user: {
    ...state.user,
    has_dwolla_customer: false,
  },
  dwollaCustomerStatus: 'failure',
});

reducerMap[SET_USER_STATE_STATUS] = (state, action) => ({
  ...state,
  user: {
    ...state.user,
    state_status: {
      ...action.payload,
      is_state_blocked: !action.payload.trading_enabled && !action.payload.io_enabled,
    },
  },
});

reducerMap[USER_INFO_UPDATE_INITIATED] = state => ({
  ...state,
  updatingUserData: true,
});

reducerMap[USER_INFO_UPDATE_COMPLETED] = state => ({
  ...state,
  updatingUserData: false,
});

reducerMap[SET_INVESTOR_DETAILS] = (state, action) => ({
  ...state,
  user: {
    ...state.user,
    customer: {
      ...state.user.customer,
      investorDetails: {
        ...state.user.customer.investorDetails,
        ...action.payload,
      },
    },
  },
});

reducerMap[SET_PERSONAL_INFORMATION] = (state, action) => ({
  ...state,
  user: {
    ...state.user,
    customer: {
      ...state.user.customer,
      personalInformation: {
        ...state.user.customer.personalInformation,
        ...action.payload,
      },
    },
  },
});

reducerMap[SET_EMPLOYMENT_DETAILS] = (state, action) => ({
  ...state,
  user: {
    ...state.user,
    customer: {
      ...state.user.customer,
      employmentDetails: {
        ...state.user.customer.employementDetails,
        ...action.payload,
      },
    },
  },
});

reducerMap[SET_CUSTOMER_DATA] = (state, action) => ({
  ...state,
  user: {
    ...state.user,
    customer: {
      ...state.user.customer,
      ...action.payload,
    },
  },
});

const user = handleActions(reducerMap, defaultState);

export default user;
