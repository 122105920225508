import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import * as uuid from 'uuid';

import { Price } from 'components/shared/Price';
import { Cross } from 'components/shared/Icons/Cross';
import { TroughButtons } from 'components/views/app/Assets/Trading/Common/TroughButtons';
import { TextButton } from 'components/shared/Buttons/TextButton';
import NumberInput from 'components/shared/Inputs/NumberInput';
import { ASSET_STATUS } from 'constants/main';

import './SelectBidAmount.scss';
import BuyOrderTypeTabs from '../BuyOrderTypeTabs';
import { OrderType, OrderContext, OrderNewEditType } from 'types/orders';
import withAssetPrevalidation from 'hoc/withAssetPrevalidation';
import { ModalViewContext } from 'components/shared/Modals/Modal';
import AlertModal from 'components/shared/Modals/AlertModal';
import { PrevalidateErrorCode } from 'types/servicesResponse';
import { DotsLoader } from 'components/shared/Loaders';
import { SecondaryButtonV2 } from 'components/shared/Buttons';
import { SecondaryMarketStatus } from 'types/assets';
import { connect } from 'react-redux';
import { getActiveAssetDetails } from 'utils/trading';

import analytics from 'services/analytics';
import { SEGMENT_ACTIONS, SEGMENT_CATEGORIES, SEGMENT_EVENTS } from 'constants/analytics';

const roundToFiveCents = value => {
  const newValue = Math.ceil(parseFloat(value) * 20) / 20;
  return parseFloat(value) === parseFloat(newValue) ? value : newValue.toFixed(2);
};

class SelectBidAmount extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    onFormUpdate: PropTypes.func.isRequired,
    onStepForward: PropTypes.func.isRequired,
    onStepBackward: PropTypes.func.isRequired,
  };

  state = {
    isStepValid: true,
    loading: false,
    showInsufficientFundsError: false,
    showNotEnoughSharesError: false,
    showInalienableUnitsError: { show: false, unitsAvailable: undefined },
    showPriceBreakerError: false,
    showCrossTradingError: false,
    maxShares: this.props.data.asset.number_of_shares,
  };

  componentDidMount() {
    const { activeAsset } = this.props;
    const {
      asset: { trading, asset_value, number_of_shares },
      shares,
      pricePerShare,
      instantBuyDisabled,
      side,
      originalOrderData,
    } = this.props.data;
    // set initial number of shares:
    if (!shares) this.onSharesChange(1);
    // set initial price per share:
    const { pricing } = trading || {};
    let initialPrice;
    if (pricing?.pricePerUnit?.session?.lastClose) {
      initialPrice = pricing.pricePerUnit.session.lastClose;
    }
    if (pricing?.pricePerUnit?.current) {
      initialPrice = pricing?.pricePerUnit?.current;
    }
    if (!initialPrice) {
      initialPrice = asset_value / number_of_shares;
    }
    if (!pricePerShare) {
      const price = initialPrice;
      this.onPricePerShareChange(price);
    }

    if (instantBuyDisabled) {
      this.props.onFormUpdate({ buySellOrderType: OrderType.LIMIT });
    }
    if (side === OrderContext.ASK) {
      const { positions } = this.props;
      const financialId = activeAsset.financialInstrument.id;
      const availableUnits = positions[financialId]?.units?.openAndAlienable ?? 0;

      const maxShares = originalOrderData
        ? availableUnits + originalOrderData.units.unfilled
        : availableUnits;
      this.setState({
        maxShares,
      });
    }

    this.fireOrderEntryEvent();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data.cash !== this.props.data.cash) {
      this.updateCost();
    }
  }

  fireOrderEntryEvent = async () => {
    const { side, originalOrderData } = this.props.data;
    const assetDetails = await this.props.getActiveAssetDetails();

    analytics.track(SEGMENT_EVENTS.ORDER_ENTRY_VIEW, {
      action: SEGMENT_ACTIONS.OPEN,
      category: SEGMENT_CATEGORIES.TRADING,
      assetName: assetDetails.assetName,
      assetState: assetDetails.assetStatus,
      assetCategory: assetDetails.assetCategory,
      assetTicker: assetDetails.assetTicker,
      currentValue: assetDetails.currentTotalValue,
      ioValue: assetDetails.ioPrice,
      sinceIO: assetDetails.gainLoss?.allTime,
      currentSharePrice: assetDetails.currentSharePrice,
      lastCloseSharePrice: assetDetails.lastCloseSharePrice,
      side: side === OrderContext.ASK ? 'SELL' : 'BUY',
      lowestAsk: assetDetails.askLow,
      orderType: originalOrderData ? OrderNewEditType.EDIT : OrderNewEditType.NEW,
    });
  };

  resetValidations = () => {
    this.setState({
      isStepValid: true,
      showInsufficientFundsError: false,
      showInalienableUnitsError: { show: false, unitsAvailable: undefined },
      showNotEnoughSharesError: false,
      showPriceBreakerError: false,
    });
  };

  onSharesChange = shares => {
    const { isStepValid } = this.state;
    if (!isStepValid) this.resetValidations();

    this.props.onFormUpdate({ shares: Number(shares) }, this.updateCost);
  };

  onPricePerShareChange = pricePerShare => {
    const { isStepValid } = this.state;
    if (!isStepValid) this.resetValidations();

    pricePerShare = isNaN(pricePerShare) ? '' : pricePerShare;
    this.props.onFormUpdate(
      {
        pricePerShare,
      },
      this.updateCost,
    );
  };

  updateCost = () => {
    const {
      data: { shares, pricePerShare },
    } = this.props;

    this.props.onFormUpdate({
      cost: shares * (pricePerShare || 0),
    });
  };

  handleTabClick = async buySellOrderType => {
    const { side, isEditing, shares, pricePerShare } = this.props.data;
    const assetDetails = await this.props.getActiveAssetDetails();

    const eventName =
      buySellOrderType === OrderType.INSTANT
        ? SEGMENT_EVENTS.INSTANT_BUY_TOGGLE_TAPPED
        : SEGMENT_EVENTS.LIMIT_BUY_TOGGLE_TAPPED;

    analytics.track(eventName, {
      action: SEGMENT_ACTIONS.TAPPED,
      category: SEGMENT_CATEGORIES.TRADING,
      assetName: assetDetails.assetName,
      assetState: assetDetails.assetStatus,
      assetCategory: assetDetails.assetCategory,
      assetTicker: assetDetails.assetTicker,
      currentValue: assetDetails.currentTotalValue,
      ioValue: assetDetails.ioPrice,
      sinceIO: assetDetails.gainLoss?.allTime,
      currentSharePrice: assetDetails.currentSharePrice,
      lastCloseSharePrice: assetDetails.lastCloseSharePrice,
      side: side === OrderContext.ASK ? 'SELL' : 'BUY',
      orderType: isEditing ? OrderNewEditType.EDIT : OrderNewEditType.NEW,
      flow: buySellOrderType,
      shareQuantity: shares,
      sharePrice: Number(pricePerShare),
      lowestAsk: assetDetails.askLow,
    });

    this.props.onFormUpdate({ buySellOrderType });
  };

  fireReviewOrderTapEvent = async () => {
    const { side, isEditing, buySellOrderType, shares, pricePerShare } = this.props.data;
    const assetDetails = await this.props.getActiveAssetDetails();

    analytics.track(SEGMENT_EVENTS.REVIEW_ORDER_TAPPED, {
      action: SEGMENT_ACTIONS.TAPPED,
      category: SEGMENT_CATEGORIES.TRADING,
      assetName: assetDetails.assetName,
      assetState: assetDetails.assetStatus,
      assetCategory: assetDetails.assetCategory,
      assetTicker: assetDetails.assetTicker,
      currentValue: assetDetails.currentTotalValue,
      ioValue: assetDetails.ioPrice,
      sinceIO: assetDetails.gainLoss?.allTime,
      currentSharePrice: assetDetails.currentSharePrice,
      lastCloseSharePrice: assetDetails.lastCloseSharePrice,
      side: side === OrderContext.ASK ? 'SELL' : 'BUY',
      orderType: isEditing ? OrderNewEditType.EDIT : OrderNewEditType.NEW,
      flow: buySellOrderType,
      shareQuantity: shares,
      sharePrice: Number(pricePerShare),
      lowestAsk: assetDetails.askLow,
    });
  };

  handleNextStep = async () => {
    this.setState({
      loading: true,
      isStepValid: true,
      showCrossTradingError: false,
      showPriceBreakerError: false,
      showNotEnoughSharesError: false,
      showInsufficientFundsError: false,
      showInalienableUnitsError: { show: false, unitsAvailable: undefined },
    });

    this.fireReviewOrderTapEvent();

    try {
      const { responseData } = await this.props.prevalidateOrder();
      const { activeAsset, activeAssetCategory } = this.props;

      if (responseData.valid) {
        this.props.onStepForward();
        return;
      }
      switch (responseData.code) {
        case PrevalidateErrorCode.UNIT_PRICE_OUT_OF_BOUNDS:
          this.setState({ showPriceBreakerError: true, isStepValid: false });
          break;
        case PrevalidateErrorCode.INSUFFICIENT_FUNDS:
          this.setState({ showInsufficientFundsError: true, isStepValid: false });
          break;
        case PrevalidateErrorCode.INSUFFICIENT_UNITS:
          this.setState({ showNotEnoughSharesError: true, isStepValid: false });
          break;
        case PrevalidateErrorCode.INALIENABLE_UNITS:
          this.setState({
            showInalienableUnitsError: {
              show: true,
              unitsAvailable: responseData.context?.unitsAvailable,
            },
            isStepValid: false,
          });
          break;
        case PrevalidateErrorCode.CROSS_TRADING_PROHIBITED:
          this.setState({ showCrossTradingError: true, isStepValid: false });
          break;
        default:
          this.context.setModalViewRenderSequence([
            {
              state: true,
              id: uuid.v4(),
              modalStyle: {
                overlayBgColor: 'rgba(0,0,0, .7)',
              },
              onCloseCompletion: () => {
                this.props.history.push(
                  `/app/assets/${activeAssetCategory.pathname}/${activeAsset.ticker.slice(1)}`,
                );
              },
              children: <AlertModal viewId={uuid.v4()} />,
              childrenProps: {
                title: 'Unable to Place Order',
                message:
                  'There was an issue processing your order request. Please try again. If issues persist, please contact us at support@rallyrd.com',
              },
            },
          ]);
          return;
      }
    } catch (err) {
      const { setModalViewRenderSequence } = this.context;
      setModalViewRenderSequence([
        {
          state: true,
          id: uuid.v4(),
          modalStyle: {
            overlayBgColor: 'rgba(0,0,0, .7)',
          },
          children: <AlertModal viewId={uuid.v4()} />,
          childrenProps: {
            title: 'Oops!',
            message: 'Something went wrong. Please try again.',
          },
        },
      ]);
    } finally {
      this.setState({ loading: false });
    }
  };

  renderPriceBreakerError = () => (
    <div className="SelectBidAmount-error">
      Your Ask cannot be more than 30% lower than Last Traded Price. Please adjust your ask
      accordingly.
    </div>
  );

  renderInsufficientFundsError = () => (
    <div className="SelectBidAmount-error">
      You do not have enough of a cash balance to cover the estimated cost. Please adjust your bid
      accordingly. You may also{' '}
      <Link to="/app/investments/deposit-withdrawal">FUND YOUR ACCOUNT</Link> to cover your future
      orders.
    </div>
  );

  renderInalienableUnitsError = () => {
    const { activeAsset, positions } = this.props;
    const { id } = activeAsset.financialInstrument;
    const unitsAvailable = positions[id]?.units?.openAndAlienable ?? 0;
    const isValidShareCount = Number.isInteger(unitsAvailable) && unitsAvailable > 0;
    const initialText = isValidShareCount
      ? `You only have ${unitsAvailable} shares available to sell.`
      : 'You do not currently have enough shares available to sell.';
    return (
      <div className="SelectBidAmount-error">
        {initialText} Please check your open orders as shares may already be allocated to an
        existing order. Also, recently acquired shares must be held for 5 business days before
        attempting to re-sell.
      </div>
    );
  };

  renderCrossTradingError = () => (
    <div className="SelectBidAmount-error">
      Currently, we limit investors from having concurrent active BIDs and ASKs for a given asset.
    </div>
  );

  renderNotEnoughSharesError = () => {
    const { activeAsset, positions } = this.props;
    const { id } = activeAsset.financialInstrument;
    return (
      <div className="SelectBidAmount-error">
        You only have {positions[id]?.units?.openAndAlienable ?? 0} shares available to sell. check
        your open orders as shares may already be allocated to an existing order. Also, recently
        acquired shares must be held for 5 business days before attempting to re-sell.
      </div>
    );
  };

  renderDividerOrTabs = () => {
    const { side, buySellOrderType, instantBuyDisabled, isLiveTrading } = this.props.data;
    const isSell = side === OrderContext.ASK;
    if (isSell || !isLiveTrading) {
      const dividerTitle = isSell ? (
        <span className="SelectBidAmount-red">Sell Shares</span>
      ) : (
        <span>Buy Shares</span>
      );
      return (
        <div className="SelectBidAmount-divider">
          <div className="SelectBidAmount-divider-line" />
          <span className="SelectBidAmount-divider-title">{dividerTitle}</span>
        </div>
      );
    }
    return (
      <BuyOrderTypeTabs
        instantBuyDisabled={instantBuyDisabled}
        type={buySellOrderType}
        onClick={this.handleTabClick}
      />
    );
  };

  renderSelectShares = () => {
    const {
      maxShares,
      showInsufficientFundsError,
      showInalienableUnitsError,
      showNotEnoughSharesError,
      showPriceBreakerError,
      loading,
    } = this.state;
    const { shares, pricePerShare, side, cost, buySellOrderType, askLow } = this.props.data;
    const showError =
      showInsufficientFundsError ||
      showNotEnoughSharesError ||
      showPriceBreakerError ||
      showInalienableUnitsError.show
        ? 'error'
        : '';
    const isSell = side === OrderContext.ASK;
    const bidAsk = isSell ? 'ask' : 'bid';
    const sign = isSell ? '+' : '-';
    const costTotal = isSell ? 'Total' : 'Cost';

    if (buySellOrderType === OrderType.INSTANT) {
      return (
        <div className="instant-buy">
          <div className="instant-buy-row">
            <div className="instant-buy-text">Share</div>
            <div className="instant-buy-value">1</div>
          </div>
          <div className="instant-buy-row">
            <div className="instant-buy-text">
              Total cost
              <div className="instant-buy-lowest">Lowest Available Ask</div>
            </div>
            <div className="instant-buy-value">$ {askLow}</div>
          </div>
        </div>
      );
    }

    return (
      <>
        <div className={'PlaceBid-fields pb-select-shares ' + showError}>
          <div className="PlaceBid-field">
            <div className="PlaceBid-field-label place-title">
              How many
              <br />
              shares?
            </div>
            <div className="PlaceBid-field-content">
              <NumberInput
                hideButtons
                disabled={loading}
                onChange={this.onSharesChange}
                value={shares}
                precision={0} // integers only
                min={1}
                max={maxShares}
              />
            </div>
          </div>
          <div className="SelectBidAmount-cross">
            <Cross />
          </div>
          <div className="PlaceBid-field">
            <div className="PlaceBid-field-label place-title">At what price (your {bidAsk})?</div>
            <div className="PlaceBid-field-content">
              <NumberInput
                formatOnReceivingValue
                disabled={loading}
                value={pricePerShare}
                onChange={this.onPricePerShareChange}
                format={roundToFiveCents}
                min={0}
                precision={2}
                step={0.05}
                prefix="$"
              />
            </div>
          </div>
        </div>
        <div className="SelectBidAmount-estimatedcost">
          <div className="PlaceBid-fields">
            <div className="PlaceBid-field">
              <div className="PlaceBid-field-label">Estimated {costTotal}</div>
              <div className="PlaceBid-field-content">
                <span>{sign} </span>
                <Price price={cost} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    const {
      loading,
      showInsufficientFundsError,
      showInalienableUnitsError,
      showNotEnoughSharesError,
      showPriceBreakerError,
      showCrossTradingError,
    } = this.state;
    const {
      activeAsset: asset,
      data: { pricePerShare },
    } = this.props;
    const trading = asset.trading || {};

    const closingTime =
      asset?.financialInstrument?.markets?.secondaryMarket?.sessionHours?.lastDateClose ||
      asset.offering_ends;
    const closingTimeFormatted = moment(closingTime).format('M/D/YY');
    const { secondaryMarket } = asset?.financialInstrument?.markets ?? {};
    const isPostOnly =
      asset.asset_status === ASSET_STATUS.POST_ONLY ||
      (asset.asset_status === ASSET_STATUS.TRADING_OPENED &&
        secondaryMarket.status !== SecondaryMarketStatus.DISABLED);
    const isLiveTrading =
      asset.asset_status === ASSET_STATUS.TRADING_OPENED &&
      secondaryMarket.status === SecondaryMarketStatus.OPEN;
    const { pricing: { pricePerUnit } = {} } = trading || {};

    let price = 0;
    if (isPostOnly) {
      price = pricePerUnit?.session?.lastClose || pricePerUnit?.issue;
    }
    if (isLiveTrading) {
      price = pricePerUnit?.session?.latestFilled || pricePerUnit?.current;
    }

    return (
      <div className="SelectBidAmount">
        <div className="SelectBidAmount-ticker">{asset.ticker}</div>
        <div className="SelectBidAmount-name">{asset.display_name}</div>
        {closingTime && (
          <div className="SelectBidAmount-lastclose">
            Last Trade, {closingTimeFormatted}
            <span className="SelectBidAmount-lastclose-price">
              <span className="SelectBidAmount-lastclose-price-pershare">
                <b>
                  <Price price={price} />
                </b>
              </span>{' '}
              &#40;
              <span className="SelectBidAmount-lastclose-price-total">
                <Price price={price * asset.number_of_shares} />
              </span>
              &#41;
            </span>
          </div>
        )}
        {this.renderDividerOrTabs()}
        {this.renderSelectShares()}
        {showInsufficientFundsError && this.renderInsufficientFundsError()}
        {showInalienableUnitsError.show && this.renderInalienableUnitsError()}
        {showNotEnoughSharesError && this.renderNotEnoughSharesError()}
        {showPriceBreakerError && this.renderPriceBreakerError()}
        {showCrossTradingError && this.renderCrossTradingError()}
        <TroughButtons className="fixed-buttons">
          <SecondaryButtonV2
            className="TroughButtons-cta"
            onClick={this.handleNextStep}
            disabled={!this.state.isStepValid || Number(pricePerShare) <= 0 || loading}
          >
            {loading ? <DotsLoader color="#fff" /> : 'Review Order'}
          </SecondaryButtonV2>
          <TextButton disabled={loading} onClick={() => this.props.onFormClose()}>
            CANCEL
          </TextButton>
        </TroughButtons>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeAssetCategory: state.Assets.activeAssetCategory,
  activeAsset: state.Assets.activeAsset,
  positions: state.Investments.positions,
});

const mapDispatchToProps = {
  getActiveAssetDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withAssetPrevalidation(SelectBidAmount));

SelectBidAmount.contextType = ModalViewContext;
