import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import './AssetDetailsLink.css';

import DETAILS_ICON from 'images/DETAILS.png';
import GALLERY_ICON from 'images/GALLERY.png';
import OPINIONS_ICON from 'images/OPINIONS.png';
import LEGAL_ICON from 'images/LEGAL.png';

export default class AssetDetailsLink extends Component {
  constructor(props) {
    super(props);

    this.icons = {
      details: DETAILS_ICON,
      gallery: GALLERY_ICON,
      opinions: OPINIONS_ICON,
      legal: LEGAL_ICON,
    };

    const app_root = '/app/';
    this.links = {
      details: app_root + 'details',
      gallery: app_root + 'gallery',
      opinions: app_root + 'chat',
      legal: app_root + 'legal',
    };
  }

  render() {
    const icon = this.icons[this.props.name];
    const link = this.links[this.props.name];
    const name = this.props.name.toUpperCase();
    return (
      <span className="AssetDetailsLink">
        <NavLink to={link}>
          <img src={icon} alt={name} />
          <div className="AssetDetailsLink-text">{name}</div>
        </NavLink>
      </span>
    );
  }
}
