import styled from 'styled-components';

export const CheckoutButton = styled.button`
  font-family: 'Gotham Medium', sans-serif;
  border-radius: 100vmin;
  border: none;
  color: white;
  transition: background-color 0.2s;
  letter-spacing: -0.02em;
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  outline: none;
`;
