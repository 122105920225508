import CONFIG from 'config.js';
import * as RequestHelper from './RequestHelper';

export function getStatements() {
  const userId = localStorage.getItem('user_id');
  const token = localStorage.getItem('token');
  const endpoint =
    CONFIG.scheme + CONFIG.apiUrl + `/api/users/${userId}/financials/forms/statements`;
  return RequestHelper.getRequest(endpoint, token);
}

export function getStatementFile(id) {
  const userId = localStorage.getItem('user_id');
  const token = localStorage.getItem('token');
  const endpoint =
    CONFIG.scheme + CONFIG.apiUrl + `/api/users/${userId}/financials/forms/statements/${id}`;
  return RequestHelper.getRequest(endpoint, token);
}

export function getTaxDocuments() {
  const userId = localStorage.getItem('user_id');
  const token = localStorage.getItem('token');
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + `/api/users/${userId}/financials/forms/taxforms`;
  return RequestHelper.getRequest(endpoint, token);
}

export function getTaxDocumentFile(id) {
  const userId = localStorage.getItem('user_id');
  const token = localStorage.getItem('token');
  const endpoint =
    CONFIG.scheme + CONFIG.apiUrl + `/api/users/${userId}/financials/forms/taxforms/${id}`;
  return RequestHelper.getRequest(endpoint, token);
}
