import CONFIG from 'config.js';
import * as RequestHelper from './RequestHelper';
import { getRequest } from './RequestHandler';

export function getInvestments() {
  const userId = localStorage.getItem('user_id');
  const token = localStorage.getItem('token');
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + `/api/users/${userId}/investments`;

  return RequestHelper.getRequest(endpoint, token);
}

export function getGainLoss() {
  const userId = localStorage.getItem('user_id');
  const token = localStorage.getItem('token');
  const endpoint =
    CONFIG.scheme + CONFIG.apiUrl + `/api/users/${userId}/investments/performance/gain-loss`;

  return RequestHelper.getRequest(endpoint, token);
}

export function startInvestments(payload) {
  const endpoint =
    CONFIG.scheme + CONFIG.apiUrl + `/api/assets/${payload.asset}/invest/reservations`;
  const token = localStorage.getItem('token');
  const body = JSON.stringify(payload);

  return RequestHelper.postRequest(endpoint, token, body);
}

export function createInvestment(payload) {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + `/api/assets/${payload.asset}/invest`;
  const token = localStorage.getItem('token');
  const body = JSON.stringify(payload);

  return RequestHelper.postRequest(endpoint, token, body);
}

export const getPositions = accountId => {
  const endpoint = CONFIG.apiUrlFinancialAccounts + `/financial-accounts/${accountId}/positions`;
  return getRequest(endpoint, { conditionalType: 'if-none-match' });
};
