import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { Popup } from './Popup';
import { SecondaryButton } from 'components/shared/Buttons/SecondaryButton';
import DwollaConfirmationNeeded from 'components/shared/DwollaConfirmationNeeded';

import { DWOLLA_CUSTOMER_STATUS as STATUSES } from 'constants/main';
import './DwollaStatusPopup.css';

class DwollaStatusPopup extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired, // "Ok" button click
    onRetry: PropTypes.func, // handling "retry" dwolla status
    history: PropTypes.object.isRequired, // 'history' service for app navigation
    status: PropTypes.string.isRequired, // dwolla status of the user
    showForRetry: PropTypes.bool, // whether to show a popup if the user has "retry" status
    showForNotCreated: PropTypes.bool, // whether to show a popup if dwolla customer isn't created
  };

  static defaultProps = { showForRetry: true, showForNotCreated: false };

  state = { confirmationNeeded: false };

  onDocumentUploadClick = () => this.props.history.push('/app/document');

  showConfirmationScreen = () => this.setState({ confirmationNeeded: true });

  onRetry = () => {
    if (this.props.onRetry) return this.props.onRetry();
    this.props.history.push('/app');
  };

  renderPopupTitle = () => {
    const { status } = this.props;
    let title = 'Something went wrong';

    if (status === STATUSES.DOCUMENT || status === STATUSES.DOCUMENT_REVIEWED) {
      const isReviewed = status === STATUSES.DOCUMENT_REVIEWED;
      title = isReviewed ? 'Please Upload Photo ID' : 'Please Verify Your Account';
    } else if (status === STATUSES.DOCUMENT_PENDING) {
      title = 'Almost There!';
    } else if (status === STATUSES.RETRY || status === STATUSES.UNVERIFIED) {
      title = 'Please Verify Your Info';
    } else if (status === STATUSES.SUSPENDED) {
      title = '';
    }
    return <h1>{title}</h1>;
  };

  renderPopupContent = () => {
    const { status } = this.props;
    let content = (
      <span>
        Please, contact&nbsp;
        <a href="mailto:support@rallyrd.com">
          <b>support@rallyrd.com</b>
        </a>
      </span>
    );
    if (status === STATUSES.DOCUMENT || status === STATUSES.DOCUMENT_REVIEWED) {
      const isReviewed = status === STATUSES.DOCUMENT_REVIEWED;
      content = `In order to verify your Rally account, you will need to upload ${
        isReviewed ? 'another ' : 'a '
      } License or Government issued photo ID. Tap Ok to upload ID.`;
    } else if (status === STATUSES.DOCUMENT_PENDING) {
      content =
        'Your account verification is still being processed. You will receive a notification once you have been approved! (usually within 2-3 days)';
    } else if (status === STATUSES.RETRY || status === STATUSES.UNVERIFIED) {
      content =
        'It looks like you might have entered your information incorrectly. Tap OKAY to review and confirm.';
    } else if (status === STATUSES.SUSPENDED) {
      content = (
        <span>
          Your Rally account has been locked for security purposes. This normally occurs when there
          is an issue with your personal or banking details. Please&nbsp;
          <a href="mailto:support@rallyrd.com">
            <b>contact support</b>
          </a>
        </span>
      );
    }
    return <p>{content}</p>;
  };

  renderPopupButton = () => {
    const { status } = this.props;
    let button = <SecondaryButton text="OKAY" onClick={this.props.onClick} />;

    if (status === STATUSES.DOCUMENT || status === STATUSES.DOCUMENT_REVIEWED) {
      button = <SecondaryButton text="OKAY" onClick={this.onDocumentUploadClick} />;
    } else if (status === STATUSES.RETRY || status === STATUSES.UNVERIFIED) {
      button = <SecondaryButton text="OKAY" onClick={this.showConfirmationScreen} />;
    }
    return button;
  };

  render() {
    const { status, showForRetry, showForNotCreated } = this.props;

    if (!status || status === STATUSES.VERIFIED) return null;
    if (!showForRetry && status === STATUSES.RETRY) return null;
    if (!showForNotCreated && status === STATUSES.NOT_CREATED) return null;
    if (this.state.confirmationNeeded) return <DwollaConfirmationNeeded onClick={this.onRetry} />;

    return (
      <Popup
        className="DwollaStatusPopup"
        {...this.props}
        show={true}
        disableAutoClose
        fullWidth={this.state.confirmationNeeded}
      >
        <div className="DwollaStatusPopup-title">{this.renderPopupTitle()}</div>
        <div className="DwollaStatusPopup-body">{this.renderPopupContent()}</div>
        <div className="DwollaStatusPopup-button">{this.renderPopupButton()}</div>
      </Popup>
    );
  }
}

export default withRouter(DwollaStatusPopup);
