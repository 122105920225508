import React, { useState, useEffect, useCallback } from 'react';
import InvestmentItem from './InvestmentItem';
import PendingOrders from './PendingOrders';
import { INVESTMENTS_STATUS } from 'constants/main';
import { groupBy, roundAccurately } from 'utils';
import { DotsLoader } from 'components/shared/Loaders';
import { OrderContext, OrderStatus } from 'types/orders';

import placeholderImage from 'images/car-placeholder-dark.png';

import './InvestmentsList.scss';

const InvestmentsList = React.memo(({ investments, orders, ...props }) => {
  const [portfolio, setPortfolio] = useState();

  /**
   * Combines active investments/orders by asset, assignes 'type' prop for each item
   * @param  {Array} investmentsArray - general investments or orders data
   * @return {Array} - combined investments/orders by asset with additional 'type' prop
   */
  const getCombinedActiveInvestmentsByAsset = investments => {
    if (!investments.length) return [];

    const sortedInvestments = Object.values(groupBy(investments, 'financialInstrument.id'));
    const combinedInvestments = sortedInvestments.map(investmentsGroup => {
      const combinedInvestment = investmentsGroup.reduce((result, current) => {
        const lastItem = {
          sharesSold: result.investmentDisplay?.sharesSold || result.sharesSold || 0,
          sharesBought: result.investmentDisplay?.sharesBought || 0,
          currentTotal: result.investmentDisplay?.currentTotal || 0,
          soldTotal: result.investmentDisplay?.soldTotal || 0,
          boughtTotal: result.investmentDisplay?.boughtTotal || 0,
          lastInvestmentDate: result.investmentDisplay?.lastInvestmentDate || null,
        };

        const currentPricePerShare =
          current.pricePerUnit?.current ||
          current.session?.lastClose ||
          current.value / current.shares;

        const currentItem = {
          shares: Number(current.shares || current.units?.filled) || 0,
          sharesBought: Number(current.shares || current.units?.filled) || 0,
          currentTotal: Number(
            currentPricePerShare * (current.shares || current.units?.filled) ??
              current.current_total_value,
          ),
          soldTotal: Number(current.pricing?.credited || current.sold_total_value) || 0,
          boughtTotal: Number(current.pricing?.debited || current.paid_total_value) || 0,
        };

        const isSale =
          (current.side && current.side === 'SELL') ||
          (current.taxonomy?.context && current.taxonomy.context === 'ASK');
        const multiplier = isSale ? -1 : 1;
        const soldMultiplier = isSale ? 1 : 0;
        const boughtMultiplier = isSale ? 0 : 1;
        const sharesSold = lastItem.sharesSold + soldMultiplier * currentItem.shares;
        const sharesBought = lastItem.sharesBought + boughtMultiplier * currentItem.sharesBought;
        const shares = sharesBought - sharesSold;
        const currentTotal = lastItem.currentTotal + multiplier * currentItem.currentTotal;
        const soldTotal = lastItem.soldTotal + soldMultiplier * currentItem.soldTotal;
        const boughtTotal = lastItem.boughtTotal + boughtMultiplier * currentItem.boughtTotal;
        const lastInvestmentDate =
          new Date(current.created) >= new Date(lastItem.lastInvestmentDate)
            ? current.created
            : lastItem.lastInvestmentDate;

        return {
          ...current,
          investmentDisplay: {
            shares,
            sharesBought,
            sharesSold,
            currentTotal,
            boughtTotal,
            soldTotal,
            lastInvestmentDate,
            currentPricePerShare,
          },
        };
      }, {});

      const averageCost =
        combinedInvestment.investmentDisplay.boughtTotal /
        combinedInvestment.investmentDisplay.sharesBought;
      const totalCost = (averageCost * combinedInvestment.investmentDisplay.shares).toFixed(2);
      const gainLoss = (
        ((combinedInvestment.investmentDisplay.currentTotal - totalCost) / totalCost) *
        100
      ).toFixed(2);
      const type = 'active';

      return {
        ...combinedInvestment,
        investmentDisplay: {
          ...combinedInvestment.investmentDisplay,
          averageCost,
          totalCost,
          gainLoss,
        },
        type,
      };
    });

    return combinedInvestments.sort(
      (a, b) =>
        new Date(b.investmentDisplay?.lastInvestmentDate || b.lastInvestmentDate) -
        new Date(a.investmentDisplay?.lastInvestmentDate || a.lastInvestmentDate),
    );
  };

  const getCombinedExitedInvestmentsByAsset = investments => {
    if (!investments.length) return [];

    const sortedInvestments = Object.values(groupBy(investments, 'financialInstrument.id'));
    const combinedInvestments = sortedInvestments.map(investmentsGroup => {
      const combinedInvestment = investmentsGroup.reduce((result, current) => {
        const shares = (result.shares || 0) + Number(current.units?.filled);
        const current_total_value =
          (result.current_total_value || 0) + Number(current.current_value);
        const paid_total_value = (result.paid_total_value || 0) + Number(current.pricing.order);
        return {
          ...current,
          shares,
          current_total_value,
          paid_total_value,
        };
      }, {});

      const averageCost = roundAccurately(
        combinedInvestment.paid_total_value / combinedInvestment.units.requested,
        2,
      );
      const totalCost = (averageCost * combinedInvestment.shares).toFixed(2);
      const gainLoss = combinedInvestment.current_total_value - totalCost;
      return {
        ...combinedInvestment,
        type: 'exited',
        averageCost,
        totalCost,
        gainLoss,
      };
    });

    return combinedInvestments;
  };

  const renderInvestments = (investments = []) => {
    return investments.map(investment => (
      <div key={investment.id}>
        <InvestmentItem investment={investment} />
      </div>
    ));
  };

  const mountPortfolioData = useCallback(() => {
    const { APPROVED, IN_PROGRESS } = INVESTMENTS_STATUS;

    const pendingInvestments = investments.filter(i => i.status === IN_PROGRESS);
    const activeInvestments = investments.filter(i => i.status === APPROVED);
    const pendingOrders = orders.filter(
      o =>
        (o.status === OrderStatus.SUBMITTED ||
          o.status === OrderStatus.PARTIALLY_FILLED ||
          o.status === OrderStatus.PENDING_CANCEL ||
          o.status === OrderStatus.PENDING_REPLACE) &&
        o.units?.unfilled > 0,
    );
    const activeOrders = orders.filter(
      o =>
        o.status === OrderStatus.FILLED ||
        o.status === OrderStatus.PARTIALLY_FILLED ||
        o.status === OrderStatus.PARTIALLY_FILLED_AND_CANCELED ||
        o.status === OrderStatus.PARTTIALLY_FILLED_AND_TERMINATED,
    );
    const exitedOrders = orders.filter(
      o => o.units.filled > 0 && o.taxonomy.context === OrderContext.ASK,
    );

    // Combine active and pending investments with active orders together by asset
    // and filter out sold out investments:
    const combinedActiveInvestments = getCombinedActiveInvestmentsByAsset([
      ...activeInvestments,
      ...pendingInvestments,
      ...activeOrders,
    ]).filter(investment => (investment.shares || investment.investmentDisplay?.shares) > 0);
    // Combine exited orders together by asset:
    const combinedExitedInvestments = getCombinedExitedInvestmentsByAsset(exitedOrders);

    setPortfolio({
      pendingInvestments,
      activeInvestments,
      pendingOrders,
      activeOrders,
      exitedOrders,
      combinedActiveInvestments,
      combinedExitedInvestments,
    });
  }, [investments, orders]);

  useEffect(() => {
    mountPortfolioData();
  }, [orders, mountPortfolioData]);

  return (
    <div className="InvestmentsList">
      {props.loading ? (
        <DotsLoader />
      ) : (
        <>
          {!!portfolio?.pendingOrders.length && (
            <div>
              <PendingOrders orders={portfolio?.pendingOrders} />
            </div>
          )}

          {!!portfolio?.combinedActiveInvestments.length && (
            <div>
              <div className="InvestmentsList-header">
                My Investments ({portfolio?.combinedActiveInvestments.length})
              </div>
              {renderInvestments(portfolio?.combinedActiveInvestments)}
            </div>
          )}

          {/*!!exitedOrders.length && this.renderInvestments(exitedOrders)*/}
          {!!portfolio?.exitedOrders.length &&
            renderInvestments(portfolio?.combinedExitedInvestments)}

          {!portfolio?.combinedActiveInvestments.length && !portfolio?.exitedOrders.length && (
            <div className="InvestmentsList-placeholder">
              <div className="InvestmentsList-placeholder-title">
                No Active or Exited Investments
              </div>
              <div className="InvestmentsList-placeholder-image">
                <img src={placeholderImage} alt="No Active or Exited Investments" />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
});

export default InvestmentsList;
