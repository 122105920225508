import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import * as uuid from 'uuid';

import TextButton from 'components/shared/Buttons/TextButton';
import { Price } from 'components/shared/Price';

import { ModalViewContext } from 'components/shared/Modals/Modal';
import MultiActionAlertModal from 'components/shared/Modals/MultiActionAlertModal';
import { getOrderById } from 'services/AssetsAPI';
import {
  getOrders,
  deleteOrder,
  setActiveAsset,
  setActiveTradingWindow,
  setActiveAssetCategory,
  setFullscreenLoader,
  setTradingWindows,
} from 'actions';

import './OrderItem.scss';
import analytics, { track } from 'services/analytics';
import { ASSET_CATEGORIES, ASSET_STATUS } from 'constants/main';
import { SEGMENT_CATEGORIES, SEGMENT_ACTIONS, SEGMENT_EVENTS } from 'constants/analytics';

import getModalContent, { ORDER_FLOW_USERACTIONS } from './OrderModalContent';
import { OrderContext, OrderStatus } from 'types/orders';
import { goToAssetItem } from 'components/RootRouter';
import { getActiveAssetDetails } from 'utils/trading';
import { getAssetSegmentStatus } from 'actions/assets';

class OrderItem extends Component {
  static propTypes = {
    order: PropTypes.object.isRequired,
    number: PropTypes.number,
    key: PropTypes.number,
  };

  state = { isLoading: false, cancelOrderLoading: false };

  componentDidMount() {
    const { order } = this.props;
    const asset = this.props.assetList.find(asset => asset.id === order.financialInstrumentId);

    if (!asset || !asset.trading || order.status === 'CLOSED' || order.status === 'MATCHED') return;
  }

  cancelOrder = async () => {
    const { order } = this.props;
    this.setState({ cancelOrderLoading: true });
    try {
      await getOrderById(order.id, order.financialAccount.id);
      if (order.status === OrderStatus.PROCESSING_UPDATE) {
        this.promptOrderModal(ORDER_FLOW_USERACTIONS.PROCESSING_UPDATE_CANCEL);
        return;
      } else {
        await this.props.deleteOrder(order.id);
      }
    } catch (err) {
      this.promptOrderModal(ORDER_FLOW_USERACTIONS.CANCEL_FAILED);
    } finally {
      this.setState({ cancelOrderLoading: false });
      this.trackCancelation();
    }
  };

  // Handle tracking of order cancelations via My Portfolio and
  trackCancelation = async () => {
    const { assetList, order, setActiveAsset, setActiveTradingWindow } = this.props;

    const targetAsset = assetList.find(
      asset => asset.financialInstrument.id === order.financialInstrumentId,
    );

    await setActiveAsset(targetAsset);
    await setActiveTradingWindow(targetAsset.financialInstrument.id);
    const assetDetails = await this.props.getActiveAssetDetails();

    analytics.track(SEGMENT_EVENTS.CANCEL_ORDER, {
      action: SEGMENT_ACTIONS.OPEN,
      category: SEGMENT_CATEGORIES.TRADING,
      assetName: assetDetails.assetName,
      assetState: assetDetails.assetStatus,
      assetCategory: assetDetails.assetCategory,
      assetTicker: assetDetails.assetTicker,
      currentValue: assetDetails.currentTotalValue,
      ioValue: assetDetails.ioPrice,
      sinceIO: assetDetails.gainLoss?.allTime,
      currentSharePrice: assetDetails.currentSharePrice,
      lastCloseSharePrice: assetDetails.lastCloseSharePrice,
      side: order.taxonomy?.context === OrderContext.ASK ? 'SELL' : 'BUY',
      shareQuantity: order.units?.unfilled,
      sharePrice: Number(order.pricing?.unit),
      lowestAsk: assetDetails.askLow,
    });
  };

  startEditOrderFlow = async () => {
    const {
      assetList,
      order,
      history,
      setActiveAsset,
      setActiveAssetCategory,
      setFullscreenLoader,
    } = this.props;

    if (order.status === OrderStatus.PROCESSING_UPDATE) {
      this.promptOrderModal(ORDER_FLOW_USERACTIONS.PROCESSING_UPDATE_EDIT);
      return;
    }

    const targetAsset = assetList.find(
      asset => asset.financialInstrument.id === order.financialInstrumentId,
    );
    const targetAssetCategory = Object.values(ASSET_CATEGORIES).find(
      ({ key }) => key === targetAsset.category,
    );

    if (
      targetAsset.asset_status === ASSET_STATUS.TRADING_OPENED ||
      targetAsset.asset_status === ASSET_STATUS.POST_ONLY ||
      targetAsset.asset_status === ASSET_STATUS.TRADING_CLOSED
    ) {
      setFullscreenLoader(true);
      await setActiveAsset(targetAsset);
      await setActiveAssetCategory(targetAssetCategory);

      history.push(
        `/app/assets/${this.props.activeAssetCategory.pathname}/${targetAsset.ticker.slice(1)}`,
      );

      history.push({
        pathname: '/app/place-bid',
        state: {
          orderData: order,
        },
      });
      setFullscreenLoader(false);
    }
  };

  handleOrderClick = () => {
    const { order, user } = this.props;
    const { ticker, category, display_name, pricePerUnit, units, taxonomy, pricing } = order;
    track(SEGMENT_EVENTS.PORTFOLIO_ORDER_TO_ASSET, {
      action: SEGMENT_ACTIONS.OPEN,
      category: SEGMENT_CATEGORIES.ASSET_2_0,
      assetTicker: ticker,
      assetCategory: category,
      assetState: getAssetSegmentStatus(order, user),
      assetName: display_name,
      lastTradeValue: pricePerUnit.session.latestFilled || pricePerUnit.session.lastClose,
      sharesMatched: units.filled,
      sharesOpen: units.unfilled,
      side: taxonomy.context === OrderContext.BID ? 'BUY' : 'SELL',
      orderType: moment(order.modified).isAfter(moment(order.modified).add(7, 'seconds'))
        ? 'Edit'
        : 'New',
      shareQuantity: units.requested,
      sharePrice: pricing.unit,
    });
  };

  promptOrderModal = type => {
    const { setModalViewRenderSequence } = this.context;

    const modalContent = getModalContent(type);

    setModalViewRenderSequence([
      {
        state: true,
        id: uuid.v4(),
        children: <MultiActionAlertModal />,
        childrenProps: {
          title: modalContent.title,
          message: modalContent.message,
          userActions: modalContent.userActions,
        },
        transition: 'modalViewScaleDown',
        onCloseCompletion: completionType => {
          switch (completionType) {
            case ORDER_FLOW_USERACTIONS.EDIT:
              this.startEditOrderFlow();
              break;
            case ORDER_FLOW_USERACTIONS.CANCEL:
              this.cancelOrder();
              break;
            case ORDER_FLOW_USERACTIONS.INIT_FLOW_CANCEL:
            case ORDER_FLOW_USERACTIONS.INIT_FLOW_EDIT:
              this.promptOrderModal(completionType);
              break;

            default:
              break;
          }
        },
      },
    ]);
  };

  render() {
    if (this.state.isLoading || !this.props.assetList.length) return null;
    const { order, key, location } = this.props;
    const asset = this.props.assetList.find(
      asset => asset.financialInstrument.id === order.financialInstrumentId,
    );

    if (!asset) return null;

    const isBid = order.taxonomy?.context === OrderContext.BID;
    const orderType = isBid ? 'Bid' : 'Ask';
    const purchaseSale = isBid ? 'Purchase' : 'Sale';

    const atPrice = order.pricing?.unit;

    return (
      <div className="OrderItem" key={key}>
        <Link
          to={goToAssetItem(asset, location)}
          onClick={this.handleOrderClick}
          className=" OrderItem-goToAsset"
        >
          <div className="OrderItem-content">
            <div className="OrderItem-header">
              {asset.ticker} - {asset.display_name}
            </div>
            <div>
              <span className={`OrderItem-${isBid ? 'bid' : 'ask'}`}>{orderType}</span>
              &nbsp;to {purchaseSale}
              &nbsp;{order.units.unfilled} Share{order.units.unfilled !== 1 ? 's' : ''}
              <b>
                &nbsp;@ <Price price={atPrice} />
              </b>
            </div>
            <div>PLACED: {moment(order.created).format('MM/DD/YYYY, h:mma')}</div>
          </div>
        </Link>
        <div className="OrderItem-btn">
          {order.status === OrderStatus.PENDING_CANCEL ? (
            <div className="pendingCancel">Pending Cancel</div>
          ) : (
            <TextButton
              className="OrderItem-confirm-btn"
              onClick={this.promptOrderModal}
              text="Edit"
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ Assets, Investments, Auth }) => ({
  assetList: Assets.assetList,
  activeAssetCategory: Assets.activeAssetCategory,
  tradingWindows: Investments.tradingWindows,
  user: Auth.user,
});

const mapDispatchToProps = {
  getOrders,
  deleteOrder,
  setActiveAsset,
  setActiveTradingWindow,
  setActiveAssetCategory,
  setFullscreenLoader,
  setTradingWindows,
  getActiveAssetDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderItem));

OrderItem.contextType = ModalViewContext;
