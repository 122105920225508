import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import RallyLogo from 'components/shared/RallyLogo';
import { DotsLoader } from 'components/shared/Loaders';
import CloseButton from 'components/shared/Buttons/CloseButton';
import history from 'services/history';
import * as AuthAPI from 'services/AuthAPI';
import './EmailConfirmation.css';
import analytics from 'services/analytics';
import { setPageTitle } from 'utils';
import { SEGMENT_CATEGORIES, SEGMENT_ACTIONS } from 'constants/analytics';

class EmailConfirmation extends Component {
  state = { isSubmitted: false, isError: false, errorMessage: null };

  async componentDidMount() {
    setPageTitle('Email Confirmation');
    const parsedUrl = this.parseArgs(this.props.location.search.substr(1));
    try {
      await AuthAPI.confirmEmail({ key: parsedUrl.key });
      this.setState({ isSubmitted: true, isError: false });
    } catch (err) {
      this.setState({ isSubmitted: true, isError: true, errorMessage: err });
    }
  }

  parseArgs(urlArgs) {
    let result = {};

    urlArgs.split('&').forEach(function(part) {
      var item = part.split('=');
      result[item[0]] = decodeURIComponent(item[1]);
    });

    return result;
  }

  handleClose = () => history.push('/app');

  render() {
    const { isSubmitted, isError, errorMessage } = this.state;
    let content = (
      <DotsLoader dotStyle={{ width: '15px', height: '15px', margin: '10px' }} color={'#28352e'} />
    );

    if (isSubmitted && !isError) {
      analytics.track('Email Confirmation - Success', {
        category: SEGMENT_CATEGORIES.ACCOUNT,
        action: SEGMENT_ACTIONS.OPEN,
      });

      content = (
        <div>
          <h1 className="EmailConfirmation-title">EMAIL CONFIRMED!</h1>
          <p className="EmailConfirmation-message">
            Your account on Rally is now active! Log in to access our latest offering. Have
            questions? Check our{' '}
            <a href="https://www.rallyrd.com/faq" target="_blank" rel="noopener noreferrer">
              FAQs here
            </a>
            , or drop us an email: support@rallyrd.com.
          </p>
        </div>
      );
    }

    if (isSubmitted && isError) {
      analytics.track('Email Confirmation - Failure', {
        category: SEGMENT_CATEGORIES.ACCOUNT,
        action: SEGMENT_ACTIONS.OPEN,
      });
      content = (
        <div>
          <h1 className="EmailConfirmation-title">Email Confirmation Failure</h1>
          <p className="EmailConfirmation-message">{errorMessage}</p>
        </div>
      );
    }

    return (
      <section className="AuthenticationPages EmailConfirmation">
        <CloseButton onClick={this.handleClose} />
        <div className="EmailConfirmation-box">
          <div className="EmailConfirmation-header">
            <Link to="/">
              <RallyLogo color="black" size="normal" />
            </Link>
          </div>
          <div className="EmailConfirmation-body">{content}</div>
        </div>
      </section>
    );
  }
}

export default EmailConfirmation;
