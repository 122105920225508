import styled from '@emotion/styled';

export const TabsContainer = styled.div({
  cursor: 'pointer',
  paddingTop: '1vmin',
  paddingBottom: '0.1vmin',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: '100%',
  flexDirection: 'column',
  '.tabs-container-inner': {
    display: 'flex',
    margin: 0,
    padding: 0,
  },
  p: {
    fontFamily: 'Gotham Book',
    color: 'white',
    width: '100%',
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '2vmin',
    height: '2vmin',
    margin: 0,
    padding: 0,
    paddingBottom: '0.5vmin',
  },
});

export const TabItem = styled.div<{ isActive?: Boolean }>(({ isActive, theme }) => ({
  fontFamily: isActive ? 'Gotham Bold' : 'Gotham Book',
  fontSize: '12px',
  fontWeight: isActive ? 500 : 400,
  color: theme.colors.greyPrimary,
  margin: '10px',
  padding: '6px 8px',
  paddingTop: 0,
  borderBottomWidth: '3px',
  borderBottomColor: theme.colors.greyPrimary,
  borderBottomStyle: isActive ? 'solid' : 'hidden',
}));
