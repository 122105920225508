import React, { Component } from 'react';
import { NavLink, withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleAssetsMenu } from 'actions';
import { Cross } from 'components/shared/Icons/Cross';

import { SUPPORTED_APPLICATION_MODES, RALLY_SUBDOMAIN_APPLICATION_MODE_MAP } from 'constants/main';

import UserLogin from 'components/shared/UserLogin';
import RallyLogo from 'components/shared/RallyLogo';
import Menu from 'components/shared/Menu';
import MobileSearch from 'components/shared/MobileSearch';

import withDeviceDetection from 'hoc/withDeviceDetection';

import './Header.scss';
import classnames from 'classnames';

class Header extends Component {
  renderMobileSearch = () => {
    const { detailsOpened, location } = this.props;
    if (location.pathname === '/app/home') {
      return <MobileSearch isHome />;
    } else if (!location.pathname.match(/assets/g) || detailsOpened) {
      return null;
    }
    return <MobileSearch />;
  };

  handleAssetListToggle = e => {
    const currentCategory = e.currentTarget.getAttribute('data-category');
    this.props.toggleAssetsMenu(currentCategory);
  };

  NavToggleButton = () => {
    const { detailsOpened } = this.props;

    if (
      detailsOpened ||
      !this.props.userDevice.isMobile ||
      this.props.location.pathname.includes('/app/tour') ||
      this.props.location.pathname.includes('/app/onboarding') ||
      this.props.location.pathname === '/app/home' ||
      this.props.location.pathname.includes('/app/investments') ||
      this.props.location.pathname.includes('/app/settings') ||
      this.props.location.pathname.includes('/app/contact')
    ) {
      return null;
    }

    const navToggleBtnClasses = classnames('AssetsMobileFooter-nav-btn', {
      listView: this.props.displayAssetsMenu,
    });

    return (
      <div className={'Header-menuwrapper-toggle'}>
        <div className="AssetsMobileFooter-list-toggler">
          <button
            className={navToggleBtnClasses}
            onClick={this.handleAssetListToggle}
            data-category={this.props.activeAssetCategory.key}
          >
            <div className="Button-modeIcon-carousel"></div>
            <div className="Button-modeIcon-listview"></div>
          </button>
        </div>
      </div>
    );
  };

  render() {
    const loc = window.location;
    let host = loc.host;

    if (this.props.applicationMode === SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS) {
      const subdomain = Object.entries(RALLY_SUBDOMAIN_APPLICATION_MODE_MAP)
        .filter(([sub, mode]) => {
          return mode === SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS;
        })
        .pop()[0];

      host = host.replace(`${subdomain}.`, '');
    }

    if (this.props.userDevice.isMobile && this.props.location.pathname === '/app/external-link') {
      return (
        <header className={`Header ${this.props.applicationMode}`}>
          <Link
            to={'/app/home'}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100vw',
              color: '#4a4947',
            }}
          >
            <Cross />
          </Link>
        </header>
      );
    }

    return (
      <>
        {this.renderMobileSearch()}
        <header className={`Header ${this.props.applicationMode}`}>
          {/*<div style={{marginRight: 'auto'}}>hey</div>*/}
          <this.NavToggleButton />
          <span className="Header-logowrapper">
            {this.props.applicationMode === SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS ? (
              <a target={'_blank'} rel="noopener noreferrer" href={`${loc.protocol}//${host}/app/`}>
                <RallyLogo color={'black'} />
              </a>
            ) : this.props.userDevice.isMobile ? (
              <RallyLogo color={'black'} />
            ) : (
              <NavLink to="/app/home">
                <RallyLogo color={'black'} />
              </NavLink>
            )}
          </span>
          <span className="Header-userloginwrapper">
            <UserLogin location={this.props.location} />
          </span>
          <span className="Header-menuwrapper">
            {this.props.showHeaderMenuOnMobile && <Menu />}
          </span>
        </header>
      </>
    );
  }
}

const mapStateToProps = state => ({
  applicationMode: state.UI.applicationMode,
  detailsOpened: state.Assets.detailsOpened,
  displayAssetsMenu: state.UI.displayAssetsMenu,
  activeAssetCategory: state.Assets.activeAssetCategory,
});

const mapDispatchToProps = { toggleAssetsMenu };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withDeviceDetection(Header)),
);
