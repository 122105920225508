/* eslint-disable no-unused-vars */
import React, { useState, useContext, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as uuid from 'uuid';

import { CheckoutButton } from './MerchandiseCheckoutStyles';
import MerchandiseCheckoutConfirm from './MerchandiseCheckoutConfirm';
import LazyImage from 'components/shared/Media/LazyImage';
import { DotsLoader } from 'components/shared/Loaders';
import { ModalViewContext, getViewContexts } from 'components/shared/Modals/Modal';
import { isEmpty } from 'utils';

const CHECKOUT_CART_QUANTITY_INCREMENT = 0;
export const MERCHANDISE_CHECKOUT_CONFIRM_COMPLETION_TYPE = {
  COMPLETION_CANCEL: 'COMPLETION_CANCEL',
  COMPLETION_SUCCESSFUL: 'COMPLETION_SUCCESSFUL',
};

const MerchandiseCheckout = ({ viewId, ...props }) => {
  const { setModalViewRenderSequence, viewArray } = useContext(ModalViewContext);
  const { asset, upsellProductInfo } = getViewContexts(
    viewArray,
    viewId,
  ).contextHandlers.childrenProps;

  const [availableMerchandisingOptions, setAvailableMerchandisingOptions] = useState(
    props.activeAssetMerchandisingOptions,
  );
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const [totalOrderPrice, setTotalOrderPrice] = useState(0);
  const [totalProductsSelectedForPurchase, setTotalProductsSelectedForPurchase] = useState([]);

  useEffect(() => {
    if (!isEmpty(props.activeAssetMerchandisingOptions)) {
      const availableMerchandising = props.activeAssetMerchandisingOptions.reduce(
        (allMerchantsData, merchant) => {
          const availableProducts = merchant.products.filter(product => {
            return (
              (product.inventory.enabled && product.inventory.total > 0) ||
              !product.inventory.enabled
            );
          });

          allMerchantsData.push({ ...merchant, products: availableProducts });
          return allMerchantsData;
        },
        [],
      );

      setAvailableMerchandisingOptions(availableMerchandising);
    }
  }, [props.activeAssetMerchandisingOptions]);

  const onCloseCompletionHandler = (confirmViewId, completionType) => {
    switch (completionType) {
      case MERCHANDISE_CHECKOUT_CONFIRM_COMPLETION_TYPE.COMPLETION_CANCEL:
        setIsCreatingOrder(false);
        break;
      case MERCHANDISE_CHECKOUT_CONFIRM_COMPLETION_TYPE.COMPLETION_SUCCESSFUL:
        setModalViewRenderSequence([{ state: false, id: viewId }]);
        break;
      default:
        break;
    }
  };

  const confirmCheckoutOrderInfo = (selectedProduct, selectedProductMerchantName) => {
    if (isCreatingOrder || !selectedProduct || !selectedProductMerchantName) return;
    setIsCreatingOrder(true);

    try {
      const updatedTotalProductsSelectedForPurchase =
        isEmpty(totalProductsSelectedForPurchase) ||
        totalProductsSelectedForPurchase.find(
          item =>
            item.product.id !== selectedProduct.id &&
            selectedProduct.merchant !== selectedProductMerchantName,
        )
          ? [
              ...totalProductsSelectedForPurchase,
              {
                merchant: selectedProductMerchantName,
                product: {
                  id: selectedProduct.id,
                  data: selectedProduct,
                  quantity: Number(1),
                  variantId: selectedProduct.variants.find(item => item.isPrimary).id, // @todo: this should be passed from a variant optionselection interface in the future
                },
              },
            ]
          : totalProductsSelectedForPurchase.map(item => {
              if (
                item.product.id === selectedProduct.id &&
                item.merchant !== selectedProductMerchantName
              )
                return {
                  ...item,
                  product: {
                    ...item.product,
                    quantity: Number(item.product.quantity) + CHECKOUT_CART_QUANTITY_INCREMENT,
                  },
                };

              return item;
            });
      setTotalProductsSelectedForPurchase(updatedTotalProductsSelectedForPurchase);

      const totalPriceOfProductsSelected = updatedTotalProductsSelectedForPurchase.reduce(
        (total, item) => {
          return Number(total) + Number(item.product.data.price.default) * item.product.quantity;
        },
        [],
      );
      setTotalOrderPrice(parseFloat(totalPriceOfProductsSelected));

      const confirmViewId = uuid.v4();
      setModalViewRenderSequence([
        {
          state: true,
          id: confirmViewId,
          children: <MerchandiseCheckoutConfirm />,
          childrenProps: {
            asset: asset,
            totalProductsSelectedForPurchase: updatedTotalProductsSelectedForPurchase,
            totalProductsPrice: parseFloat(totalPriceOfProductsSelected),
          },
          modalStyle: {
            bgColor: 'transparent',
            overlayBgColor: 'rgba(0,0,0,0.5)',
            fillEntireScreen: false,
            mobileFillEntireScreen: true,
          },
          transition: 'modalViewSlideIn',
          blockForceDismiss: true,
          onCloseCompletion: completionType =>
            onCloseCompletionHandler(confirmViewId, completionType),
        },
      ]);
    } catch (error) {
      setIsCreatingOrder(false);
    }
  };

  return (
    <MakeInvestmentCheckoutView>
      <CheckoutContainer>
        <CheckoutTitle>
          <span className="title">YOU'RE IN!</span>
          <span className="msg">Add a {upsellProductInfo.description.text} to this order?</span>
        </CheckoutTitle>

        {availableMerchandisingOptions.map(merchant => {
          const merchantName = merchant.id;
          return (
            <Fragment key={merchantName}>
              {merchant.products.map((product, index) => {
                // @todo: when interface design is ready for multiple product items, remove index check line
                if (index === 0)
                  return (
                    <CheckoutProductItem key={`${merchantName}_${product.id}`}>
                      <CheckoutProductImage>
                        <LazyImage
                          mode="image"
                          src={product.images[0].originalSourceUrl}
                          offset={0}
                        />
                      </CheckoutProductImage>
                      <CheckoutButtonsContainer>
                        <CheckoutPurchaseButton
                          clickedAndConfirming={isCreatingOrder}
                          onClick={() => confirmCheckoutOrderInfo(product, merchantName)}
                        >
                          {isCreatingOrder ? (
                            <DotsLoader color="rgba(255, 255, 255, 0.7)" />
                          ) : (
                            <>
                              <ButtonMainText>ADD TO MY ORDER</ButtonMainText>
                              <Dividebar />
                              <ProductPrice>${product.price.default}</ProductPrice>
                            </>
                          )}
                        </CheckoutPurchaseButton>
                        <CheckoutTextButton
                          onClick={() => getViewContexts(viewArray, viewId).onClose()}
                        >
                          No thanks
                        </CheckoutTextButton>
                      </CheckoutButtonsContainer>
                    </CheckoutProductItem>
                  );

                return null;
              })}
            </Fragment>
          );
        })}
      </CheckoutContainer>

      <CheckoutExplanatoryText>
        Your investment receipt will be sent via email shortly. You can access your investment in
        your portfolio
      </CheckoutExplanatoryText>
    </MakeInvestmentCheckoutView>
  );
};

const MakeInvestmentCheckoutView = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  padding: 5vh 10vw;
  overflow: auto;
  letter-spacing: -0.03em;

  @media only screen and (min-width: 350px) and (max-width: 600px) and (max-height: 550px) {
    justify-content: normal;
  }
`;

const CheckoutContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: column;

  @media only screen and (min-width: 350px) and (max-width: 600px) and (max-height: 550px) {
    margin-bottom: 5%;
  }
`;

const CheckoutTitle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;

  .title {
    color: #53cd7f;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 4vmin;
    font-family: 'Gotham Medium';
  }

  .msg {
    color: #fff;
    font-size: 3vmin;
    margin-top: 7px;
    font-family: 'Gotham Book';
  }

  @media only screen and (max-width: 600px) {
    .title {
      font-size: 6.5vmin;
    }

    .msg {
      font-size: 5.5vmin;
    }
  }
`;

const PlusIcon = styled.span`
  font-weight: 900;
  text-transform: uppercase;
  color: #fff;
  margin-right: 10px;
`;

const ButtonMainText = styled.span`
  font-weight: 800;
  text-transform: uppercase;
  color: #fff;
`;

const ProductPrice = styled.span`
  text-transform: uppercase;
  color: #53cd7f;
  font-weight: 800;
`;

const Dividebar = styled.span`
  background: rgba(255, 255, 255, 0.2);
  width: 1px;
  height: 1.6em;
  margin: 0 12px;
`;

const CheckoutProductItem = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
`;

const CheckoutProductImage = styled.div`
  max-width: 70%;
  height: 25vmin;
  margin: 5vh 0;

  @media only screen and (max-width: 600px) {
    height: 45vmin;
    margin: 3vh 0;
  }

  .LazyImage {
    height: 100%;
    display: flex;
    align-items: center;
  }

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    position: relative;
  }
`;

const CheckoutButtonsContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
`;

const CheckoutPurchaseButton = styled(CheckoutButton)`
  font-size: 2.6vmin;
  font-weight: 400;
  background: ${({ clickedAndConfirming }) => (clickedAndConfirming ? '#53cd7f' : 'transparent')};
  border: 4px solid #53cd7f;
  text-transform: none;
  padding: 2vmin 3vmin;
  justify-content: center;
  max-width: 38vw;
  min-height: 3.6em;

  @media only screen and (max-width: 600px) {
    font-size: 3.9vmin;
    padding: 4vmin 6vmin;
    width: 100%;
    max-width: none;
    height: 4.2em;
    border: 3px solid #53cd7f;
  }
`;

const CheckoutTextButton = styled(CheckoutButton)`
  font-size: 2vmin;
  font-weight: 800;
  color: rgba(255, 255, 255, 0.69);
  background: none;
  text-transform: uppercase;
  padding: 1vmin 2vmin;
  justify-content: center;
  margin-top: 20px;

  @media only screen and (max-width: 600px) {
    font-size: 3.5vmin;
    padding: 1.5vmin 3vmin;
    font-weight: 800;
  }
`;

const CheckoutExplanatoryText = styled.p`
  font-size: 2vmin;
  color: rgba(255, 255, 255, 0.69);
  text-align: center;
  margin: 0;
  width: 50%;
  font-family: 'Gotham Book';

  @media only screen and (max-width: 600px) {
    font-size: 4vmin;
    width: auto;
  }

  @media only screen and (min-width: 350px) and (max-width: 600px) and (max-height: 550px) {
    margin-top: 5%;
  }
`;

const mapStateToProps = state => ({
  activeAsset: state.Assets.activeAsset,
  activeAssetMerchandisingOptions: state.Assets.activeAssetMerchandisingOptions,
  balance: state.Investments.balance,
  bankFundingSource: state.Investments.bankFundingSource,
  user: state.Auth.user,
});

export default connect(
  mapStateToProps,
  null,
)(MerchandiseCheckout);
