import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BackNextButtons } from 'components/shared/Buttons/BackNextButtons';
import investImage from 'images/tour/03_invest.gif';

import './Invest.css';

class Invest extends Component {
  static propTypes = {
    onStepForward: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className="InvestTour">
        <h2>Invest</h2>
        <p className="light">Buy shares in any asset with the click of a button!</p>
        <img src={investImage} alt="manage" className="InvestTour-img Tour-gif" />
        <BackNextButtons
          buttons={{ next: { text: 'next' }, style: 'centered' }}
          onNextClick={() => this.props.onStepForward()}
        />
      </div>
    );
  }
}

export default Invest;
