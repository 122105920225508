import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateUserData, updateInvestmentFlow } from 'actions';
import { Input } from 'components/shared/Inputs/Input';
import { BackNextButtons } from 'components/shared/Buttons/BackNextButtons';

class CompanyTicker extends Component {
  static propTypes = {
    data: PropTypes.object,
    onFormUpdate: PropTypes.func.isRequired,
    onStepForward: PropTypes.func.isRequired,
    onStepBackward: PropTypes.func.isRequired,
    updateUserData: PropTypes.func.isRequired,
  };

  state = { isStepValid: false, isLoading: false };

  initialData = {};

  componentDidMount() {
    this.validateStep();
    this.initialData = this.props.data;
  }

  validateStep = () => {
    const {
      data: { publicly_traded_company_tickers },
    } = this.props;
    const isStepValid =
      typeof publicly_traded_company_tickers === 'string' && publicly_traded_company_tickers !== '';
    this.setState({ isStepValid });
  };

  onTickerChange = value =>
    this.props.onFormUpdate(
      {
        publicly_traded_company_tickers: value,
      },
      this.validateStep,
    );

  handleNextClick = () => {
    const { publicly_traded_company_tickers } = this.props.data;
    this.setState({ isLoading: true });
    const {
      investmentFlow: { investment_info },
    } = this.props;
    this.props
      .updateUserData({
        investment_info: { ...investment_info, publicly_traded_company_tickers },
      })
      .then(() => {
        this.setState({ isLoading: false });
        this.props.updateInvestmentFlow({ investment_info: { publicly_traded_company_tickers } });
        this.props.onStepForward();
      })
      .catch(() => this.setState({ isLoading: false }));
  };

  handleBackClick = () => {
    if (this.props.resetOnBackPress) {
      const { publicly_traded_company_tickers } = this.initialData;
      this.props.onFormUpdate({ publicly_traded_company_tickers });
    }
    this.props.onStepBackward();
  };

  render() {
    const {
      data: { publicly_traded_company_tickers },
    } = this.props;

    return (
      <div>
        <p>If yes, please list ticker symbols of the publicly traded Company(s)</p>
        <Input
          onChange={this.onTickerChange}
          value={publicly_traded_company_tickers}
          autoFocus={true}
          placeholder="AAPL, QQQQ, ETC"
        />
        <BackNextButtons
          buttons={{ back: { text: 'back' }, next: { text: 'next' } }}
          nextDisabled={!this.state.isStepValid}
          loading={this.state.isLoading}
          onBackClick={this.handleBackClick}
          onNextClick={this.handleNextClick}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  investmentFlow: state.Investments.investmentFlow,
});

const mapDispatchToProps = { updateUserData, updateInvestmentFlow };

export default connect(mapStateToProps, mapDispatchToProps)(CompanyTicker);
