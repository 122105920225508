import React, { Component } from 'react';
import './ContentHeader.css';

export default class ContentHeader extends Component {
  render() {
    return (
      <div className="ContentHeader">
        <div className="ContentHeader-border" />
        <div className="ContentHeader-title">{this.props.children}</div>
      </div>
    );
  }
}
