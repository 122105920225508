import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getClientVersionNumber, isRunningOnRallyAndroidClient } from 'utils';
import store from 'store';
import { storeAuthToken } from './AuthAPI';
import { logoutUser, setAuthTimer } from 'actions';
import { ERROR_CODES } from 'constants/main';
import Bugsnag from '@bugsnag/js';
import CONFIG from 'config';

const isRallyAndroidClient = isRunningOnRallyAndroidClient();
const clientVersionNumber = getClientVersionNumber();

type RallyResquestConfig = AxiosRequestConfig & {
  hardRefresh?: boolean;
  idempotencyKey?: string;
  conditionalType?: 'if-match' | 'if-none-match';
};

export type RequestCallerReponse<Type> = Promise<AxiosResponse<Type>>;

type RequestWithBody = (
  endpoint: string,
  body: any,
  options?: RallyResquestConfig,
) => Promise<AxiosResponse<any>>;

type RequestWithoutBody = (
  endpoint: string,
  options?: RallyResquestConfig,
) => Promise<AxiosResponse<Response>>;

type CacheController = {
  [key: string]: {
    etag?: string;
    data?: any;
  };
};

let axiosInstance: AxiosInstance;

const cacheController: CacheController = {};

const handle500s = (error: any) => {
  const is500s = error.response?.status >= 500 && error.response?.status <= 504;
  return is500s;
};

const setInterceptors = () => {
  axiosInstance.interceptors.response.use(
    response => {
      // updates the token in the store and delete it from the request
      if (response.data.token) {
        if (store.getState().Auth.isAuth) storeAuthToken(response.data.token);
        delete response.data.token;
      }
      // refreshs the token is a new one does not exists in the reponse
      if (!response.data.token) {
        if (store.getState().Auth.isAuth) store.dispatch(setAuthTimer());
      }
      return response;
    },
    error => {
      // if the token expires or not logged calss the logout the user action
      if (error.status === 401) {
        store.dispatch(logoutUser(ERROR_CODES.UNAUTHORIZED_401));
        return;
      }
      Bugsnag.notify(error);
      return Promise.reject(error);
    },
  );
};

const setEtagInterceptors = () => {
  // saves the ETag every time we receive a response
  axiosInstance.interceptors.response.use(
    response => {
      const { headers, config, status } = response;
      if (status === 304 && config.url) {
        return {
          ...response,
          data: cacheController[config.url].data,
        };
      }
      if (headers?.etag && config?.url) {
        cacheController[config.url] = {
          ...cacheController[config.url],
          etag: headers.etag,
          data: response.data,
        };
      }
      return response;
    },
    error => {
      if (error.response?.status === 412) {
        return new Promise((resolve, reject) => {
          const { config, headers } = error.response;
          cacheController[config.url] = {
            ...cacheController[config.url],
            etag: headers.etag,
          };
          config.headers[config.conditionalType] = headers.etag;
          axios
            .request(config)
            .then(response => {
              resolve(response);
            })
            .catch(err => reject(err));
        });
      }
      return Promise.reject(error);
    },
  );
  // uses the ETag when it is saved
  axiosInstance.interceptors.request.use((request: RallyResquestConfig) => {
    const { url, hardRefresh, conditionalType } = request;
    if (hardRefresh) {
      delete request.hardRefresh;
      return request;
    }
    if (url && cacheController[url]?.etag && conditionalType) {
      request.headers[conditionalType] = cacheController[url].etag;
    }
    return request;
  });
};

const setIdempotencyKey = () => {
  axiosInstance.interceptors.request.use((request: RallyResquestConfig) => {
    const { idempotencyKey } = request;
    if (idempotencyKey) {
      request.headers['Idempotency-Key'] = idempotencyKey;
      delete request.idempotencyKey;
    }
    return request;
  });
};

export const getInstance = () => {
  if (!axiosInstance) {
    axiosInstance = axios.create({
      validateStatus: function (status) {
        return status >= 200 && status <= 304;
      },
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Application': isRallyAndroidClient ? 'rallyrd-android' : 'rallyrd-web',
        'X-Application-Version': clientVersionNumber,
        'Cache-Control': 'max-age=60, must-revalidate',
      },
    });
    setInterceptors();
    setEtagInterceptors();
    setIdempotencyKey();
  }
  return axiosInstance;
};

const getHeaderWithToken = () => {
  const token = localStorage.getItem('token');
  return {
    Authorization: 'JWT ' + token,
  };
};

export const patchRequest: RequestWithBody = (endpoint, body, options) => {
  return getInstance().patch(endpoint, body, {
    headers: getHeaderWithToken(),
    ...options,
    withCredentials: true,
  });
};

export const postRequest: RequestWithBody = (endpoint, body, options) => {
  return getInstance().post(endpoint, body, {
    headers: getHeaderWithToken(),
    ...options,
    withCredentials: true,
  });
};

export const postPrevalidateRequest: RequestWithBody = async (endpoint, body, options) => {
  const instance = getInstance();
  const postPrevalidateInterceptor = instance.interceptors.response.use(response => {
    if (response?.config?.url !== endpoint) {
      return response;
    }
    instance.interceptors.response.eject(postPrevalidateInterceptor);

    return response;
  }, error => {
    if (handle500s(error)) {
      return Promise.resolve({ data: { valid: true }});
    }
    return Promise.reject(error);
  });
  return instance.post(endpoint, body, {
    headers: getHeaderWithToken(),
    ...options,
    withCredentials: true,
  });
};

export const putRequest: RequestWithBody = (endpoint, body, options) => {
  return getInstance().put(endpoint, body, {
    headers: getHeaderWithToken(),
    ...options,
    withCredentials: true,
  });
};

export const getRequest: RequestWithoutBody = (endpoint, options) => {
  return getInstance().get(endpoint, {
    headers: getHeaderWithToken(),
    ...options,
    withCredentials: true,
  });
};

// used only for getOrder on the post order flow

export const getOrderRequest: RequestWithoutBody = async (endpoint, options) => {
  const config: any = CONFIG;
  const pollingIntervalMs = 1000; // 1s
  const pollingMaxTimeMs = config.poolingAssetTimeout;
  let finished = false;
  let pollingInterval: NodeJS.Timeout;
  let finalResponse: AxiosResponse<any>;
  // TODO see if the etag is working properly with this interceptor

  const instance = getInstance();
  const getOrderInterceptor = instance.interceptors.response.use(response => {
    if (response.config.url !== endpoint) {
      return response;
    }
    instance.interceptors.response.eject(getOrderInterceptor);
    return new Promise((resolve, reject) => {
      finalResponse = response;

      pollingInterval = setInterval(() => {
        axios
          .request(response.config)
          .then(axiosResp => {
            finalResponse = axiosResp;
            if (finished) {
              clearInterval(pollingInterval);
              resolve(finalResponse);
            }
          })
          .catch(error => {
            clearInterval(pollingInterval);
            reject(error);
          });
      }, pollingIntervalMs);

      setTimeout(() => {
        finished = true;
      }, pollingMaxTimeMs);
    });
  });
  return instance.get(endpoint, {
    headers: getHeaderWithToken(),
    ...options,
    withCredentials: true,
  });
};

export const deleteRequest: RequestWithoutBody = (endpoint, options) => {
  return getInstance().delete(endpoint, {
    headers: getHeaderWithToken(),
    ...options,
    withCredentials: true,
  });
};
