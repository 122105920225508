import config from 'config';
import history from 'services/history';
import { isInTestMode } from 'utils/test';

/**
 * A wrapper around the analytics service.
 * Provides a method for initializing the service + additional utility methods when necessary.
 * Acts as a proxy wrapper for Segment Analytcs library:
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/.
 */
const analytics = new Proxy(
  {
    init(options = { trackPageViews: true }) {
      window.analytics.load(config.segmentAnalyticsKey);
      if (options.trackPageViews) {
        //window.analytics.page();     // @TODO is this redundant?
        history.listen(() => window.analytics.page());
      }
    },
  },
  {
    get(target, prop) {
      if (!window.analytics) {
        !isInTestMode() && console.warn('Analytics module not found');
        return () => null;
      }
      if (target[prop]) return target[prop];
      return window.analytics[prop];
    },
  },
);

export const track = (event, options) => analytics.track(event, options);

export default analytics;
