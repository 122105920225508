import { all, takeEvery, select, put, call } from 'redux-saga/effects';
import { SET_ACTIVE_ASSET_CATEGORY } from 'actions/types';
import { Action } from 'redux-actions';
import { RootState } from 'store';
import { Asset, AssetStatus } from 'types/assets';
import { getSecurityPricing } from 'services/AssetsAPI';
import { setCategoryAssets } from 'actions/assets';

type SetCategoryPayload = {
  key: string;
};

const nonTradingStatus = [AssetStatus.IO_CLOSED, AssetStatus.IO_OPENED, AssetStatus.COMING_SOON];

function* fetchAssetsPricingByCategory(action: Action<SetCategoryPayload>) {
  const categoryList: Asset[] = yield select(
    (store: RootState) => store.Assets.assetCategoryLists[action.payload.key],
  );

  if (!categoryList) return;

  // call all of them in the simultaneously time
  const result = yield all(
    categoryList.map(function* (asset) {
      if (nonTradingStatus.includes(asset.asset_status)) {
        return asset;
      }
      const financialId = asset.financialInstrument?.id ?? '';
      if (!financialId) return;
      const response = yield call(getSecurityPricing, financialId);
      return {
        ...asset,
        pricing: response.data,
      };
    }),
  );
  yield put(setCategoryAssets({ assets: result, category: action.payload.key }));
}

function* watchSetActiveCategory() {
  yield takeEvery(SET_ACTIVE_ASSET_CATEGORY, fetchAssetsPricingByCategory);
}

export default function* pricingSaga() {
  yield all([watchSetActiveCategory()]);
}
