const popupData = {
  early_io: {
    title: 'EARLY ACCESS',
    description:
      "Welcome to Rally. We're giving you early access to an offering before it opens to the general public. To beat the rush and get your shares prior to the platform-wide release, tap below!",
    cta: {
      title: 'EXPLORE & INVEST',
    },
    subCta: {
      title: 'NO THANKS',
    },
  },
  free_share_program: {
    title: 'THIS ONE IS ON US',
    description:
      "Welcome to Rally! To help build your portfolio, we're giving you a full credit toward a share in an exclusive asset. Select the button below to automatically apply a gift code toward this investment!",
    cta: {
      title: 'REDEEM NOW!',
    },
    subCta: {
      title: 'NO THANKS',
    },
  },
};

const getPopupData = type => popupData[type];

export default getPopupData;
