import React, { Component } from 'react';

import './TroughButtons.scss';

export class TroughButtons extends Component {
  render() {
    const { className } = this.props;
    return (
      <span className={`TroughButtons ${className ? className : ''}`}>{this.props.children}</span>
    );
  }
}
