export enum OrderStatus {
  ADMINISTRATIVELY_CANCELED = 'ADMINISTRATIVELY_CANCELED',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  FILLED = 'FILLED',
  PARTIALLY_FILLED = 'PARTIALLY_FILLED',
  PARTIALLY_FILLED_AND_CANCELED = 'PARTIALLY_FILLED_AND_CANCELED',
  PARTTIALLY_FILLED_AND_TERMINATED = 'PARTTIALLY_FILLED_AND_TERMINATED',
  PENDING_CANCEL = 'PENDING_CANCEL',
  PENDING_REPLACE = 'PENDING_REPLACE',
  PROCESSING_UPDATE = 'PROCESSING_UPDATE',
  QUEUED = 'QUEUED',
  REJECTED = 'REJECTED',
  SUBMITTED = 'SUBMITTED',
  TERMINATED = 'TERMINATED',
}

export enum OrderType {
  INSTANT = 'INSTANT',
  LIMIT = 'LIMIT',
}

export enum OrderNewEditType {
  NEW = 'New',
  EDIT = 'Edit',
}

export enum OrderContext {
  ASK = 'ASK',
  BID = 'BID',
}

export enum OrderTerm {
  GOOD_TIL_CANCELED = 'GOOD_TIL_CANCELED',
  IMMEDIATE_OR_CANCEL = 'IMMEDIATE_OR_CANCEL',
}

export type PostOrderData = {
  context: OrderContext;
  disclosureReadAndAgreedTo: boolean;
  financialInstrumentId: string;
  promotionalCode?: string;
  term: OrderTerm;
  type: 'LIMIT';
  unitPrice: number;
  units: number;
};

export interface SessionHours {
  dateClose: string;
  lastDateClose: Date;
  nextDateOpen: string;
  nextDateClose: string;
  dateOpen: string;
  lastDateOpen: Date;
}

export interface SecondaryMarket {
  sessionHours: SessionHours;
  disabled: string;
  enabled: Date;
  sessionsFollowMarketHours: boolean;
  status: string;
}

export interface Markets {
  secondaryMarket: SecondaryMarket;
}

export interface FinancialInstrument {
  id: string;
  status: string;
  markets: Markets;
}

export interface LastTradingWindow {
  id: string;
  status: string;
  created: Date;
  closing_time: Date;
  closing_price: string;
}

export interface Trading {
  current_trading_window?: any;
  last_trading_window: LastTradingWindow;
  last_closing_price: string;
  estimated_price: string;
  price_shift_percentage: number;
  price_shift: string;
}

export interface IOrder {
  id: string;
  financialInstrument: FinancialInstrument;
  asset_status: string;
  asset_value: number;
  background_color: string;
  confirmed_investment: number;
  confirmed_investment_shares: number;
  display_name: string;
  exited_date?: any;
  home_image: string;
  investment_image: string;
  is_active: boolean;
  is_exited: boolean;
  media_description: string;
  modified: Date;
  number_of_shares: number;
  offering_ends: Date;
  offering_starts: Date;
  order: number;
  pending_investment: number;
  pending_investment_shares: number;
  portal_image: string;
  progress_bar: number;
  receiptcard_image: string;
  sharecard_image: string;
  tertiary_color: string;
  ticker: string;
  trading: Trading;
  category: string;
  snapchat_deeplink_url: string;
}
