import React, { useState, useCallback } from 'react';
import { SimpleComponent } from 'types/components';
import { IStep } from 'components/shared/MultistepForm/types/step';
import { RiskProfileContainer, RiskTitle } from './styled';
import { IRadioOption, Radio } from 'components/shared/Inputs';
import { BackNextButtons } from 'components/shared/Buttons';
import { useDispatch, useSelector } from 'react-redux';
import { RiskToleranceLevel } from 'types/customer';
import Bugsnag from '@bugsnag/js';
import { useAlert, useGeneralErrorAlert } from 'hooks';
import { patchInvestorDetails } from 'services/CustomerApi';
import { RootState } from 'store';
import { setInvestorDetails } from 'actions/customer';

type Props = IStep<{
  riskToleranceLevel: RiskToleranceLevel | '';
  investorDetails: Record<string, any>;
}>;

const RiskOptions: IRadioOption[] = [
  {
    text: 'Conservative',
    value: RiskToleranceLevel.Conservative,
    description:
      'I want to preserve my initial principal, with minimum risk, even if this means this account does not generate significant income or returns.',
  },
  {
    text: 'Moderate',
    value: RiskToleranceLevel.Moderate,
    description:
      'I am willing to accept some risk to my initial principal and tolerate some volatility to seek higher returns.',
  },
  {
    text: 'Growth',
    value: RiskToleranceLevel.Growth,
    description:
      ' I am willing to accept more risk to my initial principal to seek maximum returns and understand I could lose most, or all, of the money invested.',
  },
];

const RiskProfile: SimpleComponent<Props> = ({
  data,
  onStepBackward,
  onStepForward,
  onFormUpdate,
}) => {
  const { riskToleranceLevel } = data;
  const id = useSelector<RootState, string>(store => store.Auth.user.customer.id);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const showAlert = useAlert();
  const showErrorAlert = useGeneralErrorAlert();

  const handleOnChange = useCallback(
    (value, name) => {
      onFormUpdate({ [name]: value });
    },
    [onFormUpdate],
  );

  const handleNext = async () => {
    if (!riskToleranceLevel) {
      return;
    }
    try {
      setLoading(true);
      const investorDetails = { riskToleranceLevel };
      await patchInvestorDetails(investorDetails, id);
      dispatch(setInvestorDetails(investorDetails));
      if (riskToleranceLevel !== RiskToleranceLevel.Conservative) {
        onStepForward();
        return;
      }
      showAlert(
        'Risk Profile Alert',
        <span>
          Trading on Rally involves a degree of risk which may not be suitable for your profile. You
          may edit your answer, or close the previous screen and continue.
        </span>,
      );
    } catch (err) {
      Bugsnag.notify(err);
      showErrorAlert();
    } finally {
      setLoading(false);
    }
  };

  return (
    <RiskProfileContainer>
      <RiskTitle>
        Please select the degree of risk that you are willing to take with the assets in this account:
      </RiskTitle>
      <Radio
        name="riskToleranceLevel"
        value={riskToleranceLevel}
        onChange={handleOnChange}
        options={RiskOptions}
      />
      <BackNextButtons
        buttons={{ back: { text: 'back' }, next: { text: 'next' } }}
        nextDisabled={riskToleranceLevel === ''}
        onBackClick={onStepBackward}
        loading={loading}
        onNextClick={handleNext}
      />
    </RiskProfileContainer>
  );
};

export default RiskProfile;
