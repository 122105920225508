import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { Collapse } from 'react-collapse';

import {
  CarIcon,
  BooksIcon,
  HistoryIcon,
  WineIcon,
  TransferIcon,
  FaqIcon,
  UserIcon,
  MailIcon,
  LuxuryIcon,
  CategoryIcon,
} from 'components/shared/Icons/nav';
import ChevronIcon from 'components/shared/Icons/ChevronIcon';
import { toggleMenu, showNavMenuNotification, closeAssetSearchAndClearList } from 'actions';
import { ASSET_CATEGORIES, SUPPORTED_APPLICATION_MODES } from 'constants/main';

import './NavigationMenu.scss';

class NavigationMenu extends Component {
  static propTypes = {
    applicationMode: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    menuIsOpen: PropTypes.bool.isRequired,
    navMenuNotifications: PropTypes.array.isRequired,
    toggleMenu: PropTypes.func.isRequired,
  };

  state = {
    dropdownsOpenState: {
      Categories: true,
    },
  };

  menuItems = [
    {
      path:
        this.props.applicationMode === SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS
          ? '/'
          : '/app/home',
      title: 'Home',
      mobileOnly: true,
      applicationModes: [
        SUPPORTED_APPLICATION_MODES.DEFAULT,
        SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS,
      ],
    },
    {
      path:
        this.props.applicationMode === SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS
          ? '/'
          : '/app/assets',
      title: 'Categories',
      mobileOnly: true,
      applicationModes: [
        SUPPORTED_APPLICATION_MODES.DEFAULT,
        SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS,
      ],
    },
    {
      path: '/app/home',
      title: 'Home',
      applicationModes: [SUPPORTED_APPLICATION_MODES.DEFAULT],
      desktopOnly: true,
    },
    {
      path: '/app/assets/',
      title: 'Categories',
      Icon: CategoryIcon,
      children: [
        {
          // memoriabilia
          path: `/app/assets/${ASSET_CATEGORIES.HISTORY.pathname}`,
          Icon: HistoryIcon,
          title: ASSET_CATEGORIES.HISTORY.title,
          key: ASSET_CATEGORIES.HISTORY.key,
        },
        {
          // comics + literature
          path: `/app/assets/${ASSET_CATEGORIES.COMICS_LITERATURE.pathname}`,
          Icon: BooksIcon,
          title: ASSET_CATEGORIES.COMICS_LITERATURE.title,
          key: ASSET_CATEGORIES.COMICS_LITERATURE.key,
        },
        {
          // cars
          path: `/app/assets/${ASSET_CATEGORIES.CARS.pathname}`,
          Icon: CarIcon,
          title: ASSET_CATEGORIES.CARS.title,
          key: ASSET_CATEGORIES.CARS.key,
        },
        {
          // watches + luxury
          path: `/app/assets/${ASSET_CATEGORIES.LUXURY.pathname}`,
          Icon: LuxuryIcon,
          title: ASSET_CATEGORIES.LUXURY.title,
          key: ASSET_CATEGORIES.LUXURY.key,
        },
        {
          // wine + whisky
          path: `/app/assets/${ASSET_CATEGORIES.WINE_SPIRITS.pathname}`,
          Icon: WineIcon,
          title: ASSET_CATEGORIES.WINE_SPIRITS.title,
          key: ASSET_CATEGORIES.WINE_SPIRITS.key,
        },
      ],
      desktopOnly: true,
      applicationModes: [SUPPORTED_APPLICATION_MODES.DEFAULT],
    },
    {
      path: '/app/investments',
      title: 'My Portfolio',
      Icon: TransferIcon,
      applicationModes: [SUPPORTED_APPLICATION_MODES.DEFAULT],
    },
    {
      path: 'https://rallyrd.com/faq/',
      title: 'Faqs',
      Icon: FaqIcon,
      applicationModes: [SUPPORTED_APPLICATION_MODES.DEFAULT],
      externalLink: true,
    },
    {
      path: '/app/settings',
      title: 'Settings',
      Icon: UserIcon,
      applicationModes: [SUPPORTED_APPLICATION_MODES.DEFAULT],
    },
    {
      path: '/app/contact',
      title: 'Contact',
      Icon: MailIcon,
      applicationModes: [
        SUPPORTED_APPLICATION_MODES.DEFAULT,
        SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS,
      ],
    },
    {
      path: '/app/logout',
      title: 'Logout',
      mobileOnly: true,
      applicationModes: [
        SUPPORTED_APPLICATION_MODES.DEFAULT,
        SUPPORTED_APPLICATION_MODES.SPECIAL_ACCESS,
      ],
    },
  ];

  toggleDropdown = title =>
    this.setState({
      dropdownsOpenState: {
        ...this.state.dropdownsOpenState,
        [title]: !this.state.dropdownsOpenState[title],
      },
    });

  handleNavItemClick = e => {
    if (e.currentTarget.dataset.title) {
      const { nested, title } = e.currentTarget.dataset;
      if (nested) {
        e.preventDefault();
        this.toggleDropdown(title);
      }
      if (this.props.menuIsOpen) {
        this.props.toggleMenu();
      }
    }
  };

  checkActiveHomeRoute = (path, title) => {
    if (title === 'Categories' && this.props.location.includes('asset')) return true;
    if (
      this.props.location === '/app/investments' &&
      title === 'My Portfolio' &&
      !path.includes('asset')
    )
      return true;
    if (this.props.location === '/app/settings' && title === 'Settings' && !path.includes('asset'))
      return true;
    if (this.props.location === '/app/contact' && title === 'Contact' && !path.includes('asset'))
      return true;
    if (this.props.location === '/app/home' && title === 'Home' && !path.includes('asset'))
      return true;
    return false;
  };

  checkActiveCategoryRoute = (key, path) => {
    if (path === '/app/home') return false;
    return key ? key === this.props.activeAssetCategory.key : false;
  };

  getLinkClassNames = path => {
    return classnames('NavigationMenu-link', {
      notification: this.props.navMenuNotifications.includes(path),
    });
  };

  handleCloseSearch = () => {
    const { isAssetsSearchActive, closeAssetSearchAndClearList } = this.props;
    if (isAssetsSearchActive) {
      closeAssetSearchAndClearList();
    }
  };

  render() {
    const navigationClassNames = classnames('NavigationMenu', {
      'mobile-opened': this.props.menuIsOpen,
      'mobile-closed': !this.props.menuIsOpen,
      [this.props.applicationMode]: this.props.applicationMode,
    });

    return (
      <nav className={navigationClassNames}>
        <ul className="NavigationMenu-list">
          {this.menuItems.map(
            (
              {
                path,
                Icon,
                title,
                children,
                applicationModes,
                mobileOnly,
                desktopOnly,
                externalLink,
              },
              key,
            ) => {
              const itemClassNames = classnames('NavigationMenu-item', {
                'mobile-only': mobileOnly,
                'desktop-only': desktopOnly,
              });

              if (!applicationModes.includes(this.props.applicationMode)) return '';

              const ComponentToRender = externalLink ? 'a' : NavLink;

              return (
                <li key={path + key} className={itemClassNames}>
                  <ComponentToRender
                    {...(externalLink ? { href: path, target: '_blank' } : { to: path })}
                    className={this.getLinkClassNames(path)}
                    activeClassName="NavigationMenu-link active"
                    onClick={this.handleNavItemClick}
                    data-title={title}
                    data-nested={!!children || undefined}
                    isActive={() => this.checkActiveHomeRoute(path, title)}
                  >
                    {Icon && <Icon className={`NavigationMenu-icon ${title}`} />}
                    <div className="NavigationMenu-label">{title}</div>
                    {children && (
                      <ChevronIcon
                        className="NavigationMenu-item-chevron"
                        direction={this.state.dropdownsOpenState[title] ? 'top' : 'bottom'}
                      />
                    )}
                  </ComponentToRender>

                  {children && (
                    <Collapse
                      className="NavigationMenu-list nested"
                      isOpened={this.state.dropdownsOpenState[title]}
                    >
                      {children.map(({ path, Icon, title, key }) => (
                        <div key={path} className="NavigationMenu-item nested">
                          <NavLink
                            to={path}
                            onClick={this.handleCloseSearch}
                            className={this.getLinkClassNames(path)}
                            activeClassName="NavigationMenu-link active"
                            isActive={this.checkActiveCategoryRoute.bind(
                              null,
                              key,
                              this.props.location,
                            )}
                          >
                            {Icon && <Icon className={`NavigationMenu-icon ${title}`} />}
                            <div className="NavigationMenu-label">{title}</div>
                          </NavLink>
                        </div>
                      ))}
                    </Collapse>
                  )}
                </li>
              );
            },
          )}
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = ({
  UI: { applicationMode, menuIsOpen, navMenuNotifications },
  Assets: { activeAsset, activeAssetCategory, isAssetsSearchActive },
}) => ({
  applicationMode,
  menuIsOpen,
  navMenuNotifications,
  activeAsset,
  activeAssetCategory,
  isAssetsSearchActive,
});

const mapDispatchToProps = { toggleMenu, showNavMenuNotification, closeAssetSearchAndClearList };

export default connect(mapStateToProps, mapDispatchToProps)(NavigationMenu);
