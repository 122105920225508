import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ProgressBar from 'components/views/app/Assets/AssetDetails/scenes/MainPage/ProgressBar';
import { Button } from 'components/shared/Buttons/Button';
import { UpCaret } from 'components/shared/Icons/UpCaret';

import './SettingsBlock.css';

class SettingsBlock extends Component {
  static propTypes = {
    expanded: PropTypes.bool, // enables controlled mode
    title: PropTypes.string,
    onExpandCollapseClick: PropTypes.func,
    completedPercentage: PropTypes.number,
  };

  state = { expanded: false };

  onExpandCollapseClick = () => {
    // allow to control 'expanded' state from outside in controled mode:
    if (this.props.onExpandCollapseClick) return this.props.onExpandCollapseClick();
    // otherwise use local state to track 'expanded' state:
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const expanded = this.props.expanded || this.state.expanded;
    const progress = Math.round(this.props.completedPercentage);
    const progressBar = this.props.completedPercentage ? (
      <ProgressBar
        progress={progress}
        message={'info ' + progress + '% complete'}
        width={28}
        height={2.8}
        border={0.1}
        scale="vh"
        backgroundColor="white"
        fontColor="#333"
      />
    ) : null;

    return (
      <div className="SettingsBlock">
        <span className="SettingsBlock-header" onClick={this.onExpandCollapseClick}>
          <span className="SettingsBlock-title">
            <h2>{this.props.title}</h2>
          </span>
          <span className="SettingsBlock-edit">
            <Button text={expanded ? <UpCaret /> : 'Edit'} theme="light" />
          </span>
          {!expanded && <span className="SettingsBlock-progressbar">{progressBar}</span>}
        </span>
        {expanded && <div className="SettingsBlock-contents">{this.props.children}</div>}
      </div>
    );
  }
}

export default SettingsBlock;
