import React from 'react';
import classnames from 'classnames';

import './Error.scss';

/**
 * Helper component for rendering error messages
 * @param  {String | Array} error
 */
const Error = ({ error, capitalized }) => {
  let content;

  const className = classnames('Error', { 'Error-capitalized': capitalized });

  if (!error) return null;

  if (typeof error === 'string') content = error;

  if (Array.isArray(error)) {
    content = error.map((error, index) => <div key={index}>{error.message}</div>);
  }

  if (typeof error === 'object' && Array.isArray(error.errors)) {
    content = error.errors.map((error, index) => <div key={index}>{error.message}</div>);
  }

  return <div className={className}>{content}</div>;
};

export default Error;
