import styled from '@emotion/styled';
import { theme } from 'theme/theme';
import RightArrow from 'images/home/right_arrow.svg';
import { Link } from 'react-router-dom';

const buttonHeight = 27;
const cardWidth = 140;
const mobileMenuHeight = 58.47;

export const HomeWelcomeHeading = styled.div({
  padding: `${theme.spacing.lg}px 0`,
  fontFamily: 'Gotham Medium',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '24px',
  lineHeight: '23px',
  letterSpacing: '-0.02em',
});

export const HomeContainer = styled.div({
  padding: `${theme.spacing.md}px`,
  paddingBottom: `${mobileMenuHeight + theme.spacing.md}px`,
  maxHeight: '79.2vh',
  overflow: 'scroll',
  position: 'relative',
  '.heroes': {
    margin: `${theme.spacing.lg}px 0`,
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column',
    '& a:last-of-type': {
      marginTop: theme.spacing.lg,
      [theme.breakpoints.md]: {
        marginTop: 0,
      },
    },
  },
  [theme.breakpoints.md]: {
    marginBottom: 0,
    padding: `${theme.spacing.lg}px`,
    '.heroes': {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
});

export const HomeBannerHeading = styled.div({
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  lineHeight: '16px',
  fontFamily: 'Gotham Bold',
  letterSpacing: '0px',
  [theme.breakpoints.md]: {
    fontSize: '24px',
    lineHeight: '24px',
  },
});

export const HomeBannerContainer = styled.div<{ show: boolean }>(({ show }) => ({
  padding: `${theme.spacing.lg}px`,
  backgroundColor: 'white',
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25), 0px -1px 4px rgba(0, 0, 0, 0.25)',
  display: show ? 'grid' : 'none',
  gridTemplateColumns: '2fr 1fr',
  gridTemplateRows: 'minmax(max-content, max-content)',
  gridGap: `${theme.spacing.sm}px`,
  position: 'relative',
  marginBottom: `${theme.spacing.lg}px`,
  div: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: `${theme.spacing.xxl}px`,
  },
  [theme.breakpoints.lg]: {
    padding: `${theme.spacing.xl}px`,
  },
}));

export const HomeBannerParagraph = styled.p({
  margin: 0,
  padding: '18px 0',
  fontFamily: 'Gotham Book',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: 12,
  lineHeight: '18px',
  letterSpacing: '-0.17000000178813934px',
  [theme.breakpoints.lg]: {
    fontSize: 14,
    lineHeight: '24px',
  },
});

export const HomeBannerImage = styled.img({
  display: 'inline-block',
  gridColumn: '2/2',
  alignSelf: 'center',
  justifySelf: 'center',
  maxWidth: '100%',
  height: 165,
  objectFit: 'contain',
  [theme.breakpoints.md]: {},
});

export const CloseButton = styled.img({
  position: 'absolute',
  top: 0,
  right: 0,
});

export const CTAButton = styled(Link)<{ show?: boolean; isBanner?: boolean }>(
  ({ show = true, isBanner = false }) => ({
    visibility: show ? 'visible' : 'hidden',
    fontFamily: " 'Muli', sans-serif",
    borderRadius: `${buttonHeight}px`,
    maxHeight: `${buttonHeight}px`,
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: '#53cd7f',
    letterSpacing: 'normal',
    justifyContent: 'center',
    color: 'white',
    padding: '9px 0',
    position: 'relative',
    cursor: 'pointer',
    display: 'inline-flex',
    minWidth: isBanner ? '80%' : '100%',
    marginTop: 'auto',
    [theme.breakpoints.md]: {
      minWidth: '100%',
    },
  }),
);

export const HomeHeroContainer = styled(Link)({
  color: 'inherit',
  '&:focus, &:hover, &:visited, &:link, &:active': {
    textDecoration: 'none',
  },
  minWidth: '100%',
  [theme.breakpoints.lg]: {
    minWidth: 'calc(50% - 4px)',
    maxWidth: 'calc(50% - 4px)',
  },
});

export const Spacer = styled.div<{ width?: string; height?: string }>(
  ({ width = theme.spacing.md, height = theme.spacing.lg }) => ({
    height: `${height}px`,
    width: `${width}px`,
  }),
);

export const HomeHeading = styled.div({
  padding: `${theme.spacing.sm}px 0px`,
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: 16,
  lineHeight: '28px',
  fontFamily: 'Gotham Medium',
});

export const HomeHeroCardContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const HeroCardTimeRemaining = styled.div<{ show: boolean }>(({ show }) => ({
  visibility: show ? 'visible' : 'hidden',
  width: 'Calc(100% + 2px)',
  top: -1,
  left: -1,
  position: 'absolute',
  borderTopLeftRadius: '7px',
  borderTopRightRadius: '7px',
  backgroundColor: theme.colors.paleGreen,
  boxSizing: 'border-box',
  color: 'white',
  height: `${theme.spacing.lg}px`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  p: {
    position: 'relative',
    top: 1,
    fontWeight: 500,
    fontSize: 10,
    fontFamily: 'Gotham Medium',
    lineHeight: 10,
  },
}));

export const HomeHeroCard = styled.div({
  position: 'relative',
  border: `1px solid ${theme.colors.borderGrey}`,
  boxSizing: 'border-box',
  borderRadius: '8px',
  padding: `${theme.spacing.md}px`,
  paddingTop: `${theme.spacing.lg + theme.spacing.md}px`,
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  gridTemplateRows: 'minmax(max-content, max-content)',
  rowGap: `${theme.spacing.md}px`,
  '.left': {
    padding: `0 ${theme.spacing.md}px`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    img: {
      maxWidth: 110,
    },
  },
  '.right': {
    h2: {
      '&:last-of-type': {
        marginTop: `${theme.spacing.sm}px`,
      },
      margin: 0,
      padding: 0,
      marginBottom: `${theme.spacing.xs}px`,
      fontSize: '12px',
    },
    div: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      [theme.breakpoints.md]: {
        alignItems: 'center',
      },
    },
    h1: {
      margin: 0,
      padding: 0,
    },
    img: {
      position: 'relative',
      top: -1,
      marginLeft: `${theme.spacing.xs}px`,
    },
  },
  '.footer': {
    padding: '0 22%',
    gridColumnStart: 1,
    gridColumnEnd: 4,
    gridRowStart: 2,
    gridRowEnd: 3,
  },
  h1: {
    margin: 0,
    padding: 0,
    fontFamily: 'Bebasneue',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: '24px',
    marginBottom: `${theme.spacing.sm}px`,
  },
  h2: {
    margin: 0,
    padding: 0,
    fontFamily: 'Gotham Book',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px',
  },
});

export const HeroCardStatusContainer = styled.div<{ isTrade: boolean }>(({ isTrade }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
  width: '88px',
  height: '16px',
  border: `1px solid ${isTrade ? theme.colors.paleGreen : theme.colors.deepOrange}`,
  marginBottom: `${theme.spacing.sm}px`,
  padding: `0 ${theme.spacing.xs}px`,
  p: {
    top: 1,
    position: 'relative',
    textTransform: 'uppercase',
    fontFamily: 'Gotham Bold',
    fontStyle: 'normal',
    fontSize: '8px',
    lineHeight: '8px',
    color: isTrade ? theme.colors.paleGreen : theme.colors.deepOrange,
  },
}));

export const HeroCardHeading = styled.div({});

export const CarouselContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginRight: `${theme.spacing.md}px`,
  position: 'relative',
  minWidth: '100%',
  '.carousel-heading-container': {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '.carousel-inner-container': {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
    overflowX: 'auto',
    overflowY: 'hidden',
    display: 'flex',
    gridColumnGap: `${theme.spacing.sm}px`,
    marginRight: `${-theme.spacing.md}px`,
    paddingRight: `${theme.spacing.lg}px`,
    [theme.breakpoints.md]: {
      marginRight: `${-theme.spacing.lg}px`,
    },
  },
});

export const CarouselCardImage = styled.div<{ backgroundImage: string }>(({ backgroundImage }) => ({
  marginTop: `${theme.spacing.xs}px`,
  background: `url(${backgroundImage})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  width: '100%',
  height: '100%',
}));

export const CarouselCardContainer = styled(Link)<{ hasDrag: boolean }>(({ hasDrag }) => ({
  cursor: hasDrag ? 'grabbing' : 'pointer',
  height: `${cardWidth}px !important`,
  marginRight: `${theme.spacing.sm}px`,
  maxWidth: '200px',
  minWidth: '200px',
  position: 'relative',
  border: `1px solid ${theme.colors.borderGrey}`,
  boxSizing: 'border-box',
  borderRadius: '8px',
  padding: `${theme.spacing.md}px`,
  paddingBottom: `${theme.spacing.xs}px`,
  display: 'flex',
  flexDirection: 'column',
  img: {
    maxHeight: '72px',
    minHeight: '72px',
    margin: '0 auto',
    marginTop: `${theme.spacing.xs}px`,
  },
  '.price-variation': {
    display: 'flex',
    minWidth: '100%',
    justifyContent: 'flex-start',
    fontSize: '12px',
    p: {
      fontSize: '12px',
    },
    '.price': {
      fontSize: '12px',
      color: theme.colors.greyHome,
    },
  },
  'div span': {
    backgroundColor: 'red',
  },
  'div:last-child': {
    marginRight: `${100}px`,
  },
  h1: {
    margin: 0,
    padding: 0,
    fontFamily: 'Bebasneue',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '16px',
  },
  p: {
    margin: 0,
    padding: 0,
    fontFamily: 'Gotham Medium',
    fontSize: '8px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '14px',
    textAlign: 'left',
  },
  // 'div:nth-child(2)': {
  //   display: 'flex',
  //   alignItems: 'flex-end',
  //   justifyContent: 'flex-start',
  //   width: '100%',
  // },
}));

export const ViewCalendarButton = styled(Link)<{ show: boolean }>(({ show }) => ({
  visibility: show ? 'visible' : 'hidden',
  fontFamily: 'Gotham Medium',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '11px',
  textAlign: 'right',
  letterSpacing: '0em',
  color: theme.colors.darkGrey,
  display: 'flex',
  alignItems: 'center',
}));

export const ViewCalendarImage = styled.img({
  marginLeft: `${theme.spacing.sm}`,
  position: 'relative',
  top: -1.5,
});
ViewCalendarImage.defaultProps = {
  src: RightArrow,
};

export const HeroAssetPricing = styled.p<{ sign: number }>(({ sign }) => ({
  margin: 0,
  padding: 0,
  color: sign >= 0 ? theme.colors.green : theme.colors.red,
  fontFamily: 'Gotham Book',
  fontSize: '8px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '14px',
  textAlign: 'left',
  letterSpacing: '0em',
}));

export const CardPriceVariation = styled.p<{ sign: number; show: boolean }>(({ sign, show }) => ({
  fontSize: '12px',
  marginLeft: `2px !important`,
  // marginRight: `${theme.spacing.md}px !important`,
  visibility: show ? 'visible' : 'hidden',
  color: sign > 0 ? theme.colors.green : theme.colors.red,
}));

export const SoldOutContent = styled.p(({ theme }) => ({
  fontSize: '10px',
  display: 'block',
  margin: 0,
}));

export const SoldOutText = styled.span(({ theme }) => ({
  color: theme.colors.pastelRed,
  fontFamily: 'Gotham bold',
}));
