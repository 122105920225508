import React, { Component } from 'react';
import './LineChart.css';

const X_MULT = 5.4;
const Y_MULT = 2.3;

export class LineChart extends Component {
  defineCanvas() {
    this.canvas = this.refs.lineChartCanvas;
    // clear the canvas:
    // eslint-disable-next-line no-self-assign
    this.canvas.width = this.canvas.width;
    this.ctx = this.canvas.getContext('2d');
    this.ctx.strokeStyle = this.props.lineColor || 'white';
    this.ctx.lineWidth = this.props.lineWidth || 2;
  }

  drawLines() {
    if (this.canvasReady !== true) {
      return;
    }
    this.defineCanvas();
    const { points } = this.props;
    if (typeof points === 'undefined' || points.length < 1) {
      return;
    }
    let firstPoint = points[0];
    let firstX = firstPoint[0];
    let firstY = 100 - firstPoint[1];
    this.ctx.moveTo(firstX * X_MULT, firstY * Y_MULT);
    for (let point of points) {
      let x = point[0];
      let y = 100 - point[1];
      this.ctx.lineTo(x * X_MULT, y * Y_MULT);
    }
    this.ctx.stroke();
  }

  componentDidMount() {
    this.canvasReady = true;
    this.drawLines();
  }
  render() {
    this.drawLines();
    return (
      <div className="LineChart">
        <canvas className="LineChart-canvas" width="540" height="230" ref="lineChartCanvas" />
      </div>
    );
  }
}
