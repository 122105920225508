import React from 'react';
import { SimpleComponent } from 'types/components';

import { RightCaret } from 'components/shared/Icons/RightCaret';

import { CardWrapper, CardWrapperTop, CardWrapperBottom } from './styled';

type CardProps = {
  title?: string;
  subtitle?: string;
  image?: { src?: string; alt?: string; text?: string; };
  text: string;
  buttonText: string;
  caret: boolean;
  onStepForward: () => void;
};

const Card: SimpleComponent<CardProps> = props => {
  return (
    <CardWrapper>
      <CardWrapperTop>
        <div className="topWrapper">
          <div className="title">{props.title}</div>
          <div className="subtitle">{props.subtitle}</div>
          {props.image && (
            <div className="image">
              <img src={props.image.src} alt={props.image.alt} />
              {props.image.text && (
                <div className="imageText" dangerouslySetInnerHTML={{ __html: props.image.text }} />
              )}
            </div>
          )}
        </div>
      </CardWrapperTop>
      <CardWrapperBottom>
        <div className="bottomWrapper">
          <div dangerouslySetInnerHTML={{ __html: props.text }} />
          <div className="button">
            <button
              onClick={() => props.onStepForward()}
            >
              {props.buttonText}
              {props.caret && <RightCaret />}
            </button>
          </div>
        </div>
      </CardWrapperBottom>
    </CardWrapper>
  );
};

export default Card;