import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateInvestmentFlow } from 'actions';
import { YesNoSelect } from 'components/shared/Buttons/YesNoSelect';
import { BackNextButtons } from 'components/shared/Buttons/BackNextButtons';

class FinraOrSec extends Component {
  static propTypes = {
    data: PropTypes.object,
    onFormUpdate: PropTypes.func.isRequired,
    onStepForward: PropTypes.func.isRequired,
    onStepBackward: PropTypes.func.isRequired,
    updateInvestmentFlow: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { isStepValid: this.props.data.isAssociated !== undefined };
    this.initialData = this.props.data;
  }

  validateStep = () => {
    const isStepValid = this.props.data.isAssociated !== undefined;
    this.setState({ isStepValid });
  };

  onChoiceChange = value => {
    const isAssociated = value === 'Yes';
    const updates = { isAssociated };

    // Remove organization name if user is not associated
    if (!isAssociated) {
      updates.sec_or_finra_organization_name = '';
    }

    this.props.onFormUpdate(updates, this.validateStep);
  };

  handleNextClick = () => {
    const { isAssociated } = this.props.data;
    if (!isAssociated) {
      this.props.updateInvestmentFlow({ investment_info: { sec_or_finra_organization_name: '' } });
    }
    this.props.onStepForward();
  };

  handleBackClick = () => {
    if (this.props.resetOnBackPress) {
      const { isAssociated } = this.initialData;
      this.props.onFormUpdate({ isAssociated });
    }
    this.props.onStepBackward();
  };

  render() {
    const {
      data: { isAssociated },
    } = this.props;

    return (
      <div>
        <div>
          <p>
            Are you or anyone in your household associated with a FINRA member, organization, or the
            SEC?
          </p>
          <YesNoSelect
            name="isAssociated"
            onChange={this.onChoiceChange}
            wanted="Yes"
            wantedSelected={isAssociated}
            showColors={false}
          />
        </div>
        <BackNextButtons
          buttons={{ back: { text: 'back' }, next: { text: 'next' } }}
          nextDisabled={!this.state.isStepValid}
          onBackClick={this.handleBackClick}
          onNextClick={this.handleNextClick}
        />
      </div>
    );
  }
}

const mapDispatchToProps = { updateInvestmentFlow };

export default connect(null, mapDispatchToProps)(FinraOrSec);
