import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Popup from 'components/shared/Popup';
import SecondaryButton from 'components/shared/Buttons/SecondaryButton';
import { sendConfirmationEmail } from 'services/UsersAPI';
import analytics from 'services/analytics';

import './VerifyEmailPopup.scss';
import { SEGMENT_ACTIONS, SEGMENT_CATEGORIES } from 'constants/analytics';

class VerifyEmailPopup extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    email: PropTypes.string,
    text: PropTypes.string,
    onRequestClose: PropTypes.func.isRequired,
  };

  state = {
    showSuccessMessage: false,
    showErrorMessage: false,
    isLoading: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.show && prevProps.show !== this.props.show) {
      analytics.track('Verify Email Reminder Screen', {
        category: SEGMENT_CATEGORIES.CREATE_PROFILE,
        action: SEGMENT_ACTIONS.OPEN,
      });
    }
  }

  resendEmail = () => {
    if (this.state.isLoading) return;
    this.setState({ isLoading: true });

    sendConfirmationEmail(this.props.email)
      .then(() => this.setState({ showSuccessMessage: true, isLoading: false }))
      .catch(() => this.setState({ showErrorMessage: true, isLoading: false }));
  };

  renderContent = () => {
    const email = this.props.email ? ', ' + this.props.email : '.';
    const text = 'In order to buy shares on Rally you must first verify your email' + email;

    return (
      <div className="VerifyEmailPopup-content">
        <h2>Please Verify Your Email</h2>
        <p>{this.props.text || text}</p>
        <div className="VerifyEmailPopup-buttons">
          <SecondaryButton
            text="Resend"
            className="VerifyEmailPopup-retry-btn"
            onClick={this.resendEmail}
          />
          <SecondaryButton text="Okay" onClick={this.props.onRequestClose} />
        </div>
      </div>
    );
  };

  renderSuccessMessage = () => (
    <div className="VerifyEmailPopup-content">
      <h2>Please Verify Your Email</h2>
      <p>Verification email was sent successfully to {this.props.email}</p>
      <SecondaryButton text="Okay" onClick={this.props.onRequestClose} />
    </div>
  );

  renderErrorMessage = () => (
    <div className="VerifyEmailPopup-content">
      <h2>Please Verify Your Email</h2>
      <p>Sorry, something went wrong. Please try again later</p>
      <SecondaryButton text="Okay" onClick={this.props.onRequestClose} />
    </div>
  );

  render() {
    const { show, onRequestClose } = this.props;
    let content = this.renderContent();
    if (this.state.showSuccessMessage) content = this.renderSuccessMessage();
    if (this.state.showErrorMessage) content = this.renderErrorMessage();

    return (
      <Popup
        className="VerifyEmailPopup"
        show={show}
        disableAutoClose
        onRequestClose={onRequestClose}
      >
        {content}
      </Popup>
    );
  }
}

export default VerifyEmailPopup;
