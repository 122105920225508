import moment from 'moment-timezone';

const convertTimezoneAbbreviation = (tz: string, gmtTz: string) => {
  switch(tz) {
    case 'ET':
    case 'EST':
    case 'EDT':
      return 'ET';
    case 'CT':
    case 'CST':
    case 'CDT':
      return 'CT';
    case 'MT':
    case 'MST':
    case 'MDT':
      return 'MT';
    case 'PT':
    case 'PST':
    case 'PDT':
      return 'PT';
    case 'AKT':
    case 'AKST':
    case 'AKDT':
      return 'AKT';
    case 'HST':
    case 'HSST':
    case 'HSDT':
      return 'HST';

    default:
      return `GMT${gmtTz}`;
  };
};

export const getTimeInTimezone = (hour: string, useLocalTimezone = false) => {
  const timeToConvert = hour ? moment(hour) : moment();
  const timezone = useLocalTimezone ? moment.tz.guess() : 'America/New_York';
  const tzObj = timeToConvert.tz(timezone);

  return {
    time: tzObj.format('h:mm'),
    timeLeadingZero: tzObj.format('hh:mm'),
    militaryTime: tzObj.format('H:mm'),
    ampm: tzObj.format('A'),
    timezone: convertTimezoneAbbreviation(tzObj.format('z'), tzObj.format('Z')),
    timezoneString: timezone,
  };
};
