import React from 'react';
import { render as rtlRender } from '@testing-library/react';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from 'reducers/RootReducer';
import moxios from 'moxios';

function render(
  ui,
  { initialState, store = createStore(rootReducer, initialState), ...renderOptions } = {},
) {
  function Wrapper({ children }) {
    return <Provider store={store}>{children}</Provider>;
  }
  return rtlRender(ui, { wrapper: Wrapper, ...renderOptions });
}

export * from '@testing-library/react';
export { render };

export const isInTestMode = () => process.env.NODE_ENV === 'test';

export const stubSuccessRequest = (url, response) => {
  moxios.stubRequest(url, {
    status: 200,
    response,
  });
};
