import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CloseButton from 'components/shared/Buttons/CloseButton';
import LazyImage from './LazyImage';

import './LightBox.scss';

/**
 * Lightbox component for displaying media (images and videos)
 */
class LightBox extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    activeIndex: PropTypes.number,
    media: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string.isRequired,
        // Use "video" type for video files, otherwise the image will be rendered
        type: PropTypes.string,
        alt: PropTypes.string,
      }).isRequired,
    ),
    // Callbacks:
    onPrevClick: PropTypes.func,
    onNextClick: PropTypes.func,
    onClose: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isOpen: false,
    activeIndex: 0,
    media: [],
  };

  state = {
    isMediaLoaded: false,
  };

  componentDidMount() {
    if (this.props.isOpen) {
      window.addEventListener('keydown', this.onKeyPress);
    }
  }

  /**
   * Add/Remove keyboard event listeners
   */
  componentWillReceiveProps(nextProps) {
    if (!this.props.isOpen && nextProps.isOpen) {
      window.addEventListener('keydown', this.onKeyPress);
    }
    if (!nextProps.isOpen) {
      window.removeEventListener('keydown', this.onKeyPress);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyPress);
  }

  /**
   * Handle keyboard events to navigate between media items / close lightbox
   */
  onKeyPress = e => {
    const isSingleMedia = this.props.media.length === 1;

    if (e.keyCode === 37) {
      !isSingleMedia && this.onPrevClick(e); // Left
    } else if (e.keyCode === 39) {
      !isSingleMedia && this.onNextClick(e); // Right
    } else if (e.keyCode === 27) {
      this.props.onClose(e); // Esc
    }
  };

  onMediaLoaded = () => this.setState({ isMediaLoaded: true });

  onPrevClick = e => {
    this.setState({ isMediaLoaded: false });
    this.props.onPrevClick(e);
  };

  onNextClick = e => {
    this.setState({ isMediaLoaded: false });
    this.props.onNextClick(e);
  };

  getMediaType = mediaIndex => {
    const mediaItem = this.props.media[mediaIndex];
    if (!mediaItem || !mediaItem.type) return '';

    return mediaItem.type === 'video' ? 'video' : 'image';
  };

  /**
   * Renders single media item (image or video) based on active index of the media array
   */
  renderMediaContent = index => {
    const activeMedia = this.props.media[index];
    if (!activeMedia) return null;

    const { src, alt } = activeMedia;
    const isVideo = this.getMediaType(index) === 'video';

    return isVideo ? (
      <span className="LightBox-video" key={index}>
        <video muted loop controls>
          <source src={src} type="video/mp4" />
        </video>
      </span>
    ) : (
      <LazyImage
        onAfterLoad={this.onMediaLoaded}
        key={index}
        src={src}
        alt={alt ? alt : 'Gallery image'}
        className="LightBox-image"
      />
    );
  };

  render() {
    const { isOpen, activeIndex, media, onClose } = this.props;
    if (!isOpen) return null;

    const isVideo = this.getMediaType(activeIndex) === 'video';
    const isPrevBtnHidden = media.length === 0 || activeIndex === 0;
    const isNextBtnHidden = media.length === 0 || activeIndex === media.length - 1;

    return (
      <div className="LightBox">
        <div className="LightBox-backdrop" onClick={onClose} />
        <button
          className="LightBox-arrow LightBox-arrow-prev"
          onClick={this.onPrevClick}
          style={{ visibility: isPrevBtnHidden ? 'hidden' : 'visible' }}
        >
          &#60;
        </button>
        <div className="LightBox-content">
          <div className="Lightbox-media-container">
            {(this.state.isMediaLoaded || isVideo) && <CloseButton onClick={onClose} />}
            {this.renderMediaContent(activeIndex)}
          </div>
        </div>
        <button
          className="LightBox-arrow LightBox-arrow-next"
          onClick={this.onNextClick}
          style={{ visibility: isNextBtnHidden ? 'hidden' : 'visible' }}
        >
          &#62;
        </button>
      </div>
    );
  }
}

export default LightBox;
