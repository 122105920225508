import React, { Component } from 'react';
import './UpCaret.css';

export class UpCaret extends Component {
  render() {
    return (
      <span className="UpCaret">
        <svg xmlns="http://www.w3.org/2000/svg" width="2048" height="2048" viewBox="0 0 2048 2048">
          <path d="M1523 1312q0 13-10 23l-50 50q-10 10-23 10t-23-10l-393-393-393 393q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l466 466q10 10 10 23z" />
        </svg>
      </span>
    );
  }
}

export default UpCaret;
