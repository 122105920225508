import React from 'react';
import { useSelector } from 'react-redux';
import { MobileSearchOverlay } from './styled';
import AssetsSearchResultList from 'components/views/app/Assets/AssetsMenuList/AssetsSearchResultList';
import withDeviceDetection from 'hoc/withDeviceDetection';

const MobileSearchModal = () => {
  const { isAssetsSearchActive, shouldShowAssetList } = useSelector((store: any) => store.Assets);
  return (
    <MobileSearchOverlay active={isAssetsSearchActive}>
      {shouldShowAssetList && <AssetsSearchResultList isMobileSearch />}
    </MobileSearchOverlay>
  );
};

export default withDeviceDetection(MobileSearchModal);
