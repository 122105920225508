import React from 'react';
import { ModalViewContext } from 'components/shared/Modals/Modal';
import { useContext } from 'react';
import * as uuid from 'uuid';
import AlertModal from 'components/shared/Modals/AlertModal';

type ShowAlert = (
  title: string | JSX.Element,
  message: string | JSX.Element,
  onClose?: () => void,
) => void;

const useAlert = () => {
  const { setModalViewRenderSequence } = useContext(ModalViewContext);

  const showAlert: ShowAlert = (title, message, onClose) => {
    const handleClose = () => {
      if (onClose) return onClose();
    };

    setModalViewRenderSequence([
      {
        state: true,
        id: uuid.v4(),
        modalStyle: {
          overlayBgColor: 'rgba(0,0,0, .7)',
        },
        children: <AlertModal viewId={uuid.v4()} />,
        childrenProps: {
          title,
          message,
        },
        onCloseCompletion: handleClose,
      },
    ]);
  };

  return showAlert;
};

export default useAlert;
