import React from 'react';

const UserIcon = ({ className, style, ...props }) => {
  return (
    <svg
      className={`UserIcon${className ? ` ${className}` : ''}`}
      style={{ fill: 'currentColor', ...style }}
      width={174.144}
      height={185.783}
      {...props}
      viewBox="0 0 174.144 185.783"
    >
      <path d="M.197 184.06c1.647-14.392 5.367-25.86 12.006-37.018 7.564-12.71 20.401-25.136 32.698-31.648 1.254-.664 2.233-1.333 2.177-1.486-.056-.153-1.193-1.24-2.526-2.413-10.258-9.035-17.537-21.79-20.27-35.52-1.123-5.639-1.369-15.204-.536-20.82 2.75-18.534 12.637-34.269 27.904-44.407C62.556 3.508 74.114 0 87.072 0c18.385 0 35.572 7.734 47.886 21.547 2.936 3.294 7.296 9.71 9.255 13.62 7.945 15.855 8.913 35.126 2.589 51.502-3.632 9.405-9.795 18.295-17.21 24.826-1.334 1.174-2.47 2.26-2.527 2.413-.056.153.924.822 2.178 1.486 12.296 6.512 25.134 18.937 32.698 31.648 6.64 11.157 10.359 22.626 12.005 37.017l.198 1.724h-11.909l-.223-2.087c-1.5-13.993-7.503-28.47-16.269-39.226-7.703-9.454-17.322-16.914-27.64-21.44l-3.4-1.491-3.202 1.325c-3.921 1.623-9.018 3.156-13.362 4.018-2.525.502-5.036.649-11.067.649-6.031 0-8.543-.147-11.067-.649-4.344-.862-9.441-2.395-13.362-4.018l-3.201-1.325-3.4 1.491c-12.415 5.444-23.697 15.061-31.903 27.193-2.821 4.171-6.755 12.297-8.52 17.598-1.407 4.225-3.031 11.62-3.487 15.875l-.224 2.087H0zm90.589-68.31c7.39-.612 13.396-2.222 19.327-5.18 28.17-14.046 37.632-49.254 20.307-75.554-15.705-23.84-48.14-30.354-72.195-14.5-19.551 12.886-28.035 37.903-20.422 60.22 6.666 19.543 24.372 33.305 45.028 35.001 1.834.15 3.415.295 3.515.32.1.025 2.098-.113 4.44-.307z" />
    </svg>
  );
};

export default UserIcon;
