import { SimpleComponent } from 'types/components';
import { Popup } from 'components/shared/Popup';
import CloseButton from 'components/shared/Buttons/CloseButton';
import React from 'react';

type HowItWorksPopupProps = {
  show?: boolean;
  history: Record<string, any>;
  handleClose: () => void;
};

const HowItWorksPopup: SimpleComponent<HowItWorksPopupProps> = (props) => {
  return (
    // @ts-ignore
    <Popup className={'HowItWorksPopup'} show={props.show} disableAutoClose fullWidth>
      <div>
        <header>
          <h2 className="title">How It Works</h2>
          <CloseButton onClick={props.handleClose} isHowItWorksPopup />
        </header>
        <section>
          <h3 className="section-title">PLACING A BID</h3>
          <p>
            A "BID" on Rally represents the most you are willing to pay per share for an individual
            asset. You must have a cash balance to place a BID. All BIDs remain active until
            executed or cancelled.
          </p>
        </section>
        <section>
          <h3 className="section-title">PLACING AN ASK</h3>
          <p>
            An "ASK" on Rally represents the price at which you are willing to sell your share of an
            individual asset. You must own shares to place an ASK. All ASKs remain active until
            executed or cancelled.
          </p>
        </section>
        <section>
          <h3 className="section-title">
            LIVE TRADING<span className={'trading-now'}>trading now</span>
          </h3>
          <p>
            Live Trading occurs on Rally during Market Hours from 10:30AM until 4:30PM ET every
            weekday for eligible securities, excluding federal holidays. Any orders placed during
            Market Hours are executed continuously, intraday, based on BIDs meeting or exceeding
            ASKs. Orders that are not executed during Live Trading will remain in the order book as
            "Post-Only" orders until Live Trading is opened again for that asset or until the order
            is cancelled.
          </p>
        </section>
        <section>
          <h3 className="section-title">POST-ONLY "Accepting Orders"</h3>
          <p>
            A BID or ASK can be placed 24 hours per day 7 days per week on any eligible asset, and
            will be honored upon the open of Live Trading for that asset. Once Live Trading opens,
            Post-Only orders are prioritized based on "best price" and time submitted. Upon open, if
            a BID meets an ASK, an order will be executed in real time.
          </p>
        </section>
        <footer>
          For additional information, check out our{' '}
          <a href={'https://rallyrd.com/faq/'} target={'_blank'} rel="noopener noreferrer">
            <button onClick={() => null}>FAQs</button>
          </a>{' '}
          or{' '}
          <a href="mailto:hello@rallyrd.com">
            <button onClick={() => null}>Contact Us</button>
          </a>
        </footer>
      </div>
    </Popup>
  );
};

export default HowItWorksPopup;
