import CONFIG from '../config';
import * as RequestHelper from './RequestHelper';

// appdemo

export function getDocusignUrl(payload) {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/investments/docusign/';
  const token = localStorage.getItem('token');
  let body = JSON.stringify(payload);

  return RequestHelper.postRequestWithoutHandler(endpoint, token, body);
}
