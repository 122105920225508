import React, { Component } from 'react';

export class Price extends Component {
  formatPrice(price) {
    price = Number(price);
    let priceFormatted = price.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    if (priceFormatted.includes('.00') && !this.props.keepZeros) {
      priceFormatted = priceFormatted.substr(0, priceFormatted.indexOf('.'));
    }
    return priceFormatted;
  }

  render() {
    const hasPrice = typeof this.props.price !== 'undefined';
    return <span className="Price">{hasPrice ? this.formatPrice(this.props.price) : ''}</span>;
  }
}
