import React from 'react';

const FaqIcon = ({ className, style, ...props }) => {
  return (
    <svg
      className={`FaqIcon${className ? ` ${className}` : ''}`}
      style={{ fill: 'currentColor', ...style }}
      width={110.301}
      height={99.523}
      {...props}
      viewBox="0 0 110.301 99.523"
    >
      <path d="M54.027 98.845c-.429-.36-2.823-3.384-5.321-6.718-2.847-3.8-4.76-6.122-5.128-6.222-.322-.087-7.558-.156-16.078-.153-14.733.005-15.577-.02-17.198-.522-4.541-1.402-8.454-5.192-9.773-9.464C.009 74.084-.003 73.35 0 43.051.003 9.46-.055 10.879 1.45 7.807c1.092-2.233 3.96-4.933 6.555-6.172 1.199-.572 2.81-1.17 3.582-1.329 2.012-.414 86.79-.405 88.741.01 4.11.872 7.473 3.895 9.234 8.3l.635 1.588.072 31.984c.079 34.77.135 33.273-1.369 36.56-.977 2.135-3.573 4.706-5.93 5.87l-2.004.992-16.473.142c-9.06.079-16.52.198-16.577.265-.058.067-2.264 3.004-4.902 6.527-2.64 3.522-5.03 6.528-5.315 6.68-.979.524-2.824.333-3.671-.38zm6.09-14.028c1.964-2.62 3.837-5.001 4.164-5.292.578-.515 1.066-.533 17.72-.662l17.125-.132 1.298-.802c.993-.615 1.485-1.17 2.095-2.363l.798-1.56V43.031c0-29.356-.025-31.04-.483-32.227-.67-1.735-2.45-3.263-4.234-3.633-.95-.197-14.245-.262-43.437-.213-41.38.07-42.09.08-43.354.6-1.669.687-3.167 1.985-4.075 3.528l-.725 1.235-.072 29.763c-.05 20.737.016 30.185.217 31.153.374 1.804 2.267 3.954 4.281 4.864l1.394.63 17.078.132c15.282.119 17.146.179 17.727.569.357.24 2.376 2.76 4.486 5.6 2.874 3.87 3.91 5.088 4.132 4.856.163-.17 1.902-2.452 3.865-5.071zm-7.732-18.124v-3.44h7.143v6.88h-7.143zm0-12.423c0-5.903 1.471-8.39 8.466-14.307 3.701-3.13 3.998-3.447 4.941-5.285.666-1.296.748-1.73.748-3.969 0-2.295-.07-2.639-.794-3.959-1.39-2.533-4.206-4.102-7.924-4.416-5.73-.484-9.933 2.839-11.047 8.735l-.343 1.814-1.323-.152c-.728-.084-2.304-.233-3.503-.331l-2.18-.179.153-1.086c1.225-8.694 7.105-14.869 14.992-15.744 10.995-1.22 19.315 5.443 19.3 15.458-.008 5.743-1.894 8.949-8.522 14.485-5.465 4.565-5.82 5.1-5.82 8.78v2.524h-7.144z" />
    </svg>
  );
};

export default FaqIcon;
