import React, { Component } from 'react';

import { LeftCaret } from '../Icons/LeftCaret';

import './BackButton.css';

export class BackButton extends Component {
  render() {
    return (
      <div className="Backbutton" onClick={this.props.onClickHandler || this.props.onClick}>
        <span className="Backbutton-chevron">
          <LeftCaret />
        </span>
      </div>
    );
  }
}

export default BackButton;
