import React, { Component } from 'react';
import { connect } from 'react-redux';

import ContentHeader from 'components/shared/ContentHeader';
import PrivacyPage from 'components/shared/Pages/Privacy/PrivacyPage';
import { setPageTitle } from 'utils';
import './Privacy.css';

class Privacy extends Component {
  componentDidMount() {
    setPageTitle('Privacy');
  }

  render() {
    return (
      <div className="Privacy">
        <ContentHeader>PRIVACY POLICY</ContentHeader>
        <div className="Privacy-body">
          <PrivacyPage />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Privacy);
