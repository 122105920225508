import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Button.scss';

/**
 * Button component
 * @param  {String}    theme: 'light' - to apply light theme without background
 * @param  {String}    arrow:  'back'|'left'|'previous' or 'forward'|'right'|'next'
 * @param  {Object}    style:  styles object
 * @param  {String}    className:  additional css classes
 * @param  {Node}      children:  button content
 * @param  {String}    text:  text content (same as 'children' prop)
 */
export const Button = ({ theme, arrow, style, className, children, text, buttonRef, ...props }) => {
  const buttonClasses = classnames('Button', {
    'Button-left-arrow': arrow === 'back' || arrow === 'left' || arrow === 'previous',
    'Button-right-arrow': arrow === 'forward' || arrow === 'right' || arrow === 'next',
    [theme]: theme,
    [className]: className,
  });

  return (
    <button className={buttonClasses} style={style} ref={buttonRef} {...props}>
      {children || text}
    </button>
  );
};

Button.propTypes = {
  theme: PropTypes.string,
  arrow: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
};

Button.defaultProps = {
  text: 'OKAY',
};

export default Button;
