import styled from '@emotion/styled';

const horizontalPadding = '5.8vmin';
const horizontalPaddingMd = '7vmin';

export const InvestmentCalendarContainer = styled.div(({ theme }) => ({
  position: 'relative',
  height: '100%',
  overflow: 'hidden',
  '.Closebutton': {
    height: '6vmin',
    width: '6vmin',
    [theme.breakpoints.md]: {
      height: '3.8vmin',
      width: '3.8vmin',
    },
    '.Closebutton-cross': {
      height: '50%',
      width: '50%',
      color: theme.colors.grey,
    },
  },
  '.investment-tabbar': {
    height: '90%',
  },
  '.investment-tab': {
    width: 'calc(100% - 11.6vmin)',
    margin: '0 5.3vmin',
    boxSizing: 'content-box',
    [theme.breakpoints.md]: {
      width: 'calc(100% - 14vmin)',
      margin: '0 7vmin',
    },
  },
  '.investment-content': {
    padding: `0 ${horizontalPadding}`,
    overflow: 'auto',
    maxHeight: '100%',
    [theme.breakpoints.md]: {
      padding: `0 ${horizontalPaddingMd}`,
    },
  },
  [theme.breakpoints.md]: {
    width: '68%',
    height: '84.2vh',
    float: 'right',
    boxSizing: 'border-box',
  },
}));

export const CalendarSimpleList = styled.div(({ theme }) => ({
  height: '90%',
  padding: `0 ${horizontalPadding}`,
  overflow: 'auto',
  [theme.breakpoints.md]: {
    padding: `0 ${horizontalPaddingMd}`,
  },
}));

export const CalendarTitleContainer = styled.div(({ theme }) => ({
  padding: `0 ${horizontalPadding}`,
  height: '10%',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.md]: {
    padding: `0 ${horizontalPaddingMd}`,
  },
}));

export const CalendarTitle = styled.h3(({ theme }) => ({
  fontSize: '3.8vmin',
  textTransform: 'uppercase',
  margin: '0',
  fontWeight: 'normal',
  fontFamily: 'Gotham Book',
  [theme.breakpoints.md]: {
    fontSize: '2vmin',
  },
}));
