import { createActions } from 'redux-actions';
import * as DwollaAPI from 'services/DwollaAPI';
import { getTradingWindow } from 'services/TradingAPI';
import * as InvestmentsAPI from 'services/InvestmentsAPI';
import analytics from 'services/analytics';
import { getUserData, setDwollaCustomerStatus, setDwollaCustomerError } from 'actions/user';
import { getMerchandiseProductDataAndUpdate } from 'actions/assets';
import { showNavMenuNotification } from 'actions/ui';
import { getAssetDetails } from 'actions/assets';
import {
  SET_BALANCE,
  SET_INVESTMENTS,
  SET_GAIN_LOSS,
  SET_RECEIPTS,
  SET_BANK_FUNDING_SOURCE,
  DELETE_BANK_FUNDING_SOURCE,
  SET_INVESTMENT_ERROR,
  SET_GIFT_CODE_APPLIED,
  REMOVE_GIFT_CODE_APPLIED,
  SET_TRADING_WINDOW,
  SET_LOADING_TRADING_WINDOW,
  SET_BANK_FUNDING_SOURCE_ERROR,
  SET_BANK_FUNDING_SOURCE_LOADING,
  SET_AGREEMENT_TIMESTAMP,
  SET_INVESTMENT_FLOW,
  SET_POSITIONS,
} from 'actions/types';
import Bugsnag from '@bugsnag/js';

// === Action creators: ===

export const {
  setBalance,
  setInvestments,
  setGainLoss,
  setReceipts,
  setBankFundingSource,
  setInvestmentError,
  setGiftCodeApplied,
  removeGiftCodeApplied,
  setBankFundingSourceError,
  setBankFundingSourceLoading,
  setPositions,
} = createActions(
  SET_BALANCE,
  SET_INVESTMENTS,
  SET_GAIN_LOSS,
  SET_RECEIPTS,
  SET_BANK_FUNDING_SOURCE,
  SET_INVESTMENT_ERROR,
  SET_GIFT_CODE_APPLIED,
  REMOVE_GIFT_CODE_APPLIED,
  SET_BANK_FUNDING_SOURCE_ERROR,
  SET_BANK_FUNDING_SOURCE_LOADING,
  SET_POSITIONS,
);

// === Thunks: ===

export const getBalance = () => async dispatch => {
  try {
    const balance = await DwollaAPI.getBalance();
    analytics.identify({ balance });
    dispatch(setBalance(balance));
  } catch (err) {
    if (err.message && err.message.includes('state is not available')) return err;
  }
};

export const getFinancialInfo = () => async dispatch => {
  try {
    const dwollaCustomer = await DwollaAPI.getDwollaCustomerStatus();
    if (dwollaCustomer.customer_exists && dwollaCustomer.status !== 'not-created') {
      dispatch(getBankFundingSource());
      dispatch(getBalance());
    }
  } catch (err) {
    if (err.message && err.message.includes('state is not available')) return err;
  }
};

export const getInvestments = () => async (dispatch, getState) => {
  try {
    const investments = await InvestmentsAPI.getInvestments();
    dispatch(setInvestments(investments));
  } catch (err) {
    console.log(err);
  }
};

export const getGainLoss = () => async dispatch => {
  try {
    const gainLoss = await InvestmentsAPI.getGainLoss();
    dispatch(setGainLoss(gainLoss));
  } catch (err) {
    console.log('gain loss error', err);
  }
};

export const getReceipts = () => async dispatch => {
  try {
    const receipts = await DwollaAPI.getTransfers();
    dispatch(setReceipts(receipts));
  } catch (err) {
    console.log('receipts fetching error', err);
  }
};

export const getBankFundingSource = () => async dispatch => {
  dispatch(setBankFundingSourceLoading(true));

  try {
    const bankFundingSource = await DwollaAPI.getBankFundingSource();
    dispatch(setBankFundingSource(bankFundingSource));
  } catch (err) {
    console.log('funding source error', err);
    dispatch(setBankFundingSourceError(err?.[0]?.message));
  }
};

export const makeTransfer = transferData => async dispatch => {
  try {
    const balance = await DwollaAPI.makeTransfer(transferData);
    analytics.track('Transfer Funds', { category: 'Transfer', action: transferData.type });
    dispatch(setBalance(balance));
    dispatch(getReceipts());
  } catch (err) {
    console.log('transfer creation error', err);
  }
};

export const getDwollaCustomerStatus = () => async dispatch => {
  try {
    const status = await DwollaAPI.getDwollaCustomerStatus();
    dispatch(setDwollaCustomerStatus(status));
  } catch (err) {
    console.log('dwolla customer status error', err);
  }
};

export const createDwollaCustomer = userData => async dispatch => {
  try {
    const status = await DwollaAPI.createDwollaCustomer(userData);
    dispatch(setDwollaCustomerStatus(status));
  } catch (err) {
    dispatch(setDwollaCustomerError(err.message));
  }
};

export const startInvestments = assetId => async (dispatch, getState) => {
  try {
    const ioData = await InvestmentsAPI.startInvestments({ asset: assetId });
    if (ioData.data && ioData.data.merchandising && ioData.data.available_shares > 0) {
      dispatch(getMerchandiseProductDataAndUpdate(ioData.data.merchandising));
    }
    return ioData.data;
  } catch (err) {
    console.log(err);
  }
};

export const createInvestment = investmentData => async dispatch => {
  try {
    const investmentResult = await InvestmentsAPI.createInvestment(investmentData);
    await dispatch(getUserData({ showGlobalLoader: false }));
    dispatch(showNavMenuNotification('/app/investments'));
    dispatch(getAssetDetails(investmentData.asset, { showLoadingOverlay: false }));

    return investmentResult.data;
  } catch (err) {
    throw err;
  }
};

export const createBankFundingSource = fundingSourceData => async dispatch => {
  dispatch(setBankFundingSourceLoading(true));

  if (fundingSourceData.usingPlaid) {
    const payload = {
      public_token: fundingSourceData.token,
      metadata: fundingSourceData.metadata,
    };
    const bankFundingSource = await DwollaAPI.createFundingSourceUsingPlaid(payload);
    await dispatch(setBankFundingSource(bankFundingSource.bank_funding_source));
  } else {
    const payload = {
      routingNumber: fundingSourceData.routingNumber,
      accountNumber: fundingSourceData.accountNumber,
      type: fundingSourceData.type,
      name: fundingSourceData.name,
    };
    try {
      const bankFundingSource = await DwollaAPI.createFundingSourceUsingRouting(payload);
      await dispatch(setBankFundingSource(bankFundingSource));
      analytics.track('Funding Source Added', {
        category: 'Funding Source',
        action: 'Bank Added',
        type: fundingSourceData.usingPlaid ? 'plaid' : 'manual',
      });
    } catch (e) {
      if (e.length) {
        dispatch(setBankFundingSourceError(e[0].message));
        throw e[0].message;
      }
    }
  }
};

export const deleteBankFundingSource = () => async dispatch => {
  await DwollaAPI.deleteFundingSource();
  dispatch({ type: DELETE_BANK_FUNDING_SOURCE });
};

export const checkMicroDeposits = microdepositsData => async dispatch => {
  try {
    await DwollaAPI.checkMicroDeposits(microdepositsData);
    dispatch(getBankFundingSource());
  } catch (err) {
    dispatch(setInvestmentError(err));
  }
};

export const setTradingWindows =
  (tradingWindowIds = []) =>
  async dispatch => {
    dispatch({ type: SET_LOADING_TRADING_WINDOW, payload: true });
    const fetchWindow = id =>
      getTradingWindow(id).then(payload =>
        dispatch({ type: SET_TRADING_WINDOW, tradingWindowId: id, payload }),
      );
    Promise.all(tradingWindowIds.map(fetchWindow)).then(() =>
      dispatch({ type: SET_LOADING_TRADING_WINDOW, payload: false }),
    );
  };

export const setAgreementTimestamp = timestamp => dispatch => {
  dispatch({ type: SET_AGREEMENT_TIMESTAMP, payload: timestamp });
};

export const updateInvestmentFlow = payload => dispatch => {
  dispatch({ type: SET_INVESTMENT_FLOW, payload });
};

export const getUserPositions = () => async (dispatch, getState) => {
  const accountId = getState().Auth.user.customer.accounts?.[0]?.id ?? '';
  try {
    const response = await InvestmentsAPI.getPositions(accountId);
    const positions = {};
    const { data } = response;
    for (let key in data) {
      const position = data[key];
      const { id } = position.financialInstrument;
      positions[id] = position.long;
    }
    dispatch(setPositions(positions));
  } catch (error) {
    Bugsnag.notify(error);
  }
};
