import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BackNextButtons } from 'components/shared/Buttons/BackNextButtons';
import PlaidLink from 'react-plaid-link';
import CONFIG from 'config';

import { createBankFundingSource } from 'actions';
import './ConnectBank.css';

class ConnectBank extends Component {
  static propTypes = {
    onFormUpdate: PropTypes.func.isRequired,
    onStepForward: PropTypes.func.isRequired,
    onStepBackward: PropTypes.func.isRequired,
  };

  onPlaidSuccess = (token, metadata) => {
    this.props.createBankFundingSource({
      token: token,
      metadata: metadata,
      usingPlaid: true,
    });

    this.props.onFormUpdate(
      {
        fundingSourceCreated: true,
        isSmallDepositNeeded: false,
      },
      this.props.onStepForward,
    );
  };

  onPlaidExit = (error, metadata) => {
    console.log('link: user exited');
    console.log(error, metadata);
  };

  render() {
    return (
      <div className="ConnectBank">
        <h2>Choose a way to connect your bank</h2>
        <PlaidLink
          className="ConnectBank-choice"
          style={{}}
          clientName="Plaid Client"
          selectAccount={true}
          env={CONFIG.plaidEnv}
          product={['auth']}
          publicKey={CONFIG.plaidKey}
          apiVersion="v2"
          onSuccess={this.onPlaidSuccess}
          onExit={this.onPlaidExit}
        >
          <h2>Sign Into your Bank </h2>
          <p>
            If you bank online, you can <span>purchase</span> shares by securely logging in now.
          </p>
        </PlaidLink>
        <button className="ConnectBank-choice" onClick={() => this.props.onStepForward()}>
          <h2>Enter Routing Number</h2>
          <p>Once the account is verified you'll be able to purchase shares (1-2 business days).</p>
        </button>
        <BackNextButtons
          buttons={{ back: { text: 'back' }, style: 'centered' }}
          onBackClick={() => this.props.onStepBackward()}
        />
      </div>
    );
  }
}

const mapDispatchToProps = { createBankFundingSource };

export default connect(
  null,
  mapDispatchToProps,
)(ConnectBank);
