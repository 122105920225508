const amountToShow = (type: string) => {
  switch (type) {
    case 'free_share_program':
      return 1;
    
    case 'early_io':
      return 1;

    default:
      return 3;
  }
};

interface itemArgs {
  userId: string,
  assetId: string,
  assetType: string
}

const setItem = ({ userId, assetId, assetType }: itemArgs) => {
  const userClosedSaPopup = localStorage.getItem('user_closed_sa_popup') || '{}';
  const parsedSaItem = JSON.parse(userClosedSaPopup);
  const hasAmount = parsedSaItem?.[userId]?.[`${assetId}-${assetType}`] >= 0;

  const userSaPopupObj = {
    ...parsedSaItem,
    [userId]: {
      ...parsedSaItem?.[userId],
      [`${assetId}-${assetType}`]:
        (hasAmount ? parsedSaItem[userId][`${assetId}-${assetType}`] : amountToShow(assetType)) - 1,
    },
  };

  localStorage.setItem('user_closed_sa_popup', JSON.stringify(userSaPopupObj));
};

const increaseAmount = ({ userId, assetId, assetType }: itemArgs, amount: number = 1) => {
  const userClosedSaPopup = localStorage.getItem('user_closed_sa_popup') || '{}';
  const parsedSaItem = JSON.parse(userClosedSaPopup);
  const shouldIncrease = parsedSaItem?.[userId]?.[`${assetId}-${assetType}`] === 0;

  if (shouldIncrease) {
    const userSaPopupObj = {
      ...parsedSaItem,
      [userId]: {
        ...parsedSaItem?.[userId],
        [`${assetId}-${assetType}`]: parsedSaItem[userId][`${assetId}-${assetType}`] + amount
      },
    };
  
    localStorage.setItem('user_closed_sa_popup', JSON.stringify(userSaPopupObj));
  }
};

const shouldShow = ({ userId, assetId, assetType }: itemArgs) => {
  const userClosedSaPopup = localStorage.getItem('user_closed_sa_popup') || '{}';
  const parsedSaItem = JSON.parse(userClosedSaPopup);
  const hasAmount = parsedSaItem?.[userId]?.[`${assetId}-${assetType}`] >= 0;

  return hasAmount ? parsedSaItem[userId][`${assetId}-${assetType}`] > 0 : true;
};

export { setItem, increaseAmount, shouldShow };
