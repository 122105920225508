import React, { Component } from 'react';
import PropTypes from 'prop-types';

import googleLogo from 'images/google-logo.svg';
import appleLogo from 'images/apple-logo-black.svg';
import outlookLogo from 'images/outlook-logo.svg';

import AddToCalendarIcon from 'components/shared/Icons/AddToClanedar';

import './AddToCalendarButton.scss';

class AddToCalendarButton extends Component {
  static propTypes = {
    startDate: PropTypes.object, // Date() object
    endDate: PropTypes.object, // Date() object
    title: PropTypes.string,
    description: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      showOptionsList: props.showOptions || false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.showOptions !== state.showOptions) {
      return {
        showOptionsList: props.showOptions,
      };
    }
    return null;
  }

  calendarURIGenerator = {
    google: event => {
      return encodeURI(
        [
          'https://www.google.com/calendar/render',
          '?action=TEMPLATE',
          '&text=' + (event.title || ''),
          '&dates=' + (this.formatDate(event.startDate) || ''),
          '/' + (this.formatDate(event.endDate) || ''),
          '&details=' + (event.description || ''),
          '&location=https://app.rallyrd.com/',
          '&sprop=&sprop=name:',
        ].join(''),
      );
    },
    ics: event => {
      return encodeURI(
        'data:text/calendar;charset=utf8,' +
          [
            'BEGIN:VCALENDAR',
            'VERSION:2.0',
            'BEGIN:VEVENT',
            'URL:' + document.URL,
            'DTSTART:' + (this.formatDate(event.startDate) || ''),
            'DTEND:' + (this.formatDate(event.endDate) || ''),
            'SUMMARY:' + (event.title || ''),
            'DESCRIPTION:' + (event.description || ''),
            'LOCATION:https://app.rallyrd.com/',
            'END:VEVENT',
            'END:VCALENDAR',
          ].join('\n'),
      );
    },
  };

  componentDidMount() {
    window.addEventListener('click', this.hideOptionsList, true);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.hideOptionsList);
  }

  showOptionsList = e => {
    if (this.props.onShowOptions) {
      this.props.onShowOptions();
      return;
    }
    this.setState({ showOptionsList: true });
  };

  hideOptionsList = e => {
    if (this.props.onHideOptions) {
      this.props.onHideOptions();
      return;
    }
    this.state.showOptionsList && this.setState({ showOptionsList: false });
  };

  formatDate(date) {
    return date.toISOString().replace(/-|:|\.\d+/g, '');
  }

  render() {
    const event = this.props;
    return (
      <div className="AddToCalendarButton">
        <button
          ref={el => (this.showOptionsBtn = el)}
          className="AddToCalendarButton-btn"
          onClick={this.showOptionsList}
          title="Add To Calendar"
        >
          <AddToCalendarIcon fill={this.props.color} />
        </button>

        {this.state.showOptionsList && (
          <div className="AddToCalendarButton-options">
            <div className="AddToCalendarButton-options-title">Add to calendar:</div>
            <a
              className="AddToCalendarButton-option"
              href={this.calendarURIGenerator.google(event)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={googleLogo} alt="Google Calendar" />
              Google Calendar
            </a>
            <a
              className="AddToCalendarButton-option"
              href={this.calendarURIGenerator.ics(event)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={appleLogo} alt="Apple" />
              iCalendar
              <small>(get ICS file)</small>
            </a>
            <a
              className="AddToCalendarButton-option"
              href={this.calendarURIGenerator.ics(event)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={outlookLogo} alt="Outlook" />
              Outlook
              <small>(get ICS file)</small>
            </a>
          </div>
        )}
      </div>
    );
  }
}

export default AddToCalendarButton;
