import React, { Component } from 'react';

import { Checkmark } from '../Icons/Checkmark';

import './Checkbox.css';

export class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    let value = e.target.value || '';
    this.props.onChange(value);
  }

  render() {
    const { size, name, id, checked, type, ...rest } = this.props;
    const displayCheckmark = checked ? 'shown' : 'hidden';
    return (
      <span className={'Checkbox ' + size}>
        <input
          className={`Checkbox-input${type ? ` Checkbox-input--${type}` : ''}`}
          type="checkbox"
          name={name}
          id={id || name}
          onChange={this.handleChange}
          checked={checked}
          {...rest}
        />
        <span className={'Checkbox-checkmark ' + displayCheckmark}>
          <Checkmark />
        </span>
      </span>
    );
  }
}

export default Checkbox;
