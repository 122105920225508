export const hasInvalidLength = (value, fieldName, min = 0, max = undefined) => {
  let error = false;
  const fieldValue = value.toString();
  const minLength = Number(min);
  const maxLength = Number(max);

  if (minLength && fieldValue.length < minLength)
    error = `${fieldName} should have at least ${minLength} characters`;
  if (maxLength && fieldValue.length > maxLength)
    error = `${fieldName} should have less than ${maxLength} characters`;

  return error;
};
