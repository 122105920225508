import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import MultistepForm from 'components/shared/MultistepForm';
import FormCloseModal from 'components/shared/FormCloseModal';
import { OnboardingIntro, PassportSteps } from 'components/shared/MultistepForm/steps/profile';
import { Fullpage } from 'components/shared/Fullpage';
import { CloseButton } from 'components/shared/Buttons/CloseButton';
import { getBankFundingSource } from 'actions';
import analytics from 'services/analytics';
import { ACCOUNT_STATUS, PASSPORT_SECTION } from 'constants/main';

import './Onboarding.css';

class Onboarding extends Component {
  constructor(props) {
    super(props);

    const initialFormData = this.setInitialFormData();

    this.state = {
      showWarningModal: false,
      signupOnboardingFlow:
        this.props.location.state && this.props.location.state.signupOnboardingFlow,
      isUserStateInfo: !!initialFormData.state,
      ...initialFormData,
    };
  }

  steps = [];

  componentDidMount() {
    this.props.getBankFundingSource();
  }

  setInitialFormData = () => {
    const { user } = this.props;
    const initialFormData = {
      first_name: user.first_name || '',
      last_name: user.last_name || '',
      phone_number: user.phone_number || '',
      date_of_birth: user.date_of_birth || '',
      address: user.address || '',
      apt: user.address2 || '',
      city: user.city || '',
      state: user.state || '',
      postal_code: user.postal_code || '',
      ssn: user.ssn || '',
      agreed_with_DriveWealth_TOS:
        user.customer?.accounts?.[0]?.status !== ACCOUNT_STATUS.ENROLLMENT_PENDING ?? false,
      agreedWithTos3: false,
      agreeAndContinue:
        user.customer?.accounts?.[0]?.status === ACCOUNT_STATUS.ENROLLMENT_PENDING ?? false,
      isSmallDepositNeeded: false, // verification via micro deposits
      fundingSourceCreated: false, // funding source was created with plaid
      transfer_amount: 0,
    };

    return initialFormData;
  };

  handleFormClose = () => {
    this.props.history.push({
      pathname: '/app',
      state: {
        signupOnboardingFlow: this.state.signupOnboardingFlow,
      },
    });
  };

  /**
   * Updates form data in component's state
   * This is async operation! Use callback if needed
   * @param  {Object}   updates  - updates object
   * @param  {Function} callback - callback function (optional)
   */
  handleFormUpdate = (updates, callback) => {
    this.setState(
      state => ({
        ...state,
        ...updates,
      }),
      () => callback && callback(),
    );
  };

  onStepChange = ({ nextStepIndex: index }) => {
    const { analyticsEventName } = this.steps[index];
    if (analyticsEventName) {
      analytics.track(analyticsEventName, { category: 'Create Profile', action: 'Open' });
    }
  };

  showWarningModal = () => this.setState({ showWarningModal: true });

  hideWarningModal = () => this.setState({ showWarningModal: false });

  render() {
    const { history, user } = this.props;
    const initialStepIndex = history.location.state && history.location.state.step;

    this.steps = [
      {
        path: '/',
        render: OnboardingIntro,
      },
      ...PassportSteps({
        isUserStateInfo: this.state.isUserStateInfo,
        user,
        handleFormClose: this.handleFormClose,
      }),
      {
        path: '/fund-account',
        render: () => (
          <Redirect
            to={{
              pathname: '/app/fund-account',
              state: {
                signupOnboardingFlow: this.state.signupOnboardingFlow,
              },
            }}
          />
        ),
      },
    ];

    return (
      <div>
        <Fullpage>
          <div className="Onboarding">
            <CloseButton onClickHandler={this.showWarningModal} />
            <div className="Onboarding-wrapper">
              <MultistepForm
                formMode="profile"
                rootUrl="/app/onboarding"
                section={PASSPORT_SECTION.ONBOARDING}
                initialStepIndex={initialStepIndex}
                steps={this.steps}
                onFormClose={this.handleFormClose}
                onFormUpdate={this.handleFormUpdate}
                onStepChange={this.onStepChange}
                data={this.state}
              />
            </div>
          </div>
        </Fullpage>

        <FormCloseModal
          visible={this.state.showWarningModal}
          onAccept={this.hideWarningModal}
          onDecline={() =>
            this.props.history.push({
              pathname: '/app',
              state: {
                signupOnboardingFlow: this.state.signupOnboardingFlow,
              },
            })
          }
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.Auth.user,
});

const mapDispatchToProps = { getBankFundingSource };

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
