import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import preval from 'preval.macro';

import { isRunningOnRallyAndroidClient, getClientVersionNumber } from 'utils';

const BuildNumberText = styled.div`
  padding: 7px 17px;
  font-size: 11px;
  position: absolute;
  top: 0;
`;

const AppBuildNumber = () => {
  const getBuildNumberString = () => {
    const buildTimestamp = preval`module.exports = new Date().getTime();`;
    const formattedBuildTimestamp = moment.unix(buildTimestamp / 1000).format('YYYYMMDD. HHmmss');

    return isRunningOnRallyAndroidClient()
      ? `(${formattedBuildTimestamp}) android_${getClientVersionNumber()}`
      : `(${formattedBuildTimestamp})`;
  };

  return (
    <BuildNumberText>
      {process.env.REACT_APP_NAME} {process.env.REACT_APP_VERSION} {getBuildNumberString()}
    </BuildNumberText>
  );
};

export default AppBuildNumber;
