import { colors } from './colors';
import { breakpoints } from './breakpoints';
import { spacing } from './spacing';

export const theme = {
  spacing,
  colors,
  breakpoints,
};

export type RallyTheme = typeof theme;
