import React, { useEffect, useState } from 'react';
import parse from 'parse-color';
import moment from 'moment';
import { getSecurityHistoryPricing } from 'actions';
import AssetDetailsPage from '../Common/AssetDetailsPage';
import TickerPrice from 'components/shared/TickerPrice';
import TimelineChart from 'components/shared/TimelineChart';
import { Price } from 'components/shared/Price';
import { TabsContainer, TabItem } from './styled';
import { useTracking } from 'hooks/useTracking';
import isEqual from 'lodash.isequal';

import './AssetValueChart.css';
import { useDispatch } from 'react-redux';

export type TABSType = {
  TRADING_ON_RALLY: 'TRADING_ON_RALLY';
  SIMILAR_ASSET_SALE: 'SIMILAR_ASSET_SALE';
};

export const TABS: TABSType = {
  TRADING_ON_RALLY: 'TRADING_ON_RALLY',
  SIMILAR_ASSET_SALE: 'SIMILAR_ASSET_SALE',
};

export type TABSTypeUnion = TABSType['TRADING_ON_RALLY'] | TABSType['SIMILAR_ASSET_SALE'];

type IntervalType = {
  name: string;
  timeDiff: moment.Moment;
  index: number;
};

const PointInfo = (props: {
  selectedData: any;
  noPointSelected: boolean;
  since: number | string;
  chartType: TABSTypeUnion;
  assetNumberOfShares: number;
}) => {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  let assetValue = formatter.format(
    Math.round(Number(props?.selectedData?.value) * props?.assetNumberOfShares),
  );

  return props.chartType === TABS.TRADING_ON_RALLY ? (
    <div className={'AssetValueChart-pointInfo'}>
      <div className="AssetValueChart-pointInfo-source">{props.selectedData.source}</div>
      <div className="AssetValueChart-pointInfo-value">
        <Price price={!props.noPointSelected ? props.selectedData.value : undefined} />
        <span
          className="AssetValueChart-pointInfo-since"
          style={{
            backgroundColor: '#474B4A',
            marginLeft: '15px',
            padding: '2px 5px',
            visibility: props?.selectedData?.value ? 'visible' : 'hidden',
          }}
        >
          {assetValue}
        </span>
      </div>
    </div>
  ) : (
    <div className={'AssetValueChart-pointInfo'}>
      <div className="AssetValueChart-pointInfo-source">{props.selectedData.source}</div>
      <div className="AssetValueChart-pointInfo-value">
        <Price price={!props.noPointSelected ? props.selectedData.value : undefined} />
        <span className="AssetValueChart-pointInfo-since">
          {!props.noPointSelected ? ` (${props.since}% since)` : ``}
        </span>
      </div>
    </div>
  );
};

const AssetValueChart = (props: any) => {
  const { activeAsset: asset, applicationMode } = props;
  const [activeChartPoint, setActiveChartPoint] = useState(0);
  const [activeTab, setActiveTab] = useState<TABSTypeUnion>(TABS.SIMILAR_ASSET_SALE);
  const [currentAsset, setCurrentAsset] = useState<any>({});

  const [intervals] = useState<IntervalType[]>([
    { name: '1M', timeDiff: moment().subtract(1, 'month'), index: 0 },
    { name: '3M', timeDiff: moment().subtract(3, 'month'), index: 1 },
    { name: '6M', timeDiff: moment().subtract(6, 'month'), index: 2 },
    { name: '1Y', timeDiff: moment().subtract(1, 'year'), index: 3 },
    { name: 'ALL', timeDiff: moment(), index: 4 },
  ]);
  const [activeInterval, setActiveInterval] = useState<any>(intervals[4]);

  const [chartPoints, setChartPoints] = useState<any[]>([]);
  const [allChartPoints, setAllChartPoints] = useState<any[]>([]);

  const setHistoryPricingToChartPoints = (items: any) => {
    if (!items.length) return [];
    const newItems = items.map((item: any) => {
      return {
        date: moment(item?.date).format('YYYY-MM-DD'),
        value: item?.pricePerUnit?.close,
      };
    });
    return newItems;
  };

  useEffect(() => {
    if (activeTab === TABS.TRADING_ON_RALLY) {
      if (!asset?.trading?.historyPricing) return;
      const { date, pricePerUnit } =
        asset?.trading?.historyPricing?.financialInstrument?.issue ?? {};

      const IOAssetDetails = { date, pricePerUnit: { close: pricePerUnit } };

      asset?.trading?.historyPricing &&
        setAllChartPoints(
          asset?.trading?.historyPricing?.dataPoints
            ? setHistoryPricingToChartPoints([
                IOAssetDetails,
                ...(asset?.trading?.historyPricing?.dataPoints ?? []),
              ])
            : setHistoryPricingToChartPoints([
                ...(asset?.trading?.historyPricing?.dataPoints ?? []),
              ]),
        );
    } else {
      setAllChartPoints(asset?.history_value_points);
    }
    // eslint-disable-next-line
  }, [activeTab, asset?.trading?.historyPricing?.dataPoints]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!asset) return;
    //fix to stop circular dispatching
    if (isEqual(currentAsset, asset)) return;
    setCurrentAsset(asset);
    dispatch(getSecurityHistoryPricing(asset?.financialInstrument?.id));
    // eslint-disable-next-line
  }, [asset]);

  useEffect(() => {
    setChartPoints(
      activeInterval?.name === 'ALL'
        ? allChartPoints
        : allChartPoints.filter((i: any) => moment(i.date).isAfter(activeInterval.timeDiff)) ?? [],
    );
  }, [activeInterval, allChartPoints]);

  useEffect(() => {
    setActiveInterval(intervals[4]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTotalAssetValueIncrease = (transactions: any) => {
    const firstTransaction = transactions[0];
    const lastTransaction = transactions[transactions.length - 1];
    const firstValue = Math.max(firstTransaction?.value, 1);
    const lastValue = Math.max(lastTransaction?.value, 1);
    return getAssetValueIncreaseFixed(lastValue, firstValue);
  };

  const getAssetValueIncreaseFixed = (lastValue: number, pastValue: number) => {
    const assetValueIncrease = (lastValue / pastValue - 1) * 100;
    let assetValueIncreaseFixed: number | string = Math.round(assetValueIncrease * 10) / 10;
    if (assetValueIncreaseFixed > 0) {
      assetValueIncreaseFixed = '+' + String(assetValueIncreaseFixed);
    }
    return assetValueIncreaseFixed;
  };

  const Tabs = () => {
    return (
      <TabsContainer>
        <div className={'tabs-container-inner'}>
          <TabItem
            isActive={activeTab === TABS.SIMILAR_ASSET_SALE}
            onClick={() => setActiveTab(TABS.SIMILAR_ASSET_SALE)}
          >
            SIMILAR ASSET SALES
          </TabItem>
          <TabItem
            isActive={activeTab === TABS.TRADING_ON_RALLY}
            onClick={() => setActiveTab(TABS.TRADING_ON_RALLY)}
          >
            TRADING ON RALLY
          </TabItem>
        </div>
        {activeTab === TABS.SIMILAR_ASSET_SALE ? (
          <p>Prices are not adjusted for inflation</p>
        ) : (
          <p></p>
        )}
      </TabsContainer>
    );
  };

  const assetValue = asset?.current_value || asset?.asset_value;
  const transactions = chartPoints?.length;
  const assetValueIncrease = chartPoints?.length && getTotalAssetValueIncrease(chartPoints);
  const selectedData = chartPoints?.[activeChartPoint] || {};

  const noPointSelected = typeof chartPoints?.[activeChartPoint] === 'undefined';
  // We use the asset's last comp value as the basis for the percent change in the graph.
  const lastChartPointValue = chartPoints?.[chartPoints?.length - 1]?.value;
  const pointDate = selectedData.date ? moment(selectedData.date).format('M/D/YY') : '';
  const backgroundColor = `rgba(${parse(asset?.tertiary_color)?.rgb?.join(',')}, 1)`;

  const since = getAssetValueIncreaseFixed(lastChartPointValue, selectedData.value);

  const formatDate = () => {
    if (!chartPoints || chartPoints[0] === undefined) return '';
    const date = chartPoints[0]?.date;
    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
  };

  const {
    chartTracking: { useChartViewed, chartTimeframeTapped, useChartValueTapped },
  } = useTracking();
  useChartViewed({
    assetId: asset?.id,
    chartType: activeTab,
    timeFrame: activeInterval.name,
    valueChange: chartPoints?.length && getTotalAssetValueIncrease(chartPoints),
    transactions: chartPoints?.length,
  });
  useChartValueTapped({
    assetId: asset?.id,
    activeChartPointValue: chartPoints?.[activeChartPoint],
    chartType: activeTab,
    timeFrame: activeInterval.name,
    transactions: chartPoints?.length,
    sinceIO: since,
    valueChange: chartPoints?.length && getTotalAssetValueIncrease(chartPoints),
  });

  return (
    <div
      id="AssetValueChart"
      className={`AssetValueChart ${applicationMode}`}
      style={{ background: backgroundColor }}
    >
      <AssetDetailsPage asset={asset} {...props}>
        <TickerPrice ticker={asset?.ticker} price={assetValue} color={backgroundColor} />
        <Tabs />
        <PointInfo
          assetNumberOfShares={asset?.number_of_shares}
          selectedData={selectedData}
          noPointSelected={noPointSelected}
          since={since}
          chartType={activeTab}
        />
        <div className="AssetValueChart-chart">
          <div style={{ visibility: !chartPoints?.length ? 'hidden' : 'visible' }}>
            <TimelineChart
              activeTab={activeTab}
              chartData={chartPoints}
              onChange={setActiveChartPoint}
              pointValue={pointDate}
            />
          </div>
        </div>
        <div className="AssetValueChart-intervals">
          {intervals.map((interval, index) => (
            <div
              key={index}
              className={
                interval.name === activeInterval.name
                  ? 'AssetValueChart-interval active'
                  : 'AssetValueChart-interval'
              }
              onClick={async (e) => {
                setActiveInterval(interval);
                await chartTimeframeTapped({
                  assetId: asset?.id,
                  timeFrame: activeInterval.name,
                  transactions: chartPoints?.length,
                  sinceIO: since,
                  chartType: activeTab,
                });
              }}
            >
              {interval.name}
            </div>
          ))}
        </div>
        <div className="AssetValueChart-hr" />
        <div className="AssetValueChart-summary">
          {activeTab === TABS.SIMILAR_ASSET_SALE ? (
            <div className="AssetValueChart-summary-value">
              <div className="value">
                <span>{assetValueIncrease}</span>
                <span className="pct">%</span>
              </div>
              <div className="title">
                {`COMPARABLE ASSET VALUE CHANGE ${
                  Boolean(chartPoints && chartPoints.length) ? 'SINCE' : ''
                } ${formatDate()}`}
              </div>
            </div>
          ) : (
            <div className="AssetValueChart-summary-value">
              <div className="title">% VALUE CHANGE</div>
              <div className="value">
                <span>{assetValueIncrease}</span>
                <span className="pct">%</span>
              </div>
            </div>
          )}

          {activeTab === TABS.SIMILAR_ASSET_SALE ? (
            <div className="AssetValueChart-summary-value">
              <div className="value">{transactions}</div>
              <div className="title">{`TRANSACTIONS ${
                transactions ? `SINCE ${formatDate()}` : ''
              } `}</div>
            </div>
          ) : null}
        </div>
      </AssetDetailsPage>
    </div>
  );
};

export default React.memo(AssetValueChart);
