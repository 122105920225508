import React from 'react';

import { Price } from 'components/shared/Price';
import Percentage from 'components/shared/Percentage';
import { isEmpty } from 'utils';

import './index.scss';

const GainLoss = ({ data }) => {
  return (
    <>
      {!isEmpty(data) && (
        <div className="GainLoss">
          <div className="GainLoss-section">
            <div>
              <div className="GainLoss-section-header">All Time Gain/Loss</div>
              <div
                className={`GainLoss-section-content GainLoss-section-content-${
                  data?.allTimePercentage < 0 ? 'loss' : 'gain'
                }`}
              >
                <span className="GainLoss-percentage">
                  <Percentage percentage={data?.allTimePercentage} numOfDec={2} showSign />
                </span>
                <span className="GainLoss-total">
                  (<Price price={data?.allTimeAmount} keepZeros={true} />)
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GainLoss;
