import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import LazyImage from 'components/shared/Media/LazyImage';
import LightBox from 'components/shared/Media/LightBox';
import { CloseButton } from 'components/shared/Buttons/CloseButton';
import { VideoPlay } from 'components/shared/Icons/VideoPlay';
import analytics from 'services/analytics';
import { isRunningOnRallyAndroidClient, isEmpty } from 'utils';

import { SEGMENT_ACTIONS, SEGMENT_CATEGORIES } from 'constants/analytics';

import './Gallery.scss';
import SnapIcon from 'images/btn_snap.png';

class Gallery extends Component {
  constructor(props) {
    super(props);

    const { activeAsset } = this.props;
    const assetmedia =
      !isEmpty(activeAsset) && activeAsset.asset_media ? activeAsset.asset_media : [];
    const mediaItems = assetmedia.sort((a, b) => a.order - b.order);

    this.state = { mediaItems, isLightBoxOpen: false, activeMediaIndex: 0 };
  }

  componentDidMount() {
    if (isEmpty(this.props.activeAsset)) return;

    const assetTicker = this.props.activeAsset.ticker;

    analytics.track('2.0 Gallery', {
      category: SEGMENT_CATEGORIES.ASSET2_0_PORTAL,
      action: SEGMENT_ACTIONS.OPEN,
      assetTicker: assetTicker,
    });

    analytics.page(`${assetTicker} Gallery View`);
  }

  hideLightBox = () => this.setState({ isLightBoxOpen: false });

  onMediaClick = activeMediaIndex => {
    this.setState({ activeMediaIndex, isLightBoxOpen: true });
  };

  onPrevClick = () => {
    let { activeMediaIndex, mediaItems } = this.state;

    if (activeMediaIndex - 1 < 0) {
      activeMediaIndex = mediaItems.length - 1;
    } else {
      activeMediaIndex--;
    }

    this.setState({ activeMediaIndex });
  };

  onNextClick = () => {
    let { activeMediaIndex, mediaItems } = this.state;

    if (activeMediaIndex + 1 > mediaItems.length - 1) {
      activeMediaIndex = 0;
    } else {
      activeMediaIndex++;
    }

    this.setState({ activeMediaIndex });
  };

  goToSnapchat = snapchatUrl => {
    window.open(snapchatUrl, '_blank');
  };

  onGalleryClose = () => this.props.history.goBack();

  renderImages = () => {
    return this.state.mediaItems.map((mediaItem, index) => {
      const isVideo = mediaItem.type.toLowerCase() === 'video';

      return isVideo ? (
        <div key={mediaItem.id} className="Gallery-video" onClick={() => this.onMediaClick(index)}>
          <LazyImage
            key={mediaItem.id}
            className="Gallery-video-thumbnail"
            src={mediaItem.thumbnail}
            alt="Video"
          />
          <VideoPlay />
        </div>
      ) : (
        <LazyImage
          key={mediaItem.id}
          className="Gallery-image"
          src={mediaItem.file}
          onClick={() => this.onMediaClick(index)}
          alt={this.props.activeAsset.display_name}
        />
      );
    });
  };

  render() {
    const { mediaItems } = this.state;
    const fullWidthClass = mediaItems.length <= 1 ? ' fullWidth' : '';

    const lightboxMedia = mediaItems.map(mediaItem => ({
      src: mediaItem.file,
      type: mediaItem.type.toLowerCase(),
      alt: this.props.activeAsset.display_name,
    }));

    return (
      <div className={`Gallery ${this.props.applicationMode}`}>
        <CloseButton onClickHandler={this.onGalleryClose} />
        {isRunningOnRallyAndroidClient() && this.props.activeAsset.snapchat_deeplink_url && (
          <GallerySnapchatButton
            onClick={() => this.goToSnapchat(this.props.activeAsset.snapchat_deeplink_url)}
          >
            <SnapchatARIcon />
            <span>TAP TO UNLOCK IN SNAP</span>
          </GallerySnapchatButton>
        )}
        <div className="Gallery-contents">
          <div className={'Gallery-images' + fullWidthClass}>{this.renderImages()}</div>
        </div>
        <LightBox
          isOpen={this.state.isLightBoxOpen}
          activeIndex={this.state.activeMediaIndex}
          media={lightboxMedia}
          onPrevClick={this.onPrevClick}
          onNextClick={this.onNextClick}
          onClose={this.hideLightBox}
        />
      </div>
    );
  }
}

const GallerySnapchatButton = styled.button`
  font-family: 'Muli', sans-serif;
  border: none;
  color: white;
  transition: background-color 0.2s;
  background: rgb(249, 234, 46);
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  height: 50px;
  width: 100%;

  span {
    text-transform: uppercase;
    color: #000;
    letter-spacing: 0.15em;
    font-size: 3.5vmin;
  }
`;

const SnapchatARIcon = styled.div`
  width: 37px;
  height: 32px;
  background-image: url(${SnapIcon});
  background-size: cover;
  margin-right: 15px;
`;

const mapStateToProps = state => ({
  applicationMode: state.UI.applicationMode,
  activeAsset: state.Assets.activeAsset,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
