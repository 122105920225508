import React, { Component } from 'react';
import { connect } from 'react-redux';

import LoginSignup from 'components/shared/LoginSignup/LoginSignup';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import PasswordReset from 'components/views/public/PasswordReset/PasswordReset';
import { logoutUser } from 'actions';

class Signup extends Component {
  componentDidMount() {
    if (this.props.isAuth) this.props.logoutUser();
  }

  renderComponent() {
    const { location } = this.props;

    switch (location.pathname) {
      case '/login':
        return <LoginForm />;

      case '/signup':
        return <SignupForm />;

      case '/password-reset':
        return <PasswordReset />;

      default:
        return <LoginForm />;
    }
  }

  render() {
    return <LoginSignup>{this.renderComponent()}</LoginSignup>;
  }
}

const mapStateToProps = state => ({ isAuth: state.Auth.isAuth });

const mapDispatchToProps = { logoutUser };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Signup);
