import CONFIG from 'config.js';
import * as RequestHelper from './RequestHelper';
import { getRequest, putRequest } from './RequestHandler';

export function getUsersSignUpCode() {
  let endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/users/signupcode';
  let token = localStorage.getItem('token');

  return RequestHelper.getRequest(endpoint, token);
}

export function setUserInfo(payload) {
  let endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/auth/user/';
  let token = localStorage.getItem('token');
  const body = JSON.stringify(payload);

  return RequestHelper.patchRequest(endpoint, token, body);
}

export function setUserEmail(email) {
  let endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/auth/change-email/';
  let token = localStorage.getItem('token');
  const body = JSON.stringify({ email });
  return RequestHelper.postRequestWithoutHandler(endpoint, token, body);
}

export function subscribeOnStateAvailable() {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/users/notify-me-on-state-available';
  const token = localStorage.getItem('token');
  return RequestHelper.postRequestWithoutHandler(endpoint, token);
}

export function getUserStateStatus() {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/dwolla/is-state-available/';
  const token = localStorage.getItem('token');
  return RequestHelper.getRequest(endpoint, token);
}

export function sendConfirmationEmail(email) {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/auth/email/verify/';
  const body = JSON.stringify({ email });
  const token = localStorage.getItem('token');
  return RequestHelper.postRequestWithoutHandler(endpoint, token, body);
}

export function createReferralLink(assetCategory) {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/referral/interests/';
  const body = JSON.stringify({ req_category: assetCategory.key });
  const token = localStorage.getItem('token');
  return RequestHelper.postRequestWithoutHandler(endpoint, token, body);
}

export function getReferralInfo() {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/referral/interests/';
  const token = localStorage.getItem('token');
  return RequestHelper.getRequest(endpoint, token);
}

export function handleFcmToken(payload) {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/device/fcm/';
  const body = JSON.stringify(payload);
  const token = localStorage.getItem('token');

  return RequestHelper.postRequestWithoutHandler(endpoint, token, body);
}

export function requestDocumentUploadS3URL(payload) {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/users/files/';
  const token = localStorage.getItem('token');
  const body = JSON.stringify(payload);

  return RequestHelper.postRequestWithoutHandler(endpoint, token, body);
}

export function uploadDocumentToS3URL(payload, presignedUrl) {
  return fetch(presignedUrl, {
    method: 'PUT',
    body: payload,
    headers: new Headers({
      'Content-Type': payload.type,
    }),
  });
}

export function getEnrollment(accountId) {
  const endpoint = CONFIG.apiUrlFinancialAccounts + `/financial-accounts/${accountId}/enrollment`;

  return getRequest(endpoint, { conditionalType: 'if-match' });
}

export function setTosAgreement(timestamp, accountId) {
  const endpoint = CONFIG.apiUrlFinancialAccounts + `/financial-accounts/${accountId}/enrollment`;
  const body = {
    termsOfService: {
      signedAndAgreedAt: timestamp,
    },
  };

  return putRequest(endpoint, body);
}
