import React, { Component } from 'react';
import { connect } from 'react-redux';

import { CloseButton } from 'components/shared/Buttons/CloseButton';

import analytics from 'services/analytics';

import { SEGMENT_ACTIONS, SEGMENT_CATEGORIES } from 'constants/analytics';

import { isEmpty } from 'utils';
import './Legal.scss';

class Legal extends Component {
  onCloseHandler = () => {
    const { history } = this.props;
    history.goBack();
  };

  componentDidMount() {
    const { activeAsset } = this.props;
    if (!isEmpty(activeAsset)) {
      const assetTicker = activeAsset.ticker;

      analytics.track('2.0 PDF/Legal', {
        category: SEGMENT_CATEGORIES.ASSET2_0_PORTAL,
        action: SEGMENT_ACTIONS.OPEN,
        assetTicker: assetTicker,
      });

      analytics.page(`${assetTicker} PDF/Legal View`);
    }
  }

  renderIframe = () => {
    const { activeAsset } = this.props;
    let uriEncoded = encodeURIComponent(activeAsset.pdf);
    let pdfUrl = 'https://drive.google.com/viewerng/viewer?embedded=true&url=' + uriEncoded;
    return <iframe title="legalPDF" src={pdfUrl} height="100%" width="100%" />;
  };

  render() {
    const { activeAsset, applicationMode } = this.props;

    return (
      <div className={`Legal ${applicationMode}`}>
        <CloseButton onClickHandler={this.onCloseHandler} />
        <div className="Legal-contents">
          {activeAsset && activeAsset.pdf && this.renderIframe()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  applicationMode: state.UI.applicationMode,
  activeAsset: state.Assets.activeAsset,
});

export default connect(mapStateToProps)(Legal);
