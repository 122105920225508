import React from 'react';
import { SimpleComponent } from 'types/components';
import { OrderType } from 'types/orders';
import {
  Tab,
  TabDescription,
  TabError,
  TabIndicator,
  TabLine,
  TabsContainer,
  TabTitle,
} from './styled';

type BuyOrderTypeTabProps = {
  type: OrderType;
  onClick: (t: OrderType) => void;
  instantBuyDisabled: boolean;
};

const BuyOrderTypeTab: SimpleComponent<BuyOrderTypeTabProps> = ({
  type,
  onClick,
  instantBuyDisabled,
}) => (
  <TabsContainer>
    {instantBuyDisabled && (
      <TabError>
        Instant Buy <br /> Currently Unavailable
      </TabError>
    )}
    <Tab
      disabled={instantBuyDisabled}
      active={type === OrderType.INSTANT}
      onClick={() => onClick(OrderType.INSTANT)}
    >
      <TabTitle>Instant Buy</TabTitle>
      <TabDescription>Buy 1 Now</TabDescription>
    </Tab>
    <Tab active={type === OrderType.LIMIT} onClick={() => onClick(OrderType.LIMIT)}>
      <TabTitle>Limit order</TabTitle>
      <TabDescription>1 or More</TabDescription>
    </Tab>
    <TabIndicator active={type === OrderType.LIMIT} />
    <TabLine />
  </TabsContainer>
);

export default BuyOrderTypeTab;
