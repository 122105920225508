import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { testPoBox } from 'utils';

import SettingsBlock from './SettingsBlock';
import UserSetting from './UserSetting';

import './PersonalInformation.css';

const profileInfoFields = [
  { name: 'first_name', label: 'First Name' },
  { name: 'last_name', label: 'Last Name' },
  { name: 'address', label: 'Address', type: 'address' },
  { name: 'address2', label: 'Apt #', type: 'address', required: false },
  { name: 'city', label: 'City' },
  { name: 'state', label: 'State', type: 'us_state' },
  { name: 'postal_code', label: 'ZIP Code', type: 'zip' },
  { name: 'date_of_birth', label: 'Date of Birth', type: 'date_of_birth' },
  { name: 'ssn', label: 'Social Security #', type: 'ssn' },
  { name: 'phone_number', label: 'Primary Phone #', type: 'phone' },
];

const PersonalInformation = props => {
  const { user } = useSelector(state => state.Auth);
  const [error, setError] = useState(null);
  const percentCompleted = () => {
    const [completedFields, requiredFields] = profileInfoFields.reduce(
      ([totalCompleted, totalRequired], field) => [
        user[field.name] ? totalCompleted + 1 : totalCompleted,
        field.required !== false ? totalRequired + 1 : totalRequired,
      ],
      [0, 0],
    );
    return Math.min((completedFields / requiredFields) * 100, 100);
  };

  useEffect(() => {
    if (user.address || user.address2) {
      if (user.address && user.address2 && testPoBox(user.address) && testPoBox(user.address2)) {
        setError(`Please update 'Address' and 'Apt #' fields: PO Boxes are not permitted`);
      } else if (user.address && testPoBox(user.address)) {
        setError(`Please update 'Address' field: PO Boxes are not permitted`);
      } else if (user.address2 && testPoBox(user.address2)) {
        setError(`Please update 'Apt #' field: PO Boxes are not permitted`);
      } else {
        if (error) setError(null);
      }
    }
  }, [user.address, user.address2, error]);

  return (
    <div className="PersonalInformation">
      {error && <h3 className="Error">{error}</h3>}
      <SettingsBlock
        title="PERSONAL INFORMATION"
        completedPercentage={percentCompleted()}
        expanded={props.expanded}
        onExpandCollapseClick={props.onExpandCollapseClick}
      >
        {profileInfoFields.map(field => (
          <UserSetting key={field.name} value={user[field.name] || ''} {...field} />
        ))}
      </SettingsBlock>
    </div>
  );
};

export default PersonalInformation;
