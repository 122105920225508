import React from 'react';

/**
 * Helper component for rendering auth errors
 * @param  {String | Array} errors - auth errors
 */
const LoginSignupFormErrors = ({ errors }) => {
  let content;

  if (!errors) return null;

  if (Array.isArray(errors)) {
    content = errors.map((error, index) => (
      <div className="LoginSignupForm-errors-error" key={index}>
        {error.message}
      </div>
    ));
  }

  if (typeof errors === 'string') {
    content = <div className="LoginSignupForm-errors-error">{errors}</div>;
  }

  return <div className="LoginSignupForm-errors">{content}</div>;
};

export default LoginSignupFormErrors;
