import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import { Button } from 'components/shared/Buttons/Button';
import { SecondaryButton } from 'components/shared/Buttons/SecondaryButton';
import { TextButton } from 'components/shared/Buttons/TextButton';
import { UpCaret } from 'components/shared/Icons/UpCaret';

import './BidAskButtons.scss';
import { OrderContext } from 'types/orders';
import { getInvestments, getOrders } from 'actions';
import { getActiveAssetDetails } from 'utils/trading';

import analytics from 'services/analytics';
import { SEGMENT_ACTIONS, SEGMENT_CATEGORIES, SEGMENT_EVENTS } from 'constants/analytics';
import { ASSET_STATUS } from 'constants/main';
import { SecondaryMarketStatus } from 'types/assets';
import { getUserPositions } from 'actions/investments';
class BidAskButtons extends Component {
  state = {
    inAction: false,
  };

  onBidAskClick = side => {
    this.setState({ inAction: true }, async () => {
      return new Promise(async res => {
        if (this.checkTradingTimeOver()) return this.forceUpdate();
        const emailIsVerified = await this.props.verifyEmail?.();
        await this.props.getInvestments();
        await this.props.getOrders();
        // updates the positions
        if (side === OrderContext.ASK) {
          await this.props.getUserPositions();
        }

        if (emailIsVerified) {
          const assetDetails = await this.props.getActiveAssetDetails();

          analytics.track(
            side === OrderContext.ASK
              ? SEGMENT_EVENTS.ASK_BUTTON_TAPPED
              : SEGMENT_EVENTS.BID_BUTTON_TAPPED,
            {
              action: SEGMENT_ACTIONS.TAPPED,
              category: SEGMENT_CATEGORIES.TRADING,
              assetName: assetDetails.assetName,
              assetState: assetDetails.assetStatus,
              assetCategory: assetDetails.assetCategory,
              assetTicker: assetDetails.assetTicker,
              currentValue: assetDetails.currentTotalValue,
              ioValue: assetDetails.ioPrice,
              sinceIO: assetDetails.gainLoss?.allTime,
              currentSharePrice: assetDetails.currentSharePrice,
              lastCloseSharePrice: assetDetails.lastCloseSharePrice,
              side: side === OrderContext.ASK ? 'SELL' : 'BUY',
              lowestAsk: assetDetails.askLow,
              orderType: 'New',
            },
          );

          const route = side === OrderContext.ASK ? '/app/place-ask' : '/app/place-bid';
          this.props.history.push(route);
        }

        res(true);
      }).then(() => {
        this.setState({ inAction: false });
      });
    });
  };

  onViewBidListClick = async () => {
    const { activeAsset } = this.props;

    const isPostOnly =
      activeAsset.asset_status === ASSET_STATUS.POST_ONLY ||
      (activeAsset.asset_status === ASSET_STATUS.TRADING_OPENED &&
        activeAsset.financialInstrument?.markets?.secondaryMarket?.status !==
          SecondaryMarketStatus.OPEN);

    const assetDetails = await this.props.getActiveAssetDetails();

    analytics.track(SEGMENT_EVENTS.VIEW_ORDER_BOOK, {
      action: SEGMENT_ACTIONS.TAPPED,
      category: SEGMENT_CATEGORIES.TRADING,
      assetName: assetDetails.assetName,
      assetState: assetDetails.assetStatus,
      assetCategory: assetDetails.assetCategory,
      assetTicker: assetDetails.assetTicker,
      currentValue: assetDetails.currentTotalValue,
      ioValue: assetDetails.ioPrice,
      sinceIO: assetDetails.gainLoss?.allTime,
      currentSharePrice: assetDetails.currentSharePrice,
      lastCloseSharePrice: assetDetails.lastCloseSharePrice,
      ...(isPostOnly ? {} : { gainToday: assetDetails.gainLoss?.today }),
    });

    this.props.history.push('/app/orders');
  };

  onCloseClick = () => {
    this.props.history.goBack();
  };

  checkTradingTimeOver = () => {
    const { activeAsset } = this.props;
    const { dateClose } =
      activeAsset.financialInstrument?.markets?.secondaryMarket?.sessionHours ?? {};
    return dateClose && moment().isSameOrAfter(moment(dateClose));
  };

  render() {
    const { inAction } = this.state;
    const { history, activeAsset, isLoadingTradingWindow, positions } = this.props;

    // Calculate active asset shares by combining investment and orders shares:
    const { financialInstrument } = activeAsset;
    const activeShares = positions[financialInstrument.id]?.units?.total ?? 0;

    const pathname = history.location.pathname;
    const viewAll = 'VIEW ALL BIDS & ASKS';
    const viewAllButton =
      pathname === '/app/orders' ? (
        <TextButton onClick={this.onCloseClick}>
          {viewAll}{' '}
          <span className="BidAskButtons-rotate">
            <UpCaret />
          </span>
        </TextButton>
      ) : (
        <TextButton onClick={this.onViewBidListClick}>
          {viewAll} <UpCaret />
        </TextButton>
      );

    const isSharesForSale = activeShares > 0;

    const askDisabled = inAction || !isSharesForSale || isLoadingTradingWindow;
    const bidDisabled = inAction || isLoadingTradingWindow;

    return (
      <div className="BidAskButtons">
        <div className="BidAskButtons-contents">
          {/* <span className="BidAskButtons-disclaimer">
            Updated NorthCapital disclaimer goes here (TBD to be confirmed)
          </span> */}
          <div className="BidAskButtons-buttons">
            <span className="BidAskButtons-bid">
              <Button onClick={() => this.onBidAskClick(OrderContext.BID)} disabled={bidDisabled}>
                <b>BUY (BID)</b>
              </Button>
            </span>
            <span className="BidAskButtons-ask">
              <SecondaryButton
                onClick={() => this.onBidAskClick(OrderContext.ASK)}
                disabled={askDisabled}
              >
                SELL (ASK)
              </SecondaryButton>
            </span>
          </div>
          <div className="BidAskButtons-bidlist">{viewAllButton}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeAsset: state.Assets.activeAsset,
  activeTradingWindow: state.Trading.activeTradingWindow,
  isLoadingTradingWindow: state.Trading.isLoadingTradingWindow,
  positions: state.Investments.positions,
});

const mapDispatchToProps = {
  getInvestments,
  getOrders,
  getActiveAssetDetails,
  getUserPositions,
};

export default connect(mapStateToProps, mapDispatchToProps)(BidAskButtons);
