import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as uuid from 'uuid';
import { connect } from 'react-redux';
import { Price } from 'components/shared/Price';
import { TroughButtons } from 'components/views/app/Assets/Trading/Common/TroughButtons';
import { OrderSummary } from 'components/views/app/Assets/Trading/Common/OrderSummary';
import { TextButton } from 'components/shared/Buttons/TextButton';
import TradingClosedPopup from 'components/views/app/Assets/Trading/Common/TradingClosedPopup';
import { ModalViewContext } from 'components/shared/Modals/Modal';
import AlertModal from 'components/shared/Modals/AlertModal';

import './ReviewBid.scss';

import {
  showNavMenuNotification,
  refreshActiveTradingWindow,
  getOrders,
  getBalance,
  getInvestments,
} from 'actions';
import { SecondaryButtonV2 } from 'components/shared/Buttons';
import { SuccessType } from 'components/views/app/Assets/Trading/ReviewLoader';
import { OrderContext, OrderStatus, OrderTerm, OrderType, OrderNewEditType } from 'types/orders';
import withAssetPrevalidation from 'hoc/withAssetPrevalidation';
import Bugsnag from '@bugsnag/browser';
import { PrevalidateErrorCode } from 'types/servicesResponse';
import { getOrder, postOrder, putOrder, getOrderById } from 'services/AssetsAPI';
import { DotsLoader } from 'components/shared/Loaders';
import { getActiveAssetDetails } from 'utils/trading';

import analytics from 'services/analytics';
import { SEGMENT_ACTIONS, SEGMENT_CATEGORIES, SEGMENT_EVENTS } from 'constants/analytics';
import { getUserPositions } from 'actions/investments';

class ReviewBid extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    onFormUpdate: PropTypes.func.isRequired,
    onStepForward: PropTypes.func.isRequired,
    onStepBackward: PropTypes.func.isRequired,
  };

  state = {
    isTradingClosed: false,
    error: null,
    idempotencyKey: null,
    loading: false,
  };

  componentDidMount() {
    this.setState({ idempotencyKey: uuid.v4() });
    this.fireReviewOrderEntryEvent();
  }

  fireReviewOrderEntryEvent = async () => {
    const { side, isEditing, buySellOrderType, shares, pricePerShare } = this.props.data;
    const assetDetails = await this.props.getActiveAssetDetails();

    analytics.track(SEGMENT_EVENTS.REVIEW_ORDER_VIEW, {
      action: SEGMENT_ACTIONS.OPEN,
      category: SEGMENT_CATEGORIES.TRADING,
      assetName: assetDetails.assetName,
      assetState: assetDetails.assetStatus,
      assetCategory: assetDetails.assetCategory,
      assetTicker: assetDetails.assetTicker,
      currentValue: assetDetails.currentTotalValue,
      ioValue: assetDetails.ioPrice,
      sinceIO: assetDetails.gainLoss?.allTime,
      currentSharePrice: assetDetails.currentSharePrice,
      lastCloseSharePrice: assetDetails.lastCloseSharePrice,
      side: side === OrderContext.ASK ? 'SELL' : 'BUY',
      orderType: isEditing ? OrderNewEditType.EDIT : OrderNewEditType.NEW,
      flow: buySellOrderType,
      shareQuantity: shares,
      sharePrice: Number(
        buySellOrderType === OrderType.INSTANT ? assetDetails.askLow : pricePerShare,
      ),
      lowestAsk: assetDetails.askLow,
    });
  };

  handlePartialFilled = order => {
    const { data } = this.props;
    if (!data.isEditing) {
      this.props.onLoaderChange({ successType: SuccessType.PARTIAL });
      return;
    }
    const { units: originalUnits } = data.originalOrderData ?? {};
    const { units } = order;

    if (units.filled !== originalUnits.filled) {
      this.props.onLoaderChange({ successType: SuccessType.PARTIAL });
      order.units.filled -= originalUnits?.filled ?? 0;
      order.units.requested -= originalUnits?.filled ?? 0;
      return;
    }
    order.status = OrderStatus.SUBMITTED;
    order.units.filled = 0;
    order.units.requested = order.units.unfilled;
    this.props.onLoaderChange({ successType: SuccessType.NOMATCH });
  };

  handleFilled = order => {
    const { data } = this.props;
    const { units: originalUnits } = data.originalOrderData ?? {};
    if (data.isEditing) {
      order.units.requested -= originalUnits?.filled ?? 0;
    }
    this.props.onLoaderChange({
      successType:
        order.term === OrderTerm.IMMEDIATE_OR_CANCEL ? SuccessType.INSTANT : SuccessType.FULL,
    });
  };

  handleOrderSuccess = order => {
    // FILLED or PARTIALLY_FILLED anything else === NOMATCH
    // INSTANT for instant buy FILLED success
    const { onLoaderChange } = this.props;
    switch (order.status) {
      case OrderStatus.FILLED:
        this.handleFilled(order);
        break;
      case OrderStatus.PARTIALLY_FILLED:
        this.handlePartialFilled(order);
        break;
      default:
        // instant buy high order volume for submitted orders
        if (
          order.term === OrderTerm.IMMEDIATE_OR_CANCEL &&
          order.status === OrderStatus.SUBMITTED
        ) {
          this.handleHighOrderVolumeError(true, true);
          this.fireHighOrderVolumeEvent(OrderStatus.SUBMITTED);
          this.props.getOrders();
          this.props.onFormUpdate({ order }, this.props.onStepForward);
          this.props.showNavMenuNotification('/app/investments');
          return;
        }
        onLoaderChange({ successType: SuccessType.NOMATCH });
    }
    setTimeout(() => {
      this.props.getOrders();
      this.props.onFormUpdate({ order }, this.props.onStepForward);
      this.props.onLoaderChange({ active: false });
      this.props.showNavMenuNotification('/app/investments');
    }, 3000);
  };

  firePlaceOrderEvent = async () => {
    const { side, isEditing, buySellOrderType, shares, pricePerShare } = this.props.data;
    const assetDetails = await this.props.getActiveAssetDetails();

    analytics.track(SEGMENT_EVENTS.PLACE_ORDER_TAPPED, {
      action: SEGMENT_ACTIONS.TAPPED,
      category: SEGMENT_CATEGORIES.TRADING,
      assetName: assetDetails.assetName,
      assetState: assetDetails.assetStatus,
      assetCategory: assetDetails.assetCategory,
      assetTicker: assetDetails.assetTicker,
      currentValue: assetDetails.currentTotalValue,
      ioValue: assetDetails.ioPrice,
      sinceIO: assetDetails.gainLoss?.allTime,
      currentSharePrice: assetDetails.currentSharePrice,
      lastCloseSharePrice: assetDetails.lastCloseSharePrice,
      side: side === OrderContext.ASK ? 'SELL' : 'BUY',
      orderType: isEditing ? OrderNewEditType.EDIT : OrderNewEditType.NEW,
      flow: buySellOrderType,
      shareQuantity: shares,
      sharePrice: Number(pricePerShare),
      lowestAsk: assetDetails.askLow,
    });
  };

  fireHighOrderVolumeEvent = async status => {
    const { side, isEditing, buySellOrderType, shares, pricePerShare } = this.props.data;
    const assetDetails = await this.props.getActiveAssetDetails();

    const eventName =
      status === OrderStatus.SUBMITTED
        ? SEGMENT_EVENTS.HIGH_ORDER_VOLUME_SUBMITTED
        : SEGMENT_EVENTS.HIGH_ORDER_VOLUME_QUEUED;

    analytics.track(eventName, {
      action: SEGMENT_ACTIONS.OPEN,
      category: SEGMENT_CATEGORIES.TRADING,
      assetName: assetDetails.assetName,
      assetState: assetDetails.assetStatus,
      assetCategory: assetDetails.assetCategory,
      assetTicker: assetDetails.assetTicker,
      currentValue: assetDetails.currentTotalValue,
      ioValue: assetDetails.ioPrice,
      sinceIO: assetDetails.gainLoss?.allTime,
      currentSharePrice: assetDetails.currentSharePrice,
      lastCloseSharePrice: assetDetails.lastCloseSharePrice,
      side: side === OrderContext.ASK ? 'SELL' : 'BUY',
      orderType: isEditing ? OrderNewEditType.EDIT : OrderNewEditType.NEW,
      flow: buySellOrderType,
      shareQuantity: shares,
      sharePrice: Number(pricePerShare),
      lowestAsk: assetDetails.askLow,
    });
  };

  fireUnableToPlaceOrderEvent = async status => {
    const { side, isEditing, buySellOrderType, shares, pricePerShare } = this.props.data;
    const assetDetails = await this.props.getActiveAssetDetails();

    const eventName =
      status === OrderStatus.SUBMITTED
        ? SEGMENT_EVENTS.HIGH_ORDER_VOLUME_SUBMITTED
        : SEGMENT_EVENTS.HIGH_ORDER_VOLUME_QUEUED;

    analytics.track(eventName, {
      action: SEGMENT_ACTIONS.OPEN,
      category: SEGMENT_CATEGORIES.TRADING,
      assetName: assetDetails.assetName,
      assetState: assetDetails.assetStatus,
      assetCategory: assetDetails.assetCategory,
      assetTicker: assetDetails.assetTicker,
      currentValue: assetDetails.currentTotalValue,
      ioValue: assetDetails.ioPrice,
      sinceIO: assetDetails.gainLoss?.allTime,
      currentSharePrice: assetDetails.currentSharePrice,
      lastCloseSharePrice: assetDetails.lastCloseSharePrice,
      side: side === OrderContext.ASK ? 'SELL' : 'BUY',
      orderType: isEditing ? OrderNewEditType.EDIT : OrderNewEditType.NEW,
      flow: buySellOrderType,
      shareQuantity: shares,
      sharePrice: Number(pricePerShare),
      lowestAsk: assetDetails.askLow,
    });
  };

  handlePlaceOrder = async () => {
    const { data, customer } = this.props;
    const { idempotencyKey, loading } = this.state;
    if (loading) return;

    this.firePlaceOrderEvent();

    this.setState({ loading: true });
    if (data.isLiveTrading) {
      this.props.onLoaderChange({
        active: true,
        type: data.buySellOrderType,
      });
    }

    try {
      const { responseData, orderData } = await this.props.prevalidateOrder();
      if (responseData.code === PrevalidateErrorCode.CROSS_TRADING_PROHIBITED) {
        this.handleNewTWErrors(
          'Currently, we limit Investors from having concurrent active BIDs and ASKs for a given asset.',
        );
        return;
      }
      if (!responseData.valid) {
        this.handleNewTWErrors();
        this.fireUnableToPlaceOrderEvent();
        return;
      }
      const accountId = customer?.accounts?.[0]?.id;

      let orderId;
      // get etag if editing
      if (data.isEditing) {
        orderId = data.originalOrderData?.id;

        await getOrderById(orderId, accountId);
        await putOrder(orderId, accountId, idempotencyKey, {
          units: data.shares + (data.originalOrderData?.units?.filled || 0),
          unitPrice: Number(Number(data.pricePerShare).toFixed(2)),
        });
      } else {
        const resp = await postOrder(orderData, accountId, idempotencyKey);
        orderId = resp.data.id;
      }

      const { data: finalResponse } = await getOrder(orderId, accountId);
      // generic high order volume
      if (finalResponse.status === OrderStatus.QUEUED) {
        this.handleHighOrderVolumeError(data.isLiveTrading);
        this.fireHighOrderVolumeEvent(OrderStatus.QUEUED);
        return;
      }

      this.props.getBalance();
      this.props.getUserPositions();
      this.props.getInvestments();
      this.handleOrderSuccess(finalResponse);
      // TODO call the post servce and create polling
    } catch (err) {
      this.handleNewTWErrors();
      this.fireUnableToPlaceOrderEvent();
      Bugsnag.notify(err);
    }
  };

  handleHighOrderVolumeError = (isLiveTrading, withoutRedirect) => {
    this.handleNewTWErrors(
      isLiveTrading
        ? 'Due to high order volumes, we have placed your order in the Bid queue. If the order matches, you will be notified. Check its status from your portfolio'
        : 'We will do our best to place your order as we get through the queue. Once your order is placed, you will be notified and the order will appear in Your Portfolio. You DO NOT need to retry placing the order.',
      'Experiencing High Order Volumes',
      withoutRedirect,
    );
  };

  handleNewTWErrors = (
    message = 'There was an issue processing your order request. Please try again. If issues persist, please contact us at support@rallyrd.com',
    title = 'Unable to Place Order',
    withoutRedirect,
  ) => {
    const { setModalViewRenderSequence } = this.context;
    const { data, activeAssetCategory } = this.props;
    if (data.isLiveTrading) {
      this.props.onLoaderChange({
        active: false,
      });
    }
    this.setState({ loading: false });
    setModalViewRenderSequence([
      {
        state: true,
        id: uuid.v4(),
        children: <AlertModal />,
        modalStyle: {
          overlayBgColor: 'rgba(0,0,0, .7)',
        },
        childrenProps: {
          title,
          message,
        },
        transition: 'modalViewScaleDown',
        onCloseCompletion: () => {
          if (withoutRedirect) {
            return;
          }
          this.props.history.push(
            `/app/assets/${activeAssetCategory.pathname}/${data.asset.ticker.slice(1)}`,
          );
        },
      },
    ]);
  };

  render() {
    const { error, loading } = this.state;
    const {
      data: { side, asset, shares, pricePerShare, cost, askLow, buySellOrderType },
      onFormClose,
    } = this.props;

    const ticker = asset.ticker;

    const currentCost = buySellOrderType === OrderType.INSTANT ? askLow : cost;
    const currentShares = buySellOrderType === OrderType.INSTANT ? 1 : shares;

    // const closingTime = '4pm ET';
    const isSell = side === OrderContext.ASK;

    const reviewBidInfoTitle = isSell ? (
      <span className="ReviewBid-red">Place an ask to sell shares</span>
    ) : (
      <span>Place a bid to buy shares</span>
    );

    const buySell = isSell ? 'sell' : 'buy';
    const bidAsk = isSell ? 'ask' : 'bid';
    const sign = isSell ? '+' : '-';

    return (
      <div className="ReviewBid">
        <div className="ReviewBid-explanation">
          You are placing a {buySell} order (your “{bidAsk.toUpperCase()}”) of {shares} share
          {shares > 1 ? 's' : ''} of {ticker}. Your order, if executed, will be fulfilled at{' '}
          <Price price={pricePerShare} /> per share or better.
        </div>
        {!isSell && (
          <div className="ReviewBid-description">
            If you purchase shares during a Trading Window, you agree to be admitted as a member of
            a Rally Entity and further agree to be bound by the provisions of, and deemed to be a
            party to, the Operating Agreement of the Rally Entity. For additional information
            regarding your rights and obligations as a member of the Rally Entity, please see our{' '}
            <a href="https://rallyrd.com/disclaimer/" rel="noopener noreferrer" target="_blank">
              Disclaimer
            </a>
            .
          </div>
        )}
        <div className="ReviewBid-tw-description">
          If you placed your order during live market hours, your order may be executed & matched
          immediately.{' '}
          {!isSell && 'Purchased shares must be held for 5 business days before re-selling.'}
        </div>
        {isSell && (
          <div className="ReviewBid-tw-description">
            If executed, cash will be credited to your account within 2 business days.
          </div>
        )}
        <div className="ReviewBid-info">
          <div className="ReviewBid-info-title">{reviewBidInfoTitle}</div>
          <OrderSummary
            shares={currentShares}
            ticker={ticker}
            price={buySellOrderType === OrderType.INSTANT ? askLow : pricePerShare}
          />
        </div>
        <div className="PlaceBid-fields">
          <div className="PlaceBid-field ReviewBid-ordercost">
            <div className="PlaceBid-field-label">Order Total</div>
            <div className="PlaceBid-field-content">
              <span>{sign} </span>
              <Price price={currentCost} />
            </div>
          </div>
          <div className="PlaceBid-field">
            <div className="PlaceBid-field-label">Addtl Fees</div>
            <div className="PlaceBid-field-content">
              <Price price={0} />
            </div>
          </div>
        </div>
        <TroughButtons className="fixed-buttons">
          <SecondaryButtonV2
            className="TroughButtons-cta"
            disabled={error || loading}
            onClick={this.handlePlaceOrder}
          >
            {loading ? <DotsLoader color="#fff" /> : 'Place Order'}
          </SecondaryButtonV2>
          <TextButton disabled={loading} onClick={() => this.props.onStepBackward()}>
            EDIT ORDER
          </TextButton>
        </TroughButtons>

        <TradingClosedPopup show={this.state.isTradingClosed} onConfirm={onFormClose} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeAssetCategory: state.Assets.activeAssetCategory,
  activeAsset: state.Assets.activeAsset,
  customer: state.Auth.user.customer,
});
const mapDispatchToProps = {
  showNavMenuNotification,
  refreshActiveTradingWindow,
  getInvestments,
  getBalance,
  getOrders,
  getActiveAssetDetails,
  getUserPositions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withAssetPrevalidation(ReviewBid));

ReviewBid.contextType = ModalViewContext;
