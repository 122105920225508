import React, { Component } from 'react';
import './SelectButton.css';

export class SelectButton extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    this.props.onSelect(this.props.name);
  }

  render() {
    let selectedClass = this.props.selected ? 'SelectButton-selected' : '';
    let wantedClass = '';
    if (typeof this.props.wanted !== undefined) {
      wantedClass = this.props.wanted ? 'SelectButton-wanted' : 'SelectButton-unwanted';
    }
    let styleClass = this.props.showColors === false ? 'dark' : '';
    return (
      <button
        className={'SelectButton ' + selectedClass + ' ' + wantedClass + ' ' + styleClass}
        onClick={this.handleClick}
      >
        <span className="SelectButton-contents">{this.props.name}</span>
      </button>
    );
  }
}

export default SelectButton;
