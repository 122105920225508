import React, { useRef, useEffect, useState, useCallback } from 'react';

import clamp from 'lodash.clamp';
import { useSprings, animated } from 'react-spring';
import { useGesture } from 'react-use-gesture';

const DetailsCarousel = ({ parentRef, detailPageComponents, onSlideChange, activeIndex }) => {
  const index = useRef(0);
  const lockWheelOnDesktopMode = useRef(false);

  const [listHeight, setListHeight] = useState(window.innerHeight);

  // bind the list with spring animation library
  const [props, set] = useSprings(detailPageComponents.length, i => ({
    transform: `translateY(${i * listHeight}px)`,
    display: 'flex',
    // allow to continue desktop scrolling when animation comes to a stand-still:
    onRest: () => (lockWheelOnDesktopMode.current = false),
  }));

  useEffect(() => {
    setListHeight(parentRef);

    set(i => {
      let transform = `translateY(0px)`;
      let display = 'flex';

      if (i === index.current) {
        // if this item is the current index item, reposition it to center with full scale and opacity value
        transform = `translateY(0px)`;
      } else {
        // all other items repositioned accordingly
        let transformXValue = (i - index.current) * parentRef;
        transform = `translateY(${transformXValue}px)`;
      }

      return { transform, display };
    });
  }, [listHeight, parentRef, set]);

  const updateSlideIndex = useCallback(() => {
    // reposition to provided index when initially load the screen (open the app/back to certain URL path with asset ticker)
    let indexValue = activeIndex;
    //initIndex !== 0 ? (indexValue = initIndex) : (indexValue = activeIndex);

    set(i => {
      let transform = `translateY(${(i - indexValue) * listHeight}px)`;
      let display = 'flex';

      if (indexValue === i) {
        index.current = i;
      }

      if (indexValue < i - 1 || indexValue > i + 1) display = 'none';
      return { transform, display };
    });
  }, [set, activeIndex, listHeight]);

  useEffect(() => {
    if (index.current !== activeIndex) {
      onSlideChange(activeIndex);
      updateSlideIndex();
    }
  }, [activeIndex, onSlideChange, updateSlideIndex, index]);

  // drag gesture handler
  const bind = useGesture(
    {
      onDrag: ({
        event,
        down,
        active,
        buttons,
        last,
        vxvy: [vx, vy],
        movement: [mx, my],
        direction: [dirx, diry],
        offset: [ox, oy],
        initial: [ix, iy],
        distance,
        cancel,
        cancelled,
      }) => {
        const { target } = event ? event : null;
        const eTargetClassName = target ? target.className : '';

        if (
          down &&
          (eTargetClassName === 'AssetTimeline-timeline-item-text' ||
            eTargetClassName === 'AssetTimeline-timeline-item-date' ||
            eTargetClassName === 'Circle')
        )
          return;

        if (dirx > 0.8 || dirx < -0.8) cancel(); // cancel vertical drag gesture
        if (
          (diry > 0 && index.current === 0) ||
          (diry < 0 && index.current === detailPageComponents.length - 1)
        )
          cancel();

        if (down && Math.abs(vy) >= 1.7) {
          // if dragging reaches to certain point, it tries to save the current item as a temporary current index target item
          let newCurrent = index.current + (diry > 0 ? -1 : 1);
          localStorage.setItem(
            'carousel2AssetDetailsCenterTarget',
            clamp(newCurrent, 0, detailPageComponents.length - 1),
          );
          cancel((index.current = clamp(newCurrent, 0, detailPageComponents.length - 1)));
        }

        try {
          set(i => {
            let transformXValue = (i - index.current) * listHeight + (down ? my * 1.2 : 0);
            let transform = `translateY(${transformXValue}px)`;
            let display = 'flex';
            if (down && Math.abs(transformXValue) < listHeight / 2.5) {
              // if dragging reaches to certain point, it tries to save the current item as a temporary current index target item
              localStorage.setItem(
                'carousel2AssetDetailsCenterTarget',
                clamp(i, index.current - 1, index.current + 1),
              );
            }

            if (last) {
              // if this is the last drag moment, it cancels the gesture interaction and set the current index accordingly
              // this should happen before current index repositioning, otherwise the latest current index item won't be centered.
              let carouselCenterTarget = parseInt(
                localStorage.getItem('carousel2AssetDetailsCenterTarget'),
              );
              cancel(
                (index.current = clamp(carouselCenterTarget, 0, detailPageComponents.length - 1)),
              );
              if (activeIndex !== carouselCenterTarget) {
                onSlideChange(carouselCenterTarget);
              }
            }

            if (i === index.current) {
              // if this item is the current index item, either
              // 1. if mouse/touch finger is down, scale/reposition accordingly
              // 2. if not, reposition it to center with full scale and opacity value

              if (!down) transform = `translateY(0px)`;
            } else {
              if (!down) {
                // all other items repositioned accordingly when interaction is done
                transformXValue = (i - index.current) * listHeight + (down ? my * 1.2 : 0);
                transform = `translateY(${transformXValue}px)`;
              }
            }

            return { transform, display };
          });
        } catch (e) {
          console.error(e);
        }
      },
      onWheel: ({
        event,
        down,
        active,
        vxvy: [vx, vy],
        movement: [mvx, mvy],
        direction: [dirx, diry],
        delta: [dx, dy],
        memo: [my, md, df] = [0, 0, 0],
      }) => {
        let y = my; // storing vertical wheel movement

        const isDecelerating = Math.sign(dy) !== Math.sign(md) && Math.abs(dy) - Math.abs(md) >= 0;
        const deceleratingFrames = isDecelerating ? df + (Math.abs(dy) - Math.abs(md) ? 1 : 0) : 0;
        const trulyDecelerating =
          deceleratingFrames > 40 || (deceleratingFrames > 0 && Math.abs(dy) < 2);

        if (trulyDecelerating) {
          // lock down wheel event changing slide index when wheel delta is decelerating
          lockWheelOnDesktopMode.current = false;
          y = 0;
        } else {
          if (!lockWheelOnDesktopMode.current) {
            y = y - dy;
            if (Math.abs(y) >= 3) {
              // if user scroll interaction reached the configured velocity (3), it changes the slide index based on direction
              let newCurrent = index.current + (dy < 0 ? -1 : 1);

              if (newCurrent >= 0 && newCurrent <= detailPageComponents.length - 1) {
                localStorage.setItem(
                  'carousel2AssetDetailsCenterTarget',
                  clamp(newCurrent, 0, detailPageComponents.length - 1),
                );
                index.current = newCurrent;
                onSlideChange(newCurrent);
                lockWheelOnDesktopMode.current = true;
                y = 0;
              }
            }
          }
        }

        try {
          set(i => {
            let transformXValue = (i - index.current) * listHeight + (down ? mvy * 1.3 : 0);
            let transform = `translateY(${transformXValue}px)`;
            let display = 'flex';

            if (i === index.current) {
              // if this item is the current index item, reposition it to center
              if (!down) transform = `translateY(0px)`;
            } else {
              if (!down) {
                // all other items repositioned accordingly when interaction is done
                transformXValue = (i - index.current) * listHeight;
                transform = `translateY(${transformXValue}px)`;
              }
            }

            return { transform, display, deceleratingFrames };
          });
        } catch (e) {
          console.error(e);
        }
      },
    },
    {
      pointerEvents: false,
    },
  );

  return props.map(({ transform, display }, i) => (
    <animated.div
      className="AssetDetailsCarousel-swipeableitem"
      {...bind()}
      key={i}
      style={{ display, transform }}
    >
      {detailPageComponents[i]}
    </animated.div>
  ));
};

export default DetailsCarousel;
