export { default as setPageTitle } from './setPageTitle';

/**
 * Check emptiness of strings, objects and arrays
 * @param  {String || Object || Array} - value that needs to be checked
 * @return {Boolean}
 */
export const isEmpty = value => {
  if (typeof value === 'boolean' && value) return false;
  if (typeof value === 'number' && !isNaN(value)) return false;
  if (typeof value === 'string' && value.trim().length) return false;
  if (Array.isArray(value) && value.length) return false;
  if (value instanceof Object && value.constructor === Object && Object.keys(value).length)
    return false;

  return true;
};

export const userAgentDetection = () => {
  const ua = window.navigator.userAgent || '';
  return /RallyAndroidClient/.test(ua) ? 'rally_androidclient' : ua;
};

export const testPoBox = value => {
  const word = value
    .trim()
    .replace(/\s+/g, '')
    .replace(/\.+/g, '')
    .replace(/[0-9]+/g, '')
    .toLowerCase();
  return word.includes('pobox') || word === 'pob';
};

export const isRunningOnRallyAndroidClient = param => {
  const { checkNativeBridge } = param || {};
  const ua = window.navigator.userAgent || '';

  if (!/RallyAndroidClient/.test(ua)) return false;
  if (checkNativeBridge && !window.AndroidBridge) return false;

  return true;
};

export const getClientVersionNumber = () => {
  const ua = window.navigator.userAgent || '';

  if (/RallyAndroidClient/.test(ua)) {
    // if it's running inside Android client
    const uaSplit = ua.split('RallyAndroidClient/')[1];
    const androidClientVersionNumber = uaSplit.replace(/[[\]']/g, '');

    return androidClientVersionNumber;
  } else {
    // other devices (desktop/web browsers)
    return process.env.REACT_APP_VERSION;
  }
};

/**
 * Creates an object composed of keys ('key' argument), the corresponding value of
 * each key is an array of elements responsible for generating the key.
 * @param  {Array} array - array to iterate over
 * @param  {String} key
 * @return {Object}
 */
export const groupBy = (array, key) => {
  return array.reduce((result, current) => {
    const splitKeyArr = key.split('.');
    const keyToPush =
      splitKeyArr.length > 1
        ? splitKeyArr.reduce((a, b) => {
            return current[a][b];
          })
        : current[key];
    (result[keyToPush] = result[keyToPush] || []).push(current);
    return result;
  }, {});
};

/**
 * Trim strings. Removes white spaces and line breaks
 * @param  {String} string - string to be trimmed
 * @return {String} trimmed string
 */
export const trimString = string => {
  if (typeof string !== 'string') return '';
  return string.trim().replace(/\u200B/g, '');
};

/**
 * Extracts the value of the URL query parameter
 * @param  {String} searchParamName - a name of the paramter to search for
 * @return {String}
 */
export const getUrlParameter = searchParamName => {
  const urlParams = window.location.search.substring(1).split('&');
  for (let i = 0; i < urlParams.length; i++) {
    const [paramName, paramValue] = urlParams[i].split('=');
    if (paramName && paramName === searchParamName) return decodeURIComponent(paramValue);
  }
  return undefined;
};

export const formatAssetPrice = price => {
  if (!price) return price;
  const numberPrice = Number(price);
  if (price < 1000 && price > -1000) {
    return Number(numberPrice.toFixed(2)).toString(); // fixing any decimal issues
  }
  const Kvalue = price / 1000;
  if (Kvalue < 1000 && Kvalue > -1000) {
    return (
      (Number.isInteger(Kvalue) ? Kvalue.toString() : Number(Kvalue.toFixed(1)).toString()) + 'K'
    );
  }
  const Mvalue = price / 1000000;
  return (
    (Number.isInteger(Mvalue) ? Mvalue.toString() : Number(Mvalue.toFixed(1)).toString()) + 'M'
  );
};

export const mapToCategory = assetCategory => {
  switch (assetCategory) {
    case 'watches':
      return 'LUXURY';
    case 'comics-literature':
    case 'art':
      return 'COMICS_LITERATURE';
    case 'wine-spirits':
      return 'WINE_SPIRITS';
    default:
      return assetCategory.toUpperCase();
  }
};

export const roundAccurately = (number, decimalPlaces = 2) => {
  if (Number.isInteger(number)) return Number(number + '.00');
  else return Number(Math.round(number + 'e' + decimalPlaces) + 'e-' + decimalPlaces);
};

export const getSecondaryMarketEnableDiff = secondaryMarket => {
  if (!secondaryMarket?.enabled) return undefined;
  return new Date(secondaryMarket.enabled).getTime() - Date.now();
};
