import React, { Component } from 'react';
import classnames from 'classnames';

import { Checkmark } from 'components/shared/Icons/Checkmark';
import analytics from 'services/analytics';

import { SEGMENT_ACTIONS, SEGMENT_CATEGORIES } from 'constants/analytics';

import './NotifyMeButton.css';
import { ASSET_STATUS } from 'constants/main';
import { getSecondaryMarketEnableDiff } from 'utils';
import { SecondaryMarketStatus } from 'types/assets';

class NotifyMeButton extends Component {
  constructor(props) {
    super(props);
    this.subscribe = this.subscribe.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
  }

  subscribe = () => {
    this.props.subscribeUserOnAsset({
      asset_status: this.props.asset.asset_status,
      asset_id: this.props.asset.id,
    });

    analytics.track('Notify Me Subscription', {
      action: SEGMENT_ACTIONS.NOTIFY_ME_SUBSCRIPTION,
      asset: this.props.asset.ticker,
      category: SEGMENT_CATEGORIES.NOTIFICATIONS,
      label: this.props.asset.ticker,
    });
  };

  unsubscribe = subscription => () => {
    this.props.unsubscribeUserFromAsset({
      asset_status: this.props.asset.asset_status,
      subscription: subscription,
    });

    analytics.track('Notify Me Unsubscription', {
      action: SEGMENT_ACTIONS.NOTIFY_ME_UNSUBSCRIPTION,
      asset: this.props.asset.ticker,
      category: SEGMENT_CATEGORIES.NOTIFICATIONS,
      label: this.props.asset.ticker,
    });
  };

  render() {
    const status = this.props.asset.asset_status;
    const { secondaryMarket } = this.props.asset.financialInstrument?.markets ?? {};
    const { tradingSubscriptions, ipoSubscriptions } = this.props.subscriptions;
    const timestampDiff = getSecondaryMarketEnableDiff(secondaryMarket);
    const willTrade =
      status === ASSET_STATUS.TRADING_CLOSED ||
      (status === ASSET_STATUS.POST_ONLY && timestampDiff > 0) ||
      status === ASSET_STATUS.IO_CLOSED ||
      (status === ASSET_STATUS.TRADING_OPENED &&
        secondaryMarket.status === SecondaryMarketStatus.DISABLED) ||
      ((status === ASSET_STATUS.TRADING_OPENED || status === ASSET_STATUS.POST_ONLY) &&
        secondaryMarket.status === SecondaryMarketStatus.DISABLED);

    const nextPhase = willTrade ? 'Trading' : 'Active';
    let subscription = null;

    if (willTrade) {
      subscription = tradingSubscriptions.find(item => item.asset === this.props.asset.id);
    } else {
      subscription = ipoSubscriptions.find(item => item.asset === this.props.asset.id);
    }

    const isAlreadySubscribed = !!subscription;
    const buttonClassses = classnames('NotifyMeButton', {
      selected: isAlreadySubscribed,
      unselected: !isAlreadySubscribed,
    });

    const clickHandler = isAlreadySubscribed ? this.unsubscribe(subscription) : this.subscribe;

    return (
      <div className={buttonClassses} onClick={clickHandler}>
        <div className="NotifyMeButton-container">
          <span>{'Notify When ' + nextPhase}</span>
          <Checkmark />
        </div>
      </div>
    );
  }
}

export default NotifyMeButton;
