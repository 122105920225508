import CONFIG from 'config.js';
import * as RequestHelper from './RequestHelper';
import { isRunningOnRallyAndroidClient } from 'utils';

export const storeAuthToken = token => {
  localStorage.setItem('token', token);
  if (isRunningOnRallyAndroidClient({ checkNativeBridge: true }))
    window.AndroidBridge.setAuthToken && window.AndroidBridge.setAuthToken(token);
};

export function login(payload) {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/v3/auth/login/';

  return RequestHelper.postRequestNoAuthWithoutHandler(endpoint, payload)
    .catch(error => {
      return {
        errors: [
          {
            message: 'Something went wrong. Please, try again later.',
          },
        ],
      };
    })
    .then(response => {
      if ('json' in response) {
        return response.json();
      } else {
        return response;
      }
    })
    .then(body => {
      let result = {};

      if ('errors' in body) {
        result = {
          hasError: true,
          errors: body.errors,
        };
      } else if ('error_type' in body) {
        result = {
          hasError: true,
          errors: [{ message: body.error_type }],
        };
      } else {
        storeAuthToken(body.token);
        if ('exchange_token' in body) {
          localStorage.setItem('exchange_token', body.exchange_token);
        }
        if ('user_id' in body) {
          localStorage.setItem('user_id', body.user_id);
        }

        return getUserData().then(user => ({
          hasError: false,
          user,
        }));
      }
      return result;
    });
}

export function logout(token, payload) {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/auth/logout/';

  const requestInit = {
    method: 'POST',
    headers: RequestHelper.HEADERS_WITH_AUTH(token),
    credentials: 'include',
    body: payload,
  };

  fetch(endpoint, requestInit);
}

export function signup(payload) {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/auth/registration/';

  return RequestHelper.postRequestNoAuthWithoutHandler(endpoint, payload)
    .catch(error => {
      return {
        errors: [
          {
            message: 'Something went wrong. Please, try again later.',
          },
        ],
      };
    })
    .then(response => {
      if ('json' in response) {
        return response.json();
      } else {
        return response;
      }
    })
    .then(body => {
      let result = {};
      if ('user' in body) {
        storeAuthToken(body.token);
        localStorage.setItem('user_id', body.user.id);
        result = {
          hasError: false,
          user: body.user,
        };
      } else if ('errors' in body) {
        result = {
          hasError: true,
          errors: body.errors,
        };
      } else if ('error_type' in body) {
        result = {
          hasError: true,
          errors: [{ message: body.error_type }],
        };
      }
      return result;
    });
}

export function refreshToken(token, useAuth) {
  let endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/token-refresh/';
  let payload = JSON.stringify({ token: token });
  const requestInit = {
    method: 'POST',
    credentials: 'include',
    body: payload,
  };

  if (useAuth) {
    requestInit.headers = RequestHelper.HEADERS_WITH_AUTH(token);
  } else {
    requestInit.headers = RequestHelper.HEADERS_NO_AUTH;
  }

  return fetch(endpoint, requestInit)
    .then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        return { token: null };
      }
    })
    .then(body => {
      if (body.token === null) {
        localStorage.removeItem('token');
      } else {
        storeAuthToken(body.token);
      }
      return body.token;
    })
    .catch(err => {
      throw new Error(err);
    });
}

export function getUserData() {
  let userId = localStorage.getItem('user_id');
  let token = localStorage.getItem('token');
  let endpoint = CONFIG.scheme + CONFIG.apiUrl + `/api/users/${userId}`;

  return RequestHelper.getRequest(endpoint, token);
}

export function sendPasswordResetEmail(payload) {
  let endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/auth/password/reset/';
  let body = JSON.stringify(payload);

  return RequestHelper.postRequestNoAuthWithoutHandler(endpoint, body);
}

export function sendPasswordResetConfirmation(payload) {
  let endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/auth/password/reset/confirm/';
  let body = JSON.stringify(payload);

  return RequestHelper.postRequestNoAuthWithoutHandler(endpoint, body)
    .then(response => {
      return response.json();
    })
    .then(json => {
      if ('errors' in json) {
        throw json.errors[0].message;
      }

      return json;
    });
}

export function changePassword(payload) {
  const endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/auth/password/change/';
  const body = JSON.stringify(payload);
  const token = localStorage.getItem('token');
  return RequestHelper.postRequestWithoutHandler(endpoint, token, body);
}

export function confirmEmail(payload) {
  let endpoint = CONFIG.scheme + CONFIG.apiUrl + '/api/auth/email/confirm/';
  let body = JSON.stringify(payload);

  return RequestHelper.postRequestNoAuthWithoutHandler(endpoint, body)
    .then(response => {
      return response.json();
    })
    .then(json => {
      if ('errors' in json) {
        throw json.errors[0].message;
      }

      return json;
    });
}
