import React, { Component } from 'react';
import { Button } from 'components/shared/Buttons/Button';

import manageImage from 'images/tour/04_manage.png';

import './Manage.css';

class Manage extends Component {
  onClickOnboarding = () => {
    this.props.history.push({
      pathname: '/app/onboarding',
      state: {
        signupOnboardingFlow: this.props.data.signupOnboardingFlow,
      },
    });
  };

  render() {
    return (
      <div className="Manage">
        <h2>Manage + Buy & Sell</h2>
        <p className="light">
          Once trading, you'll be able to buy more shares, sell your current shares, or hold your
          shares and track progress toward achieving your investing goals.
        </p>
        <img src={manageImage} alt="manage" className="Manage-img Tour-gif" />
        <Button onClick={() => this.onClickOnboarding()}>
          <b>GET STARTED NOW!</b>
        </Button>
      </div>
    );
  }
}

export default Manage;
