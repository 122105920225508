import { createActions } from 'redux-actions';
import * as AuthAPI from 'services/AuthAPI';
import * as UsersAPI from 'services/UsersAPI';
import { showGlobalLoader, hideGlobalLoader } from 'actions/ui';
import { isEmpty } from 'utils';
import {
  SET_USER_DATA,
  ADD_STATE_SUBSCRIPTION,
  REMOVE_ASSETS_FROM_ACCESS_GROUPS,
  ADD_ASSET_SUBSCRIPTION,
  REMOVE_ASSET_SUBSCRIPTION,
  SET_DWOLLA_CUSTOMER_STATUS,
  SET_DWOLLA_CUSTOMER_ERROR,
  SET_USER_STATE_STATUS,
  USER_INFO_UPDATE_INITIATED,
  USER_INFO_UPDATE_COMPLETED,
  SET_TOS_AGREEMENT,
} from 'actions/types';
import analytics from 'services/analytics';
import { getCustomerData } from './customer';
import { fetchUserData } from 'services/UserApi';
import Bugsnag from '@bugsnag/js';
import { getUserPositions } from './investments';

// Reflects the number of http-requests running to update user info:
let userUpdateProcessesCount = 0;

// === Action creators: ===

export const {
  setUserData,
  addStateSubscription,
  addAssetSubscription,
  removeAssetSubscription,
  setDwollaCustomerStatus,
  setDwollaCustomerError,
  setUserStateStatus,
} = createActions(
  SET_USER_DATA,
  ADD_STATE_SUBSCRIPTION,
  ADD_ASSET_SUBSCRIPTION,
  REMOVE_ASSET_SUBSCRIPTION,
  SET_DWOLLA_CUSTOMER_STATUS,
  SET_DWOLLA_CUSTOMER_ERROR,
  SET_USER_STATE_STATUS,
);

// === Thunks: ===

export const getUserData =
  (options = { showGlobalLoader: true }, fetchInvestments = false) =>
  (dispatch, getState) => {
    if (options.showGlobalLoader) dispatch(showGlobalLoader());

    return AuthAPI.getUserData(fetchInvestments).then(async userData => {
      const enrollment = await dispatch(getEnrollment(userData.customer.accounts[0].id));

      const newUserData = { ...userData };

      userData.customer.accounts[0] = {
        ...userData?.customer?.accounts?.[0],
        enrollment: { ...enrollment },
      };
      dispatch({ type: SET_USER_DATA, payload: newUserData });

      // Identify the user and subsequent traits.
      analytics.identify(userData.id, {
        email: userData.email,
        firstName: userData.first_name,
        lastName: userData.last_name,
        createdAt: userData.date_joined,
        sharesCount: userData.shares_count,
      });

      if (userData.customer?.id) {
        await dispatch(getCustomerData(userData.customer.id));
        await dispatch(getUserPositions());
      } else {
        setTimeout(() => dispatch(getUserDataInBackground()), 2000);
      }

      if (options.callback) options.callback();
      if (options.showGlobalLoader) dispatch(hideGlobalLoader());
      return userData;
    });
  };

export const updateUserData = updates => (dispatch, getState) => {
  if (userUpdateProcessesCount === 0) dispatch({ type: USER_INFO_UPDATE_INITIATED });
  userUpdateProcessesCount++;
  return UsersAPI.setUserInfo(updates).then(userData => {
    userUpdateProcessesCount = userUpdateProcessesCount - 1 || 0;
    if (userUpdateProcessesCount === 0) dispatch({ type: USER_INFO_UPDATE_COMPLETED });

    const oldState = getState().Auth.user.state;
    const newState = userData.state;
    if (oldState !== newState) {
      return dispatch(getUserStateStatus()).then(() => {
        dispatch({ type: SET_USER_DATA, payload: userData });
      });
    } else {
      dispatch({ type: SET_USER_DATA, payload: userData });
    }
  });
};

export const subscribeOnStateAvailable = state => async dispatch => {
  try {
    await UsersAPI.subscribeOnStateAvailable();
    dispatch(addStateSubscription(state));
  } catch (err) {}
};

export const getUserStateStatus = () => dispatch => {
  return UsersAPI.getUserStateStatus().then(stateStatus =>
    dispatch(setUserStateStatus(stateStatus)),
  );
};

export const removeAssetsFromAccessGroups = (type, ids) => (dispatch, getState) => {
  let updatedAccessGroups = { ...getState().Auth.user.access_groups };

  if (updatedAccessGroups[type]) {
    updatedAccessGroups[type] = updatedAccessGroups[type].filter(
      item => !ids.includes(item.asset_id),
    );
    if (isEmpty(updatedAccessGroups[type])) delete updatedAccessGroups[type];
  }

  return dispatch({ type: REMOVE_ASSETS_FROM_ACCESS_GROUPS, payload: updatedAccessGroups });
};

export const getEnrollment = accountId => async () => {
  try {
    const { data } = await UsersAPI.getEnrollment(accountId);
    return data;
  } catch (err) {}
};

export const setTosAgreement = (timestamp, callback) => async (dispatch, getState) => {
  const accountId = await getState().Auth.user.customer?.accounts?.[0]?.id;

  try {
    await UsersAPI.setTosAgreement(timestamp, accountId);
    dispatch({ type: SET_TOS_AGREEMENT, payload: timestamp });
    dispatch(getUserData({ callback }));
  } catch (err) {}
};

export const getUserDataInBackground = () => dispatch => {
  fetchUserData()
    .then(({ data }) => {
      if (data.customer?.id) {
        dispatch(getCustomerData(data.customer.id));
        dispatch(getUserPositions());
      } else {
        setTimeout(() => dispatch(getUserDataInBackground()), 2000);
      }
    })
    .catch(error => {
      Bugsnag.notify(error);
    });
};
