import React, { Component } from 'react';
import { connect } from 'react-redux';

import ContentHeader from 'components/shared/ContentHeader';
import ContactPage from 'components/shared/Pages/Contact/ContactPage';
import { setPageTitle } from 'utils';
import './Contact.css';

class Contact extends Component {
  componentDidMount() {
    setPageTitle('Contact');
  }

  render() {
    return (
      <div className="Contact">
        <ContentHeader>CONTACT</ContentHeader>
        <div className="Contact-body">
          <ContactPage url_root="/app" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Contact);
