import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Collapse } from 'react-collapse';
import classnames from 'classnames';

import Popup from 'components/shared/Popup';
import { SecondaryButton } from 'components/shared/Buttons';
import { LeftCaret } from 'components/shared/Icons/LeftCaret';
import { UpCaret } from 'components/shared/Icons/UpCaret';
import { DotsLoader } from 'components/shared/Loaders';
import { Info } from 'components/shared/Icons/Info';
import * as StatementsAPI from 'services/StatementsAPI';
import './Statements.scss';

class Statements extends Component {
  state = {
    statements: [],
    taxforms: [],
    isLoading: false,
    isStatementsOpened: false,
    isTaxformsOpened: false,
    showStatementsPopup: false,
    showTaxformsPopup: false,
  };

  toggleStatementsCollapse = () => {
    if (!this.state.statements.length) return;
    this.setState({ isStatementsOpened: !this.state.isStatementsOpened });
  };

  toggleTaxformsCollapse = () => {
    if (!this.state.taxforms.length) return;
    this.setState({ isTaxformsOpened: !this.state.isTaxformsOpened });
  };

  showStatementsPopup = e => {
    e.stopPropagation();
    this.setState({ showStatementsPopup: true });
  };
  hideStatementsPopup = () => this.setState({ showStatementsPopup: false });

  showTaxformsPopup = () => this.setState({ showTaxformsPopup: true });
  hideTaxformsPopup = () => this.setState({ showTaxformsPopup: false });

  async componentDidMount() {
    this.setState({ isLoading: true });
    const [statements, taxforms] = await Promise.all([
      StatementsAPI.getStatements()
        .then(statements =>
          Promise.all(
            statements.map(async statement => {
              const file = await StatementsAPI.getStatementFile(statement.fileKey);
              return { ...statement, url: file.url };
            }),
          ),
        )
        .catch(() => []),
      StatementsAPI.getTaxDocuments()
        .then(taxforms =>
          Promise.all(
            taxforms.map(async taxform => {
              const file = await StatementsAPI.getStatementFile(taxform.fileKey);
              return { ...taxform, url: file.url };
            }),
          ),
        )
        .catch(() => []),
    ]);

    this.setState({ isLoading: false, statements, taxforms });
  }

  render() {
    const { isLoading, showStatementsPopup, showTaxformsPopup } = this.state;
    const { statements, taxforms, isStatementsOpened, isTaxformsOpened } = this.state;

    const statementsCollapseClass = classnames('Statements-collapse', {
      open: isStatementsOpened,
      disabled: isLoading || statements.length === 0,
      loading: isLoading,
    });
    const taxformsCollapseClass = classnames('Statements-collapse', {
      open: isTaxformsOpened,
      disabled: isLoading || taxforms.length === 0,
      loading: isLoading,
    });

    return (
      <div className="Statements">
        <div className="Statements-header">
          <Link to="/app/investments">
            <LeftCaret />
          </Link>
          <span className="Statements-header-title">STATEMENTS</span>
        </div>

        <div className="Statements-content">
          <div className={statementsCollapseClass} onClick={this.toggleStatementsCollapse}>
            <div className="Statements-collapse-header">
              Account Statements
              <button onClick={this.showStatementsPopup}>
                <Info />
              </button>
              {isLoading && (
                <div className="Statements-loader-container">
                  <DotsLoader />
                </div>
              )}
            </div>
            <div className="Statements-collapse-desc">
              {statements.length
                ? `(${statements.length}) available Account Statement${
                    statements.length > 1 ? 's' : ''
                  }`
                : 'No current Account Statements'}
            </div>
            <UpCaret />
          </div>
          <Collapse isOpened={isStatementsOpened}>
            <ul className="Statements-list">
              {statements.map(statement => (
                <li key={statement.fileKey}>
                  <a href={statement.url} target="_blank" rel="noopener noreferrer">
                    {statement.displayName.replace('Statement', '')}
                  </a>
                </li>
              ))}
            </ul>
          </Collapse>

          <div className={taxformsCollapseClass} onClick={this.toggleTaxformsCollapse}>
            <div className="Statements-collapse-header">
              Tax Documents
              <button onClick={this.showTaxformsPopup}>
                <Info />
              </button>
              {isLoading && (
                <div className="Statements-loader-container">
                  <DotsLoader />
                </div>
              )}
            </div>
            <div className="Statements-collapse-desc">
              {taxforms.length
                ? `(${taxforms.length}) available Tax Document${taxforms.length > 1 ? 's' : ''}`
                : 'No current Tax Documents'}
            </div>
            <UpCaret />
          </div>
          <Collapse isOpened={isTaxformsOpened}>
            <ul className="Statements-list">
              {taxforms.map(taxform => (
                <li key={taxform.fileKey}>
                  <a href={taxform.url} target="_blank" rel="noopener noreferrer">
                    {taxform.displayName}
                  </a>
                </li>
              ))}
            </ul>
          </Collapse>
        </div>

        <Popup
          className="Statements-popup"
          disableAutoClose
          show={showStatementsPopup}
          onRequestClose={this.hideStatementsPopup}
        >
          <div className="Statements-popup-message">
            Account Statements are typically available to view by the first week of the month, and
            will only show if you have an active investment in your portfolio.
          </div>
          <SecondaryButton text="Okay got it" onClick={this.hideStatementsPopup} />
        </Popup>

        <Popup
          className="Statements-popup"
          disableAutoClose
          show={showTaxformsPopup}
          onRequestClose={this.hideTaxformsPopup}
        >
          <div className="Statements-popup-message">
            If you have sold shares, your tax documents will become available in March of the
            following year.
          </div>
          <SecondaryButton text="Okay got it" onClick={this.hideTaxformsPopup} />
        </Popup>
      </div>
    );
  }
}

export default Statements;
