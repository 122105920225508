import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SecondaryButton } from 'components/shared/Buttons/SecondaryButton';
import { Checkmark } from 'components/shared/Icons/Checkmark';
import { Price } from 'components/shared/Price';

import './TransferInitiated.css';

class TransferInitiated extends Component {
  static propTypes = {
    data: PropTypes.object,
    onFormUpdate: PropTypes.func.isRequired,
    onStepForward: PropTypes.func.isRequired,
  };

  onConfirmClick = () => {
    this.props.onFormClose();
  };

  render() {
    const {
      data: { transfer_amount },
    } = this.props;
    return (
      <div className="TransferInitiated">
        <h2>Transfer to Rally</h2>
        <div className="TransferInitiated-checkmark">
          <Checkmark />
        </div>
        <div className="TransferInitiated-amount">
          <Price price={transfer_amount} />
        </div>
        <div className="TransferInitiated-text">
          <p>
            This transfer has been initiated. Your funds will be available shortly. Make sure Rally
            notifications are on to receive a message as soon as your funds have transferred.
          </p>
        </div>
        <SecondaryButton
          className="TransferInitiated-btn"
          text="Launch Rally!"
          onClick={this.onConfirmClick}
        />
      </div>
    );
  }
}

export default TransferInitiated;
