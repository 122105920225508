import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleMenu } from 'actions';
import './Menu.scss';

class Menu extends Component {
  constructor(props) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
  }
  toggleMenu() {
    this.props.toggleMenu();
  }
  render() {
    const activeStyle = this.props.menuOpen ? ' is-active' : '';
    return (
      <div className="Menu" onClick={this.toggleMenu}>
        <div className={'hamburger hamburger--slider' + activeStyle}>
          <div className="hamburger-box">
            <div className="hamburger-inner" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  menuOpen: state.UI.menuIsOpen,
});

const mapDispatchToProps = { toggleMenu };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Menu);
