import React from 'react';
import { HeaderTitle, AssetsHeaderContainer } from './styled';
import AssetsHeaderSearch from './AssetsHeaderSearch';

const AssetsHeader = () => (
  <AssetsHeaderContainer>
    <HeaderTitle>Assets</HeaderTitle>
    <AssetsHeaderSearch />
  </AssetsHeaderContainer>
);

export default AssetsHeader;
