export const SEGMENT_ACTIONS = {
  BACK_TAPPED_CLICKED: 'Back Tapped/Clicked',
  BUTTON_TAPPED_CLICKED: 'Button tapped/clicked',
  BUTTON_TAPPED_REVIEW_ORDER: 'Button tapped (Review order)',
  BUTTON_TAPPED: 'Button tapped',
  TAPPED: 'Tapped',
  CANCEL_THIS_ORDER_TAPPED: '"Cancel this order" link tapped',
  CLOSE: 'Close',
  CREATION: 'Creation',
  DRAWER_OPEN: 'Drawer Open',
  LINK_CLICK: 'Link Click',
  LOGIN_SUBMISSION: 'Login submission',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  MONEY_TRANSFER_TO_RALLY: 'Money Transferred to Rally Account',
  NEXT_TAPPED_CLICKED: 'Next Tapped/Clicked',
  NOTIFY_ME_SUBSCRIPTION: 'Notify Me Subscription',
  NOTIFY_ME_UNSUBSCRIPTION: 'Notify Me Unsubscription',
  OPEN: 'Open',
  REFRESH: 'Refresh',
  SCREEN: 'screen',
  SCROLL: 'Scroll',
  SETUP: 'Setup',
  SHARE: 'Share',
  SKIP: 'Skip',
  SUBMIT: 'Submit',
  SWIPE_DRAG: 'Swipe/drag',
  TEXT_ENTRY: 'Text Entry',
  TOS_SELECTED: 'ToS Selected',
  X_TAPPED: 'X tapped',
  VIEW: 'View',
};

export const SEGMENT_CATEGORIES = {
  ACCOUNT: 'Account',
  ASSET1_0_SCREEN: '1.0 Asset View',
  ASSET2_0_PORTAL: '2.0 Asset Portal',
  ASSET_2_0: '2.0 Asset',
  BUY_SHARES: 'Buy Shares',
  CREATE_PROFILE: 'Create Profile',
  EARLY_BIRD: 'Early Bird',
  FUND_ACCOUNT: 'Fund Account',
  FUNDING_SOURCE: 'Funding Source',
  INVESTMENT_CALENDAR: 'Investment Calendar',
  HOME: 'Home',
  HOME_BANNER: 'Home Banner',
  MY_PORTFOLIO: 'My Portfolio',
  NOTIFICATIONS: 'Notifications',
  PROMO_CODES: 'Promo Codes',
  SETTINGS: 'Settings',
  TOUR: 'Tour',
  TRADING_WINDOW: 'Trading Window',
  TRADING: 'Trading',
  TRANSFER: 'Transfer',
  SEARCH: 'Search',
  CHART: 'Chart',
};

export const SEGMENT_EVENTS = {
  SEARCH_TAPPED: 'Search Tapped',
  SEARCH_EXITED: 'Exited Search',
  SEARCH_NO_RESULTS: 'No Search Results',
  SEARCH_RESULTS_SELECTED: 'Search Results Selected Asset',
  VIEW_ORDER_BOOK: 'View Order Book',
  BID_BUTTON_TAPPED: 'Buy / Bid Button Tapped',
  ASK_BUTTON_TAPPED: 'Sell / Ask Button Tapped',
  ORDER_ENTRY_VIEW: 'Order Entry View',
  REVIEW_ORDER_VIEW: 'Review Order View',
  REVIEW_ORDER_TAPPED: 'Review Order Button Tapped',
  PLACE_ORDER_TAPPED: 'Place Order Button Tapped',
  INSTANT_BUY_TOGGLE_TAPPED: 'Instant Buy Toggle Tapped',
  LIMIT_BUY_TOGGLE_TAPPED: 'Limit Buy Toggle Tapped',
  BID_SUMMARY: 'Bid Summary View',
  ASK_SUMMARY: 'Ask Summary View',
  CANCEL_ORDER: 'Order Canceled',
  HIGH_ORDER_VOLUME_QUEUED: 'Alert Modal - High Order Volume in Queue',
  HIGH_ORDER_VOLUME_SUBMITTED: 'Alert Modal - High Order Volume Bid Placed',
  UNABLE_TO_PLACE_ORDER: 'Alert Modal  - Unable to Place Order',
  CALENDAR_CLOSED: 'Trading Calendar - Closed',
  CALENDAR_LIVE_TAP: 'Investment Calendar - Live Trading Tapped',
  CALENDAR_LIVE_ASSET_TAP: 'Investment Calendar - Live Trading Asset Tapped',
  CALENDAR_IO_ASSET_TAP: 'Investment Calendar - Inital Offering Asset Tapped',
  CALENDAR_IO_TAP: 'Investment Calendar - Initial Offering Tapped',
  HOME_CALENDAR_TAP: 'Home - View Trading Calendar Tapped',
  PORTFOLIO_INVESTMENT_TO_ASSET: 'Asset 2.0 - Held Asset Opened from Portfolio',
  PORTFOLIO_SOLD_INVESTMENT_TO_ASSET: 'Asset 2.0 - Sold Asset Opened from Portfolio',
  PORTFOLIO_ORDER_TO_ASSET: 'Asset 2.0 - Pending Asset Opened from Portfolio',
  HOME_VIEW: 'Home - Viewed',
  HOME_IO_HERO_ASSET_TAPPED: 'Home - IO Hero Asset Tapped',
  HOME_TRADING_HERO_ASSET_TAPPED: 'Home - Now Trading Hero Asset Tapped',
  HOME_TRADING_CAROUSEL_ASSET_TAPPED: 'Home - Trading Carousel Asset Tapped',
  HOME_OFFERING_CAROUSEL_ASSET_TAPPED: 'Home - Offerings Carousel Asset Tapped',
  HOME_VIEW_ALL_OFFERINGS_TAPPED: 'Home - View All Offerings Tapped',
  HOME_BANNER_VIEW: 'Home - Banner Viewed',
  HOME_BANNER_TAPPED: 'Home - Banner Tapped',
  HOME_BANNER_CLOSED: 'Home - Banner Closed',
  HOME_CLOSED: 'Home - Closed',
  CHART_VIEW: 'Chart - Viewed',
  CHART_TIMEFRAME_TAPPED: 'Asset 2.0 - Chart Time Frame Tapped',
  CHART_VALUE_TAPPED: 'Asset 2.0 - Chart Value Tapped',
};
