import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SecondaryButton } from 'components/shared/Buttons/SecondaryButton';
import { DotsLoader } from 'components/shared/Loaders';

import {
  checkMerchandiseProductsAvailability,
  removeActiveAssetMerchandiseProductData,
} from 'actions';

import './Congrats.css';

class Congrats extends Component {
  static propTypes = {
    onFormClose: PropTypes.func.isRequired,
  };

  state = {
    isCheckingMerchandiseAvailability: false,
  };

  onOkayExitFlow = async () => {
    try {
      this.setState({ isCheckingMerchandiseAvailability: true });
      await this.props.checkMerchandiseProductsAvailability();
    } catch (error) {
      /* if there was any error while refetching up-to-date product availability,
       * clear out the reducer object to prevent merchandise checkout component loaded for user as we can't guarantee availability */
      this.props.removeActiveAssetMerchandiseProductData();
    }

    this.props.onFormClose({ closeActionOrigin: 'IO_PURCHASED' });
  };

  render() {
    return (
      <div className="Congrats">
        <h1 className="Congrats-title">
          CONGRATS! <span className="Congrats-title-emphasis">YOU'RE IN!</span>
        </h1>
        <p className="Congrats-description">
          Your receipt will be sent via e-mail shortly. Your investment details are also accessible
          by clicking "My Portfolio" in the navigation menu.
        </p>
        <SecondaryButton
          disabled={this.state.isCheckingMerchandiseAvailability}
          onClick={() => this.onOkayExitFlow()}
        >
          {this.state.isCheckingMerchandiseAvailability ? (
            <DotsLoader color="rgba(255, 255, 255, 0.7)" />
          ) : (
            <b>OKAY</b>
          )}
        </SecondaryButton>
      </div>
    );
  }
}

const mapDispatchToProps = {
  checkMerchandiseProductsAvailability,
  removeActiveAssetMerchandiseProductData,
};

export default connect(
  null,
  mapDispatchToProps,
)(Congrats);
